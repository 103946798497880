import * as Actions from '../../../redux/actions'

import React, { Component } from 'react'

import BuscketListContainer from "../MemberArea/BucketList/BucketListContainer"
import FavoritesContainer from "../MemberArea/Favorites/FavoritesContainer"
import MuvTribeContainer from "../MemberArea/Muver/MuvTribePageContainer"
import ProfileContainer from "../MemberArea/Profile/ProfileContainer"
import TripsContainer from "../MemberArea/Trip/TripsContainer"
import { connect } from 'react-redux'
import { member_area_router, } from "../Common/RegExValidate"

class MuverContainer_ extends Component {

    updateDimensions = () => {
        this.props.setScreenSize(window.screen.width, window.screen.height)
        this.props.setBodyClientSize(document.body.clientWidth, document.documentElement.scrollHeight)
        this.props.setAppMenu(document.body.clientWidth)
    }


    componentDidMount() {
        window.addEventListener("resize", this.updateDimensions)
    }


     /* destory component */
     componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions)
    }

    render() {
        const { switch_container,authModal, getMemberAreaMuverInfo } = this.props
		authModal.uuid !== undefined && authModal.uuid !== "" && getMemberAreaMuverInfo(authModal.uuid, authModal.language.short_code)
   
        return (
            <div>
                {switch_container === member_area_router.trips && <TripsContainer />}
                {switch_container === member_area_router.bucketList && <BuscketListContainer />}
                {switch_container === member_area_router.favorites && <FavoritesContainer />}
                {switch_container === member_area_router.muvTribe && <MuvTribeContainer />}
                {switch_container === member_area_router.personalInfo && <ProfileContainer />}
            </div >
        )
    }
}

const mapStateToProps = state => {
    return {
        authModal: state.Member.authModal,
        switch_container: state.Member.switch_container,
    }
};

const mapDispatchToProps = {
    getMemberAreaMuverInfo: Actions.getMemberAreaMuverInfo,
    setBodyClientSize: Actions.setBodyClientSize,

    setScreenSize: Actions.setScreenSize,
    setAppMenu: Actions.setAppMenu,
    setScreenVerticalIsTop: Actions.setScreenVerticalIsTop,
    setAppMenuBookingButtonStatus: Actions.setAppMenuBookingButtonStatus,

    setSwitchContainer: Actions.setSwitchContainer,
};


const MuverContainer = connect(mapStateToProps, mapDispatchToProps)(MuverContainer_)
export default MuverContainer
