import { ArrowLeftIcon, ArrowRightIcon } from "../Common/SVGBank"
import React, { Component } from 'react'

import OtherTripsElement from "../Common/OtherTripsElement"
import { PropTypes } from "prop-types"
import Slider from 'react-slick';
import { connect } from 'react-redux'

const SliderArrowRight = ({ className, to, onClick, from }) => (
    <button type="button" onClick={onClick} className={`button button--text button--icon ${className}`} aria-label={to}>
        <ArrowRightIcon className="icon" icon={to} style={{
            height: "60px",
            width: "60px",
            transform: from === "trip_private" ? 'translateY(-140px)' : 'translateY(-100px)'
        }} />
    </button>
)



const SliderArrowLeft = ({ className, to, onClick, from }) => (
    <button type="button" onClick={onClick} className={`button button--text button--icon ${className}`} aria-label={to}>
        <ArrowLeftIcon className="icon" icon={to} style={{
            height: "60px",
            width: "60px",
            transform: from === "trip_private" ? 'translateY(-140px)' : 'translateY(-100px)'
        }} />
    </button>
)

/* other trips component */
class OtherTrips_ extends Component {
    render() {
        let { trip_public, trip_private, from, textColor, screenSize } = this.props
        const trip = from === "trip_private" ? trip_private : trip_public
        const heightContainer = "440px"

        let otherTrips = trip.otherTrips
        const display_element_length = screenSize > 1724 ? 5 :
            screenSize > 1500 ? 4 :
                screenSize > 1100 ? 3 :
                    screenSize >= 1024 ? 2 :
                        screenSize >= 768 ? 3 :
                            screenSize > 500 ? 1 : 2
        const left_element = otherTrips && otherTrips.length < display_element_length ? "left_element" : ""
        const arrowsValue = otherTrips && otherTrips.length > display_element_length ? true : false
        let settings = {
            accessibility: false,
            dots: false,
            infinite: false,
            arrows: arrowsValue,
            speed: 500,
            autoplay: false,
            slidesToShow: from === "trip_private" ? 5 : 4,
            slidesToScroll: from === "trip_private" ? 3 : 2,
            pauseOnHover: true,
            centerMode: false,
            prevArrow: <SliderArrowLeft to="prev" from={this.props.from} />,
            nextArrow: <SliderArrowRight to="next" from={this.props.from} />,
            responsive: [
                {
                    breakpoint: 1724,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 2,
                    }
                },
                {
                    breakpoint: 1500,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 2,
                    }
                },
                {
                    breakpoint: 1100,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        arrows: false,
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 2,
                        arrows: false,
                    }
                },
                {
                    breakpoint: 500,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        arrows: false,
                    }
                },
            ]
        }


        return (
            <div className={from === "trip_private" ? "private_other_trip" : ""} style={{paddingBottom: 50}}>
                <Slider {...settings} className={left_element} style={{ height: heightContainer }}>
                    {otherTrips && otherTrips[0] !== undefined ?
                        otherTrips.map((trip, idx_trip) => {
                            return (
                                <OtherTripsElement otherTrip={trip} textColor={textColor} from={from} key={idx_trip} />
                            )
                        }) : <div>No trip for you.</div>
                    }
                </Slider>
            </div>
        )
    }
}


OtherTrips_.propTypes = {
    trip_public: PropTypes.object.isRequired,
    trip_private: PropTypes.object.isRequired,
}
const mapStateToProps = state => {
    return {
        trip_public: state.PublicTrip.trip,
        trip_private: state.Member.memberArea.selectedTrip,
        screenSize: state.Setting.htmlBody.bodyClientWidth,
    }
};


export const OtherTrips = connect(mapStateToProps, null)(OtherTrips_)


