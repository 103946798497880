import * as Actions from '../../../../redux/actions'

import { makeStyles, withStyles } from '@material-ui/core/styles';

import React from 'react';
import Slider from '@material-ui/core/Slider';
import { connect } from 'react-redux'
import { member_area_router } from "../../Common/RegExValidate"

const useStyles = makeStyles({
    root: {
        width: "100%",
        '& .MuiSlider-root ': {
            color: "var(--mainGreen)",
        },
        '& .MuiSlider-rail': {
            backgroundColor: "var(--mainGrey)",
        },
        '& .MuiSlider-mark': {
            height: "8px",
            backgroundColor: "var(--mainGrey)",
        },
        '& .MuiSlider-markActive': {
            backgroundColor: "var(--lightGray)",
        },
        '& .MuiSlider-thumb::after': {
            width: "10px",
            height: "10px",
            borderRadius: "50%",
            background: "var(--mainGreen)",
            top: "5px",
            left: "5px",
        },
    },
});

const ThumbComponent = (props) => {
    return (
        <span {...props} />
    );
}

const SliderPersonalize_ = ({
    from = "",
    subTypes_bars = [],
    bars_id = "",
    bar = {},
    avg = 0,
    lang,
    setMuverPersCategoriesScores,

    switch_container,
    authModal,
    uuid = authModal.uuid }) => {

    const PrettoSlider = withStyles({
        root: {
            color: "var(--mainGreen)",
            height: 8,
            width: "100%",
            marginTop: "7px",
            minWidth: "200px",
        },
        thumb: {
            height: 24,
            width: 24,
            backgroundColor: switch_container === member_area_router.personalInfo ? "#191919" : "#FFFFFF",
            border: '2px solid currentColor',
            marginTop: -8,
            marginLeft: -12,
            '&:focus, &:hover, &$active': {
                boxShadow: 'inherit',
            },

        },
        active: {},

        valueLabel: {
            left: 'calc(-50% + 4px)',
        },
        track: {
            height: 8,
        },
        rail: {
            height: 8,
        },
    })(Slider);
    const handleChangeBar = (event, value) => {
        let sum = []
        subTypes_bars.map((a) => a.id !== bar.id ? sum.push(Number(a.score)) : sum.push(Number(value)))

        let avg = Math.round(sum.reduce((accumulator, currentValue) => accumulator + currentValue) / sum.length)
        setMuverPersCategoriesScores(uuid, bar.id, value, lang, "bar", bars_id, avg)
    }

    const handleChangeBars = (event, value) => {
        const idSets = subTypes_bars !== undefined && subTypes_bars.length > 0 && Array.from(new Set(subTypes_bars.map(a => a.id)))
        idSets !== undefined && idSets.length > 0 && idSets.map((id) => setMuverPersCategoriesScores(uuid, id, value, lang, "bar", bars_id, value))
    }
    const classes = useStyles();
    return (
        <div>
            {from === "bar" &&
                <div className={classes.root}>
                    <PrettoSlider
                        ThumbComponent={ThumbComponent}
                        value={Number(bar.score || 0)}
                        onChange={handleChangeBar}
                        aria-labelledby="discrete-slider"
                        step={1}
                        marks
                        min={-5}
                        max={5}
                        valueLabelDisplay="auto"

                    />
                </div>
            }
            {from === "bars" &&
                < div className={classes.root}>
                    <PrettoSlider
                        ThumbComponent={ThumbComponent}
                        value={Number(avg) || 0}
                        onChange={handleChangeBars}
                        aria-labelledby="discrete-slider"
                        step={1}
                        marks
                        min={-5}
                        max={5}
                        valueLabelDisplay="auto"
                    />
                </div>
            }
        </div >
    );
}

const mapStateToProps = (state) => ({
    switch_container: state.Member.switch_container,
    screenSize: state.Setting.htmlBody.bodyClientWidth,
    authModal: state.Member.authModal,
    lang: state.Setting.lang,
    isMobile: state.Setting.is_mobile,
})

const mapDispatchToProps = {
    setMuverPersCategoriesScores: Actions.setMuverPersCategoriesScores,
}

const SliderPersonalize = connect(mapStateToProps, mapDispatchToProps)(SliderPersonalize_)
export default SliderPersonalize
