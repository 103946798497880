import React, { Component } from 'react'

import HeaderLanding from '../Header/HeaderLanding.jsx';
import RequestToJoin from './RequestToJoin.jsx';
import { TopBarOnboarding } from './TopBarOnboarding.jsx';
import { connect } from 'react-redux';

class Onboarding_ extends Component {
    render() {
        return (
            <div>                
                <HeaderLanding />
                <TopBarOnboarding />
                <RequestToJoin />
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    
})

const mapDispatchToProps = {
    
}

const Onboarding = connect(mapStateToProps, mapDispatchToProps)(Onboarding_)
export default Onboarding
