import {
    ADD_TRAVEL_HOW,
    ADD_TRAVEL_HOWLONG,
    ADD_TRAVEL_INTERESTS,
    ADD_TRAVEL_WHAT,
    ADD_TRAVEL_WHERE,
    FETCH_MUVER_INTERESTS_FAILURE,
    FETCH_MUVER_INTERESTS_SELECTED_SETS,
    FETCH_MUVER_INTERESTS_SUCCESS,
    REMOVE_TRAVEL_HOW,
    REMOVE_TRAVEL_HOWLONG,
    REMOVE_TRAVEL_INTERESTS,
    REMOVE_TRAVEL_WHAT,
    REMOVE_TRAVEL_WHERE,
} from "../interfaces/types"

const initialState = {
    fetched: false,
    travel_interests: [],
    travel_where: [],
    travel_how: [],
    travel_what: [],
    travel_howlong: [],
    selected_travel_interests_merge_sets: [],
    resources: {
        travel_interests: [],
        travel_where: [],
        travel_how: [],
        travel_what: [],
        travel_howlong: [],
    }

}


/* landing reducer */
export default (state = initialState, action) => {
    switch (action.type) {

        case FETCH_MUVER_INTERESTS_SUCCESS:
            return {
                ...state,
                resources: {
                        ...state.resources,
                        travel_interests: action.travel_interests,
                        travel_where: action.travel_where,
                        travel_how: action.travel_how,
                        travel_what: action.travel_what,
                        travel_howlong: action.travel_howlong,
                    },
                    fetched: action.fetched,
            }

            ;
        case FETCH_MUVER_INTERESTS_SELECTED_SETS:
            return {
                ...state,
                travel_interests: action.travel_interests,
                    travel_where: action.travel_where,
                    travel_how: action.travel_how,
                    travel_what: action.travel_what,
                    travel_howlong: action.travel_howlong,

                    selected_travel_interests_merge_sets: state.selected_travel_interests_merge_sets
                    .concat(
                        action.travel_interests,
                        action.travel_where,
                        action.travel_how,
                        action.travel_what,
                        action.travel_howlong,
                    ),
            }

            ;
        case FETCH_MUVER_INTERESTS_FAILURE:
            return {
                ...state,
                fetched: action.fetched,
            }

            ;

        case ADD_TRAVEL_INTERESTS:
            return {
                ...state,
                travel_interests: [...state.travel_interests, action.id],
                    selected_travel_interests_merge_sets: [...state.selected_travel_interests_merge_sets, action.id],
            }

            ;
        case REMOVE_TRAVEL_INTERESTS:
            return {
                ...state,
                travel_interests: state.travel_interests.filter(val => val !== action.id),
                    selected_travel_interests_merge_sets: state.selected_travel_interests_merge_sets.filter(val => val !== action.id),
            }

            ;
        case ADD_TRAVEL_HOWLONG:
            return {
                ...state,
                travel_howlong: [...state.travel_howlong, action.id],
                    selected_travel_interests_merge_sets: [...state.selected_travel_interests_merge_sets, action.id],
            }

            ;
        case REMOVE_TRAVEL_HOWLONG:
            return {
                ...state,
                travel_howlong: state.travel_howlong.filter(val => val !== action.id),
                    selected_travel_interests_merge_sets: state.selected_travel_interests_merge_sets.filter(val => val !== action.id),
            };
        case ADD_TRAVEL_HOW:
            return {
                ...state,
                travel_how: [...state.travel_how, action.id],
                    selected_travel_interests_merge_sets: [...state.selected_travel_interests_merge_sets, action.id],
            }

            ;
        case REMOVE_TRAVEL_HOW:
            return {
                ...state,
                travel_how: state.travel_how.filter(val => val !== action.id),
                    selected_travel_interests_merge_sets: state.selected_travel_interests_merge_sets.filter(val => val !== action.id),
            }

            ;
        case ADD_TRAVEL_WHERE:
            return {
                ...state,
                travel_where: [...state.travel_where, action.id],
                    selected_travel_interests_merge_sets: [...state.selected_travel_interests_merge_sets, action.id],
            }

            ;
        case REMOVE_TRAVEL_WHERE:
            return {
                ...state,
                travel_where: state.travel_where.filter(val => val !== action.id),
                    selected_travel_interests_merge_sets: state.selected_travel_interests_merge_sets.filter(val => val !== action.id),
            }

            ;
        case ADD_TRAVEL_WHAT:
            return {
                ...state,
                travel_what: [...state.travel_what, action.id],
                    selected_travel_interests_merge_sets: [...state.selected_travel_interests_merge_sets, action.id],
            }

            ;
        case REMOVE_TRAVEL_WHAT:
            return {
                ...state,
                travel_what: state.travel_what.filter(val => val !== action.id),
                    selected_travel_interests_merge_sets: state.selected_travel_interests_merge_sets.filter(val => val !== action.id),
            };

        default:
            return {
                ...state
            }
    }
}