import * as Actions from '../../../../redux/actions'

import React from 'react'
import { connect } from 'react-redux'

const MemberActionsInitial_ = ({ uuid, fetchMemeberActions,fetchDoneSets,fetchingMemberActionsDone, fetchPlusSets, fetchingMemberActionsPlus, fetchFavoriteSets, fetchingMemberActionsFavorite, fetchFeatures, fetchStarSets, fetchingMemberActionsStar, fetchMemberActionsMemberActions, fetchFeatureMemberActions, }) => {
    React.useEffect(() => {

        !fetchMemeberActions.isFetched && fetchMemberActionsMemberActions()
        !fetchFeatures.isFetched && fetchFeatureMemberActions()
        !fetchStarSets.isFetched && fetchingMemberActionsStar(uuid)
        if (uuid !== "") {
            !fetchFavoriteSets.isFetched && fetchingMemberActionsFavorite(uuid)
            !fetchPlusSets.isFetched && fetchingMemberActionsPlus(uuid)
            !fetchDoneSets.isFetched && fetchingMemberActionsDone(uuid)
        }
    }, [uuid])
    return (
        <React.Fragment />
    )
}

const mapStateToProps = (state) => ({
    fetchMemeberActions: state.MemberActions.fetchMemeberActions,
    fetchFeatures: state.MemberActions.fetchFeatures,
    fetchStarSets: state.MemberActions.fetchStarSets,
    fetchFavoriteSets: state.MemberActions.fetchFavoriteSets,
    fetchPlusSets: state.MemberActions.fetchPlusSets,
    fetchDoneSets: state.MemberActions.fetchDoneSets,
    uuid: state.Member.authModal.uuid,
})

const mapDispatchToProps = {
    fetchMemberActionsMemberActions: Actions.fetchMemberActionsMemberActions,
    fetchFeatureMemberActions: Actions.fetchFeatureMemberActions,
    fetchingMemberActionsStar: Actions.fetchingMemberActionsStar,
    fetchingMemberActionsFavorite: Actions.fetchingMemberActionsFavorite,
    fetchingMemberActionsPlus: Actions.fetchingMemberActionsPlus,
    fetchingMemberActionsDone:Actions.fetchingMemberActionsDone,
}

const MemberActionsInitial = connect(mapStateToProps, mapDispatchToProps)(MemberActionsInitial_)
export default MemberActionsInitial

