import * as Actions from '../../../redux/actions'

import Button from '@material-ui/core/Button';
import { CalendarDuotoneIcon, TimesIcon } from "./SVGBank"
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import React from 'react';
import { TripStartDateTextField } from '../Common/FormComponentsBank'
import { connect } from "react-redux";

/* modify trip date dialog function component */

const ModifyTripDateDialog_ = ({ from, trip, setToggleEllipsisCloseDisable, screenSize,setToggleEllipsisCloseEnable, toggle_ellipsis_close_disable, inline }) => {
    const [open, setOpen] = React.useState(false);
    const handleClickOpen = (event) => {
        event.stopPropagation()
        event.preventDefault()
        setToggleEllipsisCloseDisable()
        setOpen(true);
    };

    const handleClose = () => {
        setToggleEllipsisCloseEnable()
        setOpen(false);
    };


    return (
        <div>
            <div style={{ zIndex: "1" }} onClick={handleClickOpen}>
                {inline ? "" : <CalendarDuotoneIcon />}
                {!inline && toggle_ellipsis_close_disable ? "" : from}
            </div>
            <Dialog open={open}
                aria-labelledby={open ? 'form_dialog_title' : undefined}
            >
                <DialogTitle id={open ? "form_dialog_title" : undefined}>
                    Modify Start Date
                    
                    <div onClick={handleClose} style={{
                     float: "right",
                     display: "inline-block",
                }}>{screenSize > 1024 ? <TimesIcon width={20} height={20} /> : <TimesIcon width={15} height={15} />}</div>
                    </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {"Click below to modify trip start date"}
                    </DialogContentText>
                    <div className="d-flex">
                        <CalendarDuotoneIcon style={{ marginTop: "5px", marginRight: "10px" }} />
                        <TripStartDateTextField
                            isShortFormat={true}
                            inputStyle={{
                                font: "14px/14px Futura Md BT",
                                color: "var(--frameBlack)",
                                width: "100%",
                            }} />
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleClose} color="primary">
                          Ok
                     </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

const mapStateToProps = state => ({
    lang: state.Setting.lang,
    uuid: state.Member.authModal.uuid,
    trip: state.Member.memberArea.selectedTrip,
    screenSize: state.Setting.htmlBody.bodyClientWidth,
    toggle_ellipsis_close_disable: state.Member.memberArea.toggle_ellipsis_close_disable,
});

const mapDispatchToProps = {
    setToggleEllipsisCloseDisable: Actions.setToggleEllipsisCloseDisable,
    setToggleEllipsisCloseEnable: Actions.setToggleEllipsisCloseEnable,
}
const ModifyTripDateDialog = connect(mapStateToProps, mapDispatchToProps)(ModifyTripDateDialog_);
export default ModifyTripDateDialog 