import { AngryIcon, HappyIcon } from '../SVGBank'
import { CircleDownIcon, CircleUpIcon } from "../SVGBank"

import React from 'react'
import SliderPersonalize from "./SliderPersonalize"
import { connect } from 'react-redux'
import { member_area_router } from "../../Common/RegExValidate"

const PersonalizationGroupBars_ = ({
    screenSize,
    authModal,
    uuid = authModal.uuid,
    lang,
    isMobile,
    groupBars,
    group = groupBars,
    switch_container,
}) => {
    let screen_at_1200 = 1200
    let checkbox_title_font = "16px/20px Futura Md BT"
    let checkbox_title_font_hv = "18px/20px Futura Hv BT"
    if (!isNaN(screenSize)) {
        checkbox_title_font = screenSize > screen_at_1200 ? "16px/20px Futura Md BT" : "11px/18px Futura Md BT"
        checkbox_title_font_hv = screenSize > screen_at_1200 ? "18px/20px Futura Hv BT" : "13px/18px Futura Hv BT"
    }
    const [open, setOpen] = React.useState(false)
    const handleToggle = () => {
        setOpen(prevOpen => !prevOpen);
    };

    return (
        <div>
            <div style={{
                marginBottom: "20px",
            }}>
                {isMobile && <div style={{ display: "flex" }}>
                    <div style={{
                        font: checkbox_title_font_hv,
                        marginTop: "12px",
                        marginRight: "12px",
                        color: switch_container === member_area_router.personalInfo ? "#EFEFEF" : "#000000",

                    }}>{group.title}</div>
                    <div style={{ width: "20px", marginTop: "12px", textAlign: "left", flexGrow: "1" }}>
                        {open && <div onClick={() => handleToggle()} style={{ cursor: "pointer" }}><CircleUpIcon /></div>}
                        {!open && <div onClick={() => handleToggle()} style={{ cursor: "pointer" }}><CircleDownIcon /></div>}
                    </div>
                </div>}
                <div style={{ font: checkbox_title_font, display: "flex" }}>
                    {!isMobile && <div style={{
                        width: "200px", font: checkbox_title_font_hv, marginTop: "12px",
                        color: switch_container === member_area_router.personalInfo ? "#EFEFEF" : "#000000",
                    }}>{group.title}</div>}
                    <div style={{ padding: "12px 15px" }} >
                        <AngryIcon />
                    </div>
                    <div style={{ flexGrow: 1, }} >
                        <SliderPersonalize avg={group.avg} uuid={uuid} lang={lang} from={"bars"} subTypes_bars={group.subTypes_bars} bars_id={group.id} />
                    </div>
                    <div style={{ padding: "12px 15px" }} >
                        <HappyIcon />
                    </div>
                    {!isMobile && <div style={{ width: "20px", marginTop: "12px" }}>
                        {open && <div onClick={() => handleToggle()} style={{ cursor: "pointer" }}><CircleUpIcon /></div>}
                        {!open && <div onClick={() => handleToggle()} style={{ cursor: "pointer" }}><CircleDownIcon /></div>}
                    </div>}
                </div>
            </div>
            {open && group.subTypes_bars !== undefined && group.subTypes_bars.length > 0 && group.subTypes_bars.map((bar, bar_idx) => {
                return (
                    <div key={bar_idx} >
                        {isMobile && <div style={{
                            width: "200px", paddingTop: "12px",
                            color: switch_container === member_area_router.personalInfo ? "#EFEFEF" : "#000000",
                        }}>{bar.title}</div>}
                        <div style={{ font: checkbox_title_font, display: "flex" }}>
                            {/* {!isMobile && <div style={{ width: "30px" }}></div>} */}
                            {!isMobile && <div style={{
                                width: "200px", paddingTop: "12px",
                                color: switch_container === member_area_router.personalInfo ? "#EFEFEF" : "#000000",
                            }}>{bar.title}</div>}
                            <div style={{ padding: "12px 15px" }} >
                                <AngryIcon />
                            </div>
                            <div style={{ flexGrow: 1, }} >
                                <SliderPersonalize avg={group.avg} uuid={uuid} lang={lang} from={"bar"} subTypes_bars={group.subTypes_bars} bars_id={group.id} bar={bar} />
                            </div>
                            <div style={{ padding: "12px 15px" }} >
                                <HappyIcon />
                            </div>
                            {!isMobile && <div style={{ width: "20px" }}></div>}
                        </div>
                        <hr style={{
                            border: switch_container === member_area_router.personalInfo ? "1px solid var(--lightGray)" : "1px solid var(--lightGrayF)",
                            background: switch_container === member_area_router.personalInfo ? "var(--lightGray)" : "var(--lightGrayF)",
                        }} />
                    </div>
                )
            })}
        </div>
    )

}

const mapStateToProps = (state) => ({
    switch_container: state.Member.switch_container,

    screenSize: state.Setting.htmlBody.bodyClientWidth,
    authModal: state.Member.authModal,
    lang: state.Setting.lang,
    isMobile: state.Setting.is_mobile,
})

const PersonalizationGroupBars = connect(mapStateToProps, null)(PersonalizationGroupBars_)
export default PersonalizationGroupBars
