import * as Actions from '../../../redux/actions'
import { EllipsisVIcon, TimesIcon } from "../Common/SVGBank"

import { FooterWhite } from "../Footer/FooterWhite.jsx"
import React from 'react'
import { connect } from 'react-redux'
import { TermsComponent } from './TermsComponent'
import './terms.css';
import { PrivacyPolicyComponent } from './PrivacyPolicy'
import { ContactUsComponent } from './ContactUs'


const TermsConditionContainer_ = ({}) => {

    const pathName = window?.location?.pathname;
    const terms = pathName && pathName === '/terms-and-conditions';
    const privacy = pathName && pathName === '/privacy-policy';
    const contactUs = pathName && pathName === '/contact-us';

    React.useEffect(() => {
    }, [])

    return (
        <div style={{marginTop: 15}} > 
        {terms && <TermsComponent/> }
        {privacy && <PrivacyPolicyComponent/>}
        {privacy && <PrivacyPolicyComponent/>}
        {contactUs && <ContactUsComponent/>}

            <FooterWhite />
        </div>
    )
}

const mapStateToProps = (state) => ({
    screenSize: state.Setting.htmlBody.bodyClientWidth,

    is_mobile: state.Setting.is_mobile,
    lang: state.Setting.lang,
    uuid: state.Member.authModal.uuid,

})

const mapDispatchToProps = {
    getMemberActionsSets: Actions.getMemberActionsSets,

}

const TermsConditionContainer = connect(mapStateToProps, mapDispatchToProps)(TermsConditionContainer_)
export default TermsConditionContainer
