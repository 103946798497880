export const environment = {
    googleTrackingId: "UA-110030893-1",
    apiUrl: "https://api.muvtravel.com/api/public/",
    prodUrl: "https://muvtravel.com",
    captchaKey : "6Lcelm0UAAAAAGNIh7hkACU0IUzCFXtwRTU33b3h",
    pricelineApiUrl: "http://api-sandbox.rezserver.com/",
    pricelineRefId: "8660",
    pricelineApiKey: "ce7272cb256e1c225bb8e55ddc64c0ff",
    blackPageUrl: "https://muv.travel/",
    production: true,
    isBlackPage: true,
}

// https://api.muvtravel.com/api/public/ ---prod
