import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import "./styles.css";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'grid',
    gridTemplateColumns: '40% 60%',
    alignItems: 'center',
    marginTop: 220,

    [theme.breakpoints.down('sm')]: {
      display: 'block',
      marginTop: 50
    },
    [theme.breakpoints.between('sm', 'md')]: {
      display: 'grid',
      gridTemplateColumns: '40% 60%',
      alignItems: 'center',
      marginTop: 100,
    },
  },
  stepDescriptionSection: {
    position: 'relative',
    width: '80%',
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  stepBackgroundImage: {
    height: "160%",
    paddingLeft: 0, 
    overflow: 'hidden',
    [theme.breakpoints.between('sm', 'md')]: {
      height: "110%",
    },
  },
  firstCard: {
    position: 'absolute',
    top: 0,
    left: 70,
    [theme.breakpoints.down('sm')]: {
      left: 5,
      top: 40
    },
  },
  secondCard: {
    position: 'absolute',
    bottom: 160,
    left: 270,
    [theme.breakpoints.down('sm')]: {
      top: 0,
      left: 205
    },
  },
  thirdCard: {
    position: 'absolute',
    right: 130,
    top: 70,
    [theme.breakpoints.down('sm')]: {
      right: 107,
    },
    [theme.breakpoints.between('sm', 'md')]: {
      right: 10,
    },
  },
  card: {
    width: 190,
    maxWidth: 345,
    [theme.breakpoints.down('md')]: {
      width: 160,
    },
    [theme.breakpoints.down('sm')]: {
      width: 110,
    },

    "& .MuiCardContent-root": {
      padding: 10,
    },

    "& .MuiTypography-h5": {
      font: "normal normal 900 14px/16px Futura Hv BT",
      color: "#191919",
      [theme.breakpoints.down('md')]: {
        font: "normal normal 900 12px/10px Futura Hv BT",
      },
      [theme.breakpoints.down('sm')]: {
        font: "normal normal 900 8px/10px Futura Hv BT",
      },
    },

    "& .MuiTypography-colorTextSecondary": {
      font: "normal normal 600 9px/11px Futura Md BT",
      color: "#707070",
      [theme.breakpoints.down('sm')]: {
        font: "normal normal 600 6px/9px Futura Md BT",
      },
    },
    "& .MuiCardActions-root": {
      "& .MuiButton-label": {
        font: "normal normal 600 8px/10px Futura Md BT",
        color: "#19BC9B",
        [theme.breakpoints.down('sm')]: {
          font: "normal normal 600 4px/8px Futura Md BT",
        },
      },
      "& .MuiTouchRipple-root": {
        background: "#19BC9B 0% 0% no-repeat padding-box",
        borderRadius: "8px",
        opacity: 0.2,
      },
      "& .MuiButton-root": {
        minWidth: 0
      }
    },
  },
  media: {
    height: 100,
    [theme.breakpoints.down('sm')]: {
      height: 60,
    },
  },
}));

export default function LandingStepThree() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className="landing-step-container">
        <div className={classes.stepDescriptionSection}>
          <h1 className="step-number">3</h1>
          <div className="step-description">
            <h2 className="step-title">Create your itineraries</h2>
            <p className="futura_lt_bt_20_30">
            Walk down memory lane while recreating the itineraries of past trips. 
            Add your best photos or videos. Create new itineraries, dream trips you want to take one day or upcoming trips you wish to plan. 
            Start from someone's itinerary or just build your own from scratch.
            </p>
          </div>
        </div>
      </div>
      <div className={classes.stepBackgroundImage}>
        <div
          style={{
            position: 'relative',
            backgroundImage: `url(${require("../../../assets/images/world-map.png")})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            minHeight: 310,
            height: "125%",
          }}
        >
          <div className={classes.firstCard}>
            <Card className={classes.card}>
              <CardActionArea>
                <CardMedia
                  className={classes.media}
                  image={require("../../../assets/images/yellowstone.png")}
                />
                <CardContent>
                  <div
                    style={{
                      font: "normal normal 600 9px/11px Futura Md BT",
                      color: "#191919",
                    }}
                  >
                    Park
                  </div>
                  <Typography gutterBottom variant="h5" component="h2">
                    Yellowstone Park
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    Yellowstone National Park is an American national park located northwest corner of Wyoming and extending into Montana and Idaho. 
                    The park is known for its wildlife and its many geothermal features.
                  </Typography>
                </CardContent>
              </CardActionArea>
              <CardActions>
                <Button size="small" color="primary">
                  National Park
                </Button>
              </CardActions>
            </Card>
          </div>

          <div className={classes.secondCard}>
          <Card className={classes.card}>
              <CardActionArea>
                <CardMedia
                  className={classes.media}
                  image={require("../../../assets/images/tikal.png")}
                />
                <CardContent>
                  <div
                    style={{
                      font: "normal normal 600 9px/11px Futura Md BT",
                      color: "#191919",
                    }}
                  >
                    Archeological site
                  </div>
                  <Typography gutterBottom variant="h5" component="h2">
                    Tikal
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    Tikal is the ruin of an ancient city, found in a rainforest in Guatemala. 
                    It is one of the largest archeological sites and urban centers of the pre-Columbian Maya civilization.
                  </Typography>
                </CardContent>
              </CardActionArea>
              <CardActions>
                <Button size="small" color="primary">
                  Natural Wonder
                </Button>
              </CardActions>
            </Card>
            
          </div>

          <div className={classes.thirdCard}>
          <Card className={classes.card}>
              <CardActionArea>
                <CardMedia
                  className={classes.media}
                  image={require("../../../assets/images/fete.jpeg")}
                />
                <CardContent>
                  <div
                    style={{
                      font: "normal normal 600 9px/11px Futura Md BT",
                      color: "#191919",
                    }}
                  >
                    Event
                  </div>
                  <Typography gutterBottom variant="h5" component="h2">
                  Fête de la Musique
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    The Fête de la Musique is an annual music celebration that takes place on 21 June. On Music Day, citizens and residents are urged to play music outside in their neigh- borhoods or in public spaces and parks.
                  </Typography>
                </CardContent>
              </CardActionArea>
              <CardActions>
                <Button size="small" color="primary">
                  Festival
                </Button>
              
              </CardActions>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
}
