import * as Actions from '../../../redux/actions'

import { InfoWindow, Marker } from "react-google-maps"

import ActivityDuration from "./TimelineWizard/ActivityDuration"
import { ActivityStartTime } from "./TimelineWizard/ActivityStartTime"
import AddedToTrip from './AddedToTrip'
import { EstablishmentDescription } from "./EstablishmentDescription"
import EstablishmentName from './Establishment/EstablishmentName'
import {
    Image,
} from 'react-bootstrap'
import { PlusSignIcon } from '../Common/SVGBank'
import Rating from '@material-ui/lab/Rating'
import React from 'react'
import { connect } from 'react-redux'
import { image_boxing } from './RegExValidate'
import _ from "lodash";

export const ItineraryMarkers_ = ({pushPlacesWindow,clearPlacesWindow,checkWindowOpen, activity, switchFeatureContainer, activeTrip, index_activity, activeDestinationId, uuid_auth, lang, activeTripDestinationId, activeTripActivities, addActivityToTripDestinationInTrip, activeTripDay, }) => {
    // info window
    const [isOpenInfoWindow, setIsOpenInfoWindow] = React.useState(false)
    const [isHoverInfoWindow, setIsHoverInfoWindow] = React.useState(false)
    const switchFeatureContainerColorLight = switchFeatureContainer.color + "35"

    const uuid = uuid_auth !== "" ? uuid_auth : "TMPUUID"
    const activeTripId = activeTrip.tripId
    const activeDayInTripDestination = activeTripDay.dayInTripDestination

    const activitiesInDestinationDay = activeTrip.activities.filter(a => a.activityDay === activeTripDay.dayInTrip && a.tripDestinationId === activeTripDay.tripDestinationId)
    const lengthActivitiesInDestinationDay = activitiesInDestinationDay !== undefined ? activitiesInDestinationDay.length : 0
    const activitiesInDestinationDayEstablishmentIds = Array.from(activitiesInDestinationDay.map((a) => a.activityEstabID))
    const last_establishmentId = activitiesInDestinationDayEstablishmentIds[lengthActivitiesInDestinationDay - 1] !== undefined ? activitiesInDestinationDayEstablishmentIds[lengthActivitiesInDestinationDay - 1] : ""

    const activeTripDestinationIds = Array.from(new Set(activeTrip.tripDestinations.map(d => d.id)))
    const tripDuration = activeTrip.tripDuration
    const availableDeleteActivities = activeTripActivities.filter(a => a.activityEstabID === activity.activityEstabID)


    const onToggleOpenInfoWindow = () => {
        setIsOpenInfoWindow(!isOpenInfoWindow)
    }
    
    const onHoverOpenInfoWindow = () => {
            if(activity && activity.activityEstabID){
                pushPlacesWindow(activity.activityEstabID)
            }  
    }

    const removeHoverOpenInfoWindow = () => {
            const clearWindow = () => {
                setTimeout(function() {

                     clearPlacesWindow()
                
                }, 8000);

           
            }

           
            const clear = _.debounce(q => clearWindow(q), 1000);
            clear()
    }
   

    
    // React.useEffect(() => {
    //     setIsOpenInfoWindow(false)
    //     setIsHoverInfoWindow(false)
    // }, [activeTrip.activities])

    const handleAddActivityToTripDestinationInTrip = (lang, uuid, activeTripDestinationId, activeDayInTripDestination, establishmentID, activeTripId, last_establishmentId, tripDuration, activeDestinationId, activeTripDestinationIds) => {
        addActivityToTripDestinationInTrip(lang, uuid, activeTripDestinationId, activeDayInTripDestination, establishmentID, activeTripId, last_establishmentId, tripDuration, activeDestinationId, activeTripDestinationIds)
        document.getElementById(activeTripDay.tripDestinationId + "_" + activeTripDay.dayInTripDestination + "_" + activeTripDay.dayInTrip) !== null && document.getElementById(activeTripDay.tripDestinationId + "_" + activeTripDay.dayInTripDestination + "_" + activeTripDay.dayInTrip).scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
    return (
        <Marker

            zIndex={3}
            position={{
                lat: parseFloat(activity.activityLatitude),
                lng: parseFloat(activity.activityLongitude)
            }}
            onMouseOver={() => onHoverOpenInfoWindow()}
            // onMouseOut={() => removeHoverOpenInfoWindow()}
            onClick={() => onToggleOpenInfoWindow()}
            icon={{
                url:
                    (isOpenInfoWindow || checkWindowOpen(activity?.activityEstabID)) ?
                        index_activity < 9 ?
                            `data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 45 45">
                                <g fill-rule="evenodd">    
                                    <circle cx="22.5" cy="22.5" r="12" stroke="rgba(25, 188, 155, 0.4)" stroke-width="20" fill="rgba(25, 188, 155, 1)" />
                                    <text font-size="15" font-weight="bold" fill="white">
                                        <tspan x="19" y="27">`+ (++index_activity) + `</tspan>
                                    </text>    
                                </g>
                            </svg>`
                            :
                            `data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 45 45">
                                <g fill-rule="evenodd">    
                                    <circle cx="22.5" cy="22.5" r="12" stroke="rgba(25, 188, 155, 0.4)" stroke-width="20" fill="rgba(25, 188, 155, 1)" />
                                    <text font-size="13" font-weight="bold" fill="white">
                                        <tspan x="16" y="27">`+ (++index_activity) + `</tspan>
                                    </text>    
                                </g>
                            </svg>`
                        :
                        index_activity < 9 ?
                            `data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 45 45">
                            <g fill-rule="evenodd">    
                                <circle cx="22.5" cy="22.5" r="12" fill="rgba(25, 188, 155, 1)" />
                                <circle width="45" height="45" cx="22.5" cy="22.5" r="20" fill="none" stroke-dasharray="2, 2" stroke-width="2" stroke="rgba(25, 188, 155, 1)"/>
                                <text font-size="15" font-weight="bold" fill="white">
                                    <tspan x="19" y="27">`+ (++index_activity) + `</tspan>
                                </text>    
                            </g>
                            </svg>`
                            :
                            `data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 45 45">
                            <g fill-rule="evenodd">    
                                <circle cx="22.5" cy="22.5" r="12" fill="rgba(25, 188, 155, 1)" />
                                <circle width="45" height="45" cx="22.5" cy="22.5" r="20" fill="none" stroke-dasharray="2, 2" stroke-width="2" stroke="rgba(25, 188, 155, 1)"/>
                                <text font-size="13" font-weight="bold" fill="white">
                                    <tspan x="16" y="27">`+ (++index_activity) + `</tspan>
                                </text>    
                            </g>
                            </svg>`,
                size: { width: 50, height: 50 },
                // anchor: {x: 0, y: 0},
                scaledSize: { width: 50, height: 50 }
            }}
        >
            {(isOpenInfoWindow ||  checkWindowOpen(activity?.activityEstabID)) &&
                <InfoWindow
                    options={{ disableAutoPan: true }}
                    onCloseClick={() => { }}>
                    <div>
                        <Image className="img_fit_cover"
                            src={image_boxing(activity.image, "260", "130")}
                            alt="activity" width={260} height={130} />
                        <div style={{ display: "flex", flexWrap: "wrap", textAlign: "left", width: "260px", padding: "10px" }}>
                            <div style={{ width: "100%", }}>
                                <div style={{ display: "flex", }}>
                                    <div style={{ flexGrow: "1", }}>
                                        <EstablishmentName

                                            establishmentId={activity.activityEstabID}
                                            establishmentName={activity.activityName}
                                            titleClass={"box_2"}
                                            titleStyle={{ flexGrow: "1", cursor: 'pointer', font: "14px/12px Futura Hv BT", textAlign: "left", color: "var(--frameBlack)", padding: "0px 2px", marginRight: "10px" }}
                                        /></div>

                                    {activity.activityStarRating !== undefined && activity.activityStarRating !== "0.0" && <div className="d-flex timeline_rating_color">
                                        <div
                                            style={{
                                                font: "10px/12px Futura Md BT",
                                                color: "var(--mainGreen)",
                                            }}>{activity.activityStarRating}</div>
                                        <div style={{
                                            paddingRight: "2px",
                                        }}>
                                            <Rating name="timeline_rating" size={"small"} precision={0.5} max={1} value={parseFloat((activity.activityStarRating / 5).toFixed(1))} disabled={true} />
                                        </div>
                                    </div>}
                                </div>
                                {activity.activityDescription !== "" && <div style={{ textAlign: "left", }}>
                                    
                                    <EstablishmentDescription description={activity.activityDescription} boxDescription={"box_2"} fontDescription={"14px/15px Futura Lt BT"}
                                 bottom={'0px'} 
                                    colorDescription={"var(--mainGrey)"} height={"100px"} />
                                </div>}

                                <div style={{ display: "flex", textAlign: "left", padding: "0px 10px", width: "260px" }}>
                            <div style={{ flexGrow: "1", maxWidth:110,marginLeft:-10 }}>
                            {activity.activitySubTypes !== undefined && activity.activitySubTypes.length > 0 &&
                                    <div className="box_1">{activity.activitySubTypes.map((subtype, subtype_idx) => {
                                        return <div key={subtype_idx} style={{
                                            marginTop: "10px",
                                            font: "10px/13px Futura Md BT",
                                            color: switchFeatureContainer.color,
                                            backgroundColor: switchFeatureContainerColorLight,
                                            borderColor: switchFeatureContainerColorLight,
                                        }}
                                            className="btn_rounded"
                                        >
                                            {subtype.subtypeName}</div>
                                    })}</div>
                                }
                            </div>
                            {availableDeleteActivities.length > 0 &&
                                    <div  style={{ marginTop: "15px", cursor: "pointer",  padding: "0px 15px", display: "inline-block" }}>
                                        <AddedToTrip
                                            establishmentName={activity.activityName}
                                            establishmentStarRating={activity.activityStarRating}
                                            availableDeleteActivities={availableDeleteActivities}
                                            textFont={"10px/15px Futura Hv BT"}
                                            text={"remove from trip"}
                                        />
                                        </div>}
                        </div>

                                
                            </div>
                            {/* <div style={{ flexGrow: "1", textAlign: "right", borderLeft: "1px solid #E8E8E8" }}>
                                <ActivityStartTime activity={activity} inputStyle={{
                                    font: "11px/14px Futura Hv BT",
                                    color: "var(--frameBlack)",
                                    width: "50px",
                                    textAlign: "right",
                                    padding: "1px 0px",
                                    margin: "0px",
                                    display: "inline-block",
                                    cursor: "pointer",
                                }} />
                                <ActivityDuration activity={activity} />
                                <div style={{ marginTop: "15px" }}>
                                    {availableDeleteActivities.length > 0 &&
                                    <div  style={{ cursor: "pointer",  padding: "0px", display: "inline-block" }}>
                                        <AddedToTrip
                                            establishmentName={activity.activityName}
                                            establishmentStarRating={activity.activityStarRating}
                                            availableDeleteActivities={availableDeleteActivities}
                                            textFont={"10px/15px Futura Hv BT"}
                                            text={"remove from trip"}
                                        />
                                        </div>}
                                  
                                </div>
                            </div> */}
                        </div>
                    </div>
                </InfoWindow>}
        </Marker >
    )
}

const mapStateToProps = (state) => ({
    lang: state.Setting.lang,
    uuid_auth: state.Member.authModal.uuid,
    activeTrip: state.FormBank.TimelineWizard.activeTrip,
    activeTripDestinationId: state.FormBank.TimelineWizard.activeTripDestinationId,
    activeDestinationId: state.FormBank.TimelineWizard.activeDestinationId,
    activeTripDay: state.FormBank.TimelineWizard.activeDay,
    activeTripActivities: state.FormBank.TimelineWizard.activeTrip.activities,
    switchFeatureContainer: state.FormBank.FeatureFormat,


})

const mapDispatchToProps = {
    addActivityToTripDestinationInTrip: Actions.addActivityToTripDestinationInTrip,
    deleteActivityToDayOfTripDestinationInTrip: Actions.deleteActivityToDayOfTripDestinationInTrip,

}

const ItineraryMarkers = connect(mapStateToProps, mapDispatchToProps)(ItineraryMarkers_)
export default ItineraryMarkers
