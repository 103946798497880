import * as Actions from '../../../redux/actions'

import { AngleUpIcon, MinusSignIcon, PlusSignIcon } from '../Common/SVGBank'
import React, { Component } from 'react'
import { image_boxing, list_container_mode } from '../Common/RegExValidate'

import Fab from '@material-ui/core/Fab'
import { Heart } from '../Common/MemberActions/Heart'
import {
    Image,
} from 'react-bootstrap'
import LazyLoadingScrollEventListener from './LazyLoadingScrollEventListener'
import { Plus } from '../Common/MemberActions/Plus'
import Rating from '@material-ui/lab/Rating'
import { ScrollTopLazyLoading } from "../Common/ScrollTopLazyLoading"
import { Star } from '../Common/MemberActions/Star'
import { TripDescription } from '../Common/Trip/TripDescription'
import TripName from '../Common/Trip/TripName'
import { connect } from 'react-redux'
import { member_area_router } from "../Common/RegExValidate"
import ActionsEllipsis  from '../Common/actionsEllipsis';
import { Done } from '../Common/MemberActions/Done';

class Trips_ extends Component {

    onDragOverTrip = (event) => {
        event.preventDefault()
        event.stopPropagation()
    }

    onDropDeleteActivities = (event) => {
        this.props.draggingActivity !== undefined &&
            this.props.draggingActivity.dragDeleteActivity !== undefined &&
            this.props.draggingActivity.dragDeleteActivity !== null &&
            this.props.deleteActivityToDayOfTripDestinationInTrip(
                this.props.draggingActivity.dragDeleteActivity.lang,
                this.props.draggingActivity.dragDeleteActivity.uuid,
                this.props.draggingActivity.dragDeleteActivity.tripDestinationId,
                this.props.draggingActivity.dragDeleteActivity.activityID,
                this.props.draggingActivity.dragDeleteActivity.tripId)
    }
   
    copyTripToActiveTrip = (lang, uuid, tripID, tripIdFrom) => {
        const {copyTripDestinationsToTrip} = this.props 
        //console.log(`Trips: copyTripToActiveTrip active tripID = ${tripID} from an trip of tripId ${tripIdFrom}`)
        //console.log (`lang: ${lang} uuid = ${uuid}`)
        copyTripDestinationsToTrip (lang, uuid, tripID, tripIdFrom) 
    }

    render() {
        const { trips, switch_container, lang, uuid_auth, activeTrip, timelineWizardStatus, /*activeTripDestinationId, activeTripDay, */bodyClientWidth, isMobile, destinaionListDisplayFormat, switchFeatureContainer, selectedSubtypes, lazyLoading, activeTripActivities } = this.props

        let widthContainer = ""
        let heightContainer = ""
        let widthGridContainer = ""
        let heightGridContainer = ""
        let postionContainer = ""
        let positionScrollToTopLazyLoading = ""
        const uuid = uuid_auth !== "" ? uuid_auth : "TMPUUID"
        if (!isNaN(isMobile)) {
            widthContainer = isMobile ? (bodyClientWidth * 0.98) : (timelineWizardStatus ? ((bodyClientWidth - 380) * 0.65 / 2) : (bodyClientWidth * 0.65 / 2))
            heightContainer = isMobile ? "" : (timelineWizardStatus ? ((bodyClientWidth - 380) * 0.65 / 2 / 2) : (bodyClientWidth * 0.65 / 2 / 2))
            postionContainer = isMobile ? "block" : "d-flex"
            widthGridContainer = isMobile ? (bodyClientWidth * 0.98 - 5) : (timelineWizardStatus ? (Math.floor(bodyClientWidth * 0.535 / 3 - 8)) : (Math.floor(bodyClientWidth * 0.7 / 3 - 8)))
            heightGridContainer = Math.floor(widthGridContainer * 215 / 375)
            positionScrollToTopLazyLoading = isMobile ? "" : (timelineWizardStatus ? "10%" : "10%")
        }

        //console.log("activeTrip : ")
        //console.log(activeTrip)

        return (
            <React.Fragment>

                { switch_container === member_area_router.memberAreaTrip ?

                    <div
                        style={{
                            zIndex: "1",
                        }}>
                        <div id="back_to_top_trip"></div>
                        {trips !== undefined && trips.length > 0
                            && trips.map((trip, trip_idx) => {
                                return (
                                    <React.Fragment key={trip_idx}>
                                        <div
                                            onDragOver={(e) => this.onDragOverTrip(e)}
                                            onDrop={(e) => this.onDropDeleteActivities(e)}

                                            className={destinaionListDisplayFormat === list_container_mode.grid ? "d-inline-block" : ""}>
                                            {destinaionListDisplayFormat === list_container_mode.list &&
                                                <div>
                                                    <div className={timelineWizardStatus ? "margin_top_10" : "margin_top_20"}>
                                                        <div className={postionContainer}>
                                                            <div className="d-inline-block" style={{ width: widthContainer }}><Image
                                                                src={image_boxing(trip.tripPicture, widthContainer, heightContainer)}
                                                                alt="trip" /></div>
                                                            <div style={{ width: widthContainer + "px", minHeight: heightContainer + "px", border: "1px solid #E8E8E8", verticalAlign: "middle", flexGrow: "1", paddingLeft: "10px", position: "relative" }}>

                                                                <div style={{ position: "relative", height: "25px" }} className={timelineWizardStatus ? "margin_top_10 box_1 padding_left_10 padding_right_20 text-right" : "margin_top_20 box_1 padding_left_10 padding_right_20 text-right"} >
                                                                  
                                                                <div style={{ position: "absolute", top: "0px", right: "140px" }}>
                                                                        <Done
                                                                               unitID={trip.tripID}
                                                                            feature={switchFeatureContainer}
                                                                            fill={"var(--mainGreen)"} />
                                                                    </div>
                                                                    
                                                                    <div style={{ position: "absolute", top: "0px", right: "100px" }}>
                                                                        <Heart
                                                                            unitID={trip.tripID}
                                                                            feature={switchFeatureContainer}
                                                                            fill={"var(--mainGreen)"} />
                                                                    </div>
                                                                    <div style={{ position: "absolute", top: "0px", right: "60px" }}>
                                                                        <Plus
                                                                            unitID={trip.tripID}
                                                                            feature={switchFeatureContainer}
                                                                            fill={"var(--mainGreen)"} />
                                                                    </div>
                                                                    <div style={{ position: "absolute", top: "0px", right: "20px" }}>
                                                                        <Star
                                                                            unitID={trip.tripID}
                                                                            feature={switchFeatureContainer}
                                                                            fill={"var(--mainGreen)"} />
                                                                    </div>
                                                                </div>

                                                                {trip.destinations !== undefined && trip.destinations.length > 0 && <div style={{ display: "flex" }}>
                                                                    {trip.destinations.map((destination, destination_index) => {
                                                                        return (
                                                                            <React.Fragment key={destination_index} >
                                                                                <div className="box_1 padding_left_10 padding_right_10" style={{
                                                                                    font: "14px/16px Futura Md BT",
                                                                                    color: switchFeatureContainer.color,
                                                                                }}>
                                                                                    {destination.name}
                                                                                </div>
                                                                                {destination_index < trip.destinations.length - 1 && <MinusSignIcon width={6} fill={switchFeatureContainer.color} />}
                                                                            </React.Fragment>
                                                                        )
                                                                    })}
                                                                </div>}
                                                                <TripName
                                                                    tripName={trip.tripName}
                                                                    tripId={trip.tripID}
                                                                    titleClass={"box_1"} titleStyle={{
                                                                        font: timelineWizardStatus ? "22px/33px Futura Hv BT" : "28px/43px Futura Hv BT",
                                                                        color: "#FFFFFF",
                                                                        cursor: "pointer",
                                                                        paddingLeft: "10px",
                                                                        paddingRight: "10px",
                                                                    }} />
                                                                {trip.starsCount !== undefined
                                                                    && trip.starsCount !== null
                                                                    && trip.starsCount !== "0.0"
                                                                    && trip.starsCount !== "0"
                                                                    && <div className="d-flex timeline_rating_color padding_left_10 padding_right_10 btn_rounded">
                                                                        <Rating name="timeline_rating" precision={0.5} max={5} value={parseFloat(trip.starsCount)} disabled={true} />
                                                                        <div className="futura_md_bt_11_14_black margin_bottom_5 margin_left_5">({trip.starsCount})</div>
                                                                    </div>}
                                                                {trip.tripDescription !== undefined && <div className={"padding_left_10 padding_right_10"}><TripDescription description={trip.tripDescription} boxDescription={"box_3"} fontDescription={"14px/19px Futura Lt BT"} colorDescription={"var(--mainGrey)"} height={"57px"} /></div>}
                                                                {<div style={{ textAlign: "right" }}>
                                                                    <div className={timelineWizardStatus ? "padding_left_10 padding_right_10 text-left box_1 margin_top_5 margin_bottom_5" : "padding_left_10 padding_right_10 text-left box_1 margin_top_20 margin_bottom_10"}>
                                                                        {trip.subtypes !== undefined && trip.subtypes.length > 0 &&
                                                                            trip.subtypes.map((subtype_element, subtype_idx) => {
                                                                                return <div key={subtype_idx}
                                                                                    className="btn_rounded m-1 futura_md_bt_14_16_green upcoming_trip_style"
                                                                                    style={{ cursor: "pointer" }}
                                                                                // onClick={() => {
                                                                                //     selectedSubtypes.find(val => val.id === subtype_element.subTypeID) ?
                                                                                //         deleteSelectedSubTypeDestination({ id: subtype_element.subTypeID, title: subtype_element.subTypeName }, lazyLoading, selectedSubtypes)
                                                                                //         : addSelectedSubTypeDestination({ id: subtype_element.subTypeID, title: subtype_element.subTypeName }, lazyLoading, selectedSubtypes)
                                                                                // }}
                                                                                >
                                                                                    {subtype_element.subTypeName}</div>
                                                                            })
                                                                        }
                                                                    </div>
                                                                    {timelineWizardStatus &&
                                                                        <div onClick={() => this.copyTripToActiveTrip(lang, uuid, activeTrip.tripId, trip.tripId)} style={{ cursor: "pointer", color: switchFeatureContainer.color, font: "14px/14px Futura Hv BT", padding: "0px 10px", display: "inline-block" }}><PlusSignIcon width={10} height={13} fill={switchFeatureContainer.color} style={{ marginRight: "5px" }} />Merge to trip</div>}
                                                                </div>}

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            {destinaionListDisplayFormat === list_container_mode.grid &&
                                                <div style={{
                                                    paddingLeft: "2px",
                                                    paddingRight: "2px",
                                                    paddingBottom: "50px",
                                                    paddingTop: "15px",
                                                    position: "relative",
                                                }}>
                                                    <div className="d-inline-block"><Image
                                                        src={image_boxing(trip.tripPicture, widthGridContainer, heightGridContainer)}
                                                        alt="trip" /></div>

                                                    <div className=" rating_color pt-2 pl-2" style={{ width: widthGridContainer + "px", border: "1px solid #E8E8E8" }}>
                                                        <div className="d-flex flex-wrap " style={{ height: "25px" }} >
                                                            {trip.destinations !== undefined && trip.destinations.length > 0 && <div style={{ display: "flex", flexGrow: "1" }}>
                                                                {trip.destinations.map((destination, destination_index) => {
                                                                    return (
                                                                        <React.Fragment key={destination_index} >
                                                                            <div className="box_1" style={{
                                                                                font: "14px/16px Futura Md BT",
                                                                                color: switchFeatureContainer.color,
                                                                            }}>
                                                                                {destination.name}
                                                                            </div>
                                                                            {destination_index < trip.destinations.length - 1 && <MinusSignIcon width={6} fill={switchFeatureContainer.color} />}
                                                                        </React.Fragment>
                                                                    )
                                                                })}
                                                            </div>}
                                                            {trip.starsCount !== undefined
                                                                && trip.starsCount !== null
                                                                && trip.starsCount !== "0.0"
                                                                && trip.starsCount !== "0"
                                                                && <div className="d-flex timeline_rating_color padding_left_10 padding_right_10">
                                                                    <Rating name="timeline_rating" precision={0.5} max={1} value={parseFloat(trip.starsCount / 5)} size={"small"} disabled={true} />
                                                                    <div className="futura_md_bt_11_14_black margin_bottom_5 margin_left_5">({trip.starsCount})</div>
                                                                </div>}
                                                        </div>
                                                        <TripName
                                                            tripName={trip.tripName}
                                                            tripId={trip.tripID}
                                                            divClass={"flex_1"}
                                                            titleClass={"box_1"} titleStyle={{
                                                                font: timelineWizardStatus ? "20px/33px Futura Hv BT" : "28px/43px Futura Hv BT",
                                                                color: "#FFFFFF",
                                                                cursor: "pointer",
                                                                paddingRight: "10px",
                                                            }} />
                                                        {/* {establishment.subTypes !== undefined && establishment.subTypes.length > 0
                                                    && establishment.subTypes[0] !== undefined
                                                    && establishment.subTypes[0].subTypeName !== undefined &&
                                                    <div className="btn_rounded m-1 futura_md_bt_14_16_green upcoming_trip_style" style={{ height: "26px" }}>{establishment.subTypes[0].subTypeName}</div>
                                                } */}
                                                        <div style={{
                                                            height: "60px",
                                                            position: "relative",
                                                        }}>
                                                            {trip.tripDescription !== undefined &&
                                                                <div style={{ position: "absolute" }}>
                                                                    <TripDescription description={trip.tripDescription} boxDescription={"box_3"} fontDescription={"14px/19px Futura Lt BT"} colorDescription={"var(--mainGrey)"} height={"57px"} />
                                                                </div>}
                                                        </div>
                                                        {timelineWizardStatus &&
                                                            <div onClick={() => this.copyTripToActiveTrip(lang, uuid, activeTrip.tripId, trip.tripId)} style={{ cursor: "pointer", color: switchFeatureContainer.color, font: "14px/14px Futura Hv BT", padding: "5px 10px", textAlign: "right", }}><PlusSignIcon width={10} height={13} fill={switchFeatureContainer.color} style={{ marginRight: "5px" }} />Merge to trip</div>}

                                                    </div>
                                                  
                                                    <div style={{ position: "absolute", top: "15px"}}>
                                                    <ActionsEllipsis id={trip.tripID} switchFeatureContainer={switchFeatureContainer}/>
                                                    </div>
                                                   
                                                </div>

                                            }
                                        </div>
                                    </React.Fragment>
                                )
                            })
                        }
                        <ScrollTopLazyLoading {...this.props} targetId={"#back_to_top_trip"} rightPosition={positionScrollToTopLazyLoading}>
                            <Fab size="small" aria-label="scroll back to top" style={{
                                outline: "0px",
                                backgroundColor: switchFeatureContainer.color
                            }}>
                                <AngleUpIcon fill={"var(--mainWhite)"} style={{ marginLeft: "3px" }} />
                            </Fab>
                        </ScrollTopLazyLoading>
                        <LazyLoadingScrollEventListener />
                    </div >
                    :

                    <div
                        style={{
                            zIndex: "1",
                        }}>
                        <div id="back_to_top_trip"></div>
                        {trips !== undefined && trips.length > 0
                            && trips.map((trip, trip_idx) => {
                                return (
                                    <React.Fragment key={trip_idx}>
                                        <div
                                            onDragOver={(e) => this.onDragOverTrip(e)}
                                            onDrop={(e) => this.onDropDeleteActivities(e)}

                                            className={destinaionListDisplayFormat === list_container_mode.grid ? "d-inline-block" : ""}>
                                            {destinaionListDisplayFormat === list_container_mode.list &&
                                                <div>
                                                    <div className={timelineWizardStatus ? "margin_top_10" : "margin_top_20"}>
                                                        <div className={postionContainer}>
                                                            <div className="d-inline-block" style={{ width: widthContainer }}><Image
                                                                src={image_boxing(trip.tripPicture, widthContainer, heightContainer)}
                                                                alt="trip" /></div>
                                                            <div style={{ width: widthContainer + "px", minHeight: heightContainer + "px", border: "1px solid #E8E8E8", verticalAlign: "middle", flexGrow: "1", paddingLeft: "10px", position: "relative" }}>

                                                                <div style={{ position: "relative", height: "25px" }} className={timelineWizardStatus ? "margin_top_10 box_1 padding_left_10 padding_right_20 text-right" : "margin_top_20 box_1 padding_left_10 padding_right_20 text-right"} >
                                                                   
                                                                <div style={{ position: "absolute", top: "0px", right: "140px" }}>
                                                                        <Done
                                                                               unitID={trip.tripID}
                                                                            feature={switchFeatureContainer}
                                                                            fill={"var(--mainGreen)"} />
                                                                    </div>
                                                                    <div style={{ position: "absolute", top: "0px", right: "100px" }}>
                                                                        <Heart
                                                                            unitID={trip.tripID}
                                                                            feature={switchFeatureContainer}
                                                                            fill={"var(--mainGreen)"} />
                                                                    </div>
                                                                    <div style={{ position: "absolute", top: "0px", right: "60px" }}>
                                                                        <Plus
                                                                            unitID={trip.tripID}
                                                                            feature={switchFeatureContainer}
                                                                            fill={"var(--mainGreen)"} />
                                                                    </div>
                                                                    <div style={{ position: "absolute", top: "0px", right: "20px" }}>
                                                                        <Star
                                                                            unitID={trip.tripID}
                                                                            feature={switchFeatureContainer}
                                                                            fill={"var(--mainGreen)"} />
                                                                    </div>
                                                                </div>

                                                                {trip.destinations !== undefined && trip.destinations.length > 0 && <div style={{ display: "flex" }}>
                                                                    {trip.destinations.map((destination, destination_index) => {
                                                                        return (
                                                                            <React.Fragment key={destination_index} >
                                                                                <div className="box_1 padding_left_10 padding_right_10" style={{
                                                                                    font: "14px/16px Futura Md BT",
                                                                                    color: switchFeatureContainer.color,
                                                                                }}>
                                                                                    {destination.name}
                                                                                </div>
                                                                                {destination_index < trip.destinations.length - 1 && <MinusSignIcon width={6} fill={switchFeatureContainer.color} />}
                                                                            </React.Fragment>
                                                                        )
                                                                    })}
                                                                </div>}
                                                                <TripName
                                                                    tripName={trip.tripName}
                                                                    tripId={trip.tripID}
                                                                    titleClass={"box_1"} titleStyle={{
                                                                        font: timelineWizardStatus ? "22px/33px Futura Hv BT" : "28px/43px Futura Hv BT",
                                                                        color: "var(--frameBlack)",
                                                                        cursor: "pointer",
                                                                        paddingLeft: "10px",
                                                                        paddingRight: "10px",
                                                                    }} />
                                                                {trip.starsCount !== undefined
                                                                    && trip.starsCount !== null
                                                                    && trip.starsCount !== "0.0"
                                                                    && trip.starsCount !== "0"
                                                                    && <div className="d-flex timeline_rating_color padding_left_10 padding_right_10 btn_rounded">
                                                                        <Rating name="timeline_rating" precision={0.5} max={5} value={parseFloat(trip.starsCount)} disabled={true} />
                                                                        <div className="futura_md_bt_11_14_black margin_bottom_5 margin_left_5">({trip.starsCount})</div>
                                                                    </div>}
                                                                {trip.tripDescription !== undefined && <div className={"padding_left_10 padding_right_10"}><TripDescription description={trip.tripDescription} boxDescription={"box_3"} fontDescription={"14px/19px Futura Lt BT"} colorDescription={"var(--mainGrey)"} height={"57px"} /></div>}
                                                                {<div style={{ textAlign: "right" }}>
                                                                    <div className={timelineWizardStatus ? "padding_left_10 padding_right_10 text-left box_1 margin_top_5 margin_bottom_5" : "padding_left_10 padding_right_10 text-left box_1 margin_top_20 margin_bottom_10"}>
                                                                        {trip.subtypes !== undefined && trip.subtypes.length > 0 &&
                                                                            trip.subtypes.map((subtype_element, subtype_idx) => {
                                                                                return <div key={subtype_idx}
                                                                                    className="btn_rounded m-1 futura_md_bt_14_16_green upcoming_trip_style"
                                                                                    style={{ cursor: "pointer" }}
                                                                                // onClick={() => {
                                                                                //     selectedSubtypes.find(val => val.id === subtype_element.subTypeID) ?
                                                                                //         deleteSelectedSubTypeDestination({ id: subtype_element.subTypeID, title: subtype_element.subTypeName }, lazyLoading, selectedSubtypes)
                                                                                //         : addSelectedSubTypeDestination({ id: subtype_element.subTypeID, title: subtype_element.subTypeName }, lazyLoading, selectedSubtypes)
                                                                                // }}
                                                                                >
                                                                                    {subtype_element.subTypeName}</div>
                                                                            })
                                                                        }
                                                                    </div>
                                                                    {timelineWizardStatus &&
                                                                        <div onClick={() => this.copyTripToActiveTrip(lang, uuid, activeTrip.tripId, trip.tripID) } 
                                                                             style={{ cursor: "pointer", color: switchFeatureContainer.color, font: "14px/14px Futura Hv BT", padding: "0px 10px", display: "inline-block" }}>
                                                                                 <PlusSignIcon width={10} height={13} fill={switchFeatureContainer.color} style={{ marginRight: "5px" }} />
                                                                                 Merge to trip
                                                                        </div>}
                                                                </div>}

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            {destinaionListDisplayFormat === list_container_mode.grid &&
                                                <div style={{
                                                    paddingLeft: "2px",
                                                    paddingRight: "2px",
                                                    paddingBottom: "50px",
                                                    paddingTop: "15px",
                                                    position: "relative",
                                                }}>
                                                    <div className="d-inline-block"><Image
                                                        src={image_boxing(trip.tripPicture, widthGridContainer, heightGridContainer)}
                                                        alt="trip" /></div>

                                                    <div className=" rating_color pt-2 pl-2" style={{ width: widthGridContainer + "px", border: "1px solid #E8E8E8" }}>
                                                        <div className="d-flex flex-wrap " style={{ height: "25px" }} >
                                                            {trip.destinations !== undefined && trip.destinations.length > 0 && <div style={{ display: "flex", flexGrow: "1" }}>
                                                                {trip.destinations.map((destination, destination_index) => {
                                                                    return (
                                                                        <React.Fragment key={destination_index} >
                                                                            <div className="box_1" style={{
                                                                                font: "14px/16px Futura Md BT",
                                                                                color: switchFeatureContainer.color,
                                                                            }}>
                                                                                {destination.name}
                                                                            </div>
                                                                            {destination_index < trip.destinations.length - 1 && <MinusSignIcon width={6} fill={switchFeatureContainer.color} />}
                                                                        </React.Fragment>
                                                                    )
                                                                })}
                                                            </div>}
                                                            {trip.starsCount !== undefined
                                                                && trip.starsCount !== null
                                                                && trip.starsCount !== "0.0"
                                                                && trip.starsCount !== "0"
                                                                && <div className="d-flex timeline_rating_color padding_left_10 padding_right_10">
                                                                    <Rating name="timeline_rating" precision={0.5} max={1} value={parseFloat(trip.starsCount / 5)} size={"small"} disabled={true} />
                                                                    <div className="futura_md_bt_11_14_black margin_bottom_5 margin_left_5">({trip.starsCount})</div>
                                                                </div>}
                                                        </div>
                                                        <TripName
                                                            tripName={trip.tripName}
                                                            tripId={trip.tripID}
                                                            divClass={"flex_1"}
                                                            titleClass={"box_1"} titleStyle={{
                                                                font: timelineWizardStatus ? "20px/33px Futura Hv BT" : "28px/43px Futura Hv BT",
                                                                color: "var(--frameBlack)",
                                                                cursor: "pointer",
                                                                paddingRight: "10px",
                                                            }} />
                                                        {/* {establishment.subTypes !== undefined && establishment.subTypes.length > 0
                                                    && establishment.subTypes[0] !== undefined
                                                    && establishment.subTypes[0].subTypeName !== undefined &&
                                                    <div className="btn_rounded m-1 futura_md_bt_14_16_green upcoming_trip_style" style={{ height: "26px" }}>{establishment.subTypes[0].subTypeName}</div>
                                                } */}
                                                        <div style={{
                                                            height: "60px",
                                                            position: "relative",
                                                        }}>
                                                            {trip.tripDescription !== undefined &&
                                                                <div style={{ position: "absolute" }}>
                                                                    <TripDescription description={trip.tripDescription} boxDescription={"box_3"} fontDescription={"14px/19px Futura Lt BT"} colorDescription={"var(--mainGrey)"} height={"57px"} />
                                                                </div>}
                                                        </div>
                                                        {timelineWizardStatus &&
                                                            <div onClick={() => this.copyTripToActiveTrip(lang, uuid, activeTrip.tripId, trip.tripId)} style={{ cursor: "pointer", color: switchFeatureContainer.color, font: "14px/14px Futura Hv BT", padding: "5px 10px", textAlign: "right", }}><PlusSignIcon width={10} height={13} fill={switchFeatureContainer.color} style={{ marginRight: "5px" }} />Merge to trip</div>}

                                                    </div>
                                                    <div style={{ position: "absolute", top: "15px"}}>
                                                    <ActionsEllipsis id={trip.tripID} switchFeatureContainer={switchFeatureContainer}/>
                                                    </div>
                                                </div>

                                            }
                                        </div>
                                    </React.Fragment>
                                )
                            })
                        }
                        <ScrollTopLazyLoading {...this.props} targetId={"#back_to_top_trip"} rightPosition={positionScrollToTopLazyLoading}>
                            <Fab size="small" aria-label="scroll back to top" style={{
                                outline: "0px",
                                backgroundColor: switchFeatureContainer.color
                            }}>
                                <AngleUpIcon fill={"var(--mainWhite)"} style={{ marginLeft: "3px" }} />
                            </Fab>
                        </ScrollTopLazyLoading>
                        <LazyLoadingScrollEventListener />
                    </div >}
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    destinaionListDisplayFormat: state.Destination.destination.destinaionListDisplayFormat,
    selectedSubtypes: state.Destination.destination.selectedSubtypes,
    lazyLoading: state.Destination.destination.lazyLoading,
    trips: state.Destination.destination.resultSetsTrips,
    switchFeatureContainer: state.FormBank.FeatureFormat,
    isMobile: state.Setting.is_mobile,
    bodyClientWidth: state.Setting.htmlBody.bodyClientWidth,

    timelineWizardStatus: state.FormBank.TimelineWizard.status,
    draggingActivity: state.FormBank.TimelineWizard.draggingActivity,
    activeTripActivities: state.FormBank.TimelineWizard.activeTrip.activities,
    lang: state.Setting.lang,
    uuid_auth: state.Member.authModal.uuid,
    activeTrip: state.FormBank.TimelineWizard.activeTrip,
    activeTripDestinationId: state.FormBank.TimelineWizard.activeTripDestinationId,
    activeTripDay: state.FormBank.TimelineWizard.activeDay,

})

const mapDispatchToProps = {
    setScreenVertical: Actions.setScreenVertical,
    fetchEstablishmentsLazyloading: Actions.fetchEstablishmentsLazyloading,
    setPageYOffset: Actions.setPageYOffset,
    deleteActivityToDayOfTripDestinationInTrip: Actions.deleteActivityToDayOfTripDestinationInTrip,
    copyTripDestinationsToTrip: Actions.copyTripDestinationsToTrip
}

const Trips = connect(mapStateToProps, mapDispatchToProps)(Trips_)
export default Trips
