import { FormControl, FormControlLabel, InputLabel, makeStyles, Radio, Select, TextField, withStyles } from '@material-ui/core'
import React from 'react'
import { DurationUseStyles } from '../../FeaturePage/personalizedTrip/personalizedTripStyles'
import { flightTextField } from '../flightStyles'
import { countriesList } from './DataBank';
import moment from 'moment'
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker, DatePicker
} from '@material-ui/pickers';
import { LeftArrowIcon, RightArrowIcon } from '../../Common/SVGBank';

const useStyles = makeStyles({
    root: {
        color: props => props.defaultColor,
        '&$checked': {
            color: '#19BC9B',
        },
        font: '20px/20px Futura Hv BT',
    },
})

const GreenRadio = (props) => {
    const classes = useStyles(props);
    return <Radio color="default" {...props} className={classes.root} />
}

export const PassengerInfo = ({ setPassengerList, passengerList, index, passenger, setContactInfo, nextStep, previousStep }) => {
    const durationClass = DurationUseStyles()

    const passengerValue = { ...passenger };
    let list = [...passengerList]

    const updateValue = (field, value) => {
        setValues(field, value)
    };

    const setValues = (field, value) => {
        passengerValue[field] = value;
        list[index] = passengerValue;
        setPassengerList(list);

        if (index === 0 && passengerValue) {
            const contact = {
                contact_name: passengerValue?.last_name + ' ' + passengerValue?.first_name,
            }
            setContactInfo(contact)
        }
    }

    const classesAutocomplete = flightTextField()

    const isDisabled = () => {
        const disabled = passenger.gender && passenger.last_name && passenger.first_name && passenger.birthday;
        return disabled ? false : true;
    }


    const isLast = passengerList ? passengerList.length - 1 === index : false;


    var windowWidth = window.screen.width < window.outerWidth ?
        window.screen.width : window.outerWidth;
    var mobile = windowWidth < 500;


    return (
        <div>
            {passengerList && passengerList.length > 1 && <p style={{ font: '20px/20px Futura Hv BT', marginTop: 30 }}> Passenger {index + 1} </p>}


            {/* <div className="row">
                <div className='col-12' style={{
                    marginTop: "20px",
                }}>

                    <FormControl className={durationClass.formControl} style={{ width: '100%' }}>
                        <InputLabel htmlFor="outlined-age-native-simple">
                            Select a passenger from saved passenger list
                        </InputLabel>
                        <Select
                            native
                            value={state.age}
                            onChange={handleChange}
                            inputProps={{
                                name: 'passenger',
                                id: 'outlined-age-native-simple',
                            }}
                        >
                            <option aria-label="None" value="" />

                        </Select>
                    </FormControl>


                </div>
            </div> */}

            <div className="row">

                <div className='col-12'>
                    <p style={{ font: '20px/20px Futura Hv BT', marginTop: 10 }}> Gender</p>

                    <FormControlLabel checked={passenger?.gender === 'M'}
                        onChange={(event) => updateValue('gender', event.target.value)}
                        value="M" control={<GreenRadio defaultColor={!passenger?.gender ? 'red' : '#19BC9B'} />} label="Male" />

                    <FormControlLabel checked={passenger?.gender === 'F'}
                        onChange={(event) => updateValue('gender', event.target.value)}
                        value="F" control={<GreenRadio defaultColor={!passenger?.gender ? 'red' : '#19BC9B'} />} label="Female" />

                </div>
            </div>

            <div className="row">
                <div className={mobile ? 'col-12' : 'col-6'} style={{
                    marginTop: "20px", paddingRight: 0
                }}>

                    <div className={classesAutocomplete.root} style={{ border: !passenger.last_name ? "1px solid red" : "" }}>
                        <TextField
                            label='Last Name'
                            defaultValue={passenger.last_name}
                            required={true}
                            id={'Last Name' + index}
                            onChange={(event) => updateValue('last_name', event.target.value)}
                        />
                    </div>
                </div>
                <div className={mobile ? 'col-12' : 'col-6'} style={{
                    marginTop: "20px",
                }}>

                    <div className={classesAutocomplete.root} style={{ border: !passenger.first_name ? "1px solid red" : "" }}>
                        <TextField
                            label='First Name'
                            defaultValue={passenger.first_name}
                            required={true}
                            id={'First Name' + index}
                            onChange={(event) => updateValue('first_name', event.target.value)}
                        />
                    </div>
                </div>
            </div>


            <div className="row">
                <div className={mobile ? 'col-12' : 'col-6'} style={{
                    marginTop: "20px", paddingRight: 0
                }}>

                    <div className={classesAutocomplete.root} style={{ border: !passenger.birthday ? "1px solid red" : "" }}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                clearable
                                autoOk
                                variant="inline"
                                format="yyyy-MM-dd"
                                id={'Date of Birth' + index}
                                label='Date of Birth'
                                minDate={moment(new Date()).subtract(150, 'years').format('YYYY-MM-DD')}
                                maxDate={moment(new Date()).subtract(1, 'months').format('YYYY-MM-DD')}
                                value={passenger.birthday ? moment(passenger.birthday) : moment(new Date()).subtract(25, 'years').format('YYYY-MM-DD')}
                                onChange={(date) => {
                                    const saved = moment(date).format('YYYY-MM-DD');
                                    if (saved) {
                                        updateValue('birthday', saved)
                                    }

                                }}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                            {/* <TextField
                            id={'Date of Birth' + index}
                            label='Date of Birth'
                            type="date"
                            error={!passenger.birthday}
                            defaultValue={passenger.birthday || moment(new Date()).subtract(25, 'years').format('YYYY-MM-DD')}
                            required={true}
                            onChange={(event) => {updateValue('birthday', event.target.value)}}
                            inputProps={{
                                max: moment(new Date()).subtract(1, 'months').format('YYYY-MM-DD'),
                                min: moment(new Date()).subtract(150, 'years').format('YYYY-MM-DD')
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        /> */}
                        </MuiPickersUtilsProvider>
                    </div>
                </div>
                <div className={mobile ? 'col-12' : 'col-6'} style={{
                    marginTop: "20px",
                }}>

                    <FormControl className={durationClass.formControl} style={{ width: '100%' }}>
                        <InputLabel htmlFor="Citizenship">
                            Citizenship
                        </InputLabel>
                        <Select
                            native
                            defaultValue={passenger.country_code}
                            required={true}
                            onChange={(event) => updateValue('country_code', event.target.value)}
                            inputProps={{
                                name: 'Citizenship',
                                id: 'Citizenship' + index,
                            }}
                        >
                            <option aria-label="None" value="" />
                            {countriesList.map((val, index) => {
                                return (<option key={index} value={val.code}>{val.name}</option>)
                            })}
                        </Select>
                    </FormControl>
                </div>
            </div>

            <div className="row">
                <div className={mobile ? 'col-12' : 'col-6'} style={{
                    marginTop: "20px", paddingRight: 0
                }}>

                    <FormControl className={durationClass.formControl} style={{ width: '100%' }}>
                        <InputLabel htmlFor="idType">
                            Id Type
                        </InputLabel>
                        <Select
                            native
                            defaultValue={passenger.id_type}
                            required={true}
                            onChange={(event) => updateValue('id_type', event.target.value)}
                            inputProps={{
                                name: 'idType',
                                id: 'idType' + index,
                            }}
                        >
                            <option aria-label="None" value="" />
                            <option value={'Passport'}>Passport</option>
                            <option value={'National Identity Card'}>National Identity Card</option>
                            <option value={"Driver's Licence"}>Driver's Licence </option>

                        </Select>
                    </FormControl>
                </div>
                <div className={mobile ? 'col-12' : 'col-6'} style={{
                    marginTop: "20px",
                }}>

                    <div className={classesAutocomplete.root}>
                        <TextField
                            id={'Id Expiration Date' + index}
                            label='Id Expiration Date'
                            type="date"
                            defaultValue={passenger.id_expire_date || moment(new Date()).add(1, 'years').format('YYYY-MM-DD')}
                            onChange={(event) => updateValue('id_expire_date', event.target.value)}
                            inputProps={{ min: moment(new Date()).format('YYYY-MM-DD') }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </div>
                </div>
            </div>

            <div className="row">
                <div className={mobile ? 'col-12' : 'col-6'} style={{
                    marginTop: "20px", paddingRight: 0
                }}>

                    <div className={classesAutocomplete.root}>
                        <TextField
                            label='Id Number'
                            defaultValue={passenger.id_number}
                            id={'Id Number' + index}
                            onChange={(event) => updateValue('id_number', event.target.value)}
                        />
                    </div>
                </div>
                {/* <div className='col-6' style={{
                    marginTop: "20px",
                }}>

                    <FormControl className={durationClass.formControl} style={{ width: '100%' }}>
                        <InputLabel htmlFor="Education">
                            Education
                        </InputLabel>
                        <Select
                            native
                            value={passenger.education}
                            onChange={(event)=> updateValue('education',event.target.value)}
                            inputProps={{
                                name: 'Education',
                                id: 'Education' + index,
                            }}
                        >
                            <option aria-label="None" value="" />

                        </Select>
                    </FormControl>
                </div> */}
            </div>

            <div style={{ padding: '16px 35px', marginTop: 30, marginBottom: 30, background: '#19BC9B33', color: '#19BC9B', font: '14px/14px Futura Lt BT' }}>
                Note: please ensure the ID is vaild for at least 6 months after the date of departure
                </div>


            {isLast && <div>
                <div style={{ marginBottom: 20 }}>
                    <button className='recapButtons' style={{ background: mobile ? 'transparent' : '#DFDFDF' }} onClick={previousStep}>
                        <LeftArrowIcon fill={'#7F7F7F'} height={14} width={14} style={{ marginRight: 10 }} />  Back to booking
                    </button>


                    <div style={{ float: "right" }} className="nextBtn">
                        <button disabled={isDisabled()} onClick={nextStep} className='recapButtons' style={{
                            opacity: isDisabled() ? 0.4 : 1, background: '#19BC9B',
                            color: 'white', font: '14px/14px Futura Hv BT',
                        }} >
                            Next step  <RightArrowIcon height={14} width={14} style={{ marginLeft: 90 }} />
                        </button>
                    </div>

                </div>

            </div>}



        </div>
    )
}