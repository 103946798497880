import * as Actions from '../../../../redux/actions'

import {
    LeftArrowIcon,
    RightArrowIcon,
} from '../SVGBank';
import React, { Component } from 'react'

import CheckBox from "../CheckBox"
import ImageCheckBox from "../ImageCheckBox"
import { connect } from 'react-redux'
import { landing_router } from "../RegExValidate"
import { member_area_router } from "../../Common/RegExValidate"

const TravelInterestsForm_ = ({screenSize,
    setSwitchProfileContainer,
    selected_travel_interests,
    selected_travel_how,
    selected_travel_where,
    selected_travel_howlong,
    selected_travel_what,
    resources,
    switch_container,
    fetched, uuid_auth, lang, fetchTravelInterests 
}) => {

    React.useEffect(() => {
        if(!fetched){
            fetchTravelInterests(uuid_auth, lang)
        }
   },[uuid_auth,fetched, lang, fetchTravelInterests])
      
        const {
            travel_how,
            travel_howlong,
            travel_interests,
            travel_what,
            travel_where,
        } = resources

        let screen_at_1200 = 1200
        let screen_at_500 = 500
        let arrow_icon_size = 19
        let arrow_font = "18px/16px Futura Md BT"
        let arrow_container_width = "230px"
        let arrow_container_height = "60px"
        let arrow_container_padding_left = "10"
        let is_text_left = "text-left"
        if (!isNaN(screenSize)) {
            // menuH = screenSize > screen_at_1200_1200 ? 100 : 40
            arrow_icon_size = screenSize > screen_at_1200 ? 19 : 10
            arrow_font = screenSize > screen_at_1200 ? "18px/16px Futura Md BT" : "9px/8px Futura Md BT"
            arrow_container_width = screenSize > screen_at_1200 ? "230px" : "120px"
            arrow_container_height = screenSize > screen_at_1200 ? "60px" : "30px"
            arrow_container_padding_left = screenSize > screen_at_1200 ? "35" : "10"
            is_text_left = screenSize > screen_at_500 ? "text-left" : "text-center"

        }
        return (
            <div>
                <div
                    style={{
                        font: "18px/40px Futura Hv BT",
                        color: switch_container === member_area_router.personalInfo ? "#EFEFEF" : "#000000",
                        letterSpacing: "0",
                        textAlign: "left",
                        marginTop: "20px",
                    }}
                >
                    Tell us what type of travel you like</div>
                <div
                    style={{
                        font: "14px/40px Futura Lt BT",
                        color: switch_container === member_area_router.personalInfo ? "#EFEFEF" : "#707070",
                        letterSpacing: "0",
                        textAlign: "left",
                    }}
                >
                    We will only use this information to personalize your experience on müvTravel
                </div>
                <div className={is_text_left}>
                    {travel_interests !== undefined
                        && travel_interests.length > 0
                        && travel_interests.map((element, idx_travel_interests) => {
                            let checked = 0
                            selected_travel_interests.find(el => el === element.id) ? checked = 1 : checked = 0
                            return (
                                <ImageCheckBox key={idx_travel_interests} checked={checked} disable={element.disable} id={element.id} text={element.text} type={"TRAVEL_INTERESTS"} icon={element.icon} />
                            )
                        })
                    }
                </div>
                <div className="row m-0">
                    <div className="d-inline-block" style={{ font: "18px/28px Futura Hv BT", color: switch_container === member_area_router.personalInfo ? "#EFEFEF" : "#707070" }}>Where</div>
                    <hr className="col-10" />
                </div>

                <div style={{ font: "14px/40px Futura Lt BT", color: switch_container === member_area_router.personalInfo ? "#EFEFEF" : "#707070" }}>Favorite destination</div>
                <div className="p-3">
                    {travel_where !== undefined
                        && travel_where.length > 0
                        && travel_where.map((element, idx_travel_where) => {
                            let checked = 0
                            selected_travel_where.find(el => el === element.id) ? checked = 1 : checked = 0
                            return (
                                <CheckBox key={idx_travel_where} checked={checked} disable={element.disable} id={element.id} text={element.text} type={"TRAVEL_WHERE"} />
                            )
                        })
                    }
                </div>
                <div className="row m-0">
                    <div className="d-inline-block" style={{ font: "18px/28px Futura Hv BT", color: switch_container === member_area_router.personalInfo ? "#EFEFEF" : "#707070" }}>How Long</div>
                    <hr className="col-9" />
                </div>
                <div style={{ font: "14px/40px Futura Lt BT", color: switch_container === member_area_router.personalInfo ? "#EFEFEF" : "#707070" }}>Preferred trip length</div>
                <div className="p-3">
                    {travel_howlong !== undefined
                        && travel_howlong.length > 0
                        && travel_howlong.map((element, idx_travel_howlong) => {
                            let checked = 0
                            selected_travel_howlong.find(el => el === element.id) ? checked = 1 : checked = 0
                            return (
                                <CheckBox key={idx_travel_howlong} checked={checked} disable={element.disable} id={element.id} text={element.text} type={"TRAVEL_HOWLONG"} />
                            )
                        })
                    }
                </div>
                <div className="row m-0">
                    <div className="d-inline-block" style={{ font: "18px/28px Futura Hv BT", color: switch_container === member_area_router.personalInfo ? "#EFEFEF" : "#707070" }}>How</div>
                    <hr className="col-10" />
                </div>
                <div style={{ font: "14px/40px Futura Lt BT", color: switch_container === member_area_router.personalInfo ? "#EFEFEF" : "#707070" }}>Preferred local transit</div>
                <div className="p-3">
                    {travel_how !== undefined
                        && travel_how.length > 0
                        && travel_how.map((element, idx_travel_how) => {
                            let checked = 0
                            selected_travel_how.find(el => el === element.id) ? checked = 1 : checked = 0
                            return (
                                <CheckBox key={idx_travel_how} checked={checked} disable={element.disable} id={element.id} text={element.text} type={"TRAVEL_HOW"} />
                            )
                        })
                    }
                </div>
                <div className="row m-0">
                    <div className="d-inline-block" style={{ font: "18px/28px Futura Hv BT", color: switch_container === member_area_router.personalInfo ? "#EFEFEF" : "#707070" }}>What</div>
                    <hr className="col-10" />
                </div>
                <div style={{ font: "14px/40px Futura Lt BT", color: switch_container === member_area_router.personalInfo ? "#EFEFEF" : "#707070" }}>What do you prefer to explore</div>
                <div className="p-3">
                    {travel_what !== undefined
                        && travel_what.length > 0
                        && travel_what.map((element, idx_travel_what) => {
                            let checked = 0
                            selected_travel_what.find(el => el === element.id) ? checked = 1 : checked = 0
                            return (
                                <CheckBox key={idx_travel_what} checked={checked} disable={element.disable} id={element.id} text={element.text} type={"TRAVEL_WHAT"} />
                            )
                        })
                    }
                </div>
                <div className="position-relative margin_top_20" style={{ zIndex: "3" }}>
                    {switch_container !== member_area_router.personalInfo && <div onClick={() => setSwitchProfileContainer(landing_router.register)}
                        style={{
                            cursor: "pointer",
                            backgroundColor: "#E8E8E8",
                            paddingLeft: arrow_container_padding_left + "px",
                            height: arrow_container_height,
                            width: arrow_container_width,
                            marginTop: "10px",
                            display: "block",
                            position: "absolute",
                            border: "0",
                            left: "0px",
                            // padding-left: 5px;
                        }}>
                        <div style={{
                            textAlign: "left",
                            font: arrow_font,
                            letterSpacing: "0",
                            margin: "0",
                            position: "absolute",
                            top: "50%",
                            msTransform: "translateY(-50%)",
                            transform: "translateY(-50%)",
                            color: "#707070",
                        }}>
                            <LeftArrowIcon width={arrow_icon_size} height={arrow_icon_size} fill={"#707070"} style={{ marginRight: "20px" }} />   Previous Step
                        </div>
                    </div>}
                    <div onClick={() => setSwitchProfileContainer(landing_router.bio)}
                        style={{
                            cursor: "pointer",
                            backgroundColor: "var(--mainGreen)",
                            paddingLeft: arrow_container_padding_left + "px",
                            height: arrow_container_height,
                            width: arrow_container_width,
                            marginTop: "10px",
                            display: "block",
                            position: "absolute",
                            border: "0",
                            right: "0px",
                            // padding-left: 5px;
                        }}>
                        <div style={{
                            textAlign: "left",
                            font: arrow_font,
                            letterSpacing: "0",
                            margin: "0",
                            position: "absolute",
                            top: "50%",
                            msTransform: "translateY(-50%)",
                            transform: "translateY(-50%)",
                            color: "var(--mainWhite)",
                        }}>
                            Next Step  <RightArrowIcon width={arrow_icon_size} height={arrow_icon_size} style={{ marginLeft: "40px" }} />
                        </div>
                    </div>
                </div>
            </div>
        )
}

const mapStateToProps = (state) => {
    return {
        switch_container: state.Member.switch_container,

        screenSize: state.Setting.htmlBody.bodyClientWidth,
        selected_travel_interests: state.Landing.travel_interests,
        selected_travel_where: state.Landing.travel_where,
        selected_travel_how: state.Landing.travel_how,
        selected_travel_howlong: state.Landing.travel_howlong,
        selected_travel_what: state.Landing.travel_what,
        resources: state.Landing.resources,
        lang: state.Setting.lang,
        uuid_auth: state.Member.authModal.uuid,
    }
};

const mapDispatchToProps = {
    setSwitchProfileContainer: Actions.setSwitchProfileContainer,
    fetchTravelInterests: Actions.fetchTravelInterests,
}

const TravelInterestsForm = connect(mapStateToProps, mapDispatchToProps)(TravelInterestsForm_)
export default TravelInterestsForm

