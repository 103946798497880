import React, { Component } from 'react'

import {
    Image,
} from 'react-bootstrap'
import { connect } from 'react-redux'
import moment from 'moment';

/* hotel component */
class Hotel_ extends Component {
    render() {
        const { hotelSaved, hotelBookedAndSaved, screenSize, from } = this.props
        const pic_width = screenSize > 1024 ? 710 : 340
        const pic_height = pic_width * 0.48
        return (
            <div style={{ color: "#FFF" }} className="d-inline-block">

                {from === "destinationHotels"
                    && hotelSaved !== undefined
                    &&
                    <React.Fragment>
                        <div className="content_container" style={{ width: pic_width, marginRight:"10px"}}>
                            <Image className="min_width_100 img_fit_cover" src={"https://amhqajbken.cloudimg.io/v7/" + hotelSaved.activityPicture + "?func=crop&w=" + pic_width + "&h=" + pic_height} />

                            <div className="mask_bar_root"></div>
                            <div className="mask_hotel_name d-flex pr-1">
                                <div className="futura_md_bt_30_30 flex_1 box_1">{hotelSaved.activityName}</div>
                            </div>
                        </div>
                        <div className="p-2" style={{ width: pic_width }}>
                            <div className="row m-0">
                                <div className="hotel_margin_right">
                                    <div className="futura_lt_bt_18_32">Not yet booking</div>
                                    <div className="futura_md_bt_18_32"></div>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                }
                {from === "bookedAndSavedHotelInfo"
                    && hotelBookedAndSaved !== undefined
                    &&
                    <React.Fragment>
                        <div className="content_container" style={{ width: pic_width, marginRight:"10px" }}>
                            <Image className="min_width_100 img_fit_cover" src={"https://amhqajbken.cloudimg.io/v7/" + hotelBookedAndSaved.photo + "func=crop&w=" + pic_width + "&h=" + pic_height} style={{ width: pic_width, height: pic_height }} />

                            <div className="mask_bar_root"></div>
                            <div className="mask_hotel_name d-flex pr-1">
                                <div className="futura_md_bt_30_30 flex_1 box_1">{hotelBookedAndSaved.name}</div>
                            </div>
                        </div>
                        <div className="p-2" style={{ width: pic_width }}>
                            <div className="row m-0">
                                <div className="hotel_margin_right">
                                    <div className="futura_lt_bt_18_32">Check in:</div>
                                    <div className="futura_md_bt_18_32">{hotelBookedAndSaved.check_in !== "" ? moment(hotelBookedAndSaved.check_in).format('ddd[.] D MMM') : ''}</div>
                                </div>
                                <div className="hotel_margin_right">
                                    <div className="futura_lt_bt_18_32">Check out:</div>
                                    <div className="futura_md_bt_18_32">{hotelBookedAndSaved.check_out !== "" ? moment(hotelBookedAndSaved.check_out).format('ddd[.] D MMM') : ''}</div>
                                </div>
                                <div className="hotel_margin_right">
                                    <div className="futura_lt_bt_18_32">Confirmation #:</div>
                                    <div className="futura_md_bt_18_32">{hotelBookedAndSaved.booking_number !== "" ? hotelBookedAndSaved.booking_number : ''}</div>
                                </div>
                                {hotelBookedAndSaved.address !== undefined ?
                                    <div>
                                        <div className="futura_lt_bt_18_32">Address:</div>
                                        <div className="futura_md_bt_18_32 box_1" style={{ width: pic_width * 0.4 }}>
                                            {hotelBookedAndSaved.address.address_line_one !== "" ? hotelBookedAndSaved.address.address_line_one + " " : ''}
                                            {hotelBookedAndSaved.address.city_name !== "" ? hotelBookedAndSaved.address.city_name + " " : ''}
                                            {hotelBookedAndSaved.address.state_code !== "" ? hotelBookedAndSaved.address.state_code + " " : ''}
                                        </div>
                                    </div>
                                    : ""}
                            </div>
                        </div>
                    </React.Fragment>
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    screenSize: state.Setting.htmlBody.bodyClientWidth,
})

const mapDispatchToProps = {

}

const Hotel = connect(mapStateToProps, mapDispatchToProps)(Hotel_)
export default Hotel 
