import * as Actions from '../../../redux/actions'

import { CheckLightIcon, CircleDownIcon, CircleUpIcon } from "../Common/SVGBank"

import MenuItem from '@material-ui/core/MenuItem'
import React from 'react'
import Rating from '@material-ui/lab/Rating'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'

const StarsFilterTypeMenuItem = connect((state) => ({
    selectedSubtypes: state.FormBank.HotelSearch.selectedSubtypes,
    lazyLoading: state.FormBank.HotelSearch.lazyLoading,
    switchFeatureContainer: state.FormBank.FeatureFormat,
    //establishments: state.FormBank.HotelSearch.establishments,
    establishmentsToFilter: state.FormBank.HotelSearch.establishmentsToFilter,
    hotel_form_state: state.FormBank.HotelSearch.hotel_form_state,


}), {
    addSelectedSubTypeHotels: Actions.addSelectedSubTypeHotels,
    deleteSelectedSubTypeHotels: Actions.deleteSelectedSubTypeHotels,
})(({
    selectedSubtypes,
    lazyLoading,
    addSelectedSubTypeHotels,
    deleteSelectedSubTypeHotels,
    switchFeatureContainer,
    //establishments,
    establishmentsToFilter,
    hotel_form_state,
}) => {
    const switchFeatureContainerColorLight = switchFeatureContainer.color + "35"
    const barsStyles = makeStyles(theme => ({
        root: {
            display: 'flex',
            '& .MuiListItem-root': {
                boxShadow: "0 0 0 0 rgba(0,123,255,.25)",
                font: "16px/16px Futura Md BT",
                color: "#707070",
            },
        },
        buttonText: {
            color: switchFeatureContainer.color,
            textAlign: "left",
            font: "16px/22px Futura Md BT",
            letterSpacing: "0",
            padding: "5px",
            '&:focus': {
                border: "0",
                outline: "0px",

            }
        },
        timesDiv: {
            marginRight: "10px",
            marginLeft: "115px",
            display: "inline-block",
            textAlign: "right",
        },
        popper: {
            zIndex: "3",
            marginTop: "20px",
            font: "16px/16px Futura Md BT",
            '& .MuiListItem-root:hover': {
                backgroundColor: switchFeatureContainerColorLight,
            },
        },
        paddingLeft30: {
            paddingLeft: "30px"
        },
        backgroundSelection: {
            '&:hover': {
                backgroundColor: switchFeatureContainer.color
            }
        },
        paper: {
            marginRight: theme.spacing(2),
        },
        xoutline: {
            '& .MuiMenuItem-root': {
                outline: "0px",
                paddingTop: "0px!important",
                paddingBottom: "0px!important",
            }
        },
        outline: {
                outline: "0px",
                paddingTop: "0px!important",
                paddingBottom: "0px!important",
            
        },
    }))
    

    const classesBars = barsStyles()

    let  [,setState]=React.useState();
    const handleAddSelectedSubTypeHotels = (establishmentsToFilter, subtype, lazyLoading, selectedSubtypes) => {
        addSelectedSubTypeHotels(establishmentsToFilter, subtype, selectedSubtypes, hotel_form_state, lazyLoading)
        setState({});
    }

    const title = "Star Rating"

    const subtypes=[
        {id:"stars-5",
         type:"stars",
         title: "5 stars",
        },
        {id:"stars-4",
         type:"stars",
         title: "4 stars",
        },
        {id:"stars-3",
         type:"stars",
         title: "3 stars",
        },
        {id:"stars-2",
         type:"stars",
         title: "2 stars",
        },
        {id:"stars-1",
         type:"stars",
         title: "1 stars",
        },
    ]

    return (
        <div className="position-relative">
            <div>
                <div style={{ display: "flex", width: "200px", paddingLeft: "10px", paddingRight: "10px", font: "16px/16px Futura Md BT", outline: "0px", }} >
                    <div style={{ flexGrow: "1", paddingTop: "10px", paddingBottom: "6px", cursor: "pointer", outline: "0px", }}>
                        {title}
                    </div>
                </div>

                <div className="timeline_rating_color" style={{width:"100%"}}>
                
                    { subtypes.map((subtype, subtype_index) => {
                        
                        return (
                            <MenuItem key={subtype_index}
                                className={classesBars.outline}
                                onClick={
                                    () => {
                                        selectedSubtypes.find(val => val.id === subtype.id) ?
                                            deleteSelectedSubTypeHotels(subtype, selectedSubtypes, establishmentsToFilter, hotel_form_state, lazyLoading)
                                            :
                                            handleAddSelectedSubTypeHotels(establishmentsToFilter, subtype, lazyLoading, selectedSubtypes)
                                    }}
                            >                                    
                                <Rating name={subtype.id} precision={0.5} max={5} value={parseFloat(subtype.id.replace("stars-", ""))} disabled={true} />
                                
                                {selectedSubtypes.find(val => val.id === subtype.id) && <CheckLightIcon fill={switchFeatureContainer.color} />}
                            </MenuItem>
                        )
                    })}
                 </div>

            </div>

        </div>
    )
})

export default StarsFilterTypeMenuItem