import {
    CURRENT_VIDEO_DISPLAY
} from "../interfaces/types"

/* display video */
export const displayVideo = (youtube_id, video_desc, index) => dispatch => {
    dispatch({
        type: CURRENT_VIDEO_DISPLAY,
        youtube_id: youtube_id,
        video_desc: video_desc,
        index: index
    })
}