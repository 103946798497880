import "./SaveTrip.css";

import * as Actions from '../../../redux/actions'

import { PropTypes } from "prop-types"
import React from 'react'
import { RightArrowIcon } from '../Common/SVGBank';
import { connect } from 'react-redux'
import { member_area_router } from "../Common/RegExValidate"
import { useHistory } from "react-router-dom"

/* save trip component */
const SaveTrip_ = ({ copyPublicTrip, setSwitchContainer, trip, uuid, alertBar, cost, copyTripId, setCopyTrip }) => {

    const history = useHistory()
    const handlerSaveTrip = () => {
        if (uuid !== undefined && uuid !== "") {
            copyPublicTrip(trip.tripID, trip.tripName, uuid)
        } else {
            this.props.setOpenAuthModal(true)
            alertBar("warning", "Please Sign In / Sign Up")
        }
    }

    React.useEffect(() => {
        if (copyTripId !== undefined && copyTripId !== "") {
            setSwitchContainer(member_area_router.destination)
            history.push("/destination-explore/copy/trip/" + copyTripId)
            setCopyTrip("")
        }
    }, [copyTripId])

    return (
        <div className="margin_top_20 d-flex justify-content-end">
            <div>
                {/* <div className="futura_lt_bt_18_21_black">{cost !== null && "starting at"}</div> */}
                {/* <div className="futura_hv_bt_36_43_black">{cost !== null && cost.currency + cost.currency_symbol+" " + cost.cost}</div> */}
            </div>
            <div>
                <div className="bg_green top_bar_container" onClick={() => handlerSaveTrip()} style={{ cursor: "pointer" }}>
                    <div className="futura_md_bt_24_10_white vertical_center left_50" >
                        Save this trip  <RightArrowIcon />
                    </div>
                </div>
            </div>
        </div>
    )
}


SaveTrip_.propTypes = {
    trip: PropTypes.object.isRequired,
    tripId: PropTypes.string.isRequired,
    lang: PropTypes.string.isRequired,
}

const mapStateToProps = state => {
    return {
        trip: state.PublicTrip.trip,
        cost: state.PublicTrip.tripCost.cost,
        tripId: state.PublicTrip.tripId,
        copyTripId: state.PublicTrip.copyTripId,
        uuid: state.Member.authModal.uuid,
        lang: state.Setting.lang,
    }
};

const mapDispatchToProps = {
    copyPublicTrip: Actions.copyPublicTrip,
    setOpenAuthModal: Actions.setOpenAuthModal,
    alertBar: Actions.alertBar,
    setCopyTrip: Actions.setCopyTrip,
    setSwitchContainer: Actions.setSwitchContainer,
}

export const SaveTrip = connect(mapStateToProps, mapDispatchToProps)(SaveTrip_)
