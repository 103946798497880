import * as Actions from '../../../redux/actions';

import React, { Component } from 'react'

import { DeleteClipIcon } from '../Common/SVGBank'
import { connect } from 'react-redux'

class Clip_ extends Component {
    render() {
        const { element, removeLanguageFluency, uuid, langSetting } = this.props
        let lang = element.language !== undefined &&  element.language.title
        let fluency = element.fluency !== undefined && element.fluency !== null && " - " + element.fluency.title
        let lang_id =  element.language !== undefined &&  element.language.id
        let fluency_code = element.fluency !== undefined && element.fluency !== null && "_" + element.fluency.code
        let ids = lang_id + fluency_code
        let desc = lang + fluency
        return (
            <div
                style={{
                    color: "rgba(0, 0, 0, 0.87)",
                    border: "none",
                    cursor: "default",
                    height: "32px",
                    display: "inline-flex",
                    outline: "0",
                    padding: "0",
                    fontSize: "0.8125rem",
                    boxSizing: "border-box",
                    transition: "background - color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box - shadow 300ms cubic - bezier(0.4, 0, 0.2, 1) 0ms",
                    alignItems: "center",
                    font: "14px/24px Futura Md BT",
                    whiteSpace: "nowrap",
                    borderRadius: "16px",
                    verticalAlign: "middle",
                    justifyContent: "center",
                    textDecoration: "none",
                    backgroundColor: "#e0e0e0",
                    margin: "3px",
                    maxWidth: "calc(100% - 6px)",
                }}>
                <span style={{
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    paddingLeft: "12px",
                    paddingRight: "12px",
                    textOverflow: "ellipsis",
                }}>{desc}</span>
                <div onClick={() => removeLanguageFluency(ids, element.language, element.fluency, uuid, langSetting)}><DeleteClipIcon /></div>
            </div >
        )
    }
}

const mapStateToProps = (state) => ({
    langSetting: state.Setting.lang,
    uuid: state.Member.authModal.uuid,
})

const mapDispatchToProps = {

    removeLanguageFluency: Actions.removeLanguageFluency
}

const Clip = connect(mapStateToProps, mapDispatchToProps)(Clip_)
export default Clip

