import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import allReducer from './reducers';

const store = configureStore({
  reducer: allReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false,
  }),
  
})
export default store;