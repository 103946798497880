import * as Actions from '../../../../redux/actions'

import {
    CheckedBoxReglarIcon,
    UnCheckBoxReglarIcon,
} from '../SVGBank'

import PublicTripLink from '../../Common/Trip/PublicTripLink'
import React from 'react'
import { check_box_trip_status } from "../RegExValidate"
import { connect } from 'react-redux'
import { member_area_router } from "../../Common/RegExValidate"

const TripAuthCheckBox_ = ({ uuid, lang, tripId, switch_container, tripAuthStatus, setTripAuthStatus, text, activities, }) => {
    const [check, setChecked] = React.useState(check_box_trip_status.disable)
    React.useEffect(() => {
        if (tripId !== "") {
            if (activities.length > 0) {
                setChecked(String(tripAuthStatus))
            } else {
                setChecked(check_box_trip_status.disable)
                tripAuthStatus === check_box_trip_status.public && setTripAuthStatus(lang, uuid, tripId, check_box_trip_status.private)
            }
        }
    }, [tripId, tripAuthStatus, activities])

    return (

        <React.Fragment>
            {switch_container === member_area_router.memberAreaTrip ?
                <div style={{ position: "relative" }}>
                    <div
                        style={{
                            paddingRight: "15px",
                            textAlign: "right",
                            cursor: check !== check_box_trip_status.disable ? "pointer" : "",
                        }}
                        onClick={() => check !== check_box_trip_status.disable ?
                            setTripAuthStatus(lang, uuid, tripId, check === check_box_trip_status.private ? check_box_trip_status.public : check_box_trip_status.private) : {}}
                    >
                        {check === check_box_trip_status.private && <CheckedBoxReglarIcon style={{ marginBottom: "5px" }} />}
                        {check === check_box_trip_status.public && <UnCheckBoxReglarIcon style={{ marginBottom: "5px" }} />}
                        {check === check_box_trip_status.disable && <CheckedBoxReglarIcon style={{ marginBottom: "5px" }} stroke={"#E8E8E850"} />}
                        <div className="d-inline-block"
                            style={{ font: "18px/18px Futura Md BT", color: check !== check_box_trip_status.disable ? "#FFFFFF" : "#E8E8E850", marginLeft: "5px" }}>
                            {text}
                        </div>
                    </div >
    
                </div>
                :
                <div style={{ position: "relative" }}>
                    <div
                        style={{
                            paddingRight: "15px",
                            textAlign: "right",
                            cursor: check !== check_box_trip_status.disable ? "pointer" : "",
                        }}
                        onClick={() => check !== check_box_trip_status.disable ?
                            setTripAuthStatus(lang, uuid, tripId, check === check_box_trip_status.private ? check_box_trip_status.public : check_box_trip_status.private) : {}}
                    >
                        {check === check_box_trip_status.private && <CheckedBoxReglarIcon style={{ marginBottom: "5px" }} />}
                        {check === check_box_trip_status.public && <UnCheckBoxReglarIcon style={{ marginBottom: "5px" }} />}
                        {check === check_box_trip_status.disable && <CheckedBoxReglarIcon style={{ marginBottom: "5px" }} stroke={"#E8E8E8"} />}
                        <div className="d-inline-block"
                            style={{ font: "18px/18px Futura Md BT", color: check !== check_box_trip_status.disable ? "#191919" : "#E8E8E8", marginLeft: "5px" }}>
                            {text}
                        </div>
                    </div >
                </div>}
        </React.Fragment>
    )

}

const mapStateToProps = (state) => ({
    tripAuthStatus: state.FormBank.TimelineWizard.activeTrip.tripAuthStatus,
    tripId: state.FormBank.TimelineWizard.activeTrip.tripId,

    activities: state.FormBank.TimelineWizard.activeTrip.activities,
    lang: state.Setting.lang,
    uuid: state.Member.authModal.uuid,
    switch_container: state.Member.switch_container,
})

const mapDispatchToProps = {
    setTripAuthStatus: Actions.setTripAuthStatus,
}

const TripAuthCheckBox = connect(mapStateToProps, mapDispatchToProps)(TripAuthCheckBox_)
export default TripAuthCheckBox

