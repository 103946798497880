import * as Actions from '../../../redux/actions'

import React, { Component } from 'react'

import Breadcrumb from './Breadcrumb'
import DestinationListContainer from './DestinationListContainer'
import Fab from '@material-ui/core/Fab'
import { MapDuotoneIcon } from "../Common/SVGBank"
import DestinationTopBarSlider from './TopBarSlider'
import { connect } from 'react-redux'
import { list_container_mode, member_area_router } from '../Common/RegExValidate'

const DestinationContainer_ = ({location, destination,props,switchFeatureContainer, lang, fetchDestination, setPageYOffset, fetchActiveTripFromCookies, setDestinationId, fetchActiveTripByTripId, fetchDestinationPhotoGallery, fetchDestinationFeaturesAndTypes, fetchEstablishmentSortingOptions, fetchEstablishmentInfluencers, fetchEstablishmentSuggestedTrips, setTripDisplayMode, is_mobile }) => {
    /* fetch destination */

    React.useEffect(() => {
        if (props.match.params.destinationID !== undefined &&
            props.match.params.tripID === undefined &&
            props.match.params.destinationID !== null &&
            props.match.params.destinationID !== "") {
            const id = props.match.params.destinationID
            fetchDestination(id, lang)
            fetchDestinationPhotoGallery(id, lang)
            fetchEstablishmentSuggestedTrips(id, lang)
            if((switchFeatureContainer && !switchFeatureContainer.featureId) ||  ( (destination.destination && destination.destination.activeId === 0)  || props.history.action === "PUSH")){
                fetchDestinationFeaturesAndTypes(id, lang)
            }
        
            fetchEstablishmentSortingOptions(lang)
            fetchEstablishmentInfluencers(id, lang)
            setDestinationId(id)
            fetchActiveTripFromCookies(id, lang)
            setTripDisplayMode(list_container_mode.timeline)
        }
        if (props.match.params.tripID !== undefined &&
            props.match.params.tripID !== null &&
            props.match.params.tripID !== "") {
            fetchActiveTripByTripId(props.match.params.tripID, lang)

        }
    }, [props.match.params.tripID, props.match.params.destinationID])

    const handleMap = () => {
        setTripDisplayMode(list_container_mode.map)
        setPageYOffset(0)
    }
    return (
        <div style={{ position: "relative" }}>
            <DestinationTopBarSlider />
            <Breadcrumb />
            <DestinationListContainer />
            <div style={{ position: "fixed", top: "50%", right: is_mobile ? "10px" : "100px", zIndex: "3" }}>

                <Fab size="large" aria-label="back to list" style={{
                    outline: "0px",
                    backgroundColor: "#F8F8F8"
                }} onClick={() => handleMap()}>
                    <MapDuotoneIcon width={30} height={30} fill={"#0D3A7D"} style={{ marginLeft: "3px" }} />
                </Fab>
            </div>
        </div>
    )
}


const mapStateToProps = state => {
    return {
        lang: state.Setting.lang,
        is_mobile: state.Setting.is_mobile,
        switchFeatureContainer: state.FormBank.FeatureFormat,
        switch_container: state.Member.switch_container,
        destination: state.Destination
    }
}

const mapDispatchToProps = {
    setTripDisplayMode: Actions.setTripDisplayMode,
    setPageYOffset: Actions.setPageYOffset,

    // destination
    fetchDestination: Actions.fetchDestination,
    fetchDestinationPhotoGallery: Actions.fetchDestinationPhotoGallery,
    fetchDestinationFeaturesAndTypes: Actions.fetchDestinationFeaturesAndTypes,
    fetchEstablishmentInfluencers: Actions.fetchEstablishmentInfluencers,
    fetchEstablishmentSortingOptions: Actions.fetchEstablishmentSortingOptions,
    fetchEstablishmentSuggestedTrips: Actions.fetchEstablishmentSuggestedTrips,
    setDestinationId: Actions.setDestinationId,
    fetchActiveTripFromCookies: Actions.fetchActiveTripFromCookies,
    setSwitchContainer: Actions.setSwitchContainer,



    //destination explore    
    fetchActiveTripByTripId: Actions.fetchActiveTripByTripId,
}

const DestinationContainer = connect(mapStateToProps, mapDispatchToProps)(DestinationContainer_)
export default DestinationContainer
