import React, { Component } from 'react';
import { makeStyles } from "@material-ui/core/styles";

import './styles.css';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'grid',
    gridTemplateColumns: '50% 50%',
    alignItems: 'center',
    marginTop: 170,

    [theme.breakpoints.down('sm')]: {
      display: 'block',
      marginTop: 160
    },
    [theme.breakpoints.between('sm', 'md')]: {
      display: 'grid',
      gridTemplateColumns: '50% 50%',
      alignItems: 'center',
    },
  },
  stepBackgroundImage: {
    paddingRight: 40,
    [theme.breakpoints.down('sm')]: {
      paddingTop: 50,
      paddingRight: 0,
    },
  },
}));

export default function InfluencerStepTwo() {
  const classes = useStyles();
  const screenSize = document.body.clientWidth;
  let screen_at_500 = 500;

  return (
    <div className={classes.root}>
      {screenSize < screen_at_500 ?
        <div className="landing-step-container" style={{marginBottom:50}}>
          <div className="step-description-section">
              <h1 className="step-number-influencers">2</h1>
              <div className="step-description">
                  <h2 className="step-title-influencer">Delight your followers, gain new ones</h2>
                  <p className="futura_lt_bt_20_30"
                  style={{color: '#B1B1B1'}}
                  >Make it easy to your followers and readers. When you create an itinerary on müvTravel or select favorites or bucket list ideas, they can copy them to their account with the click of a button. They can customize the itine- rary, get a trip cost and book, if they wish. Have past trips whose itineraries you forgot, no problem. Send us a link to an album of geo-located photos and we will recreate it for you, for free. Don’t wait, this offer won't last.
                  </p>
              </div>
          </div>
        </div>
        : ""
      }

      <div className={classes.stepBackgroundImage}>
        <div style={{}}>
          <img style={{width: '100%'}} src={require('../../../assets/images/itinerary.jpg')}/>

        </div>
      </div>

      {screenSize > screen_at_500 ?
        <div className="landing-step-container">
          <div className="step-description-section">
              <h1 className="step-number-influencers">2</h1>
              <div className="step-description">
                  <h2 className="step-title-influencer">Delight your followers, gain new ones</h2>
                  <p className="futura_lt_bt_20_30"
                  style={{color: '#B1B1B1'}}>
                  Make it easy to your followers and readers. When you create an itinerary on müvTravel or select favorites or bucket list ideas, they can copy them to their account with the click of a button. They can customize the itine- rary, get a trip cost and book, if they wish. Have past trips whose itineraries you forgot, no problem. Send us a link to an album of geo-located photos and we will recreate it for you, for free. Don’t wait, this offer won't last.
                    </p>
              </div>
          </div>
        </div>
        : ""
      }
    </div>
  )
}
