import { EllipsisVIcon, TimesIcon } from "./SVGBank"

import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Star } from './MemberActions/Star';
import { Heart } from './MemberActions/Heart';
import { Plus } from './MemberActions/Plus';
import { Done } from './MemberActions/Done';
import { connect } from 'react-redux';

/*  inject css to menu component */
const useStyles = makeStyles((theme) => {

        return {
            root: {
                display: 'inline-block',
            },
            button_text: {
                color: "var(--mainGreen)",
                textAlign: "left",
                font: "16px/22px Futura Md BT",
                letterSpacing: "0",
                '&:focus': {
                    border: "0",
                    outline: "0px"
                }
            },
            positionZIndex: {
                top: "10px !important",
                left: "-5px !important",
                zIndex: "34 !important",
                width: 130
            },



            paddingLeft30: {
                paddingLeft: "30px"
            },
            backgroundSelection: {
                '&:hover': {
                    backgroundColor: ""
                },
                minHeight: "10px",
            },
            paper: {
                marginRight: theme.spacing(2),
            },

            hr: {

                width: "100%",
                height: "1px",
                display: "block",
                position: "relative",
                marginRight: "10%",
                marginTop: "5px",
                marginBottom: "5px",
                border: "1px solid rgba(0,0,0,.05)",
            },
            timesDiv: {
                marginRight: "10px",
                marginLeft: "115px",
                display: "inline-block",
                textAlign: "right",
            },

        }
});

 const ActionsEllipsis_ = ({ id, switchFeatureContainer,uuid }) => {
    const classes  = useStyles(switchFeatureContainer.color);
    const [open, setOpen] = React.useState(false);

    const anchorRef = React.useRef(null);

    const handleToggle = () => {
        setOpen(prevOpen => !prevOpen);
    };
    const handleClose = event => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };
    return (
        <div className={classes.root}>

            <div>
                <div style={{ textAlign: "right", position: "absolute", top: "25px", right:  uuid !== undefined && uuid !== "" ? "55px": "20px", zIndex: "2", }}>
                    <Star
                        unitID={id}
                        feature={switchFeatureContainer}
                        fill={"#FFFFFF"} />
                </div>

                { uuid !== undefined && uuid !== "" &&
               <div>
                <div
                    ref={anchorRef}
                    aria-controls={open ? 'menu_ellipsis' : undefined}
                    aria-haspopup="true"
                    onClick={handleToggle}
                    className={classes.button_text}
                    style={{ textAlign: "right", position: "absolute", top: "25px", right: "25px", zIndex: "2", cursor: "pointer", }}
                >
                    <EllipsisVIcon height={'25'} fill={"#FFFFFF"} />
                </div>

                <ClickAwayListener onClickAway={handleClose}>
                    <Popper
                        open={open}
                        anchorEl={anchorRef.current}
                        className={classes.positionZIndex}

                        role={undefined}
                        transition
                        disablePortal
                        placement={'bottom-end'}
                        modifiers={{
                            flip: {
                                enabled: false,
                            },
                        }}

                    >
                        {({ TransitionProps, placement }) => (
                            <Grow
                                {...TransitionProps}
                                style={{ transformOrigin: placement, }}
                                {...({ timeout: 1000 })}
                            >
                                <Paper >
                                    <MenuList

                                        autoFocusItem={false}
                                        disableListWrap={true}
                                        dense={false}
                                        id="menu_ellipsis" 
                                        >
                                        {/* <div className={classes.timesDiv} onClick={handleClose}>{<TimesIcon width={20} height={20} />}</div> */}
                                        <MenuItem className={classes.backgroundSelection} >
                                            <Done
                                                style={{ marginRight: "10px" }}
                                                unitID={id}
                                                feature={switchFeatureContainer}
                                                fill={switchFeatureContainer.color} caption={true}/>
                                        </MenuItem>
                                        <div className={classes.hr}></div>
                                        <MenuItem className={classes.backgroundSelection} >
                                            <Heart
                                                style={{ marginRight: "10px" }}
                                                unitID={id}
                                                feature={switchFeatureContainer}
                                                fill={switchFeatureContainer.color} caption={true}/>
                                        </MenuItem>
                                        <div className={classes.hr}></div>
                                        <MenuItem className={classes.backgroundSelection} >
                                            <Plus
                                                style={{ marginRight: "10px" }}
                                                unitID={id}
                                                feature={switchFeatureContainer}
                                                fill={switchFeatureContainer.color} caption={true}/>
                                        </MenuItem>

                                    </MenuList>
                                </Paper>
                            </Grow>
                        )}
                    </Popper>

                </ClickAwayListener>
                </div>}
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({

    uuid: state.Member.authModal.uuid,
})

const mapDispatchToProps = {

}

const ActionsEllipsis = connect(mapStateToProps, mapDispatchToProps)(ActionsEllipsis_)
export default ActionsEllipsis 
