import {
    Image,
} from 'react-bootstrap'
import Rating from '@material-ui/lab/Rating'
import React from 'react'
import ReviewsDescription from "./ReviewsDescription"
import { connect } from 'react-redux'
import moment from 'moment'

const ReviewsGoogle_ = ({ reviewsGoogle, open }) => {
    return (
        <div>
            { reviewsGoogle !== undefined && reviewsGoogle !== null && reviewsGoogle.length > 0 && reviewsGoogle.map((review, review_index) => {
                const date_created = review.date_created !== undefined ? moment(review.date_created, 'YYYY-MM-DD').format("MMM Do[,] YYYY") : ""
                const rating = review.rating !== undefined ? review.rating : ""
                const text = review.text !== undefined ? review.text : ""
                const author_name = review.author_name !== undefined ? review.author_name : ""
                const profile_photo_url = review.profile_photo_url !== undefined ? review.profile_photo_url : ""

                
                return (
                    <React.Fragment key={review_index}>
                        {(open || review_index < 3) && <React.Fragment>
                            {profile_photo_url === "" ?
                                <div style={{ display: "flex", flexDirection: "column", marginTop: "30px" }}>
                                    <div style={{ flexGrow: "1", flexWrap: "wrap" }}>
                                        <div><span style={{ font: "18px/24px Futura Hv BT", color: "#707070" }}>{author_name}</span> {" - "} <span style={{ font: "14px/24px Futura Lt BT", color: "#707070", whiteSpace: "nowrap", }}>{date_created}</span></div>
                                        <Rating name="reviews_rating" style={{
                                            color: "var(--mainGreen)", opacity: "1",
                                        }} precision={0.5} max={5} value={parseFloat(rating)} disabled={true} />
                                    </div>
                                    <div style={{flexGrow:"1"}}>
                                    {text !== "" && <ReviewsDescription description={text} boxDescription={"box_3"} fontDescription={"16px/22px Futura Md BT"} colorDescription={"#191919"} height={"90px"} width={"630px"} />}
                                    </div>
                                </div>
                                :
                                <div style={{ display: "flex", flexDirection: "column", marginTop: "30px" }}>
                                    <div style={{flexGrow: "1"}}>
                                    <Image className=""
                                        src={"https://amhqajbken.cloudimg.io/v7/" + profile_photo_url + "?func=crop&w=70&h=70"}
                                        alt="google profile photo" width={"70px"} height={"70px"} />
                                    </div>
                                    <div style={{ marginLeft: "10px", flexGrow: "1", flexWrap: "wrap" }}>
                                        <div><span style={{ font: "18px/24px Futura Hv BT", color: "#707070" }}>{author_name}</span> {" - "} <span style={{ font: "14px/24px Futura Lt BT", color: "#707070", whiteSpace: "nowrap", }}>{date_created}</span></div>
                                        <Rating name="reviews_rating" style={{
                                            color: "var(--mainGreen)", opacity: "1",
                                        }} precision={0.5} max={5} value={parseFloat(rating)} disabled={true} />
                                    </div>
                                    <div style= {{flexGrow: "1"}}>
                                    {text !== "" && <ReviewsDescription description={text} boxDescription={"box_3"} fontDescription={"16px/22px Futura Md BT"} colorDescription={"#191919"} height={"90px"} width={"630px"} />}
                                    </div>
                                </div>}

                        </React.Fragment>}
                    </React.Fragment>
                )
            })}
        </div >
    )
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = {

}

const ReviewsGoogle = connect(mapStateToProps, mapDispatchToProps)(ReviewsGoogle_)
export default ReviewsGoogle 
