import {
    FETCH_API_ERROR_MEMBER_ACTIONS,
    FETCH_FEATURE_MEMBER_ACTIONS,
    FETCH_HEART_SETS_MEMBER_ACTIONS,
    FETCH_MEMBER_ACTIONS_MEMBER_ACTIONS,
    FETCH_MEMBER_ACTION_SETS,
    FETCH_PLUS_SETS_MEMBER_ACTIONS,
    FETCH_DONE_SETS_MEMBER_ACTIONS,
    FETCH_STAR_SETS_MEMBER_ACTIONS,
    GetAsyncHelper,
    IS_FETCHED_FEATURES_MEMBER_ACTIONS,
    IS_FETCHED_HEART_MEMBER_ACTIONS,
    IS_FETCHED_DONES_MEMBER_ACTIONS,
    IS_FETCHED_MEMBER_ACTIONS_MEMBER_ACTIONS,
    IS_FETCHED_PLUS_MEMBER_ACTIONS,
    IS_FETCHED_STARS_MEMBER_ACTIONS,
    PostAsyncHelper,
    SET_COOKIES_MEMBER_ACTIONS_SETS,
    SET_SELECTED_MEMBER_ACTIONS,
} from "../interfaces/types"

import Axios from 'axios';
import Cookies from 'js-cookie'
import qs from 'qs'
import { environment } from "../../Environments/environment";

export const fetchFeatureMemberActions = () => async dispatch => {

    try {
        const response = await Axios.get(environment.apiUrl +'featurelist/getavailablefeatures/en')
        if (response.statusText !== "OK") {
            throw Error(response.statusText);
        }
        dispatch({
            type: FETCH_FEATURE_MEMBER_ACTIONS,
            features: response.data.features,
        })
        dispatch({
            type: IS_FETCHED_FEATURES_MEMBER_ACTIONS,
            is_fetched: true,
        })
    } catch (error) {
        dispatch({
            type: FETCH_API_ERROR_MEMBER_ACTIONS,
            api_error: true
        })
    }
}
export const fetchMemberActionsMemberActions = () => async dispatch => {

    try {
        const response = await Axios.get(environment.apiUrl +'getPossibleMemberActions/en')
        if (response.statusText !== "OK") {
            throw Error(response.statusText);
        }
        dispatch({
            type: IS_FETCHED_MEMBER_ACTIONS_MEMBER_ACTIONS,
            is_fetched: true,
        })
        dispatch({
            type: FETCH_MEMBER_ACTIONS_MEMBER_ACTIONS,
            member_actions: response.data,
        })
    } catch (error) {
        dispatch({
            type: FETCH_API_ERROR_MEMBER_ACTIONS,
            api_error: true
        })
    }
}
/* star */
export const addingStar = (uuid, unitID, feature, cookiesMemberActionSets = []) => async dispatch => {

    if (uuid !== undefined && uuid !== "") {
        let obj = {
            uuid: uuid,
            memberaction: [{
                unitID: unitID,
                feature: feature.featureId,
                action: "star",
            }, ],
        }

        let api_url = environment.apiUrl +"useraction"

        PostAsyncHelper(api_url, obj).then(response => {
            GetAsyncHelper(environment.apiUrl +'getMemberActions/' + uuid + '/en?actionType=star').then(responseStars => {
                if (responseStars.statusText === "OK") {
                    dispatch({
                        type: FETCH_STAR_SETS_MEMBER_ACTIONS,
                        stars: responseStars.data
                    })

                } else {
                    dispatch({
                        type: FETCH_API_ERROR_MEMBER_ACTIONS,
                        api_error: true
                    })
                }
            })
        })
        Cookies.remove('muv_member_actions')
        dispatch({
            type: SET_COOKIES_MEMBER_ACTIONS_SETS,
            cookies_member_action_sets: [],
        })
    } else {

        const stars = [{
            key: unitID + "_" + feature.featureId + "_star",
            unitID: unitID,
            feature: feature.featureId,
            action: "star",
        }]
        dispatch({
            type: SET_COOKIES_MEMBER_ACTIONS_SETS,
            cookies_member_action_sets: cookiesMemberActionSets.concat(stars),
        })
        const memberActionStringify = qs.stringify(cookiesMemberActionSets.concat(stars))
        Cookies.set('muv_member_actions', memberActionStringify, {
            expires: 7
        })
    }
    dispatch({
        type: SET_SELECTED_MEMBER_ACTIONS,
        unitID: unitID,
        feature: feature.featureId,
        action: "star",
    })
}

export const deletingStar = (uuid, unitID, feature, cookiesMemberActionSets = [],actionType) => async dispatch => {

    if (uuid !== undefined && uuid !== "") {
        PostAsyncHelper(environment.apiUrl +'useractiondelete?uuid=' + uuid + '&unitID=' + unitID + '&feature=' + feature.featureId + `&action=${actionType}`, {}).then(response => {
            GetAsyncHelper(environment.apiUrl +'getMemberActions/' + uuid + `/en?actionType=${actionType}`).then(responseStars => {
                if (responseStars !== undefined && responseStars.statusText === "OK") {
                    dispatch({
                        type: FETCH_STAR_SETS_MEMBER_ACTIONS,
                        stars: responseStars.data
                    })

                } else {
                    dispatch({
                        type: FETCH_API_ERROR_MEMBER_ACTIONS,
                        api_error: true
                    })
                }
            })

            const url_member_actions_sets = environment.apiUrl +"getMemberActionsGroupedByFeature/" + uuid + "/en" + "?actionType=" + actionType
            GetAsyncHelper(url_member_actions_sets).then(responseMemberActions => {
                if (responseMemberActions !== undefined && responseMemberActions.statusText === "OK") {
                    const resultMemberActions = responseMemberActions.data
                    const featuresList = Array.from(resultMemberActions.map(action => action.featureTitle))
                    
                    dispatch({
                        type: FETCH_MEMBER_ACTION_SETS,
                        member_actions: resultMemberActions,
                        features: featuresList
                    })
                } else {
                    console.error("Error getMemberActionsGroupedByFeature")
                }
            })
        })
        Cookies.remove('muv_member_actions')
        dispatch({
            type: SET_COOKIES_MEMBER_ACTIONS_SETS,
            cookies_member_action_sets: [],
        })
    } else {
        const unique = unitID + "_" + feature.featureId + `_${actionType}`
        dispatch({
            type: SET_COOKIES_MEMBER_ACTIONS_SETS,
            cookies_member_action_sets: cookiesMemberActionSets.filter(val => val.key !== unique)
        })
        const memberActionStringify = qs.stringify(cookiesMemberActionSets.filter(val => val.key !== unique))
        Cookies.set('muv_member_actions', memberActionStringify, {
            expires: 7
        })
    }
    dispatch({
        type: SET_SELECTED_MEMBER_ACTIONS,
        unitID: unitID,
        feature: feature.featureId,
        action: actionType,
    })

}

export const fetchingMemberActionsStar = (uuid, actionType = "star") => async dispatch => {
    if (uuid !== undefined && uuid !== "") {
        GetAsyncHelper(environment.apiUrl +'getMemberActions/' + uuid + '/en?actionType=' + actionType).then(responseStars => {
            if (responseStars.statusText === "OK") {
                dispatch({
                    type: FETCH_STAR_SETS_MEMBER_ACTIONS,
                    stars: responseStars.data
                })
                dispatch({
                    type: IS_FETCHED_STARS_MEMBER_ACTIONS,
                    is_fetched: true,
                })

            } else {
                dispatch({
                    type: FETCH_API_ERROR_MEMBER_ACTIONS,
                    api_error: true
                })
            }
        })
    } else {
        const member_actions_cookies = Cookies.get('muv_member_actions')
        const member_action_object = Object.values(qs.parse(member_actions_cookies))
        dispatch({
            type: SET_COOKIES_MEMBER_ACTIONS_SETS,
            cookies_member_action_sets: member_action_object,
        })
    }
}

/* favorite ---heart */


export const fetchingMemberActionsFavorite = (uuid, actionType = "favorite") => async dispatch => {
    if (uuid !== undefined && uuid !== "") {

        GetAsyncHelper(environment.apiUrl +'getMemberActions/' + uuid + '/en?actionType=' + actionType).then(responseFavorites => {
            if (responseFavorites.statusText === "OK") {
                dispatch({
                    type: FETCH_HEART_SETS_MEMBER_ACTIONS,
                    favorites: responseFavorites.data
                })
                dispatch({
                    type: IS_FETCHED_HEART_MEMBER_ACTIONS,
                    is_fetched: true,
                })

            } else {
                dispatch({
                    type: FETCH_API_ERROR_MEMBER_ACTIONS,
                    api_error: true
                })
            }
        })
    } else {
        console.error("force to login")
    }
}


export const addingHeart = (uuid, unitID, feature) => async dispatch => {

    if (uuid !== undefined && uuid !== "") {
        let obj = {
            uuid: uuid,
            memberaction: [{
                unitID: unitID,
                feature: feature.featureId,
                action: "favorite",
            }, ],
        }

        let api_url = environment.apiUrl +"useraction"

        PostAsyncHelper(api_url, obj).then(response => {
            GetAsyncHelper(environment.apiUrl +'getMemberActions/' + uuid + '/en?actionType=favorite').then(responseHearts => {
                if (responseHearts.statusText === "OK") {
                    dispatch({
                        type: FETCH_HEART_SETS_MEMBER_ACTIONS,
                        favorites: responseHearts.data
                    })

                } else {
                    dispatch({
                        type: FETCH_API_ERROR_MEMBER_ACTIONS,
                        api_error: true
                    })
                }
            })
        })
    } else {
        console.error("force to login")
    }

    dispatch({
        type: SET_SELECTED_MEMBER_ACTIONS,
        unitID: unitID,
        feature: feature.featureId,
        action: "favorite",
    })
}

export const deletingHeart = (uuid, unitID, feature) => async dispatch => {

    if (uuid !== undefined && uuid !== "") {
        PostAsyncHelper(environment.apiUrl +'useractiondelete?uuid=' + uuid + '&unitID=' + unitID + '&feature=' + feature.featureId + '&action=favorite', {}).then(response => {
            GetAsyncHelper(environment.apiUrl +'getMemberActions/' + uuid + '/en?actionType=favorite').then(responseHearts => {
                if (responseHearts !== undefined && responseHearts.statusText === "OK") {
                    dispatch({
                        type: FETCH_HEART_SETS_MEMBER_ACTIONS,
                        favorites: responseHearts.data
                    })

                } else {
                    dispatch({
                        type: FETCH_API_ERROR_MEMBER_ACTIONS,
                        api_error: true
                    })
                }
            })
        })
    } else {
        console.error("force to login")
    }
    dispatch({
        type: SET_SELECTED_MEMBER_ACTIONS,
        unitID: unitID,
        feature: feature.featureId,
        action: "favorite",
    })

}

/* wish list ---plus */


export const fetchingMemberActionsPlus = (uuid, actionType = "plus") => async dispatch => {
    if (uuid !== undefined && uuid !== "") {

        GetAsyncHelper(environment.apiUrl +'getMemberActions/' + uuid + '/en?actionType=' + actionType).then(responseFavorites => {
            if (responseFavorites.statusText === "OK") {
                dispatch({
                    type: FETCH_PLUS_SETS_MEMBER_ACTIONS,
                    pluses: responseFavorites.data
                })
                dispatch({
                    type: IS_FETCHED_PLUS_MEMBER_ACTIONS,
                    is_fetched: true,
                })

            } else {
                dispatch({
                    type: FETCH_API_ERROR_MEMBER_ACTIONS,
                    api_error: true
                })
            }
        })
    } else {
        console.error("force to login")
    }
}


export const addingPlus = (uuid, unitID, feature) => async dispatch => {

    if (uuid !== undefined && uuid !== "") {
        let obj = {
            uuid: uuid,
            memberaction: [{
                unitID: unitID,
                feature: feature.featureId,
                action: "plus",
            }, ],
        }

        let api_url = environment.apiUrl +"useraction"

        PostAsyncHelper(api_url, obj).then(response => {
            GetAsyncHelper(environment.apiUrl +'getMemberActions/' + uuid + '/en?actionType=plus').then(responsePluses => {
                if (responsePluses !== undefined && responsePluses.statusText === "OK") {
                    dispatch({
                        type: FETCH_PLUS_SETS_MEMBER_ACTIONS,
                        pluses: responsePluses.data
                    })

                } else {
                    dispatch({
                        type: FETCH_API_ERROR_MEMBER_ACTIONS,
                        api_error: true
                    })
                }
            })
        })
    } else {
        console.error("force to login")
    }

    dispatch({
        type: SET_SELECTED_MEMBER_ACTIONS,
        unitID: unitID,
        feature: feature.featureId,
        action: "plus",
    })
}

export const deletingPlus = (uuid, unitID, feature) => async dispatch => {

    if (uuid !== undefined && uuid !== "") {
        PostAsyncHelper(environment.apiUrl +'useractiondelete?uuid=' + uuid + '&unitID=' + unitID + '&feature=' + feature.featureId + '&action=plus', {}).then(response => {
            GetAsyncHelper(environment.apiUrl +'getMemberActions/' + uuid + '/en?actionType=plus').then(responsePluses => {
                if (responsePluses !== undefined && responsePluses.statusText === "OK") {
                    dispatch({
                        type: FETCH_PLUS_SETS_MEMBER_ACTIONS,
                        pluses: responsePluses.data
                    })

                } else {
                    dispatch({
                        type: FETCH_API_ERROR_MEMBER_ACTIONS,
                        api_error: true
                    })
                }
            })
        })
    } else {
        console.error("force to login")
    }
    dispatch({
        type: SET_SELECTED_MEMBER_ACTIONS,
        unitID: unitID,
        feature: feature.featureId,
        action: "plus",
    })

}



export const getMemberActionsSets = (uuid, lang, actionType = "") => async dispatch => {
    if (uuid !== undefined && uuid !== "" && actionType !== "") {
        const url_member_actions_sets = environment.apiUrl +"getMemberActionsGroupedByFeature/" + uuid + "/" + lang + "?actionType=" + actionType
        GetAsyncHelper(url_member_actions_sets).then(responseMemberActions => {
            if (responseMemberActions !== undefined && responseMemberActions.statusText === "OK") {
                const resultMemberActions = responseMemberActions.data
                const featuresList = Array.from(resultMemberActions.map(action => action.featureTitle))
                
                dispatch({
                    type: FETCH_MEMBER_ACTION_SETS,
                    member_actions: resultMemberActions,
                    features: featuresList
                })
            } else {
                console.error("Error getMemberActionsGroupedByFeature")
            }
        })
    } else {
        console.error("force to login")
    }
}


export const addingDone = (uuid, unitID, feature) => async dispatch => {

    if (uuid !== undefined && uuid !== "") {
        let obj = {
            uuid: uuid,
            memberaction: [{
                unitID: unitID,
                feature: feature.featureId,
                action: "done",
            }, ],
        }

        let api_url = environment.apiUrl +"useraction"

        PostAsyncHelper(api_url, obj).then(response => {
            GetAsyncHelper(environment.apiUrl +'getMemberActions/' + uuid + '/en?actionType=done').then(responseDone => {
                if (responseDone !== undefined && responseDone.statusText === "OK") {
                    dispatch({
                        type: FETCH_DONE_SETS_MEMBER_ACTIONS,
                        dones: responseDone.data
                    })

                } else {
                    dispatch({
                        type: FETCH_API_ERROR_MEMBER_ACTIONS,
                        api_error: true
                    })
                }
            })
        })
    } else {
        console.error("force to login")
    }

    dispatch({
        type: SET_SELECTED_MEMBER_ACTIONS,
        unitID: unitID,
        feature: feature.featureId,
        action: "done",
    })
}


export const deletingDone = (uuid, unitID, feature) => async dispatch => {

    if (uuid !== undefined && uuid !== "") {
        PostAsyncHelper(environment.apiUrl +'useractiondelete?uuid=' + uuid + '&unitID=' + unitID + '&feature=' + feature.featureId + '&action=done', {}).then(response => {
            GetAsyncHelper(environment.apiUrl +'getMemberActions/' + uuid + '/en?actionType=done').then(responseDone => {
                if (responseDone !== undefined && responseDone.statusText === "OK") {
                    dispatch({
                        type: FETCH_DONE_SETS_MEMBER_ACTIONS,
                        dones: responseDone.data
                    })

                } else {
                    dispatch({
                        type: FETCH_API_ERROR_MEMBER_ACTIONS,
                        api_error: true
                    })
                }
            })
        })
    } else {
        console.error("force to login")
    }
    dispatch({
        type: SET_SELECTED_MEMBER_ACTIONS,
        unitID: unitID,
        feature: feature.featureId,
        action: "done",
    })

}


/* done ---done it */


export const fetchingMemberActionsDone = (uuid, actionType = "done") => async dispatch => {
    if (uuid !== undefined && uuid !== "") {

        GetAsyncHelper(environment.apiUrl +'getMemberActions/' + uuid + '/en?actionType=' + actionType).then(responseDone => {
            if (responseDone.statusText === "OK") {
                dispatch({
                    type: FETCH_DONE_SETS_MEMBER_ACTIONS,
                    dones: responseDone.data
                })
                dispatch({
                    type: IS_FETCHED_DONES_MEMBER_ACTIONS,
                    is_fetched: true,
                })

            } else {
                dispatch({
                    type: FETCH_API_ERROR_MEMBER_ACTIONS,
                    api_error: true
                })
            }
        })
    } else {
        console.error("force to login")
    }
}