import * as Actions from '../../../redux/actions'

import React, { Component } from 'react'
import { member_area_router, menus_fields_member_area_link, profile_router } from "./RegExValidate"
import {  useHistory} from "react-router-dom"
import { connect } from 'react-redux'

/* menu member component */
const MenuMember_ = ({setSwitchProfileContainer,switch_container, favorites, pluses}) =>{
  const history = useHistory()

    const handlerOnclick = (link_string) => {
        const url = "/muver/"+link_string;
        history.push(url.toLowerCase())
        setSwitchProfileContainer(profile_router.profile)
    }
        const links = []
        menus_fields_member_area_link.map((link) => {
            if (link.name === member_area_router.bucketList && pluses.length === 0) {

                // no dispaly
            } else if (link.name === member_area_router.favorites && favorites.length === 0) {
                // no dispaly
            } else {
                links.push(link)

            }
        })

        return (
            <div className="ml-auto p-3">
                {links.map((menu, idx) => { return <div key={idx} className={switch_container === menu.name ? "btn btn-sm flex-wrap member_menu_top_btn_active" : "btn btn-sm flex-wrap member_menu_top_btn member_menu"} onClick={() => handlerOnclick(menu.name)}><div className="margin_top_2" >{menu.display}</div></div> })}
            </div>
        )
}

const mapStateToProps = (state) => {
    return {
        switch_container: state.Member.switch_container,
        favorites: state.MemberActions.fetchFavoriteSets.favorites,
        pluses: state.MemberActions.fetchPlusSets.pluses,
    }
};
const mapDispatchToProps = {
    setSwitchContainer: Actions.setSwitchContainer,
    setSwitchProfileContainer: Actions.setSwitchProfileContainer,

};

const MenuMember = connect(mapStateToProps, mapDispatchToProps)(MenuMember_);
export default MenuMember
