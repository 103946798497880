import { Col, Row } from "react-bootstrap"
import React, { Component } from 'react'

import PropTypes from "prop-types";
import { connect } from 'react-redux'

/* infomartion trip component */
class InfoTrip_ extends Component {
    render() {
        // let containerH = 300;
        const {
            // screenSize, 
            trip } = this.props;
        // if (screenSize !== undefined) {
        //     containerH = screenSize / 3;
        //     containerH = containerH < 300 ? 300 : (containerH > 500 ? 500 : containerH);
        // }
        return (
            <Row className="magin_bottom_50">
                <Col sm={12} md={8} lg={8} xl={8} className="margin_bottom_20 padding_right_col_1">
                    {trip.tripDescription === undefined && trip.tripDescription === "" ?
                        <React.Fragment>
                            <div className="futura_hv_bt_30_32_black ">Description</div>
                            <div className="futura_md_bt_18_24_lightgray box_10 margin_top_20 margin_left_10">{trip.tripDescription}</div>
                        </React.Fragment>
                        : ""}
                    {trip.tripCompanions !== undefined && trip.tripCompanions.length >0 ?
                        <div className="futura_hv_bt_30_50_black margin_top_50">Travel Companions: <span className="futura_lt_bt_24_50_black">{trip.tripCompanions ? trip.tripCompanions.map((companion) => "@" + companion.companionName) : ""}</span></div>
                    :""}
                </Col>
                <Col sm={12} md={4} lg={4} xl={4} className="text_right">
                    {/* <Image src="https://amhqajbken.cloudimg.io/v7/_muv_/muvimages/muv_assets/whitepage/ratings.png?func=crop&w=400&h=500" style={{ height: containerH }} /> */}
                </Col>
            </Row>
        )
    }
}


InfoTrip_.propTypes = {
    trip: PropTypes.object.isRequired,
}

const mapStateToProps = state => {
    return {
        trip: state.PublicTrip.trip,
        screenSize: state.Setting.htmlBody.bodyClientWidth,
    }
};

const mapDispatchToProps = {

}

export const InfoTrip = connect(mapStateToProps, mapDispatchToProps)(InfoTrip_)
