import * as Actions from '../../../../redux/actions'

import Divider from '@material-ui/core/Divider'
import Grow from '@material-ui/core/Grow'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import React from 'react'
import { TimesIcon } from "../SVGBank"
import { URL_REPLACE } from "../../Common/RegExValidate"
import { connect } from "react-redux"
import { generalSearchStyles } from "../ComponentsStylingBank"
import { member_area_router } from "../../Common/RegExValidate"
import { useHistory } from "react-router-dom"


export const GeneralSearchBarAttachment = connect((state) => ({
    generalSearch: state.FormBank.generalSearch,
    is_mobile: state.Setting.is_mobile,
    lang: state.Setting.lang,
}), {

    clearGeneralSearch: Actions.clearGeneralSearch,
    setEstablishmentId: Actions.setEstablishmentId,

    setSwitchContainer: Actions.setSwitchContainer,
    setPageYOffset: Actions.setPageYOffset,

})(({
    lang,
    anchorRef,
    handleClose,
    is_mobile,
    generalSearch,
    open = generalSearch.open,

    clearGeneralSearch,
    setSwitchContainer,
    setEstablishmentId,
    setPageYOffset,
    destinationOnly = false
}) => {
    const history = useHistory()
    const classes = generalSearchStyles()

    //const openMenu = open && !destinationOnly 
    const handleSelectedDestination = (destination) => {
        clearGeneralSearch()
        const id = destination.destinationId;
        const url = "/destination/" + destination?.partialUri + "/" + 1 + "/" + id;
        // setSwitchContainer(member_area_router.destination)
        setPageYOffset(0)
        handleClose()
       history.push(url.toLowerCase())
    }
    const handleSelectedEstablishment = (establishment) => {
        clearGeneralSearch()
        const { id } = establishment;
        const url ="/establishment/" + establishment.name.replace(URL_REPLACE, "-") + "-" + establishment.countryName.replace(URL_REPLACE, "-") + "/" + establishment.id;
        history.push(url.toLowerCase())
        setSwitchContainer(member_area_router.establishment)
        setEstablishmentId(id)

    }

    let menuClose = false
    if ( destinationOnly && generalSearch && generalSearch.destinations && 
         generalSearch.destinations.length === 0 && generalSearch.establishments && generalSearch.establishments.length > 0 ) { 
         menuClose = true
    }
    
    return (
        <div className={classes.root} style={{ textAlign: "left" }}>
            <div>
                <Popper anchorEl={anchorRef} open={open} role={undefined} className={classes.dropdown}
                    placement={"bottom"}
                    modifiers={{
                        flip: {
                            enabled: false,
                        },
                    }} transition disablePortal>
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{ transformOrigin: placement === 'bottom' ? 'left top' : 'left bottom', marginTop: "30px" }}
                        >
                            <Paper>
                                {!menuClose && <MenuList autoFocusItem={open} id="menu-list-sort-trips" >                         
                                    <div>
                                        {!destinationOnly && <div>
                                            <div style={{ display: "flex", outline: "0" }}>
                                                <div style={{
                                                    flexGrow: "1",
                                                    paddingTop: "10px",
                                                    paddingBottom: "6px",
                                                    paddingLeft: "6px",
                                                    outline: "0px",
                                                    font: is_mobile ? "11px / 11px Futura Md BT" : "16px / 16px Futura Md BT",
                                                }}>Destinations
                                                </div>
                                                <div onClick={handleClose} style={{ marginRight: "10px", marginTop: "3px" }}>
                                                    {is_mobile ? <TimesIcon width={13} height={13} /> : <TimesIcon width={20} height={20} />}
                                                </div>
                                            </div>
                                            <Divider light style={{ margin: "0px" }} />
                                        </div>}

                                        {generalSearch && generalSearch.destinations && generalSearch.destinations.length > 0 && generalSearch.destinations.map((destination, destinations_idx) => {
                                            return (
                                                <div key={destinations_idx} >
                                                    {!is_mobile && <MenuItem className={"btn btn-sm flex-wrap member_menu_top_btn member_menu"} style={{
                                                        cursor: "pointer",
                                                        minHeight: is_mobile ? "0px" : "",
                                                        font: is_mobile ? "9px/9px Futura Md BT" : "16px/16px Futura Md BT"
                                                    }} onClick={() => handleSelectedDestination(destination)}>{destination.nameInline}</MenuItem>}
                                                    {is_mobile && destinations_idx < 4 && <MenuItem className={"btn btn-sm flex-wrap member_menu_top_btn member_menu"} style={{
                                                        cursor: "pointer",
                                                        minHeight: is_mobile ? "0px" : "",
                                                        font: is_mobile ? "9px/9px Futura Md BT" : "16px/16px Futura Md BT"
                                                    }} onClick={() => handleSelectedDestination(destination)}>{destination.nameInline}</MenuItem>}
                                                </div>
                                            )
                                        })} 
                                    </div> 
                                    {!destinationOnly && <div>
                                        <div style={{
                                            paddingTop: "10px",
                                            paddingBottom: "6px",
                                            paddingLeft: "6px",
                                            outline: "0px",
                                            font: is_mobile ? "11px / 11px Futura Md BT" : "16px / 16px Futura Md BT",
                                        }}>Places</div>
                                        <Divider light style={{ margin: "0px" }} />
                                    
                                       {generalSearch && generalSearch.establishments && generalSearch.establishments.length > 0  && generalSearch.establishments.map((establishment, establishment_idx) => {
                                            return (
                                                <div key={establishment_idx} >
                                                    {!is_mobile &&
                                                        <MenuItem className={"btn btn-sm flex-wrap member_menu_top_btn member_menu"} style={{
                                                            cursor: "pointer",
                                                            minHeight: is_mobile ? "0px" : "",
                                                            font: is_mobile ? "9px/9px Futura Md BT" : "16px/16px Futura Md BT"
                                                        }} onClick={() => handleSelectedEstablishment(establishment)}>{establishment.name}</MenuItem>}
                                                    {is_mobile && establishment_idx < 4 &&
                                                        <MenuItem className={"btn btn-sm flex-wrap member_menu_top_btn member_menu"} style={{
                                                            cursor: "pointer",
                                                            minHeight: is_mobile ? "0px" : "",
                                                            font: is_mobile ? "9px/9px Futura Md BT" : "16px/16px Futura Md BT"
                                                        }} onClick={() => handleSelectedEstablishment(establishment)}>{establishment.name}</MenuItem>}
                                                </div> 
                                            )
                                        })}
                                    </div>}
                                </MenuList>}
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </div>
        </div>
    )
})
