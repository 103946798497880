import * as Actions from '../../../../redux/actions'
import { connect } from "react-redux"
import { EllipsisHIcon, EllipsisVIcon, TimesIcon } from "../SVGBank"
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Grow from '@material-ui/core/Grow'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        marginLeft: theme.spacing(20),
      },
      paper: {
        marginRight: theme.spacing(2),
    },
}))


const DeleteTripEllipsis_ = ({lang, uuid, destination, tripId, tripDuration, deleteTripDestinationInTrip}) => {
    const classes = useStyles()
    const [open, setOpen] = React.useState(false)
    const [disableEllipsis, setDisableEllipsis] = React.useState(false)

    const handleEllipsis = (event) => {
        event.preventDefault()
        setOpen(prevOpen => !prevOpen)
    }
    const anchorRef = React.useRef(null)

    const handleClose = event => {
        if (!disableEllipsis) {
            if (anchorRef.current && anchorRef.current.contains(event.target)) {
                return
            }
            setOpen(false)
        }
    }

    //lang, uuid, destination, tripId, tripDuration
    //console.log (`DeleteTripEllipsis lang = ${lang}`)
    //console.log (`DeleteTripEllipsis uuid = ${uuid}`)
    //console.log (`DeleteTripEllipsis destination = ${destination}`)
    //console.log (`DeleteTripEllipsis tripId = ${tripId}`)
    //console.log (`DeleteTripEllipsis tripDuration = ${tripDuration}`)
    return (
        <div className={classes.root}>
            <div
                ref={anchorRef}
                style={{ cursor: "pointer" }}
                onClick={e => handleEllipsis(e)}>
                {/*<EllipsisHIcon height={'35'} fill="#C2C2C2"/>*/}
                <EllipsisVIcon height={'35'} fill="#C2C2C2"/>
            </div>
            <ClickAwayListener onClickAway={handleClose}>
                <Popper style={{zIndex: 500}}  open={open} anchorEl={anchorRef.current} role={undefined} transition>
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'}}
                            >
                            <Paper className={classes.paper}>                 
                                <MenuList autoFocusItem={open} id="menu-list-grow" >
                                    <div onClick={handleClose} style={{textAlign: "right", marginRight:"10px"}}><TimesIcon width={15} height={15} /></div>
                                    <MenuItem style={{textAlign: "right", marginRight:"10px"}} onClick={() => deleteTripDestinationInTrip(lang, uuid, destination, tripId, tripDuration)}>Delete Destination</MenuItem>
                                </MenuList>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </ClickAwayListener>
        </div>
    )
}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = {
    deleteTripDestinationInTrip: Actions.deleteTripDestinationInTrip,
}

const DeleteTripEllipsis = connect(null, mapDispatchToProps)(DeleteTripEllipsis_)
export default DeleteTripEllipsis