import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import React from 'react'

export function TripDescription({ description, boxDescription, fontDescription, colorDescription, height }) {
  const [open, setOpen] = React.useState(false)

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen)
  }
  const handleClose = event => {
    setOpen(false)
  }
  return (
    <ClickAwayListener onClickAway={handleClose}>
      {!open ? <div className={boxDescription} style={{
        cursor: 'pointer',
        font: fontDescription,
        color: colorDescription,
      }} onClick={() => handleToggle()}>{description}</div>
        :
        <div style={{
          cursor: 'pointer',
          overflowY: "scroll",
          height: height,
          font: fontDescription,
          color: colorDescription,
        }} onClick={() => handleToggle()}>{description}</div>
      }
    </ClickAwayListener>
  )
}