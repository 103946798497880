import { CheckIcon, MinusSignIcon, } from './SVGBank';

import Backdrop from '@material-ui/core/Backdrop'
import DeleteActivity from './TimelineWizard/DeleteActivity';
import Modal from '@material-ui/core/Modal'
import Rating from '@material-ui/lab/Rating';
import React from 'react'
import {
    TimesIcon,
} from './SVGBank'
import { connect } from 'react-redux'
import {
    deleteActivitiesModalUseStyles,
} from "./ComponentsStylingBank"
import moment from 'moment';

const AddedToTrip_ = ({ availableDeleteActivities, establishmentName, establishmentStarRating, activeTrip, text, textFont }) => {
    const tripNoDate = activeTrip.tripNoDate
    const tripStartDate = activeTrip.tripStartDate
    const classes = deleteActivitiesModalUseStyles();
    const [openDeleteActivitiesModel, setOpenDeleteActivitiesModal] = React.useState(false)
    return (
        <div>
            <div onClick={() => setOpenDeleteActivitiesModal(true)}
                style={{ cursor: "pointer", color: "#0D3A7D", font: textFont, padding: text === "Added to trip" ? "0px 10px" : "", display: "inline-block" }}>
                {text === "Added to trip" && <React.Fragment><CheckIcon fill={"#0D3A7D"} width={10} height={13} style={{ marginRight: "5px" }} /><React.Fragment>{text}</React.Fragment></React.Fragment>}
                {text === "remove from trip" && <React.Fragment><MinusSignIcon fill={"#0D3A7D"} width={8} height={5} style={{ marginRight: "2px" }} /><React.Fragment>{text}</React.Fragment></React.Fragment>}
            </div>
            <Modal
                open={openDeleteActivitiesModel}
                onClose={() => setOpenDeleteActivitiesModal(false)}
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 1000,
                }}
            >
                <div style={{
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                }} className={classes.paper}>
                    <div className="d-flex">
                        <div style={{
                            font: "30px/35px Futura Hv BT",
                            color: "#191919",
                            paddingBottom: '10px',
                            paddingTop: '15px',
                            flexGrow: "1",
                        }}>{establishmentName}</div>
                        <div
                            style={{
                                cursor: "pointer",
                            }}
                            onClick={() => setOpenDeleteActivitiesModal(false)}><TimesIcon style={{ marginTop: "5px" }} width={'25'} height={'25'} /></div>
                    </div>
                    {establishmentStarRating !== "0.0" && <div className="d-flex timeline_rating_color margin_bottom_30">
                        <Rating name="timeline_rating" precision={0.5} max={5} value={parseFloat(establishmentStarRating)} disabled={true} />
                        <div className="futura_md_bt_11_14_black margin_bottom_5 margin_left_5">({establishmentStarRating})</div>
                    </div>}

                    {availableDeleteActivities !== undefined && availableDeleteActivities.length > 0 &&
                        availableDeleteActivities.map((activity, activity_index) => {
                            const activityStartingDayInTrip = tripNoDate === "1" ? "Day " + activity.activityDay
                                : moment(tripStartDate).add(activity.activityDay - 1, "days").format('ddd, MMMM Do');
                        //    const timeUpdate =    activity.activityNoTime === "0" && String(new Date('2020-01-01 ' + activity.activityStartTime)) !== "Invalid Date" ? new Date('2020-01-01 ' + activity.activityStartTime):null; 
                            const activityStartTime = activity && activity.activityTimeHuman && activity.activityNoTime === "0" ? "@"+ activity.activityTimeHuman: "";
                            return (
                                <div className="d-flex margin_bottom_10" key={activity_index} >
                                    <div style={{ flexGrow: "1", font: "18px/24px Futura Md BT" }}>{activityStartingDayInTrip + " " +  activityStartTime} </div>
                                    <DeleteActivity activity={activity} tripDestinationId={activity.tripDestinationId} fetchingStatus={false} from={"modal"} />
                                </div>
                            )
                        })}
                    <DeleteActivity availableDeleteActivities={availableDeleteActivities} fetchingStatus={false} from={"modal_delete_all"} />

                </div>

            </Modal>
        </div>
    )
}

const mapStateToProps = (state) => ({

    activeTrip: state.FormBank.TimelineWizard.activeTrip,
})

const mapDispatchToProps = {

}

const AddedToTrip = connect(mapStateToProps, mapDispatchToProps)(AddedToTrip_)
export default AddedToTrip 
