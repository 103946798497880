import * as Actions from '../../../redux/actions'

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { EraserDuotoneIcon, TimesIcon } from "./SVGBank"
import React from 'react';
import { TripNameTextField } from '../Common/FormComponentsBank'
import { connect } from "react-redux";

/* modify trip name dialog function component */

const ModifyTripNameDialog_ = ({ from, setToggleEllipsisCloseDisable, screenSize,setToggleEllipsisCloseEnable, toggle_ellipsis_close_disable, inline }) => {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = (event) => {
        event.stopPropagation()
        event.preventDefault()
        setToggleEllipsisCloseDisable()
        setOpen(true);
    };

    const handleClose = () => {
        setToggleEllipsisCloseEnable()
        setOpen(false);
    };

    return (
        <div>
            <div style={{ zIndex: "1" }} onClick={handleClickOpen}>
                {inline ? "" : <EraserDuotoneIcon />}
                {!inline && toggle_ellipsis_close_disable ? "" : from}
            </div>
            <Dialog open={open}
                aria-labelledby={open ? 'form_dialog_title' : undefined}
            >
                <DialogTitle id={open ? "form_dialog_title" : undefined}>{" Modify Trip Name"}
                
                <div onClick={handleClose} style={{
                     float: "right",
                     display: "inline-block",
                }}>{screenSize > 1024 ? <TimesIcon width={20} height={20} /> : <TimesIcon width={15} height={15} />}</div>

                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {"To modify, please enter trip name here."}
                    </DialogContentText>
                    <div className="d-flex">
                        <EraserDuotoneIcon style={{ marginTop: "7px", marginRight: "10px" }} />
                        <TripNameTextField inputStyle={{
                            font: "18px/20px Futura Md BT",
                            color: "var(--frameBlack)",
                            width: "300px",
                        }} />
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleClose} color="primary">
                          Ok
                     </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

const mapStateToProps = state => ({
    lang: state.Setting.lang,
    uuid: state.Member.authModal.uuid,
    screenSize: state.Setting.htmlBody.bodyClientWidth,
    trip: state.Member.memberArea.selectedTrip,
    toggle_ellipsis_close_disable: state.Member.memberArea.toggle_ellipsis_close_disable,
});

const mapDispatchToProps = {
    setToggleEllipsisCloseDisable: Actions.setToggleEllipsisCloseDisable,
    setToggleEllipsisCloseEnable: Actions.setToggleEllipsisCloseEnable,
}
const ModifyTripNameDialog = connect(mapStateToProps, mapDispatchToProps)(ModifyTripNameDialog_);
export default ModifyTripNameDialog 