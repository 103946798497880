import { useDispatch, useSelector } from "react-redux";
import React, { useEffect } from 'react'
import { Grid } from "@material-ui/core";
import { RightArrowIcon } from "../../Common/SVGBank";
import moment from "moment";
import { getNearByDestinationCode } from "../../FlightsPage/services/Flights.thunks";
import { URL_REPLACE } from "../../Common/RegExValidate";
import { getNearByAirport } from "./payment.thunk";
import { setTimeLineStatus } from "../../../../redux/actions";
import { useHistory } from 'react-router-dom';

export const CongratulationsHotel = () => {
    const dispatch = useDispatch();
    const history = useHistory()
    const authModal = useSelector(state => state.Member.authModal);
    const pricelineBooking = useSelector(state => state.FormBank.BookingPriceline) ; 
    const { booking_info } = pricelineBooking;
    const checkin = booking_info && booking_info.check_in ? moment(booking_info.check_in).format('MMM Do') : null;
    const checkout = booking_info && booking_info.check_out ? moment(booking_info.check_out).format('MMM Do') : null;
    const hotelName = booking_info && booking_info.name ? booking_info.name : null;
    const bookingData = useSelector(state => state.HotelBooking.bookingData);
    const nearByDestination  = useSelector(state => state.Flights.nearByDestination);
    const activeTrip = useSelector(state=> state.FormBank.TimelineWizard.activeTrip);

    const firstName = authModal.firstName || '';
    const arrivalCity =  booking_info?.cityName || "";
    const bookingNumber  = bookingData?.book_data?.itinerary.id || "";
    const geo = bookingData?.contract_data?.hotel_data.geo || null;


    const bookingId = bookingNumber;
    const email = bookingData?.book_data?.itinerary_details?.customer?.email;
    const imageUrl = bookingData?.contract_data?.room_data?.photos?.photo_data?.photo_0;
    const canViewDetails = bookingId && email && imageUrl;

    const destinations = activeTrip.tripDestinations;
    const currDestination = destinations[0];

    useEffect(() => {
        if(geo){
            dispatch(getNearByAirport({
                lat: geo.latitude, lng: geo.longitude
            }));
        }
      }, [geo]);

    const goToDestination = () =>{
        if(currDestination){
            const url = "/destination/" + currDestination?.name.replace(URL_REPLACE, "-")  + "/" + 1 + "/" + currDestination?.destId;
            dispatch(setTimeLineStatus(true))
            history.push(url.toLowerCase());  
        }
    }

    const goToFlight = () =>{
        const url = "/flight/search";
        window.open(url.toLowerCase());   
    }

    const goToBooking  =() =>{
    
        if(canViewDetails){
            const url = `/hotel/booking_details?booking_id=${bookingId}&email=${email}&imageUrl=${imageUrl}`;
            window.open(url);   
        }
    }


    return (
        <Grid container style={{ padding: 20 }}>
            <Grid item  >
                <div>
                    <p style={{ font: '24px/24px Futura Hv BT' }}> Congratulations {firstName},</p>
                </div>

                <div>
                    <p style={{ marginTop: 20, font: '20px/20px Futura Md BT', lineHeight: '30px' }}>Your booking for {hotelName} from {checkin} to {checkout} is complete.</p>
               
               
                    <p style={{ marginTop: 20, font: '20px/20px Futura LT BT', lineHeight: '30px' }}>Your booking reference number is {bookingNumber}.</p>

                    <p style={{ marginTop: 20, font: '20px/20px Futura LT BT', lineHeight: '30px' }}>You will soon receive a confirmation email from Priceline,<br/> our booking partner with all your booking information.</p>

                    <p style={{ marginTop: 20, font: '20px/20px Futura MD BT', lineHeight: '30px' }}>You can safely continue planning your trip.</p>
                </div>

            </Grid>

            <Grid item style={{  marginTop: 60, width: '100%' }} >
                <div >

                    {canViewDetails && <button className='recapButtons' style={{ background: '#DFDFDF' }} onClick={goToBooking}>
                       See Booking Details
                    </button>}


                    <div style={{ float: "right",textAlign:"right" }}>
                    <button className='recapButtons' style={{ background: '#19BC9B', color: 'white', font: '14px/14px Futura Hv BT' }} onClick={goToFlight}>
                            <span style={{ paddingRight: 60, font: '14px/14px Futura Hv BT' }}>  Find a Flight  </span>
                            <RightArrowIcon height={14} width={14} />
                        </button>
                        <div onClick={goToDestination} style={{ marginTop: 10, font: '14px/14px Futura Hv BT' ,cursor:"pointer",}}>
                    <p>Skip to  <u> explore things to see & do in {arrivalCity}  </u></p>
                </div>
                    </div>
 

                </div> 
                

            </Grid>
        </Grid>
    )
}