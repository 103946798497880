import React, { Component } from 'react';
import { makeStyles } from "@material-ui/core/styles";

import './styles.css';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'grid',
    gridTemplateColumns: '50% 50%',
    alignItems: 'center',
    marginTop: 220,

    [theme.breakpoints.down('sm')]: {
      display: 'block',
      marginTop: 160
    },
    [theme.breakpoints.between('sm', 'md')]: {
      display: 'grid',
      gridTemplateColumns: '50% 50%',
      alignItems: 'center',
    },
  },
  stepBackgroundImage: {
    paddingRight: 40,
    [theme.breakpoints.down('sm')]: {
      paddingTop: 50,
      paddingRight: 0,
    },
  },
}));

export default function LandingStepTwo() {
  const classes = useStyles();
  const screenSize = document.body.clientWidth;
  let screen_at_500 = 500;

  return (
    <div className={classes.root}>
      {screenSize < screen_at_500 ?
        <div className="landing-step-container">
          <div className="step-description-section">
              <h1 className="step-number">2</h1>
              <div className="step-description">
                  <h2 className="step-title">Share your best spots</h2>
                  <p className="futura_lt_bt_20_30">Share with müvers your hometown favorites. Help them find out the best places to see, eat, shop, go out or just take a walk. Add photos, videos, notes and reviews and become a true inspiration to many. Tick off your travel favorites, they will love that too.</p>
              </div>
          </div>
        </div>
        : ""
      }

      <div className={classes.stepBackgroundImage}>
        <div style={{display: 'grid', gridTemplateColumns: '28% 28% 10% 28%', alignItems: 'center', justifyContent: 'space-between'}}>
          <img style={{width: '100%'}} src={require('../../../assets/images/spot1.png')}/>
          <img style={{width: '100%'}} src={require('../../../assets/images/spot2.png')}/>
          <img style={{width: '100%', transform: 'scale(4.5)'}} src={require('../../../assets/images/spot3.png')}/>
          <img style={{width: '100%'}} src={require('../../../assets/images/spot4.png')}/>
        </div>
      </div>

      {screenSize > screen_at_500 ?
        <div className="landing-step-container">
          <div className="step-description-section">
              <h1 className="step-number">2</h1>
              <div className="step-description">
                  <h2 className="step-title">Share your best spots</h2>
                  <p className="futura_lt_bt_20_30">Share with müvers your hometown favorites. Help them find out the best places to see, eat, shop, go out or just take a walk. Add photos, videos, notes and reviews and become a true inspiration to many. Tick off your travel favorites, they will love that too.</p>
              </div>
          </div>
        </div>
        : ""
      }
    </div>
  )
}
