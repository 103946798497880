import * as Actions from '../../../redux/actions'

import React from 'react'
import ScrollToTop from "../Common/ScrollToTop"
import TripSchedule from '../Common/Trip/TripSchedule'
import {
    airportAutocompleteUseStyles,
} from "../Common/ComponentsStylingBank"
import { connect } from 'react-redux'
import { list_container_mode } from '../Common/RegExValidate'
import { tripDescriptionMultiLineTextFieldUseStyles } from '../Common/ComponentsStylingBank'


/* member trip sun container component */
const PrintTripSubContainer_ = ({ screenSize, trip, tripMode, app_menu_height, is_mobile, logo_width, logo_height, setTripDisplayMode, tripNoDate, switch_container }) => {
    const classesMultiLineTextField = tripDescriptionMultiLineTextFieldUseStyles();
    const [open, setOpen] = React.useState(false)
    const classesAutocomplete = airportAutocompleteUseStyles()

    const handleClose = event => {
        setOpen(false);
        document.getElementById("trip_description_bar").scrollIntoView({ behavior: 'smooth', block: 'start' })
    };

    let position = "sticky"
    let logoH = logo_width
    let w1 = 10
    let w2 = 60
    let w3 = 70
    let border_size = 8
    let margin_right = 0
    let margin_left = 0
    let margin_top = 30
    if (!isNaN(screenSize)) {
        margin_left = logoH
        margin_right = logoH
        if (screenSize <= 1024) {
            w1 = 10
            w2 = 60
            w3 = 70
            margin_top = 15
        }
        if (screenSize < 500) {
            w1 = 25
            w2 = 0

            border_size = 4
            margin_right = 5
            margin_left = 5
            margin_top = 0
        }
    }


    return (
        <React.Fragment>
            
                <div id={"trip_description_bar"} style={{
                    backgroundColor: "#C7C7C7",
                    paddingLeft: "0px",
                    marginLeft: "0px",
                    marginRight: tripMode === list_container_mode.schedule ? margin_right : "0px"
                }} >

                   
                    
                    {tripMode === list_container_mode.schedule &&
                        <div style={{ backgroundColor: "#FFFFFF" }}>
                            <TripSchedule />
                        </div>
                    }
                
                    <ScrollToTop />
                    <style dangerouslySetInnerHTML={{
                __html: `
                   .addActivity{
                       display:none
                   }
                   .dayHeader{
                    position: initial !important;
                   }
                `}} />
                </div >
        </React.Fragment >
    )
}

const mapStateToProps = state => {
    return {
        screenSize: state.Setting.htmlBody.bodyClientWidth,
        is_mobile: state.Setting.is_mobile,
        app_menu_height: state.Setting.appMenu.app_menu_height,
        logo_width: state.Setting.appMenu.logo.width,
        trip: state.Member.memberArea.selectedTrip,
        tripMode: state.Member.tripMode,
        logo_height: state.Setting.appMenu.logo.height,


        tripNoDate: state.FormBank.TimelineWizard.activeTrip.tripNoDate,
        switch_container: state.Member.switch_container,
    }
}
const mapDispatchToProps = {
    setTripDisplayMode: Actions.setTripDisplayMode,
}

const PrintTripSubContainer = connect(mapStateToProps, mapDispatchToProps)(PrintTripSubContainer_)
export default PrintTripSubContainer 
