import * as Actions from '../../../redux/actions'

import { MinusSignIcon, PlusSignIcon } from "../Common/SVGBank"
import React, { Component } from 'react'

import { connect } from 'react-redux'

class DestinationsFilter_ extends Component {
    constructor(props) {
        super(props)
        this.state = { extender: false }
    }
    render() {
        const { feature, addSelectedDestinationsFeature,fetchTypesAndSubtypesFeature, deleteSelectedDestinationsFeature, cleanSelectedDestinationsFeature, is_mobile, switchFeatureContainer, activeFeatureId,lang} = this.props
        const { loading, destinations } = feature
        const { selectedDestinations } = loading
        const defaultDisplay = 6
        if (!isNaN(is_mobile)) {
        }

        const destinationsSorted = [...destinations].sort(function (a, b) {
            if (a.destinationTitle.toLowerCase() < b.destinationTitle.toLowerCase()) return -1;
            if (a.destinationTitle.toLowerCase() > b.destinationTitle.toLowerCase()) return 1;
            return 0;
        })

        const selectDestination= (isChecked,destination) =>{
            if(isChecked){
                deleteSelectedDestinationsFeature(destination, selectedDestinations, destinations)
            }
            else{
                addSelectedDestinationsFeature(destination.destinationId, selectedDestinations, destinations)               
            }
            fetchTypesAndSubtypesFeature(activeFeatureId, lang,null)
         
        }

        const clearSelection= () =>{
            cleanSelectedDestinationsFeature()
            fetchTypesAndSubtypesFeature(activeFeatureId, lang,null)
        }
        return (
            <div className="d-flex margin_top_20"
                style={{
                    maxHeight: "105px",
                    overflowY: this.state.extender ? "scroll" : "",
                }}>
                <div style={{ flexGrow: "1" }}>
                    {destinations !== undefined
                        && destinations.length > 0
                        && <div
                            onClick={() => {clearSelection()}}
                            style={{
                                backgroundColor: selectedDestinations.length === 0 ? switchFeatureContainer.color + "35" : "#F8F8F8",
                                color: "var(--frameBlack)",
                                border: "0px solid #E8E8E8",
                                height: "30px",
                                display: "inline-flex",
                                borderRadius: "25px",
                                flexWrap: "wrap",
                                cursor: "pointer",
                                verticalAlign: "middle",
                                padding: ".375rem .75rem",
                                marginRight: "6px",
                                marginBottom: "5px",
                            }}>
                            <div style={{
                                font: "16px/18px Futura Lt BT",
                                color: "var(--frameBlack)",
                            }}>{"All"}</div>
                        </div>}
                    {
                        destinations !== undefined
                        && destinations.length > 0
                        && destinations.map((destination, destination_index) => {
                            const isChecked = selectedDestinations.find(d => d.id === destination.destinationId) !== undefined
                            return (
                                (destination_index < defaultDisplay && !this.state.extender) &&
                                <div 
                                    key={destination_index}
                                    onClick={() => selectDestination(isChecked,destination)}
                                    style={{
                                        backgroundColor: isChecked ? switchFeatureContainer.color + "35" : "#F8F8F8",
                                        color: "var(--frameBlack)",
                                        border: "0px solid #E8E8E8",
                                        height: "30px",
                                        display: "inline-flex",
                                        borderRadius: "25px",
                                        flexWrap: "wrap",
                                        cursor: "pointer",
                                        verticalAlign: "middle",
                                        padding: ".375rem .75rem",
                                        marginRight: "6px",
                                        marginBottom: "5px",
                                    }}>

                                    <div style={{
                                        font: "16px/18px Futura Lt BT",
                                        color: "var(--frameBlack)",
                                    }}>{destination.destinationTitle}</div>
                                </div>
                            )
                        })
                    }
                    {
                        destinationsSorted !== undefined
                        && destinationsSorted.length > 0
                        && destinationsSorted.map((destination, destination_index) => {
                            const isChecked = selectedDestinations.find(d => d.id === destination.destinationId) !== undefined
                            return (
                                (this.state.extender) &&
                                <div
                                    key={destination_index}
                                    onClick={() => selectDestination(isChecked,destination)}
                                    style={{
                                        backgroundColor: isChecked ? switchFeatureContainer.color + "35" : "#F8F8F8",
                                        color: "var(--frameBlack)",
                                        border: "0px solid #E8E8E8",
                                        height: "30px",
                                        display: "inline-flex",
                                        borderRadius: "25px",
                                        flexWrap: "wrap",
                                        cursor: "pointer",
                                        verticalAlign: "middle",
                                        padding: ".375rem .75rem",
                                        marginRight: "6px",
                                        marginBottom: "5px",
                                    }}>

                                    <div style={{
                                        font: "16px/18px Futura Lt BT",
                                        color: "var(--frameBlack)",
                                    }}>{destination.destinationTitle}</div>
                                </div>
                            )
                        })
                    }
                    {feature.destinations.length > defaultDisplay && !this.state.extender && <div onClick={() => this.setState({ extender: true })} style={{ display: "inline-block", }}><PlusSignIcon fill={switchFeatureContainer.color} /></div>}
                    {feature.destinations.length > defaultDisplay && this.state.extender && <div onClick={() => this.setState({ extender: false })} style={{ display: "inline-block", }}><MinusSignIcon fill={switchFeatureContainer.color} /></div>}
                </div>
            </div >
        )
    }
}

const mapStateToProps = state => {
    return {
        feature: state.Feature.feature,
        is_mobile: state.Setting.is_mobile,
        activeFeatureId: state.Feature.feature.activeId,
        lang: state.Setting.lang,
        switchFeatureContainer: state.FormBank.FeatureFormat,

    }
}
const mapDispatchToProps = {
    addSelectedDestinationsFeature: Actions.addSelectedDestinationsFeature,
    deleteSelectedDestinationsFeature: Actions.deleteSelectedDestinationsFeature,
    cleanSelectedDestinationsFeature: Actions.cleanSelectedDestinationsFeature,
    fetchTypesAndSubtypesFeature: Actions.fetchTypesAndSubtypesFeature
}

const DestinationsFilter = connect(mapStateToProps, mapDispatchToProps)(DestinationsFilter_)
export default DestinationsFilter
