import React, { Component } from 'react'

import MenuMember from "../../Common/MenuMember.jsx"
import ScrollToTop from "../../Common/ScrollToTop"
import { connect, useDispatch, useSelector } from 'react-redux'
import MuvTribeFriends from './MuvTribeFriends.jsx'
import MuvTribeFollowers from './MuvTribeFollowers.jsx'
import { clearMuvtribe, getFriendRequests, getPendingFriendRequests, getRelatedInfluencers, getUserFollowers, getUserFollowing, inviteFriends } from '../../SocialPage/social.slice.jsx'
import Influencers from '../../Common/Influencers/Influencers.jsx'
import { InputBase, makeStyles } from '@material-ui/core'
import MuvTribeFollowSlider from './MuvTribeFollowSlider.jsx'
import MuvTribeFriendRequests from './MuvTribeFriendRequests.jsx'
import { TimesIcon } from '../../Common/SVGBank.jsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faPaperPlaneTop } from '@fortawesome/free-solid-svg-icons'


const searchStyles = makeStyles((theme) => ({
    title: {
        flexGrow: 1,
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
    search: {
        background: "#FFFFFF 0% 0% no-repeat padding-box",
        boxShadow: "0px 3px 6px #00000029",
        border: "1px solid #B1B1B1",
        borderRadius: "20px",
        position: 'relative',
        marginTop: "15px",
        [theme.breakpoints.up('md')]: {
            // marginLeft: theme.spacing(1),
            width: '50%',
        },
        height: '45px',
        width: "100%",
    },
    inputRoot: {
        color: 'var(--bgBlack)',
        font: "16px/16px Futura Lt BT",
        width: "100%"
    },
    inputInput: {
        padding: theme.spacing(1.4, 1, 1, 3),
        // vertical padding + font size from searchIcon
        transition: theme.transitions.create('width'),
        width: '100%',

    },
}));

/* muv tribe page container component */
const MuvTribePageContainer_ = ({ uuid, followersSets, influencers, screenSize, app_menu_height, is_mobile, logo_height }) => {

    const dispatch = useDispatch()
    const relatedInfluencers = useSelector(state => state.SocialPage.muvTribe?.relatedInfluencers);
    const userFollowing = useSelector(state => state.SocialPage.muvTribe?.following);
    const [query, setQuery] = React.useState("")
    const classes = searchStyles()
    React.useEffect(() => {

        return () => {
            dispatch(clearMuvtribe())
        }
    }, [])


    React.useEffect(() => {
        if (uuid) {
            dispatch(getUserFollowers(uuid))
            dispatch(getUserFollowing(uuid))
            dispatch(getRelatedInfluencers(uuid))
            dispatch(getPendingFriendRequests(uuid))
            dispatch(getFriendRequests(uuid))
        }

    }, [uuid, followersSets])




    let margin_left = 0
    let margin_right = 0
    let padding_left = 0
    let padding_right = 0
    let position = "sticky"
    let top_H = 180
    let font_size = "50"
    if (!isNaN(screenSize)) {
        padding_left = screenSize <= 768 ? "4px" : "40px"
        padding_right = screenSize <= 768 ? "4px" : "40px"
        font_size = screenSize <= 768 ? 25 : 50
        margin_left = screenSize > 1200 ? (screenSize / 100 * 5) + 430 : (screenSize <= 768 ? 0 : (screenSize / 100 * 4) + 350)
        margin_right = (screenSize <= 768) ? 0 : (screenSize / 100 * 8.5)
    }


    const handleInvite = event => {
        let search = event.target.value
        search = search ? search?.toLowerCase() : search
        setQuery(search)
    }

    const inviteUsers = async () => {
        const response = await dispatch(inviteFriends({
            uuid: uuid,
            emails: query
        }))
        const success = response?.payload?.status === "success" || response?.meta?.requestStatus === "fulfilled";
        if (success) {
            setQuery("")
        }
    }

    return (

        <div style={{ top: top_H, position: "relative", marginLeft: margin_left, marginRight: margin_right, backgroundColor: "#191919" }} className="padding_top_20">
            <div style={{
                position: position, zIndex: "3",
                top: is_mobile ? 0 : (-Math.ceil(-app_menu_height / 2))
            }}>
                <div className="padding_top_10 padding_left_20 padding_right_20" style={{ backgroundColor: "#191919" }}>
                    <MenuMember />
                </div>
            </div>
            <div className="padding_top_50 padding_125rem_lr_mobile"
                style={{
                    paddingLeft: padding_left,
                    paddingRight: padding_right,
                    color: "var(--mainWhite)",
                }}
            >
                <div className=""
                    style={{
                        color: "var(--mainWhite)",
                        font: font_size + "px/72px Futura Hv BT",
                    }}
                >
                    My müvTribe
            </div>

                <div className=""
                    style={{
                        marginTop: 30
                    }}
                >


                    <div className=""
                        style={{
                            color: "var(--mainWhite)",
                            font: "18px/10px Futura Lt BT",
                        }}
                    >
                        Invite friends to join your müvTribe
            </div>

                    <div style={{display:'flex'}}>
                    <div className={classes.search} >
                        <InputBase
                            placeholder="Enter müvHandles or emails comma separated"
                            classes={{
                                root: classes.inputRoot,
                                input: classes.inputInput,
                            }}
                            inputProps={{ 'aria-label': 'Enter emails comma separated' }}
                            onChange={handleInvite}
                            value={query}
                            endAdornment={
                                <React.Fragment>
                                    {query && query.length > 0 && <div style={{ cursor: "pointer", marginRight: 20, borderRadius: '50%', background: 'white' }} onClick={() => { setQuery("") }} >
                                        <TimesIcon width={15} height={15} /></div>}
                                </React.Fragment>

                            }
                            onKeyPress={(ev) => {
                                if (ev.key === 'Enter') {
                                    inviteUsers()
                                    ev.preventDefault();
                                }
                            }}
                        />

                    
                    </div>

                
                    <div style={{
                       backgroundColor:query && query.length >1 ?  "var(--mainGreen)": "#E8E8E8",
                      color: query && query.length >1 ?  "white": "#191919CC",
                      font: "14px/16px Futura Hv BT",
                      borderRadius: 25,
                      marginRight: 5,
                      marginBottom: 5,
                      height: 45,
                      width: "fit-content",
                      padding: "15px 25px 0px 50px",
                      marginTop: 15,
                      marginLeft:-39,
                      cursor: 'pointer'
                    }}
                    onClick={()=>{
                        inviteUsers()
                    }}>
                      Send
                    </div>
                    </div>
                    


                    {true && <MuvTribeFriendRequests
                        title="Friend Requests" friendRequests={[]} />
                    }
                    <MuvTribeFriends />
                    <MuvTribeFollowers />

                    {relatedInfluencers && relatedInfluencers?.length > 0 && <React.Fragment>
                        <hr className="line_separator margin_top_20" />
                        <Influencers from={"social"} list={relatedInfluencers} />
                    </React.Fragment>}

                </div>




            </div>

            <ScrollToTop />
        </div>
    )
}

const mapStateToProps = state => {
    return {
        muver: state.Member.socialMember.muver,
        screenSize: state.Setting.htmlBody.bodyClientWidth,
        is_mobile: state.Setting.is_mobile,
        app_menu_height: state.Setting.appMenu.app_menu_height,
        logo_height: state.Setting.appMenu.logo.height,
        influencers: state.Member.socialMember.influencers,
        uuid: state.Member.authModal.uuid,
        followersSets: state.MemberActions.fetchFollowers.followersSets,
    }
};



const MuvTribePageContainer = connect(mapStateToProps, null)(MuvTribePageContainer_)
export default MuvTribePageContainer
