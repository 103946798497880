import { Grid } from "@material-ui/core"
import moment from "moment"
import React from 'react'
import { DurationLayover, secondsToHms } from "../../../../utility/utility"

export const FlightRecapComponent = ({ flightData, title }) => {
    var windowWidth = window.screen.width < window.outerWidth ?
        window.screen.width : window.outerWidth;
    var mobile = windowWidth < 500;

    return (
        <Grid container >
            <div style={{ paddingBottom: 20, paddingLeft: mobile? 0:50, paddingTop: 30 }}>
                <span style={{ font: "24px/24px Futura Hv BT", paddingRight: mobile?0:20, paddingLeft: 40 }}>{title} </span>
                <span style={{ font: mobile? "16px/16px Futura LT BT": "24px/24px Futura LT BT", textTransform: 'capitalize' }}>{flightData.departure.airport.code}-{flightData.arrival.airport.code} </span>
                <span style={{ font:  mobile? "12px/12px Futura LT BT": "18px/18px Futura LT BT", textTransform: 'capitalize' }}>({secondsToHms(flightData.info.duration)})</span>

            </div>
            { flightData && flightData.flight_data.map((flight, flightIndex) => {

                let DataComponent = [];
                const previous = flightIndex !== 0 && flightData.flight_data.length !== 1 ? flightData.flight_data[flightIndex - 1] : null;

                DataComponent.push(
                    <FlightRecapItem key={flightIndex + "flight"} index={flightIndex} flight={flight} flightData={flightData} previous={previous} />
                )

                return DataComponent;
            })}
        </Grid>

    )
}

export const FlightRecapItem = ({ flight, index, flightData, previous }) => {

    var windowWidth = window.screen.width < window.outerWidth ?
    window.screen.width : window.outerWidth;
var mobile = windowWidth < 500;


    const departureDate = moment(flight.departure.datetime.date).format('ddd, MMM. Do');
    const arrivalDate = moment(flight.arrival.datetime.date).format('ddd, MMM. Do');

    let airlineData = `${flight.info.marketing_airline} ${flight.info.marketing_airline_code}${flight.info.flight_number} - ${flight.info.aircraft_type} - ${flight.info.aircraft}`;

    if (flight?.info?.marketing_airline !== flight?.info?.operating_airline_code && flight?.info?.operating_airline_code.length > 0) {
        airlineData = `${flight.info.marketing_airline} - ${flight.info.marketing_airline_code}${flight.info.flight_number} - Operated by - ${flight.info.operating_airline}`;
    }

    return (
        <Grid container spacing={0} style={{ paddingTop: 10, paddingBottom: 10 }} >

            { previous && flightData.flight_data.length > 1 &&
                <LayoverComponent key={index + "layover"} flight={flight} previous={previous} flightData={flightData} />}

            <Grid container spacing={0} style={{ paddingLeft: mobile?40:50 }} >

                <Grid item lg={2} xs={12} md={12} style={{ paddingBottom: 10, textAlign: mobile? 'left':'center' }} >
                    <div style={{ font: "14px/14px Futura Md BT", lineHeight: 1.6 }}>  {departureDate} </div>
                    {index === flightData.flight_data.length - 1 && <div style={{ font: "12px/12px Futura LT BT", lineHeight: 1.6, color: 'red' }}> Lands {arrivalDate}  </div>}
                </Grid>

                <Grid item lg={2} style={{ textAlign: 'center', maxWidth: 60, paddingBottom: 10, }}  >
                    <img src={flightData.airline.logo} height={25} alt="logo" style={{}} />
                </Grid>

                <Grid item lg={7} xs={12} sm={12} md={12} style={{ textAlign: 'left', paddingBottom: 10, font: "14px/14px Futura Md BT" }}  >


                    <div style={{ lineHeight: 1.4 }}> {flight.departure.datetime.time_12h} -  {flight.arrival.datetime.time_12h}</div>
                    <div style={{ lineHeight: 1.4, marginBottom: 7 }}>  {flight.departure.airport.city} ({flight.departure.airport.code}) - {flight.arrival.airport.city} ({flight.arrival.airport.code}) </div>
                    <div style={{ lineHeight: 1.6, color: '#7F7F7F' }}> {airlineData}</div>

                </Grid>

                <Grid item lg={1} xs={12} sm={12} md={12} style={{ paddingBottom: 10 }} >
                    <div style={{ font: "14px/14px Futura Md BT", lineHeight: 1.6 }}>  {secondsToHms(flight.info.duration)} </div>
                    <div style={{ font: "14px/14px Futura Md BT", color: '#7F7F7F', lineHeight: 1.6 }}>  {flight.info.cabin_name} </div>

                </Grid>

            </Grid>
        </Grid>
    )
}

export const LayoverComponent = ({ flight, previous }) => {
    const notes = previous?.info?.notes;
    return (
        <Grid container spacing={0} style={{ background: '#F4F9FC', width: '100%', padding: 10, paddingLeft: 50, marginBottom: 20 }} >

            <Grid item lg={2} xs={12} md={12} >
            </Grid>
            <Grid item lg={2} style={{ maxWidth: 60 }}  >
            </Grid>

            <Grid item lg={7} xs={6} sm={6} md={6} style={{ textAlign: 'left', font: "14px/14px Futura LT BT" }}  >
                {/* <div style={{ lineHeight: 1.4 }}> Layover in {flight.departure.airport.city} ({flight.departure.airport.code})</div> */}
                {notes &&
                    Object.keys(notes).map(key => {
                        const val = notes[key]

                        return (
                            <p style={{ marginTop: 5, lineHeight: 1.4, marginBottom: 5, width: "100%", font: "14px/14px Futura  Lt BT" }}> {val?.value}</p>
                        )
                    })
                }
            </Grid>

            <Grid item lg={1} xs={6} sm={6} md={6} style={{ paddingLeft: 7 }} >
                <div style={{ font: "14px/14px Futura LT BT", lineHeight: 1.6 }}>  {DurationLayover(previous.arrival.datetime.date_time, flight.departure.datetime.date_time)}m </div>
            </Grid>



        </Grid>
    )
}