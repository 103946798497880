import * as Actions from '../../../../redux/actions'

import { StarEmptyIcon, StarIcon } from '../SVGBank'

import React from 'react'
import { connect } from "react-redux"
import { LightTooltip } from './tooltipActions'

export const Star = connect((state) => ({
    screenSize: state.Setting.htmlBody.bodyClientWidth,
    isMobile: state.Setting.is_mobile,
    fetchMemeberActions: state.MemberActions.fetchMemeberActions,
    cookiesMemberActionSets: state.MemberActions.cookiesMemberActionSets,
    fetchFeatures: state.MemberActions.fetchFeatures,
    fetchStarSets: state.MemberActions.fetchStarSets,
    uuid: state.Member.authModal.uuid,
}), {
    addingStar: Actions.addingStar,
    deletingStar: Actions.deletingStar,
})(({
    fill,
    uuid,
    feature,
    // isMobile,
    // fetchMemeberActions,
    fetchFeatures,
    fetchStarSets,
    // screenSize,
    cookiesMemberActionSets,
    addingStar,
    deletingStar,
    unitID,

}) => {

    const unique = unitID + "_" + feature.featureId + "_star"
    return (
        <div style={{
            font: "14px / 14px Futura Lt BT",
        }}>

            {uuid !== undefined && uuid !== "" ?
                <div>
                     <LightTooltip title={
                          fetchStarSets.stars.filter(star => star.featureUnitId + "_" + star.featureId + "_star" === unique).length <= 0 ? "Star it": "Starred"
                     } placement="top-start">
                    {fetchStarSets !== undefined && fetchFeatures.isFetched &&
                        fetchStarSets.stars.filter(star => star.featureUnitId + "_" + star.featureId + "_star" === unique).length <= 0 ?
                        <div style={{ cursor: "pointer" }} onClick={() => addingStar(uuid, unitID, feature, cookiesMemberActionSets)}>
                            <StarEmptyIcon fill={fill} width={25} height={25} />
                        </div>
                        :
                        <div style={{ cursor: "pointer" }} onClick={() => deletingStar(uuid, unitID, feature, cookiesMemberActionSets,'star')}>
                            <StarIcon fill={fill} width={25} height={25} />
                        </div>
                    }
                       </LightTooltip>
                </div>
                :
                <div>
                       <LightTooltip title={
                          cookiesMemberActionSets.filter(star => star.key === unique).length <= 0 ? "Star it": "Starred"
                     } placement="top-start">
                    {cookiesMemberActionSets !== undefined &&
                        cookiesMemberActionSets.filter(star => star.key === unique).length <= 0 ?
                        <div style={{ cursor: "pointer" }} onClick={() => addingStar(uuid, unitID, feature, cookiesMemberActionSets)}>
                            <StarEmptyIcon fill={fill} width={25} height={25} />
                        </div>
                        :
                        <div style={{ cursor: "pointer" }} onClick={() => deletingStar(uuid, unitID, feature, cookiesMemberActionSets,'star')}>
                            <StarIcon fill={fill} width={25} height={25} />
                        </div>
                        
                    }
                    </LightTooltip>
                </div>
            }
        </div>
    )
})