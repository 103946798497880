import * as Actions from '../../../redux/actions'

import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import React from 'react'
import { connect } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';

/* css styleing injection function */
const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex'
    },
    button_text: {
        color: "var(--mainGreen)",
        textAlign: "left",
        font: "16px/22px Futura Md BT",
        letterSpacing: "0",
        padding: "5px",
        '&:focus': {
            border: "0",
            outline: "0px"
        }
    },
    zIndex: {
        zIndex: "1"
    },
    paddingLeft30: {
        paddingLeft: "30px"
    },
    backgroundSelection: {
        '&:hover': {
            backgroundColor: "var(--mainGreen)"
        }
    },
    paper: {
        marginRight: theme.spacing(2),
    },
}));

/* menu list compo */
const MenuListComposition_ = ( { sortTripsListByDateAscending, sortTripsListByDateDescending } ) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);

    const handleToggle = () => {
        setOpen(prevOpen => !prevOpen);
    };

    const handleClose = event => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    const handleListKeyDown = (event) => {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        }
    }
    
    const handleListSelected = (event) => {
        handleClose(event)
        switch (event.target.innerText) {
            case "Date Descending":
                console.log("Date Descending")
                sortTripsListByDateDescending()
                return
            case "Date Ascending":
                    console.log("Date Ascending")
                sortTripsListByDateAscending()
                return
            default:
                return ""
        }
    }

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    return (
        <div className={classes.root}>

            <div>
                <Button
                    ref={anchorRef}
                    aria-controls={open ? 'menu-list-sort-trips' : undefined}
                    aria-haspopup="true"
                    onClick={handleToggle}
                    className={classes.button_text}
                >
                    Sort by <span className={classes.paddingLeft30}>V</span>
                </Button>
                <ClickAwayListener onClickAway={handleClose}>
                    <Popper open={open} anchorEl={anchorRef.current} role={undefined} className={classes.zIndex} transition disablePortal>
                        {({ TransitionProps, placement }) => (
                            <Grow
                                {...TransitionProps}
                                style={{ transformOrigin: placement === 'bottom' ? 'left top' : 'left bottom' }}
                            >
                                <Paper>
                                    <MenuList autoFocusItem={open} id="menu-list-sort-trips" onKeyDown={handleListKeyDown}>
                                        <MenuItem className={classes.backgroundSelection} onClick={handleListSelected}>Date Descending</MenuItem>
                                        <MenuItem className={classes.backgroundSelection} onClick={handleListSelected}>Date Ascending</MenuItem>
                                        <MenuItem className={classes.backgroundSelection} onClick={handleListSelected}>Destinaiton (A-Z)</MenuItem>
                                        <MenuItem className={classes.backgroundSelection} onClick={handleListSelected}>Destinaiton (Z-A)</MenuItem>
                                        <MenuItem className={classes.backgroundSelection} onClick={handleListSelected}>Continent</MenuItem>
                                    </MenuList>
                                </Paper>
                            </Grow>
                        )}
                    </Popper>

                </ClickAwayListener>
            </div>
        </div>
    );
}

const mapDispatchToProps = {
    sortTripsListByDateDescending: Actions.sortTripsListByDateDescending,
    sortTripsListByDateAscending: Actions.sortTripsListByDateAscending,
}
const MenuListComposition = connect(null, mapDispatchToProps)(MenuListComposition_);
export default MenuListComposition 