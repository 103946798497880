import * as Actions from '../../../redux/actions'

import React, { Component, useRef } from 'react'
import { validate, validate_fields } from "../Common/RegExValidate"
import PageNotFound from '../ErrorPage/PageNotFound'
import { Redirect } from "react-router-dom"
import { connect } from 'react-redux'
import { list_container_mode } from '../Common/RegExValidate'
import { member_area_router } from "../Common/RegExValidate"
import PrintContainer from './PrintContainer';
import { TripImageBar } from '../TripPage/TripImageBar';
import PrintHeader from './PrintHeader';
import ReactToPrint, { useReactToPrint } from 'react-to-print';
import { PrintIcon } from '../Common/SVGBank';
import { Button } from '@material-ui/core';
import { PrintImageBar } from './PrintImageBar';
class PrintPage_ extends Component {
    /* update screen size */
    updateDimensions = () => {
        this.props.setScreenSize(window.screen.width, window.screen.height)
        this.props.setBodyClientSize(document.body.clientWidth, document.documentElement.scrollHeight)
        this.props.setAppMenu(document.body.clientWidth)
    }
    /* initial component */
    componentDidMount() {
        window.addEventListener("resize", this.updateDimensions)
        this.updateDimensions()

        this.props.setPageYOffset(0)
        this.props.setSwitchContainer(member_area_router.destination)
        this.props.setAppMenuBookingButtonStatus(true)
        this.props.setTripDisplayMode(list_container_mode.schedule)

    }
    /* destory component */
    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions)
        this.props.cleanSearchQueryDestination()
        this.props.clearFeatureFormat()
    }
    render() {
        const lang = this.props.lang
        const { preload, switch_container, destinaionListDisplayFormat, tripMode } = this.props


        return (
            validate(lang, validate_fields.languages) ?
                <div >
                    <PrintHeader bg_color={"var(--mainWhite)"} text_color={"var(--mainWhite)"} />
                    <PrintImageBar className="bg_white" from={"trip_private"} />
                   
                    <PrintContainer props={this.props} /> 

                </div>
                :
                <Redirect to={"error"} component={PageNotFound} />
        )
    }
}


const mapStateToProps = state => {
    return {
        destinaionListDisplayFormat: state.Destination.destination.destinaionListDisplayFormat,
        preload: state.Setting.preload,
        switch_container: state.Member.switch_container,

        lang: state.Setting.lang,
        tripMode: state.Member.tripMode,
    }
}

const mapDispatchToProps = {
    setBodyClientSize: Actions.setBodyClientSize,

    setScreenSize: Actions.setScreenSize,
    setAppMenu: Actions.setAppMenu,
    setScreenVerticalIsTop: Actions.setScreenVerticalIsTop,
    setAppMenuBookingButtonStatus: Actions.setAppMenuBookingButtonStatus,

    setPageYOffset: Actions.setPageYOffset,
    cleanSearchQueryDestination: Actions.cleanSearchQueryDestination,
    setSwitchContainer: Actions.setSwitchContainer,
    setTripDisplayMode: Actions.setTripDisplayMode,
    clearFeatureFormat: Actions.clearFeatureFormat,

}

const PrintPage = connect(mapStateToProps, mapDispatchToProps)(PrintPage_)
export default PrintPage
