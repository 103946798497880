import { Backdrop, makeStyles } from '@material-ui/core';
import React from 'react';
import { RightArrowIcon, TimesIcon } from '../Common/SVGBank';
import { useHistory } from "react-router-dom";

import { connect, useDispatch, useSelector } from 'react-redux'
import { setExpiredFlight } from './services/Flights.slice';

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

export const FlightExpired = () => {
    const history = useHistory();
    const dispatch = useDispatch()

    const relaunchSearch = () =>{
        closeExpired()
        history.push('/flight/search')
    }
    const expired = useSelector(state => state.Flights.showExpired);
    const bookingFailedFlight = useSelector(state => state.Flights.bookingFailed);
    const bookingMessageFlight = useSelector(state => state.Flights.bookingMessage);

    const classes = useStyles();

    const closeExpired = ()=>{
        dispatch(setExpiredFlight(false))
    }

  
    return (
        <div>

            {bookingFailedFlight  && 
             <Backdrop className={classes.backdrop} open={bookingFailedFlight} >
            <div style={{

            }} className="flightExpired">

                <div className="expiredTop">
                    <div  onClick={closeExpired} ><TimesIcon height={30} width={30} style={{ position: 'absolute',right:30, top: 30,cursor: "pointer",}}/></div>
                    <h1> Sorry, we cannot complete your booking at this time </h1>
                </div>
                <div className="expiredBottom">
                    <p>
                        {bookingMessageFlight}
                    </p>

                    <div style={{ float: "right" }}>
                        <button className='recapButtons' style={{ background: '#19BC9B', color: 'white', font: '14px/14px Futura Hv BT',
                        marginRight: 35 , marginTop: -10}} onClick={relaunchSearch}>
                            Relaunch Search <RightArrowIcon height={14} width={14} style={{ marginLeft: 20 }} />
                        </button>
                    </div>

                </div>
            </div>
            </Backdrop>}
        </div>)

}
