import {
    IS_EXTANSION_TIMELINE_BAR_CLOSE,
    IS_EXTANSION_TIMELINE_BAR_OPEN,
} from "../interfaces/types"

const initialState = {
    isExtansionTimelineBar: false,
};

/* is show reducer */
export default (state = initialState, action) => {
    switch (action.type) {
        case IS_EXTANSION_TIMELINE_BAR_CLOSE:
            return {
                ...state,
                isExtansionTimelineBar: false
            };
        case IS_EXTANSION_TIMELINE_BAR_OPEN:
            return {
                ...state,
                isExtansionTimelineBar: true
            };


        default:
            return {
                ...state
            }
    }
}