import BioForm from "../../Common/Profile/BioForm.jsx"
import MenuMember from "../../Common/MenuMember.jsx"
import MenuProfile from "./MenuProfile"
import PersonalizationForm from "../../Common/Profile/PersonalizationForm.jsx"
import ProfileForm from "../../Common/Profile/ProfileForm.jsx"
import React from 'react'
import ScrollToTop from "../../Common/ScrollToTop"
import TravelInterestsForm from "../../Common/Profile/TravelInterestsForm.jsx"
import { connect } from 'react-redux'
import { profile_router } from "../../Common/RegExValidate"

const ProfileContainer_ = ({ screenSize, app_menu_height, profile_container, is_mobile, logo_height }) => {
    let margin_left = 0
    let margin_right = 0
    // let size = 0
    let position = "sticky"
    let top_H = 180
    if (screenSize !== undefined) {
        margin_left = screenSize > 1200 ? (screenSize / 100 * 5) + 430 : (screenSize <= 768 ? 0 : (screenSize / 100 * 4) + 350)
        margin_right = (screenSize <= 768) ? 0 : (screenSize / 100 * 8.5)
        // size = screenSize > 1025 ? 250 : (screenSize > 500 ? 200 : 250)
    }
    return (

        <div style={{ top: top_H, position: "relative", marginLeft: margin_left, marginRight: margin_right, backgroundColor: "#191919",paddingBottom:50 }} className="padding_top_20">
            <div style={{
                position: position, zIndex: "3",
                top: is_mobile ? 0 : (-Math.ceil(-app_menu_height / 2))
            }}>
                <div className="padding_top_10 padding_left_20 padding_right_20" style={{ backgroundColor: "#191919" }}>
                    <MenuMember />
                </div>
            </div>
            <div style={{ font: "50px/72px Futura Hv BT", padding: "40px", color: "#FFFFFF", textAlign: "left" }}>{profile_container}</div>
            <div style={{ paddingLeft: "50px", paddingRight: "50px" }}>
                <MenuProfile />
                {profile_container === profile_router.interests && <React.Fragment>
                    <div style={{
                        marginTop: "20px",
                        color: "#FFFFFF",
                        font: "18px/22px Futura Hv BT",
                    }}>
                        Help us match you with like-minded travelers and locals, tell us more about you.
                    </div>
                    <TravelInterestsForm />
                    <div style={{
                        position: "relative", marginTop: "120px",
                        height: "50px",
                        backgroundColor: "rgba(25, 188, 155, 0.14)",
                    }}>
                        <div style={{
                            color: "#19BC9B",
                            font: "14px/21px Futura Lt BT",
                            margin: "0",
                            position: "absolute",
                            left: "1%",
                            bottom: "5px",
                            msTransform: "translateY(-50%)",
                            transform: "translateY(-50%)",
                            opacity: "1.0",
                        }}>
                            SAFE &amp; SECURE: Your information will only be used to match you with like-minded people and personalized experiences
                        </div>
                    </div>
                </React.Fragment>}
                {profile_container === profile_router.bio && <BioForm />}
                {profile_container === profile_router.profile && <ProfileForm />}
                {profile_container === profile_router.personalization && <React.Fragment>
                    <div style={{
                        marginTop: "20px",
                        color: "#FFFFFF",
                        font: "18px/22px Futura Hv BT",
                    }}>
                        Personalize recommendations and suggestions, input your likes and dislikes
                    </div>
                    <PersonalizationForm />
                </React.Fragment>
                }
            </div>
            <ScrollToTop />
        </div>
    )
}

const mapStateToProps = (state) => ({
    profile_container: state.Member.profile_container,

    screenSize: state.Setting.htmlBody.bodyClientWidth,
    is_mobile: state.Setting.is_mobile,
    app_menu_height: state.Setting.appMenu.app_menu_height,
    logo_height: state.Setting.appMenu.logo.height,

})

const mapDispatchToProps = {

}

const ProfileContainer = connect(mapStateToProps, mapDispatchToProps)(ProfileContainer_)
export default ProfileContainer
