import * as Actions from '../../../redux/actions'

import {
    DELAY_ZERO_SECOND,
    validate,
    validate_fields,
} from "../Common/RegExValidate"

import InputBase from '@material-ui/core/InputBase';
import React from 'react';
import { SearchIcon, TimesIcon } from "../Common/SVGBank"
import { connect } from "react-redux";
import { makeStyles } from '@material-ui/core/styles'
import  ClickAwayListener  from '@material-ui/core/ClickAwayListener';
import  SearchBarDestinationAutocomplete  from './SearchBarAutocomplete';


const searchStyles = makeStyles((theme) => ({
    title: {
        flexGrow: 1,
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
    search: {
        background: "#FFFFFF 0% 0% no-repeat padding-box",
        boxShadow: "0px 3px 6px #00000029",
        border: "1px solid #B1B1B1",
        borderRadius: "4px",
        position: 'relative',
        marginTop: "15px",
        [theme.breakpoints.up('sm')]: {
            // marginLeft: theme.spacing(1),
            width: 'auto',
        },
        height: '40px',
        width: "100%",
    },
    inputRoot: {
        color: 'var(--bgBlack)',
        font: "16px/16px Futura Md BT",
    },
    inputInput: {
        padding: theme.spacing(1, 0, 1, 1),
        // vertical padding + font size from searchIcon
        paddingRight: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '20ch',
            // '&:focus': {
            //     width: '20ch',
            // },
        },
    },
}));

export const SearchBar = connect((state) => {
    return {
        lang: state.Setting.lang,
        activeFeatureId: state.Feature.feature.activeId,
        destinationList: state.Destination.destination.destinationSearchList,
        searchQuery: state.Feature.feature.loading.searchQuery,
        types: state.Feature.feature.types,
        lazyLoading: state.Destination.destination.lazyLoading,
    }
}, {
    cleanSearchQueryDestination: Actions.cleanSearchQueryDestination,
    setSearchQueryFeature: Actions.setSearchQueryFeature,
    setSwitchFeatureTypeContainerOfFeature: Actions.setSwitchFeatureTypeContainerOfFeature,
    cleanSelectedDestinationsFeature: Actions.cleanSelectedDestinationsFeature,
    cleanSelectedSubTypeFeature: Actions.cleanSelectedSubTypeFeature,
    setSearchAutocompleteDestination: Actions.setSearchAutocompleteDestination,
    setSearchQueryDestination: Actions.setSearchQueryDestination,

},
)(({
    lang,
    types,
    searchQuery,
    activeFeatureId,
    destinationList,
    lazyLoading,
    setSearchQueryDestination,
    setSearchQueryFeature,
    cleanSearchQueryDestination,
    setSearchAutocompleteDestination,
    setSwitchFeatureTypeContainerOfFeature,
    cleanSelectedDestinationsFeature,
    cleanSelectedSubTypeFeature,
}) => {
    const [query, setQuery] = React.useState("")
    
    const anchorRef = React.useRef(null);
    const classes = searchStyles()
    const delay = (() => {
        let timer = 0
        return (callback, ms) => {
            clearTimeout(timer)
            timer = setTimeout(callback, ms)
        }
    })()
    const updateState = (search, error) => {
        if (!error) {
            setSearchAutocompleteDestination(search,lazyLoading)
          //  setSearchQueryFeature(search, activeFeatureId, lang)
        }
    }
    const handleChangeHandleTextField = event => {
        let search = event.target.value
        setQuery(search)
        delay(function () {
            updateState(search, !validate(String(search).trim(), validate_fields.search))
        }, DELAY_ZERO_SECOND)
    }

    const searchFilter = () =>{
        setSearchQueryDestination(query, lazyLoading)
    }
    const handleCloseAway = event => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        // setQuery("")
        // cleanSearchQueryDestination()
    };
    const handleClose = event => {
        setQuery("")
        setSearchQueryFeature("")
        setSearchQueryDestination("", lazyLoading)
        cleanSearchQueryDestination()

    };
    const handleEreser = () => {
        setQuery("")
        setSearchQueryFeature("")
        setSearchQueryDestination("", lazyLoading)
        cleanSearchQueryDestination()

    }
    return (

        <ClickAwayListener onClickAway={handleCloseAway}>
            <div
                ref={anchorRef} style={{ position: "relative" }}>
                <div className={classes.search}>

                    <InputBase
                        placeholder="Search"
                        classes={{
                            root: classes.inputRoot,
                            input: classes.inputInput,
                        }}
                        inputProps={{ 'aria-label': 'search' }}
                        onChange={handleChangeHandleTextField}
                        value={query}
                    />
                      {query === "" &&
                        <div
                            style={{
                                right: "10px",
                                bottom: "12px",
                                position: 'absolute',
                                pointerEvents: 'none',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <SearchIcon />
                        </div>}
                </div>
                {query !== "" &&
                    <div
                        style={{
                            cursor: "pointer",
                            right: "10px",
                            bottom: "12px",
                            position: 'absolute',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                        onClick={() => searchFilter()}>
                           <SearchIcon    />
                    </div>}
            
               
                {destinationList.length > 0 && < SearchBarDestinationAutocomplete
                    anchorRef={anchorRef}
                    handleClose={handleClose}
                    open={destinationList.length > 0}
                />}
            </div>
        </ClickAwayListener>
    )
})
