import ActivityDuration from '../../Common/TimelineWizard/ActivityDuration'
import ActivityEllipsis from "../../Common/TimelineWizard/ActivityEllipsis"
import { ActivityStartTime } from '../../Common/TimelineWizard/ActivityStartTime'
import DeleteActivity from '../../Common/TimelineWizard/DeleteActivity'
import { EstablishmentDescription } from "../../Common/EstablishmentDescription"
import EstablishmentName from '../Establishment/EstablishmentName'
import {
    Image,
} from 'react-bootstrap'
import Rating from '@material-ui/lab/Rating'
import React from 'react'
import TimeToReach from '../../Common/TimelineWizard/TimeToReach'
import { connect } from 'react-redux'
import { image_boxing } from '../RegExValidate'
import { member_area_router } from "../../Common/RegExValidate"

/*  activity element component */
const ActivityElement_ = ({ switch_container, activity, bodyClientWidth, logo_width, isMobile, switchFeatureContainer, logo_height }) => {
    const activityNoTime = activity.activityNoTime
    const switchFeatureContainerColorLight = switchFeatureContainer.color + "35"
    const widthContainer = isMobile ? Math.floor(bodyClientWidth * 0.98) : Math.floor(((bodyClientWidth - 17) * 0.83) - (logo_width * 2) - 40)
    const widthImage = widthContainer / 2
    const heightImage = widthImage / 2
    const timeToReachColor = "#E8E8E8"
    const timeToReachFillIcon = "#FFFFFF"
    
    return (
        <React.Fragment>

            { switch_container === member_area_router.memberAreaTrip ?
                <div>
                    <TimeToReach activity={activity} fillIcon={timeToReachFillIcon} color={timeToReachColor} fabColor={"#E8E8E840"} textColor={"#FFFFFF"} />
                    <div style={{
                        display: "flex",
                        position: "relative",
                        margin: "0px",
                        color: "#FFFFFF",
                        border: "1px solid #707070",
                        width: widthContainer,
                    }}>
                        <div style={{ position: "absolute", top: "10px", right: "15px", zIndex: "2" }}>
                            <ActivityEllipsis activity={activity} format={"ellipsis_v"} />
                        </div>
                        <div className="d-inline-block">
                            <Image
                                src={image_boxing(activity.image, widthImage, heightImage)}
                                alt="activity" width={widthImage} height={heightImage} />
                        </div>
                        <div style={{ width: widthImage + "px", minHeight: heightImage + "px", verticalAlign: "middle", flexGrow: "1", padding: "20px", position: "relative" }}>
                            <div className="box_1" style={{
                                font: "14px/16px Futura Md BT",
                                color: "var(--mainGreen)",
                            }}>
                                {activity.activityNeighborhood}
                            </div>
                            {activity.activityEstabID !== undefined && activity.activityEstabID !== "" &&
                                <EstablishmentName
                                    establishmentId={activity.activityEstabID}
                                    establishmentName={activity.activityName}
                                    divClass={"flex_1"}
                                    titleClass={"box_1"} titleStyle={{
                                        font: "24px/32px Futura Hv BT", textAlign: "left", color: "#FFFFFF",
                                        marginTop: "10px",
                                        cursor: "pointer",
                                    }}
                                />}

                            {activity.activityStarRating !== "0.0" && <div className="d-flex timeline_rating_color" style={{ marginTop: "10px" }}>
                                <Rating name="timeline_rating" precision={0.5} max={5} value={parseFloat(activity.activityStarRating)} disabled={true} />
                                <div className="futura_md_bt_11_14_black margin_bottom_5 margin_left_5" style={{
                                    font: "11px/15px Futura Md BT",
                                    color: "#E8E8E888",
                                    marginLeft: "5px",
                                    bottomBottom: "5px",
                                }}>({activity.activityStarRating})</div>
                            </div>}
                            {activity.activityDescription !== undefined && activity.activityDescription !== "" && <EstablishmentDescription description={activity.activityDescription} boxDescription={"box_3"} fontDescription={"14px/19px Futura Lt BT"} colorDescription={"var(--mainGrey)"} height={"57px"} />}
                            <div className={"text-left box_1 margin_top_20 "} style={{ marginTop: "10px" }}>
                                {activity.activitySubTypes !== undefined && activity.activitySubTypes.length > 0 &&
                                    activity.activitySubTypes.map((subtype, subtype_idx) => {
                                        return <div key={subtype_idx}
                                            className="btn_rounded m-1 futura_md_bt_14_16_green upcoming_trip_style"
                                        >
                                            {subtype.subtypeName}</div>
                                    })
                                }
                            </div>
                            <div style={{ position: "absolute", bottom: "10px", right: "10px", }}>
                                <DeleteActivity activity={activity} tripDestinationId={activity.tripDestinationId} fetchingStatus={false} from={"schedule"} />
                            </div>

                        </div>
                        <div style={{
                            position: "absolute", top: "40%",
                            width: Math.floor(logo_height) + "px",
                            left: Math.floor(-(logo_height + 40)) + "px",
                            textAlign: "center",
                        }}>
                            <ActivityStartTime activity={activity} inputStyle={{
                                font: "22px/20px Futura Hv BT",
                                color: "#FFFFFF",
                                width: "120px",
                                textAlign: "center",
                                padding: "1px 0px",
                                margin: "0px",
                                display: "inline-block",
                                cursor: "pointer",
                            }} />
                            <ActivityDuration activity={activity} inputStyle={{
                                font: "18px/20px Futura Md BT",
                                color: "#E8E8E888",
                                width: "80px",
                                textAlign: "center",
                                padding: "1px 0px",
                                margin: "0px",
                                display: "inline-block",
                                cursor: "pointer",
                            }} />
                        </div>
                    </div>
                </div>
                :
                <div>
                    <TimeToReach activity={activity} fillIcon={"#707070"} color={timeToReachColor} fabColor={"#C7C7C7"} textColor={"#707070"} />
                    <div style={{
                        display: "flex",
                        position: "relative",
                        margin: "0px",
                        color: "#C7C7C7",
                        border: "1px solid #C7C7C7",
                        width: widthContainer,
                    }}>
                        <div style={{ position: "absolute", top: "10px", right: "15px", zIndex: "2" }}>
                            <ActivityEllipsis activity={activity} format={"ellipsis_v"} />
                        </div>
                        <div className="d-inline-block">
                            <Image
                                src={image_boxing(activity.image, widthImage, heightImage)}
                                alt="activity" width={widthImage} height={heightImage} />
                        </div>
                        <div style={{ width: widthImage + "px", minHeight: heightImage + "px", verticalAlign: "middle", flexGrow: "1", padding: "20px", position: "relative" }}>
                            <div className="box_1" style={{
                                font: "14px/16px Futura Md BT",
                                color: "var(--mainGreen)",
                            }}>
                                {activity.activityNeighborhood}
                            </div>
                            {activity.activityEstabID !== undefined && activity.activityEstabID !== "" &&
                                <EstablishmentName
                                    establishmentId={activity.activityEstabID}
                                    establishmentName={activity.activityName}
                                    divClass={"flex_1"}
                                    titleClass={"box_1"} titleStyle={{
                                        font: "24px/32px Futura Hv BT", textAlign: "left", color: "#191919",
                                        marginTop: "10px",
                                        cursor: "pointer",
                                    }}
                                />}

                            {activity.activityStarRating !== "0.0" && <div className="d-flex timeline_rating_color" style={{ marginTop: "10px" }}>
                                <Rating name="timeline_rating" precision={0.5} max={5} value={parseFloat(activity.activityStarRating)} disabled={true} />
                                <div className="futura_md_bt_11_14_black margin_bottom_5 margin_left_5" style={{
                                    font: "11px/15px Futura Md BT",
                                    color: "#191919",
                                    marginLeft: "5px",
                                    bottomBottom: "5px",
                                }}>({activity.activityStarRating})</div>
                            </div>}
                            {activity.activityDescription !== undefined && activity.activityDescription !== "" && <EstablishmentDescription description={activity.activityDescription} boxDescription={"box_3"} fontDescription={"14px/19px Futura Lt BT"} colorDescription={"var(--mainGrey)"} height={"57px"} />}
                            <div className={"text-left box_1 margin_top_20 "} style={{ marginTop: "10px" }}>
                                {activity.activitySubTypes !== undefined && activity.activitySubTypes.length > 0 &&
                                    activity.activitySubTypes.map((subtype, subtype_idx) => {
                                        return <div key={subtype_idx}
                                            className="btn_rounded m-1 futura_md_bt_14_16_green upcoming_trip_style"
                                        >
                                            {subtype.subtypeName}</div>
                                    })
                                }
                            </div>
                            <div style={{ position: "absolute", bottom: "10px", right: "10px", }}>
                                <DeleteActivity activity={activity} tripDestinationId={activity.tripDestinationId} fetchingStatus={false} from={"schedule"} />
                            </div>

                        </div>
                        <div style={{
                            position: "absolute", top: "40%",
                            width: Math.floor(logo_height) + "px",
                            left: Math.floor(-(logo_height + 40)) + "px",
                            textAlign: "center",
                        }}>
                            <ActivityStartTime activity={activity} inputStyle={{
                                font: "22px/20px Futura Hv BT",
                                color: "#191919",
                                width: "120px",
                                textAlign: "center",
                                padding: "1px 0px",
                                margin: "0px",
                                display: "inline-block",
                                cursor: "pointer",
                            }} />
                            <ActivityDuration activity={activity} inputStyle={{
                                font: "18px/20px Futura Md BT",
                                color: "#707070",
                                width: "80px",
                                textAlign: "center",
                                padding: "1px 0px",
                                margin: "0px",
                                display: "inline-block",
                                cursor: "pointer",
                            }} />
                        </div>
                    </div>
                </div>
            }
        </React.Fragment>
    )

}

const mapStateToProps = (state) => ({
    bodyClientWidth: state.Setting.htmlBody.bodyClientWidth,
    logo_width: state.Setting.appMenu.logo.width,
    isMobile: state.Setting.is_mobile,
    switchFeatureContainer: state.FormBank.FeatureFormat,
    logo_height: state.Setting.appMenu.logo.height,

    switch_container: state.Member.switch_container,

})

const mapDispatchToProps = {

}

const ActivityElement = connect(mapStateToProps, mapDispatchToProps)(ActivityElement_)
export default ActivityElement
