import "./UpComingTripTimeline.css"

import { DownArrowIcon, UpArrowIcon } from "../Common/SVGBank"

import EstablishmentName from '../Common/Establishment/EstablishmentName'
import {
    Image,
} from 'react-bootstrap'
import Rating from '@material-ui/lab/Rating'
import React from 'react'
import { connect } from 'react-redux'
import { image_boxing } from '../Common/RegExValidate'

/* timeline component */
const UpComingTripTimeline_ = ({ trip }) => {
    const [open, setOpen] = React.useState(false)
    const [destinationNumberIndex, setDestinationNumberIndex] = React.useState(0)
    const setClose = () => {
        setOpen(false)
        document.getElementById("upcoming_trip_timeline").scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
    let tripDestinations = trip.tripDestinations
    const destinaitonRadioTimelineHandler = (num) => {
        setDestinationNumberIndex(num)
    }

    return (
        <div>
            <div className="d-flex padding_left_40_timeline padding_right_20_timeline" id={"upcoming_trip_timeline"}>
                {tripDestinations !== undefined
                    && tripDestinations.length > 0 &&
                    tripDestinations.map((destination, index) => {

                        return (
                            <React.Fragment key={index}>
                                {
                                    destination !== undefined &&
                                    destination.destinationDays !== undefined &&
                                    destination.destinationDays.length > 0 &&
                                    <div
                                        className={destinationNumberIndex === index ?
                                            "bg_color_btn_black col-md-3 col-sm-3 col-lg-3 col-xl-3 destination_black_active"
                                            : "bg_color_btn_black  col-md-3 col-sm-3 col-lg-3 col-xl-3 "}
                                        onClick={() => destinaitonRadioTimelineHandler(index)}>
                                        <div className="futura_md_bt_14_10 box_1 p-1 text-center ">{destination.name}</div>
                                    </div>
                                }
                            </React.Fragment>
                        )
                    })
                }
            </div>
            <div className="content_container">
                <div className="steps_timeline_hm text-left padding_left_40_timeline padding_right_20_timeline">

                    {tripDestinations[destinationNumberIndex].destinationDays !== undefined && tripDestinations[destinationNumberIndex].destinationDays !== null && tripDestinations[destinationNumberIndex].destinationDays.length > 0 &&
                        tripDestinations[destinationNumberIndex].destinationDays.map((activity, idx) => {
                            return (
                                <React.Fragment key={idx} >
                                    {idx < 3 || open ?
                                        <div className={(idx !== tripDestinations[destinationNumberIndex].destinationDays.length - 1) ? "step padding_bottom_10" : "step_last padding_bottom_10"} >
                                            <div className={tripDestinations[destinationNumberIndex].destinationDays.length > 1 ? "title_hm" : ""}>
                                                <div className="d-inline-block">
                                                    <Image
                                                        src={image_boxing(activity.activityImage, "350", "150")}
                                                        alt="activity" style={{ width: "350px" }} /></div>
                                                <div className="d-inline-block btn_rounded" style={{ width: "250px" }}>
                                                    <div className="futura_md_bt_10_10 text_white margin_top_10 box_1" style={{ paddingLeft: "10px" }}>{activity.activityNeighborhood}</div>
                                                    <EstablishmentName
                                                        establishmentId={activity.activityEstabID}
                                                        establishmentName={activity.activityName}
                                                        titleClass={"box_1"}
                                                        titleStyle={{ cursor: 'pointer', font: "20px/24px Futura Md BT", textAlign: "left", color: "#FFFFFF", paddingLeft: "10px" }}
                                                    />
                                                    <div className="d-flex margin_top_5 timeline_rating_color" style={{ paddingLeft: "10px" }}>
                                                        <Rating name="timeline_rating" precision={0.1} max={5} value={parseFloat(activity.activityStarRating)} size="small" disabled={true} />
                                                        <div className="futura_md_bt_8_10_white margin_bottom_5 margin_left_5">({activity.activityStarRating})</div>
                                                    </div>
                                                    <div className="margin_bottom_10 text-left box_2" style={{ marginLeft: "5px" }}>
                                                        {activity.activitySubTypes !== undefined && activity.activitySubTypes.length > 0 &&
                                                            activity.activitySubTypes.map((element, idx) => { return <div key={idx} className="btn_rounded m-1 futura_md_bt_14_16_green upcoming_trip_style">{element.subtypeName}</div> })
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div> : ""}
                                </React.Fragment>
                            )
                        })
                    }
                </div>
                {!open && tripDestinations[destinationNumberIndex].destinationDays.length > 3 && <div className="content_text_container_bottom_black"
                    style={{
                        msTransform: tripDestinations[destinationNumberIndex].destinationDays !== undefined && tripDestinations[destinationNumberIndex].destinationDays.length > 3 ? "translateY(-30px)" : "",
                        transform: tripDestinations[destinationNumberIndex].destinationDays !== undefined && tripDestinations[destinationNumberIndex].destinationDays.length > 3 ? "translateY(-30px)" : ""
                    }} ></div>}

                {tripDestinations[destinationNumberIndex].destinationDays !== undefined && tripDestinations[destinationNumberIndex].destinationDays.length > 3 ?
                    open ?
                        <React.Fragment>
                            <div className="text-center">
                                <div className="d-inline-flex p-2 justify-content-center futura_md_bt_13_16_green" style={{ cursor: "pointer" }} onClick={() => setClose()}>
                                    <div className="m-0 p-0 futura_md_bt_16_19_green" >See Less</div>
                                    <UpArrowIcon fill={"#19BC9B"} style={{ marginTop: "3px", marginLeft: "8px" }} />
                                </div>

                            </div>
                        </React.Fragment>
                        :
                        <React.Fragment>
                            <div className="text-center">
                                <div className="d-inline-flex p-2 justify-content-center futura_md_bt_13_16_green" style={{ cursor: "pointer" }} onClick={() => setOpen(true)}>
                                    <div className="m-0 p-0 futura_md_bt_16_19_green" >See All</div>
                                    <DownArrowIcon fill={"#19BC9B"} style={{ marginTop: "3px", marginLeft: "8px" }} />
                                </div>
                            </div>
                        </React.Fragment>
                    : ""
                }
            </div>

        </div>
    )
}


const mapStateToProps = (state) => {
    return {
    }
}


const mapDispatchToProps = {
}


const UpComingTripTimeline = connect(mapStateToProps, mapDispatchToProps)(UpComingTripTimeline_)
export default UpComingTripTimeline

