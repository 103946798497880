import React, { Component } from 'react'

import BioForm from "./BioForm"
import MenuLanding from '../../LandingPage/MenuLanding'
import { connect } from 'react-redux'

class Bio_ extends Component {
    render() {
        const { screenSize, app_menu_height, is_mobile } = this.props;

        let position = "sticky"
        let text_height = "85px"
        let screen_at_1200 = 1200
        let padding_8_percent = "8.5%"

        if (!isNaN(screenSize)) {
            text_height = screenSize < screen_at_1200 ? "40px" : "85px"
            padding_8_percent = screenSize < screen_at_1200 ? "2.5%" : "8.5%"
        }
        return (
            <div style={{ padding: padding_8_percent }}>
                {screenSize >= screen_at_1200 ?
                    <div style={{
                        height: text_height,
                        font: "36px/40px Futura Hv BT",
                    }}>
                        Tell us more about you.
                    </div>
                    : ""
                }
                <div style={{ position: position, zIndex: "3", top: is_mobile ? app_menu_height : Math.ceil(app_menu_height / 2) }}>
                    <MenuLanding />
                </div>
                {screenSize < screen_at_1200 ?
                    <div style={{
                        height: text_height,
                        marginTop: "20px",
                        marginLeft: "20px",
                        marginRight: "20px",
                        marginBottom: "20px",
                        font: "18px/22px Futura Hv BT",
                    }}>
                        Tell us more about you.
                    </div>
                    : ""
                }
                <div style={{
                    marginLeft: "0px",
                    marginRight: "0px",
                }}>
                    <BioForm />
                </div>
            </div>
        )
    }
}
const mapStateToProps = (state) => ({
    screenSize: state.Setting.htmlBody.bodyClientWidth,
    app_menu_height: state.Setting.appMenu.app_menu_height,
    is_mobile: state.Setting.is_mobile,
})


const Bio = connect(mapStateToProps, null)(Bio_)
export default Bio

