import './HometownFavorites.css';

import * as Actions from '../../../redux/actions';

import {
    ArrowLeftIcon,
    ArrowRightIcon,
    BedIcon,
    CameraIcon,
    ConciergeBellIcon,
    GlassMartiniIcon,
    ShoppingBagIcon,
    SkiingIcon,
    SpaIcon,
    TheaterMasksIcon,
} from "../Common/SVGBank"

import FavoritesElement from "../Common/FavoritesElement"
import { PropTypes } from "prop-types"
import React from 'react'
import Slider from 'react-slick'
import { connect } from 'react-redux'

const SliderArrowRight = ({ className, to, onClick }) => (
    <button type="button" onClick={onClick}
        className={`button button--text button--icon ${className}`} aria-label={to}>
        <ArrowRightIcon className="icon" icon={to} style={{
            height: "60px",
            width: "60px",
            transform: 'translateY(-66px)'
        }} />
    </button>
)


const SliderArrowLeft = ({ className, to, onClick }) => (
    <button type="button" onClick={onClick} className={`button button--text button--icon ${className}`} aria-label={to}>
        <ArrowLeftIcon className="icon" icon={to} style={{
            height: "60px",
            width: "60px",
            transform: 'translateY(-57px)'
        }} />
    </button>
)

const HomeFavorites_ = ({hometownFavoritesType,type_id,homeFavourites,screenSize}) =>{


        const heightContainer = "400px"
        let settings = {
            touchMove: false,
            dots: false,
            infinite: false,
            arrows: true,
            speed: 500,
            lazyLoad: false,
            autoplay: false,
            slidesToShow: 5,
            slidesToScroll: 3,
            centerPadding: "70px",
            pauseOnHover: true,
            centerMode: false,
            prevArrow: <SliderArrowLeft to="prev" />,
            nextArrow: <SliderArrowRight to="next" />,
            responsive: [
                {
                    breakpoint: 1724,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 2,
                    }
                },
                {
                    breakpoint: 1500,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 2,
                    }
                },
                {
                    breakpoint: 1100,
                    settings: {
                        touchMove: true,
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        arrows: false,
                    }
                },

                {
                    breakpoint: 768,
                    settings: {
                        touchMove: true,
                        slidesToShow: 3,
                        slidesToScroll: 2,
                        arrows: false,

                    }
                },
                {
                    breakpoint: 500,
                    settings: {
                        touchMove: true,
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        arrows: false,
                    }
                },
            ]
        }
        const display_element_length = screenSize > 1724 ? 5 :
            screenSize > 1500 ? 4 :
                screenSize > 1100 ? 3 :
                    screenSize >= 1024 ? 2 :
                        screenSize >= 768 ? 3 :
                            screenSize > 500 ? 1 : 2
       
        const [selectedType, setSelectedType] = React.useState([]);
        const [homeSelectedFavs, setHomeFavs] = React.useState(homeFavourites || []);
        const left_element = homeSelectedFavs && homeSelectedFavs.length < display_element_length ? "left_element" : ""

        const selectType = (id)=>{
            let selected = [];
            if(typeSelected(id)){
                selected = selectedType.filter(item => item !== id)
               setSelectedType(selected)
            }
            else{
                selected = [...selectedType,id]
                setSelectedType(selected)
            }
            if(selected && selected.length > 0){
                let selectedFavs = homeFavourites.filter(item => {
                    if(item?.subTypes){
                        const found = item.subTypes.find(type => selected.includes(type.typeID))
                        return found;
                    }
                   return item;
                })
                console.log(selectedFavs)
                setHomeFavs(selectedFavs)
            }
            else{
                setHomeFavs(homeFavourites)
            }
        }

        const typeSelected = (id)=>{
            return  selectedType.includes(id);
        }
   
        return (
            <div>
                <div className="margin_right_5 margin_left_5 row padding_125rem_lr_mobile ">
                    <div className="futura_hv_bt_24_26_white margin_bottom_20" >My Home Favorites</div>
                    <div className="ml-auto">
                        {hometownFavoritesType !== undefined &&
                            hometownFavoritesType.map((type, type_index) => {
                                return (
                                    type.typeID <= 8 ?
                                        <div
                                            key={type_index}
                                            onClick={() => {selectType(type.typeID) }}
                                            className="btn btn-sm my_home_faverites_top_btn flex-wrap"
                                            style={{
                                                backgroundColor: screenSize > 768 ? (typeSelected(type.typeID) ? "var(--mainGreen)" : "var(--mainWhite)") : "rgba(0, 0, 0, 0)",
                                                color: typeSelected(type.typeID)  ? "var(--mainWhite)" : "var(--mainGrey)"
                                            }}>
                                            {type.typeID === "4" ? <BedIcon className="home_favorites_icon" fill={typeSelected(type.typeID) ? "#FFFFFF" : "#707070"} /> : ""}
                                            {type.typeID === "1" ? <CameraIcon className="home_favorites_icon" fill={typeSelected(type.typeID)  ? "#FFFFFF" : "#707070"} /> : ""}
                                            {type.typeID === "3" ? <ConciergeBellIcon className="home_favorites_icon" fill={typeSelected(type.typeID)  ? "#FFFFFF" : "#707070"} /> : ""}
                                            {type.typeID === "5" ? <GlassMartiniIcon className="home_favorites_icon" fill={typeSelected(type.typeID) ? "#FFFFFF" : "#707070"} /> : ""}
                                            {type.typeID === "6" ? <SkiingIcon className="home_favorites_icon" fill={typeSelected(type.typeID)  ? "#FFFFFF" : "#707070"} /> : ""}
                                            {type.typeID === "7" ? <SpaIcon className="home_favorites_icon" fill={typeSelected(type.typeID)  ? "#FFFFFF" : "#707070"} /> : ""}
                                            {type.typeID === "2" ? <ShoppingBagIcon className="home_favorites_icon" fill={typeSelected(type.typeID)  ? "#FFFFFF" : "#707070"} /> : ""}
                                            {type.typeID === "8" ? <TheaterMasksIcon className="home_favorites_icon" fill={typeSelected(type.typeID)  ? "#FFFFFF" : "#707070"} /> : ""}
                                            <div className="margin_top_2">{type.typeName}</div>
                                        </div>
                                        : ""
                                )
                            })
                        }
                    </div>
                </div>
                {/* <div
                    className="futura_hv_bt_16_19_green text-right padding_125rem_lr_mobile"
                    onClick={type_id === "0" ? () => { } : () => { this.props.setFavoritesTypeSelected(uuid, "0", "", lang) }}>
                    All</div> */}
              <Slider {...settings} className={left_element} style={{ height: heightContainer }}>
                    {homeSelectedFavs !== undefined && homeSelectedFavs[0] !== undefined &&
                        homeSelectedFavs.map((favorite, idx) => {
                            return (
                                <FavoritesElement favorite={favorite} key={idx} from={"hometownFavorites"} />
                            )
                        })
                    }
                </Slider>
                
              
            </div>
        )
}

HomeFavorites_.propTypes = {
    hometownFavorites: PropTypes.array.isRequired,
}
const mapStateToProps = (state) => {
    return {
        hometownFavoritesType: state.Member.socialMember.hometownFavoritesType,
        homeFavourites:  state.SocialPage.homeFavourites,
        hometownFavoritesOffset: state.Member.socialMember.hometownFavoritesOffset,
        hometownFavoritesLimit: state.Member.socialMember.hometownFavoritesLimit,
        hometownFavoritesIsFetch: state.Member.socialMember.hometownFavoritesIsFetch,
        screenSize: state.Setting.htmlBody.bodyClientWidth,
        uuid: state.Social.member_uuid,
        type_id: state.Member.socialMember.currentFavoritesTypeIdSelected,
        lang: state.Setting.lang,
    }
};

const mapDispatchToProps = {
    setFavoritesTypeSelected: Actions.setFavoritesTypeSelected,

    fatchHometownFavoritesSlider: Actions.fatchHometownFavoritesSlider,
};
const HomeFavorites = connect(mapStateToProps, mapDispatchToProps)(HomeFavorites_)
export default HomeFavorites;
