import React from 'react'
import { connect } from 'react-redux'

/*  top bar image balck page component */
class Banner_ extends React.Component {
  render() {
    const { app_menu_height, is_mobile } = this.props;
    let imageH = 180 + -Math.ceil(-app_menu_height / 2);
    return (
      <div>
        <img src='/img/banner.png'
          className="img_object_fit"
          alt="banner"
          style={{
            top: is_mobile ? "0px" : -Math.ceil(-app_menu_height / 2),
            height: imageH,
            width: "100%",
            position: "fixed",
          }} />
      </div>
    )
  }
}


const mapStateToProps = (state) => {
  return {
    is_mobile: state.Setting.is_mobile,
    app_menu_height: state.Setting.appMenu.app_menu_height,
  }
};

const Banner = connect(mapStateToProps, null)(Banner_)
export default Banner
