import {
    defaultBucketListImage,
    DELAY_ONE_SECOND,
    IMAGE_CLOUD_MAX,
} from "../Common/RegExValidate"
import { image_boxing, loadingImage,feature_color } from '../Common/RegExValidate'

import { Image } from "react-bootstrap"
import { PhotoVideoIcon } from '../Common/SVGBank'
import React from 'react'
import { SRLWrapper } from "simple-react-lightbox"
import SimpleReactLightbox from "simple-react-lightbox" // Import Simple React Lightbox
import { connect } from 'react-redux'

// import { CameraRegulerIcon } from '../Common/SVGBank'
import { Star } from '../Common/MemberActions/Star';





const TopBarSlider_ = ({ sliderImages, generalInfo, screenSize, isMobile, switchFeatureContainer, activeId }) => {

    const [imageRef, setImageRef] = React.useState(null)
    const [open, setOpen] = React.useState(false)

    React.useEffect(() => {
        setOpen(false)
        setTimeout(
            () => setOpen(true)
            , DELAY_ONE_SECOND)
    }, [activeId])

    const destinationName = generalInfo !== undefined && generalInfo !== null ? generalInfo.bucketListName : ""
    let topImageHeight = isMobile ? "180" : "780"
    let containerH = 500
    let fontDestinationName = isMobile ? "25" : "50"
    let fontIcon = isMobile ? "10" : "16"
    let widthIcon = isMobile ? "110px" : "170px"
    let heightIcon = isMobile ? "30px" : "45px"
    let fontHeightIcon = isMobile ? "17px" : "30px"
    // let fontIconCameraReguler = isMobile ? "15" : "20"
    if (topImageHeight !== undefined) {
        containerH = topImageHeight + "px"
    }
    let sliderIndex = 0
     const color = feature_color[3].color;
     const defaultImage  = defaultBucketListImage;
    let destinationImage = sliderImages !== undefined && sliderImages.length > 0 && sliderImages[sliderIndex] !== undefined ? image_boxing(sliderImages[sliderIndex], screenSize, topImageHeight) : image_boxing(defaultImage, screenSize, topImageHeight) 
    const display_letters = screenSize > 1024 ? 50 : 20
    const options = open && sliderImages !== undefined && sliderImages.length > 0 ? {
        overlayColor: "rgb(25, 25, 25)",
        captionColor: color,
        captionFontFamily: "Futura Md BT",
        captionFontSize: "22px",
        captionFontWeight: "300",
        captionFontStyle: "capitalize",
        buttonsBackgroundColor: "#191919",
        buttonsIconColor: color,
        autoplaySpeed: 1500,
        transitionSpeed: 900,

        buttons: {
            showFullscreenButton: false,
        },
    } : {}
    let fontIconPhotoVideo = isMobile ? "18" : "25"
    return (
        <div className="position-relative z_index_2" style={{backgroundColor: "black"}} >
            <Image
                src={destinationImage}
                alt="destination image" width={screenSize} height={topImageHeight} style={{opacity: '0.8'}} />
            {sliderImages === undefined || sliderImages.length <= 0 || sliderImages[sliderIndex] === undefined && <div className="mask_block" style={{ height: containerH }}></div>}

            <div style={{ position: "absolute", top: "60px", right: "84px",
                    width:"29px", height:"28px" }} className="starBucket">
                        <div> </div>
                              { generalInfo && generalInfo.bucketListId?
                                    <Star
                                    unitID={generalInfo.bucketListId}
                                    feature={switchFeatureContainer}
                                    fill={"#FFFFFF"} />:null
                              }
         </div>

            <div className="z_index_3"
                style={{
                    top: "30%",
                    left: "0",
                    right: "0",
                    position: "absolute",
                }}>
                       <div className="box_1 text-left bucketTitle"
                    style={{
                        font: "30px/36px Futura Lt BT",
                        fontWeight: "bold",
                        color: "#FFFFFF",
                        paddingLeft: "8.5%",  
                        paddingBottom: "39px", 
                    }}>BUCKET LIST IDEA</div> 
                <div className="box_1 text-left bucketName"
                    style={{
                        font: fontDestinationName + "px/80px Futura Md BT",
                        color: "#FFFFFF",
                        paddingLeft: "8.5%",
                        textShadow: '0px 6px 6px #191919'
                    }} > {destinationName}</div> 
    
            </div>
            {open && sliderImages !== undefined && sliderImages.length > 0 && sliderImages[0] !== undefined
                && sliderImages[0].imageName !== undefined &&
                <SimpleReactLightbox>
                    <div className="z_index_3"
                        style={{
                            bottom: "10%",
                            left: "0",
                            right: "60px",
                            position: "absolute",
                            zIndex: "5",
                        }}>
                        <div className="box_1 text-right"
                            style={{
                                color: "#FFFFFF",
                                paddingLeft: "8.5%"
                            }} >
                            <div className="d-inline-block viewGalleryBucket"
                                onClick={() => imageRef.click()}
                                style={{
                                    width: widthIcon,
                                    height: heightIcon,
                                    background: "#FFFFFF",
                                    color: color,
                                    border: "1px solid " + color,
                                    borderRadius: "39px",
                                    paddingTop: '5px',
                                    textAlign: "center",
                                    font: fontIcon + "px/" + fontHeightIcon + " Futura Md BT",
                                    marginRight: "8px",
                                    cursor: "pointer",
                                }}>
                                <SRLWrapper options={options} >
                                    <PhotoVideoIcon width={fontIconPhotoVideo} fill={color} />{" View Gallery"}

                                    {sliderImages.map((image, index) => {
                                        return (
                                            index === 0 ?
                                                <Image
                                                    key={index}
                                                    style={{
                                                        position: "absolute",
                                                        right: "200%"
                                                    }}
                                                    src={image_boxing(image, screenSize * 0.9, screenSize * 0.4)}
                                                    className="img_fit_cover d-block w-100"
                                                    ref={imageClick => setImageRef(imageClick)}
                                                    alt={image.description} />
                                                : (index < IMAGE_CLOUD_MAX &&
                                                    <Image
                                                        key={index}
                                                        style={{
                                                            position: "absolute",
                                                            right: "200%"
                                                        }}
                                                        src={image_boxing(image, screenSize * 4, screenSize * 0.6)}
                                                        className="img_fit_cover d-block w-100"
                                                        alt={image.description} />)
                                        )
                                    })}
                                </SRLWrapper>
                            </div>
                            {/* <div className="d-inline-block"
                            style={{
                                width: widthIcon,
                                height: heightIcon,
                                background: "#FFFFFF",
                                color: switchFeatureContainer.color,
                                border: "1px solid " + switchFeatureContainer.color,
                                borderRadius: "39px",
                                textAlign: "center",
                                font: fontIcon + "px/" + fontHeightIcon + " Futura Md BT",
                            }}><CameraRegulerIcon width={fontIconCameraReguler} fill={switchFeatureContainer.color} />{" Add photos"}
                        </div> */}
                        </div>
                    </div>
                </SimpleReactLightbox>
            }

        </div >
    )

}


const mapStateToProps = state => {
    return {
        screenSize: Math.min(state.Setting.htmlBody.bodyClientWidth, document.body.clientWidth),
        isMobile: state.Setting.is_mobile,
        activeId: state.Destination.bucketlist.activeId,
        sliderImages: state.Destination.bucketlist.sliderImages,
        generalInfo: state.Destination.bucketlist.generalInfo,
        switchFeatureContainer: state.FormBank.FeatureFormat,
    }
}

const mapDispatchToProps = {
}

const TopBarSlider = connect(mapStateToProps, mapDispatchToProps)(TopBarSlider_)
export default TopBarSlider
