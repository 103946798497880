import { animated, useSpring } from 'react-spring'
import { features_router, image_boxing } from '../RegExValidate'

import EstablishmentName from '../Establishment/EstablishmentName'
import {
    Image,
} from 'react-bootstrap'
import Rating from '@material-ui/lab/Rating'
import React from 'react'
import { TripDescription } from '../Trip/TripDescription'
import TripName from '../Trip/TripName'
import { connect } from 'react-redux'
import MemberActionPlaceEllipsis from './MemberActionPlaceEllipsis'
import MemberActionTripEllipsis from './MemberActionTripEllipsis'
import BucketListCardView from '../BucketList/BucketListCardView';
import DeleteFromListDialog from '../DeleteFromListDialog'


const PlacesElement = ({ establishment, feature, witchFeatureContainer, textColor, gridContainer, userAction, userActionId }) => {
    const [flipped, setFlipped] = React.useState(false)
    const { transform, opacity } = useSpring({
        opacity: flipped ? 1 : 0,
        transform: `perspective(600px) rotateX(${flipped ? 180 : 0}deg)`,
        config: { mass: 5, tension: 500, friction: 80 }
    })
    const memberArea = userAction &&  userAction === 'star'? false : true
    const width = gridContainer + 19;
    return (
        <div id={userAction + "_" + feature.featureTitle + "_" + establishment.destinationId + "_element"}
            style={{
                position: "relative", width: width, height: gridContainer + 65, display: "inline-block",
                // paddingLeft: "0px",
                // paddingRight: "15px",
                paddingBottom: "50px",
                marginBottom:30,
                marginRight:12,
                paddingTop: "15px",
            }}>

            <animated.div style={{ position: "absolute", zIndex: flipped ? "0" : "1", opacity: opacity.interpolate(o => 1 - o), transform }}>
                <div style={{
                    position: "relative",
                    display: "inline-block",
                }}>
                    {userAction == "star" && <div style={{ position: "absolute", top: "10px", right: "10px", cursor: "pointer", zIndex: 2 }} >
                        <MemberActionPlaceEllipsis
                            unitID={establishment.id}
                            feature={feature}
                            destinationId={establishment.destinationId}
                            userActionId={userActionId}
                            destination={establishment}
                            userAction={userAction}
                        />
                    </div>}
                    {memberArea && 
                    <div style={{ position: "absolute", top: "10px", right: "10px", cursor: "pointer",  zIndex: 2 }} >
                        <DeleteFromListDialog fillColor={"#FFFFFF"} unitID={establishment.id} feature={feature}
                         setOpenTripEllipsis={false} hideTitle={true}  from={"Remove from List"} userAction={userAction}/>
                    </div>}

                    <div className="d-inline-block"><Image
                        src={image_boxing(establishment.estabPicture, width, gridContainer)}
                        alt="establishment"
                        onClick={() => (establishment.establishmentDescription !== undefined && establishment.establishmentDescription !== "") ? setFlipped(state => !state) : undefined} /></div>
                    <div 
                    // className="d-flex flex-wrap rating_color pt-2 pl-2" 
                    style={{ width: width, border: "1px solid #E8E8E8" }}>
                        <div style={{ flexGrow: "1" }}>
                            <div style={{  font: "12px/29px Futura Md BT",color: textColor,}}>
                                {establishment.destinationName}
                                </div>
                            <EstablishmentName
                            establishmentName={establishment.establishmentName}
                            establishmentId={establishment.id}
                            titleClass={"box_1"} titleStyle={{
                                font: "16px/25px Futura Md BT",
                                color: textColor,
                                cursor: "pointer",
                            }} />
                        </div>
                        {establishment.averageRating !== "" && establishment.averageRating !== "0.0" && <div className="d-flex timeline_rating_color p-0 btn_rounded">
                            <div
                                style={{
                                    paddingRight: "5px",
                                    marginTop: "3px",
                                    font: "11px/14px Futura Md BT",
                                    color: "var(--mainGreen)",
                                }}>{establishment.averageRating}</div>
                            <div style={{
                                paddingRight: "2px",
                            }}>
                                <Rating name="timeline_rating" precision={0.5} max={1} value={parseFloat((establishment.averageRating / 5).toFixed(1))} disabled={true} />
                            </div>
                        </div>}


                    </div>
                </div>
            </animated.div>
            <animated.div style={{ position: "absolute", zIndex: flipped ? "1" : "0", opacity, transform: transform.interpolate(t => `${t} rotateX(180deg)`) }}>
                <div style={{
                    position: "relative",
                    display: "inline-block",
                }}>
                    <div className="d-inline-block"><Image
                        src={image_boxing(establishment.estabPicture, gridContainer, gridContainer)}
                        alt="establishment" /></div>
                    {establishment.establishmentDescription !== undefined &&
                        <div style={{ position: "absolute", top: "15px", zIndex: "1" }} className={"padding_left_10 padding_right_10"}>
                            {establishment.establishmentDescription !== undefined && establishment.establishmentDescription !== "" && <div
                                className={"box_10"}
                                style={{
                                    font: "14px/19px Futura Lt BT",
                                    color: "#FFFFFF",
                                    cursor: "pointer",
                                    overflowY: "auto",
                                    maxHeight: (gridContainer - 45) + "px",
                                }} onClick={() => setFlipped(state => !state)}>
                                {establishment.establishmentDescription}
                            </div>}
                        </div>}

                    <div style={{ backgroundColor: "#19191980", position: "absolute", width: width, height: gridContainer + "px", top: "0px" }} />
                    <div className="d-flex flex-wrap rating_color pt-2 pl-2" style={{ width: width, border: "1px solid #E8E8E8" }}>
                        <div style={{ flexGrow: "1" }}>
                        <div style={{  font: "12px/29px Futura Md BT",  color: textColor,}}>
                                {establishment.destinationName}
                                </div>
                                <EstablishmentName
                            establishmentName={establishment.establishmentName}
                            establishmentId={establishment.id}
                            titleClass={"box_1"} titleStyle={{
                                font: "16px/25px Futura Md BT",
                                color: textColor,
                                cursor: "pointer",
                            }} />
                        </div>
                        {establishment.averageRating !== "" && establishment.averageRating !== "0.0" && <div className="d-flex timeline_rating_color p-0 btn_rounded">
                            <div
                                style={{
                                    paddingRight: "5px",
                                    marginTop: "3px",
                                    font: "11px/14px Futura Md BT",
                                    color: "var(--mainGreen)",
                                }}>{establishment.averageRating}</div>
                            <div style={{
                                paddingRight: "2px",
                            }}>
                                <Rating name="timeline_rating" precision={0.5} max={1} value={parseFloat((establishment.averageRating / 5).toFixed(1))} disabled={true} />
                            </div>
                        </div>}
                    </div>
                </div>
            </animated.div>
        </div >
    )
}
/* bucket list component */
const BucketListElement = ({ bucketList, feature, widthContainer, textColor, heightContainer, userAction, userActionId ,isMobile}) => {
    const memberAction = {
        userAction: userAction,
        unitID: bucketList.bucketListId,
        destinationId: bucketList.destinationId,
        userActionId: userActionId,
        feature: feature,
        destination: bucketList,
        memberArea: userAction === 'star'? false : true
    }
    const width = widthContainer + 19;
    return (
        <div id={userAction + "_" + feature.featureTitle + "_" + bucketList.destinationId + "_element"}
        style={{
            paddingLeft: "0px",
            paddingRight: "7.5px",
            paddingBottom: "50px",
            paddingTop: "15px",
            position: "relative",
            display: "inline-block",
        }}>
            <BucketListCardView bucketItem={bucketList}
                widthContainer={width}
                heightContainer={heightContainer}
                is_mobile={isMobile} timelineWizardStatus={false} 
                memberAction={memberAction}/>


        </div >
    )
}

//
const TripsComponent = ({trip,gridContainer,userAction,feature,userActionId, textColor}) =>{
    const memberArea = userAction &&  userAction === 'star'? false : true
    const width = gridContainer + 19;
    return (
        <div id={userAction + "_" + feature.featureTitle + "_" + trip.destinationId + "_element"}
        style={{
            paddingLeft: "0px",
            paddingRight: "0px",
            paddingBottom: "40px",
            paddingTop: "15px",
            position: "relative",
            marginRight:12,
            display: "inline-block",
        }}>

             <div className="d-inline-block"><Image
                src={image_boxing(trip.headerImage, width, 220)}
                alt="trip" /></div>

            <div className="rating_color pt-2" style={{
                width: width,
                height: '130px',
                overflow: "hidden",
            }}>
                <div className="d-flex flex-wrap ">
                    <div className="box_1 padding_right_10" style={{
                        font: "14px/14px Futura Md BT",
                        color: textColor,
                        flexGrow: "1",
                        height: "23px",
                        marginTop: "5px"
                    }}>
                        {trip.destinationName}
                    </div>
                    {trip.averageRating !== "0.0" && trip.averageRating !== "0" && trip.averageRating !== ""
                        && <div className="d-flex timeline_rating_color p-0" style={{ height: "23px" }}>
                            <div
                                style={{
                                    paddingRight: "5px",
                                    font: "11px/17px Futura Hv BT",
                                    color: "var(--mainGreen)",
                                    flexGrow: "1",
                                }}>{trip.averageRating}</div>
                            <div style={{
                                paddingRight: "2px",
                            }}>
                                <Rating
                                    style={{ color: "var(--mainGreen)", opacity: "1" }}
                                    name="timeline_rating" precision={0.5} max={1} value={parseFloat((trip.averageRating / 5).toFixed(1))} disabled={true} size={"small"} />
                            </div>
                        </div>}
       
                </div>
                <TripName

                    tripName={trip.tripName}
                    tripId={trip.tripID}
                    divClass={"flex_1"}
                    titleClass={"bucketListName"} titleStyle={{
                        font: "22px/26px Futura Hv BT",
                        color: textColor,
                        cursor: "pointer",
                    }} />
                <div style={{ height: "65px", position: "relative" }}>
                    {trip.tripDescription !== undefined && <div style={{ position: "absolute" }} className={"padding_right_10"}><TripDescription description={trip.tripDescription} boxDescription={"box_3"} fontDescription={"14px/19px Futura Lt BT"} colorDescription={"var(--mainGrey)"} height={"57px"} /></div>}
                </div>
            </div>
            {userAction == "star" && <div style={{ position: "absolute", top: "25px", right: "45px", cursor: "pointer", zIndex: 2 }} >
                         <MemberActionTripEllipsis
                            unitID={trip.tripID}
                            feature={feature}
                            destinationId={trip.destinationId}
                            userActionId={userActionId}
                            destination={trip}
                            userAction={userAction}
                        />
                    </div>}
                    {memberArea && 
                    <div style={{ position: "absolute", top: "25px", right: "45px", cursor: "pointer", zIndex: 2 }} >
                        <DeleteFromListDialog fillColor={"#FFFFFF"} unitID={trip.tripID} feature={feature}
                         setOpenTripEllipsis={false} hideTitle={true}  from={"Remove from List"} />
                    </div>}



            </div>
   
    )
}
const MemberActionElement_ = ({ memberAction, feature, bodyClientWidth, isMobile, textColor }) => {
    const margin_left = bodyClientWidth > 1200 ? Math.floor((bodyClientWidth / 100 * 5) + 430) : Math.floor(bodyClientWidth <= 768 ? 0 : (bodyClientWidth / 100 * 4) + 350)
    const margin_right = (bodyClientWidth <= 768) ? 0 : Math.floor(bodyClientWidth / 100 * 8.5)
    const gridContainer = isMobile ? 160: 250;
    const placeGridContainer = isMobile ? 160: 250;
    const widthContainer = isMobile ? (bodyClientWidth * 0.98) :  (bodyClientWidth * 0.65 / 2)
    const heightContainer = isMobile ? "" :  (bodyClientWidth * 0.65 / 2 / 2)
    return (
        <React.Fragment>
           
           {memberAction.featureId === features_router.places.featureId &&
                <PlacesElement establishment={memberAction.unit} feature={feature} gridContainer={placeGridContainer}
                    textColor={textColor} userAction={memberAction.userAction} userActionId={memberAction.userActionId} />}
        
            {memberAction.featureId === features_router.trips.featureId &&
            <TripsComponent   userAction={memberAction.userAction} gridContainer={gridContainer} userActionId={memberAction.userActionId} 
            trip={memberAction.unit} feature={feature}   textColor={textColor}/> 
                }
              
         
        {memberAction.featureId === features_router.bucket_list.featureId &&
                <BucketListElement bucketList={memberAction.unit} feature={feature} heightContainer={heightContainer}
                    widthContainer={widthContainer} isMobile={isMobile}
                    textColor={textColor} userAction={memberAction.userAction} userActionId={memberAction.userActionId} />
            }


        </React.Fragment >
    )
}

const mapStateToProps = (state) => ({
    bodyClientWidth: state.Setting.htmlBody.bodyClientWidth,
    textColor: state.Setting.appMenu.textColor,
      isMobile: state.Setting.is_mobile,

})

const mapDispatchToProps = {

}

const MemberActionElement = connect(mapStateToProps, mapDispatchToProps)(MemberActionElement_)
export default MemberActionElement
