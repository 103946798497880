import { TextField } from '@material-ui/core'
import React from 'react'
import { flightTextField } from '../../FlightsPage/flightStyles';

export const GuestContactForm = ({is_mobile, contactInfo, setContactInfo, guestList }) => {

    const contact = { ...contactInfo };

    const updateValue = (field, value) => {
        setValues(field, value)
    };

    const setValues = (field, value) => {
        contact[field] = value;
        setContactInfo(contact)
    }

    const classesAutocomplete = flightTextField()

    return (
        <div>

            <div className="row">
                <div className={is_mobile?'col-12':'col-6'}  style={{
                    marginTop: "20px", paddingRight: 0
                }}>

                    <div className={classesAutocomplete.root}>
                        <TextField
                            label='Last Name'
                            id='Last Name'
                            required={true}
                            defaultValue={contact.name_last}
                            onChange={(event) => updateValue('name_last', event.target.value)}
                        />
                    </div>
                </div>


                <div className={is_mobile?'col-12':'col-6'}  style={{
                    marginTop: "20px",
                }}>

                    <div className={classesAutocomplete.root}>
                        <TextField
                            label='First Name'
                            required={true}
                            id='First Name'
                            defaultValue={contact.name_first}
                            onChange={(event) => updateValue('name_first', event.target.value)}
                        />
                    </div>
                </div>


            </div>

            <div className="row">
                <div className={is_mobile?'col-12':'col-6'}  style={{
                    marginTop: "20px", paddingRight: 0
                }}>

                    <div className={classesAutocomplete.root}>
                        <TextField
                            label='Mobile Phone'
                            required={true}
                            id='Mobile Phone'
                            type='tel'
                            defaultValue={contact.phone_number}
                            onChange={(event) => updateValue('phone_number', event.target.value)}
                        />
                    </div>
                </div>


                <div className={is_mobile?'col-12':'col-6'}  style={{
                    marginTop: "20px",
                }}>

                    <div className={classesAutocomplete.root}>
                        <TextField
                            label='Email'
                            required={true}
                            type='email'
                            id='Email'
                            defaultValue={contact.email}
                            onChange={(event) => updateValue('email', event.target.value)}
                        />
                    </div>
                </div>
            </div>



        </div>
    )
}