import "./influencer.css"

import { ArrowLeftIcon, ArrowRightIcon, LeftQuoteIcon, RightArrowIcon, RightQuoteIcon } from '../../Common/SVGBank'

import { Image } from "react-bootstrap"
import React from 'react'
import Slider from 'react-slick'
import { connect } from 'react-redux'
import { image_boxing } from "../../Common/RegExValidate"
import moment from 'moment'
import { useHistory } from "react-router-dom"

const SliderArrowLeft = ({ className, to, onClick }) => (
    <button type="button" onClick={onClick} className={`button button--text button--icon ${className}`} aria-label={to}>
        <ArrowLeftIcon className="icon" icon={to} style={{
            height: "60px",
            width: "60px",
            transform: 'translateY(-60px)',

        }} />
    </button>
)
const SliderArrowRight = ({ className, to, onClick }) => (
    <button type="button" onClick={onClick} className={`button button--text button--icon ${className}`} aria-label={to}>
        <ArrowRightIcon className="icon" icon={to} style={{
            height: "60px",
            width: "60px",
            transform: 'translateY(-60px)'
        }} />
    </button>
)
const Influencer_ = ({ homepage, screenSize, bodyClientWidth, lang, is_mobile }) => {

    const history = useHistory()
    const goesLandingPage = () => {
        history.push("/landing")
    }
    const { influencers } = homepage
    const sliderArrowRight = <SliderArrowRight />
    let heightContainer = "340"
    const display_element_length =
        screenSize >= 1024 ? 3 :
            screenSize >= 768 ? 3 :
                screenSize > 500 ? 1 : 2
    const left_element = influencers && influencers.length < display_element_length ? "left_element" : ""
    if (!isNaN(bodyClientWidth)) {
        heightContainer = bodyClientWidth * 0.82 / 10 + 100
    }
    const setting = {
        touchMove: false,
        dots: false,
        infinite: false,
        arrows: false,
        centerMode: true,
        className: "center",
        centerPadding: "0px",
        speed: 2000,
        lazyLoad: false,
        autoplay: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        pauseOnHover: false,
        prevArrow: <SliderArrowLeft to="prev" />,
        nextArrow: sliderArrowRight,
        responsive: [
            {
                breakpoint: 1100,
                settings: {
                    touchMove: true,
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    arrows: false,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    touchMove: true,
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    arrows: false,

                }
            },
            {
                breakpoint: 500,
                settings: {
                    touchMove: true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                }
            },
        ]
    }
    return (
        <div className="testimonial" style={{
            paddingBottom:50
        }}>
            <div className="title text-center">Featured müvers</div>
            <Slider {...setting} className={left_element} style={{ height: heightContainer }}>
                {influencers.length > 0 && influencers.map((item, item_idx) => {
                    return (
                        <div className="border" key={item_idx}>
                            <Image
                                className="testimonial-avatar"
                                src={image_boxing(item.muverAvatar, 90, 90, "muvimages/user_avatar/armando.jpg")}
                                alt="inflencer" />
                            <div className="mb-2 mt-2 avatar_fullname">{(item.muverFirstName !== "" && item.muverLastName !== "") ? item.muverFirstName.charAt().toUpperCase() + item.muverFirstName.substring(1) + ' ' + item.muverLastName.charAt().toUpperCase() + '.' : ""}</div>
                            <div className="text-left">
                                <LeftQuoteIcon width={is_mobile ? "17px" : "34.473px"} height={is_mobile ? "12px" : "24.339px"} fill="#19bc9b" />
                            </div>
                            <div className="text text-center box_4" style={{ minHeight: "20px" }}>{item.muverComment}</div>
                            <div className="text-right">
                                <RightQuoteIcon width={is_mobile ? "17px" : "34.473px"} height={is_mobile ? "12px" : "24.339px"} fill="#19bc9b" />
                            </div>
                            {/* <div className="date text-center"><b>{item.muverCommentDate !== undefined && item.muverCommentDate !== null && moment(item.muverCommentDate).format("YYYY / MM / DD")}</b></div> */}
                        </div>
                    )
                })}
            </Slider>

            {!is_mobile && <div className="col-md-12 text-right" style={{ marginTop: "50px" }}>
                <div onClick={() => goesLandingPage()} className="btn btn-primary ExploreMore">
                    Learn more <RightArrowIcon />
                </div>
            </div>}
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        bodyClientWidth: state.Setting.htmlBody.bodyClientWidth,

        lang: state.Setting.lang,
        homepage: state.Home.homepage,
        is_mobile: state.Setting.is_mobile,
    }

}

const Influencer = connect(mapStateToProps, null)(Influencer_)
export default Influencer