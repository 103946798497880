import * as Actions from '../../../redux/actions'
import { EllipsisVIcon, TimesIcon } from "../Common/SVGBank"

import { FooterWhite } from "../Footer/FooterWhite.jsx"
import React from 'react'
import { connect } from 'react-redux'
import './contact.css';
import { Grid } from '@material-ui/core'
import { ContactUsForm } from './ContactUsForm'

const TermsConditionContainer_ = ({ }) => {


    React.useEffect(() => {
    }, [])

    return (
        <div style={{ marginTop: '6%', marginLeft: '16%', marginRight: '16%',minHeight:'100vh' }} >

            <Grid container style={{ margin: '6% 0px 3% 0px', padding: 0 }} spacing={9}>
                <Grid item xs={12} md={7} style={{ marginBottom: '2%', padding: 0, paddingRight: 30 }}>

                    <div style={{
                        font: '56px/30px Futura Hv BT',
                        color: "white",marginBottom:'7%',
                    }}>
                        Contact us
        </div>

                    <ContactUsForm />

                </Grid>
                <Grid item xs={12} md={5} style={{ textAlign: 'left' }}>

                    <div style={{
                        font: '46px/30px Futura Hv BT',
                        color: "white",
                        lineHeight: 1.2,
                    }}>
                        We would love to hear from you
        </div>


                    <div style={{
                        font: '20px/30px Futura Lt BT',
                        color: "white", marginTop: 15,
                        paddingBottom: '20%',
                    }}>
                        Whether for a question, a comment, a bug you found or an improvement you think we can make, we would love get your feedback.
        <br />       <br />
        MüvTravel is a work in progress made for you, to help you find inspiration, make your trip planning easy and personalized and find you the best deals we can.

        <br />       <br />
        Tell us about your experience on the site, and we will attempt to answer your questions and wishes.
        </div>
                </Grid>

            </Grid>

            {/* <FooterWhite /> */}
        </div>
    )
}

const mapStateToProps = (state) => ({
    screenSize: state.Setting.htmlBody.bodyClientWidth,

    is_mobile: state.Setting.is_mobile,
    lang: state.Setting.lang,
    uuid: state.Member.authModal.uuid,

})

const mapDispatchToProps = {
    getMemberActionsSets: Actions.getMemberActionsSets,

}

const TermsConditionContainer = connect(mapStateToProps, mapDispatchToProps)(TermsConditionContainer_)
export default TermsConditionContainer
