import { BarsIcon, TimesIcon } from "../Common/SVGBank"

import BarsSubTypeMenuItem from "./BarsSubTypeMenuItem"
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Grow from '@material-ui/core/Grow'
import MenuList from '@material-ui/core/MenuList'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import React from 'react'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'

const BarsSubType = connect((state) => ({
    subtypes: state.Destination.destination.subtypes,
    isMobile: state.Setting.is_mobile,

    switchFeatureContainer: state.FormBank.FeatureFormat,

}), {
})(({
    switchFeatureContainer,
    isMobile,
    subtypes,
}) => {
    const switchFeatureContainerColorLight = switchFeatureContainer.color + "35"
    const barsStyles = makeStyles(theme => ({
        root: {
            display: 'flex',
            '& .MuiListItem-root': {
                boxShadow: "0 0 0 0 rgba(0,123,255,.25)",
                font: "16px/16px Futura Md BT",
                color: "#707070",
            },
        },
        buttonText: {
            color: switchFeatureContainer.color,
            textAlign: "left",
            font: "16px/22px Futura Md BT",
            letterSpacing: "0",
            padding: "5px",
            '&:focus': {
                border: "0",
                outline: "0px",

            }
        },
        timesDiv: {
            marginRight: "10px",
            marginLeft: "115px",
            display: "inline-block",
            textAlign: "right",
        },
        popper: {
            zIndex: "3",
            marginTop: "20px",
            font: "16px/16px Futura Md BT",
            '& .MuiListItem-root:hover': {
                backgroundColor: switchFeatureContainerColorLight,
            },
        },
        paddingLeft30: {
            paddingLeft: "30px"
        },
        backgroundSelection: {
            '&:hover': {
                backgroundColor: switchFeatureContainerColorLight
            }
        },
        paper: {
            marginRight: theme.spacing(2),
        },
        outline: {
            '& .MuiList-root .MuiList-padding': {
                outline: "0px",
            }
        },
    }))
    const [open, setOpen] = React.useState(false)
    const anchorRef = React.useRef(null)
    const classesBars = barsStyles()
    const handleToggle = () => {
        setOpen(prevOpen => !prevOpen)
    }

    const handleClose = event => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return
        }

        setOpen(false)
    }

    const prevOpen = React.useRef(open)
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus()
        }

        prevOpen.current = open
    }, [open])
    return (
        <div style={{
            paddingTop: "20px", zIndex: "1", marginRight: "5px"
        }}>
            <div
                style={{
                    display: "inline-flex",
                }}
                onClick={handleToggle}
                ref={anchorRef}
                aria-haspopup="true">
                <BarsIcon />
            </div>
            {open && <Grow
                in={open}
                style={{ transformOrigin: '0 0 0' }}
                {...(open ? { timeout: 1000 } : {})}
            ><div style={{ position: "absolute", zIndex: "4", top: "190px", backgroundColor: "var(--mainWhite)", padding: "10px 0", }}>
                    <div style={{ display: "flex", width: "270px", paddingLeft: "10px", paddingRight: "10px", outline: "0", }} >
                        <div style={{ flexGrow: "1", outline: "0", font: "18px/18px Futura Hv BT", }}>Filters</div>
                        <div onClick={(event) => handleClose(event)}>{isMobile ? <TimesIcon width={13} height={13} /> : <TimesIcon width={20} height={20} />}</div>
                    </div>
                </div></Grow>}
            <ClickAwayListener onClickAway={handleClose}>
                <Popper open={open}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    className={classesBars.popper}
                    placement={"bottom-start"}
                    transition
                    modifiers={{
                        flip: {
                            enabled: false,
                        },
                    }}
                    disablePortal
                    style={{ overflowY: "scroll", marginBottom: "8px", maxHeight: "500px" }}
                >
                    <Grow
                        in={open}
                        style={{ transformOrigin: '0 0 0' }}
                        {...(open ? { timeout: 1000 } : {})}
                    >
                        <Paper>
                            <MenuList style={{ outline: "0px", width: "270px", height: "100%", marginTop: "30px" }}>
                                {subtypes !== undefined
                                    && subtypes.length > 0
                                    && subtypes.map((subtypeGroup, subtype_group_index) => {
                                        return (
                                            <BarsSubTypeMenuItem subtypeGroup={subtypeGroup} key={subtype_group_index} />
                                        )
                                    })}
                            </MenuList>
                        </Paper>
                    </Grow>
                </Popper>
            </ClickAwayListener>
        </div >

    )
})
export default BarsSubType
