import 'date-fns'

import * as Actions from '../../../../redux/actions'

import {
    MuiPickersUtilsProvider,
    TimePicker
} from "@material-ui/pickers"

import Backdrop from '@material-ui/core/Backdrop'
import Button from '@material-ui/core/Button'
import { ClockIcon } from "../SVGBank"
import DateFnsUtils from "@date-io/date-fns"
import Modal from '@material-ui/core/Modal'
import React from 'react'
import {
    activityTimeModalUseStyles,
} from "../ComponentsStylingBank"
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import moment from "moment"

const ActivityDuration_ = ({ from = "", closeEllipsis, setDisableEllipsis, lang, uuid_auth, activeTrip, activity, setDurationToActivity, inputStyle = {
    font: "10px/12px Futura Md BT",
    color: "#7F7F7F",
    width: "40px",
    textAlign: "right",
    padding: "1px 0px",
    margin: "0px",
    display: "inline-block",
    cursor: "pointer",
} }) => {
    const timeStylingTextField = makeStyles(theme => ({
        root: {
            '& .MuiInput-input': inputStyle,
            '& .MuiInput-underline:before': {
                borderBottom: "0px",
            },
            '& .MuiInput-underline:after': {
                borderBottom: "2px solid var(--mainGreen)",
            },
            '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                borderBottom: "2px solid rgba(25, 188, 155, 0.45)",
            },

        },
    }))
    const tripId = activeTrip.tripId
    const uuid = uuid_auth !== "" ? uuid_auth : "TMPUUID"

    const defaultTime = activity.activityNoTime === "0" && String(new Date('2020-01-01 ' + activity.activityStartTime)) !== "Invalid Date" ? new Date('2020-01-01 ' + activity.activityStartTime) : null

    const timeStyling = timeStylingTextField()
    const [open, setOpen] = React.useState(false)
    const classes = activityTimeModalUseStyles()

    React.useEffect(() => {
        const timeUpdate = String(new Date('2020-01-01 ' + activity.activityStartTime)) !== "Invalid Date" ? new Date('2020-01-01 ' + activity.activityStartTime) : null
        setSelectedDate(timeUpdate)
    }, [activity.activityStartTime])

    const [selectedDate, setSelectedDate] = React.useState(defaultTime)
    const handleDateChange = date => {
        const new_date = date === null ? new Date('2020-01-01 00:30') : date
        setSelectedDate(new_date)
    }

    const saveTime = () => {
        setDurationToActivity(lang, uuid, activity.activityID, selectedDate, tripId)
        setOpen(false)
        from === "ellipsis" && setDisableEllipsis(false)
        from === "ellipsis" && closeEllipsis(false)
    }
    const handleClickOpen = (event) => {
        event.stopPropagation()
        event.preventDefault()
        from === "ellipsis" && setDisableEllipsis(true)
        setOpen(true);
    }
    const handleClickClose = (event) => {
        event.stopPropagation()
        event.preventDefault()
        from === "ellipsis" && setDisableEllipsis(false)
        from === "ellipsis" && closeEllipsis(false)
        setOpen(false);
    }
    return (
        <React.Fragment>
            {from === "ellipsis" ?
                <div style={{ cursor: "pointer", textAlign: "right", color: "#191919", font: "14px/18px Futura Md BT" }}
                    onClick={(e) => handleClickOpen(e)}
                ><ClockIcon width={16} height={16} style={{ marginRight: "0px", marginBottom: "3px", marginRight: "10px" }} /> Duration
                            </div>
                :
                defaultTime? <div style={inputStyle} onClick={(e) => handleClickOpen(e)}>{
                    moment(activity.activityDuration, 'H:mm').format("H") !== "0" ?
                        (moment(activity.activityDuration, 'H:mm').format("m") !== "0" ?
                            moment(activity.activityDuration, 'H:mm').format("H[h]mm") : moment(activity.activityDuration, 'H:mm').format("H[h]")) :
                        moment(activity.activityDuration, 'H:mm').format("m[min]")
                }</div> :""}
            <Modal
                open={open}
                onClose={(e) => handleClickClose(e)}
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 1000,
                }}
            >
                <div style={{
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                }} className={classes.paper}>
                    <div style={{
                        font: "22px/33px Futura Hv BT",
                        color: "var(--frameBlack)",
                        borderBottom: "1px solid #3f51b5",
                        textAlign: "center",
                    }}>Activity duration</div>
                    <div className={timeStyling.root}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <TimePicker
                                placeholder=""

                                format={"H:mm"}
                                ampm={false}
                                value={selectedDate}
                                onChange={handleDateChange}
                                variant="static"
                                orientation="landscape"
                                error={false}
                                helperText={""}
                            // cancelLabel={"ClOSE"}
                            // clearLabel={"30 MINUTES"}
                            // clearable={true}
                            />
                        </MuiPickersUtilsProvider>
                        <div style={{
                            font: "11px/26px Futura Lt BT",
                            color: "#3f51b5",
                            borderTop: "1px solid #3f51b5",
                            display: "flex",
                        }}>
                            <div style={{ flexGrow: "1" }}>
                                <div style={{ display: "inline-block", padding: "3px 10px", cursor: "pointer", }} onClick={() => handleDateChange(new Date('2020-01-01 00:30'))} >30 min</div>
                                <div style={{ display: "inline-block", padding: "3px 10px", cursor: "pointer", }} onClick={() => handleDateChange(new Date('2020-01-01 01:00'))}>1 h</div>
                                <div style={{ display: "inline-block", padding: "3px 10px", cursor: "pointer", }} onClick={() => handleDateChange(new Date('2020-01-01 02:00'))}>2 h</div>
                                <div style={{ display: "inline-block", padding: "3px 10px", cursor: "pointer", }} onClick={() => handleDateChange(new Date('2020-01-01 03:00'))}>3 h</div>
                                <div style={{ display: "inline-block", padding: "3px 10px", cursor: "pointer", }} onClick={() => handleDateChange(new Date('2020-01-01 05:00'))}>5 h</div>
                                <div style={{ display: "inline-block", padding: "3px 10px", cursor: "pointer", }} onClick={() => handleDateChange(new Date('2020-01-01 08:00'))}>8 h</div>
                            </div>
                            <Button onClick={() => saveTime()} color="primary">
                                Save
                            </Button>
                            <Button onClick={(e) => handleClickClose(e)} color="default">
                                Cancel
                            </Button>
                        </div>
                    </div>
                </div>

            </Modal>
        </React.Fragment >

    )
}

const mapStateToProps = (state) => ({
    activeTrip: state.FormBank.TimelineWizard.activeTrip,
    lang: state.Setting.lang,
    uuid_auth: state.Member.authModal.uuid,
})

const mapDispatchToProps = {
    setDurationToActivity: Actions.setDurationToActivity,
}


const ActivityDuration = connect(mapStateToProps, mapDispatchToProps)(ActivityDuration_)
export default ActivityDuration
