import * as Actions from '../../../../redux/actions'

import { MinusSignIcon, PlusSignIcon } from '../SVGBank'

import React from 'react'
import { connect } from "react-redux"
import { LightTooltip } from './tooltipActions'
export const Plus = connect((state) => ({
    screenSize: state.Setting.htmlBody.bodyClientWidth,
    isMobile: state.Setting.is_mobile,
    fetchMemeberActions: state.MemberActions.fetchMemeberActions,
    fetchFeatures: state.MemberActions.fetchFeatures,
    fetchPlusSets: state.MemberActions.fetchPlusSets,
    uuid: state.Member.authModal.uuid,
}), {
    addingPlus: Actions.addingPlus,
    deletingPlus: Actions.deletingPlus,
})(({
    fill,
    uuid,
    feature,
    // isMobile,
    // fetchMemeberActions,
    fetchFeatures,
    fetchPlusSets,
    // screenSize,
    addingPlus,
    deletingPlus,
    unitID,
    caption,

}) => {

    const unique = unitID + "_" + feature.featureId + "_plus"
    return (
        <div style={{
            font: "14px / 14px Futura Lt BT",
        }}>

            {
                uuid !== undefined && uuid !== "" &&
                <div>
                    {fetchPlusSets !== undefined && fetchFeatures.isFetched &&
                        fetchPlusSets.pluses.filter(Plus => Plus.featureUnitId + "_" + Plus.featureId + "_plus" === unique).length <= 0 ?
                        <div>
                        {caption? 
                        
                        <div style={{ cursor: "pointer", color: fill,     padding: '20px',
                        paddingRight: '40px',
                        margin: '-20px'}} onClick={() => addingPlus(uuid, unitID, feature)}>
                            <PlusSignIcon fill={fill} width={25} height={25} /> &nbsp; Add it
                        </div>
                        :
                        
                        <LightTooltip title="Add it" placement="top-start">
                         <div style={{ cursor: "pointer", color: fill}} onClick={() => addingPlus(uuid, unitID, feature)}>
                            <PlusSignIcon fill={fill} width={25} height={25} />
                        </div>
                        </LightTooltip>}
                        </div>

                       
                        :
                        <div>
                        {caption? 
                        
                        <div style={{ cursor: "pointer", color: fill,       padding: '20px',
                        paddingRight: '40px',
                        margin: '-20px'}} onClick={() => deletingPlus(uuid, unitID, feature)}>
                            <MinusSignIcon fill={fill} width={25} height={25} /> &nbsp; Added
                        </div>
                        :
                        
                        <LightTooltip title="Added" placement="top-start">
                        <div style={{ cursor: "pointer", color: fill }} onClick={() => deletingPlus(uuid, unitID, feature)}>
                            <MinusSignIcon fill={fill} width={25} height={25} /> 
                        </div>
                        </LightTooltip>}
                        </div>

                       
                    }
                </div>
                // :
                // <div>
                //     {
                //         <div style={{ cursor: "pointer" }} onClick={() => console.log("force login")}>
                //             <MinusSignIcon fill={fill} width={25} height={25} />
                //         </div>

                //     }
                // </div>
            }
        </div>
    )
})