import Logo from "./Logo"
import { Navbar } from 'react-bootstrap'
import React from 'react'
import { connect } from 'react-redux'

/* header component */
class Header_ extends React.Component {
  render() {
    const {  app_menu_height, is_mobile, header_height } = this.props
    return (
      <Navbar bg="black" variant="dark" sticky="top" className="pt-0 pb-0"
        style={{
          height: app_menu_height + "px",
          boxShadow: "0px 6px 12px #0000001A",
          justifyContent: "center",
          top: is_mobile ? "0px" : Math.ceil(-app_menu_height / 2),
        }}>
        <div className="p-0 m-0">

          <Logo header_height={header_height} />
        </div>
      </Navbar>
    )
  }
}


const mapStateToProps = (state) => {
  return {
    app_menu_height: state.Setting.appMenu.app_menu_height,
    is_mobile: state.Setting.is_mobile,
  }
};
const Header = connect(mapStateToProps, null)(Header_)
export default Header;