import React from 'react'

import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom";
import { Menu } from 'semantic-ui-react';

import { setOpenAuthModal } from '../../../../redux/actions';
import { PaymentMethodForm } from './PaymentMethodForm';
import { GuestInfo } from './GuestForm';
import { GuestContactForm } from './ContactForm';
import { LeftArrowIcon, RightArrowIcon } from '../../Common/SVGBank';
import { bookHotelThunk } from './payment.thunk';
import { testPriceLineInfo } from './testData';


export const HotelPaymentForm = ({is_mobile}) => {
    const history = useHistory();
    const authModal = useSelector(state => state.Member.authModal);
    // useSelector(state => state.FormBank.BookingPriceline) ;
    const pricelineBooking = useSelector(state => state.FormBank.BookingPriceline) ; 
    const {booking_info,booking_bundles} = pricelineBooking;
    const hotelBookingInfo = booking_bundles && booking_bundles.length > 0 ? booking_bundles[booking_bundles.length-1]: null;

    const roomText = booking_info && booking_info.room_text ? booking_info.room_text : null;


    const guests = booking_info && booking_info.num_rooms ? Number(booking_info.num_rooms) : 1;

    const [selectedType, setType] = React.useState(1)
    const FormTypes = ['Guests Info', 'Contact Details', 'Payment Method'];
    const dispatch = useDispatch()
    let [guestList, setGuestList] = React.useState(Array(guests).fill({}));
    let [contactInfo, setContactInfo] = React.useState({});
    let [paymentInfo, setPaymentInfo] = React.useState({});
    let [promoCode, setPromoCode] = React.useState();
    let [specialRequest, setSpecialRequest] = React.useState();


    const selectType = (value) => {
        setType(value)
    }

    const signIn = () => {
        dispatch(setOpenAuthModal(true));
    }

    const previousStep = () => {
        if (selectedType === 1) {
            history.goBack()
        }
        else {
            selectedType === 2 ? setType(1) : setType(2)
        }
    }

    const nextStep = () => {
        if (selectedType !== 3) {
            selectedType === 1 ? setType(2) : setType(3)
        }
        else {
           const paymentData = {
               guestList: guestList,
               contactInfo: contactInfo,
               paymentInfo: paymentInfo,
               bookingInfo: hotelBookingInfo,
               promoCode: promoCode,
               specialRequest:specialRequest
           }
           dispatch(bookHotelThunk(paymentData))
        }
    }



    return (
        <div>
          
            <Menu pointing secondary style={{ marginTop: 20, paddingBottom: 35, borderBottom: "10px solid var(--lightGrayF)" }}>
                {FormTypes.map((type, index) => {
                    return (
                        <div key={index}
                            style={{
                                letterSpacing: "0",
                                backgroundColor: "initial",
                                opacity: "1",
                                textAlign: "left",
                                height: is_mobile? 'auto':45,
                                paddingBottom:is_mobile?10:0,
                                width: "100%",
                                paddingTop: "0px",
                                paddingLeft: index * 20,
                                paddingRight: "15px",
                                color: selectedType === index + 1 ? "#19BC9B" : '#B1B1B1',
                                borderBottom: selectedType === index + 1 ? '10px solid #19BC9B' : '10px solid var(--lightGrayF)',
                                marginBottom: -48,
                                cursor: "pointer",
                            }}
                            onClick={() => selectType(index + 1)}>
                            <p style={{ textAlign: 'left', font: "18px/18px Futura Md BT" }}> {index + 1}. {type}</p>
                        </div>
                    )
                })}
            </Menu>

            {selectedType === 1 &&
                 <GuestInfo is_mobile={is_mobile} roomText={roomText}   setSpecialRequest={setSpecialRequest} specialRequest={specialRequest}
                   setGuestList={setGuestList} guestList={guestList} promoCode={promoCode} setPromoCode={setPromoCode} contactInfo={contactInfo} setContactInfo={setContactInfo} />   
            }
            {selectedType === 2 && <GuestContactForm is_mobile={is_mobile}
            contactInfo={contactInfo} setContactInfo={setContactInfo}  guestList={guestList} />}
            {selectedType === 3 && <PaymentMethodForm is_mobile={is_mobile}
             paymentInfo={paymentInfo} setPaymentInfo={setPaymentInfo} />}


            {selectedType === 1 && <div style={{ padding: '16px 35px', marginTop: 30, marginBottom: 30, background: '#19BC9B33', color: '#19BC9B', font: '14px/14px Futura Lt BT' }}>
                Note: please ensure the ID is vaild for at least 6 months after the date of departure
                </div>}

            {selectedType !== 1 && <div style={{ padding: '16px 35px', marginTop: 30, marginBottom: 30, borderLeft: '3px solid #19BC9B', background: '#19BC9B33', color: '#19BC9B', font: '14px/14px Futura Lt BT' }}>

                {selectedType === 2 ? <p style={{lineHeight:1.5}}> We will send your booking confirmation and check-in info to this email address.
                 Please ensure you are able to receive emails when traveling before submitting your booking. </p>:
                   <p style={{lineHeight:1.5}}> SAFE AND SECURE BILLING Your credit card transaction is protect by an secure SSL encrypted transaction </p>}
            </div>}




            <div>
                <div style={{ marginBottom: 20 }}>
                    <button className='recapButtons' style={{ background: '#DFDFDF' }} onClick={previousStep}>
                        <LeftArrowIcon fill={'#7F7F7F'} height={14} width={14} style={{ marginRight: 10 }} />  {selectedType !== 1 ? 'Previous step' : 'Back to search results'}
                    </button>


                    <div style={{ float: "right" }}>
                        <button className='recapButtons' style={{ background: '#19BC9B', color: 'white', font: '14px/14px Futura Hv BT' }} onClick={nextStep}>
                            {selectedType !== 3 ? 'Next step' : 'Book'} <RightArrowIcon height={14} width={14} style={{ marginLeft: 90 }} />
                        </button>
                    </div>

                </div>

            </div>

        </div>
    )
}