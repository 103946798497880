import * as Actions from '../../../redux/actions'

import { AngleUpIcon } from '../Common/SVGBank'
import React, { Component } from 'react'
import {list_container_mode } from '../Common/RegExValidate'

import Fab from '@material-ui/core/Fab'
import LazyLoadingScrollEventListener from './LazyLoadingScrollEventListener'
import { ScrollTopLazyLoading } from "../Common/ScrollTopLazyLoading"
import { connect } from 'react-redux'
import { member_area_router } from "../Common/RegExValidate"
import { BucketListView } from '../Common/BucketList/BucketListView'
import BucketListCardView  from '../Common/BucketList/BucketListCardView'


class BucketList_ extends Component {

    onDragOverTrip = (event) => {
        event.preventDefault()
        event.stopPropagation()
    }

    onDropDeleteActivities = (event) => {
        this.props.draggingActivity !== undefined &&
            this.props.draggingActivity.dragDeleteActivity !== undefined &&
            this.props.draggingActivity.dragDeleteActivity !== null &&
            this.props.deleteActivityToDayOfTripDestinationInTrip(
                this.props.draggingActivity.dragDeleteActivity.lang,
                this.props.draggingActivity.dragDeleteActivity.uuid,
                this.props.draggingActivity.dragDeleteActivity.tripDestinationId,
                this.props.draggingActivity.dragDeleteActivity.activityID,
                this.props.draggingActivity.dragDeleteActivity.tripId)
    }

    copyTripToActiveTrip = (lang, uuid, tripID, tripIdFrom) => {
        const { copyTripDestinationsToTrip } = this.props
        //console.log(`Trips: copyTripToActiveTrip active tripID = ${tripID} from an trip of tripId ${tripIdFrom}`)
        //console.log (`lang: ${lang} uuid = ${uuid}`)
        copyTripDestinationsToTrip(lang, uuid, tripID, tripIdFrom)
    }

    render() {
        const { bucketlist, switch_container, lang, uuid_auth, activeTrip, timelineWizardStatus, /*activeTripDestinationId, activeTripDay, */bodyClientWidth, isMobile, destinaionListDisplayFormat, switchFeatureContainer, selectedSubtypes, lazyLoading, activeTripActivities } = this.props

        let widthContainer = ""
        let heightContainer = ""
        let widthGridContainer = ""
        let heightGridContainer = ""
        let postionContainer = ""
        let positionScrollToTopLazyLoading = ""
        const uuid = uuid_auth !== "" ? uuid_auth : "TMPUUID"
        if (!isNaN(isMobile)) {
            widthContainer = isMobile ? (bodyClientWidth * 0.98) : (timelineWizardStatus ? ((bodyClientWidth - 380) * 0.65 / 2) : (bodyClientWidth * 0.65 / 2))
            heightContainer = isMobile ? "" : (timelineWizardStatus ? ((bodyClientWidth - 380) * 0.65 / 2 / 2) : (bodyClientWidth * 0.65 / 2 / 2))
            postionContainer = isMobile ? "block" : "d-flex"
            widthGridContainer = isMobile ? (bodyClientWidth * 0.98 - 5) : (timelineWizardStatus ? (Math.floor(bodyClientWidth * 0.535 / 3 - 8)) : (Math.floor(bodyClientWidth * 0.7 / 3 - 8)))
            heightGridContainer = Math.floor(widthGridContainer * 215 / 375)
            positionScrollToTopLazyLoading = isMobile ? "" : (timelineWizardStatus ? "10%" : "10%")
        }

        //console.log("activeTrip : ")
        //console.log(activeTrip)

        return (
            <React.Fragment>

                { switch_container === member_area_router.memberAreaTrip ?

                    <div  //member
                        style={{
                            zIndex: "1",
                        }}>
                        <div id="back_to_top_trip"></div>
                        {bucketlist !== undefined && bucketlist.length > 0
                            && bucketlist.map((bucket, bucket_idx) => {
                                return (
                                    <React.Fragment key={bucket_idx}>
                                        <div
                                            onDragOver={(e) => this.onDragOverTrip(e)}
                                            onDrop={(e) => this.onDropDeleteActivities(e)}

                                            className={destinaionListDisplayFormat === list_container_mode.grid ? "d-inline-block" : ""}>
                                            {destinaionListDisplayFormat === list_container_mode.list &&
                                                <BucketListView timelineWizardStatus={timelineWizardStatus}
                                                postionContainer={postionContainer}
                                                widthContainer={widthContainer}
                                                heightContainer={heightContainer}
                                                bucketItem={bucket}
                                                switchFeatureContainer={switchFeatureContainer}
                                                isMember={true} is_mobile={isMobile} />
                                            }
                                            {destinaionListDisplayFormat === list_container_mode.grid &&
                                                 <BucketListCardView bucketItem={bucket} 
                                                 widthContainer={widthContainer}
                                                 heightContainer={heightContainer}
                                                 is_mobile={isMobile} timelineWizardStatus={timelineWizardStatus} />
                                                  
                                            }
                                        </div>
                                    </React.Fragment>
                                )
                            })
                        }
                        <ScrollTopLazyLoading {...this.props} targetId={"#back_to_top_trip"} rightPosition={positionScrollToTopLazyLoading}>
                            <Fab size="small" aria-label="scroll back to top" style={{
                                outline: "0px",
                                backgroundColor: switchFeatureContainer.color
                            }}>
                                <AngleUpIcon fill={"var(--mainWhite)"} style={{ marginLeft: "3px" }} />
                            </Fab>
                        </ScrollTopLazyLoading>
                        <LazyLoadingScrollEventListener />
                    </div >
                    :
                    <div   //non-member
                        style={{
                            zIndex: "1",
                        }}>
                        <div id="back_to_top_trip"></div>
                        {bucketlist !== undefined && bucketlist.length > 0
                            && bucketlist.map((bucket, bucket_idx) => {
                                return (
                                    <React.Fragment key={bucket_idx}>
                                        <div
                                            onDragOver={(e) => this.onDragOverTrip(e)}
                                            onDrop={(e) => this.onDropDeleteActivities(e)}

        
                            className={destinaionListDisplayFormat === list_container_mode.grid ? "d-inline-block" : ""}>
                                            {destinaionListDisplayFormat === list_container_mode.list &&
                                                
                                                  <BucketListView timelineWizardStatus={timelineWizardStatus}
                                                  postionContainer={postionContainer}
                                                  widthContainer={widthContainer}
                                                  heightContainer={heightContainer}
                                                  bucketItem={bucket}
                                                  switchFeatureContainer={switchFeatureContainer}
                                                  isMember={false}/>
                                            }
                                            {destinaionListDisplayFormat === list_container_mode.grid &&
                                                     <BucketListCardView bucketItem={bucket}
                                                     widthContainer={widthContainer}
                                                     heightContainer={heightContainer} is_mobile={isMobile}  timelineWizardStatus={timelineWizardStatus}/>

                                            }

                                            
                                          
                                        </div>
                                    </React.Fragment>
                                )
                            })
                        }
                        <ScrollTopLazyLoading {...this.props} targetId={"#back_to_top_trip"} rightPosition={positionScrollToTopLazyLoading}>
                            <Fab size="small" aria-label="scroll back to top" style={{
                                outline: "0px",
                                backgroundColor: switchFeatureContainer.color
                            }}>
                                <AngleUpIcon fill={"var(--mainWhite)"} style={{ marginLeft: "3px" }} />
                            </Fab>
                        </ScrollTopLazyLoading>
                        <LazyLoadingScrollEventListener />
                        <style dangerouslySetInnerHTML={{
                __html: `
                   *::-webkit-scrollbar-thumb {
                    background-color: var(--lightGraySeparator);
                    border-radius: 20px;
                    border: 3px solid ${switchFeatureContainer.color};
                  }
                `}} />

                    </div >
                }
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    // console.log(state)
    return {
        destinaionListDisplayFormat: state.Destination.destination.destinaionListDisplayFormat,
        selectedSubtypes: state.Destination.destination.selectedSubtypes,
        lazyLoading: state.Destination.destination.lazyLoading,
        bucketlist: state.Destination.destination.resultSetsBucketList,
        switchFeatureContainer: state.FormBank.FeatureFormat,
        isMobile: state.Setting.is_mobile,
        bodyClientWidth: state.Setting.htmlBody.bodyClientWidth,
        timelineWizardStatus: state.FormBank.TimelineWizard.status,
        draggingActivity: state.FormBank.TimelineWizard.draggingActivity,
        activeTripActivities: state.FormBank.TimelineWizard.activeTrip.activities,
        lang: state.Setting.lang,
        uuid_auth: state.Member.authModal.uuid,
        activeTrip: state.FormBank.TimelineWizard.activeTrip,
        activeTripDestinationId: state.FormBank.TimelineWizard.activeTripDestinationId,
        activeTripDay: state.FormBank.TimelineWizard.activeDay,

    }
}

const mapDispatchToProps = {
    setScreenVertical: Actions.setScreenVertical,
    fetchEstablishmentsLazyloading: Actions.fetchEstablishmentsLazyloading,
    setPageYOffset: Actions.setPageYOffset,
    deleteActivityToDayOfTripDestinationInTrip: Actions.deleteActivityToDayOfTripDestinationInTrip,
    copyTripDestinationsToTrip: Actions.copyTripDestinationsToTrip
}

const BucketList = connect(mapStateToProps, mapDispatchToProps)(BucketList_)
export default BucketList
