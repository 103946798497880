import React from 'react'
import { connect } from 'react-redux'

const About_ = ({ establishment }) => {
    const { generalInfo } = establishment

    const descSource = generalInfo?.descriptionSource
    const descUri = generalInfo?.descriptionSourceUri
    return (
        <div>
            <div style={{ font: "30px/36px Futura Hv BT", color: "#191919", marginBottom: "30px", }}>About</div>
            <div>
                {/* <div style={{ float: "right", width: "50%", height: "120px" }}></div> */}
                <div style={{      font:  "19px / 19px Futura Lt BT",
                    color: "var(--mainGrey)",
                    lineHeight: 1.3,
                    textAlign: "justify", }}
                dangerouslySetInnerHTML={{ __html: generalInfo.about }}>
                </div>

                {descUri &&<div style={{   font:  "16px / 16px Futura Lt BT",
                    color: "rgb(13, 58, 125)", marginTop:10,
                    lineHeight: 1.3,textAlign:'right'}}>
                       <a href={descUri} rel="noopener noreferrer" target="_blank"> Source {descSource} </a>
                </div>}
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({

    establishment: state.Destination.establishment,
    isMobile: state.Setting.is_mobile,
    lang: state.Setting.lang,
    uuid: state.Member.authModal.uuid,

    switchFeatureContainer: state.FormBank.FeatureFormat,
})

const mapDispatchToProps = {

}

const About = connect(mapStateToProps, mapDispatchToProps)(About_)
export default About
