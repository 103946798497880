import * as Actions from '../../../redux/actions'
import EstablishmentReviews from '../Common/Establishment/EstablishmentReviews'
import React from 'react'
import { connect } from 'react-redux'
import "./ReviewContainer.css"
import ReviewsUserPost from './ReviewsUserPost';
import ReviewsList from './ReviewsList';
import { Grid } from '@material-ui/core'
import ReviewsRatingComponent from '../Common/ReviewsRating/ReviewsUserRating';
import { hexToRGB } from '../../../utility/utility';

const ReviewContainer_ = ({ switchFeatureContainer, uuid,  bucketlist, app_menu_height, lang }) => {
    const [open, setOpen] = React.useState(false)
    let generalInfo = bucketlist ? bucketlist.generalInfo: null;
    const headerHeight = (-Math.ceil(-app_menu_height / 2))
    const menuOffset = -headerHeight - 90
    let reviews = bucketlist ? bucketlist.reviews : null;

    return (
        <React.Fragment>
            <div className="reviewContainer">
                <div  style={{ font: "30px/36px Futura Hv BT", color: "#191919", marginBottom: "30px" }}>
                      Reviews
                </div>
                <Grid container spacing={3} >

                <Grid item  xs={12} sm={12} md={6} lg={6}> 
            <div className="rating"> 
                <ReviewsRatingComponent reviews= {reviews} color={'#fb884d'}/>    
            </div>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6} >
                    <div >
                            <ReviewsUserPost generalInfo ={generalInfo} uuid={uuid} lang ={lang}/>
                      </div>
                     </Grid>
                </Grid>

                <div className="posting">
        <hr className="line_separator_w margin_top_50 margin_bottom_50" /> 
              <ReviewsList reviews={reviews} open={open} />
            </div>

            {open  && reviews.reviews.length > 3 ? <div style={{ color: "#0D3A7D", font: "18px/26px Futura Md BT", textAlign: "center", marginTop: "30px", cursor: "pointer", }} onClick={() => setOpen(false)}>{"see less reviews"}</div>
                : (reviews && reviews.reviews.length > 3 ? <div style={{ color: "#0D3A7D", font: "18px/26px Futura Md BT", textAlign: "center", marginTop: "30px", cursor: "pointer", }} onClick={() => setOpen(true)}>{"see more reviews"}</div> : "")
            }

            </div>
            <style dangerouslySetInnerHTML={{
                __html: `
                .MuiRating-iconEmpty {
                    color: ${hexToRGB('#fb884d','0.3')} !important;
                }


                `}} />
        </React.Fragment >
    )
}


const mapStateToProps = state => {
    return {
        logo_height: state.Setting.appMenu.logo.height,
        sliderImages: state.Destination.establishment.sliderImages,
        lang: state.Setting.lang,
        uuid: state.Member.authModal.uuid,
        is_mobile: state.Setting.is_mobile,
        switchFeatureContainer: state.FormBank.FeatureFormat,
        app_menu_height: state.Setting.appMenu.app_menu_height,
        screenSize: state.Setting.htmlBody.bodyClientWidth,

        bucketlist: state.Destination.bucketlist,
    }
}

const mapDispatchToProps = {

   
}

const ReviewContainer = connect(mapStateToProps, mapDispatchToProps)(ReviewContainer_)
export default ReviewContainer
