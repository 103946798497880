
import React, { useRef } from 'react'
import * as Actions from '../../../redux/actions'
import { connect } from 'react-redux'
import MemberTripSubContainer from '../MemberArea/Trip/MemberTripSubContainer';
import PrintTripSubContainer from './PrintTripSubContainer';
import ReactToPrint, { useReactToPrint } from 'react-to-print';
import { PrintIcon } from '../Common/SVGBank';
import { Button } from '@material-ui/core';

/* member trip container component */
const PrintContainer_ = ({ is_mobile, switch_container, trip,activeTrip,lang,props,fetchActiveTripByTripId}) => {
    const [loaded, setLoaded] = React.useState(false)
    React.useEffect(() => {
        if((trip &&  Object.keys(trip).length) || (activeTrip &&  Object.keys(activeTrip).length) ){
            setLoaded (true)
        }
        else{
            setLoaded (false)
        }
    }, [trip,activeTrip])

    React.useEffect(() => {
        if (props.match.params.tripID !== undefined &&
            props.match.params.tripID !== null &&
            props.match.params.tripID !== "") {
            fetchActiveTripByTripId(props.match.params.tripID, lang)

        }
    }, [props.match.params.tripID])
    const pageStyle = `
    @page {
      size: 80mm 50mm;
    }
  
    @media all {
      .pagebreak {
        display: none;
      }
    }
  
    @media print {
      .pagebreak {
        page-break-before: always;
      }
    }
  `;
    const componentRef = useRef();
    //console.log("TripContainer.jsx")
    return (
        <React.Fragment>
            {loaded && <div>
           

                <div style={{
                    height: "auto",
                    marginLeft: is_mobile ? "" : "8.5%",
                    marginRight: is_mobile ? "" : "8.5%",
                }}>
                  
                  <div  >
                  <Button
                                onClick={() => window.print()}
                                style={{

                                    position: 'absolute',
                                    right: '16%',
                                    marginTop: '4%'
                                }}>   <PrintIcon />
                                <p style={{ font: "18px/20px Futura Md BT", textTransform: 'capitalize' }}>Print Trip</p>

                            </Button>
                  <PrintTripSubContainer  />
                      </div>
                   
                </div>
              
            </div>}
        </React.Fragment>
    )
}

const mapStateToProps = state => {
    return {
        is_mobile: state.Setting.is_mobile,
        activeTrip: state.FormBank.TimelineWizard.activeTrip,
        screenSize: state.Setting.htmlBody.bodyClientWidth,
        switch_container: state.Member.switch_container,
        trip: state.Member.memberArea.selectedTrip,
        lang: state.Setting.lang,
    }
}
const mapDispatchToProps = {
    //destination explore    
    fetchActiveTripByTripId: Actions.fetchActiveTripByTripId,
}


const PrintContainer = connect(mapStateToProps, mapDispatchToProps)(PrintContainer_)
export default PrintContainer
