import { createAsyncThunk } from "@reduxjs/toolkit";
import Axios from "axios";
import Cookies from "js-cookie";
import { environment } from "../../../../Environments/environment";
import { setTripToTimeline } from "../../../../redux/actions";
import { ALERT_BARS_HANDLE, GetAsyncHelper, PostAsyncHelper, SET_TIMELINE_WIZARD_STATUS } from "../../../../redux/interfaces/types";
import { getSessionId } from "../../../../utility/utility";
import { getNearByDestinationCode } from "../../FlightsPage/services/Flights.thunks";


export const bookHotelThunk = createAsyncThunk(
    'Hotel/bookHotel',
    async (query, thunkAPI) => {
        const dispatch = thunkAPI.dispatch;
        let errorMessage = ""
        try {

            const contactInfo = query.contactInfo;
            const guests = query.guestList;
            const specialRequest = query.specialRequest;
            const promoCode = query.promoCode;
            const paymentInfo = query.paymentInfo;
            const bookingInfo = query.bookingInfo;
            const ppnBundle = bookingInfo ? bookingInfo.ppn_book_bundle : null;

            const guestFirstNames = () => {
                if (guests && guests.length > 0) {
                    return guests.map((guest, index) => {
                        return guest.guest_name_first;
                    })
                }
            }

            const guestLastNames = () => {
                if (guests && guests.length > 0) {
                    return guests.map((guest, index) => {
                        return guest.guest_name_last;
                    })
                }
            }

            const expires = paymentInfo ? paymentInfo.cc_exp_mo + paymentInfo.cc_exp_yr : null;


            const response = await Axios.request({
                url: `${environment.apiUrl}proxy/dispatchRequest`,
                method: 'get',
                params: {
                    "proxyData[ppn_bundle]": ppnBundle,
                    "proxyData[phone_number]": contactInfo.phone_number,
                    "proxyData[name_first]": contactInfo.name_first,
                    "proxyData[name_last]": contactInfo.name_last,
                    "proxyData[email]": contactInfo.email,
                    "proxyData[guest_name_first]": guestFirstNames(),
                    "proxyData[guest_name_last]": guestLastNames(),
                    "proxyData[comments]": specialRequest,
                    "proxyData[address_line_one]": paymentInfo.address_line_one,
                    "proxyData[address_line_two]": paymentInfo.address_line_two,
                    "proxyData[address_city]": paymentInfo.address_city,
                    "proxyData[country_code]": paymentInfo.country_code,
                    "proxyData[address_postal_code]": paymentInfo.address_postal_code,
                    "proxyData[address_state_code]": paymentInfo.address_state_code,
                    "proxyData[card_holder]": paymentInfo.card_holder,
                    "proxyData[card_type]": paymentInfo.card_type,
                    "proxyData[card_number]": paymentInfo.card_number,
                    "proxyData[expires]": expires,
                    "proxyData[cvc_code]": paymentInfo.cvc_code,
                    "proxyData[sid]": getSessionId(),
                    proxyApiMethod: "POST",
                    proxyApiProperty: "hotel",
                    proxyApiEndpoint: "getExpress.Book",
                },
            })

            const errorStatus = response?.data?.ProxyResult?.status;
            const errorStatusMessage = response?.data?.ProxyResult?.status_message;

            errorMessage = errorStatus ? formatMessage(errorStatus) : formatMessage(errorStatusMessage);

            const data = response.data.ProxyResult["getHotelExpress.Book"]?.results || null;
            if (data) {
                dispatch({
                    type: ALERT_BARS_HANDLE,
                    message_type: "success",
                    message: "Your booking was successful"
                })
                const { FormBank, Member } = thunkAPI.getState();
                const { booking_info,booking_bundles } = FormBank?.BookingPriceline;
                const uuid = Member?.authModal?.uuid;

                const booking = booking_bundles && booking_bundles.length > 0 ? booking_bundles[0]: null;
                const saveData = {
                    postData: {
                        pricelineHotelId: booking_info?.hotel_id || "",
                        booking_trip_number: data.book_data?.itinerary.id || "",
                        booking_status: data.book_data?.itinerary.status || "",
                        booking_status_code: data.book_data?.itinerary.code || "",
                        checkIn: booking_info.check_in || "",
                        checkOut: booking_info.check_out || "",
                        bookedEmail: contactInfo.email,
                        displaySymbol: booking?.rate_symbol,
                        displayCurrency: booking?.rate_currency,
                        displayTotalFare: booking?.rate_total
                    },
                    uuid: uuid || ""
                }
                dispatch(saveHotelTimeline(saveData))

                return data;
            }
            dispatch({
                type: ALERT_BARS_HANDLE,
                message_type: "error",
                message: errorMessage
            })
            return thunkAPI.rejectWithValue(errorMessage)
        }
        catch (err) {
            dispatch({
                type: ALERT_BARS_HANDLE,
                message_type: "error",
                message: errorMessage
            })
            return thunkAPI.rejectWithValue(err.response.data)
        }

    }
)

const formatMessage = (str) => {
    if (str) {
        return str.substr(str.indexOf(":") + 2)
    }
    return "Booking failed, Please try again"
}


export const saveHotelTimeline = createAsyncThunk(
    'Hotel/saveHotelTimeline',
    async (query, thunkAPI) => {
        const dispatch = thunkAPI.dispatch;
        const state = thunkAPI?.getState();
      
        const uuid = query?.uuid;
        try {
            let apiURL = environment.apiUrl + "bookingData/priceline/hotel/processPricelineHotel/" + uuid;
            let postData = query?.postData;
            const response = await PostAsyncHelper(apiURL, postData);
            if (response && response.data) {
                const tripID = response.data?.result?.tripID;
                dispatch(setTripToTimeline(tripID, uuid))

                dispatch({
                    type: ALERT_BARS_HANDLE,
                    message_type: "success",
                    message: "Your hotel was saved"
                })
                // dispatch({
                //     type: SET_TIMELINE_WIZARD_STATUS,
                //     status: false
                // })
                return response.data;
            }
            dispatch({
                type: ALERT_BARS_HANDLE,
                message_type: "error",
                message: uuid ? "Your hotel was not saved" : "You must log in to save this"
            })
        }
        catch {
            dispatch({
                type: ALERT_BARS_HANDLE,
                message_type: "error",
                message: uuid ? "Your hotel was not saved" : "You must log in to save this"
            })
            return null;
        }
    }
)


export const getNearByAirport = createAsyncThunk(
    'Hotel/getNearByAirport',
    async (query, thunkAPI) => {
        try {
            const dispatch = thunkAPI.dispatch;
            const latitude = query.lat;
            const longitude = query.lng

            let apiURL = environment.apiUrl + `muvsearch/nearestairport?latitude=${latitude}&longitude=${longitude}`;

            const response = await GetAsyncHelper(apiURL);

            if (response && response.statusText === "OK") {
                const airport = response?.data[0];
                if (airport) {
                    dispatch(getNearByDestinationCode(airport?.iata));
                }
                return airport;
            }
        }
        catch {
            return null;
        }
    }
)

export const searchHotelByNameThunk = createAsyncThunk(
    'Hotel/searchHotelByNameThunk',
    async (query, thunkAPI) => {
        try {
            const response = await Axios.request({
                url: `${environment.apiUrl}proxy/dispatchRequest`,
                method: 'get',
                params: {
                    "proxyData[string]":query,
                    "proxyData[show_all_cities]":false,
                    "proxyData[get_hotels]":true,
                    "proxyData[get_cities]":false,
                    "proxyData[max_results]":3,
                    "proxyData[sort]":"name",
                    proxyApiMethod:"GET",
                    proxyApiProperty:"hotel",
                    proxyApiEndpoint:"getAutoSuggestV2"
                },
            })

           
            if (response.data.ProxyResult["getHotelAutoSuggestV2"]) {
                const hotels = response.data.ProxyResult?.getHotelAutoSuggestV2?.results?.result?.hotels; 
                return hotels;
            }
        }
        catch (err) {
            return thunkAPI.rejectWithValue(err.response.data)
        }

    }
)



export const deleteHotelTimeline = createAsyncThunk(
    'Hotel/deleteHotelTimeline',
    async (query, thunkAPI) => {
        const uuid = query?.uuid;
        const tripId =query?.tripId
        const isCustom = query?.isCustom;
        const dispatch = thunkAPI.dispatch;
      try{
        let apiURL = environment.apiUrl + ( isCustom ? "/timeline/removeCustomHotel/":
        "/timeline/removeMuvHotel/") + uuid;
        console.log(apiURL,query,'555')
        const postData = query?.postData;
  
        const response = await PostAsyncHelper(apiURL, postData);
       if(response && response.data ){


            dispatch(setTripToTimeline(tripId, uuid))
        
            // dispatch({
            //     type: SET_TIMELINE_WIZARD_STATUS,
            //     status: false
            // })

        dispatch({
            type: ALERT_BARS_HANDLE,
            message_type: "success",
            message: "Your hotel was removed"
        })
           return response.data;
       }
       dispatch({
        type: ALERT_BARS_HANDLE,
        message_type: "error",
        message: "Your hotel was not removed"
    })

      }
      catch{
        dispatch({
            type: ALERT_BARS_HANDLE,
            message_type: "error",
            message: "Your hotel was not removed"
        })
        return null;
      }
    }
)


export const lookUpHotelThunk = createAsyncThunk(
    'Hotel/lookUpHotelThunk',
    async (query, thunkAPI) => {
        const email = query.email;
        const booking_id = query.booking_id;
        try {
            const response = await Axios.request({
                url: `${environment.apiUrl}proxy/dispatchRequest`,
                method: 'get',
                params: {
                    "proxyData[booking_id]": booking_id,
                    "proxyData[email]": email,
                    proxyApiMethod: "POST",
                    proxyApiProperty: "hotel",
                    proxyApiEndpoint: "getExpress.LookUp"
                },
            })

           
            if (response.data.ProxyResult["getHotelExpress.LookUp"]) {
                const data = response.data.ProxyResult["getHotelExpress.LookUp"].results.result;
                return data;
            }
        }
        catch (err) {
            return thunkAPI.rejectWithValue(err.response.data)
        }

    }
)




export const CancelHotelThunk = createAsyncThunk(
    'Hotel/CancelHotelThunk',
    async (query, thunkAPI) => {
        const email = query.email;
        const booking_id = query.booking_id;
        const dispatch = thunkAPI.dispatch;
        try {
            const postData = {
                booking_trip_number: booking_id,
                bookedEmail:email
            };
      

            let apiURL = environment.apiUrl + `/bookingData/priceline/hotel/cancelHotel?booking_trip_number=${booking_id}&bookedEmail=${email}`;
          
            const response = await PostAsyncHelper(apiURL, postData);
         
            if (response && response.data ) {
                const data = response.data ;

                if(data?.result){
                    dispatch({
                        type: ALERT_BARS_HANDLE,
                        message_type: "success",
                        message: "Your booking was cancelled"
                    })
                //    setTimeout(() => {
                //     window.open("/flight/search","_self")
                //    }, 3000);
                }
                return data;
            }
        }
        catch (err) {
            dispatch({
                type: ALERT_BARS_HANDLE,
                message_type: "error",
                message:  "Your booking was not cancelled" 
            })
        
            return thunkAPI.rejectWithValue(err.response.data)
        }

    }
)


export const lookUpHotelReviews = createAsyncThunk(
    'Hotel/lookUpHotelReviews',
    async (query, thunkAPI) => {
        const hotel_id = query.hotel_id;
        const offset = query.offset || 0 ;
        const reviewEndpoint = "getReviews";     // getReviews
        try {
            const response = await Axios.request({
                url: `${environment.apiUrl}proxy/dispatchRequest`,
                method: 'get',
                params: {
                    "proxyData[format]": "json2",
                    "proxyData[hotel_id]": hotel_id,
                    "proxyData[limit]": 20,
                    "proxyData[offset]": offset,
                    proxyApiMethod: "GET",
                    proxyApiProperty: "hotel",
                    proxyApiEndpoint: reviewEndpoint,
                },
            })

           
            const results = response.data?.ProxyResult?.getHotelReviews?.results;

            if (results) {
                return results;
            }
        }
        catch (err) {
            return thunkAPI.rejectWithValue(err.response.data)
        }

    }
)