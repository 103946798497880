import * as Actions from '../../../redux/actions'
import React from 'react'
import { connect } from 'react-redux'

class LazyLoadingHotelsScrollEventListener_ extends React.Component {
    handleScroll = () => {
        const windowHeight = "innerHeight" in window ? window.innerHeight : document.documentElement.offsetHeight
        const body = document.body
        const html = document.documentElement
        const docHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight)
        const windowBottom = windowHeight + window.pageYOffset
        const veticalObj = {
            windowHeight: windowHeight,
            windowBottom: windowBottom,
            isBottom: true,
        }
        const scrollTrigger = 0.7;
        const position =  windowBottom/docHeight;
        let rounded = Math.round(position * 10) / 10;
        rounded >= scrollTrigger && this.props.setScreenVertical(veticalObj)
        rounded >= scrollTrigger && !this.props.lazyLoading.noMore && !this.props.noProgress && this.props.fetchHotelsLazyDisplay(this.props.lazyLoading, this.props.filteredEstablishments)
        //rounded >= scrollTrigger && !this.props.lazyLoading.noMore && this.props.setLazyloadingIsHotelListings(true)
        this.props.setPageYOffset(window.pageYOffset)
    }

    componentDidMount() {
        window.addEventListener("scroll", this.handleScroll)
    }

    /* destory component */
    componentWillUnmount() {
        window.removeEventListener("scroll", this.handleScroll)
    }
    render() { return "" }
}

const mapStateToProps = (state) => ({
    lazyLoading: state.FormBank.HotelSearch.lazyLoading,
    filteredEstablishments: state.FormBank.HotelSearch.filteredEstablishments,
    hotel_form_state: state.FormBank.HotelSearch.hotel_form_state,
})

const mapDispatchToProps = {
    setPageYOffset: Actions.setPageYOffset,
    setScreenVertical: Actions.setScreenVertical,
    fetchHotelsLazyDisplay: Actions.fetchHotelsLazyDisplay,
    setLazyloadingIsHotelListings: Actions.setLazyloadingIsHotelListings,

}

const LazyLoadingHotelsScrollEventListener = connect(mapStateToProps, mapDispatchToProps)(LazyLoadingHotelsScrollEventListener_)
export default LazyLoadingHotelsScrollEventListener