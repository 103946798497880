import * as Actions from '../../../redux/actions'
import React from 'react';
import { connect } from 'react-redux'
import TextField from '@material-ui/core/TextField';
import { Rating } from '@material-ui/lab'
import StarBorder from '@material-ui/icons/StarBorder';
//import { Button } from '@material-ui/core'
import { RightArrowIcon } from '../Common/SVGBank'
import "./ReviewsUserPost.css"
import SignIn from "../Common/model/SignIn"
import ConfirmationReview from '../Common/model/ConfirmationReview';
import { Star } from '@material-ui/icons';

const ReviewsUserPost_ = ({ generalInfo, uuid, lang, setOpenAuthModal, setUserReviewsRatingsDestination }) => {
    const feature_id = 4
    const feature_unit_id = generalInfo ? generalInfo.bucketListId : null
    //console.log(feature_type_id)
    const [isConfirmationOpen, setIsConfirmationOpen] = React.useState(false);
    const [isSigned, setIsSigned] = React.useState(false);
    const [textInput, setTextInput] = React.useState("");
    const [rate, setRate] = React.useState(0);

    React.useEffect(() => {
        //console.log(uuid)
        if (uuid !== null && uuid !== undefined && uuid !== "") {
            setIsSigned(true)
        } else {
            setIsSigned(false)
        }
    }, [uuid])

    const handleTextFieldChange = (event) => {
        //console.log(event.target.value)
        setTextInput(event.target.value);
    }

    const rateEstblishment = (event) => {
        //console.log(`Rated with value ${event.target.value}`)
        setRate(parseInt(event.target.value));
    }

    const buttonClick = () => {
        if (!isSigned) {
            setOpenAuthModal(true)
        } else {
            setIsConfirmationOpen(true)
        }
    }

    const closeConfirmation = () => {
        setIsConfirmationOpen(false)
    }

    const handleConfirmation = () => {
        //console.log("handleConfirmation")
        let reviewPostObj = {
            lang: lang,
            uuid: uuid,
            featureId: parseInt(feature_id),
            featureUnitId: parseInt(feature_unit_id),
            comment: textInput,
            rating: parseInt(rate)
        }
        if (reviewPostObj.comment || reviewPostObj.rating) {

            setUserReviewsRatingsDestination(reviewPostObj)
            setTextInput('')
            setRate(0)
        }
    }
    let arrow_icon_size = 19
    let arrow_font = "18px/16px Futura Md BT"

    return (
        <React.Fragment>
            <div className="reviewsBucketListUserPost">
                <div className="label">
                    Write a review
                </div>
                <div className="input">
                    <TextField
                        className="textField"
                        multiline
                        value={textInput || ''}
                        rows={10}
                        margin="normal"
                        variant="outlined"
                        onChange={handleTextFieldChange}
                    />
                </div>
                <div style={{ width: "400px", height: "60px", display: "flex", flexDirection: "row", justifyContent: "space-between" }} className="bucketPost">
                    <div style={{ width: "210px" }}>
                        <div style={{ height: "25px", font: "18px/14px Futura Lt BT", color: "#274674" }}>
                            Rate this experience
                        </div>
                        <div style={{ height: "30px" }}>
                            <div style={{ marginTop: "2px" }}>
                                <Rating
                                    name="client-ratings"
                                    value={rate}
                                    max={5}
                                    onChange={rateEstblishment}
                                    style={{ color: "var(--mainOrange)", opacity: "1" }}
                                    precision={0.5}
                                    disabled={false}
                                    icon={<Star fontSize="inherit" />}
                                />
                            </div>
                        </div>
                    </div>

                    <div style={{ width: "190px", height: "60px" }}>

                        <div
                            onClick={buttonClick}
                            style={isSigned ? { backgroundColor: "var(--mainOrange)", opacity: "1", cursor: 'pointer' } : { cursor: 'pointer', backgroundColor: "var(--lightGray)", opacity: "1" }} >
                            <div style={{
                                height: "60px",
                                width: "190px",
                                textAlign: "center",
                                font: "14px/16px Futura Hv BT",
                                letterSpacing: "0",
                                margin: "0",
                                paddingTop: "20px",
                                color: "var(--mainWhite)",
                            }}>
                                {isSigned ? "Post Review " : "Login to post "} <RightArrowIcon width={arrow_icon_size} height={arrow_icon_size} style={{ marginLeft: "30px" }} />
                            </div>

                        </div>


                    </div>
                    <div>
                        <SignIn />
                    </div>
                    <div>
                        <ConfirmationReview
                            closeConfirmation={closeConfirmation}
                            isConfirmationOpen={isConfirmationOpen}
                            color={"var(--mainOrange)"}
                            confirmationMessage="Are you sure you want post this review ?"
                            handleConfirmation={handleConfirmation}
                        />
                    </div>
                </div>
            </div>
            
        </React.Fragment >
    )
}


const mapDispatchToProps = {
    setOpenAuthModal: Actions.setOpenAuthModal,
    setUserReviewsRatingsDestination: Actions.setUserReviewsRatingsDestination,
}

const ReviewsUserPost = connect(null, mapDispatchToProps)(ReviewsUserPost_)
export default ReviewsUserPost

