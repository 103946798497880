import * as Actions from '../../../../redux/actions'

import Badge from '@material-ui/core/Badge'
import IconButton from '@material-ui/core/IconButton'
import React from 'react'
import { StarEmptyIcon } from '../SVGBank'
import { connect } from 'react-redux'
import { member_area_router } from '../RegExValidate'
import { useHistory } from "react-router-dom"

const StarredIcon_ = ({ textColor, lengthStars, setSwitchContainer,color }) => {
    const history = useHistory()
    const linkStarredPage = () => {
        setSwitchContainer(member_area_router.starred)
        history.push("/starred")
    }
    return (
        <>
        <IconButton color="inherit" onClick={() => linkStarredPage()}>
            <Badge badgeContent={lengthStars} color="secondary">
                <StarEmptyIcon fill={textColor} width={18} height={18} />
            </Badge>
        </IconButton >
        <style dangerouslySetInnerHTML={{
                __html: `
                .MuiBadge-badge {
                     background-color: ${color} !important; 
                  }
                `}} />
        </>
    )
}


const mapStateToProps = (state) => ({
    textColor: state.Setting.appMenu.textColor,
})

const mapDispatchToProps = {
    setSwitchContainer: Actions.setSwitchContainer,
}
const StarredIcon = connect(mapStateToProps, mapDispatchToProps)(StarredIcon_)
export default StarredIcon

