import * as Actions from '../../../../redux/actions';

import React, { Component } from 'react'

import MenuPersType from './MenuPersType'
import PersonalizationForm from "./PersonalizationForm"
import { connect } from 'react-redux'
import { member_area_router } from "../../Common/RegExValidate"

class Personalization_ extends Component {

    render() {
        const { screenSize, app_menu_height, switch_container, is_mobile } = this.props;

        let position = "sticky"
        let text_height = "85px"
        let screen_at_1200 = 1200
        let padding_8_percent = "8.5%"

        if (!isNaN(screenSize)) {
            text_height = screenSize < screen_at_1200 ? "40px" : "85px"
            padding_8_percent = screenSize < screen_at_1200 ? "2.5%" : "8.5%"
        }
        return (
            <div style={{ padding: padding_8_percent }}>
                {screenSize >= screen_at_1200 &&
                    <div style={{
                        height: text_height,
                        font: "24px/40px Futura Hv BT",
                    }}>
                        Personalize recommendations and suggestions, input your likes and dislikes.
                    </div>
                }
                {switch_container !== member_area_router.personalInfo && <div style={{ position: position, zIndex: "3", top: is_mobile ? app_menu_height : Math.ceil(app_menu_height / 2) }}>
                    <MenuPersType />
                </div>}
                {screenSize < screen_at_1200 &&
                    <div style={{
                        height: text_height,
                        marginTop: "20px",
                        marginLeft: "20px",
                        marginRight: "20px",
                        font: "14px/22px Futura Hv BT",
                    }}>
                        Personalize recommendations and suggestions, input your likes and dislikes.
                    </div>
                }
                <PersonalizationForm />
            </div>
        )
    }
}


const mapStateToProps = (state) => ({
    screenSize: state.Setting.htmlBody.bodyClientWidth,
    app_menu_height: state.Setting.appMenu.app_menu_height,
    lang: state.Setting.lang,
    is_mobile: state.Setting.is_mobile,
    uuid: state.Member.authModal.uuid,
    switch_container: state.Member.switch_container,
})

const mapDispatchToProps = {
}
const Personalization = connect(mapStateToProps, mapDispatchToProps)(Personalization_)
export default Personalization
