import * as Actions from '../../../../redux/actions'

import React, { Component } from 'react'

import {
    Image,
} from 'react-bootstrap'
import { RightArrowIcon } from '../../Common/SVGBank';
import { connect } from 'react-redux'
import { image_boxing } from '../../Common/RegExValidate'
import { TrackEvent } from '../../../../utility/utility';

class StartPlanning_ extends Component {
    render() {

        const { is_mobile, bodyClientWidth, setBookingFormStatus, setBookingFormActiveTab } = this.props;
        const startPlanImageBg = {
            bucketName: "muvimages",
            folderName: "muv_assets",
            imageName: "whitepage/homepage_start_plan.png",
        }
        const startPlanImageItinerary = {
            bucketName: "muvimages",
            folderName: "muv_assets",
            imageName: "whitepage/homepage_itinerary.png",
        }
        const handleStartTrip = () => {
            TrackEvent('HOME',"Click create itinerary",'plan a trip')
            setBookingFormActiveTab('plan a trip')
            setBookingFormStatus(true)
        }
        return (
            <div className="createItenary" style={{ marginTop: is_mobile ? "50px" : "150px" }}>
                {!is_mobile &&
                    <div className="position-relative m-0 w-100">
                        <Image className="min_width_100 img_fit_cover"
                            src={image_boxing(startPlanImageBg, bodyClientWidth, 500)}
                            alt="start plan image" />

                        <div style={{ position: "absolute", top: "0px", width: bodyClientWidth + "px", height: "500px", backgroundColor: "#19191933" }} ></div>
                        <div className="position-absolute row m-0" style={{ top: "60px", left: "0", }}>
                            <div className="col-md-4 text-right">
                                <div className="content-section text-left d-inline-block" style={{ marginTop: "90px", width: 322 }}>
                                    <h2 style={{ font: "36px/36px Futura Hv BT" }}>Start planning</h2>
                                    <p style={{ font: "16px/18px Futura Md BT" }}>Start from ready-made itineraries or custom build a trip from scratch, you can be on your way in mere hours.</p>
                                    <div className={"open_header_booking_form"}
                                        style={{
                                            font: "16px/65px Futura Md BT",
                                            color: "#FFFFFF",
                                            background: "#19BC9B",
                                            height: "65px",
                                            width: "200px",
                                            marginTop: "15px",
                                            borderRadius: "0",
                                            textAlign: "center",
                                            cursor: "pointer",
                                        }}
                                        onClick={() => handleStartTrip()}
                                    >Create itinerary <RightArrowIcon />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-8">
                                <Image className="min_width_100 img_fit_cover"
                                    src={image_boxing(startPlanImageItinerary, bodyClientWidth / 1.7, 400)}
                                    alt="start plan itierary" /></div>
                        </div>
                    </div>}
                {
                    is_mobile &&
                    <div className="position-relative m-0 w-100">
                        <Image className="min_width_100 img_fit_cover"
                            src={image_boxing(startPlanImageBg, bodyClientWidth, 300)}
                            alt="start plan image" />
                        <div style={{ position: "absolute", top: "0px", width: bodyClientWidth + "px", height: "300px", backgroundColor: "#19191933" }} ></div>
                        <div className="position-absolute" style={{ top: "5%", left: "20px", }}>
                            <div className="content-section">
                                <h2 style={{ font: "18px/17px Futura Hv BT" }}>Start planning</h2>
                            </div>
                        </div>
                        <div className="position-absolute" style={{ top: "70%", left: "20px", }}>
                            <div className="content-section">
                                <p style={{ font: "10px/14px Futura Md BT" }}>Start from ready-made itineraries or custom build a trip from scratch you can be on your way in mere hours.</p>
                            </div>
                        </div>
                        <div className="position-absolute" style={{ bottom: "10px", right: "10px", }}>
                            <div className="content-section">
                                <div className={"open_header_booking_form"}

                                    style={{
                                        font: "12px/40px Futura Md BT", width: "120px", height: "40px",
                                        color: "#FFFFFF",
                                        background: "#19BC9B",
                                        marginTop: "15px",
                                        borderRadius: "0",
                                        textAlign: "center",
                                        cursor: "pointer",
                                    }}
                                    onClick={() => handleStartTrip()}
                                >Create itinerary <RightArrowIcon width={"13"} height={"13"} />
                                </div>
                            </div>
                        </div>
                        <div className="position-absolute" style={{ top: "10%", left: "0", }}>
                            <Image className="min_width_100 img_fit_cover"
                                src={image_boxing(startPlanImageItinerary, bodyClientWidth, 200)}
                                alt="start plan itierary" />
                        </div>
                    </div>
                }
            </div >
        )
    }
}

const mapStateToProps = (state) => {
    return {
        bodyClientWidth: state.Setting.htmlBody.bodyClientWidth,
        is_mobile: state.Setting.is_mobile,
    }
}

const mapDispatchToProps = {
    setBookingFormActiveTab: Actions.setBookingFormActiveTab,
    setBookingFormStatus: Actions.setBookingFormStatus,
}
const StartPlanning = connect(mapStateToProps, mapDispatchToProps)(StartPlanning_)
export default StartPlanning