import * as Actions from '../../../redux/actions'

import { Image } from 'react-bootstrap'
import React from 'react'
import { connect } from 'react-redux'
import { member_area_router } from "../Common/RegExValidate"
import { useHistory } from "react-router-dom"

const PrintLogo_ = ({ logo_width, app_menu_height, header_height, setSwitchContainer,height }) => {
    const logo_top = header_height === "half" ? -app_menu_height / 4 : ""
    const history = useHistory()
    const goesHomePage = () => {
        history.push("/home")
        setSwitchContainer(member_area_router.home)
    }
    return (
        <Image
            style={{
                position: "relative",
                cursor: "pointer",
                marginTop: (-height)/.75
            }}
            src={"https://amhqajbken.cloudimg.io/v7/_muv_/muvimages/muv_assets/whitepage/logo.png?func=crop&w=" + logo_width + "&h=" + logo_width}
            alt="logo app"
            onClick={() => goesHomePage()} />
    )
}

const mapStateToProps = (state) => ({
    app_menu_height: state.Setting.appMenu.app_menu_height,

    logo_width: state.Setting.appMenu.logo.width,
})

const mapDispatchToProps = {
    setSwitchContainer: Actions.setSwitchContainer,

}

const PrintLogo = connect(mapStateToProps, mapDispatchToProps)(PrintLogo_)
export default PrintLogo 
