import React from 'react'
import "./ReviewsUserRating.css"

const ReviewsRatingComponent = ({reviews, color}) => {
    const average = reviews && reviews.avarageRating.result !== undefined ? reviews.avarageRating.result.avarageRating: '0';
    const totalReview = reviews ? reviews?.totalNumberOfReviews: '0' ;
    const ratingBreakDown = reviews ? reviews.ratingBreakDown: null;
    const excellent = ratingBreakDown ?  Math.ceil(ratingBreakDown[4].NumberOfVotes) * 100/parseInt(totalReview) :0;
    const veryGood = ratingBreakDown ? Math.ceil(ratingBreakDown[3].NumberOfVotes) * 100/parseInt(totalReview) :0;
    const fair = ratingBreakDown ? Math.ceil(ratingBreakDown[2].NumberOfVotes) * 100/parseInt(totalReview) :0;
    const averageRating = ratingBreakDown ? Math.ceil(ratingBreakDown[1].NumberOfVotes) * 100/parseInt(totalReview) :0;
    const terrible = ratingBreakDown ? Math.ceil(ratingBreakDown[0].NumberOfVotes) * 100/parseInt(totalReview) :0;

    let overalRatingLabel = ""
    if ( parseInt(average) < 1.5 ) {
        overalRatingLabel = "Terrible"
    } else if (parseInt(average) < 2.5) {
        overalRatingLabel = "Average"
    } else if (parseInt(average) < 3.5) {
        overalRatingLabel = "Fair"
    } else if (parseInt(average) < 4.5) {
        overalRatingLabel = "Very Good"
    } else {
        overalRatingLabel = "Excellent"
    }
   
    const greenLine = ((length, label="", index)=> {
        length = length *3;
        const numberOfReviews =   ratingBreakDown? ratingBreakDown[index].NumberOfVotes: 0;
       return( <div style={{height: "45px"}}>
            <div style={{height: "20px", font: "12px/18px Futura Md BT", color: "#0D3A7D", marginBottom: "5px"}}>
                {label}
            </div>
            <div style={{height: "10px", transform: 'rotateX(180deg) rotateY(180deg)', width: '85%',float: 'left'}}>
                <hr style={{margin: "0px", marginRight: "-5px",width:  `${300-length}px`, backgroundColor: "lightgrey", height: "8px", float: "left" , borderRadius: "7px"}}/> 
                <hr style={{margin: "0px", width: length,  backgroundColor: color, height: "8px", float: "left", borderRadius: "7px" }}/>
              
            </div>
            <span style={{marginRight: '30px',marginTop: '-5px',float: 'right', font: "12px/18px Futura Md BT", color: "#0D3A7D"}}> {numberOfReviews}</span>
        </div>)
    }
    );

    return (
        <React.Fragment>
            <div className="reviewsUserRating">
                <div className="header">                  
                        <table>
                        <tbody>
                            <tr style={{width: "240px"}}>

                                {average &&<th style={{width: "100px", font: "60px/72px Futura Lt BT", color: "#0D3A7D"}}>{average}</th>}
                                <th style={{width: "130px"}}>
                                    <table>
                                        <tbody>
                                        <tr>
                                            {reviews && reviews.avarageRating.result !== undefined? <td style={{font: "18px/22px Futura Md BT", color: "#0D3A7D", marginLeft:"10px"}}>{overalRatingLabel}</td>:null}
                                        </tr>
                                        <tr> 
                                             <td style={{font: "12px/22px Futura Md BT", color: color, marginLeft:"10px"}}>{totalReview + " reviews"}</td> 
                                        </tr> 
                                        </tbody>
                                    </table>
                                </th>
                            </tr>
                        </tbody>
                        </table>
                </div>       
                 <div className="barScale">
                     {greenLine(excellent, "Excellent",4)} 
                     {greenLine(veryGood, "Very Good",3)}
                     {greenLine(fair, "Fair",2)}
                     {greenLine(averageRating, "Average",1)}
                     {greenLine(terrible, "Terrible",0)}
                </div>
            </div>
        </React.Fragment >
    )
}
export default ReviewsRatingComponent
