import * as Actions from '../../../redux/actions'

import {
    AdventureIcon,
    ArtCultureIcon,
    BudgetTravelIcon,
    FoodNightlifeIcon,
    HealthWellnessIcon,
    LuxuryIcon,
    MountainIcon,
    OutdoorActivitiesIcon,
    RoadTripIcon,
    ShoppingTripsIcon,
    SightseeingIcon,
    SunBeachIcon,
} from "./SVGBank"
import React, { Component } from 'react'

import { connect } from 'react-redux'
import { member_area_router } from "../Common/RegExValidate"

class ImageCheckBox_ extends Component {
    render() {
        const { screenSize, id, text, checked, disable, type, switch_container, addSelectedElementInterests, removeSelectedElementInterests, icon, selected_travel_interests_merge_sets, uuid, lang } = this.props
        let screen_at_500 = 1200
        let font_size_text = 18
        let container_width = 215
        let container_height = 285
        let icon__percentage = 1
        if (!isNaN(screenSize)) {
            // menuH = screenSize > screen_at_1200_1200 ? 100 : 40
            font_size_text = screenSize > screen_at_500 ? 18 : 12
            container_width = screenSize > screen_at_500 ? 215 : 125
            container_height = screenSize > screen_at_500 ? 285 : 175
            icon__percentage = screenSize > screen_at_500 ? 1 : 0.7
        }
        return (
            <div className="d-inline-block position-relative"
                style={{
                    border: checked === 1 ? "3px solid #19BC9B" : "3px solid #E8E8E8",
                    boxShadow: "0px 1px 0px #00000029",
                    width: container_width + "px",
                    height: container_height + "px",
                    marginRight: "20px",
                    marginBottom: "15px",
                    cursor: disable === 0 ? "pointer" : "",
                }}
                onClick={() => disable === 0 ? (checked === 0 ? addSelectedElementInterests(id, type, selected_travel_interests_merge_sets, uuid, lang) : removeSelectedElementInterests(id, type, selected_travel_interests_merge_sets, uuid, lang)) : ""}
            >
                <div className="position-absolute"
                    style={{
                        top: "50%",
                        left: "0px",
                        right: "0px",
                        textAlign: "center",
                        msTransform: "translateY(-50%)",
                        transform: "translateY(-50%)",
                    }}>
                    {icon === "SunBeachIcon" && <SunBeachIcon fill={disable === 1 ? "#E8E8E8" : (switch_container === member_area_router.personalInfo && checked === 0 ? "#E8E8E8" : "#19BC9B")} percentageSize={icon__percentage} />}
                    {icon === "OutdoorActivitiesIcon" && <OutdoorActivitiesIcon fill={disable === 1 ? "#E8E8E8" : (switch_container === member_area_router.personalInfo && checked === 0 ? "#E8E8E8" : "#19BC9B")} percentageSize={icon__percentage} />}
                    {icon === "AdventureIcon" && <AdventureIcon fill={disable === 1 ? "#E8E8E8" : (switch_container === member_area_router.personalInfo && checked === 0 ? "#E8E8E8" : "#19BC9B")} percentageSize={icon__percentage} />}
                    {icon === "ArtCultureIcon" && <ArtCultureIcon fill={disable === 1 ? "#E8E8E8" : (switch_container === member_area_router.personalInfo && checked === 0 ? "#E8E8E8" : "#19BC9B")} percentageSize={icon__percentage} />}
                    {icon === "FoodNightlifeIcon" && <FoodNightlifeIcon fill={disable === 1 ? "#E8E8E8" : (switch_container === member_area_router.personalInfo && checked === 0 ? "#E8E8E8" : "#19BC9B")} percentageSize={icon__percentage} />}
                    {icon === "LuxuryIcon" && <LuxuryIcon fill={disable === 1 ? "#E8E8E8" : (switch_container === member_area_router.personalInfo && checked === 0 ? "#E8E8E8" : "#19BC9B")} percentageSize={icon__percentage} />}
                    {icon === "HealthWellnessIcon" && <HealthWellnessIcon stroke={disable === 1 ? "#E8E8E8" : (switch_container === member_area_router.personalInfo && checked === 0 ? "#E8E8E8" : "#19BC9B")} percentageSize={icon__percentage} />}
                    {icon === "SightseeingIcon" && <SightseeingIcon fill={disable === 1 ? "#E8E8E8" : (switch_container === member_area_router.personalInfo && checked === 0 ? "#E8E8E8" : "#19BC9B")} percentageSize={icon__percentage} />}
                    {icon === "BudgetTravelIcon" && <BudgetTravelIcon fill={disable === 1 ? "#E8E8E8" : (switch_container === member_area_router.personalInfo && checked === 0 ? "#E8E8E8" : "#19BC9B")} percentageSize={icon__percentage} />}
                    {icon === "MountainIcon" && <MountainIcon fill={disable === 1 ? "#E8E8E8" : (switch_container === member_area_router.personalInfo && checked === 0 ? "#E8E8E8" : "#19BC9B")} percentageSize={icon__percentage} />}
                    {icon === "ShoppingTripsIcon" && <ShoppingTripsIcon fill={disable === 1 ? "#E8E8E8" : (switch_container === member_area_router.personalInfo && checked === 0 ? "#E8E8E8" : "#19BC9B")} percentageSize={icon__percentage} />}
                    {icon === "RoadTripIcon" && <RoadTripIcon fill={disable === 1 ? "#E8E8E8" : (switch_container === member_area_router.personalInfo && checked === 0 ? "#E8E8E8" : "#19BC9B")} percentageSize={icon__percentage} />}
                </div>
                <div className="position-absolute"
                    style={{
                        top: "90%",
                        left: "0px",
                        right: "0px",
                        textAlign: "center",
                        msTransform: "translateY(-90%)",
                        transform: "translateY(-90%)",
                        font: font_size_text + "px/40px Futura Hv BT",
                        color: disable === 1 ? "#E8E8E8" : (checked === 1 ? "#19BC9B" : "#707070")
                    }}>
                    {text}
                </div>
            </div >
        )
    }
}


const mapStateToProps = (state) => ({
    switch_container: state.Member.switch_container,

    screenSize: state.Setting.htmlBody.bodyClientWidth,
    selected_travel_interests_merge_sets: state.Landing.selected_travel_interests_merge_sets,
    lang: state.Setting.lang,
    uuid: state.Member.authModal.uuid,
})

const mapDispatchToProps = {

    addSelectedElementInterests: Actions.addSelectedElementInterests,
    removeSelectedElementInterests: Actions.removeSelectedElementInterests,
}
const ImageCheckBox = connect(mapStateToProps, mapDispatchToProps)(ImageCheckBox_)
export default ImageCheckBox 
