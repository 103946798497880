import {
    COPY_TRIP_DREAM_TRIPS,
    COPY_TRIP_FUTURE_TRIPS,
    COPY_TRIP_MODIFY_PROFILE,
    COPY_TRIP_PAST_TRIPS,
    CURRENT_FAVORITES_TYPE_SELECTED,
    DELETE_DREAM_TRIP_LIST,
    DELETE_FUTURE_TRIP_LIST,
    DELETE_PAST_TRIP_LIST,
    DELETE_TRIP_LIST,
    DELETE_TRIP_MODIFY_PROFILE,
    FETCH_HOMETOWN_FAVORITES_TYPE_FAILURE,
    FETCH_HOMETOWN_FAVORITES_TYPE_SUCCESS,
    FETCH_MEMBER_DESTINATION_INTERESTS,
    FETCH_MEMBER_DESTINATION_INTERESTS_ERROR,
    FETCH_MEMBER_DREAM_TRIPS,
    FETCH_MEMBER_DREAM_TRIPS_ERROR,
    FETCH_MEMBER_DREAM_TRIPS_FAILURE,
    FETCH_MEMBER_DREAM_TRIPS_SUCCESS,
    FETCH_MEMBER_FAILURE,
    FETCH_MEMBER_FOLLOWERS,
    FETCH_MEMBER_FOLLOWERS_ERROR,
    FETCH_MEMBER_FRIENDS,
    FETCH_MEMBER_FRIENDS_ERROR,
    FETCH_MEMBER_FUTURE_TRIPS,
    FETCH_MEMBER_FUTURE_TRIPS_ERROR,
    FETCH_MEMBER_FUTURE_TRIPS_FAILURE,
    FETCH_MEMBER_FUTURE_TRIPS_SUCCESS,
    FETCH_MEMBER_INFLUENCERS,
    FETCH_MEMBER_INFLUENCERS_ERROR,
    FETCH_MEMBER_LAST_TRIP_FAILURE,
    FETCH_MEMBER_LAST_TRIP_SUCCESS,
    FETCH_MEMBER_MUVER,
    FETCH_MEMBER_MUVER_ERROR,
    FETCH_MEMBER_PAST_TRIPS,
    FETCH_MEMBER_PAST_TRIPS_ERROR,
    FETCH_MEMBER_SUCCESS,
    FETCH_MUVER_FAILURE,
    FETCH_MUVER_SUCCESS,
    FETCH_SOCIAL_MEMBER_DESTINATION_FAVORITES,
    FETCH_SOCIAL_MEMBER_DESTINATION_FAVORITES_ERROR,
    FETCH_SOCIAL_MEMBER_DESTINATION_FAVORITES_IS_FETCH,
    FETCH_SOCIAL_MEMBER_DESTINATION_FAVORITES_MERGE,
    FETCH_SOCIAL_MEMBER_HOMETOWN_FAVORITES,
    FETCH_SOCIAL_MEMBER_HOMETOWN_FAVORITES_ERROR,
    FETCH_SOCIAL_MEMBER_HOMETOWN_FAVORITES_IS_FETCH,
    FETCH_SOCIAL_MEMBER_HOMETOWN_FAVORITES_MERGE,
    FETCH_SOCIAL_MEMBER_PAST_TRIPS,
    FETCH_SOCIAL_MEMBER_PAST_TRIPS_ERROR,
    FETCH_SOCIAL_MEMBER_PAST_TRIPS_IS_FETCH,
    FETCH_SOCIAL_MEMBER_PAST_TRIPS_MERGE,
    GET_SELECTED_TRIP_INFORMATION,
    CLEAR_SELECTED_TRIP_INFORMATION,
    CLEAR_ALL_TRIP_INFORMATION,
    MODIFY_TRIP_DATE_LISTING,
    MODIFY_TRIP_NAME_LISTING,
    SET_AUTH_FIRST_NAME,
    SET_AUTH_HANDLE,
    SET_AUTH_LAST_NAME,
    SET_AUTH_UUID,
    SET_CONTAINER_ROUTER,
    SET_LIST_DISPLAY_FORMAT_MEMBER,
    SET_MODAL_COOKIES_EXIST_AUTH,
    SET_MODAL_CURRENCY_AUTH,
    SET_MODAL_LANGUAGE_AUTH,
    SET_MODAL_MEMBER_STATUS_AUTH,
    SET_MODAL_OPEN_AUTH,
    SET_MODAL_ROUTE_AUTH,
    SET_MODAL_TOKEN_AUTH,
    SET_PRIVATE_AREA,
    SET_SELECTED_TRIP_IMAGE_MEMBER_AREA,
    SET_TRIP_DISPLAY_MODE,
    SORT_TRIP_COLLECTION_BY_DATE_ASCENDING,
    SORT_TRIP_COLLECTION_BY_DATE_DESCENDING,
    SWITCH_PROFILE_CONTAINER,
    TOGGLE_ELLIPSIS_CLOSE_DISABLE,
    TOGGLE_ELLIPSIS_CLOSE_ENABLE,
    UPDATE_SELECTED_HEADER_IMAGE,
    UPDATE_TRIP_IMAGE,
    RESET_MEMBER_ACCOUNT,
} from "../interfaces/types"

import moment from "moment"

const initialState = {
    switch_container: "",
    tripMode: "",
    profile_container: "",
    isPrivateArea: false,
    authModal: {
        open: false,
        route: "Login",
        uuid: "",
        handle: "",
        firstName: "",
        lastName: "",
        token: "",
        language: "",
        memberStatus: "",
        currency: "",
        cookiesExsit: false,
    },
    socialMember: {
        member: {},
        error: '',
        muver: {},
        error_muver: "",
        member_uuid: '',
        pastTrips: [],
        pastTripsOffset: 0,
        pastTripsLimit: 3,
        pastTripsIsFetch: false,
        error_past_trips: "",
        lastTrip: [],
        error_last_trip: "",
        futureTrips: [],
        error_future_trips: "",
        dreamTrips: [],
        error_dream_trips: "",
        hometownFavoritesType: [],
        error_hometown_favorites_type: "",
        currentFavoritesTypeIdSelected: "0",
        /* 0 is return all types */
        currentFavoritesTypeSelected: "",
        hometownFavorites: [],
        hometownFavoritesOffset: 0,
        hometownFavoritesLimit: 3,
        hometownFavoritesIsFetch: false,
        error_hometown_favorites: false,
        destinationFavorites: [],
        destinationFavoritesOffset: 0,
        destinationFavoritesLimit: 3,
        destinationFavoritesIsFetch: false,
        error_destination_favorites: false,
        influencers: [],
        error_influencers: "",
        friends: [],
        error_friends: "",
        followers: [],
        error_followers: "",
        destinationInterests: [],
        error_destination_interests: "",
    },
    memberArea: {
        selectedTrip: {},
        toggle_ellipsis_close_disable: false,
        memberMuver: {},

        fetchMemebrMuverError: false,
        memberPastTrips: [],
        fetchMemberPastTripsError: false,
        memberFutureTrips: [],
        fetchMemberFutureTripsError: false,
        memberDreamTrips: [],
        fetchMemberDreamTripsError: false,


        tripImage: {
            tripId: "",
            selectedFilePath: "",
        },
    },
}

/* get member reducer */
export default (state = initialState, action) => {
    switch (action.type) {

        case SET_CONTAINER_ROUTER:
            return {
                ...state,
                switch_container: action.switch_container,
            };
        case RESET_MEMBER_ACCOUNT:
            return initialState;
            
        case SET_TRIP_DISPLAY_MODE:
            return {
                ...state,
                tripMode: action.trip_mode,
            };
        case FETCH_MUVER_FAILURE:
            return {
                ...state,
                socialMember: {
                    ...state.socialMember,
                    muver: {},
                    error_muver: action.payload
                }
            };
        case FETCH_MUVER_SUCCESS:
            return {
                ...state,
                socialMember: {
                    ...state.socialMember,
                    muver: action.payload,
                    error_muver: ''
                }
            };
        case FETCH_SOCIAL_MEMBER_PAST_TRIPS_ERROR:
            return {
                ...state,
                socialMember: {
                    ...state.socialMember,
                    error_past_trips: action.error
                }
            };
        case FETCH_SOCIAL_MEMBER_PAST_TRIPS:
            return {
                ...state,

                socialMember: {
                    ...state.socialMember,
                    pastTrips: action.past_trips,
                    pastTripsOffset: action.offset,
                    pastTripsLimit: action.limit,
                }
            };
        case FETCH_SOCIAL_MEMBER_PAST_TRIPS_MERGE:
            return {
                ...state,

                socialMember: {
                    ...state.socialMember,
                    pastTrips: state.socialMember.pastTrips.concat(action.past_trips),
                    pastTripsOffset: action.offset,
                }
            };
        case FETCH_SOCIAL_MEMBER_PAST_TRIPS_IS_FETCH:
            return {
                ...state,

                socialMember: {
                    ...state.socialMember,
                    pastTripsIsFetch: action.is_fatch,
                }
            };
        case FETCH_MEMBER_LAST_TRIP_FAILURE:
            return {
                ...state,
                socialMember: {
                    ...state.socialMember,
                    lastTrip: [],
                    error_last_trip: action.payload
                }
            };
        case FETCH_MEMBER_LAST_TRIP_SUCCESS:
            return {
                ...state,

                socialMember: {
                    ...state.socialMember,
                    lastTrip: action.payload,
                    error_last_trip: ''
                }
            };
        case FETCH_MEMBER_FUTURE_TRIPS_FAILURE:
            return {
                ...state,

                socialMember: {
                    ...state.socialMember,
                    futureTrips: [],
                    error_future_trips: action.payload
                }
            };
        case FETCH_MEMBER_FUTURE_TRIPS_SUCCESS:
            return {
                ...state,

                socialMember: {
                    ...state.socialMember,
                    futureTrips: action.payload,
                    error_future_trips: ''
                }
            };
        case FETCH_MEMBER_DREAM_TRIPS_FAILURE:
            return {
                ...state,

                socialMember: {
                    ...state.socialMember,
                    dreamTrips: [],
                    error_dream_trips: action.payload
                }
            };
        case FETCH_MEMBER_DREAM_TRIPS_SUCCESS:
            return {
                ...state,

                socialMember: {
                    ...state.socialMember,
                    dreamTrips: action.payload,
                    error_dream_trips: ''
                }
            };
        case FETCH_HOMETOWN_FAVORITES_TYPE_FAILURE:
            return {
                ...state,

                socialMember: {
                    ...state.socialMember,
                    hometownFavoritesType: [],
                    error_hometown_favorites_type: action.payload
                }
            };
        case FETCH_HOMETOWN_FAVORITES_TYPE_SUCCESS:
            return {
                ...state,

                socialMember: {
                    ...state.socialMember,
                    hometownFavoritesType: action.payload,
                    error_hometown_favorites_type: ''
                }
            };
        case CURRENT_FAVORITES_TYPE_SELECTED:
            return {
                ...state,

                socialMember: {
                    ...state.socialMember,
                    currentFavoritesTypeIdSelected: action.type_id,
                    currentFavoritesTypeSelected: action.type_name,
                }
            };
        case FETCH_SOCIAL_MEMBER_HOMETOWN_FAVORITES:
            return {
                ...state,
                socialMember: {
                    ...state.socialMember,
                    hometownFavorites: action.hometown_favorites,
                    hometownFavoritesOffset: action.offset,
                    hometownFavoritesLimit: action.limit,
                }
            };
        case FETCH_SOCIAL_MEMBER_HOMETOWN_FAVORITES_MERGE:
            return {
                ...state,
                socialMember: {
                    ...state.socialMember,
                    hometownFavorites: state.socialMember.hometownFavorites.concat(action.hometown_favorites),
                    hometownFavoritesOffset: action.offset,
                }
            };
        case FETCH_SOCIAL_MEMBER_HOMETOWN_FAVORITES_IS_FETCH:
            return {
                ...state,
                socialMember: {
                    ...state.socialMember,
                    hometownFavoritesIsFetch: action.is_fatch,
                }
            };
        case FETCH_SOCIAL_MEMBER_HOMETOWN_FAVORITES_ERROR:
            return {
                ...state,
                socialMember: {
                    ...state.socialMember,
                    error_hometown_favorites: action.error
                }
            };
        case FETCH_SOCIAL_MEMBER_DESTINATION_FAVORITES:
            return {
                ...state,
                socialMember: {
                    ...state.socialMember,
                    destinationFavorites: action.destination_favorites,
                    destinationFavoritesOffset: action.offset,
                    destinationFavoritesLimit: action.limit,
                }
            };
        case FETCH_SOCIAL_MEMBER_DESTINATION_FAVORITES_MERGE:
            return {
                ...state,
                socialMember: {
                    ...state.socialMember,
                    destinationFavorites: state.socialMember.destinationFavorites.concat(action.destination_favorites),
                    destinationFavoritesOffset: action.offset,
                }
            };
        case FETCH_SOCIAL_MEMBER_DESTINATION_FAVORITES_IS_FETCH:
            return {
                ...state,
                socialMember: {
                    ...state.socialMember,
                    destinationFavoritesIsFetch: action.is_fatch,
                }
            };
        case FETCH_SOCIAL_MEMBER_DESTINATION_FAVORITES_ERROR:
            return {
                ...state,
                socialMember: {
                    ...state.socialMember,
                    error_destination_favorites: action.error
                }
            };
        case FETCH_MEMBER_INFLUENCERS:
            return {
                ...state,

                socialMember: {
                    ...state.socialMember,
                    influencers: action.influencers,
                }
            };
        case FETCH_MEMBER_INFLUENCERS_ERROR:
            return {
                ...state,

                socialMember: {
                    ...state.socialMember,
                    error_influencers: action.error,
                }
            };
        case FETCH_MEMBER_FRIENDS:
            return {
                ...state,

                socialMember: {
                    ...state.socialMember,
                    friends: action.friends,
                }
            };
        case FETCH_MEMBER_FRIENDS_ERROR:
            return {
                ...state,

                socialMember: {
                    ...state.socialMember,
                    error_friends: action.error,
                }
            };
        case FETCH_MEMBER_FOLLOWERS:
            return {
                ...state,

                socialMember: {
                    ...state.socialMember,
                    followers: action.followers,
                }
            };
        case FETCH_MEMBER_FOLLOWERS_ERROR:
            return {
                ...state,

                socialMember: {
                    ...state.socialMember,
                    error_followers: action.error,
                }
            };
        case FETCH_MEMBER_DESTINATION_INTERESTS:
            return {
                ...state,
                socialMember: {
                    ...state.socialMember,
                    destinationInterests: action.destination_interests,
                }
            };
        case FETCH_MEMBER_DESTINATION_INTERESTS_ERROR:
            return {
                ...state,
                socialMember: {
                    ...state.socialMember,
                    error_destination_interests: action.error,
                }
            };
        case FETCH_MEMBER_SUCCESS:
            return {
                ...state,

                socialMember: {
                    ...state.socialMember,
                    member: action.payload,
                    member_uuid: action.member_uuid,
                    error: ''
                }
            };
        case FETCH_MEMBER_FAILURE:
            return {
                ...state,

                socialMember: {
                    ...state.socialMember,
                    member: {},
                    member_uuid: '',
                    error: action.payload
                }
            };

        case DELETE_TRIP_LIST:
            return {
                ...state,
                memberArea: {
                    ...state.memberArea,
                    memberPastTrips: state.memberArea.memberPastTrips.filter(val => val.tripID !== action.tripID),
                    memberFutureTrips: state.memberArea.memberFutureTrips.filter(val => val.tripID !== action.tripID),
                    memberDreamTrips: state.memberArea.memberDreamTrips.filter(val => val.tripID !== action.tripID)
                }
            };
        case DELETE_DREAM_TRIP_LIST:
            return {
                ...state,
                memberArea: {
                    ...state.memberArea,
                    memberDreamTrips: state.memberArea.memberDreamTrips.filter(val => val.tripID !== action.tripID)
                }
            };
        case DELETE_PAST_TRIP_LIST:
            return {
                ...state,
                memberArea: {
                    ...state.memberArea,
                    memberPastTrips: state.memberArea.memberPastTrips.filter(val => val.tripID !== action.tripID),
                }
            };
        case DELETE_FUTURE_TRIP_LIST:
            return {
                ...state,
                memberArea: {
                    ...state.memberArea,
                    memberFutureTrips: state.memberArea.memberFutureTrips.filter(val => val.tripID !== action.tripID),
                }
            }

            ;
        case DELETE_TRIP_MODIFY_PROFILE:
            return {
                ...state,
                memberArea: {
                    ...state.memberArea,
                    memberMuver: {
                        ...state.memberArea.memberMuver,
                        muverTripsCount: state.memberArea.memberMuver.muverTripsCount - 1
                    }
                }
            };
        case COPY_TRIP_MODIFY_PROFILE:
            return {
                ...state,
                memberArea: {
                    ...state.memberArea,
                    memberMuver: {
                        ...state.memberArea.memberMuver,
                        muverTripsCount: state.memberArea.memberMuver.muverTripsCount - (-1)
                    }
                }
            };
        case MODIFY_TRIP_NAME_LISTING:
            return {
                ...state,

                memberArea: {
                    ...state.memberArea,
                    memberPastTrips: state.memberArea.memberPastTrips.map((trip) => {
                        if (trip.tripID !== action.trip_id) {
                            return trip
                        }
                        return {
                            ...trip,
                            tripName: action.trip_name
                        }
                    }),
                    memberFutureTrips: state.memberArea.memberFutureTrips.map((trip) => {
                        if (trip.tripID !== action.trip_id) {
                            return trip
                        }
                        return {
                            ...trip,
                            tripName: action.trip_name
                        }
                    }),
                    memberDreamTrips: state.memberArea.memberDreamTrips.map((trip) => {
                        if (trip.tripID !== action.trip_id) {
                            return trip
                        }
                        return {
                            ...trip,
                            tripName: action.trip_name
                        }
                    }),
                }
            };
        case MODIFY_TRIP_DATE_LISTING:
            return {
                ...state,
                memberArea: {
                    ...state.memberArea,
                    memberPastTrips: state.memberArea.memberPastTrips.map((trip) => {
                        if (trip.tripID !== action.trip_id) {
                            return trip
                        }
                        return {
                            ...trip,
                            tripStartDate: action.trip_date,
                            noDate: action.no_date,
                        }
                    }),
                    memberFutureTrips: state.memberArea.memberFutureTrips.map((trip) => {
                        if (trip.tripID !== action.trip_id) {
                            return trip
                        }
                        return {
                            ...trip,
                            tripStartDate: action.trip_date,
                            noDate: action.no_date,
                        }
                    }),
                    memberDreamTrips: state.memberArea.memberDreamTrips.map((trip) => {
                        if (trip.tripID !== action.trip_id) {
                            return trip
                        }
                        return {
                            ...trip,
                            tripStartDate: action.trip_date,
                            noDate: action.no_date,
                        }
                    }),
                }
            }

            ;
        case UPDATE_TRIP_IMAGE:
            return {
                ...state,
                memberArea: {
                    ...state.memberArea,
                    memberPastTrips: state.memberArea.memberPastTrips.map((trip) => {
                        if (trip.tripID !== action.trip_id) {
                            return trip
                        }
                        return {
                            ...trip,
                            tripHeaderImage: action.trip_image,
                        }
                    }),
                    memberFutureTrips: state.memberArea.memberFutureTrips.map((trip) => {
                        if (trip.tripID !== action.trip_id) {
                            return trip
                        }
                        return {
                            ...trip,
                            tripHeaderImage: action.trip_image,
                        }
                    }),
                    memberDreamTrips: state.memberArea.memberDreamTrips.map((trip) => {
                        if (trip.tripID !== action.trip_id) {
                            return trip
                        }
                        return {
                            ...trip,
                            tripHeaderImage: action.trip_image,
                        }
                    }),
                }
            }

            ;
        case COPY_TRIP_DREAM_TRIPS:
            return {
                ...state,

                memberArea: {
                    ...state.memberArea,
                    memberDreamTrips: [
                        ...state.memberArea.memberDreamTrips,
                        action.trip_copied
                    ]
                }
            };
        case COPY_TRIP_PAST_TRIPS:
            return {
                ...state,

                memberArea: {
                    ...state.memberArea,
                    memberPastTrips: [
                        ...state.memberArea.memberPastTrips,
                        action.trip_copied
                    ]
                }
            };
        case COPY_TRIP_FUTURE_TRIPS:
            return {
                ...state,

                memberArea: {
                    ...state.memberArea,
                    memberFutureTrips: [
                        ...state.memberArea.memberFutureTrips,
                        action.trip_copied
                    ]
                }
            };
        case SORT_TRIP_COLLECTION_BY_DATE_DESCENDING:
            return {
                ...state,

                memberArea: {
                    ...state.memberArea,
                    memberPastTrips: state.memberArea.memberPastTrips.slice().sort((a, b) => {
                        if (moment(a.tripStartDate).diff(b.tripStartDate, 'days') > 0)
                            return -1
                        if (moment(a.tripStartDate).diff(b.tripStartDate, 'days') < 0)
                            return 1
                        return 0
                    }),
                    memberFutureTrips: state.memberArea.memberFutureTrips.slice().sort((a, b) => {
                        if (moment(a.tripStartDate).diff(b.tripStartDate, 'days') > 0)
                            return -1
                        if (moment(a.tripStartDate).diff(b.tripStartDate, 'days') < 0)
                            return 1
                        return 0
                    })
                }
            };
        case SORT_TRIP_COLLECTION_BY_DATE_ASCENDING:
            return {
                ...state,

                memberArea: {
                    ...state.memberArea,
                    memberPastTrips: state.memberArea.memberPastTrips.slice().sort((a, b) => {
                        if (moment(a.tripStartDate).diff(b.tripStartDate, 'days') < 0)
                            return -1
                        if (moment(a.tripStartDate).diff(b.tripStartDate, 'days') > 0)
                            return 1
                        return 0
                    }),
                    memberFutureTrips: state.memberArea.memberFutureTrips.slice().sort((a, b) => {
                        if (moment(a.tripStartDate).diff(b.tripStartDate, 'days') < 0)
                            return -1
                        if (moment(a.tripStartDate).diff(b.tripStartDate, 'days') > 0)
                            return 1
                        return 0
                    })
                }
            };
        case FETCH_MEMBER_MUVER:
            return {
                ...state,
                memberArea: {
                    ...state.memberArea,
                    memberMuver: action.member_muver,
                },
            };
        case GET_SELECTED_TRIP_INFORMATION:
            return {
                ...state, 
                memberArea: {
                    ...state.memberArea,
                    selectedTrip: action.trip
                }
            };
        case CLEAR_SELECTED_TRIP_INFORMATION:
                return {
                    ...state, 
                    memberArea: {
                        ...state.memberArea,
                        selectedTrip: {}
                    }
            };
        case CLEAR_ALL_TRIP_INFORMATION:
           return {
                    ...state, 
                    socialMember: {
                        ...state.socialMember,
                        pastTrips: [],
                        lastTrip: [],
                        futureTrips: [],
                        dreamTrips: [],
                    },
                    memberArea: {
                        ...state.memberArea,
                        memberPastTrips: [],
                        memberFutureTrips: [],
                        memberDreamTrips: [],
                    },
           };
        case UPDATE_SELECTED_HEADER_IMAGE:
                return {
                    ...state, memberArea: {
                        ...state.memberArea,
                        selectedTrip: {
                            ...state.memberArea.selectedTrip,
                            headerImage: action.header_image,
                        }
                    }
                }

                ;
        case TOGGLE_ELLIPSIS_CLOSE_DISABLE:
                return {
                    ...state, memberArea: {
                        ...state.memberArea,
                        toggle_ellipsis_close_disable: true
                    }
                };
        case TOGGLE_ELLIPSIS_CLOSE_ENABLE:
                return {
                    ...state, memberArea: {
                        ...state.memberArea,
                        toggle_ellipsis_close_disable: false
                    }
                };

        case FETCH_MEMBER_MUVER_ERROR:
                return {
                    ...state,
                    memberArea: {
                        ...state.memberArea,
                        fetchMemebrMuverError: action.error,
                    },
                };
        case FETCH_MEMBER_PAST_TRIPS:
                return {
                    ...state,
                    memberArea: {
                        ...state.memberArea,
                        memberPastTrips: action.member_past_trips,
                    },
                };
            case FETCH_MEMBER_PAST_TRIPS_ERROR:
                return {
                    ...state,
                    memberArea: {
                        ...state.memberArea,
                        fetchMemberPastTripsError: action.error,
                    },
                };
            case FETCH_MEMBER_FUTURE_TRIPS:
                return {
                    ...state,
                    memberArea: {
                        ...state.memberArea,
                        memberFutureTrips: action.member_future_trips,
                    },
                };
            case FETCH_MEMBER_FUTURE_TRIPS_ERROR:
                return {
                    ...state,
                    memberArea: {
                        ...state.memberArea,
                        fetchMemberFutureTripsError: action.error,
                    },
                };
            case FETCH_MEMBER_DREAM_TRIPS:
                return {
                    ...state,
                    memberArea: {
                        ...state.memberArea,
                        memberDreamTrips: action.member_dream_trips,
                    },
                };
            case FETCH_MEMBER_DREAM_TRIPS_ERROR:
                return {
                    ...state,
                    memberArea: {
                        ...state.memberArea,
                        fetchMemberDreamTripsError: action.error,
                    },
                };


            case SET_PRIVATE_AREA:
                return {
                    ...state,
                    isPrivateArea: action.is_private,
                };
            case SET_LIST_DISPLAY_FORMAT_MEMBER:
                return {
                    ...state,
                    memberArea: {
                        ...state.memberArea,
                        destinaionListDisplayFormat: action.container_format,
                    },
                };

            case SET_SELECTED_TRIP_IMAGE_MEMBER_AREA:
                return {
                    ...state,
                    memberArea: {
                        ...state.memberArea,
                        tripImage: {
                            ...state.memberArea.tripImage,
                            tripId: action.trip_id,
                            selectedFilePath: action.selected_file_path,
                        }
                    }
                };
            case SWITCH_PROFILE_CONTAINER:
                return {
                    ...state,
                    profile_container: action.switch_container
                }

                ;

                /* auth modal */
            case SET_MODAL_OPEN_AUTH:
                return {
                    ...state,
                    authModal: {
                        ...state.authModal,
                        open: action.open,
                    }
                }

                ;
            case SET_MODAL_ROUTE_AUTH:
                return {
                    ...state,
                    authModal: {
                        ...state.authModal,
                        route: action.route,
                    }
                };
            case SET_AUTH_UUID:
                return {
                    ...state,
                    authModal: {
                        ...state.authModal,
                        uuid: action.uuid,
                    }
                };
            case SET_AUTH_HANDLE:
                return {
                    ...state,
                    authModal: {
                        ...state.authModal,
                        handle: action.handle,
                    }
                };
            case SET_AUTH_FIRST_NAME:
                return {
                    ...state,
                    authModal: {
                        ...state.authModal,
                        firstName: action.first_name,
                    }
                };
            case SET_AUTH_LAST_NAME:
                return {
                    ...state,
                    authModal: {
                        ...state.authModal,
                        lastName: action.last_name,
                    }
                };
            case SET_MODAL_CURRENCY_AUTH:
                return {
                    ...state,
                    authModal: {
                        ...state.authModal,
                        currency: action.currency,
                    }
                };
            case SET_MODAL_LANGUAGE_AUTH:
                return {
                    ...state,
                    authModal: {
                        ...state.authModal,
                        language: action.language,
                    }
                };
            case SET_MODAL_MEMBER_STATUS_AUTH:
                return {
                    ...state,
                    authModal: {
                        ...state.authModal,
                        memberStatus: action.member_status,
                    }
                };
            case SET_MODAL_TOKEN_AUTH:
                return {
                    ...state,
                    authModal: {
                        ...state.authModal,
                        token: action.token,
                    }
                };
            case SET_MODAL_COOKIES_EXIST_AUTH:
                return {
                    ...state,
                    authModal: {
                        ...state.authModal,
                        cookiesExsit: action.cookiesExsit,
                    }
                };
            default:
                return {
                    ...state
                }
    }
}