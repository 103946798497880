import * as Actions from '../../../redux/actions'

import MuiAlert from "@material-ui/lab/Alert";
import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import { connect } from 'react-redux'

/* alert call back function  
    param props
    <Alert severity="error">This is an error message!</Alert>
    <Alert severity="warning">This is a warning message!</Alert>
    <Alert severity="info">This is an information message!</Alert>
    <Alert severity="success">This is a success message!</Alert> 
*/
function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

/* snack bars function component */
const Snackbars = connect((state) => ({
    messageType: state.AlertBars.messageType,
    message: state.AlertBars.message,
}), {
    alertBar: Actions.alertBar,
},
)(({
    label = "",
    required = "",
    id = "",
    messageType,
    message,
    alertBar,
}) => {
    const [open, setOpen] = React.useState(false);
    const handleClose = () => {
        setOpen(false);
        alertBar("", "")
    }
    React.useEffect(() => {
        message !== "" && setOpen(true)
    }, [message])
    return (
        <div>
            {
                open &&
                <Snackbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    open={open}
                    autoHideDuration={3000}
                    onClose={handleClose}>
                    <Alert onClose={handleClose} severity={messageType}>
                        {message}
                    </Alert>
                </Snackbar>
            }
        </div>
    )
})

export default Snackbars