import "./Discover.css"

import * as Actions from '../../../redux/actions';

import React, { Component } from 'react'

import LinkDestination from "../DestinationPage/LinkDestination";
import { connect } from 'react-redux'

/* discover component */
class Discover_ extends Component {

    componentDidMount() {
        this.props.fetchDiscoverySets(this.props.lang)
    }

    render() {
        const { discoverSets, extender, setterExtenderDiscover, isMobile, lang } = this.props;

        const destinations = discoverSets.destinations;
        const numShort = discoverSets?.numberOfShortDest;
        const numAll = discoverSets?.numberOfAllDest;
        // const numberOfShortDest = discoverSets.numberOfShortDest
        // const numberOfShortDestCol = Math.ceil(numberOfShortDest / 7)
        // const numberOfAllDest = discoverSets.numberOfAllDest
        // const numberOfAllDestCol = Math.ceil(numberOfAllDest / 7)
        let maxWidthContinent = "0 0 14.2857%"
        let font_title = 30
        if (!isNaN(isMobile)) {
            maxWidthContinent = isMobile ? "0 0 48%" : "0 0 14.2857%"
            font_title = isMobile ? "20" : "30"
        }

        return (
            <div
                style={{
                    background: "#efefef",
                    width: "100%",
                    paddingRight: "15px",
                    paddingLeft: "15px",
                    marginRight: "0",
                    marginLeft: "0",
                }}>
                <div>
                    <div className="d-flex w-100 p-3">
                        <div style={{
                            textAdivgn: "left",
                            font: font_title + "px/" + font_title + "px Futura Hv BT",
                            letterSpacing: "0",
                            color: "#202020",
                            flexGrow: "1",
                        }}>Discover</div>
                        <div style={{
                            textAdivgn: "left",
                            font: "14px/22px Futura Hv BT",
                            letterSpacing: "0",
                            color: "var(--mainGreen)",
                            cursor: "pointer",
                        }} onClick={() => setterExtenderDiscover()} >Show {extender ? 'less' : 'more'}</div>
                    </div>
                    <div className="p-3 discoverDestinations">
                        <div style={{
                            maxHeight: extender ? numAll?numAll *5 : 2000: numShort? numShort * 4.6 : 800,
                            display: "flex",
                            flexDirection: 'column',
                            flexWrap: 'wrap'
                        }}>
                            {destinations !== undefined && destinations.length > 0 &&
                                destinations.map((continent, continent_idx) => {
                                    return (
                                        <React.Fragment key={continent_idx}>
                                            {(extender || continent.shortListShow) && <div style={{

                                                marginTop: "5px",
                                                marginBottom: "5px",
                                                flex: maxWidthContinent,
                                                paddingLeft: "2px",
                                               
                                            }}>
                                                {continent && continent.level === '1' ? <LinkDestination link_style={{
                                                    color: "#202020",
                                                    textAlign: "left",
                                                    font: "18px/20px Futura Hv BT",
                                                    letterSpacing: "0",
                                                    cursor: "pointer",
                                                    marginRight:10
                                                }}
                                                    lang={lang}
                                                    destination_id={continent.id}
                                                    title={continent.title} />

                                            :<div
                                                    style={{
                                                        marginTop: "2px",
                                                        marginBottom: "2px",
                                                        flex: maxWidthContinent,
                                                        paddingLeft: (extender ? continent?.level === '2' ? "10px": continent?.level === '3' ? "20px":
                                                        continent?.level === '4' ? "30px": "10px": "10px"),
                                                    }} >
                                                    <LinkDestination link_style={{
                                                        color: "#202020",
                                                        textAlign: "left",
                                                        font: "16px/20px Futura Lt BT",
                                                        letterSpacing: "0",
                                                        cursor: "pointer",
                                                        marginRight:10
                                                    }}
                                                        lang={lang}
                                                        destination_id={continent.id}
                                                        title={continent.title} />

                                                </div>}
                                            </div>}


                                        </React.Fragment>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        discoverSets: state.Home.discover.discoverSets,
        extender: state.Home.discover.extender,
        lang: state.Setting.lang,
        isMobile: state.Setting.is_mobile,
    }

}

const mapDispatchToProps = {
    fetchDiscoverySets: Actions.fetchDiscoverySets,
    setterExtenderDiscover: Actions.setterExtenderDiscover,
}

export const Discover = connect(mapStateToProps, mapDispatchToProps)(Discover_)
