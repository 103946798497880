import * as Actions from '../../../../redux/actions'

import {
    AboutMeTextField,
    HandleTextField,
} from "../FormComponentsBank"
import { CameraRetroIcon, LeftArrowIcon, RightArrowIcon } from '../SVGBank'
import {
    multiLineTextFieldUseStyles,
    registerHandleUseStyles,
} from "../ComponentsStylingBank"

import { DestinationVisited } from '../DestinationVisitedSearch/DestinationVisited'
import React from 'react'
import { connect } from 'react-redux'
import { landing_router } from "../RegExValidate"
import { member_area_router } from "../../Common/RegExValidate"

const BioForm_ = ({
    screenSize,
    setSwitchProfileContainer,
    photoAvatar,
    setProfilePhotoAvatar,
    authModal,
    token = authModal.token,
    switch_container,
}) => {

    const classesMultiLineTextField = multiLineTextFieldUseStyles();

    let avatarFileInput = ""
    let camera_top = "50px"
    let camera_size = 30
    let avatar_height = 140
    let screen_at_1200 = 1200
    let arrow_icon_size = 19
    let arrow_font = "18px/16px Futura Md BT"
    let arrow_container_width = "230px"
    let arrow_container_height = "60px"
    let arrow_container_padding_left = "10"
    let padding_l_r = "15"
    let padding_l_20 = "20"
    let avatar_container_width = 140

    let padding_left_handle = "35"
    let previousStepMarginRight = "10px"
    let title_font = "24px/40px Futura Hv BT"
    let notification_font = "14px/21px Futura Lt BT"
    let notification_margin_top = "140px"
    if (!isNaN(screenSize)) {
        padding_left_handle = screenSize < screen_at_1200 ? "10" : "35"
        camera_top = screenSize < screen_at_1200 ? "30px" : "50px"
        camera_size = screenSize < screen_at_1200 ? 20 : 30
        avatar_height = screenSize < screen_at_1200 ? 90 : 140
        avatar_container_width = screenSize < screen_at_1200 ? 90 : 140
        padding_l_r = screenSize > screen_at_1200 ? (switch_container !== member_area_router.personalInfo ? "15" : "0") : "10"
        padding_l_20 = screenSize > screen_at_1200 ? (switch_container !== member_area_router.personalInfo ? "20" : "0") : "0"
        arrow_icon_size = screenSize > screen_at_1200 ? 19 : 10
        arrow_font = screenSize > screen_at_1200 ? "18px/16px Futura Md BT" : "9px/8px Futura Md BT"
        arrow_container_width = screenSize > screen_at_1200 ? "230px" : "120px"
        arrow_container_height = screenSize > screen_at_1200 ? "60px" : "30px"
        arrow_container_padding_left = screenSize > screen_at_1200 ? "35" : "10"
        title_font = screenSize > screen_at_1200 ? "24px/40px Futura Hv BT" : "18px/22px Futura Hv BT"
        notification_font = screenSize > screen_at_1200 ? "14px/21px Futura Lt BT" : "10px/15px Futura Lt BT"
        notification_margin_top = screenSize > screen_at_1200 ? "140px" : "80px"
        previousStepMarginRight = screenSize > screen_at_1200 ? "10px" : "20px"

    }
    const srcAvatar = photoAvatar.isSelected ? photoAvatar.selectedFilePath : "https://amhqajbken.cloudimg.io/v7/_muv_/muvimages/user_avatar/armando.jpg?func=crop&w=" + avatar_height + "&h=" + avatar_height


    const classHandle = registerHandleUseStyles();
    return (
        <div className="ml-0 mr-0 margin_top_20">
            <div className={screenSize > screen_at_1200 ? "d-flex justify-content-center ml-0 mr-0" : "justify-content-center ml-0 mr-0 text-center"} >
                {switch_container !== member_area_router.personalInfo &&
                    <div className="position-relative margin_top_20 text-center d-inline-block" style={{ width: avatar_container_width, zIndex: "1" }}>
                        <div
                            className="img_fit_cover rounded-circle d-inline-block"
                            style={{
                                width: avatar_height,
                                height: avatar_height,
                                backgroundImage: "url('" + srcAvatar + "')", backgroundRepeat: "no-repeat",
                                backgroundSize: "100% 100%",
                            }}>
                        </div>
                        <div className="rounded-circle"
                            style={{
                                width: avatar_height,
                                height: avatar_height,
                                top: "0",
                                left: "0",
                                right: "0",
                                position: "absolute",
                                backgroundColor: "rgba(25, 25, 25, 0.2)",
                                zIndex: "2",
                            }}>
                        </div>
                        <div className="position-absolute text-center" style={{ zIndex: "3", left: "0px", right: "0px", top: camera_top }}>
                            <input
                                style={{ display: 'none' }}
                                type="file"
                                accept="image/*"
                                onChange={event => {
                                    event.target.files !== undefined
                                        && event.target.files.length > 0
                                        && event.target.files[0] !== undefined
                                        && setProfilePhotoAvatar((window.URL || window.webkitURL).createObjectURL(event.target.files[0]), photoAvatar.isSelected, photoAvatar.selectedFilePath, event.target.files[0], token)
                                }}
                                ref={fileInput => avatarFileInput = fileInput}
                            />
                            {/* <button onClick={()=>uploadProfilePhotoAvatar(photoAvatar.selectedFile)} /> */}
                            <div className="d-inline-block" onClick={() => avatarFileInput.click()}>
                                <CameraRetroIcon width={camera_size} height={camera_size} fill={"#E8E8E8"} />
                            </div>
                        </div>
                        <div className={classHandle.root} style={{
                              
                              position: "relative", width: "100%", textAlign: "center",

                          }}>
                           

                                  <HandleTextField
                                      label=""
                                      placeholder="@handle"
                                      required={false}
                                      id="handle"
                                      token={token}
                                      useFlex
                                      verifyOnly
                                  />

                          </div>
                    </div>}
                <div className={"flex_grow_1 margin_top_20 padding_left_" + padding_l_20}>
                    <div
                        style={{
                            paddingLeft: padding_l_r + "px",
                            paddingRight: padding_l_r + "px",
                        }}>
                        <div style={{ font: title_font, marginBottom: "20px", color: switch_container === member_area_router.personalInfo ? "#EFEFEF" : "#707070" }}>How would you describe yourself.</div>
                        <div className={classesMultiLineTextField.root}>
                            <AboutMeTextField label="About me"
                                required={false}
                                id="about_me" />
                        </div>
                        <div style={{ font: title_font, margin: "40px 0 20px 0", color: switch_container === member_area_router.personalInfo ? "#EFEFEF" : "#707070" }}>Destinations you visited (so far).</div>
                        <DestinationVisited />
                    </div>
                </div>
            </div>



            <div className="position-relative margin_top_20">
                <div onClick={() => setSwitchProfileContainer(landing_router.interests)}
                    style={{
                        cursor: "pointer",
                        backgroundColor: "#E8E8E8",
                        paddingLeft: arrow_container_padding_left + "px",
                        height: arrow_container_height,
                        width: arrow_container_width,
                        marginTop: "10px",
                        display: "block",
                        position: "absolute",
                        border: "0",
                        left: "0px",
                        // padding-left: 5px;
                    }}>
                    <div style={{
                        textAlign: "left",
                        font: arrow_font,
                        letterSpacing: "0",
                        margin: "0",
                        position: "absolute",
                        top: "50%",
                        msTransform: "translateY(-50%)",
                        transform: "translateY(-50%)",
                        color: "#707070",
                    }}>
                        <LeftArrowIcon width={arrow_icon_size} height={arrow_icon_size} fill={"#707070"} style={{ marginRight: previousStepMarginRight }} />   Previous Step
                    </div>
                </div>
                <div onClick={() => setSwitchProfileContainer(landing_router.profile)}
                    style={{
                        cursor: "pointer",
                        backgroundColor: "var(--mainGreen)",
                        paddingLeft: arrow_container_padding_left + "px",
                        height: arrow_container_height,
                        width: arrow_container_width,
                        marginTop: "10px",
                        display: "block",
                        position: "absolute",
                        border: "0",
                        right: "0px",
                        // padding-left: 5px;
                    }}>
                    <div style={{
                        textAlign: "left",
                        font: arrow_font,
                        letterSpacing: "0",
                        margin: "0",
                        position: "absolute",
                        top: "50%",
                        msTransform: "translateY(-50%)",
                        transform: "translateY(-50%)",
                        color: "var(--mainWhite)",
                    }}>
                        {"Next Step"} <RightArrowIcon width={arrow_icon_size} height={arrow_icon_size} style={{ marginLeft: "40px" }} />
                    </div>
                </div>
            </div>
            <div style={{
                height: "50px",
                marginTop: notification_margin_top,
                marginButtom: "10px",
                background: "rgba(25, 188, 155,.14)",
                position: "relative",
            }}>
                <div style={{
                    color: "#19BC9B",
                    font: notification_font,
                    margin: "0",
                    position: "absolute",
                    left: "1%",
                    top: "50%",
                    msTransform: "translateY(-50%)",
                    transform: "translateY(-50%)",
                    opacity: "1.0",
                }}>
                    SAFE &amp; SECURE: Your information will only be used to match you with like-minded people and personalized experiences
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    screenSize: state.Setting.htmlBody.bodyClientWidth,
    authModal: state.Member.authModal,

    switch_container: state.Member.switch_container,
    photoAvatar: state.FormBank.photoAvatar,
})

const mapDispatchToProps = {
    setSwitchProfileContainer: Actions.setSwitchProfileContainer,
    setProfilePhotoAvatar: Actions.setProfilePhotoAvatar,
    uploadProfilePhotoAvatar: Actions.uploadProfilePhotoAvatar,
}

const BioForm = connect(mapStateToProps, mapDispatchToProps)(BioForm_)
export default BioForm

