import * as Actions from '../../../../redux/actions'

import React from 'react'
import { connect } from 'react-redux'
import { member_area_router } from "../../Common/RegExValidate"
import { useHistory } from "react-router-dom"
import { environment } from '../../../../Environments/environment'

const HandleName_ = ({ setSwitchContainer, muverHandle, classNameHandle, styleHandle }) => {
    const history = useHistory()
    const goesMemberAreaPage = () => {
        window.location = environment.prodUrl+"/"+muverHandle;   
        // history.push("/" + muverHandle)
        // setSwitchContainer(member_area_router.social)
    }
    return (
        <div onClick={() => goesMemberAreaPage()} className={classNameHandle} style={styleHandle} > @{muverHandle}</div>
    )
}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = {
    setSwitchContainer: Actions.setSwitchContainer,

}

const HandleName = connect(mapStateToProps, mapDispatchToProps)(HandleName_)
export default HandleName 
