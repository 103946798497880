import * as Actions from '../../../redux/actions'

import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Divider from '@material-ui/core/Divider';
import Grow from '@material-ui/core/Grow';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import React from 'react'
import { connect } from "react-redux";
import { menuListTopBarStyles } from "./ComponentsStylingBank"

export const Language = connect((state) => ({
    language: state.Setting.language,
    token: state.Member.authModal.token,
    tokenLanguage: state.Member.authModal.language,
    textColor: state.Setting.appMenu.textColor,
    bodyClientWidth: state.Setting.htmlBody.bodyClientWidth,
    is_mobile: state.Setting.is_mobile,
    logo_width: state.Setting.appMenu.logo.width,
}), {
    setCurrentLanguage: Actions.setCurrentLanguage,
    fetchLanguageSets: Actions.fetchLanguageSets,
})(({
    token,
    textColor,
    language,
    is_mobile,
    tokenLanguage,
    logo_width,
    bodyClientWidth,
    setCurrentLanguage,
    fetchLanguageSets,
    fetch_language = language !== undefined && !language.isFetched && fetchLanguageSets(),

}) => {
    const classes = menuListTopBarStyles();
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);

    const handleToggle = () => {
        setOpen(prevOpen => !prevOpen);
    };

    const handleClose = event => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };
    const handlerOnclick = (language) => {
        setCurrentLanguage(language, token)
        setOpen(false)
    }


    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    return (
        <div className={classes.root}>
            <div>
                <div
                    ref={anchorRef}
                    aria-controls={open ? 'menu-list-sort-trips' : undefined}
                    aria-haspopup="true"
                    onClick={handleToggle}>
                    {!is_mobile && <div
                        style={{
                            width: "0px",
                            textAlign: "left",
                            font: "16px/36px Futura Md BT",
                            letterSpacing: "0",
                            color: textColor,
                            cursor: "pointer",
                            paddingLeft: "16px",
                        }}
                    >
                        {(tokenLanguage !== undefined && tokenLanguage !== "" && tokenLanguage !== null) ? tokenLanguage.englishName : language.selected.englishName}
                    </div>}
                    {is_mobile && <div style={{
                        width: logo_width + "px",
                        textAlign: "center",
                        outline: "0",

                        color: textColor,
                        cursor: "pointer",
                        paddingLeft: "0px",
                        font: "14px/22px Futura Hv BT",
                    }}>{(tokenLanguage !== undefined && tokenLanguage !== "" && tokenLanguage !== null) ? tokenLanguage.englishName : language.selected.englishName}</div>}
                    {is_mobile && <div style={{
                        font: "8px/28px Futura Lt BT",
                        color: textColor,
                        outline: "0"
                    }}>Language</div>}
                </div>
                <ClickAwayListener onClickAway={handleClose}>
                    <Popper open={open} anchorEl={anchorRef.current} role={undefined} className={classes.zIndex}
                        style={{ MarginRight: "10px" }}
                        placement={bodyClientWidth > 500 ? "bottom-start" : "right-start"}
                        modifiers={{
                            flip: {
                                enabled: false,
                            },
                        }} transition disablePortal>
                        {({ TransitionProps, placement }) => (
                            <Grow
                                {...TransitionProps}
                                style={{ transformOrigin: placement === 'bottom' ? 'left top' : 'left bottom' }}
                            >
                                <Paper>
                                    <MenuList autoFocusItem={open} id="menu-list-sort-trips" >

                                        {language !== undefined && language.languageSets !== undefined && language.languageSets.length > 0 &&
                                            language.languageSets.map((currency, currency_idx) => {
                                                return (
                                                    <div key={currency_idx} >
                                                        {currency_idx !== 0 && <Divider light />}
                                                        <MenuItem className={"btn btn-sm flex-wrap member_menu_top_btn member_menu"} onClick={() => handlerOnclick(currency)}>{currency.nativeName}</MenuItem>
                                                    </div>
                                                )
                                            })}
                                    </MenuList>
                                </Paper>
                            </Grow>
                        )}
                    </Popper>

                </ClickAwayListener>
            </div>
        </div>
    );
})
