import * as Actions from '../../../redux/actions'

import { CheckLightIcon, CircleDownIcon, CircleUpIcon } from "../Common/SVGBank"

import MenuItem from '@material-ui/core/MenuItem'
import React from 'react'
import Typography from '@material-ui/core/Typography'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { hexToRGB } from '../../../utility/utility'

const BarsSubTypeMenuItem = connect((state) => {
    return {
        selectedSubtypes: state.Feature.feature.loading.selectedSubtypes,
        subtypes: state.Feature.feature.subtypes,
        types: state.Feature.feature.types,
        selectedTypes: state.Feature.feature.loading.selectedTypes,
        switchFeatureContainer: state.FormBank.FeatureFormat,
        feature: state.Feature.feature,
        activeFeatureId: state.Feature.feature.activeId,
        lang: state.Setting.lang,

    }
}, {
    addSelectedSubTypeFeature: Actions.addSelectedSubTypeFeature,
    deleteSelectedSubTypeFeature: Actions.deleteSelectedSubTypeFeature,
    handleGoupeSubTypeFeature: Actions.handleGoupeSubTypeFeature,
    fetchTypesAndSubtypesFeature: Actions.fetchTypesAndSubtypesFeature,
    setSwitchFeatureTypeContainerOfFeature: Actions.setSwitchFeatureTypeContainerOfFeature,
})(({
    selectedSubtypes,
    addSelectedSubTypeFeature,
    deleteSelectedSubTypeFeature,
    handleGoupeSubTypeFeature,fetchTypesAndSubtypesFeature,
    subtypeGroup,
    feature, selectedTypes, subtypes,
    switchFeatureContainer,
    activeFeatureId, lang,
    types,
    setSwitchFeatureTypeContainerOfFeature,
}) => {
    const switchFeatureContainerColorLight = switchFeatureContainer.color + "35"

    const barsStyles = makeStyles(theme => ({
        root: {
            display: 'flex',
            '& .MuiListItem-root': {
                boxShadow: "0 0 0 0 rgba(0,123,255,.25)",
                font: "16px/16px Futura Md BT",
                color: "#707070",
            },
        },
        buttonText: {
            color: switchFeatureContainer.color,
            textAlign: "left",
            font: "16px/22px Futura Md BT",
            letterSpacing: "0",
            padding: "5px",
            '&:focus': {
                border: "0",
                outline: "0px",

            }
        },
        timesDiv: {
            marginRight: "10px",
            marginLeft: "115px",
            display: "inline-block",
            textAlign: "right",
        },
        popper: {
            zIndex: "3",
            marginTop: "20px",
            font: "16px/16px Futura Md BT",
            '& .MuiListItem-root:hover': {
                backgroundColor: switchFeatureContainerColorLight,
            },
        },
        paddingLeft30: {
            paddingLeft: "30px"
        },
        backgroundSelection: {
            '&:hover': {
                backgroundColor: switchFeatureContainer.color
            }
        },
        paper: {
            marginRight: theme.spacing(2),
        },
        outline: {
            '& .MuiList-root .MuiList-padding': {
                outline: "0px",
            }
        },
    }))
    const [open, setOpen] = React.useState(false)


    const classesBars = barsStyles()
    const handleToggle = () => {
        setOpen(prevOpen => !prevOpen)
    }

    const checkValue = (target, value) => {
        return value.every(r => target.includes(r))
    }


    const checkSubtype = () => {
        return checkValue(selectedSubtypes, subtypeGroup.subtypes) || (selectedTypes.find(val => val.typeID === subtypeGroup.id) && selectedTypes.length !== types.length)
        //    else{
        //     return checkValue(selectedSubtypes,subtypeGroup.subtypes)
        //    }

    }

    const filterTitle = () => {
        const type = types.find(val => val.typeID === subtypeGroup.id) || null;
        if (type) {
            setSwitchFeatureTypeContainerOfFeature(activeFeatureId, lang, feature.types, type)
        }
        //  handleGoupeSubTypeFeature(subtypeGroup.subtypes, selectedSubtypes) 
    }

    const selectSubType= (subtype) =>{
      const found =   selectedSubtypes.find(val => val.id === subtype.id)
        if(found){
            deleteSelectedSubTypeFeature(subtype, selectedSubtypes)
        }
        else{
            addSelectedSubTypeFeature(subtype, selectedSubtypes)             
        }
    }

    

    return (
        <div className="position-relative">
            <div>
                {subtypeGroup.subtypes !== undefined && subtypeGroup.subtypes
                    && subtypeGroup.subtypes.length > 0 &&
                    <div style={{}} >
                        <div style={{ flexGrow: "1", paddingTop: "10px", paddingBottom: "6px", cursor: "pointer", outline: "0px", }}
                            onClick={() => filterTitle()}

                        >
                            <Typography variant="inherit" noWrap style={{ display: "inline-flex", width: "215px", paddingLeft: "10px", paddingBottom: 4, paddingRight: "10px", font: "16px/16px Futura Md BT", outline: "0px" }}>
                                {subtypeGroup.title}
                            </Typography>


                            {checkSubtype() ? <CheckLightIcon fill={switchFeatureContainer.color} /> : null}
                        </div>
                    </div>
                }

                {subtypeGroup.subtypes !== undefined
                    && subtypeGroup.subtypes.length > 0
                    && subtypeGroup.subtypes.map((subtype, subtype_index) => {
                        return (
                            <React.Fragment key={subtype_index}>
                                {   //if nested
                                    subtype.subtypes && subtype.subtypes.length > 0 ?
                                        <div style={{}} key={subtype_index + "sub"}>
                                            <div style={{ flexGrow: "1", paddingTop: "10px", paddingBottom: "6px", cursor: "pointer", outline: "0px", }}
                                            >
                                                <Typography variant="inherit" noWrap style={{ display: "inline-flex", width: "215px", paddingLeft: "20px", paddingBottom: 4, paddingRight: "10px", font: "16px/16px Futura Md BT", outline: "0px" }}>
                                                    {subtype.title}
                                                </Typography>
                                            </div>


                                            <SubTypeComponent
                                            selectSubType={selectSubType}
                                                classesBars={classesBars} selectedSubtypes={selectedSubtypes}
                                                subtype={subtype} deleteSelectedSubTypeFeature={deleteSelectedSubTypeFeature}
                                                addSelectedSubTypeFeature={addSelectedSubTypeFeature} checkValue={checkValue} switchFeatureContainer={switchFeatureContainer}
                                            />



                                        </div>
                                        : open || subtype_index < 15 ?
                                            <MenuItem key={subtype_index} style={{ height: 32 }}
                                                className={classesBars.outline}
                                                onClick={
                                                    () => {
                                                        selectSubType(subtype)
                                                    }}
                                            >
                                                <Typography variant="inherit" noWrap style={{ width: "200px", font: "15px/15px Futura Lt BT" }}>
                                                    {subtype.title}
                                                    {subtype.featureUnitQuantity && <span style={{
                                                        fontSize: 13,
                                                        color: "lightgrey",
                                                        paddingLeft: 15
                                                    }}>
                                                        {subtype.featureUnitQuantity} </span>}
                                                </Typography>
                                                {selectedSubtypes.find(val => val.id === subtype.id) && !checkValue(selectedSubtypes, subtypeGroup.subtypes) && <CheckLightIcon fill={switchFeatureContainer.color} />}

                                            </MenuItem>
                                            : ""

                                }
                            </React.Fragment>
                        )
                    })}

            </div>

            <div className="d-inline-block position-absolute" style={{ zIndex: "4", right: "2px", bottom: "3px" }}>
                {subtypeGroup.subtypes !== undefined
                    && subtypeGroup.subtypes.length > 15 && open && <div onClick={() => handleToggle()}> <CircleUpIcon /></div>}
                {subtypeGroup.subtypes !== undefined
                    && subtypeGroup.subtypes.length > 15 && !open && <div onClick={() => handleToggle()}> <CircleDownIcon /></div>}
            </div>
        </div>
    )
})

export default BarsSubTypeMenuItem

export const SubTypeComponent = ({ classesBars, selectedSubtypes, subtype, deleteSelectedSubTypeFeature,
    addSelectedSubTypeFeature, checkValue, switchFeatureContainer,selectSubType }) => {
    const [subOpen, setSubOpen] = React.useState(false)
    const limit = 15;
    const handleSubToggle = () => {
        setSubOpen(prevOpen => !prevOpen)
    }
    return (
        <>
            {subtype.subtypes && subtype.subtypes.slice(0,
                (subOpen ? subtype.subtypes.length : limit)).map((category, category_index) => {

                    return (
                        <MenuItem key={category_index}
                            className={classesBars.outline} style={{ height: 33 }}>
                            <Typography variant="inherit" noWrap style={{ paddingLeft: "10px", paddingBottom: 1, width: "210px", font: "15px/15px Futura Lt BT" }} onClick={
                                () => {
                                    selectSubType(category);
                                }}>
                                {category.title} {category.featureUnitQuantity && <span style={{
                                    fontSize: 13,
                                    color: "lightgrey",
                                    paddingLeft: 15
                                }}>
                                    {category.featureUnitQuantity} </span>}
                            </Typography>
                            {selectedSubtypes.find(val => val.id === category.id) && !checkValue(selectedSubtypes, subtype.subtypes) && <CheckLightIcon fill={switchFeatureContainer.color} />}
                            {subOpen && (subtype.subtypes.length - 1) === category_index && <div onClick={() => handleSubToggle()}> <CircleUpIcon /></div>}
                            {!subOpen && category_index === limit - 1 && <div onClick={() => handleSubToggle()}> <CircleDownIcon /></div>}

                        </MenuItem>)
                })}

        </>
    )
}