import './muvInPress.css'

import { ArrowLeftIcon, ArrowRightIcon } from "../../Common/SVGBank"
import React, { Component } from 'react';

import Slider from 'react-slick';
import { connect } from 'react-redux'

const SliderArrowLeft = ({ className, to, onClick }) => (
  <button type="button" onClick={onClick} className={`button button--text button--icon ${className}`} aria-label={to}>
    <ArrowLeftIcon className="icon" icon={to} style={{
      height: "60px",
      width: "60px",
      transform: 'translateY(-77px)',

    }} />
  </button>
)
const SliderArrowRight = ({ className, to, onClick }) => (
  <button type="button" onClick={onClick} className={`button button--text button--icon ${className}`} aria-label={to}>
    <ArrowRightIcon className="icon" icon={to} style={{
      height: "60px",
      width: "60px",
      transform: 'translateY(-77px)'
    }} />
  </button>
)
class MuvInPress_ extends Component {

  render() {
    const { homepageMuverPress, screenSize, bodyClientWidth, is_mobile } = this.props;
    const sliderArrowRight = <SliderArrowRight />
    let heightContainer = "240"
    const display_element_length = screenSize > 1724 ? 5 :
      screenSize > 1500 ? 4 :
        screenSize > 1100 ? 3 :
          screenSize >= 1024 ? 2 :
            screenSize >= 768 ? 3 :
              screenSize > 500 ? 1 : 2
    const left_element = homepageMuverPress && homepageMuverPress.length < display_element_length ? "left_element" : ""
    const arrowsValue = homepageMuverPress && homepageMuverPress.length > display_element_length ? true : false
    if (!isNaN(bodyClientWidth)) {
      heightContainer = bodyClientWidth * 0.82 / 15 + 50
    }
    const setting = {
      touchMove: false,
      dots: false,
      infinite: false,
      arrows: arrowsValue,
      speed: 500,
      lazyLoad: false,
      autoplay: false,
      slidesToShow: 5,
      slidesToScroll: 2,
      pauseOnHover: false,
      centerMode: false,
      prevArrow: <SliderArrowLeft to="prev" />,
      nextArrow: sliderArrowRight,
      responsive: [
        {
          breakpoint: 1724,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 2,
          }
        },
        {
          breakpoint: 1500,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 2,
          }
        },
        {
          breakpoint: 1100,
          settings: {
            touchMove: true,
            slidesToShow: 2,
            slidesToScroll: 2,
            arrows: false,
          }
        },
        {
          breakpoint: 768,
          settings: {
            touchMove: true,
            slidesToShow: 2,
            slidesToScroll: 1,
            arrows: false,

          }
        },
      ]
    }
    return (
      <div>
        <div className="carousel-heading-2 text-center" style={{ font: is_mobile ? "18px/48px Futura Hv BT" : "40px/48px Futura Hv BT" }}>müv in the press</div>
        <Slider {...setting} className={left_element} style={{ height: heightContainer }}>
          {homepageMuverPress.length > 0 && homepageMuverPress.map((item, item_idx) => {
            return (
              <div key={item_idx}>
                <div className="item" onClick={() => window.open(item.url)} style={{ cursor: "pointer", paddingLeft: "35px", textAlign: "center" }}>
                  <img src={'https://amhqajbken.cloudimg.io/v7/' + item.image + '?func=crop&w=240'} alt="press" />
                </div>
              </div>
            )
          })}
        </Slider>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    bodyClientWidth: state.Setting.htmlBody.bodyClientWidth,
    homepageMuverPress: state.Home.homepage_muver_press.result,
    is_mobile: state.Setting.is_mobile,
  }

}

const MuvInPress = connect(mapStateToProps, null)(MuvInPress_)

export default MuvInPress