import React from 'react'
import { connect } from 'react-redux'

 const StarredTopBar_ = ({is_mobile }) => {

    let topImageHeight = is_mobile ? "180" : "250"
    let containerH = 500
    if (topImageHeight !== undefined) {
        containerH = topImageHeight + "px"
    }

    let srcImage = ""
    srcImage = "/img/banner.png"
    return (
        <div className="position-relative z_index_2">
            <div style={{
                width: "100%",
                display: "block",
                position: "relative",
                top: "0",
                left: "0",
                bottom: "0",
                right: "0",
                border: "0",
                height: containerH,
                backgroundImage: "url('" + srcImage + "')",
                backgroundRepeat: "no-repeat",
            }}></div>
            <div className="mask_block" style={{ height: containerH }}></div>

        </div >
    )

}

const mapStateToProps = state => {
    return {
        screenSize: Math.min(state.Setting.htmlBody.bodyClientWidth, document.body.clientWidth),
        is_mobile: state.Setting.is_mobile,
        switchFeatureContainer: state.FormBank.FeatureFormat,
        topImage: state.Feature.feature.topImage,
    }
}

const mapDispatchToProps = {
}

const StarredTopBar = connect(mapStateToProps, mapDispatchToProps)(StarredTopBar_)
export default StarredTopBar
