import React, { Component } from 'react'

import { AddressMapIcon } from './SVGBank';
import {
    Image,
} from 'react-bootstrap'
import { Star } from '../Common/MemberActions/Star'
import { connect } from 'react-redux'
import { features_router } from "./RegExValidate"
import { image_boxing } from './RegExValidate'

class FavoritesElement_ extends Component {

    render() {
        const { favorite, from,
            //  lang 
        } = this.props
        return (
            <div className="padding_left">
                <div className="content_container p-2"  >
                    <Image className="min_width_100 img_fit_cover"
                        src={image_boxing(favorite.muvImage, "214", "341")}
                        alt="inflencer" />
                    <div style={{
                        top: "0",
                        left: "0",
                        right: "0",
                        height: "10%",
                        position: "absolute",
                        alignItems: "center",
                    }}>
                        <div style={{ height: "100%", backgroundColor: "rgba(25, 25, 25, 0.2)", textAlign: "right", padding: "10px", }}>
                            <Star
                                unitID={favorite.favoriteID}
                                feature={features_router.places}
                                fill={"var(--mainWhite)"} />
                        </div>
                    </div>
                    <div
                    >
                        <div style={{
                            top: "10%",
                            left: "0",
                            right: "0",
                            height: "70%",
                            position: "absolute",
                            alignItems: "center",
                        }}>
                            <div style={{ height: "100%", backgroundColor: "rgba(25, 25, 25, 0.2)", textAlign: "right", padding: "5px", }}>
                            </div>
                        </div>
                    </div>
                    <div style={{
                        bottom: "0",
                        left: "0",
                        right: "0",
                        height: "20%",
                        position: "absolute",
                        alignItems: "center",
                    }}>
                        <div style={{ height: "100%", backgroundColor: "rgba(25, 25, 25, 0.2)", textAlign: "left", }}>
                            {from === "hometownFavorites" ? "" : <div style={{ paddingBottom: "51px", position: "absolute", bottom: "0" }}>
                                <div className="box_1" style={{
                                    marginLeft: "32px",
                                    marginRight: "10px",
                                    color: "white",
                                    font: "12px/12px Futura Md BT",
                                }}>{favorite.destinationName}</div>
                            </div>}
                            <div className="d-flex" style={{ paddingLeft: "10px", paddingBottom: "26px", position: "absolute", bottom: "0" }}>
                                <AddressMapIcon fill={"#FFFFFF"} style={{ marginLeft: "5px", marginRight: "5px" }} />
                                <div className="futura_md_bt_16_22_white flex_1 box_1" style={{ marginRight: "10px" }}>{favorite.favoriteName}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="box_3 futura_md_bt_13_19_green pl-2 pr-2">
                    {favorite.favoriteDesc}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    lang: state.Setting.lang,

})

const mapDispatchToProps = {

}

const FavoritesElement = connect(mapStateToProps, mapDispatchToProps)(FavoritesElement_)
export default FavoritesElement
