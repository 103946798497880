import "./Influencers.css";

import * as Actions from '../../../../redux/actions'

import { ArrowLeftIcon, ArrowRightIcon } from "../SVGBank"

import { Image } from "react-bootstrap"
import { PropTypes } from "prop-types"
import React from 'react'
import Slider from 'react-slick'
import { connect } from 'react-redux';
import { image_boxing } from '../RegExValidate'

const SliderArrowRight = ({ className, to, onClick }) => (
    <button type="button" onClick={onClick} className={`button button--text button--icon ${className}`} aria-label={to}>
        <ArrowRightIcon className="icon" icon={to} style={{
            height: "40px",
            width: "40px",
            transform: 'translate(-10px, -55px)'
        }} />
    </button>
)



const SliderArrowLeft = ({ className, to, onClick }) => (
    <button type="button" onClick={onClick} className={`button button--text button--icon ${className}`} aria-label={to}>
        <ArrowLeftIcon className="icon" icon={to} style={{
            height: "40px",
            width: "40px",
            transform: 'translate(30px, -55px)'
        }} />
    </button>
)

/* influencer component */
class Influencers_ extends React.Component {
    handlerFollower = (muver_id, action) => {
        const { uuid, alertBar, modifyFollowing } = this.props
        if (uuid !== undefined && uuid !== "") {
            modifyFollowing(uuid, muver_id, action)
        } else {
            this.props.setOpenAuthModal(true)
            alertBar("warning", "Please Sign In / Sign Up")
        }
    }
    render() {
        const { from, socialMemberInfluencers, establishmentInfluencers, screenSize, followersSets, list } = this.props;


        let influencers = []
        influencers = (from === "establishment") ? establishmentInfluencers
            : (from === "social" && (list || socialMemberInfluencers))

        let settings = {
            touchMove: false,
            dots: false,
            infinite: false,
            arrows: influencers && influencers.length > 6 ? true : false,
            speed: 500,
            autoplay: false,
            lazyLoad: false,
            slidesToShow: 6,
            slidesToScroll: 3,
            pauseOnHover: true,
            centerMode: false,
            centerPadding: "70px",
            prevArrow: <SliderArrowLeft to="prev" />,
            nextArrow: <SliderArrowRight to="next" />,
            responsive: [
                {
                    breakpoint: 1724,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 2,
                        centerPadding: "60px",
                        arrows: influencers && influencers.length > 4 ? true : false,
                    }
                },
                {
                    breakpoint: 1500,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 2,
                        centerPadding: "60px",
                        arrows: influencers && influencers.length > 3 ? true : false,
                    }
                },
                {
                    breakpoint: 1100,
                    settings: {
                        touchMove: true,
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        arrows: false,
                        centerPadding: "60px",
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        touchMove: true,
                        slidesToShow: 3,
                        slidesToScroll: 2,
                        arrows: false,
                        centerPadding: "60px",

                    }
                },
                {
                    breakpoint: 500,
                    settings: {
                        touchMove: true,
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        arrows: false,
                        centerPadding: "15px",
                    }
                },
            ]
        }
        const display_element_length = screenSize > 1724 ? 5 :
            screenSize > 1500 ? 4 :
                screenSize > 1100 ? 3 :
                    screenSize >= 1024 ? 2 :
                        screenSize >= 768 ? 3 :
                            screenSize > 500 ? 1 : 2
        const left_element = influencers && influencers.length < display_element_length ? "left_element" : ""

        const goToBlackPage = (handle) => {
            if (handle) {
                const url = "/" + handle;
                window.open(url.toLowerCase());
            }
        }

        return (
            <React.Fragment>
                {influencers.length > 0 && from === "social" && <div className={`${list ? '' : 'text-center'} margin_bottom_30 margin_top_40`} style={{ font: "24px/26px Futura Md BT", color: "var(--mainWhite)" }}>Other müvers
                {list && <span className=""
                        style={{
                            paddingTop: 10,
                            paddingLeft: 10,
                            font: "12px/12px Futura Lt BT",
                        }}
                    >{influencers.length}</span>}
                </div>}
                {influencers.length > 0 && from === "establishment" && <div className="text-left margin_bottom_30 margin_top_40" style={{ font: "18px/26px Futura Md BT", color: "var(--mainGreen)" }}>Local Influencers</div>}
                <Slider {...settings} className={left_element}>
                    {influencers.length > 0 && influencers.map((influencer, idx) => {
                        return (
                            <div key={idx}>
                                {from === "social" &&
                                    <div>
                                        <div className="text-center mb-2" onClick={() => goToBlackPage(influencer?.muverHandle)}
                                            style={{ cursor: "pointer" }}>
                                            <Image className="rounded-circle d-inline img_fit_cover"
                                                src={image_boxing(influencer.muverAvatar, "100", "100", "muvimages/user_avatar/armando.jpg")}
                                                alt="avatar_inflencer" />
                                        </div>
                                        <div className="text-center" style={{ marginTop: "10px", marginBottom: "10px" }}>
                                            <div onClick={() => goToBlackPage(influencer?.muverHandle)}
                                                style={{ cursor: "pointer" }} className="futura_md_bt_18_20_white text-center">{influencer.muverFirstName !== "" && influencer.muverLastName !== "" ? influencer.muverFirstName.charAt().toUpperCase() + influencer.muverFirstName.substring(1) + ' ' + influencer.muverLastName.charAt().toUpperCase() + '.' : ''}</div>
                                            <div className="futura_lt_bt_14_16_grey text-center" style={{ marginTop: "6px" }}>{influencer.professions !== undefined && influencer.professions.length > 0 && influencer.professions[0] !== undefined && influencer.professions[0].title}</div>
                                            {followersSets !== undefined &&
                                                followersSets.filter(follower => follower.memberID === influencer.muverUID).length > 0 ?
                                                <div className="futura_hv_bt_16_19_green text-center" style={{ marginTop: "6px" }} onClick={() => this.handlerFollower(influencer.muverUID, "unfollow")}>Unfollow</div>
                                                :
                                                <div className="futura_hv_bt_16_19_green text-center" style={{ marginTop: "6px" }} onClick={() => this.handlerFollower(influencer.muverUID, "follow")}>Follow</div>
                                            }
                                        </div>
                                    </div>
                                }
                                {from === "establishment" &&
                                    <div>
                                        <div className="text-center mb-2">

                                            <Image className="rounded-circle d-inline img_fit_cover"
                                                src={image_boxing(influencer.muverAvatar, "110", "110", "muvimages/user_avatar/armando.jpg")}
                                                alt="avatar_inflencer" />
                                        </div>
                                        <div className="text-center" style={{ marginTop: "10px", marginBottom: "10px" }}>
                                            <div className="text-center" style={{ font: "18px/20px Futura Md BT", color: "var(--frameBlack)" }}>{influencer.muverFirstName !== "" && influencer.muverLastName !== "" && influencer.muverFirstName !== undefined && influencer.muverLastName !== undefined ? influencer.muverFirstName.charAt().toUpperCase() + influencer.muverFirstName.substring(1) + ' ' + influencer.muverLastName.charAt().toUpperCase() + '.' : ''}</div>
                                            {followersSets !== undefined &&
                                                followersSets.filter(follower => follower.memberID === influencer.muverUID).length > 0 ?
                                                <div className="futura_hv_bt_16_19_green text-center" style={{ marginTop: "6px" }} onClick={() => this.handlerFollower(influencer.muverUID, "unfollow")}>Unfollow</div>
                                                :
                                                <div className="futura_hv_bt_16_19_green text-center" style={{ marginTop: "6px" }} onClick={() => this.handlerFollower(influencer.muverUID, "follow")}>Follow</div>
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                        )
                    })}
                </Slider>
                <div style={{height:50}}>
                    </div>
            </React.Fragment>
        )
    }
}


Influencers_.propTypes = {
    establishmentInfluencers: PropTypes.array.isRequired,
    socialMemberInfluencers: PropTypes.array.isRequired,
    screenSize: PropTypes.number.isRequired,
}
const mapStateToProps = state => {
    return {
        socialMemberInfluencers: state.Member.socialMember.influencers,
        screenSize: state.Setting.htmlBody.bodyClientWidth,
        establishmentInfluencers: state.Destination.destination.influencers,
        followersSets: state.MemberActions.fetchFollowers.followersSets,
        uuid: state.Member.authModal.uuid,
    }
};

const mapDispatchToProps = {
    alertBar: Actions.alertBar,
    setOpenAuthModal: Actions.setOpenAuthModal,
    modifyFollowing: Actions.modifyFollowing,
}
const Influencers = connect(mapStateToProps, mapDispatchToProps)(Influencers_)
export default Influencers;