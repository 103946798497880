import * as Actions from '../../../redux/actions';

import React, { Component } from 'react'

import { Menu } from 'semantic-ui-react'
import { connect } from 'react-redux'
import { landing_router } from '../Common/RegExValidate';

class MenuLanding_ extends Component {
    render() {

        const { switch_container, setSwitchProfileContainer, screenSize, token } = this.props;
        let nav_menu_width = "240"

        let screen_at_500 = 500
        if (!isNaN(screenSize)) {
            nav_menu_width = screenSize < screen_at_500 ? screenSize / 3 : "240"
        }
        return (
            <Menu pointing secondary style={{ backgroundColor: "#FFFFFF", borderBottom: "0", paddingTop: "10px" }}>
                <div className={switch_container === landing_router.register ? "menu_link_title_text_create_account_active p-0 m-0" : "menu_link_title_text_create_account p-0 m-0"}
                    onClick={() => setSwitchProfileContainer(landing_router.register)}
                    style={{
                        width: nav_menu_width + "px",
                        cursor: token !== undefined && token !== "" ? "pointer" : "",
                    }}>1. Account</div>
                <div className={switch_container === landing_router.interests ? "menu_link_title_text_create_account_active p-0 m-0" : "menu_link_title_text_create_account p-0 m-0"}
                    onClick={
                        token !== undefined && token !== "" ?
                            () => setSwitchProfileContainer(landing_router.interests)
                            :
                            () => { }
                    }
                    style={{
                        width: nav_menu_width + "px",
                        cursor: token !== undefined && token !== "" ? "pointer" : "",
                    }}>2. Interests</div>
                <div className={switch_container === landing_router.bio ? "menu_link_title_text_create_account_active p-0 m-0" : "menu_link_title_text_create_account p-0 m-0"}
                    onClick={
                        token !== undefined && token !== "" ?
                            () => setSwitchProfileContainer(landing_router.bio)
                            :
                            () => { }
                    }
                    style={{
                        width: nav_menu_width + "px",
                        cursor: token !== undefined && token !== "" ? "pointer" : "",
                    }}>3. Bio</div>
                <div className={switch_container === landing_router.profile ? "menu_link_title_text_create_account_active p-0 m-0" : "menu_link_title_text_create_account p-0 m-0"}
                    onClick={
                        token !== undefined && token !== "" ?
                            () => setSwitchProfileContainer(landing_router.profile)
                            :
                            () => { }
                    }
                    style={{
                        width: nav_menu_width + "px",
                        cursor: token !== undefined && token !== "" ? "pointer" : "",
                    }}>4. Profile</div>
            </Menu>
        )
    }
}


const mapStateToProps = (state) => ({
    screenSize: state.Setting.htmlBody.bodyClientWidth,
    switch_container: state.Member.profile_container,
    app_menu_height: state.Setting.appMenu.app_menu_height,
    is_mobile: state.Setting.is_mobile,
    token: state.Member.authModal.token,
})

const mapDispatchToProps = {
    setSwitchProfileContainer: Actions.setSwitchProfileContainer,
}

const MenuLanding = connect(mapStateToProps, mapDispatchToProps)(MenuLanding_)
export default MenuLanding
