import {
    Image,
} from 'react-bootstrap'
import React from 'react'
import { Star } from '../../Common/MemberActions/Star'
import { features_router } from "../../Common/RegExValidate"
import { image_boxing } from '../../Common/RegExValidate'
import BucketListName from './BucketListName';
import * as Actions from '../../../../redux/actions'
import { useHistory } from "react-router-dom"
import { member_area_router, URL_REPLACE } from '../RegExValidate'
import { connect } from 'react-redux'
import { animated, useSpring } from 'react-spring';
import { BucketListDescription } from './BucketListDescription';
import ActionsEllipsis  from '../actionsEllipsis';
import MemberActionPlaceEllipsis from '../MemberActions/MemberActionPlaceEllipsis';
import DeleteFromListDialog from '../DeleteFromListDialog'

const BucketListCardView_ = ({ bucketItem,switchFeatureContainer, timelineWizardStatus, is_mobile,memberAction=null }) => {
    const history = useHistory()
    const [flipped, setFlipped] = React.useState(false)
    const { transform, opacity } = useSpring({
        opacity: flipped ? 1 : 0,
        transform: `perspective(600px) rotateY(${flipped ? 180 : 0}deg)`,
        config: { mass: 5, tension: 500, friction: 80 }
    })

    let imageW = "249"
    let imageH = "405"
    if (!isNaN(is_mobile)) {
        imageW = is_mobile ? "179" : "272"
        imageH = is_mobile ? "260" : "405"
    }

    const picture = memberAction? bucketItem.image: bucketItem.bucketDestinationPicture
    return (
        <div style={{
            marginBottom: "20px",
            marginRight: "0px",
            marginLeft: "0px",
            position: 'relative',
            marginTop: '20px',
            cursor: "pointer",
            // margin: is_mobile ? "20px 10px 20px 10px" : "40px 15px 40px 15px",
        }}>
            
          {!flipped ?    ///front of card
          <animated.div style={{        backgroundColor: "black", position: "relative", zIndex: flipped ? "0" : "1", opacity: opacity.interpolate(o => 1 - o), transform }}>
             
                    <Image 
                        src={image_boxing(picture, imageW, imageH)}
                        alt="bucket list" style={{ opacity: '0.8' }} onClick={() => setFlipped(state => !state)} />



                    <div style={{
                        top: "0px",
                        right: "0px",
                        height: "10%",
                        position: "absolute",
                        alignItems: "center",
                        display: "inline-block"
                    }}>

                  
                        { !memberAction && <div style={{ height: "100%", textAlign: "right", padding: "10px", }}>
                        <ActionsEllipsis id={bucketItem.bucketListID} switchFeatureContainer={switchFeatureContainer}/>
                        </div>}
                        {memberAction && memberAction.userAction === "star" &&  !memberAction.memberArea  && 
                        <div style={{ height: "100%", textAlign: "right", padding: "10px", }}>
                        <MemberActionPlaceEllipsis 
                            unitID={memberAction.unitID} 
                            feature={memberAction.feature}
                            destinationId={memberAction.destinationId}
                            userActionId={memberAction.userActionId}
                            destination={memberAction.destination}
                            userAction={memberAction.userAction}
                            />
                    </div>}
                    {memberAction &&  memberAction.memberArea && 
                        <div style={{ height: "100%", textAlign: "right", padding: "10px", color: 'white' }}>
                        <DeleteFromListDialog fillColor={"#FFFFFF"} unitID={memberAction.unitID} feature={memberAction.feature}
                         setOpenTripEllipsis={false} hideTitle={true}  from={"Remove from List"} />
                    </div>}


                   

                    </div>

                    <div style={{
                        bottom: "30px",
                        left: "30px",
                        right: "0px",
                        height: "20%",
                    }}>
                        <div style={{ height: "100%", textAlign: "left", }}>
                            <div className="d-flex" style={{ paddingLeft: "10px", paddingBottom: "26px", position: "absolute", bottom: "10px" }}>

                                <div className="futura_md_bt_16_22_white flex_1 box_1" style={{ marginLeft: "10px", marginRight: "10px" }}>
                                    <BucketListName
                                        bucketName={bucketItem.bucketListName}
                                        bucketId={bucketItem.bucketListID}
                                        titleStyle={{
                                            cursor: "pointer",
                                            paddingLeft: "10px",
                                            paddingRight: "10px",
                                        }} />
                                </div>
                            </div>
                        </div>
                    </div>
           
            </animated.div>
///back of card
            : <animated.div style={{         backgroundColor: "black",position: "relative", zIndex: flipped ? "1" : "0", opacity, transform: transform.interpolate(t => `${t} rotateY(180deg)`) }}>
            
                    <Image
                        src={image_boxing(picture, imageW, imageH)}
                        alt="bucket list" style={{ opacity: '0.8' }} onClick={() => setFlipped(state => !state)} />

                {bucketItem.bucketListDescription !== undefined &&
                        <div style={{ position: "absolute", top: "60px",width: '85%',
                        marginLeft: '20px', zIndex: "1",
                        borderBottomStyle: 'solid',
                        borderBottomWidth: '1px',
                        paddingBottom: '10px',
                        borderBottomColor: '#ffffff70',
                         }} className={"padding_left_10 padding_right_10"}>
                            <BucketListDescription description={bucketItem.bucketListDescription } boxDescription={"box_10"} fontDescription={timelineWizardStatus ? "14px/19px Futura LT BT" : "16px/25px Futura LT BT"} colorDescription={"#FFFFFF"} height={timelineWizardStatus ? "190px" : "250px"} />
                        </div>}

                    <div style={{
                        top: "0px",
                        right: "0px",
                        height: "10%",
                        position: "absolute",
                        alignItems: "center",
                        display: "inline-block"
                    }}>
                    
                    { !memberAction && <div style={{ height: "100%", textAlign: "right", padding: "10px", }}>
                        <ActionsEllipsis id={bucketItem.bucketListID} switchFeatureContainer={switchFeatureContainer}/>
                        </div>}
                        {memberAction && memberAction.userAction === "star" && 
                        <div style={{ height: "100%", textAlign: "right", padding: "10px", }}>
                        <MemberActionPlaceEllipsis 
                            unitID={memberAction.unitID} 
                            feature={memberAction.feature}
                            destinationId={memberAction.destinationId}
                            userActionId={memberAction.userActionId}
                            destination={memberAction.destination}
                            />
                    </div>}
                    </div>

                    <div style={{
                        bottom: "30px",
                        left: "30px",
                        right: "0px",
                        height: "20%",
                    }}>
                        <div style={{ height: "100%", textAlign: "left", }}>
                            <div className="d-flex" style={{ paddingLeft: "10px",  paddingBottom: "26px", position: "absolute", bottom: "10px" }}>

                                <div className="futura_md_bt_16_22_white flex_1 box_1" style={{ marginLeft: "10px",
                                           marginRight: "10px" }}>
                                    <BucketListName
                                        bucketName={bucketItem.bucketListName}
                                        bucketId={bucketItem.bucketListID}
                                        titleStyle={{
                                            cursor: "pointer",
                                            paddingLeft: "10px",
                                            paddingRight: "10px",
                                        }} />
                                </div>
                            </div>
                        </div>
                    </div>
          
            </animated.div>}

         
        </div>
    )
}
const mapStateToProps = (state) => ({
    lang: state.Setting.lang,
    switchFeatureContainer: state.FormBank.FeatureFormat,
})


const mapDispatchToProps = {
    setSwitchContainer: Actions.setSwitchContainer,
}

const BucketListCardView = connect(mapStateToProps, mapDispatchToProps)(BucketListCardView_)
export default BucketListCardView
