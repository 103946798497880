import { GoogleMap, Marker, withGoogleMap, } from "react-google-maps"
import { compose, lifecycle, withProps } from "recompose"

import React from 'react';
import { connect } from 'react-redux'
import mapStyles from "../TripPage/mapStyles"

/* wrap map high order component */
const WrappedMap = compose(
    withProps({
        // googleMapURL: "https://maps.googleapis.com/maps/api/js?libraries=geometry,drawing,places&key=" + GOOGLE_API_KEY,
        loadingElement: <div style={{ height: `100%` }} />,
        containerElement: <div style={{ height: `100%` }} />,
        mapElement: <div style={{ height: `100%` }} />
    }),
    withGoogleMap,
    lifecycle({
        componentDidUpdate(prevProps) {
        },
        componentDidMount() { }
    })
)((props) =>
    /* map setting */
    <GoogleMap
        defaultZoom={11}
        defaultCenter={{
            lat: parseFloat(props.mapCenter.lat),
            lng: parseFloat(props.mapCenter.lng)
        }}
        center={{
            lat: parseFloat(props.mapCenter.lat),
            lng: parseFloat(props.mapCenter.lng)
        }}
        defaultOptions={{
            controlSize: 20,
            mapTypeControl: false,
            fullscreenControl: false,

            minZoom: 4,
            maxZoom: 18,
            styles: mapStyles
        }}
    >
        <Marker
            position={{
                lat: parseFloat(props.mapCenter.lat),
                lng: parseFloat(props.mapCenter.lng)
            }}
        >
        </Marker>
    </GoogleMap>
)

/* home map component */
class EstablishmentMap_ extends React.Component {

    render() {
        const { screenSize, lat, lng } = this.props;
        let containerH = 600;
        if (screenSize !== undefined) {
            containerH = screenSize / 5;
            containerH = containerH < 320 ? 320 : (containerH > 380 ? 380 : containerH);
        }
        return (
            <div style={{ height: containerH }}>
                <WrappedMap
                    mapCenter={{
                        lat: parseFloat(lat),
                        lng: parseFloat(lng)
                    }}
                />
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        screenSize: state.Setting.htmlBody.bodyClientWidth
    }
};

const mapDispatchToProps = {
};
const EstablishmentMap = connect(mapStateToProps, mapDispatchToProps)(EstablishmentMap_)
export default EstablishmentMap;
