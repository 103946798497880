import './suggestedDestination.css'

import * as Actions from '../../../../redux/actions'
import { ScrollMenu } from 'react-horizontal-scrolling-menu';
import { ArrowLeftIcon, ArrowRightIcon } from "../../Common/SVGBank"

import {
  Image,
} from 'react-bootstrap'
import React from 'react'
import Slider from 'react-slick'
import { URL_REPLACE } from "../../Common/RegExValidate"
import { connect } from 'react-redux'
import { image_boxing } from '../../Common/RegExValidate'
import { member_area_router } from "../../Common/RegExValidate"
import { useHistory } from "react-router-dom"
import { GeneralSearchBar } from '../../Common/GeneralSearch/GeneralSearchBar'
import { TrackEvent } from '../../../../utility/utility';

const SliderArrowLeft = ({ className, to, onClick }) => (
  <button type="button"
    style={{ marginLeft: "78vw", marginTop: -110 }}
    onClick={onClick} className={`button button--text button--icon ${className}`} aria-label={to}>
    <ArrowLeftIcon className="icon" icon={to} style={{
      height: "60px",
      width: "60px",
    }} />
  </button>
)
const SliderArrowRight = ({ className, to, onClick }) => (
  <button type="button" style={{ marginRight: "2.2vw", marginTop: -110 }}
    onClick={onClick} className={`button button--text button--icon ${className}`} aria-label={to}>
    <ArrowRightIcon className="icon" icon={to} style={{
      height: "60px",
      width: "60px",
    }} />
  </button>
)
const SuggestedDestination_ = ({ setPageYOffset, homepage, screenSize, is_mobile, setSwitchContainer }) => {
  const history = useHistory()

  const linkPage = (destinationId, city, state, country, uri) => {
    TrackEvent('HOME',"Click suggested destination",destinationId)

    setSwitchContainer(member_area_router.destination)
    const url = "/destination/" + uri + "/" + 1 + "/" + destinationId;
    history.push(url.toLowerCase())
    setPageYOffset(0)
  }

  const suggestedDestinations = homepage.suggestedDestinations
  const sliderArrowRight = <SliderArrowRight />
  let heightContainer = "300"
  let imageW = "160"
  let imageH = "200"
  let col_11 = "col-11"
  if (!isNaN(screenSize)) {
    col_11 = is_mobile ? "col-12" : "col-11"
  }


  const display_element_length =
    screenSize > 1725 ? 7 :
      screenSize > 1025 ? 4 :
        screenSize >= 768 ? 3 :
          screenSize > 500 ? 2 : 2
  const left_element = suggestedDestinations && suggestedDestinations.length < display_element_length ? "left_element" : ""
  const arrowsValue = suggestedDestinations && suggestedDestinations.length > display_element_length ? true : false

  const setting = {
    touchMove: true,
    dots: false,
    infinite: true,
    arrows: arrowsValue,
    speed: 500,
    lazyLoad: false,
    autoplay: false,
    draggable: true,
    slidesToShow: 7,
    slidesToScroll: 4,
    swipeToSlide: true,
    pauseOnHover: true,
    cssEase: 'ease-out',
    centerMode: false,
    useTransform: false,
    prevArrow: <SliderArrowLeft to="prev" />,
    nextArrow: sliderArrowRight,
    beforeChange: (index, newIndex) => {
    },
    responsive: [
      {
        breakpoint: 1724,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 3,
        }
      },
      {
        breakpoint: 1366,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 3,
          touchMove: true,
          arrows: false,
        }
      },
      {
        breakpoint: 1025,
        settings: {
          touchMove: true,
          slidesToShow: 4,
          slidesToScroll: 2,
          arrows: false,
        }
      },
      {
        breakpoint: 768,
        settings: {
          touchMove: true,
          slidesToShow: 3,
          slidesToScroll: 2,
          arrows: false,

        }
      },
      {
        breakpoint: 500,
        settings: {
          touchMove: true,
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: false,
        }
      },
    ]
  }

  return (
    <div className="suggestedDestination">
      <div className="header">
        <div className="title" style={{ font: is_mobile ? "16px/16px Futura XBlk BT" : "24px/24px Futura XBlk BT" }}>Suggested destinations</div>
        <div className="searcher"> <GeneralSearchBar whiteBackground={true} destinationOnly={true} /> </div>
       
      </div>
      {is_mobile ?
        <div className="mobileSuggested">
          <ScrollMenu LeftArrow={null} RightArrow={null}>
            {suggestedDestinations !== undefined && suggestedDestinations.length > 0 && suggestedDestinations.map((item, idx) => {
              return (
                <React.Fragment key={idx}>
                  <div className="item"
                    style={{ cursor: "pointer", marginRight:25 }}
                    onClick={() => linkPage(item.id, item.suggestCityName, item.suggestStateName, item.suggestCountryName, item.partialUri)}
                  >
                    <Image className="min_width_100 img_fit_cover"
                      src={image_boxing(item.destinationImage, imageW, imageH)}
                      alt="suggested destination" />
                    <h2 style={{ font: is_mobile ? 'bold 14px/18px Muli, sans-serif' : 'bold 18px/28px Muli, sans-serif' }}>{item.suggestCityName}</h2>
                  </div>
                </React.Fragment>
              )
            })
            }
          </ScrollMenu>



        </div> :
        <div className="slider">
           <div style={{ font: "16px/20px Futura Md BT", color: "#19bc9b", textAlign:"right",marginTop:50, marginBottom: -50 }}>
                            <a href={'/suggested-destinations'} 
                            style={{ color: "#19bc9b" }} >See All</a>
        </div>
          <Slider  {...setting} className={left_element + ' destinationSlider'} style={{ height: heightContainer }}>
            {suggestedDestinations !== undefined && suggestedDestinations.length > 0 && suggestedDestinations.map((item, idx) => {
              return (
                <React.Fragment key={idx}>
                  <div className="item"
                    style={{ cursor: "pointer" }}
                    onClick={() => linkPage(item.id, item.suggestCityName, item.suggestStateName, item.suggestCountryName, item.partialUri)}
                  >
                    <Image className="min_width_100 img_fit_cover"
                      src={image_boxing(item.destinationImage, imageW, imageH)}
                      alt="suggested destination" />
                    <h2 style={{ font: is_mobile ? 'bold 14px/18px Muli, sans-serif' : 'bold 18px/28px Muli, sans-serif' }}>{item.suggestCityName}</h2>
                  </div>
                </React.Fragment>
              )
            })
            }
          </Slider>
        </div>}
    </div>
  )

}

const mapStateToProps = (state) => {
  return {
    screenSize: state.Setting.htmlBody.bodyClientWidth,
    lang: state.Setting.lang,
    homepage: state.Home.homepage,
    is_mobile: state.Setting.is_mobile,
  }
}


const mapDispatchToProps = {
  setPageYOffset: Actions.setPageYOffset,
  setSwitchContainer: Actions.setSwitchContainer,
};

const SuggestedDestination = connect(mapStateToProps, mapDispatchToProps)(SuggestedDestination_)
export default SuggestedDestination