import * as Actions from '../../../../redux/actions'

import CircularProgress from '@material-ui/core/CircularProgress'
import { DeleteIcon, } from '../SVGBank'
import React from 'react'
import { connect } from 'react-redux'
import { member_area_router } from "../RegExValidate"

const DeleteActivity_ = ({ activeTrip, switch_container, uuid_auth, deleteActivitiesOfTripDestinationInTrip, deleteActivityToDayOfTripDestinationInTrip, lang, tripDestinationId, activity, fetchingStatus, availableDeleteActivities, from, closeEllipsis = (() => { }) }) => {
    const tripId = activeTrip.tripId
    const uuid = uuid_auth !== "" ? uuid_auth : "TMPUUID"
    const [fetching, setFetching] = React.useState(fetchingStatus)
    const deleteActivityHandler = (lang, uuid, tripDestinationId, activityID, tripId) => {
        setFetching(true)
        deleteActivityToDayOfTripDestinationInTrip(lang, uuid, tripDestinationId, activityID, tripId)
        from === "ellipsis" && closeEllipsis(false)
    }
    const deleteActivitiesHandler = () => {
        setFetching(true)
        deleteActivitiesOfTripDestinationInTrip(lang, uuid, availableDeleteActivities, tripId)
    }
    React.useEffect(() => {
        setFetching(false)
    }, [activeTrip.activities])
    return (
        <React.Fragment>
            {!fetching ?
                <React.Fragment>
                    {from === "modal" &&
                        <div style={{ cursor: "pointer", textAlign: "right", color: "#0D3A7D", font: "14px/24px Futura Md BT" }}
                            onClick={() => deleteActivityHandler(lang, uuid, tripDestinationId, activity.activityID, tripId)}
                        >delete
                        </div>
                    }
                    {from === "modal_delete_all" &&
                        <div style={{ cursor: "pointer", textAlign: "right", color: "#0D3A7D", font: "18px/24px Futura Md BT" }}
                            onClick={() => deleteActivitiesHandler()}
                        >delete all</div>
                    }
                    {from === "ellipsis" &&
                        <div style={{ cursor: "pointer", textAlign: "right", color: "#191919", font: "14px/18px Futura Md BT" }}
                            onClick={() => deleteActivityHandler(lang, uuid, tripDestinationId, activity.activityID, tripId)}
                        ><DeleteIcon width={13} height={16} style={{ marginRight: "0px", marginBottom: "3px", marginRight: "10px" }} /> Delete
                            </div>
                    }
                    {from === "schedule" && <React.Fragment>
                        {switch_container === member_area_router.memberAreaTrip ?

                            <div  className="addActivity" style={{ cursor: "pointer", textAlign: "right", color: "#E8E8E888", font: "18px/24px Futura Md BT" }}
                                onClick={() => deleteActivityHandler(lang, uuid, tripDestinationId, activity.activityID, tripId)}
                            ><DeleteIcon width={13} height={16} style={{ marginRight: "0px", marginBottom: "3px" }} /> Delete from trip
                            </div>
                            :
                            <div   className="addActivity" style={{ cursor: "pointer", textAlign: "right", color: "#0D3A7D", font: "18px/24px Futura Md BT" }}
                                onClick={() => deleteActivityHandler(lang, uuid, tripDestinationId, activity.activityID, tripId)}
                            ><DeleteIcon width={13} height={16} fill={"#0D3A7D"} style={{ marginRight: "0px", marginBottom: "3px" }} /> Delete from trip
                             </div>}
                    </React.Fragment>
                    }
                </React.Fragment>
                :
                <div style={{ cursor: "pointer", textAlign: "right", }}>
                    <CircularProgress
                        disableShrink
                        size={15}
                        style={{
                            color: "#6A9EEC",
                            textAlign: "right",
                        }} />
                </div>

            }
        </React.Fragment>
    )
}

const mapStateToProps = (state) => ({

    activeTrip: state.FormBank.TimelineWizard.activeTrip,
    activeDay: state.FormBank.TimelineWizard.activeDay,
    lang: state.Setting.lang,
    uuid_auth: state.Member.authModal.uuid,
    switch_container: state.Member.switch_container,

})

const mapDispatchToProps = {
    deleteActivityToDayOfTripDestinationInTrip: Actions.deleteActivityToDayOfTripDestinationInTrip,
    deleteActivitiesOfTripDestinationInTrip: Actions.deleteActivitiesOfTripDestinationInTrip,

}

const DeleteActivity = connect(mapStateToProps, mapDispatchToProps)(DeleteActivity_)
export default DeleteActivity
