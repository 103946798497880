import * as Actions from '../../../redux/actions'

import { Authorization } from '../Common/Authorization';
import { BookingFormHeader } from './BookingFormHeader';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { Currency } from '../Common/Currency';
import Divider from '@material-ui/core/Divider'
import { GeneralSearchBar } from '../Common/GeneralSearch/GeneralSearchBar';
import Grow from '@material-ui/core/Grow';
import { Image, } from 'react-bootstrap'
import { Language } from '../Common/Language';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import React from 'react'
import { connect } from 'react-redux'
import { headerMobileStyles } from '../Common/ComponentsStylingBank'
import { Home } from '@material-ui/icons';

import { useHistory } from "react-router-dom"
import StarredIcon from '../Common/MemberActions/StarredIcon';

const HeaderMobile_ = ({ tokenLogin,stars, settAppMenuBackGroundColorMobile, appMenuBookingButtonStatus, setterAppMenuTopBarTextColor, logo_width, logo_height, textColor, backGroundColorMobile, bodyClientWidth, authModal, bg_color, text_color}) => {
    const getColor = () =>{
        return "#19BC9B";
      }
    const history = useHistory()
    const isLoggedIn = () => {
        return authModal?.uuid && authModal?.uuid.length > 0 ? true : false
    }

    const lengthStars = stars.length
    // function component did mount
    React.useEffect(() => {
        settAppMenuBackGroundColorMobile(bg_color)
        setterAppMenuTopBarTextColor(text_color)
        if (!isLoggedIn()) {
            tokenLogin()
        }
    }, [])
    const anchorRef = React.useRef(null)
    const [open, setOpen] = React.useState(false);
    // const [query, setQuery] = React.useState(generalSearch.query)
    // const [openSearchMobile, setOpenSearchMobile] = React.useState(false);
    const prevOpen = React.useRef(open)
    const style = headerMobileStyles()
    const handleToggle = () => {
        setOpen(prevOpen => !prevOpen);
    };

  

    const handleClose = event => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };


    const goToHome = ()=>{
        history.push("/")
    }


    const goToStarred = ()=>{
        history.push("/starred")
    }

    
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);


    return (
        <div style={{ position: "sticky", top: 0, zIndex: "10", paddingLeft: "8.5%", background: backGroundColorMobile }} >
            <div>
                <div style={{display: "flex"}}>
                <Image className="img_fit_cover" ref={anchorRef} onClick={handleToggle}
                    src={`https://amhqajbken.cloudimg.io/v7/_muv_/muvimages/muv_assets/whitepage/logo-with-bars.png?func=crop&w=${logo_width}&h=${logo_height}`}
                    
                    alt="bars" />
                    <div style={{marginLeft: "auto", marginRight: "0", paddingRight: "2em", paddingTop: "14px"}}><GeneralSearchBar/></div>      

                <div > 
                
               
                
                </div>
                </div>
                <ClickAwayListener
                    onClickAway={authModal.open ? () => { } : handleClose}
                >
                    <Popper open={open}
                        anchorEl={anchorRef.current}
                        role={undefined}
                        placement={bodyClientWidth < 500 ? "bottom-start" : "right-start"}
                        transition
                        className={style.root}
                        modifiers={{
                            flip: {
                                enabled: false,
                            },
                        }}
                        disablePortal>
                        <Grow
                            in={open}
                            style={{ transformOrigin: '0 0 0' }}
                            {...(open ? { timeout: 1000 } : {})}
                        >
                            <Paper>
                                <MenuList
                                    autoFocusItem={false}
                                    disableListWrap={true}
                                    dense={false}
                                    style={{ outline: "0px", height: logo_height, backgroundColor: backGroundColorMobile, display: bodyClientWidth > 500 ? "flex" : "", }}>


                                    <div onClick={goToHome} style={{ width: logo_width, textAlign: "center", paddingTop: "7px", paddingBottom: "7px", outline: "0", backgroundColor: backGroundColorMobile, }} >
                                        <div style={{ outline: "0" }}><Home style={{ color: textColor, fontSize: 25 }} /></div>

                                        <div style={{
                                            font: "8px/24px Futura Lt BT",
                                            color: textColor,
                                            paddingTop: 0,
                                        }}>Home
                                        </div>

                                    </div>

                                    {bodyClientWidth > 500 && appMenuBookingButtonStatus && <BookingFormHeader />}
                                    
                                    <div style={{ width: logo_width, textAlign: "center", paddingTop: "7px", paddingBottom: "7px", outline: "0", backgroundColor: backGroundColorMobile, }} >
                                        <Authorization fontColor={textColor} font={"8px/28px Futura Lt BT"} />
                                    </div>
                                    
                                    <div style={{ width: logo_width, textAlign: "center", paddingTop: "7px", paddingBottom: "7px", outline: "0", backgroundColor: backGroundColorMobile, }} >
                                        <Currency />
                                    </div>
                                    {/* <div style={{ width: logo_width, textAlign: "center", paddingTop: "7px", paddingBottom: "7px", outline: "0", backgroundColor: backGroundColorMobile, }} >
                                        <Language />
                                    </div> */}

                                    {/* {bodyClientWidth < 500 && <Divider />} */}
                                    {lengthStars > 0 && isLoggedIn() &&
                                    <div onClick={goToStarred} style={{ width: logo_width, textAlign: "center", paddingTop: "7px", paddingBottom: "7px", outline: "0", backgroundColor: backGroundColorMobile, }} >
                                        <div style={{ outline: "0" }}>
                                         <div style={{ }}><StarredIcon color={getColor()} lengthStars={lengthStars} /></div>

                                        </div>

                                        <div style={{
                                            font: "8px/24px Futura Lt BT",
                                            color: textColor,
                                            paddingTop: 0,
                                        }}>Starred
                                        </div>

                                    </div>}
                                    {bodyClientWidth < 500 && appMenuBookingButtonStatus && <BookingFormHeader />}
                                    
                                </MenuList>
                            </Paper>
                        </Grow>
                    </Popper>
                </ClickAwayListener>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        logo_width: state.Setting.appMenu.logo.width,
        logo_height: state.Setting.appMenu.logo.height,
        bodyClientWidth: state.Setting.htmlBody.bodyClientWidth,

        textColor: state.Setting.appMenu.textColor,
        backGroundColorMobile: state.Setting.appMenu.backGroundColorMobile,
        appMenuBookingButtonStatus: state.Setting.appMenu.appMenuBookingButtonStatus,

        isTop: state.Setting.screenVertical.isTop,
        booking_form_status: state.FormBank.BookingForm.status,
        stars: state.MemberActions.fetchStarSets.stars,
        authModal: state.Member.authModal,
    }
};
const mapDispatchToProps = {
    setSwitchContainer: Actions.setSwitchContainer,
    setBookingFormStatus: Actions.setBookingFormStatus,
    setBookingFormActiveTab: Actions.setBookingFormActiveTab,
    settAppMenuBackGroundColorMobile: Actions.settAppMenuBackGroundColorMobile,
    setterAppMenuTopBarTextColor: Actions.setterAppMenuTopBarTextColor,
    tokenLogin: Actions.tokenLogin,
};
export const HeaderMobile = connect(mapStateToProps, mapDispatchToProps)(HeaderMobile_)