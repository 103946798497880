import React, { Component } from 'react'

import { type_icon_components } from "./RegExValidate"

class FeatureTypeIcon extends Component {

    render() {
        const { icon, className, fill, style} = this.props
        const TypeIcon = type_icon_components[icon]
        return TypeIcon === undefined ? "" : <TypeIcon className={className} fill={fill} style={style}/>
    }
}
export default FeatureTypeIcon