import * as Actions from '../../../../redux/actions'

import { EllipsisHIcon, EllipsisVIcon, TimesIcon } from "../SVGBank"

import ActivityDuration from './ActivityDuration'
import { ActivityStartTime } from './ActivityStartTime'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import DeleteActivity from './DeleteActivity'
import Grow from '@material-ui/core/Grow'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import React from 'react'
import { connect } from 'react-redux'
import { list_container_mode } from '../RegExValidate'
import { makeStyles } from '@material-ui/core/styles'
import { member_area_router } from '../RegExValidate'
import { MoreHoriz } from '@material-ui/icons'

// import { ActivityChangeDate } from './ActivityChangeDate'


/*  inject css to menu component */
const useStyles = makeStyles(theme => ({
    root: {
        display: 'inline-block',
        position: "relative",
    },
    positionTimeline: {
        zIndex: "35",
        top: "-70px !important",
        left: "0px !important",
        /*backgroundColor: "var(--mainGreen)",*/
    },
    position: {
        zIndex: "35",
        top: "-25px !important",
        left: "-135px !important",
    },
    positionCalendar: {
        zIndex: "35",
        top: "-20px !important",
        left: "-125px !important",
    },
    backgroundSelection: {
        '&:hover': {
            backgroundColor: "var(--mainGreen)"
        },
        minHeight: "10px"
    },
    paper: {
        marginRight: theme.spacing(2),
    },

    hr: {
        width: "65%",
        height: "1px",
        display: "block",
        position: "relative",
        marginRight: "10%",
        marginTop: "5px",
        marginLeft: "25%",
        marginBottom: "5px",
        border: "1px dashed rgba(0,0,0,.1)",
    },
    timesDiv: {
        marginRight: "10px",
        marginLeft: "90px",
        display: "inline-block",
        textAlign: "right",
        cursor: "pointer",
    },
    hidden: {
        zIndex: -1,
        top: "-5000px !important",
    },
    ellipsis: {
        // backgroundColor: "var(--mainGreen)", 
        width:"135px", 
        height:"150px",
        margin: "7px",
    }
}))


/* menu list composition function component 

param  trip object

*/
const TripEllipsisTimeline_ = ({ switch_container, tripMode, format = "ellipsis_h", activity, is_mobile }) => {


    const classes = useStyles()
    const [open, setOpen] = React.useState(false)
    const [disableEllipsis, setDisableEllipsis] = React.useState(false)

    const handleEllipsis = (event) => {
        event.preventDefault()
        setOpen(prevOpen => !prevOpen)
    }
    const anchorRef = React.useRef(null)

    const handleClose = event => {
        if (!disableEllipsis) {
            if (anchorRef.current && anchorRef.current.contains(event.target)) {
                return
            }
            setOpen(false)
        }
    }

    return (
        <div className={classes.root}>
            <div
                ref={anchorRef}   className="addActivity"
                style={{ cursor: "pointer" }}
                onClick={e => handleEllipsis(e)}>
       
                {format === "ellipsis_h" &&  <div style={{
                   
                    color:tripMode === list_container_mode.calendar ? "#FFFFFF" : "#202020"
                }}> <MoreHoriz  fontSize="large" color={tripMode === list_container_mode.calendar ? "#FFFFFF" : "#202020"}/></div> }
                {format === "ellipsis_v" && <EllipsisVIcon height={'25'} fill={tripMode === list_container_mode.calendar ? "#FFFFFF" : "#C2C2C2"} />}
            </div>
            <ClickAwayListener onClickAway={handleClose}>
                <Popper
                    open={open}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    className={format === "ellipsis_h" ? (tripMode === list_container_mode.calendar ? classes.positionCalendar : classes.positionTimeline) : (tripMode === list_container_mode.calendar ? classes.positionCalendar : classes.position)}
                    transition
                    disablePortal
                    placement={is_mobile? "left-start" :"bottom-start"}
                    modifiers={{
                        flip: {
                            enabled: false,
                        },
                    }}
                >
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            className={classes.ellipsis}
                            style={{ transformOrigin: placement === 'bottom' ? 'left top' : 'left bottom' }}
                        >
                            <Paper>
                                <MenuList
                                    autoFocusItem={false}
                                    disableListWrap={true}
                                    dense={false}
                                    id="trip_ellipsis_timeline" >
                                    <div className={classes.timesDiv} onClick={handleClose}>{!is_mobile ? <TimesIcon width={15} height={15} /> : <TimesIcon width={10} height={10} />}</div>
                                    {/* <MenuItem  ><ActivityChangeDate activity={activity} from={"ellipsis"} disableEllipsis={disableEllipsis} setDisableEllipsis={setDisableEllipsis} closeEllipsis={setOpen} /></MenuItem> */}
                                    <MenuItem><ActivityStartTime activity={activity} from={"ellipsis"} disableEllipsis={disableEllipsis} setDisableEllipsis={setDisableEllipsis} closeEllipsis={setOpen} /></MenuItem>
                                    <MenuItem><ActivityDuration activity={activity} from={"ellipsis"} disableEllipsis={disableEllipsis} setDisableEllipsis={setDisableEllipsis} closeEllipsis={setOpen} /></MenuItem>
                                    <div className={classes.hr}></div>
                                    <MenuItem><DeleteActivity activity={activity} tripDestinationId={activity.tripDestinationId} fetchingStatus={false} from={"ellipsis"} closeEllipsis={setOpen} /></MenuItem>
                                </MenuList>
                            </Paper>
                        </Grow>
                    )}
                </Popper>

            </ClickAwayListener>
            
        </div>
    )
}


const mapStateToProps = state => {
    return {
        is_mobile: state.Setting.is_mobile,
        tripMode: state.Member.tripMode,
    }
}

const mapDispatchToProps = {

}


const TripEllipsisTimeline = connect(mapStateToProps, mapDispatchToProps)(TripEllipsisTimeline_)
export default TripEllipsisTimeline