import * as Actions from '../../../redux/actions';

import { Element, Link } from 'react-scroll'

import { Card } from 'react-bootstrap'
import Friends from './Friends.jsx'
import HomeFavorites from './HomeFavorites.jsx'
import HometownMap from './HometownMap.jsx'
import Influencers from '../Common/Influencers/Influencers.jsx'
import LastTrip from './LastTrip.jsx'
import { Menu } from 'semantic-ui-react'
import MuverInformation from './MuverInformation.jsx'
import Photos from './Photos.jsx'
import { PropTypes } from 'prop-types'
import React from 'react'
import TravelFavorites from './TravelFavorites.jsx'
import TripSection from '../Common/Trip/TripSection.jsx'
import UpComingTrip from './UpComingTrip.jsx'
import Videos from './Videos.jsx'
import { connect, useDispatch } from 'react-redux'
import { getHomeFavourites, getSocialPhotos, getSocialVideos, getTravelFavourites } from './social.slice';

/* home member container component */
const SocialContainer_ = ({fetchAboutMe, props, member,member_uuid, fetchSocialInfo, lang, screenSize, muver, photos, videos, pastTrips, futureTrips, homeFavourites, travelFavourites, app_menu_height, is_mobile, influencers, friends, logo_height }) => {
    const dispatch = useDispatch();
    React.useEffect(() => {
        if (props.match.params.handle !== undefined &&
            props.match.params.handle !== null &&
            props.match.params.handle !== "") {

            fetchSocialInfo(props.match.params.handle, lang)
        }
    }, [props.match.params.handle])

    React.useEffect(() => {
        if(member_uuid){
            dispatch(getSocialVideos(member_uuid))
            dispatch(getSocialPhotos(member_uuid))
            dispatch(getHomeFavourites(member_uuid))
            dispatch(getTravelFavourites(member_uuid))
            fetchAboutMe(member_uuid, lang)
        }
      }, [member_uuid]) 
  

    let margin_left = 0
    let margin_right = 0
    let margin_top = 0

    let position = "sticky"
    let counter = 0
    const menu_width = "120px"
    if (!isNaN(screenSize)) {
        margin_left = screenSize > 1200 ? (screenSize / 100 * 5) + 430 : (screenSize <= 768 ? 0 : (screenSize / 100 * 4) + 350)
        margin_right = (screenSize <= 768) ? 0 : (screenSize / 100 * 8.5)
        margin_top = (screenSize <= 768) ? 150 : 0
    }
    return (
        <div style={{ top: app_menu_height, position: "relative", marginLeft: margin_left, marginRight: margin_right, marginTop: margin_top }}>
            <div style={{ backgroundColor: "#191919" }} className=" padding_left_20 padding_right_20">
                {muver !== undefined
                    && muver !== null &&
                    <MuverInformation />}
            </div>
            <React.Fragment>
                <div style={{
                    position: position, zIndex: "3",
                    top: is_mobile ? logo_height : (-Math.ceil(-app_menu_height / 2))
                }}>
                    <Menu pointing secondary className="padding_left_20 padding_right_20" style={{ backgroundColor: "#2C2C2C" }}>
                        {member.home !== undefined &&
                            <Link to="hometown" spy={true} smooth={true} offset={screenSize < 500 ? -130 : -200} duration={1000} className="menu_link_title_text" style={{ width: menu_width }} key={++counter}><p>Hometown</p></Link>
                        }
                        {homeFavourites !== undefined
                            && homeFavourites.length > 0 &&
                            <Link to="homeFavorites" spy={true} smooth={true} offset={screenSize < 500 ? -130 : -200} duration={1300} className="menu_link_title_text" style={{ width: menu_width }} key={++counter}><p>Hometown Favorites</p></Link>
                        }
                        {pastTrips !== undefined
                            && pastTrips.length > 0
                            && pastTrips[0] !== undefined &&
                            <Link to="trips" spy={true} smooth={true} offset={screenSize < 500 ? -130 : -200} duration={1600} className="menu_link_title_text" style={{ width: menu_width }} key={++counter}><p>Trips</p></Link>
                        }
                        {photos !== undefined
                            && photos.length > 0 &&
                            <Link to="photos" spy={true} smooth={true} offset={screenSize < 500 ? -130 : -200} duration={1800} className="menu_link_title_text" style={{ width: menu_width }} key={++counter}><p>Photos</p></Link>
                        }
                        {videos !== undefined
                            && videos.length > 0 &&
                            <Link to="videos" spy={true} smooth={true} offset={screenSize < 500 ? -130 : -200} duration={2100} className="menu_link_title_text" style={{ width: menu_width }} key={++counter}><p>Videos</p></Link>
                        }
                        {travelFavourites !== undefined
                            && travelFavourites.length > 0 &&
                            <Link to="travelFavorites" spy={true} smooth={true} offset={screenSize < 500 ? -130 : -200} duration={2400} className="menu_link_title_text" style={{ width: menu_width }} key={++counter}><p>Travel Favorites</p></Link>
                        }
                        {friends !== undefined
                            && friends.length > 0 &&
                            <Link to="friends" spy={true} smooth={true} offset={screenSize < 500 ? -130 : -200} duration={2700} className="menu_link_title_text" style={{ width: menu_width }} key={++counter}><p>Friends</p></Link>
                        }
                    </Menu>
                </div>

                <div style={{ backgroundColor: "#191919" }} className="padding_left_20 padding_right_20">
                    <Card style={{ backgroundColor: "#191919" }}>
                        <Card.Body className="padding_0_mobile">
                            {futureTrips !== undefined
                                && futureTrips.length > 0
                                && futureTrips[0] !== undefined
                                && futureTrips[0].tripDestinations !== undefined
                                && futureTrips[0].tripDestinations.length > 0
                                && futureTrips[0].tripDestinations[0] !== undefined &&
                                <React.Fragment>
                                    <UpComingTrip />
                                </React.Fragment>
                            }
                            {member.home !== undefined &&
                                <React.Fragment>
                                    <hr className="line_separator margin_top_20" />
                                    <Element name="hometown" className="element">
                                        {window.google !== undefined && <HometownMap />}
                                    </Element>
                                </React.Fragment>
                            }
                            {homeFavourites !== undefined
                                && homeFavourites.length > 0 &&
                                <React.Fragment>
                                    <hr className="line_separator margin_top_20" />
                                    <Element name="homeFavorites" className="element">
                                        <HomeFavorites />
                                    </Element>
                                </React.Fragment>
                            }

                            {pastTrips !== undefined
                                && pastTrips.length > 0
                                && pastTrips[0] !== undefined &&
                                <React.Fragment>
                                    <hr className="line_separator margin_top_20" />
                                    <Element name="trips" className="element">
                                        <div className="futura_hv_bt_24_30_white margin_top_50 padding_125rem_lr_mobile">Trips</div>
                                        <LastTrip />
                                        <TripSection from={"social"} />
                                    </Element>
                                </React.Fragment>
                            }

                            {photos !== undefined
                                && photos.length > 0 && <React.Fragment>
                                    <hr className="line_separator margin_top_20" />
                                    <Element name="photos" className="element">
                                        <Photos/>
                                    </Element>
                                </React.Fragment>}

                            {videos !== undefined
                                && videos.length > 0 &&
                                <React.Fragment>
                                    <hr className="line_separator margin_top_20" />
                                    <Element name="videos" className="element">
                                        <Videos />
                                    </Element>
                                </React.Fragment>
                            }

                            {travelFavourites !== undefined
                                && travelFavourites.length > 0 &&
                                <React.Fragment>
                                    <hr className="line_separator margin_top_20" />
                                    <Element name="travelFavorites" className="element">
                                        <TravelFavorites />
                                    </Element>
                                </React.Fragment>
                            }

                            {friends !== undefined
                                && friends.length > 0 &&
                                <React.Fragment>
                                    <hr className="line_separator margin_top_20" />
                                    <Element name="friends" className="element">
                                        <Friends />
                                    </Element>
                                </React.Fragment>
                            }
                            {influencers !== undefined
                                && influencers.length > 0 &&
                                <React.Fragment>
                                    <hr className="line_separator margin_top_20" />
                                    <Influencers from={"social"} />
                                </React.Fragment>
                            }
                        </Card.Body>
                    </Card>
                </div>
            </React.Fragment>
        </div >
    )
}





SocialContainer_.propTypes = {
    member: PropTypes.object.isRequired,
}
const mapStateToProps = state => {
    return {
        member: state.Member.socialMember.member,
        muver: state.Member.socialMember.muver,
        pastTrips: state.Member.socialMember.pastTrips,
        futureTrips: state.Member.socialMember.futureTrips,
        influencers: state.Member.socialMember.influencers,
        friends: state.Member.socialMember.friends,
        homeFavourites:  state.SocialPage.homeFavourites,
        travelFavourites: state.SocialPage.travelFavourites,
        videos: state.SocialPage.videos,
        photos: state.SocialPage.photos,
        screenSize: state.Setting.htmlBody.bodyClientWidth,
        is_mobile: state.Setting.is_mobile,
        app_menu_height: state.Setting.appMenu.app_menu_height,
        logo_height: state.Setting.appMenu.logo.height,
        member_uuid: state.Member.socialMember.member_uuid
    }
};
const mapDispatchToProps = {
    fetchAboutMe: Actions.fetchAboutMe,
    fetchSocialInfo: Actions.fetchSocialInfo,
};

const SocialContainer = connect(mapStateToProps, mapDispatchToProps)(SocialContainer_)
export default SocialContainer
