import './BlogSlider.css'

import { ArrowLeftIcon, ArrowRightIcon } from "../../Common/SVGBank"
import React, { Component } from 'react'

import Slider from 'react-slick'
import { connect } from 'react-redux'

const Entities = require('html-entities').XmlEntities

const SliderArrowLeft = ({ className, to, onClick }) => (
	<button type="button" onClick={onClick} className={`button button--text button--icon ${className}`} aria-label={to}>
		<ArrowLeftIcon className="icon" icon={to} style={{
			height: "60px",
			width: "60px",
			transform: 'translate(10px, -77px)',

		}} />
	</button>
)
const SliderArrowRight = ({ className, to, onClick }) => (
	<button type="button" onClick={onClick} className={`button button--text button--icon ${className}`} aria-label={to}>
		<ArrowRightIcon className="icon" icon={to} style={{
			height: "60px",
			width: "60px",
			transform: 'translate(-10px, -77px)'
		}} />
	</button>
)
class BlogList_ extends Component {
	render() {
		const { blog, screenSize, is_mobile } = this.props
		const entities = new Entities()
		const sliderArrowRight = <SliderArrowRight />
		let heightContainer = "440"
		let heightImage = "350"
		let widthImage = "200"
		if (!isNaN(screenSize)) {
			heightContainer = screenSize * 0.82 / 4 + 100
			heightImage = is_mobile ? 150 : Math.ceil(screenSize * 0.82 / 4)
			widthImage = is_mobile ? 170 : Math.ceil(screenSize * 0.82 / 4) - 14
		}
		const display_element_length =
			screenSize > 1724 ? 4 :
				screenSize >= 1025 ? 3 :
					screenSize > 500 ? 2 : 2
		const left_element = blog && blog.length < display_element_length ? "left_element" : ""
		const infiniteValue = blog && blog.length > display_element_length ? true : false
		const arrowsValue = blog && blog.length > display_element_length ? true : false

		const setting = {
			touchMove: false,
			dots: false,
			infinite: infiniteValue,
			arrows: arrowsValue,
			speed: 500,
			lazyLoad: false,
			autoplay: false,
			slidesToShow: 4,
			slidesToScroll: 3,
			pauseOnHover: true,
			centerMode: false,
			prevArrow: <SliderArrowLeft to="prev" />,
			nextArrow: sliderArrowRight,
			responsive: [
				{
					breakpoint: 1724,
					settings: {
						slidesToShow: 4,
						slidesToScroll: 3,
					}
				},
				{
					breakpoint: 1025,
					settings: {
						touchMove: true,
						slidesToShow: 3,
						slidesToScroll: 2,
						arrows: false,
					}
				},
				{
					breakpoint: 500,
					settings: {
						touchMove: true,
						slidesToShow: 2,
						slidesToScroll: 1,
						arrows: false,
					}
				},
			]
		}
		return (
			<div>
				<div className="carousel-heading-2 text-center" style={{ font: is_mobile ? "18px/48px Futura Hv BT" : "40px/48px Futura Hv BT" }}>müvTravel Blog</div>
				<Slider {...setting} className={left_element} style={{ height: heightContainer }}>
					{blog.length > 0 && blog.map((item, item_idx) => {
						return (
							<div key={item_idx}>
								<div className="item m-2" >
									<div className="card">
										<div className="blogItems" style={{ height: heightImage, width: widthImage, backgroundImage: "url('https://amhqajbken.cloudimg.io/v7/" + item.blogImg + "?func=crop&w=" + widthImage + "&h=" + heightImage + "')" }}></div>
										<div className="overlay" onClick={() => window.open(item.blogLink)} style={{ cursor: "pointer", width: widthImage }}></div>
										<div className="text-right star-on-image "></div>
										<div style={{ width: widthImage, position: "absolute", textAlign: "center", bottom: "5px", padding: "3px", color: "white", font: "24px/26px Futura Md BT" }}>
											{entities.decode(item.blogTitle)}
										</div>
									</div>
								</div>
							</div>
						)
					})}
				</Slider>

			</div>
		)
	}
}


const mapStateToProps = (state) => {
	return {
		screenSize: state.Setting.htmlBody.bodyClientWidth,
		lang: state.Setting.lang,
		blog: state.Home.homepage.blog,
		is_mobile: state.Setting.is_mobile,
	}

}
const BlogList = connect(mapStateToProps, null)(BlogList_)
export default BlogList