import * as Actions from '../../../../redux/actions'

import {
    CalendarDuotoneIcon,
    PlaneArrivalIcon,
    PlaneDepartureIcon,
    TimesIcon,
} from "../SVGBank"

import { AsyncTypeahead } from 'react-bootstrap-typeahead'
import DatePicker from "react-datepicker"
import React from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { getAirportsListThunk } from '../../FlightsPage/services/Flights.thunks'
import moment from 'moment'

const FlightFormFlightElementRoundTrip_ = ({ departingFlight, returningFlight, setFlightsData, is_mobile, lang, error, getAirportList }) => {
    const dispatch = useDispatch()
    const airportsList = useSelector(state => state.Flights.airportsList)
    let departingDate = departingFlight.departureDateFormat &&  moment(departingFlight.departureDateFormat).toDate();
    let returningDate = returningFlight.departureDateFormat && moment(returningFlight.departureDateFormat).toDate();
   
    const [airportFrom, setAirportFrom] = React.useState(departingFlight.airportFrom)
    const [airportTo, setAirportTo] = React.useState(departingFlight.airportTo)

    const handleSelectedAirportsFrom = (airports) => {
        if (airports !== undefined) {
            setAirportFrom(airports.length > 0 ? airports[0] : null)
            setFlightsData(departingFlight, airports.length > 0 ? airports[0] : null, airportTo, departingDate)
        }
    }

    const handleSelectedAirportsTo = (airports) => {
        if (airports !== undefined) {
            setAirportTo(airports.length > 0 ? airports[0] : null)
            setFlightsData(returningFlight, airports.length > 0 ? airports[0] : null, airportFrom, returningDate)
            
        }
    }

    const handleChangeDepartureDate = (date) => {
        setFlightsData(departingFlight, airportFrom, airportTo, date)
    }
    const handleChangeReturnDate = (date) => {
        setFlightsData(returningFlight, airportTo, airportFrom, date)
    }

    const handleSearchAirports = (query) => {
        dispatch(getAirportsListThunk(query))
    }


    React.useEffect(() => {
        setFlightsData(returningFlight, airportTo, airportFrom, returningDate)
    }, [airportFrom]);
    
    React.useEffect(() => {
        setFlightsData(departingFlight, airportFrom, airportTo, departingDate)
    }, [airportTo]);
 
    return (
        <div className="row m-0 p-0 w-100 mt-3" style={{ padding: "1px", border: airportFrom !== null && airportTo !== null && airportFrom.iata === airportTo.iata ? "1px solid red" : "" }}>
            <div className="col-12 col-md-4 p-0">
                <div className="position-relative" style={{ border: airportFrom === null && error ? "1px solid red" : "" }}>
                    <AsyncTypeahead
                        id="flight-airport-from-typeahead-departing-from"
                        labelKey={(option) => {
                            return (option.city? `${option.city} (${option.iata} - ${option.airport})`:
                            `${option.iata} - ${option.airport}`)
                        }}
                        onChange={(e) => handleSelectedAirportsFrom(e)}
                        options={airportsList}
                        placeholder="From"
                        shouldSelect={true}
                        minLength={1}
                        onSearch={query => handleSearchAirports(query)}
                        renderMenuItemChildren={(option) => {
                            return (<div key={option.id} user={option} style={{
                                font:option.type === "main"? "16px/30px Futura Md BT":"",
                            paddingLeft: option.type === "sub"? 20: 0 }}> 

                                { option.city? `${option.city} (${option.iata} - ${option.airport})`:
                            `${option.iata} - ${option.airport}`
                             }
                            </div>)
                        }}
                        defaultSelected={airportFrom ? [airportFrom]: []}
                        isLoading={false}
                        delay={100}
                        clearButton={true}
                        multiple={false}
                    />
                    <div className="position-absolute" style={{ top: is_mobile ? "10px" : "14px", left: "7px" }}>
                        <PlaneDepartureIcon width={15} height={15} />
                    </div>
                </div>
            </div>
            <div className="col-12 col-md-4 p-0">

                <div className="position-relative" style={{ border: airportTo === null && error ? "1px solid red" : "" }}>
                    <AsyncTypeahead
                        id="light-airport-to-typeahead-going-to"
                        labelKey={(option) => {
                            return (option.city? `${option.city} (${option.iata} - ${option.airport})`:
                            `${option.iata} - ${option.airport}`)
                        }}
                        onChange={(e) => handleSelectedAirportsTo(e)}
                        options={airportsList}
                        placeholder="To"
                        shouldSelect={true}
                        minLength={1}
                        onSearch={query => handleSearchAirports(query)}
                        renderMenuItemChildren={(option) => {
                            return (<div key={option.id} user={option} style={{
                                font:option.type === "main"? "16px/30px Futura Md BT":"",
                            paddingLeft: option.type === "sub"? 20: 0 }}> 

                                { option.city? `${option.city} (${option.iata} - ${option.airport})`:
                            `${option.iata} - ${option.airport}`
                             }
                            </div>)
                        }}
                        isLoading={false}
                        defaultSelected={airportTo? [airportTo]: []}
                        delay={100}
                        clearButton={true}
                        multiple={false}
                    />
                    <div className="position-absolute" style={{ top: is_mobile ? "10px" : "14px", left: "7px" }}>
                        <PlaneArrivalIcon width={15} height={15} />
                    </div>
                </div>
            </div>
            <div className="col-6 col-md-2 p-0">
                <div className="position-relative" style={{ border: departingDate === "" && error ? "1px solid red" : "" }}>
                    <DatePicker
                        selected={ departingDate}
                        onChange={(date) => handleChangeDepartureDate(date)}
                        placeholderText="Departure"
                        dateFormat="yyyy/MM/dd"
                        minDate={new Date()}
                        maxDate={returningDate? new Date(returningDate):null}
                        className="form-control" />
                    <div className="position-absolute" style={{ top: is_mobile ? "12px" : "14px", left: "7px" }}>
                        <CalendarDuotoneIcon width={15} height={15} />
                    </div>
                </div>
            </div>
            <div className="col-6 col-md-2 p-0">
                <div className="position-relative" style={{ border: returningDate === "" && error ? "1px solid red" : "" }}>
                    <DatePicker
                        readOnly={departingDate ? false:true}
                        selected={returningDate}
                        onChange={(date) => handleChangeReturnDate(date)}
                        placeholderText="Return"
                        dateFormat="yyyy/MM/dd"
                        minDate={new Date(returningFlight.availableSelectDate)}
                        className="form-control" />
                    <div className="position-absolute" style={{ top: is_mobile ? "12px" : "14px", left: "7px" }}>
                        <CalendarDuotoneIcon width={15} height={15} />
                    </div>
                </div>
            </div>
        </div>

    )
}

const mapStateToProps = (state) => ({
    lang: state.Setting.lang,
    is_mobile: state.Setting.is_mobile,
})

const mapDispatchToProps = {
    getAirportList: Actions.getAirportList,
}

const FlightFormFlightElementRoundTrip = connect(mapStateToProps, mapDispatchToProps)(FlightFormFlightElementRoundTrip_)
export default FlightFormFlightElementRoundTrip
