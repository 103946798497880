import 'react-bootstrap-typeahead/css/Typeahead.css';
import "react-datepicker/dist/react-datepicker.css";
import "../../HomePage/homePage.css";

import moment from 'moment';

import * as Actions from '../../../../redux/actions';

import {
    AddressMapIcon,
    CalendarDuotoneIcon,
} from "../SVGBank"
import React, { Component } from 'react'

import { AsyncTypeahead } from 'react-bootstrap-typeahead'
import DatePicker from "react-datepicker"
import FlightForm from "./FlightForm"
import MuvFeaturedTrip from "./MuvFeaturedTrip"
import PlanTripForm from "./PlanTripForm"
import HotelSearchForm from "./HotelSearchForm"
import { PropTypes } from 'prop-types'
import { connect } from 'react-redux'

class PriceLineBookingFormElement_ extends Component {
    constructor(props) {
        super(props);
        this.state = {
            destinationSize: 1,
            changeHeader: false,
            activeTripSelection: 'round-trip',
            MultiFlightsInput: ['input-0'],
            showMoreOptionsInMultiCity: true,
            errorMessage: '',

            hotel_form_state: {
                check_in: '',
                check_out: '',
                rooms: '',
                guests: '',
                adults: '',
                type: 'hotels',
                currency: 'usd',
                city_id: '',
                city_latitude: '',
                city_longitude: '',
                children: '0',
                package: '0',
                where: ''
            },

            train_bus_form_state: {
                check_in: '',
                check_out: '',
            }
        }
    }



    _handleSearchCities = (query) => {
        this.props.getCitiesList(query);

    }

    _handleSearchAirports = (query) => {
        this.props.getAirportList(query);
    }




    handleFlightsSelection(param) {
        this.setState({
            activeTripSelection: param,
            MultiFlightsInput: ['input-0']
        })
    }

    handleSeeMoreOptions = () => {
        this.setState(prevState => ({
            showMoreOptionsInMultiCity: !prevState.showMoreOptionsInMultiCity
        }));
    }


    setSelectedCity = (selectedItem) => {
        let hotel_form_state = { ...this.state.hotel_form_state }
        if (selectedItem.length) {
            hotel_form_state.city_id = selectedItem[0].cityid_ppn;
            const city = selectedItem[0].city !== undefined ? selectedItem[0].city + ',' : ""
            const state = selectedItem[0].state !== undefined ? selectedItem[0].state + ',' : ""
            const country = selectedItem[0].country !== undefined ? selectedItem[0].country : ""
            hotel_form_state.where = city + " " + state + " " + country;;
            hotel_form_state.city_latitude = selectedItem[0].latitude;
            hotel_form_state.city_longitude = selectedItem[0].longitude;
            this.setState({
                hotel_form_state: hotel_form_state
            })
        } else {
            hotel_form_state.city_id = '';
            hotel_form_state.where = '';
            hotel_form_state.city_latitude = '';
            hotel_form_state.city_longitude = '';
            this.setState({
                hotel_form_state: hotel_form_state
            })
        }
    }





    handleChangeCheckInDate = (date) => {
        let check_in = date !== null ? date : ""
        let check_in_format = date === "" || moment(date).format('YYYY-MM-DD') === "Invalid date" ? "" : moment(date).format('YYYY-MM-DD')

        let hotel_form_state = { ...this.state.hotel_form_state }
        hotel_form_state.check_in = check_in;    // use date or formatted string?
        this.setState({
            hotel_form_state: hotel_form_state
        });
    }



    handleChangeCheckOutDate = (date) => {
        let check_out = date !== null ? date : ""
        let check_out_format = date === "" || moment(date).format('YYYY-MM-DD') === "Invalid date" ? "" : moment(date).format('YYYY-MM-DD')

        let hotel_form_state = { ...this.state.hotel_form_state }
        hotel_form_state.check_out = check_out;     // use date or formatted string?
        this.setState({
            hotel_form_state: hotel_form_state
        });
    }


    handleSetDestinations() {
        let temp = this.state.destinationSize;
        temp += 1;
        this.setState({
            destinationSize: temp
        })
    }

    handleRemoveDestinations() {
        let temp = this.state.destinationSize;
        temp -= 1;
        this.setState({
            destinationSize: temp
        })
    }


    handleRoomsChange = (event) => {
        let hotel_form_state = { ...this.state.hotel_form_state }
        hotel_form_state.rooms = event.target.value
        this.setState({
            hotel_form_state: hotel_form_state
        });
    }

    handleGuestsChange = (event) => {
        let hotel_form_state = { ...this.state.hotel_form_state }
        hotel_form_state.adults = event.target.value
        this.setState({
            hotel_form_state: hotel_form_state
        });
    }



    handleAddMultipleFlightsCities = () => {
        const newInput = `input-${this.state.MultiFlightsInput.length}`;
        if (this.state.MultiFlightsInput.length < 4) {
            this.setState(prevState => ({
                MultiFlightsInput: prevState.MultiFlightsInput.concat([newInput]),
            }));
        }
    }
    render() {
        const { searchListCities, activeTab, is_mobile, isHeader,flightMode} = this.props;
        return (
            <React.Fragment>
                <div className={"booking_form_container"}>
                    {activeTab === 'plan a trip' && <PlanTripForm
                    handleSetDestinations={()=> this.handleSetDestinations()}
                    handleRemoveDestinations={()=> this.handleRemoveDestinations()} />}
                    {activeTab === 'hotel' && <HotelSearchForm 
                    isHeader={isHeader}/>}
                    {activeTab === 'xhotel' && <form method="post" action={'http://stagingbooking.muvtravel.com/hotels'}>
                        <div className="row m-0 p-0">
                            <div className="col-12 col-md-4 mt-3 p-0">
                                <div className="position-relative">
                                    <AsyncTypeahead
                                        id="hotel-cities-typeahead"
                                        labelKey={(option) =>
                                            option.state !== undefined ? `${option.city} ${option.state} ${option.country}`
                                                : `${option.city} ${option.country}`}
                                        onChange={this.setSelectedCity}
                                        options={searchListCities}
                                        placeholder="Where"
                                        shouldSelect={true}
                                        minLength={1}
                                        onSearch={this._handleSearchCities}
                                        renderMenuItemChildren={(option) => (
                                            option.state !== undefined ? <div key={option.id} user={option} >{option.city}, {option.state}, {option.country}</div> :
                                                <div key={option.id} user={option} >{option.city}, {option.country}</div>
                                        )}
                                        isLoading={false}

                                        delay={100}
                                        clearButton={true}
                                    />
                                    <div className="position-absolute" style={{ top: is_mobile ? "10px" : "18px", left: "7px" }}>
                                        <AddressMapIcon width={15} height={15} />
                                    </div>
                                </div>
                                <input type="hidden" name="city_id" value={this.state.hotel_form_state.city_id} />
                                <input type="hidden" name="city_latitude" value={this.state.hotel_form_state.city_latitude} />
                                <input type="hidden" name="city_longitude" value={this.state.hotel_form_state.city_longitude} />
                            </div>
                            <div className="col-6 col-md-2 mt-3 p-0">
                                <div className="position-relative">
                                    <DatePicker
                                        selected={this.state.hotel_form_state.check_in}
                                        onChange={this.handleChangeCheckInDate}
                                        placeholderText="Check In"
                                        dateFormat="yyyy/MM/dd"
                                        minDate={new Date()}
                                        maxDate={new Date(this.state.hotel_form_state.check_out)}
                                        className="form-control" />

                                    <div
                                        className="position-absolute"
                                        style={{ top: is_mobile ? "12px" : "21px", left: "7px" }}>
                                        <CalendarDuotoneIcon width={15} height={15} />
                                    </div>
                                </div>
                                <input type="hidden" name="currency" value={this.state.hotel_form_state.currency} />
                                <input type="hidden" name="check_in"
                                    value={new Date(this.state.hotel_form_state.check_in).getMonth() + 1 + '/' + new Date(this.state.hotel_form_state.check_in).getDate() + '/' + new Date(this.state.hotel_form_state.check_in).getFullYear()} required={true} />
                            </div>
                            <div className="col-6 col-md-2 mt-3 p-0">

                                <div className="position-relative">
                                    <DatePicker
                                        selected={this.state.hotel_form_state.check_out}
                                        minDate={this.state.hotel_form_state.check_in !== '' ? new Date(this.state.hotel_form_state.check_in).getTime() + 86400000 : new Date()}
                                        dateFormat="yyyy/MM/dd"
                                        onChange={this.handleChangeCheckOutDate}
                                        placeholderText="Check Out"
                                        className="form-control" />

                                    <div className="position-absolute" style={{ top: is_mobile ? "12px" : "21px", left: "7px" }}>
                                        <CalendarDuotoneIcon width={15} height={15} />
                                    </div>
                                </div>
                                <input type="hidden" name="check_out"
                                    value={new Date(this.state.hotel_form_state.check_out).getMonth() + 1 + '/' + new Date(this.state.hotel_form_state.check_out).getDate() + '/' + new Date(this.state.hotel_form_state.check_out).getFullYear()} required={true} />
                            </div>

                            <div className="col-6 col-md-2 mt-3 p-0">
                                <select className="form-control pl-3" id="rooms" placeholder="Rooms" defaultValue="placeholder" onChange={this.handleRoomsChange} >
                                    <option value="placeholder" disabled>Rooms</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    <option value="6">6</option>
                                    <option value="7">7</option>
                                    <option value="8">8</option>
                                    <option value="9">9</option>
                                </select>
                                <input type="hidden" name="rooms" value={this.state.hotel_form_state.rooms} required={true} />
                            </div>
                            <div className="col-6 col-md-2 mt-3 p-0">
                                <select className="form-control pl-3" id="guests" placeholder="Guests" defaultValue="placeholder" onChange={this.handleGuestsChange} >
                                    <option value="placeholder" disabled>Guests</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    <option value="6">6</option>
                                    <option value="7">7</option>
                                    <option value="8">8</option>
                                    <option value="9">9</option>
                                </select>
                                <input type="hidden" name="adults" value={this.state.hotel_form_state.adults} required={true} />
                            </div>
                        </div>
                        <div className="extra_fields">
                            <input type="hidden" name="currency" value={this.state.hotel_form_state.currency} />
                            <input type="hidden" name="type" value={this.state.hotel_form_state.type} />
                            <input type="hidden" name="where" value={this.state.hotel_form_state.where} required={true} />
                            <input type="hidden" name="package" value={this.state.hotel_form_state.package} />
                            <input type="hidden" name="children" value={this.state.hotel_form_state.children} />
                        </div>
                        <div className="row text-right mt-3 ml-0 mr-0">
                            <div className="col-md-12 p-0">
                                <button type="submit"
                                    disabled={this.state.hotel_form_state.city_id === ''}
                                    formEncType="multipart/form-data"
                                    value="Submit as Multipart/form-data"
                                    className="btn btn-default submitBtn col-12 p-0">Search</button>
                            </div>
                        </div>
                    </form>
                    }
                    {activeTab === 'flight' && <FlightForm />}
                    {(activeTab === 'train' || activeTab === 'bus') &&
                        <div  style={{ marginTop: "19px" }}>
                            <div className="row m-0 p-0" >
                                <div className="col-md-4 mt-3 p-0">

                                    <div className="position-relative">
                                        <input type="text" className="form-control" id="from" placeholder="From" style={{ paddingLeft: "30px" }} />
                                        <div className="position-absolute" style={{ top: is_mobile ? "10px" : "14px", left: "7px" }}>
                                            <AddressMapIcon width={15} height={15} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 mt-3 p-0">

                                    <div className="position-relative">
                                        <input type="text" className="form-control" id="to" placeholder="To" style={{ paddingLeft: "30px" }} />
                                        <div className="position-absolute" style={{ top: is_mobile ? "10px" : "14px", left: "7px" }}>
                                            <AddressMapIcon width={15} height={15} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6 col-md-2 mt-3 p-0">

                                    <div className="position-relative">
                                        <DatePicker selected={this.state.train_bus_form_state.check_in}
                                            dateFormat="yyyy/MM/dd"
                                            minDate={new Date()} onChange={this.handleChangeinStartDate} placeholderText="Departure" className="form-control" />
                                        <div className="position-absolute" style={{ top: is_mobile ? "12px" : "14px", left: "7px" }}>
                                            <CalendarDuotoneIcon width={15} height={15} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6 col-md-2 mt-3 p-0">

                                    <div className="position-relative">
                                        <DatePicker selected={this.state.train_bus_form_state.check_out}
                                            dateFormat="yyyy/MM/dd"
                                            minDate={new Date(this.state.train_bus_form_state.check_out)} onChange={this.handleChangeinEndDate} placeholderText="Return" className="form-control" />
                                        <div className="position-absolute" style={{ top: is_mobile ? "12px" : "14px", left: "7px" }}>
                                            <CalendarDuotoneIcon width={15} height={15} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row col-12 m-0 p-0">
                                <div className="col-md-2 p-0 mt-3">
                                    <select className="form-control pl-3" id="rooms" placeholder="Rooms" >
                                        <option>1 passenger</option>
                                        <option>2 passengers</option>
                                        <option>3 passengers</option>
                                    </select>
                                </div>
                                <div className="col-4 p-0 mt-3">
                                 
                                </div>

                                <div className="col-6 col-md-6 p-0 mt-3">
                                    <div className="col-4 offset-md-8  p-0" >
                                        <button type="submit" className="btn btn-default submitBtn">Search</button>
                                    </div>

                                </div>
                             
                            </div>
                        </div>
                    }
                </div >
                {isHeader !== "is_header" && this.state.destinationSize < 3 && <MuvFeaturedTrip flightFormType={flightMode} />}

            </React.Fragment>
        )
    }
}

PriceLineBookingFormElement_.propTypes = {
    bodyClientWidth: PropTypes.number.isRequired,
}

const mapStateToProps = (state) => {
    return {
        bodyClientWidth: state.Setting.htmlBody.bodyClientWidth,
        bodyClientHeight: state.Setting.htmlBody.bodyClientHeight,
        flightMode: state.Flights.flightData.flightMode,
        lang: state.Setting.lang,

        activeTab: state.FormBank.BookingForm.activeTab,
        searchListCities: state.Home.search_list_cities,
        uuid: state.Member.authModal.uuid,
        is_mobile: state.Setting.is_mobile,
        activeTrip: state.FormBank.TimelineWizard.activeTrip,
        switch_container: state.Member.switch_container,
        //tripMode: state.Member.tripMode,
    }

}

const mapDispatchToProps = {
    getCitiesList: Actions.getPriceLineCitiesList,      // HomeAction.js, PL_FETCH_CITIES_LIST_SUCCESS
    //getCitiesList: Actions.getCitiesList,               // HomeAction.js, FETCH_CITIES_LIST_SUCCESS
    getAirportList: Actions.getPriceLineAirportList,    // HomeReducer.js

    setSwitchContainer: Actions.setSwitchContainer,     // MemberAction.js
}

const PriceLineBookingFormElement = connect(mapStateToProps, mapDispatchToProps)(PriceLineBookingFormElement_)

export default PriceLineBookingFormElement