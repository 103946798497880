import Rating from '@material-ui/lab/Rating';
import React from 'react'
import { connect } from 'react-redux'

const EstablishmentBasicInfo_ = ({ establishment, is_mobile }) => {
    const { generalInfo, subtypes } = establishment
    const establishmentStarRating = generalInfo.establishmentStarRating
    return (
        <div>
            <div style={{ font: "18px/36px Futura Hv BT", color: "#707070", marginBottom: "5px", }}>{generalInfo.neighborhood}</div>
            <div style={{ font: is_mobile ? "26px/26px Futura Hv BT" : "36px/36px Futura Hv BT", color: "#191919", marginBottom: "15px", }}>{generalInfo.name}</div>
            <div className="d-flex timeline_rating_color padding_left_10 padding_right_10 btn_rounded" style={{ marginBottom: "5px", }}>
                <div style={{ font: "16px/19px Futura Md BT", color: "#707070" }}><Rating name="timeline_rating" precision={0.5} max={5} value={parseFloat(establishmentStarRating)} disabled={true} /> {"(" + establishmentStarRating + ")"}</div>
            </div>
            {subtypes !== undefined && subtypes.length > 0 &&
                subtypes.map((subtype, subtype_idx) => {
                    return <div key={subtype_idx}
                        className="btn_rounded m-1 futura_md_bt_14_16_green upcoming_trip_style">
                        {subtype.subTypeTitle}</div>
                })}
        </div>
    )
}

const mapStateToProps = (state) => ({

    establishment: state.FormBank.HotelSearch.establishment,
    is_mobile: state.Setting.is_mobile,
    lang: state.Setting.lang,
    uuid: state.Member.authModal.uuid,

    switchFeatureContainer: state.FormBank.FeatureFormat,
})

const mapDispatchToProps = {

}

const EstablishmentBasicInfo = connect(mapStateToProps, mapDispatchToProps)(EstablishmentBasicInfo_)
export default EstablishmentBasicInfo
