import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import "./styles.css";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'grid',
    gridTemplateColumns: '40% 60%',
    alignItems: 'center',
    marginTop: 220,

    [theme.breakpoints.down('sm')]: {
      display: 'block',
      marginTop: 50
    },
    [theme.breakpoints.between('sm', 'md')]: {
      display: 'grid',
      gridTemplateColumns: '40% 60%',
      alignItems: 'center',
      marginTop: 100,
    },
  },
  stepDescriptionSection: {
    position: 'relative',
    width: '80%',
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  stepBackgroundImage: {
    paddingLeft: 0, 
    overflow: 'hidden',
    textAlign:'right',
    marginRight:'30%',
    marginTop:"5%",
    marginBottom:'10vw',
    [theme.breakpoints.between('sm', 'md')]: {
    },
  },
  firstCard: {
    position: 'absolute',
    top: 0,
    left: 70,
    [theme.breakpoints.down('sm')]: {
      left: 5,
      top: 40
    },
  },
  secondCard: {
    position: 'absolute',
    bottom: 160,
    left: 270,
    [theme.breakpoints.down('sm')]: {
      top: 0,
      left: 205
    },
  },
  thirdCard: {
    position: 'absolute',
    right: 130,
    top: 70,
    [theme.breakpoints.down('sm')]: {
      right: 107,
    },
    [theme.breakpoints.between('sm', 'md')]: {
      right: 10,
    },
  },
  card: {
    width: 190,
    maxWidth: 345,
    [theme.breakpoints.down('md')]: {
      width: 160,
    },
    [theme.breakpoints.down('sm')]: {
      width: 110,
    },

    "& .MuiCardContent-root": {
      padding: 10,
    },

    "& .MuiTypography-h5": {
      font: "normal normal 900 14px/16px Futura Hv BT",
      color: "#191919",
      [theme.breakpoints.down('md')]: {
        font: "normal normal 900 12px/10px Futura Hv BT",
      },
      [theme.breakpoints.down('sm')]: {
        font: "normal normal 900 8px/10px Futura Hv BT",
      },
    },

    "& .MuiTypography-colorTextSecondary": {
      font: "normal normal 600 9px/11px Futura Md BT",
      color: "#707070",
      [theme.breakpoints.down('sm')]: {
        font: "normal normal 600 6px/9px Futura Md BT",
      },
    },
    "& .MuiCardActions-root": {
      "& .MuiButton-label": {
        font: "normal normal 600 8px/10px Futura Md BT",
        color: "#19BC9B",
        [theme.breakpoints.down('sm')]: {
          font: "normal normal 600 4px/8px Futura Md BT",
        },
      },
      "& .MuiTouchRipple-root": {
        background: "#19BC9B 0% 0% no-repeat padding-box",
        borderRadius: "8px",
        opacity: 0.2,
      },
      "& .MuiButton-root": {
        minWidth: 0
      }
    },
  },
  media: {
    height: 100,
    [theme.breakpoints.down('sm')]: {
      height: 60,
    },
  },
}));

export default function InfluencerStepThree() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className="landing-step-container" 
      style={{
        marginBottom:70
      }}>
        <div className={classes.stepDescriptionSection}>
          <h1 className="step-number-influencers">3</h1>
          <div className="step-description">
            <h2 className="step-title-influencer">Gain the attention of travel brands</h2>
            <p className="futura_lt_bt_20_30"
             style={{color: '#B1B1B1'}}>
              
            müvTravel lists more 200,000 travel brands, hotels, attractions, restaurants, service providers etc. many interested to partner with travel influencers like you to promote their brand. Gain a following on müvTravel and attract their attention early. You might be pleasantly surprised.
            </p>
          </div>
        </div>
      </div>
      <div className={classes.stepBackgroundImage}>
        <div>

        <img style={{width: 'auto',height:'36vw'}} src={require('../../../assets/images/step3.jpg')}/>
        </div>

      </div>
    </div>
  );
}
