import {
    Image,
} from 'react-bootstrap'
import Rating from '@material-ui/lab/Rating'
import React from 'react'
import ReviewsDescription from "./ReviewsDescription"
import { connect } from 'react-redux'
import moment from 'moment'

const PostsUser_ = ({ open, establishment }) => {
   let reviews = establishment && establishment.reviews? establishment.reviews.reviews : []  
   //console.log(reviews)
   return (
      <div>
        { reviews && reviews.length > 0 && reviews.map((review, review_index) => {
            const updated_at = review.updated_at ? moment(review.updated_at, 'YYYY-MM-DD').format("MMM Do[,] YYYY") : ""
            const comment = review.comment ? review.comment : ""
            const author_name = review.member && review.member.muverLastName && review.member.muverFirstName? review.member.muverFirstName + " " + review.member.muverLastName : ""
            const profile_photo_url = review.member && review.member.muverAvatar && review.member.muverAvatar.fullUrl? review.member.muverAvatar.fullUrl : ""
            const rating = review.rating? parseInt(review.rating) : 3.0 

            return (
                <React.Fragment key={review_index}>
                    {(open || review_index < 3) && <React.Fragment>
                        {profile_photo_url === "" ?
                            <div style={{ display: "flex", flexDirection: "column", marginTop: "30px" }}>
                                <div style={{ flexGrow: "1", flexWrap: "wrap" }}>
                                    <div><span style={{ font: "18px/24px Futura Hv BT", color: "#707070" }}>{author_name}</span> {" - "} <span style={{ font: "14px/24px Futura Lt BT", color: "#707070", whiteSpace: "nowrap", }}>{updated_at}</span></div>
                                    <Rating name="reviews_rating" style={{
                                        color: "var(--mainGreen)", opacity: "1",
                                    }} precision={0.5} max={5} value={rating} disabled={true} />
                                </div>
                                <div style={{flexGrow:"1"}}>
                                {comment && <ReviewsDescription description={comment} boxDescription={"box_3"} fontDescription={"16px/22px Futura Md BT"} colorDescription={"#191919"} height={"90px"} width={"630px"} />}
                                </div>
                            </div>
                            :
                            <div style={{ display: "flex", flexDirection: "column", marginTop: "30px" }}>
                                <div style={{flexGrow: "1"}}>
                                <Image className=""
                                    src={profile_photo_url + "?func=crop&w=70&h=70"}
                                    alt="muver Avatar" width={"70px"} height={"70px"} />
                                </div>
                                <div style={{ marginLeft: "10px", flexGrow: "1", flexWrap: "wrap" }}>
                                    <div><span style={{ font: "18px/24px Futura Hv BT", color: "#707070" }}>{author_name}</span> {" - "} <span style={{ font: "14px/24px Futura Lt BT", color: "#707070", whiteSpace: "nowrap", }}>{updated_at}</span></div>
                                    <Rating name="reviews_rating" style={{
                                        color: "var(--mainGreen)", opacity: "1",
                                    }} precision={0.5} max={5} value={rating} disabled={true} />
                                </div>
                                <div style= {{flexGrow: "1"}}>
                                {comment !== "" && <ReviewsDescription description={comment} boxDescription={"box_3"} fontDescription={"16px/22px Futura Md BT"} colorDescription={"#191919"} height={"90px"} width={"630px"} />}
                                </div>
                            </div>}

                    </React.Fragment>}
                </React.Fragment>
            )
        })}
    </div >
    )
}

const mapStateToProps = (state) => ({
    establishment: state.Destination.establishment
})


const PostsUser = connect(mapStateToProps, null)(PostsUser_)
export default PostsUser 
