import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import React from 'react'

const ConfirmationReview = ({ closeConfirmation, isConfirmationOpen, confirmationTitle, confirmationMessage ,handleConfirmation,color}) => {
  const [open, setOpen] = React.useState(isConfirmationOpen)

  const handleClose = () => {
     if (closeConfirmation) {
        closeConfirmation() 
     } else {
        setOpen (false)
     }
  }

  const confirmed = () => {
    if (handleConfirmation) {
       handleConfirmation() 
       handleClose ()
    } else {
      handleClose ()
    }
 }

  React.useEffect(() => {
    setOpen (isConfirmationOpen)
  }, [isConfirmationOpen]) 

  let windowW = window.innerWidth
  let windowH = window.innerHeight
  
  //console.log ("w = ", windowW)
  //console.log ("h = ", windowH)

  let  top = (windowH - 400)/2  +"px"
  let  left = (windowW-600)/2  +"px"

  return (
    <React.Fragment>
        <div>
            <Dialog
            PaperProps={{
                style: { borderRadius: 0 }
              }}
               open={open}
               onClose={handleClose}
               aria-labelledby="confirmation-dialog-title"
               aria-describedby="confirmation-dialog-description"
               fullWidth
               maxWidth="xl"
               style ={{width: "500px", height:"400px",
               position: "absolute",
               left: left, 
               top: top, 
            }}
            >
            {/* <DialogTitle id="confirmation-dialog-title" style = {{textAlign: "center"}}> <span style = {{font: "30px/36px Futura Hv BT", textAlign: "center"}}>{confirmationTitle}</span></DialogTitle> */}
            <DialogContent>
                <DialogContentText id="confirmation-dialog-description" style = {{textAlign: "center",marginTop:'15px'}}>
                    <span style = {{font: "18px/24px Futura Hv BT"}}>{confirmationMessage}</span>
                </DialogContentText>
            </DialogContent>
            <DialogActions style={{margin: '0 auto',marginBottom: '13px', marginTop: '18px'}}>
                <Button onClick={handleClose} style={{ textTransform: "capitalize", marginRight: "20px",
                 width: "160px", borderRadius: '0px', marginLeft:"-5px", height:"45px", backgroundColor:"#C7C7C7",color:"var(--mainWhite)", font: "normal normal 900 14px/16px Futura Hv BT"}}>
                     Cancel
                </Button>
                 <Button onClick={confirmed} 
                    style={{textTransform: "capitalize",width: "160px", height:"45px", borderRadius: '0px', backgroundColor: color, color:"var(--mainWhite)", font: "normal normal 900 14px/16px Futura Hv BT"}}
                    autoFocus 
                 >
                     Post 
                 </Button>
            </DialogActions>
            </Dialog>
     
        </div>
      
    </React.Fragment>
  )
}

export default ConfirmationReview
