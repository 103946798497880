import "./Friends.css";

import {
  Col,
  Image,
  Row,
} from 'react-bootstrap'

import { PropTypes } from "prop-types"
import React from 'react'
import { connect } from 'react-redux'
import { image_boxing } from '../Common/RegExValidate'

/* friends component */
class Friends_ extends React.Component {



  render() {

    const { friends, screenSize } = this.props;
    const display_num = screenSize < 1025 ? 6 : 8

    const goToBlackPage = (handle) =>{
      if(handle){
       const url = "/" + handle;
       window.open(url.toLowerCase()); 
      }
   }

    return (
      <div>
        <div className="margin_bottom_50">
          <div className="d-flex margin_bottom_20 margin_top_30 margin_right_20 padding_125rem_lr_mobile">
            <div className="futura_hv_bt_24_26_white ">Friends</div>
            <div className="futura_hv_bt_16_19_green my_home_faverites_top_btn_container text-right">All</div>
          </div>
          <Row>
            {
              friends.map((friend, idx) => {
                return (
                  <React.Fragment key={idx} >
                    {idx < display_num ?
                      <Col sm={6} md={4} lg={4} xl={3} >
                        <div  style={{cursor: "pointer"}}
                        onClick={() => goToBlackPage(friend?.friendHandle)}
                        className={screenSize < 500 ? "m-2 text-center" : "m-2 text-left content_container"}>
                          <Image className="img_fit_cover rounded-circle d-inline-block"
                            src={image_boxing(friend.friendAvatar, "70", "70", "muvimages/user_avatar/armando.jpg")}
                            alt="avatar_friends" />
                          <div className={screenSize < 500 ? "margin_top_10 text_overflow_ellipsis overflow-hidden d-inline-block m-2" : "text_overflow_ellipsis overflow-hidden d-inline-block m-2 vertical_center"}>
                            <div className="futura_md_bt_18_20_white box_1">{(friend.friendFirstName !== "" && friend.friendLastName !== "") ? friend.friendFirstName.charAt().toUpperCase() + friend.friendFirstName.substring(1) + ' ' + friend.friendLastName.charAt(0).toUpperCase() + '.' : ""}</div>
                            <div className="futura_md_bt_14_16_green box_1">@{friend.friendHandle !== "" ? friend.friendHandle : ""}</div>
                          </div>
                        </div>
                      </Col>
                      : ""
                    }
                  </React.Fragment>
                )
              })
            }
          </Row>
        </div>
      </div>
    )
  }
}
Friends_.propTypes = {
  friends: PropTypes.array.isRequired,
}
const mapStateToProps = (state) => {
  return {
    friends: state.Member.socialMember.friends,
    screenSize: state.Setting.htmlBody.bodyClientWidth
  }
};

const Friends = connect(mapStateToProps, null)(Friends_)
export default Friends
