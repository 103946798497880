import * as Actions from '../../../../redux/actions'

import PlanTripFormDestinaiton from "./PlanTripFormDestinaitonElement"
import React, { useEffect, useRef } from 'react'
import { connect, useDispatch } from 'react-redux'
import { list_container_mode, member_area_router } from "../../Common/RegExValidate"
import moment from 'moment'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox';
import { useHistory } from "react-router-dom"
import { updateRoadTrip } from '../Trip/ActiveTrip.slice'
import { TrackEvent } from '../../../../utility/utility'

export const PlanTripForm_ = ({ handleRemoveDestinations, handleSetDestinations,newTrip,setTripDisplayMode, setPageYOffset, lang, uuid, is_mobile, setSwitchContainer, setBookingFormStatus, activeTrip, createMultipleDestinationTrip, switch_container }) => {

    const history = useHistory()
    const prevTripIdRef = React.useRef()
    const dispatch = useDispatch();


    const handleMap = () => {
        setTripDisplayMode(list_container_mode.map)
        setPageYOffset(0)
    }


   
  

    const [error, setError] = React.useState(false)

    // React.useEffect(() => {
    //     if (prevTripIdRef.current !== undefined && prevTripIdRef.current.length > 0 && activeTrip.tripId) {
    //     }
    //     prevTripIdRef.current = activeTrip.tripId
    // }, [activeTrip.tripId])

    React.useEffect(() => {
        if (newTrip.trip && newTrip.trip.tripID) {
            if(isRoadTrip){  ///not include starting point 
                if (destinations && destinations[1].destinationId) {
                    const url = `/destination/plan-trip/` + 1 + "/" + destinations[1].destinationId;
                    history.push(url.toLowerCase())
                }
            }else{
                if (destinations && destinations[0].destinationId) {
                    const url = `/destination/plan-trip/` + 1 + "/" + destinations[0].destinationId;
                    history.push(url.toLowerCase())
                }
            }

           
            // setBookingFormStatus(false)
            // setSwitchContainer(member_area_router.destination)
        }
    }, [newTrip.trip])

    const [destinations, setDestinations] = React.useState([{
        destinationId: "",
        startDateFormat: "",
        duration: "3",
        startDate: "",
        availableSelectDate: "",
        positionId: "d-0",
    }])

    const [isRoadTrip, setIsRoadTrip] = React.useState(false)
    const [startingPoint, setStartingPoint] = React.useState()
    
    const handleCheckChange = () => {
        setIsRoadTrip(!isRoadTrip)
        dispatch(updateRoadTrip(!isRoadTrip))
        // if (!isRoadTrip) {
        //     handleAddaDestination(true)
        // }
    }


    const AlwaysScrollToBottom = () => {
        const elementRef = useRef();
        useEffect(() => elementRef.current.scrollIntoView({ behavior: 'smooth',block: 'nearest'}));
        return <div ref={elementRef} />;
      };


    const handleAddaDestination = (roadTrip) => {
        const pid_set = ["d-0", "d-1", "d-2", "d-3", "d-4", "d-5", "d-6", "d-7", "d-8", "d-9", "d-10"]
        const used_pid = Array.from(destinations.map(d => d.positionId))
        const intersetions = pid_set.filter(pid => used_pid.indexOf(pid) === -1)

        handleSetDestinations();
        setDestinations(prevState =>
            [...prevState,
            {
                destinationId: "",
                startDateFormat: prevState !== undefined
                    && prevState[prevState.length - 1] !== undefined
                    && prevState[prevState.length - 1].startDateFormat !== ""
                    ? moment(prevState[prevState.length - 1].startDateFormat)
                        .add(Number(prevState[prevState.length - 1].duration) - 1, "days").format("YYYY-MM-DD") : "",

                duration:  roadTrip || isRoadTrip? '1': '3',
                startDate: prevState !== undefined
                    && prevState[prevState.length - 1] !== undefined
                    && prevState[prevState.length - 1].startDateFormat !== ""
                    ? new Date(moment(prevState[prevState.length - 1].startDateFormat)
                        .add(Number(prevState[prevState.length - 1].duration) - 1, "days").format("YYYY-MM-DD") + " 00:00") : "",
                availableSelectDate: prevState !== undefined
                    && prevState[prevState.length - 1] !== undefined
                    && prevState[prevState.length - 1].startDateFormat !== ""
                    ? new Date(moment(prevState[prevState.length - 1].startDateFormat)
                        .add(Number(prevState[prevState.length - 1].duration) - 1, "days").format("YYYY-MM-DD") + " 00:00") : "",
                positionId: intersetions[0] !== undefined ? intersetions[0] : "",
            }
            ]
        )
    }

    const setDestinationsData = (destination_id, date, duration, pid, available_select_date,destination) => {
        let start_date = date !== null ? date : ""
        let start_date_format = date === "" || moment(date).format('YYYY-MM-DD') === "Invalid date" ? "" : moment(date).format('YYYY-MM-DD')
        let destination_duration = duration > 0 ? duration : "0"
        let sort_destinations_id = []
        let sort_destinations_is_push = false
        destinations.map(d => {
            if (d.positionId === pid) { sort_destinations_is_push = true }
            return (
                sort_destinations_is_push ? sort_destinations_id.push(d.positionId) : ""
            )
        })
     
        let selected_destination = {
            destinationId: destination_id,
            startDateFormat: start_date_format,
            duration: destination_duration,
            startDate: start_date,
            availableSelectDate: available_select_date,
            positionId: pid,
        }
        
        setDestinations(prevState =>
            [...prevState.map((d) => {
                if (d.positionId === pid) {
                    return selected_destination
                } else if (d.positionId !== pid && sort_destinations_id.find(sd => sd === d.positionId) !== undefined) {
                    start_date = start_date_format === "" ? "" : new Date(moment(start_date_format)
                        .add(Number(destination_duration - 1), "days").format("YYYY-MM-DD 00:00"))
                    start_date_format = start_date_format === "" ? "" : moment(start_date_format)
                        .add(Number(destination_duration - 1), "days").format("YYYY-MM-DD")
                    destination_duration = d.duration
                    return {
                        destinationId: d.destinationId,
                        startDateFormat: start_date_format,
                        duration: d.duration,
                        startDate: start_date,
                        availableSelectDate: start_date,
                        positionId: d.positionId,
                    }
                } else {
                    return d
                }
            })]
        )
    }

    const removeAddedDestination = (destination) => {
        handleRemoveDestinations()
        let isFirstDestination = false
        if (destinations !== undefined && destinations.length > 0 && destinations[0] !== undefined
            && destinations[0].positionId === destination.positionId && destinations[1] !== undefined) {
            isFirstDestination = true
        }
        const subtract_duration = destination.duration > 0 ? destination.duration : "0"
        let sort_destinations_id = []
        let sort_destinations_is_push = false
        destinations.map(d => {
            if (d.positionId === destination.positionId) { sort_destinations_is_push = true }
            return (
                sort_destinations_is_push ? sort_destinations_id.push(d.positionId) : ""
            )
        })

        setDestinations(destinations.filter(d => d.positionId !== destination.positionId))
        setDestinations(prevState =>
            [...prevState.map((d) => {
                if (d.positionId === destination.positionId) {
                    return d
                } else if (d.positionId !== destination.positionId && sort_destinations_id.find(sd => sd === d.positionId) !== undefined) {
                    const start_date = d.startDateFormat === "" ? "" : new Date(moment(d.startDateFormat)
                        .subtract(Number(subtract_duration - 1), 'days')
                        .format("YYYY-MM-DD 00:00"))
                    const start_date_format = d.startDate === "" ? "" : moment(d.startDate)
                        .subtract(Number(subtract_duration - 1), 'days')
                        .format("YYYY-MM-DD")
                    return {
                        destinationId: d.destinationId,
                        startDateFormat: start_date_format,
                        duration: d.duration,
                        startDate: start_date,
                        availableSelectDate: isFirstDestination ? "" : start_date,
                        positionId: d.positionId,
                    }
                } else {
                    return d
                }
            })]
        )
    }
    const handlePlanTripForm = () => {
        setError(true)
        const destinations_selected = [...destinations.filter(d => d.destinationId !== "").map(d => {
            return {
                destinationId: d.destinationId,
                startDateFormat: d.startDateFormat,
                duration: d.duration,
                startDate: d.startDate,
            }
        })]
        if (destinations_selected.length > 0) {
      


            if(destinations_selected.length === 1){
                const url = `/destination/plan-trip/` + 1 + "/" + destinations_selected[0].destinationId;
                history.push(url.toLowerCase())
            }
            else{

            const dataToCreateTrip = {
                destinations: destinations_selected,
                isRoadTrip: isRoadTrip,
            } 

            if(isRoadTrip){  ///not include starting point 
                if(   destinations_selected.length > 1){
                    setError(false)
                    destinations_selected.shift();
                    createMultipleDestinationTrip(uuid, lang, dataToCreateTrip, activeTrip.tripId,startingPoint)
                }   
            }else{
                setError(false)
                createMultipleDestinationTrip(uuid, lang, dataToCreateTrip, activeTrip.tripId,startingPoint)
            }
            TrackEvent('SEARCH',"Plan a trip",JSON.stringify(dataToCreateTrip))

            }
            
         
        }
    }

    /*const [isRoadTrip, setIsRoadTrip] = React.useState(false)
    const handleCheckChange = () => {
          console.log(`PlanTripForm isRoadTrip before = ${isRoadTrip}`)
          setIsRoadTrip (!isRoadTrip)
          console.log(`PlanTripForm isRoadTrip after = ${isRoadTrip}`)
    }*/

    //console.log(`PlanTripForm switch_container = ${switch_container}`)
    return (
        <React.Fragment>
            <div className="row m-0 p-0" style={{ marginTop: "30px" }}>

                {/* {switch_container === "Starred" &&
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={isRoadTrip}
                                onChange={handleCheckChange}
                                name="road_trip_checkbox_popup"
                                color="default"
                            />
                        }
                        label="Road Trip"
                        style={{ marginLeft: "auto", marginTop: "-30px", marginRight: 0, marginBottom: "-10px" }}
                    />} */}
                {
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={isRoadTrip}
                                onChange={handleCheckChange}
                                name="road_trip_checkbox_homepage"
                                style={{
                                 color: 
                                 "var(--mainGreen)" 
                                }}
                            />
                        }
                        label="Road Trip"
                        style={{ marginLeft: "auto", marginTop: "-25px", marginRight: 10, marginBottom: "-10px", color: "var(--mainGreen)" }}
                    />}

            <div style={{width: '105%', marginTop:18, marginRight:-10, maxHeight:destinations.length > 4 ? 235: 'none', overflowY:destinations.length > 4 ? 'scroll': 'revert', paddingLeft: 30, marginLeft: -30}}>
                {destinations.length > 0 && destinations.map((destination, index) => {
                    return (
                        <div key={destination.positionId} >


                            <PlanTripFormDestinaiton index={index} setStartingPoint={setStartingPoint} destination={destination} setDestinationsData={setDestinationsData} error={error} lengthDestiantions={destinations.length} removeAddedDestination={removeAddedDestination} isRoadTrip={isRoadTrip} />

                        </div>
                    )
                })}
                  <AlwaysScrollToBottom />
            </div>
                <div className="col-12 col-md-10 p-0 mt-3" style={{ backgroundColor: "rgba(255, 255, 255, 0.5)" }}>
                    <button className="btn btn-default col-12 addDestinationBtn p-0" style={{ color: destinations.length >= 4 && !isRoadTrip ? "#212529" : "#19bc9b" }} onClick={() => handleAddaDestination(false)} disabled={isRoadTrip ? false : destinations.length >= 4}>
                        + Add a Destination
                    </button>
                </div>
                {is_mobile &&
                    <div className="col-12 p-0 mt-3">
                        <select className="form-control pl-3" placeholder="Travelers" defaultValue={"2"} style={{ cursor: "pointer" }}>
                            <option value={"1"}>1 traveler</option>
                            <option value={"2"}>2 travelers</option>
                            <option value={"3"}>3 travelers</option>
                            <option value={"4"}>4 travelers</option>
                            <option value={"5"}>5 travelers</option>
                            <option value={"6"}>6 travelers</option>
                            <option value={"7"}>7 travelers</option>
                            <option value={"8"}>8 travelers</option>
                            <option value={"9"}>9 travelers</option>
                        </select>
                    </div>}
                <div className="col-12 col-md-2 p-0">
                    <button type="submit" disabled={false} className="btn btn-default submitBtn col-12 mt-3 p-0" onClick={() => handlePlanTripForm()}>
                        { destinations?.length > 1? "Plan a trip": "Explore Now"}
				    </button>
                </div>
            </div>
            {!is_mobile && <div className="row m-0 p-0">
                <div className="col-md-2 p-0 mt-3">
                    <select className="form-control pl-3" placeholder="Travelers" defaultValue={"2"} style={{ cursor: "pointer" }}>
                        <option value={"1"}>1 traveler</option>
                        <option value={"2"}>2 travelers</option>
                        <option value={"3"}>3 travelers</option>
                        <option value={"4"}>4 travelers</option>
                        <option value={"5"}>5 travelers</option>
                        <option value={"6"}>6 travelers</option>
                        <option value={"7"}>7 travelers</option>
                        <option value={"8"}>8 travelers</option>
                        <option value={"9"}>9 travelers</option>
                    </select>
                </div>
            </div>}
        </React.Fragment>
    )

}


const mapStateToProps = (state) => ({
    lang: state.Setting.lang,
    uuid: state.Member.authModal.uuid,
    newTrip: state.ActiveTrip,
    activeTrip: state.FormBank.TimelineWizard.activeTrip,
    switch_container: state.Member.switch_container,
})

const mapDispatchToProps = {
    createMultipleDestinationTrip: Actions.createMultipleDestinationTrip,
    setBookingFormStatus: Actions.setBookingFormStatus,
    setTripDisplayMode: Actions.setTripDisplayMode,
    setPageYOffset: Actions.setPageYOffset,
    setSwitchContainer: Actions.setSwitchContainer,

}

const PlanTripForm = connect(mapStateToProps, mapDispatchToProps)(PlanTripForm_)
export default PlanTripForm
