import * as Actions from '../../../redux/actions'

import { PlusCircleIcon } from '../Common/SVGBank'
import React from 'react'
import { URL_REPLACE } from "../Common/RegExValidate"
import { connect } from 'react-redux'
import { list_container_mode } from '../Common/RegExValidate'
import {
    useHistory
} from "react-router-dom"
import { TrackEvent } from '../../../utility/utility'

const LinkDestination_ = ({ from = "", link_style, setTripDisplayMode, title, setPageYOffset, destination_id }) => {
    const history = useHistory()
    const goesToDestinationPage = () => {
        TrackEvent('HOME',"Click discover destination",destination_id)
        const url = "/destination/" + title.replace(URL_REPLACE, "-") +  "/" + 1 + "/" + destination_id;
        history.push(url.toLowerCase())
        setPageYOffset(0)
        setTripDisplayMode(list_container_mode.timeline)

    }
    return (
        <React.Fragment>
            {from === "" ?
                <div style={link_style} onClick={() => goesToDestinationPage()}>
                    {title}
                </div>
                :
                <div style={link_style} onClick={() => goesToDestinationPage()}>
                    <PlusCircleIcon width={25} height={25} style={{ marginRight: "5px" }} fill={"#19BC9B"} />{title}
                </div>}
        </React.Fragment>
    )
}

const mapStateToProps = (state) => ({
    switch_container: state.Member.switch_container,

})

const mapDispatchToProps = {
    setPageYOffset: Actions.setPageYOffset,
    setTripDisplayMode: Actions.setTripDisplayMode,
}

const LinkDestination = connect(mapStateToProps, mapDispatchToProps)(LinkDestination_)
export default LinkDestination
