import * as Actions from '../../../../redux/actions'

import React, { Component } from 'react'

import { connect, useDispatch, useSelector } from 'react-redux'
import { useParams,useLocation } from 'react-router-dom';
import { ScrollTopLazyLoading } from '../../Common/ScrollTopLazyLoading';
import { Backdrop, CircularProgress, Fab, Grid, makeStyles } from '@material-ui/core';
import { AngleUpIcon } from '../../Common/SVGBank';
import { useHistory } from "react-router-dom";
import { URL_REPLACE } from "../../Common/RegExValidate";
import moment from 'moment';
import Loader from 'react-loader-spinner'
import { lookUpHotelThunk,CancelHotelThunk } from '../PaymentPage/payment.thunk';
import {HotelBookingStatus, HotelBookingSupport, HotelConfirmationDetails, HotelDetails} from "./HotelManagementComponent"
import { HotelRoomList } from './HotelManagementList';
import { HotelPaymentDetails } from './HotelPaymentDetails';

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));


const HotelManagementContainer_ = ({ lang, uuid,bodyClientWidth, pageYOffset, is_mobile, timelineWizardStatus,activeTrip }) => {
    const search = useLocation().search;
    const query = new URLSearchParams(search);
    let booking_id = query.get('booking_id');
    let email = query.get('email');
    let imageUrl = query.get('imageUrl');

    const classes = useStyles();
    const history = useHistory();
    const nearByDestination  = useSelector(state => state.Flights.nearByDestination);
    const selectedFlight = useSelector(state => state.Flights.selectedFlight);
    const selectedFlightMode = useSelector(state => state.Flights.selectedFlightMode);

    let arrivalCode = selectedFlight && selectedFlight.slice_data ? selectedFlight.slice_data[selectedFlight.slice_data.length -1].arrival.airport.code : '';
    if (selectedFlightMode === 'round_trip') {
         arrivalCode = selectedFlight && selectedFlight.slice_data ? selectedFlight.slice_data[selectedFlight.slice_data.length -1].departure.airport.code : '';
    }
    const activeTripId = activeTrip?.tripId;
    let width;
    let marginLeft;
    const [saveToTrip, setSaveToTrip] = React.useState(false);
    const [loading, setLoading] = React.useState(true);
    const [bookingFlight, setBookFlight] = React.useState(false);
  

    React.useEffect(() => {
        if(email && booking_id){
                const data ={
                    email:email,
                    booking_id:booking_id
                }
                dispatch(lookUpHotelThunk(data)).then((res =>{
                    setLoading(false)
                }));  
        }
        setTimeout(() => {
            setLoading(false)
        }, 4000);

    }, [booking_id,email])

    let positionScrollToTopLazyLoading = ""
    if (!isNaN(is_mobile)) {
        width = is_mobile ? '100%' : '60%';
        marginLeft = is_mobile ? '0%' : timelineWizardStatus ? '31%' : '23%';
        positionScrollToTopLazyLoading = is_mobile ? "" : (timelineWizardStatus ? "10%" : "10%")
    }
    const dispatch = useDispatch()

    const lookUpData = useSelector(state => state.HotelBooking.lookUpData);

    const authModal = useSelector(state => state.Member.authModal);
 
   
    const cancelHotel = () =>{
        const  ppn_bundle = lookUpData?.actions?.cancel;
        if(ppn_bundle){
            const data ={
                ppn_bundle:ppn_bundle,
                email:email,
                booking_id:booking_id
            }
            dispatch(CancelHotelThunk(data)).then((res =>{
            }));
         }   
    }
    

    const customer  =  lookUpData?.itinerary_details?.customer;
    const hotel  =  lookUpData?.hotel;
    const itinerary  =  lookUpData?.itinerary;
    const itinerary_details  =  lookUpData?.itinerary_details;
    const cancellation_details  =  lookUpData?.cancellation_details;
    const allowCancel =  lookUpData?.actions?.cancel;
    const price_details  =  lookUpData?.price_details;
    const important_information =  lookUpData?.important_information;
    const booking_status = lookUpData?.itinerary?.status &&  lookUpData?.itinerary?.status === "Cancelled" ? "cancelled": "confirmed";
    return (
        <div>
        {loading ? <Loader
                    type="Rings"
                    color="#19BC9B"
                    className="se-pre-con"
                    height={150}
                    width={150}
                    style={{
                        backgroundColor: "#FFFFFF"
                    }} /> : 
            <div>
            <div style={{
                position: "relative",
                width: width,
                marginLeft: marginLeft,
                paddingBottom: 50,
                marginTop: 50
            }}>
                <div id="back_to_top_flights"></div>
 
                <Grid container className="flightRecap">
            
                {booking_status && hotel && <HotelBookingStatus hotelName={hotel?.hotel_name} booking_status={booking_status}/>}

                {hotel && <HotelDetails hotel={hotel} imageUrl={imageUrl} />}

                {itinerary && <HotelConfirmationDetails itinerary={itinerary} itinerary_details={itinerary_details} 
                hotel={hotel}/>}


                {itinerary_details && <HotelRoomList itinerary_details={itinerary_details} 
                hotel={hotel} cancellation_details={cancellation_details} booking_status={booking_status}
                cancelHotel={cancelHotel} allowCancel={allowCancel}/>}

                {lookUpData && <HotelPaymentDetails lookUpData={lookUpData} />}
                {lookUpData && <HotelBookingSupport lookUpData={lookUpData} />}
                </Grid>
                <Backdrop className={classes.backdrop} open={bookingFlight} >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </div>



            <ScrollTopLazyLoading targetId={"#back_to_top_flights"} rightPosition={positionScrollToTopLazyLoading}>
                <Fab size="small" aria-label="scroll back to top" style={{
                    outline: "0px",
                    backgroundColor: '#19BC9B',
                }}>
                    <AngleUpIcon fill={"var(--mainWhite)"} style={{ marginLeft: "3px" }} />
                </Fab>
            </ScrollTopLazyLoading>
        </div>}
        
        </div>
    )
}


const mapStateToProps = state => {
    return {
        lang: state.Setting.lang,
        activeTrip: state.FormBank.TimelineWizard.activeTrip,
        is_mobile: state.Setting.is_mobile,
        lazyLoading: state.FormBank.HotelSearch.lazyLoading,
        hotelSearchForm: state.FormBank.HotelSearch.hotel_form_state,
        bookingForm: state.FormBank.BookingForm,
        bodyClientWidth: state.Setting.htmlBody.bodyClientWidth,
        pageYOffset: state.Setting.screenVertical.pageYOffset,
        switchFeatureContainer: state.FormBank.FeatureFormat,
        timelineWizardStatus: state.FormBank.TimelineWizard.status,
        uuid: state.Member.authModal.uuid,
    }
}

const mapDispatchToProps = {
    setTripDisplayMode: Actions.setTripDisplayMode,
    setPageYOffset: Actions.setPageYOffset
}

const HotelManagementContainer = connect(mapStateToProps, mapDispatchToProps)(HotelManagementContainer_)
export default HotelManagementContainer
