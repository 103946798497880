import * as Actions from '../../../redux/actions';

import React, { Component } from 'react';
import { ThemeProvider, useTheme, createMuiTheme } from '@material-ui/core/styles';

import { RightArrowIcon } from '../Common/SVGBank';
import { connect } from 'react-redux';
import { landing_router, member_area_router } from "../Common/RegExValidate";
import LandingStepOne from './LandingStepOne.jsx';
import LandingStepTwo from './LandingStepTwo.jsx';
import LandingStepThree from './LandingStepThree.jsx';
import LandingStepFour from './LandingStepFour.jsx';
import { useHistory } from "react-router-dom"



/* top bar component */
const TopBarOnboarding_ = ({screenSize, setSwitchProfileContainer}) => {
        const history = useHistory()

        const navigateToRegister = () => {
            history.push("/register")
        }


        let topImageHeight = ""
        const screen_at = 500
        if (!isNaN(screenSize)) {
            topImageHeight = screenSize < screen_at ? screenSize / 1.8 : screenSize / 2.1
        }
        let containerH = screen_at;
        if (topImageHeight !== undefined) {
            containerH = topImageHeight + "px";
        }
        
        let tripImage = "https://amhqajbken.cloudimg.io/v7/_muv_/muvimages/muv_assets/whitepage/topbar_landing.png?func=crop&w=" + screenSize + "&h=" + topImageHeight
        return (
            <div className="margin_bottom_50">
                <div className="position-relative z_index_1">
                    <div className="top_bar_container" style={{ height: containerH, backgroundImage: "url('" + require("../../../assets/images/landing.png") + "')", backgroundRepeat: "no-repeat",backgroundSize:"cover" }}></div>
                    <div className="mask_block" style={{ height: containerH }}></div>
                    <div className="z_index_3 mask_landing_title_btn">
                        <div className="futura_md_bt_70_100 text_main_white" >
                            <div>JOIN</div>
                            <div>THE</div>
                            <div>MÜVMENT</div>
                      
                            <div
                                className="join_muvment_button top_bar_container margin_top_10"
                                style={{ cursor: "pointer" }}
                                onClick={() => { navigateToRegister() }}
                                >
                             
                                <div className="futura_md_bt_18_26 vertical_center text_main_white" >
                                    Join the müvment  <RightArrowIcon width={screenSize > 1025 ? 19 : 10} height={screenSize > 1025 ? 19 : 10} />
                                </div>
                            </div>
                         
                        </div>
                    </div>
                </div>
                <div className="margin_top_40">
                    <div className="futura_hv_bt_48_48 text-center">Dream. Plan. Share. Inspire</div>
                    <div className="futura_lt_bt_20_30 text-center" style={{ marginLeft: "20%", marginTop:50, marginRight: "20%", color:'#B1B1B1' }}>Do you like to travel but hate being a tourist? Are you looking to know "where locals go", discover off-the-beaten path journeys and learn out about influencers and like-minded travellers itineraries. Then the müvment is for you. As a müver, you will be able to get inspired, dream, plan, book, connect, all in one place, and then enjoy a perfect trip, share and inspire.</div>
                </div>
                <LandingStepOne />
                <LandingStepTwo />
                <LandingStepThree />
                <LandingStepFour />
            </div>
        )
    }



const mapStateToProps = (state) => ({
    screenSize: state.Setting.htmlBody.bodyClientWidth,
})

const mapDispatchToProps = {
    setSwitchProfileContainer: Actions.setSwitchProfileContainer,
};
export const TopBarOnboarding = connect(mapStateToProps, mapDispatchToProps)(TopBarOnboarding_)
