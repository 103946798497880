import {
  CLEAN_SELECTED_DESTINATIONS_FEATURE,
  CLEAN_SELECTED_SUBTYPES_FEATURE,
  FETCH_API_ERROR_FEATURE,
  FETCH_FEATURE_LIST_OFFSET_ZERO_FEATURE,
  FETCH_FEATURE_SEARCH_LIST_FEATURE,
  FETCH_FEATURE_SUBTYPES_FEATURE,
  FETCH_FEATURE_TYPES_FEATURE,
  FETCH_RELATIVE_DESTINATIONS_FEATURE,
  FETCH_SORTING_OPTION_FEATURE,
  MERGE_FEATURE_LIST_OFFSET_ZERO_FEATURE,
  MERGE_ALL_FEATURE_LIST_OFFSET_ZERO_FEATURE,
  CLEAR_FEATURE_LIST_OFFSET_ZERO_FEATURE,
  SELECTED_FEATURE_SEARCH_FEATURE,
  SELECTED_SORTING_OPTION_FEATURE,
  SET_FEATURE_ID_FEATURE,
  SET_LIST_DISPLAY_FORMAT_FEATURE,
  SET_LOADING_FEATURE_LIST_OFFSET_FEATURE,
  SET_TOP_IMAGE_FEATURE,
  UPDATE_SELECTED_DESTINATIONS_FEATURE,
  UPDATE_SELECTED_SUBTYPES_FEATURE,
  UPDATE_SELECTED_TYPES_FEATURE,
} from "../interfaces/types";

const initialState = {
  feature: {
    activeId: 0,
    featureList: [],
    offset:8,
    loading: {
      selectedTypes: [],
      selectedDestinations: [],
      selectedSubtypes: [],
      searchQuery: "",
      selectedSortingOption: null,
    },
    sortingOptions: [],
    featureSearchList: [],
    types: [],
    subtypes: [],
    destinations: [],
    topImage: null,
    featureListDisplayFormat: "Grid",
    error: false,
  },
};

/* display activity reducer */
export default (state = initialState, action) => {
  switch (action.type) {
    case SET_FEATURE_ID_FEATURE:
      return {
        ...state,
        feature: {
          ...state.feature,
          activeId: action.active_id,
        },
      };
    case SELECTED_SORTING_OPTION_FEATURE:
      return {
        ...state,
        feature: {
          ...state.feature,
          loading: {
            ...state.feature.loading,
            selectedSortingOption: action.selected_sorting_option,
          },
        },
      };
    case SELECTED_FEATURE_SEARCH_FEATURE:
      return {
        ...state,
        feature: {
          ...state.feature,
          loading: {
            ...state.feature.loading,
            searchQuery: action.search_query,
          },
        },
      };
    case SET_TOP_IMAGE_FEATURE:
      return {
        ...state,
        feature: {
          ...state.feature,
          topImage: action.top_image,
        },
      };
    case FETCH_SORTING_OPTION_FEATURE:
      return {
        ...state,
        feature: {
          ...state.feature,
          sortingOptions: action.sorting_options,
        },
      };
    case FETCH_FEATURE_SEARCH_LIST_FEATURE:
      return {
        ...state,
        feature: {
          ...state.feature,
          featureSearchList: action.feature_search_list,
        },
      };
    case FETCH_FEATURE_TYPES_FEATURE:
      return {
        ...state,
        feature: {
          ...state.feature,
          types: action.types,
        },
      };
    case SET_LIST_DISPLAY_FORMAT_FEATURE:
      return {
        ...state,
        feature: {
          ...state.feature,
          featureListDisplayFormat: action.container_format,
        },
      };
    case FETCH_FEATURE_SUBTYPES_FEATURE:
      return {
        ...state,
        feature: {
          ...state.feature,
          subtypes: action.subtypes,
        },
      };
    case FETCH_RELATIVE_DESTINATIONS_FEATURE:
      return {
        ...state,
        feature: {
          ...state.feature,
          destinations: action.destinations,
        },
      };
    case FETCH_API_ERROR_FEATURE:
      return {
        ...state,
        feature: {
          ...state.feature,
          error: action.api_error,
        },
      };
    case UPDATE_SELECTED_SUBTYPES_FEATURE:
      return {
        ...state,
        feature: {
          ...state.feature,
          loading: {
            ...state.feature.loading,
            selectedSubtypes: action.subtypes,
          },
        },
      };
    case UPDATE_SELECTED_DESTINATIONS_FEATURE:
      return {
        ...state,
        feature: {
          ...state.feature,
            offset: 0,
          loading: {
            ...state.feature.loading,
            selectedDestinations: action.destinations,
          },
        },
      };
    case UPDATE_SELECTED_TYPES_FEATURE:
      return {
        ...state,
        feature: {
          ...state.feature,
          offset: 0,
          loading: {
            ...state.feature.loading,
            selectedTypes: action.types,
          },
        },
      };
    case CLEAN_SELECTED_SUBTYPES_FEATURE:
      return {
        ...state,
        feature: {
          ...state.feature,
          loading: {
            ...state.feature.loading,
            selectedSubtypes: [],
          },
        },
      };
    case CLEAN_SELECTED_DESTINATIONS_FEATURE:
      return {
        ...state,
        feature: {
          ...state.feature,

          loading: {
            ...state.feature.loading,
            selectedDestinations: [],
          },
        },
      };
    case FETCH_FEATURE_LIST_OFFSET_ZERO_FEATURE:
      return {
        ...state,
        feature: {
          ...state.feature,
          featureList: action.feature_list,
        },
      };
    case MERGE_ALL_FEATURE_LIST_OFFSET_ZERO_FEATURE:
      return {
        ...state,
        feature: {
          ...state.feature,
          featureList: state.feature.featureList.concat(action.data.feature_list),
          offset: action.data.offset,
        },
      };
    case MERGE_FEATURE_LIST_OFFSET_ZERO_FEATURE:
      return {
        ...state,
        feature: {
          ...state.feature,
          featureList: state.feature.featureList.map((feature) => {
            if (feature.id !== action.type_id) {
              return feature;
            }
            return {
              ...feature,
              features: feature.features.concat(action.establishments),
            };
          }),
        },
      };
    case CLEAR_FEATURE_LIST_OFFSET_ZERO_FEATURE:
      return {
        ...state,
        feature: {
          ...state.feature,
          featureList: state.feature.featureList.map((feature) => {
            if (feature.id !== action.type_id) {
              return feature;
            }
            return {
              ...feature,
              features: action.establishments,
            };
          }),
        },
      };
    case SET_LOADING_FEATURE_LIST_OFFSET_FEATURE:
      return {
        ...state,
        feature: {
          ...state.feature,
          types: state.feature.types.map((type) => {
            if (type.typeID !== action.type_id) {
              return type;
            }
            return {
              ...type,
              clickLazyLoading: {
                ...type.clickLazyLoading,
                offset: action.offset,
              },
            };
          }),
        },
      };
    default:
      return {
        ...state,
      };
  }
};
