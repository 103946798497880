import React, { Component } from 'react'

import HeaderLanding from '../Header/HeaderLanding.jsx';
import { Image } from "react-bootstrap"
import Profile from '../Common/Profile/Profile';
import ScrollToTop from '../Common/ScrollToTop.jsx';
import { connect } from 'react-redux'

class ProfileSection_ extends Component {
    render() {
        const { screenSize } = this.props;
        let leftImageHeight = 0
        let leftImageWidth = 0
        let col_at = "col-6"
        const screen_at = 1200

        if (!isNaN(screenSize)) {
            leftImageWidth = screenSize < screen_at ? screenSize : screenSize / 2
            leftImageHeight = screenSize < screen_at ? screenSize / 4 : window.innerHeight
            col_at = screenSize < screen_at ? "col-12" : "col-6"
        }
        const random = Math.floor(Math.random() * 5 + 1)
        let leftImage = "https://amhqajbken.cloudimg.io/v7/_muv_/muvimages/muv_assets/whitepage/landing/landing_" + random + ".png?func=crop&w=" + leftImageWidth + "&h=" + leftImageHeight

        return (
            <div>
                <HeaderLanding />
                <div className="row m-0">
                    <div className={"p-0 " + col_at}>
                        {
                            screenSize < screen_at ?
                                <div className="img_fit_cover" style={{ height: leftImageHeight, backgroundImage: "url('" + leftImage + "')", backgroundRepeat: "no-repeat" }}></div>
                                :
                                <div style={{ height: leftImageHeight }}>
                                    <Image className="img_fit_cover" src={leftImage}
                                        style={{
                                            top: "0px",
                                            position: "fixed",
                                        }} />
                                </div>
                        }
                    </div>
                    <div className={"p-0 " + col_at}>
                        <Profile />
                    </div>
                    <ScrollToTop />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    screenSize: state.Setting.htmlBody.bodyClientWidth,
})

const mapDispatchToProps = {
}


const ProfileSection = connect(mapStateToProps, mapDispatchToProps)(ProfileSection_)
export default ProfileSection
