import { Col, Image, Row } from "react-bootstrap"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { PropTypes } from "prop-types"
import React from 'react'
import { ReformatTripRangeDate } from '../Common/DateTimeFormatHandler'
import Slider from 'react-slick'
import { URL_REPLACE } from "../Common/RegExValidate"
import { connect } from 'react-redux'
import { faCircle } from '@fortawesome/free-regular-svg-icons'
import { image_boxing } from "../Common/RegExValidate"
import { useHistory } from "react-router-dom"

const LastTrip_ = ({ lastTrips, lang }) => {
  const history = useHistory()
  const goesTripPage = (tripId, tripName) => {
    const url = "/trip/" + tripName.replace(URL_REPLACE, "-") + "/" + tripId;
    history.push(url.toLowerCase())
  }
  let range_date
  let settings
  let lastTrip
  if (lastTrips && lastTrips[0] !== undefined) {
    lastTrip = lastTrips[0]
    range_date = ReformatTripRangeDate(lastTrip.tripStartDate, lastTrip.tripDaysCount)
    settings = {
      dots: false,
      infinite: false,
      speed: 500,
      autoplay: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      pauseOnHover: true,
      appendDots: dots => (
        <div
          style={{
            top: "260px",
            left: "-10%",
          }}>
          <ul style={{ margin: "0px" }}> {dots} </ul>
        </div>
      ),
      customPaging: i => (
        <div
          style={{
            width: "10px",
            color: "white",
            cursor: "pointer",
          }}
        >
          {i = <FontAwesomeIcon icon={faCircle} size="1x" />}
        </div>
      )
    }
  }
  return (
    <div className="margin_left_20 margin_right_50 margin_top_30">
      {lastTrips && lastTrips[0] !== undefined ?
        <div>
          <div className="futura_md_bt_24_26_white padding_125rem_lr_mobile">Last Trip</div>
          <Row>
            <Col sm={12} md={12} lg={12} xl={8} className="margin_top_20">
              {lastTrip.tripPhotoGallery !== undefined && lastTrip.tripPhotoGallery.length === 0 &&
                <div onClick={() => goesTripPage(lastTrip.tripID, lastTrip.tripName)} style={{ cursor: "pointer" }}>
                  <Image className="rounded img_fit_cover over-follow" style={{ width: "100%" }}
                    src={lastTrips[0].tripHeaderImage !== undefined ? image_boxing(lastTrips[0].tripHeaderImage, 750, 300) : ""} />
                </div>
              }
              {lastTrip.tripPhotoGallery !== undefined && lastTrip.tripPhotoGallery.length > 0 &&
                <Slider {...settings}>
                  {lastTrip.tripPhotoGallery.map((gallery, idx) => {
                    return (
                      <div onClick={() => goesTripPage(lastTrip.tripID, lastTrip.tripName)} style={{ cursor: "pointer" }}><Image key={idx} className="rounded img_fit_cover" src={"https://amhqajbken.cloudimg.io/v7/" + gallery.photoPath + "?func=crop&w=750&h=300"} /></div>
                    )
                  })}
                </Slider>
              }
            </Col>
            <Col sm={12} md={12} lg={12} xl={4} className="margin_top_20 margin_left_10_last_trip padding_125rem_lr_mobile">
              <div className="futura_md_bt_14_16_grey margin_top_10">{range_date}</div>
              <div className="futura_hv_bt_18_20_white margin_top_10" onClick={() => goesTripPage(lastTrip.tripID, lastTrip.tripName)} style={{ cursor: "pointer" }}>{lastTrip.tripName}</div>
              <div className="futura_lt_bt_16_18_grey margin_top_20">{lastTrip.tripDescription}</div>
            </Col>
          </Row>
        </div> : ""}
    </div>
  )
}


LastTrip_.propTypes = {
  lastTrips: PropTypes.array.isRequired,
}
const mapStateToProps = (state) => {
  return {
    lastTrips: state.Member.socialMember.lastTrip,
    lang: state.Setting.lang,
  }
}

const LastTrip = connect(mapStateToProps, null)(LastTrip_)
export default LastTrip
