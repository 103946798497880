import "./MuverInformation.css"

import {
  Card,
  Col,
  Row
} from 'react-bootstrap'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { PropTypes } from "prop-types"
import React from 'react'
import { connect } from 'react-redux'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
import { faAngleUp } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'

/* muver information component  */
const MuverInformation_ = ({ muver, followers, destinationInterests,aboutMeTextField }) => {
  const [open, setOpen] = React.useState(false)

  const destinationInterestsNameSets = destinationInterests !== undefined && destinationInterests.length > 0 && Array.from(new Set(destinationInterests.map(a => a.interestName)))

  return (
    <Card className="bg-black text-white mb-2" >
      <Card.Body className="pb-2">
        <div className="about_title_container">
          <div className="about_title">About </div>
          <div className="about_title_value" >@{muver.muverHandle !== undefined && muver.muverHandle}</div>
        </div>
        <div className="about_desc mt-4 mb-4" style={{ font: "16px/18px Futura Lt BT" }}>{aboutMeTextField && aboutMeTextField?.aboutMe !== undefined && aboutMeTextField.aboutMe}</div>
        <Row>
          {muver.gender !== undefined && <Col sm={12} md={6} lg={5} xl={3}>
            <label className="text_title mr-2">Gender: </label>
            <span className="text_content"> {muver?.gender?.title}</span>
          </Col>}
          {muver.muverLivingIn !== undefined && <Col sm={12} md={6} lg={7} xl={3}>
            <label className="text_title mr-2">Living in: </label>
            <span className="text_content"> {muver.muverLivingIn}</span>
          </Col>}
          {destinationInterestsNameSets !== undefined && destinationInterestsNameSets.length &&
            <Col sm={12} md={12} lg={12} xl={6}>
              <label className="text_title mr-2">Destinations: </label>
              <span className="text_content">
                {destinationInterestsNameSets.map((name, index) => { return (<span key={index} style={{ font: "16px/18px Futura Lt BT", paddingRight: "5px", }}>{index !== destinationInterestsNameSets.length - 1 ? name + ", " : name + ""}</span>) })}</span>
            </Col>
          }
          {muver.muverBirthday !== undefined && muver.muverBirthday !== null && <Col sm={12} md={6} lg={5} xl={3}>
            <label className="text_title mr-2">Birthday: </label>
            <span className="text_content"> {moment(muver.muverBirthday).format('MMM Do YYYY')}</span>
          </Col>}
          {muver.muverLineOfWork !== undefined && muver.muverLineOfWork !== "" &&
            muver.muverLineOfWork !== null && <Col sm={12} md={6} lg={7} xl={6}>
              <label className="text_title mr-2">Line of Work: </label>
              <span className="text_content">{muver.muverLineOfWork}</span>
            </Col>}
        </Row>

        {!open ?
          <React.Fragment>
            <hr className="bg-dark mb-2" />
            <div className="text-center">
              <div className="d-inline-flex p-2 justify-content-center see_more_about_container" onClick={() => setOpen(true)}>
                <p className="futura_hv_bt_16_19_green m-0 p-0" > See More</p>
                <FontAwesomeIcon icon={faAngleDown} size="1x" className="text_muv ml-4 mt-1" />
              </div>

            </div>
          </React.Fragment>
          :
          <React.Fragment>
            <div className="ex_about_bar">
              <Row>
                {muver.muverBanner !== undefined && muver.muverBanner !== "" && <Col md={12}>
                  <label className="text_title mr-2">Banner: </label>
                  <span className="text_content">{muver.muverBanner}</span>
                </Col>}
                {followers !== undefined && followers.length > 0 &&
                  <Col md={12}>
                    <label className="text_title mr-2">Followers: </label>
                    <span className="text_content">
                      {followers.map((follower, index) => { return (<span key={index} style={{ font: "16px/18px Futura Lt BT", paddingRight: "5px", }}>{index !== followers.length - 1 ? (follower.followingFirstName !== "" && follower.followingLastName !== "" ? follower.followingFirstName.charAt().toUpperCase() + follower.followingFirstName.substring(1) + ' ' + follower.followingLastName.charAt().toUpperCase() + '.' : '') + ", " : (follower.followingFirstName !== "" && follower.followingLastName !== "" ? follower.followingFirstName.charAt().toUpperCase() + follower.followingFirstName.substring(1) + ' ' + follower.followingLastName.charAt().toUpperCase() + '.' : '') + ""}</span>) })}</span>
                  </Col>
                }
              </Row>
            </div>
            <hr className="bg-dark mb-2" />
            <div className="text-center">
              <div className="d-inline-flex p-2 justify-content-center see_more_about_container" onClick={() => setOpen(false)}>
                <p className="futura_hv_bt_16_19_green m-0 p-0" > See Less</p>
                <FontAwesomeIcon icon={faAngleUp} size="1x" className="text_muv ml-4 mt-1" />
              </div>
            </div>
          </React.Fragment>
        }
      </Card.Body>
    </Card >
  )

}

MuverInformation_.propTypes = {
  muver: PropTypes.object.isRequired,
}
const mapStateToProps = (state) => {
  return {
    muver: state.Member.socialMember.muver,
    aboutMeTextField: state.FormBank.aboutMeTextField,
    followers: state.Member.socialMember.followers,
    destinationInterests: state.Member.socialMember.destinationInterests,
  }
}


const mapDispatchToProps = {
}

const MuverInformation = connect(mapStateToProps, mapDispatchToProps)(MuverInformation_)
export default MuverInformation
