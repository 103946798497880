import * as Actions from '../../../redux/actions'
import EstablishmentReviews from '../Common/Establishment/EstablishmentReviews'
import React from 'react'
import { connect, useSelector } from 'react-redux'
import "./ReviewContainer.css"
import ReviewsUser from "./ReviewsUser"

const ReviewContainer_ = ({ props, sliderImages,  setEstablishmentId, is_mobile, establishment, logo_height, app_menu_height, lang, fetchEstablishment, fetchPhotoGalleryEstablishment, fetchExperiencesEstablishment, fetchNeighborhoodEstablishment, fetchGoogleAndYelpReviewsEstablishment }) => {
    
  
    const headerHeight = (-Math.ceil(-app_menu_height / 2))
    const menuOffset = -headerHeight - 90
    const experiencesExisted = establishment && establishment.experiences && establishment.experiences.length > 0
    const neighborhoodExisted = establishment && establishment.neighborhood 

    return (
        <React.Fragment>
            <div className="reviewContainer">
                {/* <div className="title">
                      REVIEWS
                </div> */}
                <div  style={{ font: "30px/36px Futura Hv BT", color: "#191919", marginBottom: "30px" }}>
                      Reviews
                </div>

             
                <div className="reviews">
                    <div className="user">
                        <ReviewsUser/>
                    </div>
                    <div className="cloud">
                        <EstablishmentReviews/>
                    </div>
                </div>

                
            </div>
        </React.Fragment >
    )
}


const mapStateToProps = state => {
    return {
        logo_height: state.Setting.appMenu.logo.height,
        sliderImages: state.Destination.establishment.sliderImages,
        lang: state.Setting.lang,

        is_mobile: state.Setting.is_mobile,
        app_menu_height: state.Setting.appMenu.app_menu_height,
        screenSize: state.Setting.htmlBody.bodyClientWidth,

        establishment: state.Destination.establishment,
    }
}

const mapDispatchToProps = {
    fetchEstablishment: Actions.fetchEstablishment,
    fetchPhotoGalleryEstablishment: Actions.fetchPhotoGalleryEstablishment,
    fetchExperiencesEstablishment: Actions.fetchExperiencesEstablishment,
    fetchNeighborhoodEstablishment: Actions.fetchNeighborhoodEstablishment,
    fetchGoogleAndYelpReviewsEstablishment: Actions.fetchGoogleAndYelpReviewsEstablishment,


    setEstablishmentId: Actions.setEstablishmentId,
}

const ReviewContainer = connect(mapStateToProps, mapDispatchToProps)(ReviewContainer_)
export default ReviewContainer
