import BarsSubType from './BarsSubType'
import Clips from "./Clips"
import Establishments from './Establishments'
import Features from './Features'
import { Progress } from '../Common/Progress'
import React from 'react'
import { SearchBar } from './SearchBar'
import { SortingBar } from './SortingBar'
import TripSection from '../Common/Trip/TripSection.jsx'
import Trips from './Trips'
import Types from './Types'
import { connect } from 'react-redux'
import { features_router } from "../Common/RegExValidate"
import { member_area_router } from "../Common/RegExValidate"
import BucketList from './BucketList'

const DestinationListContainer_ = ({ lang, is_mobile, switch_container, noProgress, subtypes, suggestedTrips, switchFeatureContainer, timelineStatus, app_menu_height, }) => {
    let padding_l = 15
    let padding_r = 15


    if (!isNaN(is_mobile)) {
        padding_l = is_mobile ? "1" : (timelineStatus ? "31" : "15")
        padding_r = is_mobile ? "1" : (timelineStatus ? "15" : "15")
    }


    return (<React.Fragment>

        { switch_container === member_area_router.memberAreaTrip ?

            <div>
                <div style={{ flexGrow: 1 }}>
                    <div style={{
                        paddingLeft: padding_l + "%",
                        paddingRight: padding_r + "%",
                        position: "relative",
                        zIndex: "2",
                        backgroundColor: "#2C2C2C",
                        paddingTop: "15px",
                    }}>
                        {suggestedTrips.length > 0 && <TripSection from={"establishment"} />}
                    </div>
                    <div style={{
                        marginLeft: padding_l + "%",
                        paddingRight: padding_r + "%",
                        paddingBottom: "10px",
                        position: is_mobile ? "" : "sticky",
                        top: is_mobile ? "" : (-Math.ceil(-app_menu_height / 2)),
                        backgroundColor: "#2C2C2C",
                        zIndex: "3",
                    }}>

                        <Features lang={lang} />
                        <Types lang={lang} />
                        <div className="d-flex margin_top_10">
                            {subtypes !== undefined && subtypes.length > 0 && <BarsSubType />}
                            {subtypes !== undefined && subtypes.length > 0 ? <Clips /> : <div style={{ flexGrow: "1" }}></div>}
                            {!is_mobile && <SearchBar />}
                            {!is_mobile && <SortingBar />}
                        </div>
                        {is_mobile && <SearchBar />}
                        {is_mobile && <SortingBar />}

                    </div>
                    <div style={{
                        paddingLeft: padding_l + "%",
                        paddingRight: padding_r + "%",
                        paddingBottom: "10px",
                    }}>
                        {switchFeatureContainer.featureId === features_router.places.featureId && <Establishments />}
                        {switchFeatureContainer.featureId === features_router.trips.featureId && <Trips />}
                        {switchFeatureContainer.featureId === features_router.bucket_list.featureId && <BucketList />}
                        {noProgress && <Progress />}
                    </div>
                </div>
            </div> :
            <div>
                <div style={{ flexGrow: 1 }}>
                    <div style={{
                        paddingLeft: padding_l + "%",
                        paddingRight: padding_r + "%",
                        position: "relative",
                        zIndex: "2",
                        backgroundColor: "var(--mainWhite)",
                        paddingTop: "15px",
                    }}>
                        {suggestedTrips.length > 0 && <TripSection from={"establishment"} />}
                    </div>
                    <div style={{
                        marginLeft: padding_l + "%",
                        paddingRight: padding_r + "%",
                        paddingBottom: "10px",
                        position: is_mobile ? "" : "sticky",
                        top: is_mobile ? "" : (-Math.ceil(-app_menu_height / 2)),
                        backgroundColor: "#FFFFFF",
                        zIndex: "3",
                    }}>

                        <Features lang={lang} />
                        <Types lang={lang} />
                        <div className="d-flex margin_top_10">
                            {subtypes !== undefined && subtypes.length > 0 && <BarsSubType />}
                            {subtypes !== undefined && subtypes.length > 0 ? <Clips /> : <div style={{ flexGrow: "1" }}></div>}
                            {!is_mobile && <SearchBar />}
                            {!is_mobile && <SortingBar />}
                        </div>
                        {is_mobile && <SearchBar />}
                        {is_mobile && <SortingBar />}

                    </div>
                    <div style={{
                        paddingLeft: padding_l + "%",
                        paddingRight: padding_r + "%",
                        paddingBottom: "10px",
                    }}>
                        {switchFeatureContainer.featureId === features_router.places.featureId && <Establishments />}
                        {switchFeatureContainer.featureId === features_router.trips.featureId && <Trips />}
                        {switchFeatureContainer.featureId === features_router.bucket_list.featureId && <BucketList />}
                        {noProgress && <Progress />}
                    </div>
                </div>
            </div>} 
    </React.Fragment>
    )
}

const mapStateToProps = (state) => {
    return {
        timelineStatus: state.FormBank.TimelineWizard.status,
        is_mobile: state.Setting.is_mobile,
        lazyLoading: state.Destination.destination.lazyLoading,
        switchFeatureContainer: state.FormBank.FeatureFormat,
        noProgress: state.Destination.destination.lazyLoading.noProgress,
        suggestedTrips: state.Destination.destination.suggestedTrips,
        app_menu_height: state.Setting.appMenu.app_menu_height,
        screenHeight: state.Setting.screenSize.height,
        logo_width: state.Setting.appMenu.logo.width,
    
        subtypes: state.Destination.destination.subtypes,
        lang: state.Setting.lang,
        switch_container: state.Member.switch_container,
    }
}

const mapDispatchToProps = {
}

const DestinationListContainer = connect(mapStateToProps, mapDispatchToProps)(DestinationListContainer_)
export default DestinationListContainer
