    import * as Actions from '../../../redux/actions'

    import { Element, Link } from 'react-scroll'

    import About from './About'
    import Amenities from './Amenities'
    import Policies from './Policies'
    import Experiences from './Experiences'
    import Rooms from './Rooms'
    import Neighborhood from './Neighborhood'
    import Breadcrumb from './Breadcrumb'
    import Contact from './Contact'
    import EstablishmentBasicInfo from './EstablishmentBasicInfo'
    import ReviewContainer from "./ReviewContainer"
    //import EstablishmentReviews from '../Common/Establishment/EstablishmentReviews'
    import { Menu } from 'semantic-ui-react'
    import React from 'react'
    import TopBarSlider from './TopBarSlider'
    import { connect, useSelector } from 'react-redux'
    import HotelSearchFormEmbedded from "../Common/BookingForm/HotelSearchFormEmbedded"
    
    import { makeStyles } from '@material-ui/core/styles'
    import CircularProgress from '@material-ui/core/CircularProgress'

    const EstablishmentContainer_ = ({ props, sliderImages,  setHotelEstablishmentId, is_mobile, establishment, 
        logo_height, app_menu_height, lang, fetchHotelEstablishment, fetchHotelRooms, fetchPhotoGalleryEstablishment, fetchExperiencesEstablishment, 
        hotelSearchForm, fetchGoogleAndYelpReviewsEstablishment, setHotelSearchState, timelineStatus,
        }) => {
        const [menu, setMenu] = React.useState("rooms")
        var searchParams = new URLSearchParams(document.location.search.substring(1));
        
        searchParams.forEach((value, key) => {
            //console.log(value, key);
        });
        
        const selectedCurrency = useSelector(state => state.Setting.currency.selected.shortCode);
        const fullCurrency =  selectedCurrency ? selectedCurrency.toUpperCase(): "CAD";

        const city_id = searchParams.get("city_id")
        const check_in = searchParams.get("check_in")
        const check_out = searchParams.get("check_out")
        const rooms = searchParams.get("rooms")
        const adults = searchParams.get("guests")
        const city_latitude = searchParams.get("city_lat")
        const city_longitude = searchParams.get("city_lng")
        const ppn_bundle = searchParams.get("ppn_bundle")        
        const where = searchParams.get("where")!==null ? searchParams.get("where").replaceAll("_", " "):""

        //console.log("setHotelSearchState",city_id, check_in, check_out, rooms, adults, where);
        
        /* fetch destination */
        React.useEffect(() => {
            if (props.match.params.establishmentPricelineID !== undefined &&
                props.match.params.establishmentPricelineID !== null &&
                props.match.params.establishmentPricelineID !== "") {
                setHotelSearchState({city_id, check_in, check_out, rooms, adults, where, city_latitude, city_longitude})
                fetchHotelEstablishment(props.match.params.establishmentPricelineID, ppn_bundle, {city_id, check_in, check_out, rooms, adults})
                //fetchGoogleAndYelpReviewsEstablishment(props.match.params.establishmentPricelineID, lang)
                fetchHotelRooms(props.match.params.establishmentPricelineID, ppn_bundle, {city_id, check_in, check_out, rooms, adults},fullCurrency)
                //fetchPhotoGalleryEstablishment(props.match.params.establishmentPricelineID, lang)
                //fetchExperiencesEstablishment(props.match.params.establishmentPricelineID, lang)
                //fetchNeighborhoodEstablishment(props.match.params.establishmentPricelineID, lang)
                setHotelEstablishmentId(props.match.params.establishmentPricelineID)
            }
        }, [props.match.params.establishmentPricelineID])
        const breadcrumb = establishment.breadcrumb
        const generalInfo = establishment.generalInfo
        let padding_l = 15.5
        let padding_r = 1

    if (!isNaN(is_mobile)) {
            padding_l = is_mobile ? "1" : (timelineStatus ? "29.5" : "15.5")
            // padding_r = is_mobile ? "1" : (timelineStatus ? "1" : "19")
        }
        
        const headerHeight = (-Math.ceil(-app_menu_height / 2))
        const menuOffset = -headerHeight - 90
        const experiencesExisted = establishment && establishment.experiences && establishment.experiences.length > 0
        const neighborhoodExisted = establishment && establishment.neighborhood 

        const useStyles = makeStyles({
            element: {
                border: "2px solid rgba(232, 232, 232, 1)",
                padding: "27px",
                paddingRight: "0px",
                marginTop: "25px",
            },
            elementTitle: {
                color: "rgba(0, 0, 0, 1)",
                font: "24px/28px Futura Hv BT",
                marginBottom: "20px",
            },
            elementBody: {
                color: "rgba(146, 146, 146, 1)",
                font: "16px/20px Futura Lt BT",
                marginBottom: "20px",
            }
          });
        
        const classes = useStyles();

        const hotelReviews = useSelector(state => state.HotelBooking.reviews);
    const reviewData = hotelReviews?.results;
    const reviewCount = hotelReviews?.data?.review_count;
    const hasReviews = reviewCount && reviewData;

        return (
            <React.Fragment>

                <div style={{ position: "relative" ,  paddingLeft: padding_l + "%",
                            paddingRight: padding_r + "%",}}>
                    <div style={{ maxWidth: "1200px", }}>

                        <div style={{
                            position: "relative",
                            zIndex: "4",
                            backgroundColor: "var(--mainWhite)",
                            paddingTop: "15px",
                            paddingBottom: "15px",
                        }}>
                            {hotelSearchForm!==undefined && hotelSearchForm.check_in!=="" &&
                                <div className="background-img-row">
                                <div className={"booking_form_container"}>
                                    <HotelSearchFormEmbedded embedForm="hotel"/> 
                                </div>
                            </div>}
                                            
                        </div>

                        <div style={{
                        }}>
                            {sliderImages !== undefined && sliderImages.length > 0 && <TopBarSlider timelineStatus={timelineStatus} setMenu={setMenu} paddingLeft={Number(padding_l)} paddingRight={Number(padding_r)} />}

                            {breadcrumb !== undefined && breadcrumb.length > 0 && <Breadcrumb />}

                        </div>

                        <div style={{
                            position: "sticky", zIndex: "3",
                            top: is_mobile ? logo_height : headerHeight,
                        }}>
                            {!is_mobile &&<Menu pointing secondary style={{ position: "relative", backgroundColor: "#FFFFFF", borderBottom: "10px solid #F8F8F8", paddingTop: "60px", }}>
                            {
                                    <Link to="rooms" spy={true} smooth={true} offset={menuOffset} duration={1000} style={{
                                        font: "18px/18px Futura Md BT",
                                        letterSpacing: "0",
                                        backgroundColor: "initial",
                                        fontWeight: "bold",
                                        opacity: "1",
                                        textAlign: "center",
                                        height: "40px",
                                        padding: "0px 20px",
                                        width:is_mobile? "90px": "200px",
                                        position: "absolute",
                                        bottom: "-10px",
                                        color: menu === "rooms" ? "var(--mainGreen)" : "#191919",
                                        cursor: menu === "rooms" ? "" : "pointer",
                                        borderBottom: menu === "rooms" ? "10px solid var(--mainGreen)" : "10px solid #F8F8F8",
                                    }} ><p style={{
                                        verticalAlign: "middle"
                                    }} onClick={() => setMenu("rooms")}
                                    > Rooms</p></Link>
                                }
                                {
                                    <Link to="about" spy={true} smooth={true} offset={menuOffset} duration={1000} style={{
                                        font: "18px/18px Futura Md BT",
                                        letterSpacing: "0",
                                        backgroundColor: "initial",
                                        fontWeight: "bold",
                                        opacity: "1",
                                        textAlign: "center",
                                        height: "40px",
                                        padding: "0px 20px",
                                        left: is_mobile? "90px":"200px",
                                        width:is_mobile? "90px": "200px",
                                        position: "absolute",
                                        bottom: "-10px",
                                        color: menu === "about" ? "var(--mainGreen)" : "#191919",
                                        cursor: menu === "about" ? "" : "pointer",
                                        borderBottom: menu === "about" ? "10px solid var(--mainGreen)" : "10px solid #F8F8F8",
                                    }} ><p style={{
                                        verticalAlign: "middle"
                                    }} onClick={() => setMenu("about")}
                                    > Area</p></Link>
                                }
                                {
                                    <Link to="amenities" spy={true} smooth={true} offset={menuOffset} duration={1000} style={{
                                        font: "18px/18px Futura Md BT",
                                        letterSpacing: "0",
                                        backgroundColor: "initial",
                                        fontWeight: "bold",
                                        opacity: "1",
                                        textAlign: "center",
                                        height: "40px",
                                        padding: "0px 20px",
                                        left: is_mobile? "180px":"400px",
                                        width:is_mobile? "90px": "200px",
                                        position: "absolute",
                                        bottom: "-10px",
                                        color: menu === "amenities" ? "var(--mainGreen)" : "#191919",
                                        cursor: menu === "amenities" ? "" : "pointer",
                                        borderBottom: menu === "amenities" ? "10px solid var(--mainGreen)" : "10px solid #F8F8F8",
                                    }} ><p style={{
                                        verticalAlign: "middle"
                                    }} onClick={() => setMenu("amenities")}
                                    > Amenities</p></Link>
                                }
                                {
                                    <Link to="reviews" spy={true} smooth={true} offset={menuOffset} duration={1000} style={{
                                        font: "18px/18px Futura Md BT",
                                        letterSpacing: "0",
                                        backgroundColor: "initial",
                                        fontWeight: "bold",
                                        opacity: "1",
                                        textAlign: "center",
                                        height: "40px",
                                        width:is_mobile? "90px": "200px",
                                        position: "absolute",
                                        bottom: "-10px",
                                        left: is_mobile? "270px":"800px",
                                        padding: "0px 20px",
                                        color: menu === "reviews" ? "var(--mainGreen)" : "#191919",
                                        cursor: menu === "reviews" ? "" : "pointer",
                                        borderBottom: menu === "reviews" ? "10px solid var(--mainGreen)" : "10px solid #F8F8F8",
                                    }} ><p style={{
                                        verticalAlign: "middle"
                                    }} onClick={() => setMenu("reviews")}>Reviews</p></Link>
                                }
                                {
                                    <Link to="policies" spy={true} smooth={true} offset={menuOffset} duration={1000} style={{
                                        font: "18px/18px Futura Md BT",
                                        letterSpacing: "0",
                                        backgroundColor: "initial",
                                        fontWeight: "bold",
                                        opacity: "1",
                                        textAlign: "center",
                                        height: "40px",
                                        width:is_mobile? "50px": "200px",
                                        position: "absolute",
                                        bottom: "-10px",
                                        left: "600px",
                                        padding: "0px 20px",
                                        color: menu === "policies" ? "var(--mainGreen)" : "#191919",
                                        cursor: menu === "policies" ? "" : "pointer",
                                        borderBottom: menu === "policies" ? "10px solid var(--mainGreen)" : "10px solid #F8F8F8",
                                    }} ><p style={{
                                        verticalAlign: "middle"
                                    }} onClick={() => setMenu("policies")}>Policies</p></Link>
                                }
                                {
                                experiencesExisted && <Link to="experiences" spy={true} smooth={true} offset={menuOffset} duration={1000} style={{
                                        font: "18px/18px Futura Md BT",
                                        letterSpacing: "0",
                                        backgroundColor: "initial",
                                        fontWeight: "bold",
                                        opacity: "1",
                                        textAlign: "center",
                                        height: "40px",
                                        width:is_mobile? "50px": "200px",
                                        position: "absolute",
                                        bottom: "-10px",
                                        left: "1000px",
                                        padding: "0px 20px",
                                        color: menu === "experiences" ? "var(--mainGreen)" : "#191919",
                                        cursor: menu === "experiences" ? "" : "pointer",
                                        borderBottom: menu === "experiences" ? "10px solid var(--mainGreen)" : "10px solid #F8F8F8",
                                    }} ><p style={{
                                        verticalAlign: "middle"
                                    }} onClick={() => setMenu("experiences")}>Experiences</p></Link>
                                }
                            </Menu>}
                        </div>
                        {false && generalInfo !== undefined && generalInfo !== null && <div style={{
                            marginTop: "20px"
                        }}>

                            <EstablishmentBasicInfo />

                        </div>} 

                        {!(generalInfo !== undefined && generalInfo !== null && generalInfo.amenities !== undefined && generalInfo.amenities !== null && generalInfo.amenities !== "" ) && <CircularProgress
                            disableShrink
                            size={15}
                            style={{
                                color: "#6A9EEC",
                                textAlign: "right",
                                marginTop: "15px",
                                marginRight: "5px",
                            }} />}               

                        {generalInfo !== undefined && generalInfo !== null  && <div style={{
                        }}>
                            {generalInfo !== undefined && generalInfo !== null && generalInfo.room_data&& 
                            <Element name="rooms" className={classes.element}>
                                <Rooms />
                            </Element>}
                        </div>}

                        {generalInfo !== undefined && generalInfo !== null && generalInfo.about !== null && generalInfo.about !== "" && 
                        generalInfo?.about && <div style={{
                        }}>
                            <Element name="about" className={classes.element}>
                                <About />
                            </Element>
                        </div>}

                        {generalInfo !== undefined && generalInfo !== null && generalInfo.amenities !== undefined && generalInfo.amenities !== null && generalInfo.amenities !== "" && <div style={{
                        }}>
                            <Element name="amenities" className={classes.element}>
                                <Amenities />
                            </Element>
                        </div>}

                        {generalInfo !== undefined && generalInfo !== null && generalInfo.amenities !== undefined && generalInfo.amenities !== null && generalInfo.amenities !== "" && <div style={{
                        }}>
                            <Element name="policies" className={classes.element}>
                                <Policies />
                            </Element>
                        </div>}

                        { <div style={{
                            }}>
                            <Element name="reviews" className={classes.element}>
                                <ReviewContainer/>
                            </Element>
                        </div>}

                        {experiencesExisted && <div style={{
                        }}>
                            <Element name="experiences" className={classes.element}>
                                <Experiences/>
                            </Element>
                        </div>}

                        {neighborhoodExisted && <div style={{
                        }}>
                            <Element name="neighborhood" className={classes.element}>
                                <Neighborhood/>
                            </Element>
                        </div>}

                        <div style={{height:50}} />
                        
                    </div>
                    

                </div>

            </React.Fragment >
        )
    }


    const mapStateToProps = state => {
        return {
            logo_height: state.Setting.appMenu.logo.height,
            sliderImages: state.FormBank.HotelSearch.establishment.sliderImages,
            lang: state.Setting.lang,

            timelineStatus: state.FormBank.TimelineWizard.status,
            is_mobile: state.Setting.is_mobile,
            app_menu_height: state.Setting.appMenu.app_menu_height,
            screenSize: state.Setting.htmlBody.bodyClientWidth,

            establishment: state.FormBank.HotelSearch.establishment,
            hotelSearchForm: state.FormBank.HotelSearch.hotel_form_state,
            //check_in: state.FormBank.HotelSearch.lazyLoading.requests['proxyData[check_in]'],
            //check_out: state.FormBank.HotelSearch.lazyLoading.requests['proxyData[check_out]'],
            //rooms: state.FormBank.HotelSearch.lazyLoading.requests['proxyData[rooms]'],
            //adults: state.FormBank.HotelSearch.lazyLoading.requests['proxyData[adults]'],
        }
    }

    const mapDispatchToProps = {
        fetchHotelEstablishment: Actions.fetchHotelEstablishment,
        fetchHotelRooms: Actions.fetchHotelRooms,
        setHotelSearchState: Actions.setHotelSearchState,
        fetchGoogleAndYelpReviewsEstablishment: Actions.fetchGoogleAndYelpReviewsEstablishment,
        //fetchPhotoGalleryEstablishment: Actions.fetchPhotoGalleryEstablishment,
        fetchExperiencesEstablishment: Actions.fetchExperiencesEstablishment,
        fetchNeighborhoodEstablishment: Actions.fetchNeighborhoodEstablishment,

        setHotelEstablishmentId: Actions.setHotelEstablishmentId,
    }

    const EstablishmentContainer = connect(mapStateToProps, mapDispatchToProps)(EstablishmentContainer_)
    export default EstablishmentContainer
