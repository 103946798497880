import * as Actions from '../../../redux/actions'

import { Discover } from "./Discover.jsx"
import { FooterWhite } from "../Footer/FooterWhite.jsx"
import Header from '../Header/Header.jsx'
import { InfoTrip } from "./InfoTrip.jsx"
import { OtherTrips } from "./OtherTrips.jsx"
import { PhotoGallery } from "./PhotoGallery.jsx"
import PublicTripItineraryMap from "./PublicTripItineraryMap.jsx"
import PublicTripTimeline from "./PublicTripTimeline.jsx"
import React from 'react'
import { SaveTrip } from "./SaveTrip.jsx"
import { TripImageBar } from "./TripImageBar.jsx"
import { VideoGallery } from "./VideoGallery.jsx"
import { connect } from 'react-redux'
import { HeaderMobile } from '../Header/HeaderMobile'

export const TripContainer_ = ({ getPublicTripShare,props, trip, is_mobile, lang, getPublicTrip }) => {
    let padding_l = "8.5"
    let padding_r = "8.5"
    /* fetch trip */
    React.useEffect(() => {
        const shareID = props.match.params?.shareID;
        if (props.match.params.tripID !== undefined &&
            props.match.params.tripID !== null &&
            props.match.params.tripID !== "") {
            getPublicTrip(props.match.params.tripID, lang)
        }
        if(shareID){
            getPublicTripShare(shareID)
        }
    }, [props.match.params.tripID,props.match.params?.shareID])

    if (!isNaN(is_mobile)) {
        padding_l = is_mobile ? "1" : "8.5"
        padding_r = is_mobile ? "1" : "8.5"
    }
    return (
        <div>
            <>

                {!is_mobile ? <Header bg_color={"#FFFFFF"} text_color={"var(--frameBlack)"} /> :
                    <HeaderMobile  bg_color={"#FFFFFF"} text_color={"var(--frameBlack)"} />}
            </>
            <TripImageBar className="bg_white" from={"trip_public"} />
            <div style={{
                paddingLeft: padding_l + "%",
                paddingRight: padding_r + "%",
            }}>
                <SaveTrip />
                {trip !== undefined
                    && trip.tripDescription === undefined
                    && trip.tripDescription === ""
                    && trip.tripCompanions !== undefined
                    && trip.tripCompanions.length > 0 &&
                    <React.Fragment>
                        <hr className="line_separator_w margin_top_50 margin_bottom_50" />
                        <InfoTrip />
                    </React.Fragment>}
                <hr className="line_separator_w margin_top_50 margin_bottom_50" />
                <div className="futura_hv_bt_30_32_black pl-2 margin_bottom_50">Itinerary</div>
                {window.google !== undefined && <PublicTripItineraryMap />}
                {trip.tripPhotoGallery !== undefined && trip.tripPhotoGallery.length > 0 &&
                    <React.Fragment>
                        <hr className="line_separator_w margin_top_50 margin_bottom_50" />
                        <div className="futura_hv_bt_30_32_black pl-2 margin_bottom_50">Photo Gallery</div>
                        <PhotoGallery />
                    </React.Fragment>}
                {trip.tripPhotoGallery !== undefined && trip.tripPhotoGallery.length > 0 &&
                    <React.Fragment>
                        <hr className="line_separator_w margin_top_50 margin_bottom_50" />
                        <div className="futura_hv_bt_30_32_black pl-2 margin_bottom_50">Videos</div>
                        <VideoGallery />
                    </React.Fragment>}
                {trip.tripDestinations !== undefined
                    && trip.tripDestinations.length > 0
                    && trip.tripDestinations[0] !== undefined
                    && trip.tripDestinations[0].destinationDays !== undefined
                    && trip.tripDestinations[0].destinationDays.length > 0
                    && trip.tripDestinations[0].destinationDays[0] !== undefined &&
                    <React.Fragment>
                        < hr className="line_separator_w margin_top_50 margin_bottom_50" />
                        <div className="futura_hv_bt_30_32_black pl-2 margin_bottom_50"
                            style={{
                                font: "18px/20px Futura Hv BT"
                            }}>Timeline</div>
                        <PublicTripTimeline />
                    </React.Fragment>}
                {/* <hr className="line_separator_w margin_top_50 margin_bottom_50" />
                                    <div className="futura_hv_bt_30_32_black pl-2 margin_bottom_50">Travel journal</div>
                                    <TravelJournal screenSize={this.state.screenSize} /> */}
                {trip.otherTrips !== undefined && trip.otherTrips.length > 0 &&
                    <React.Fragment>
                        <hr className="line_separator_w margin_top_50 margin_bottom_50" />
                        <div className="futura_hv_bt_30_32_black pl-2 margin_bottom_50">Other trips you might like</div>
                        <OtherTrips from={"trip_public"} textColor={"text_main_black"} />
                        <div className="margin_top_50 margin_bottom_50"></div>

                    </React.Fragment>}
            </div>
            <div style={{
                paddingLeft: padding_l + "%",
                paddingRight: padding_r + "%",
                background: "#efefef",
                marginTop: is_mobile ? "50px" : "150px",
            }}>
                <Discover />
            </div>

            <FooterWhite />
        </div>
    )
}

const mapStateToProps = (state) => ({
    trip: state.PublicTrip.trip,
    is_mobile: state.Setting.is_mobile,
    lang: state.Setting.lang,

})

const mapDispatchToProps = {
    getPublicTrip: Actions.getPublicTrip,
    getPublicTripShare:Actions.getPublicTripShare
}

const TripContainer = connect(mapStateToProps, mapDispatchToProps)(TripContainer_)
export default TripContainer
