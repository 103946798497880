import { Discover } from '../../TripPage/Discover'
import Header from '../../Header/Header.jsx';
import MemberTripSubContainer from "../../MemberArea/Trip/MemberTripSubContainer"
import React from 'react'
import { TripImageBar } from "../../TripPage/TripImageBar.jsx";
import { connect } from 'react-redux'
import { member_area_router } from "../../Common/RegExValidate"
import * as Actions from '../../../../redux/actions'

/* member trip container component */
const TripComtainer_ = ({ setScreenSize, setBodyClientSize, setAppMenu,is_mobile, switch_container, trip,activeTrip }) => {
    const [loaded, setLoaded] = React.useState(false)
    React.useEffect(() => {
        if((trip &&  Object.keys(trip).length) || (activeTrip &&  Object.keys(activeTrip).length) ){
            setLoaded (true)
        }
        else{
            setLoaded (false)
        }
    }, [trip,activeTrip])

    const updateDimensions = () => {
        setScreenSize(window.screen.width, window.screen.height)
        setBodyClientSize(document.body.clientWidth, document.documentElement.scrollHeight)
        setAppMenu(document.body.clientWidth)
    }

    React.useEffect(() => {
        window.addEventListener("resize", updateDimensions)
        updateDimensions()

        return () => {
            window.removeEventListener("resize", updateDimensions)
        }
    }, [])



    //console.log("TripContainer.jsx")
    return (
        <React.Fragment>
            {loaded && <div>
                <Header bg_color={"rgba(25,25,25, 1)"} text_color={"var(--mainWhite)"} />
                <TripImageBar className="bg_white" from={"trip_private"} />
                <div style={{
                    height: "auto",
                    marginLeft: is_mobile ? "" : "8.5%",
                    marginRight: is_mobile ? "" : "8.5%",
                }}>
                    <MemberTripSubContainer />
                </div>
                {switch_container !== member_area_router.memberAreaTrip && <div style={{ backgroundColor: "#EFEFEF", zIndex: "30", position: "relative", }}><div style={{ paddingLeft: "8.5%", paddingRight: "8.5%", paddingTop: "20px", paddingbottom: "20px" }}><Discover /></div></div>}

            </div>}
        </React.Fragment>
    )
}

const mapStateToProps = state => {
    return {
        is_mobile: state.Setting.is_mobile,
        activeTrip: state.FormBank.TimelineWizard.activeTrip,
        screenSize: state.Setting.htmlBody.bodyClientWidth,
        switch_container: state.Member.switch_container,
        trip: state.Member.memberArea.selectedTrip,
    }
}

const mapDispatchToProps = {
    setSwitchContainer: Actions.setSwitchContainer,
    setScreenSize: Actions.setScreenSize,
    setBodyClientSize: Actions.setBodyClientSize,
    setAppMenu: Actions.setAppMenu,
}

const TripComtainer = connect(mapStateToProps, mapDispatchToProps)(TripComtainer_)
export default TripComtainer
