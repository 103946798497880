import * as Actions from '../../../redux/actions'

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import React from 'react';
import { ShareIcon } from "./SVGBank"
import TextField from '@material-ui/core/TextField';
import { connect } from "react-redux";

function isEmpty(obj) {
    return Object.keys(obj).length === 0 || !obj
}

/* share trip dialog componnent */
const ShareTripDialog_ = ({ activeTrip, from, selectedTrip, uuid, tripPageTrip, shareTrip, tripPage, setToggleEllipsisCloseDisable, setToggleEllipsisCloseEnable, toggle_ellipsis_close_disable, inline, offerToJoinTrip }) => {
    const trip =

        tripPage ? tripPageTrip :
            from === "Share Trip" ? !isEmpty(selectedTrip) ? selectedTrip : activeTrip : (from === "Offer to join" ? offerToJoinTrip : undefined)
    const [emailFieldValue, setEmailFieldValue] = React.useState(trip?.tripName);
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setToggleEllipsisCloseDisable()
        setOpen(true);
    };
    const handleClose = () => {
        setToggleEllipsisCloseEnable()
        setOpen(false);
    };

    const handleChangeShareTripField = event => {
        setEmailFieldValue(event.target.value);
    };
    const handleChangeShareTrip = (emails) => {
        shareTrip(trip.tripID || trip?.tripId, trip?.tripName, emails, uuid)
        setOpen(false);
        setToggleEllipsisCloseEnable()
    };

 

    return (
        <div>
            <div onClick={handleClickOpen}>
                {inline ? "" : from === "Share Trip" && <ShareIcon />}
                {tripPage && <span>  <ShareIcon width={25} height={25} fill={'white'} />
                </span>}
                {!inline && toggle_ellipsis_close_disable ? "" : from}
            </div>
            <Dialog open={open}
                aria-labelledby={open ? 'form_dialog_title' : undefined}
            >
                <DialogTitle id={open ? "form_dialog_title" : undefined}>{trip?.tripName + " (Share)"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {"To share the trip, please enter email here."}
                    </DialogContentText>
                    <TextField
                        autoFocus
                        id="Text Field"
                        label="Emails"
                        multiline
                        fullWidth
                        rowsMax="4"
                        onChange={handleChangeShareTripField}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={() => handleChangeShareTrip(emailFieldValue)} color="primary">
                        <ShareIcon />{"Share"}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

const mapStateToProps = state => ({
    lang: state.Setting.lang,
    activeTrip: state.FormBank.TimelineWizard.activeTrip,
    uuid: state.Member.authModal.uuid,
    selectedTrip: state.Member.memberArea.selectedTrip,
    toggle_ellipsis_close_disable: state.Member.memberArea.toggle_ellipsis_close_disable,
});

const mapDispatchToProps = {
    shareTrip: Actions.shareTrip,
    setToggleEllipsisCloseDisable: Actions.setToggleEllipsisCloseDisable,
    setToggleEllipsisCloseEnable: Actions.setToggleEllipsisCloseEnable,
}
const ShareTripDialog = connect(mapStateToProps, mapDispatchToProps)(ShareTripDialog_);
export default ShareTripDialog 