import * as Actions from '../../../../redux/actions'

import { CheckBoxIcon, CheckedBoxIcon } from "../SVGBank"
import { DirectionsRenderer, GoogleMap, withGoogleMap } from "react-google-maps"
import { compose, lifecycle, withProps } from "recompose"

import { DELAY_ZERO_SECOND, URL_REPLACE } from "../RegExValidate"
import ItineraryMarkers from "../ItineraryMarkers"
import { DestinationMap } from "./DestinationMap"
import PlacesMarkers from "../PlacesMarkers"
import PropTypes from "prop-types"
import React, { useCallback } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import mapStyles from "../../TripPage/mapStyles"
import moment from "moment"
import { list_container_mode, member_area_router } from "../RegExValidate"
import FeatureTypeIcon from '../FeatureTypeIcon'
import { getDestinationNearbyDestinations, getDestinationNearbyPlaces, updateDestinationCoords } from './ActiveTrip.slice'
import { getHomeDetails } from '../../../../redux/actions'
import _ from "lodash";
import MarkerClusterer from 'react-google-maps/lib/components/addons/MarkerClusterer'
import { useHistory } from 'react-router-dom';
import DestinationMarkers from '../destinationMarkers'
import { useMemo } from 'react';
import { debounce } from 'lodash';


// import { MarkerClusterer } from "react-google-maps/lib/components/addons/MarkerClusterer"

/* wrap map high order component */
const WrappedMap = compose(
    withProps({
        // googleMapURL: "https://maps.googleapis.com/maps/api/js?libraries=geometry,drawing,places&key=" + GOOGLE_API_KEY,
        loadingElement: <div style={{ height: `100%` }} />,
        containerElement: <div style={{ height: `100%` }} />,
        mapElement: <div style={{ height: `100%` }} />
    }),
    withGoogleMap,
    lifecycle({
        componentDidUpdate(prevProps) {
            const { tripId, activities, activeDay, destination, handleCenterChanged } = this.props
            if (prevProps.tripId !== tripId
                || prevProps.activeDay.tripDestinationId !== activeDay.tripDestinationId
                || prevProps.activeDay.dayInTrip !== activeDay.dayInTrip
                || prevProps.activeDay.dayInTripDestination !== activeDay.dayInTripDestination
                || prevProps.activities.length !== activities.length) {
                let waypts = []
                let first_lat = ""
                let first_lng = ""
                activities.map((activity) => {
                    first_lat = activity.activityLatitude
                    first_lng = activity.activityLongitude
                    waypts.push({
                        lat: parseFloat(activity?.activityLatitude),
                        lng: parseFloat(activity?.activityLongitude)
                    })
                    return ""
                })

                let first = waypts && waypts[0];
                let last = waypts && waypts.length > 0 ? waypts[waypts.length - 1] : null;
                let way_points = waypts.map(obj => {
                    let key_obj = {}
                    key_obj["location"] = obj
                    key_obj["stopover"] = true
                    return key_obj
                })

                if (first !== undefined && last !== undefined) {
                    // console.log("display directions")
                    const DirectionsService = new window.google.maps.DirectionsService()
                    DirectionsService.route({
                        origin: first,
                        destination: last,
                        waypoints: way_points,
                        provideRouteAlternatives: true,
                        optimizeWaypoints: true,
                        travelMode: window.google.maps.TravelMode.DRIVING,
                    }, (result, status) => {

                        if (status === window.google.maps.DirectionsStatus.OK) {
                            this.setState({
                                directions: { ...result },
                                display: true
                            })
                        } else {
                            console.log(`Driving error fetching directions ${result}`)
                            DirectionsService.route({
                                origin: first,
                                destination: last,
                                waypoints: way_points,
                                provideRouteAlternatives: true,
                                optimizeWaypoints: true,
                                travelMode: window.google.maps.TravelMode.WALKING,
                            }, (result, status) => {
                                if (status === window.google.maps.DirectionsStatus.OK) {
                                    this.setState({
                                        directions: { ...result },
                                        display: true
                                    })
                                } else {
                                    console.error(`Walking error fetching directions ${result}`)
                                }
                            })
                        }
                    })
                } else if (first !== undefined) {
                    // console.log(" no directions")
                    this.setState({
                        directions: null,
                        display: false,
                    })
                    this.props.setMapCenter({
                        lat: parseFloat(first_lat),
                        lng: parseFloat(first_lng)
                    })
                    this.props.setZoom(14)
                } else if (first === undefined) {
                    // console.log(" no activity and directions")
                    this.setState({
                        directions: null,
                        display: false
                    })
                    this.props.setMapCenter({
                        lat: parseFloat(destination?.latitude),
                        lng: parseFloat(destination?.longitude)
                    })
                    this.props.setZoom(14)
                }
            }
        },
        componentDidMount() {
            const { activities, destination, handleCenterChanged } = this.props
            let waypts = []
            let first_lat = ""
            let first_lng = ""
            activities && activities.map((activity) => {
                first_lat = activity.activityLatitude
                first_lng = activity.activityLongitude
                waypts.push({
                    lat: parseFloat(activity.activityLatitude),
                    lng: parseFloat(activity.activityLongitude)
                })
                return ""
            })
            let first = waypts.shift()
            let last = waypts.pop()
            let way_points = waypts.map(obj => {
                let key_obj = {}
                key_obj["location"] = obj
                key_obj["stopover"] = true
                return key_obj
            })

            if (first !== undefined && last !== undefined) {
                // console.log("display directions")
                const DirectionsService = new window.google.maps.DirectionsService()

                DirectionsService.route({
                    origin: first,
                    destination: last,
                    waypoints: way_points,
                    provideRouteAlternatives: true,
                    optimizeWaypoints: true,
                    travelMode: window.google.maps.TravelMode.DRIVING,
                }, (result, status) => {
                    if (status === window.google.maps.DirectionsStatus.OK) {
                        this.setState({
                            directions: { ...result },
                            display: true
                        })
                    } else {

                        console.log(`Driving error fetching directions ${result}`)
                        DirectionsService.route({
                            origin: first,
                            destination: last,
                            waypoints: way_points,
                            provideRouteAlternatives: true,
                            optimizeWaypoints: true,
                            travelMode: window.google.maps.TravelMode.WALKING,
                        }, (result, status) => {
                            if (status === window.google.maps.DirectionsStatus.OK) {
                                this.setState({
                                    directions: { ...result },
                                    display: true
                                })
                            } else {
                                console.error(`Walking error fetching directions ${result}`)
                            }
                        })
                    }
                })

            } else if (first !== undefined) {
                // console.log(" no directions")
                this.setState({
                    directions: null,
                    display: false,
                })
                this.props.setMapCenter({
                    lat: parseFloat(first_lat),
                    lng: parseFloat(first_lng)
                })
                this.props.setZoom(14)
            } else if (first === undefined) {
                // console.log(" no activity and directions")
                this.setState({
                    directions: null,
                    display: false
                })
                this.props.setMapCenter({
                    lat: parseFloat(destination !== undefined ? destination.latitude : 0),
                    lng: parseFloat(destination !== undefined ? destination.longitude : 0)
                })
                this.props.setZoom(destination !== undefined ? 14 : 1)
            }
        }
    })
)((props) => {
    React.useEffect(() => {
        props.fetchEstablishments();
        return () => {
        };
    }, []);

    const tripDestinations = props?.destinations;
    const dispatch = useDispatch();
    const destinationNearByDestinations = useSelector(state => state.ActiveTrip.destinationNearByDestinations);

    const [trackInfoArray, setTrackInfoArray] = React.useState([]);
    const [trackDestArray, setTrackDestArray] = React.useState([]);


    const pushDestWindow = (id) => {
        const tempArray = [];
        tempArray.push(id)
        setTrackDestArray(tempArray);
    }
    const clearDestWindow = () => {
        setTrackDestArray([]);
    }
    const checkDestOpen = (id) => {
        const showWindow = trackDestArray.find(element => element === id);
        return showWindow ? true : false;
    }


    const pushPlacesWindow = (id) => {
        const tempArray = [];
        tempArray.push(id)
        setTrackInfoArray(tempArray);
    }

    const clearPlacesWindow = () => {
        setTrackInfoArray([]);
    }

    const checkWindowOpen = (id) => {
        const showWindow = trackInfoArray.find(element => element === id);
        return showWindow ? true : false;
    }


    const mapClick = () => {
        clearPlacesWindow()
        clearDestWindow()
    }

    const handleCenterChanged = () => {
        props.handleCenterChanged()

    }

    const handleZoomChanged = () => {
        props.handleZoomChanged()
    }

    // React.useEffect(() => {
    //     if (trackInfoArray && trackInfoArray.length > 0) {
    //         setTimeout(() => {
    //             clearPlacesWindow()
    //         }, 8000);
    //     }
    //     return () => {
    //     };
    // }, [trackInfoArray]);

  


    return (<GoogleMap
        defaultZoom={props.zoom}
        zoom={props.zoom}
        defaultCenter={props.mapCenter}
        center={props.mapCenter}
        defaultOptions={{
            controlSize: 20,
            mapTypeControl: false,
            fullscreenControl: false,
            minZoom: 4,
            // maxZoom: 17,
            styles: mapStyles
        }}
        onCenterChanged={handleCenterChanged}
        onZoomChanged={handleZoomChanged}
        ref={props.refMap}
        onClick={mapClick}
    >


        {/* <MarkerClusterer gridSize={30}> */}
        {props.activities.length > 0 && props.activities.map((activity, index_activity) => {
            const lengthActivities = props.activities.length
            return (
                <ItineraryMarkers key={index_activity} lengthActivities={lengthActivities} activity={activity} index_activity={index_activity}
                    pushPlacesWindow={pushPlacesWindow}
                    checkWindowOpen={checkWindowOpen}
                    clearPlacesWindow={clearPlacesWindow} />
            )
        })}

        <MarkerClusterer
            averageCenter
            enableRetinaIcons
            gridSize={100}
            defaultMinimumClusterSize={2}
            calculator={(markers, numStyles) => {
                if (markers.length >= 0 && markers.length < 10) return { text: markers.length, index: 1 };
                return { text: markers.length, index: 0 };
            }
            }
            styles={[{
                height: 36,
                url: "https://storage.googleapis.com/muvimages/muv_assets/icons/Destination%20cluster2.png",
                width: 35,
                textColor: "white",
            }, {
                height: 35,
                url: "https://storage.googleapis.com/muvimages/muv_assets/icons/Small%20destination%20marker%202.png",
                width: 35,
                textColor: "white",
            },
            ]}
        >

            {destinationNearByDestinations && props.showNearBy && destinationNearByDestinations.length > 0 && destinationNearByDestinations.map((destination, index_destination) => {
                const lengthDestinations = destinationNearByDestinations.length;
                let isItinerary = false;

                if(tripDestinations){
                    isItinerary = tripDestinations?.find((dest)=> dest?.id === destination?.destinationId)
                }
    
                return (
                    <DestinationMarkers key={index_destination} lengthDestinations={lengthDestinations} destination={destination} color={{ fill: 'rgba(216, 82, 29, 1)', stroke: 'rgba(216, 82, 29, 0.4)' }}
                        index_destination={index_destination} mapProps={props} isRegularTrip={true} textColor={"rgba(25, 188, 155, 1)"} 
                        pushDestWindow={pushDestWindow}
                        clearDestWindow={clearDestWindow}
                        checkDestOpen={checkDestOpen}
                        isItinerary={isItinerary?true:false}
                        dblClickDestination={props?.dblClickDestination}/>
                )
            })}
        </MarkerClusterer>

        {/* </MarkerClusterer>
                        <MarkerClusterer gridSize={30}> */}
        <MarkerClusterer
            averageCenter
            enableRetinaIcons
            gridSize={100}
            defaultMinimumClusterSize={10}
            styles={[{
                height: 36,
                url: "https://storage.googleapis.com/muvimages/muv_assets/icons/Activity%20cluster2.png",
                width: 35,
                textColor: "white",
            },]}
        >
            {props.selectedTypes && props.selectedTypes.length > 0 &&
                props.placesNearByCenter.length > 0 && props.placesNearByCenter.map((establishment, index_establishment) => {


                    const typeID = establishment?.subType?.typeId;
                    const type = props.types ? props.types.find(type => type.typeID === typeID) : null;
                    const icon = type && type.icon ? type.icon : null;
                    const zoom = props && props.refMap ? props.refMap?.current?.getZoom() : 55;
                    return (
                        <React.Fragment key={index_establishment}>

                            {!props.activities.find(a => a.activityEstabID === establishment.id) &&

                                <PlacesMarkers zoom={zoom} icon={icon} establishment={establishment} index_establishment={index_establishment} placesNearByCenter={props.placesNearByCenter}
                                    pushPlacesWindow={pushPlacesWindow}
                                    checkWindowOpen={checkWindowOpen}
                                    clearPlacesWindow={clearPlacesWindow} />


                            }

                        </React.Fragment>
                    )
                })}
        </MarkerClusterer>


        {/* </MarkerClusterer> */}
        {props.display && props.directions && props.sameLocation && (
            <DirectionsRenderer
                directions={props.directions}
                options={{
                    suppressMarkers: true,
                    polylineOptions: {
                        strokeColor: "#19BC9B",
                        strokeOpacity: 1,
                        strokeWeight: 4
                    }
                }}
            />
        )}

    </GoogleMap>)

}
)

/* trip map component */
const TripMap_ = ({
    app_menu_height,
    screenSizeHeight,
    lang,
    uuid_auth,
    uuid = uuid_auth !== "" ? uuid_auth : "TMPUUID",

    activeDay,
    activeTrip,
    activities = activeTrip.activities,
    destinations = activeTrip.tripDestinations,
    noDate = String(activeTrip.tripNoDate),
    tripStartDate = activeTrip.tripStartDate,
    activeFeatureId,
    is_mobile,
    getEstablishmentsNearbyMapCenter,
    setActiveTripDestinationId,
    setSwitchFeatureTypeContainerOfFeature,
    selectedTypes,
    placesNearByCenter,
    feature, currentDestination,
    switch_container,
    tripMode,
    generalInfo, setDestinationId, fetchDestination
}) => {
    const from_destination = switch_container === member_area_router.feature && tripMode === list_container_mode.map
    const destinationsFilter = destinations.filter(d => d.td_id === activeDay.tripDestinationId)

    let destination = destinationsFilter.length > 0 && destinationsFilter[0] !== undefined ? destinationsFilter[0] : undefined
    let destinationDays = destination;


    if (from_destination) {
        destination = generalInfo ? { latitude: parseFloat(generalInfo.latitude), longitude: parseFloat(generalInfo.longitude) } : { latitude: 45.490439, longitude: -73.714369 }
    }

    

    const containerH = screenSizeHeight - Math.ceil(app_menu_height / 2)

    //updating  activities of the iternary
    const activitiesInTripDestinationDay = activities.filter(a => a.tripDestinationId === activeDay.tripDestinationId && a.activityDayInDestination === activeDay.dayInTripDestination)
    const [mapCenter, setMapCenter] = React.useState({
        lat: parseFloat(destination !== undefined ? destination.latitude : 0),
        lng: parseFloat(destination !== undefined ? destination.longitude : 0)
    })
    const [mapCenterDestination, setMapCenterDestination] = React.useState({
        lat: parseFloat(destination !== undefined ? destination.latitude : 0),
        lng: parseFloat(destination !== undefined ? destination.longitude : 0)
    })

    const [zoom, setZoom] = React.useState(destination !== undefined ? 14 : 1)
    const [zoomDestination, setZoomDestination] = React.useState(destination !== undefined ? 14 : 1)

    const types = feature && feature.types ? [...feature.types] : null;
    // const [selectedTypes, setSelectedTypes] = React.useState([]);
    const savedActiveTrip = useSelector(state => state.ActiveTrip);
    const destinationPlacesCoordinates = useSelector(state => state.ActiveTrip.destinationPlacesCoordinates);

    const [isRoadTrip, setIsRoadTrip] = React.useState(false);
    const [tripMap, setTripMap] = React.useState(false);
    const history = useHistory()


    const handleSetActiveTripDestinationId = (destination, dayInTrip, dayInTripDestination) => {
        setActiveTripDestinationId(destination, dayInTrip, dayInTripDestination)
        document.getElementById(destination.td_id + "_" + dayInTripDestination + "_" + dayInTrip) !== null
            && document.getElementById(destination.td_id + "_" + dayInTripDestination + "_" + dayInTrip)
                .scrollIntoView({ behavior: 'smooth', block: 'start' })
        setDestinationId(destinationDays.destId)
        //fetchDestination(destinationDays.destId, lang)

    }

    // updating the places markers
    const refMap = React.useRef(null)
    const destinationRef = React.useRef(null)
    const dispatch = useDispatch()


    const [mapDestinations, setMapDestinations] = React.useState(destinations || []);
    const [showNearBy, setShowNearBy] = React.useState(false);



    React.useEffect(() => {
        if (destinations) {
            setMapDestinations(destinations)
            if (savedActiveTrip && savedActiveTrip?.trip &&
                destinations && destinations.length > 0) {

                if (savedActiveTrip?.trip?.tripTravelCategory?.code === "road") {
                    setTripMap(true)
                    setIsRoadTrip(true)
                }

                getHomeInfo()  ///set hometown
            }
        }
    }, [destinations]);




    const getHomeInfo = async () => {
        const starting = savedActiveTrip?.trip?.startingPoint;
        if (starting) {
            const destinationExists = destinations && destinations.find((dest) => starting?.latitude === dest?.latitude && starting?.longitude === dest?.longitude);
            if (!destinationExists) {
                const destArray = [...destinations];
                const startingCopy = { ...starting };
                startingCopy['home'] = true;
                destArray.unshift(startingCopy)
                setMapDestinations(destArray)
            }

        }
    }



    const fetch = () => {
        const reference = tripMap ? destinationRef : refMap;

        if (reference && reference.current) {

            let selectedType = [...selectedTypes];
            if (tripMap) {
                const data = {
                    coords: [...destinationPlacesCoordinates],
                    selectedTypes: selectedType || []
                }
                dispatch(getDestinationNearbyPlaces(data))
            }
            if (showNearBy) {
                fetchNearBy()

            }
            if (!tripMap) {
                if (selectedTypes && selectedTypes.length > 0) {
                    let ne = reference?.current?.getBounds()?.getNorthEast();
                    let sw = reference?.current?.getBounds()?.getSouthWest();
                    if (ne && sw) {
                        const coordinates = [{ lat: ne.lat(), lng: sw.lng() }, { lat: sw.lat(), lng: ne.lng() }];
                        getEstablishmentsNearbyMapCenter(coordinates, lang, selectedTypes)
                    }

                }
            }


        }
    }


    const fetchEstablishments = debounce(fetch, 1000)

    const [refreshText, setRefreshText] = React.useState("Search this area");

    const [showRefresh, setShowRefresh] = React.useState(false);

    const checkType = (type) => {
        return selectedTypes.find(val => val.typeID === type.typeID)
    }

    const selectType = () => {
        const reference = tripMap ? destinationRef : refMap;
        if (reference && reference.current) {
            fetchEstablishments()
        }

    }

    React.useEffect(() => {
        if (selectedTypes) {
            selectType()
        }
        return () => {
        };
    }, [selectedTypes]);


    React.useEffect(() => {
        if (currentDestination?.activeId === destinationDays?.destId) {
            if (activities && activities.length > 0) {
                changeType(null)
            }
        }
    }, []);

    const selectTripMap = () => {
        setTripMap(!tripMap)
        getHomeInfo()  ///set hometown

    }

    const handleCenterChanged = () => {
        setShowRefresh(true)
        ///fetchEstablishments()
    }
    const handleZoomChanged = () => {
        const reference = tripMap ? destinationRef : refMap;

        const zoom = reference?.current?.getZoom()
        if (zoom) {
            setZoom(zoom)
        }

        // fetchEstablishments()
    }


    const handleDestinationCenterChanged = () => {
        setShowRefresh(true)
        //fetchEstablishments()
    }
    const handleDestinationZoomChanged = () => {
        // fetchEstablishments()
    }

    const changeType = (type) => {
        setSwitchFeatureTypeContainerOfFeature(activeFeatureId, lang, feature.types, type)
    }


    const refreshMap = ()=>{
        setRefreshText("Searching...")
        fetchEstablishments()
        setTimeout(() => {
            setRefreshText("Search this area")
        }, 5000);
    }
    const selectNearBy = () => {
        setShowNearBy(!showNearBy)

        if( !showNearBy){
            fetchNearBy()
            ///deselect  types
            changeType(null)
            setZoom(11)
        }
    }

    const fetchNearBy = async () => {
       
        const reference = tripMap ? destinationRef : refMap;
        if (reference) {
            let ne = reference?.current?.getBounds()?.getNorthEast()
            let sw = reference?.current?.getBounds()?.getSouthWest()
            if (ne && sw) {
                const coordinates = [{ lat: ne.lat(), lng: sw.lng() }, { lat: sw.lat(), lng: ne.lng() }];
                let data = {
                    coords: destinationPlacesCoordinates,
                    mapBounds: coordinates,
                    destinations: mapDestinations,
                    size:['Large','Medium'],
                }
                setShowRefresh(true)
                setRefreshText("Searching...")
                const response = await dispatch(getDestinationNearbyDestinations(data))
                if (response?.meta?.requestStatus === "fulfilled") {
                    const resData = response?.payload;
                     data = {
                        coords: destinationPlacesCoordinates,
                        mapBounds: coordinates,
                        destinations: mapDestinations,
                        size:['Small'],
                        join:true
                    }

                    const response2 = await dispatch(getDestinationNearbyDestinations(data))

                  
                }
                setRefreshText("Search this area")
                setShowRefresh(false)
            }
        }
    }

    const setDefaultTypeTag = () => {  //see type default
        if (!selectedTypes || selectedTypes.length < 1 ) {
            changeType({
                typeID: "1"
            })
        }
    
    }
    
    const dblClickDestination = (center,clickZoom)=>{
        setMapCenter(center)
        setZoom(clickZoom?clickZoom:13)
        setDefaultTypeTag()
        selectNearBy()
    }




    return (
        <div style={{
            height: containerH,
            width: "100%",
            position: "absolute",
            top: "0",
        }} className="content_container margin_bottom_20 inline-block" id="google_map_wrap">
            {!tripMap && <WrappedMap
                tripId={activeTrip.tripId}
                activities={activitiesInTripDestinationDay}
                activeDay={activeDay}
                destinations={destinations}
                destination={destination}
                placesNearByCenter={placesNearByCenter}
                selectedTypes={selectedTypes}
                zoom={zoom}
                fetchEstablishments={fetchEstablishments}
                types={types}
                setZoom={setZoom}
                mapCenter={mapCenter}
                setMapCenter={setMapCenter}
                selectNearBy={selectNearBy}
                handleCenterChanged={handleCenterChanged}
                handleZoomChanged={handleZoomChanged}
                handleDayMapCenterChanged={handleCenterChanged}
                refMap={refMap}
                showNearBy={showNearBy}
                setDefaultTypeTag={setDefaultTypeTag}
                dblClickDestination={dblClickDestination}
                sameLocation={currentDestination?.activeId === destinationDays?.destId}
            />}

            {tripMap && <DestinationMap
                tripId={activeTrip.tripId}
                activities={activitiesInTripDestinationDay}
                activeDay={activeDay}
                destinations={mapDestinations}
                destination={destination}
                zoom={zoomDestination}
                types={types}
                setZoom={setZoomDestination}
                fetchEstablishments={fetchEstablishments}
                selectedTypes={selectedTypes}
                showNearBy={showNearBy}
                mapCenter={mapCenterDestination}
                roadTrip={isRoadTrip}
                toggleMap={selectTripMap}
                setMapCenter={setMapCenterDestination}
                setMapCenterTrip={setMapCenter}
                setZoomTrip={setZoom}
                setDefaultTypeTag={setDefaultTypeTag}
                selectNearBy={selectNearBy}
                placesNearByCenter={placesNearByCenter}
                handleDayMapCenterChanged={handleCenterChanged}
                handleCenterChanged={handleDestinationCenterChanged}
                handleZoomChanged={handleDestinationZoomChanged}
                refMap={destinationRef}
                dblClickDestination={dblClickDestination}
            />}
            <div
                style={is_mobile ? 
                    {
                        position: "absolute",
                        top: "7%",
                        right: "0%",
                        left:"1%",
                        overflowX: "auto",
                        whiteSpace:"nowrap",
                        scrollbarWidth:"none",scrollbarColor:'transparent',
                        width:"100%",
                        height:50,
                        zIndex: "1",
                        margin: "0",
                    }
                    :{
                    position: "absolute",
                    top: "2%",
                    right: "22%",
                    zIndex: "1",
                    margin: "0",
                }}>
                {<div

                    onClick={() => selectNearBy()}
                    style={{
                        backgroundColor: showNearBy ? "#D8521D" : "var(--mainWhite)",
                        color: showNearBy ? "var(--mainWhite)" : "var(--frameBlack)",
                        border: showNearBy ? "1px solid #D8521D" : "1px solid #E8E8E8",
                        height: "30px",
                        display: "inline-flex",
                        borderRadius: "25px",
                        flexWrap: "wrap",
                        cursor: "pointer",
                        verticalAlign: "middle",
                        padding: ".375rem .75rem",
                        marginRight: "6px",
                    }}>
                    <div style={{
                        font: "16px/18px Futura Lt BT",
                        color: showNearBy ? "var(--mainWhite)" : "var(--frameBlack)",
                    }}>Nearby Destinations</div>
                </div>}
                
                {types !== undefined && types.length > 0
                     && types.map((type, type_index) => {
                        return (
                            <div
                                key={type.toString() + type_index}
                                onClick={() => changeType(type)}
                                style={{
                                    backgroundColor: checkType(type) ? "#0d3a7d" : "var(--mainWhite)",
                                    color: checkType(type) ? "var(--mainWhite)" : "var(--frameBlack)",
                                    border: checkType(type) ? "1px solid #0d3a7d" : "1px solid #E8E8E8",
                                    height: "30px",
                                    display: "inline-flex",
                                    borderRadius: "25px",
                                    flexWrap: "wrap",
                                    cursor: "pointer",
                                    verticalAlign: "middle",
                                    padding: ".375rem .75rem",
                                    marginRight: "6px",
                                    visibility: refreshText=== "Searching..." ?"hidden":"visible"
                                }}>
                                <FeatureTypeIcon
                                    icon={type.icon}
                                    fill={checkType(type) ? "var(--mainWhite)" : "#707070"}
                                    style={{
                                        width: "15px",
                                        height: "16px",
                                        marginLeft: "5px",
                                        marginRight: "10px",
                                    }} />
                                <div style={{
                                    font: "16px/18px Futura Lt BT",
                                    color: checkType(type) ? "var(--mainWhite)" : "var(--frameBlack)",
                                }}>{type.typeName}</div>
                            </div>
                        )
                    })
                }


                

            </div>

           {showRefresh && <div

                   
                    style={{
                        position: "absolute",
                        top: is_mobile? "15%":"7%",
                        right: "14%",
                        zIndex: "1",
                        margin: "0",
                        left:is_mobile?"10%": "50%",
                        marginLeft: -20
                    }}>

                    <button  onClick={() => refreshMap()}
                     disabled={refreshText=== "Searching..."} style={{
                         backgroundColor:  "#0d3a7d" ,
                         color:  "var(--mainWhite)",
                         height: "30px",
                         display: "inline-flex",
                         borderRadius: "25px",
                         flexWrap: "wrap",
                         cursor: "pointer",
                         verticalAlign: "middle",
                         padding: ".375rem .75rem",
                         marginRight: "6px",
                         outline:0,
                        font: "16px/18px Futura Lt BT",
                    }}>{refreshText}</button>
                </div>}


            {activeTrip && activeTrip?.tripId && <div
                style={{
                    position: "absolute",
                    top: "2%",
                    right:is_mobile?"2%": "14%",
                    zIndex: "1",
                    margin: "0",
                }}>
                <div

                    onClick={() => selectTripMap()}
                    style={{
                        backgroundColor: tripMap ? isRoadTrip ? "#D8521D" : "#0d3a7d" : "var(--mainWhite)",
                        color: tripMap ? "var(--mainWhite)" : "#0d3a7d",
                        border: tripMap ? isRoadTrip ? "1px solid #D8521D" : "1px solid #0d3a7d" : "1px solid #0d3a7d",
                        height: "30px",
                        display: "inline-flex",
                        borderRadius: "25px",
                        flexWrap: "wrap",
                        cursor: "pointer",
                        verticalAlign: "middle",
                        padding: ".375rem .75rem",
                        marginRight: "6px",
                    }}>

                    <div style={{
                        font: "16px/18px Futura Lt BT",
                        color: tripMap ? "var(--mainWhite)" : "#0d3a7d",
                    }}>Trip Map</div>
                </div>
            </div>}

            <div
                style={{
                    position: "absolute",
                    top: is_mobile? "15%":"2%",
                    right: "2%",
                    zIndex: "1",
                    margin: "0",
                }}>

                {destinationDays !== undefined && !tripMap &&
                    destinationDays.spanningDays !== undefined &&
                    destinationDays.spanningDays.split(',').length > 0 &&
                    destinationDays.spanningDays.split(',').map((dayInTrip, idx) => {
                        const dayInTripDestination = String(idx + 1)
                        return (
                            <div key={idx}
                                style={{
                                    border: "1px solid #E8E8E8",
                                    background: "var(--mainWhite) 0% 0% no-repeat padding-box",
                                    cursor: "pointer",
                                    color: "var(--mainGreen)",
                                    padding: "1px",
                                    width: noDate === "1" ? "90px" : "110px",
                                }}
                                onClick={() => handleSetActiveTripDestinationId(destinationDays, dayInTrip, dayInTripDestination)}
                            >
                                <div className={noDate === "1" ? "futura_md_bt_12_16 pl-1 pt-1 pb-1 text-center" : "futura_md_bt_12_16 pl-1 pt-1 pb-1 text-left"}>

                                    {activeDay.dayInTrip === dayInTrip && currentDestination?.activeId === destinationDays?.destId ?
                                        <CheckedBoxIcon className="mr-1" />
                                        :
                                        <CheckBoxIcon className="mr-1" />
                                    }
                                    {noDate === "1" ? "Day " + dayInTrip : moment(tripStartDate, 'YYYY-MM-DD').add((dayInTrip - 1), 'days').format('ddd D[,] MMM[.]')}
                                </div>
                            </div>
                        )
                    })}
            </div>
        </div >
    )
}

TripMap_.propTypes = {
    activeTrip: PropTypes.object.isRequired
}
const mapStateToProps = state => {
    return {
        lang: state.Setting.lang,
        activeFeatureId: state.Feature.feature.activeId,
        app_menu_height: state.Setting.appMenu.app_menu_height,
        bodyClientWidth: state.Setting.htmlBody.bodyClientWidth,
        screenSizeHeight: window.innerHeight,
        feature: state.Feature.feature,
        activeTrip: state.FormBank.TimelineWizard.activeTrip,
        activeTripDestinationId: state.FormBank.TimelineWizard.activeTripDestinationId,
        activeDay: state.FormBank.TimelineWizard.activeDay,
        uuid_auth: state.Member.authModal.uuid,
        placesNearByCenter: state.FormBank.Map.placesNearByCenter,
        generalInfo: state.Destination.destination.generalInfo,
        currentDestination: state.Destination.destination,
        selectedTypes: state.Feature.feature.loading.selectedTypes,
        switch_container: state.Member.switch_container,
        tripMode: state.Member.tripMode,
        is_mobile: state.Setting.is_mobile,
    }

}

const mapDispatchToProps = {
    getEstablishmentsNearbyMapCenter: Actions.getEstablishmentsNearbyMapCenter,
    setSwitchFeatureTypeContainerOfFeature: Actions.setSwitchFeatureTypeContainerOfFeature,
    setActiveTripDestinationId: Actions.setActiveTripDestinationId,
    setDestinationId: Actions.setDestinationId,
    fetchDestination: Actions.fetchDestination,
}
const TripMap = connect(mapStateToProps, mapDispatchToProps)(TripMap_)
export default TripMap
