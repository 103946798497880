import {
    CURRENT_VIDEO_DISPLAY
} from "../interfaces/types"

const initialState = {
    youtube_id: '',
    video_desc: '',
    index: 0
}


/* dispaly video reducer */
export default (state = initialState, action) => {
    switch (action.type) {
        case CURRENT_VIDEO_DISPLAY:
            return {
                youtube_id: action.youtube_id,
                    video_desc: action.video_desc,
                    index: action.index,
            };
        default:
            return {
                ...state
            }
    }
}