import * as Actions from '../../../redux/actions'
import Rating from '@material-ui/lab/Rating';
import React, {useState} from 'react';
import { connect } from 'react-redux'
import { priceline_image_boxing, loadingImage } from '../Common/RegExValidate'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faTrashAlt } from '@fortawesome/free-solid-svg-icons'

import { Image } from 'react-bootstrap';

const BookingItems_ = ({ booking_bundles, booking_info, establishment, is_mobile, removeBundleFromHotelBooking }) => {
    const { generalInfo, subtypes } = establishment

    let  [,setState]=useState();

    let ppn_bundles = [...booking_bundles]
        
    const handleRemoveRoomBooking = (booking_bundle_idx) => {
        ppn_bundles.splice(booking_bundle_idx, 1)
    
        removeBundleFromHotelBooking(booking_bundle_idx, ppn_bundles)
        setState({})
    } 

    return (
        <div style={{verticalAlign:"top"}}>
            {ppn_bundles !== undefined && ppn_bundles !== null && ppn_bundles.length>0 &&  
             ppn_bundles.map((booking_bundle, bidx) => {
                return (<table style={{ border:"2px solid rgb(218,218,218,1)", marginBottom:15, width: "100%"}} key={bidx}><tbody>
                   
                        <tr >
                        <td colSpan="2">
                        {
                        ppn_bundles !== undefined && ppn_bundles !== null && ppn_bundles.length>1 && <div onClick={() => handleRemoveRoomBooking(bidx)} 
                                        style={{ cursor:"pointer", paddingRight: "1rem", textAlign:"right", font: "20px/30px Futura Md BT", color: "#dadada", }}>
                                <FontAwesomeIcon icon={faTrashAlt} />
                            </div>}
                        </td>
                    </tr>
                    
                    <tr>
                        <td colSpan="2">
                            <div style={{ paddingLeft: "2rem", font: "18px/22px Futura Hv BT", color: "#000000", marginBottom: "5px",marginTop:15 }}>{booking_bundle.name}</div>
                        </td>
                    </tr>
                    
                    <tr>
                        <td colSpan="2">
                            <div style={{ paddingLeft: "2rem", font: is_mobile ? "12px/18px Futura Hv BT" : "14px/16px Futura Hv BT", color: "#707070", marginBottom: "10px", }}>{booking_bundle.room_text}</div>
                            <div style={{ paddingLeft: "2rem", font: is_mobile ? "12px/18px Futura Lt BT" : "12px/16px Futura Lt BT", color: "#707070", marginBottom: "15px", }}>
                                {booking_bundle.is_cancellable==1 && <span>Cancellable, </span>}
                                {booking_bundle.is_cancellable==0 && <span>Non-Cancellable, </span>}
                                {booking_bundle.details_summary.freeRefundableFlag==1 && <span>Refundable</span>}
                                {booking_bundle.details_summary.freeRefundableFlag==0 && <span>Non-Refundable</span>}
                            </div>
                        </td>
                    </tr>
                    
                    <tr style={{ font: is_mobile ? "12px/18px Futura Lt BT" : "12px/18px Futura Lt BT", color: "#707070", }}>
                        <td >
                            <div style={{ paddingLeft: "2rem"}} >Check-in:</div>
                        </td>
                        <td >
                            <div style={{ font: is_mobile ? "12px/18px Futura Hv BT" : "12px/18px Futura Hv BT", paddingRight: "2rem", textAlign:"right", color: "#000000", }}>{moment(booking_bundle.check_in).format('MMMM Do, yyyy')}</div>
                        </td>
                    </tr>
                    
                    <tr style={{ font: is_mobile ? "12px/18px Futura Lt BT" : "12px/18px Futura Lt BT", color: "#707070", }}>
                        <td >
                            <div style={{ paddingLeft: "2rem", paddingTop:5, paddingBottom:12}} >Check-out:</div>
                        </td>
                        <td >
                            <div style={{ font: is_mobile ? "12px/18px Futura Hv BT" : "12px/18px Futura Hv BT", paddingRight: "2rem", paddingTop:5, paddingBottom:12, textAlign:"right", color: "#000000", }}>{moment(booking_bundle.check_out).format('MMMM Do, yyyy')}</div>
                        </td>
                    </tr>
                    
                    <tr style={{ font: is_mobile ? "14px/16px Futura Md BT" : "14px/16px Futura Md BT", color: "#000000", backgroundColor:"#dadada", }}>
                        <td >
                            <div style={{ paddingLeft: "2rem", paddingTop:10, paddingBottom:10}} >Room price:</div>
                        </td>
                        <td >
                            <div style={{ paddingRight: "2rem", paddingTop:10, paddingBottom:10, textAlign:"right", color: "#000000", }}>{booking_bundle.rate_symbol}{Number(booking_bundle.rate_avg).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}/night</div>
                        </td>
                    </tr>
                    
                    <tr style={{ font: is_mobile ? "14px/16px Futura Md BT" : "14px/16px Futura Md BT", color: "#707070", }}>
                        <td >
                            <div style={{ paddingLeft: "2rem", paddingTop:10}} >{booking_info.num_rooms} rooms x {booking_info.num_nights} nights:</div>
                        </td>
                        <td >
                            <div style={{ paddingRight: "2rem", paddingTop:10, textAlign:"right", color: "#000000", }}>{booking_bundle.rate_symbol}{Number(booking_bundle.rate_total - booking_bundle.rate_fee-booking_bundle.rate_processing_fee).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                        </td>
                    </tr>
                    
                    {Number(booking_bundle.rate_insurance_fee)!==0 && 
                      <tr style={{ font: is_mobile ? "12px/16px Futura Md BT" : "12px/16px Futura Md BT", color: "#707070", }}>
                        <td >
                            <div style={{ paddingLeft: "2rem", paddingTop:10}} >Insurance fees:</div>
                        </td>
                        <td >
                            <div style={{ paddingRight: "2rem", paddingTop:10, textAlign:"right", color: "#000000", }}>{booking_bundle.rate_symbol}{Number(booking_bundle.rate_insurance_fee).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                        </td>
                    </tr>}
                    
                    {Number(booking_bundle.rate_processing_fee)!==0 && 
                      <tr style={{ font: is_mobile ? "12px/16px Futura Md BT" : "12px/16px Futura Md BT", color: "#707070", }}>
                        <td >
                            <div style={{ paddingLeft: "2rem", paddingTop:10}} >Processing fees:</div>
                        </td>
                        <td >
                            <div style={{ paddingRight: "2rem", paddingTop:10, textAlign:"right", color: "#000000", }}>{booking_bundle.rate_symbol}{Number(booking_bundle.rate_processing_fee).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                        </td>
                    </tr>}
                    
                    <tr style={{ font: is_mobile ? "12px/16px Futura Md BT" : "12px/16px Futura Md BT", color: "#707070", }}>
                        <td >
                            <div style={{ paddingLeft: "2rem"}} >Taxes and fees:</div>
                        </td>
                        <td >
                            <div style={{ paddingRight: "2rem", paddingTop:10, paddingBottom:10, textAlign:"right", color: "#000000", }}>{booking_bundle.rate_symbol}{Number(booking_bundle.rate_fee).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                        </td>
                    </tr>
                    
                    <tr style={{ font: is_mobile ? "14px/18px Futura Hv BT" : "14px/24px Futura Hv BT", color: "#000000", backgroundColor:"#dadada", }}>
                        <td >
                            <div style={{ paddingLeft: "2rem", paddingTop:10, paddingBottom:10}} >TOTAL</div>
                        </td>
                        <td >
                            <div style={{ paddingRight: "2rem", textAlign:"right", color: "#000000", }}>{booking_bundle.rate_currency} {booking_bundle.rate_symbol}{Number(booking_bundle.rate_total).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                        </td>
                    </tr>
                    
                    
                    </tbody></table>)                    
             })
            }    
        </div>
    )
}

const mapStateToProps = (state) => ({

    establishment: state.FormBank.HotelSearch.establishment,
    is_mobile: state.Setting.is_mobile,
    lang: state.Setting.lang,
    uuid: state.Member.authModal.uuid,

    switchFeatureContainer: state.FormBank.FeatureFormat,
    booking_info: state.FormBank.BookingPriceline.booking_info,
    //booking_bundles: state.FormBank.BookingPriceline.booking_bundles
})

const mapDispatchToProps = {
    removeBundleFromHotelBooking: Actions.removeBundleFromHotelBooking
}

const BookingItems = connect(mapStateToProps, mapDispatchToProps)(BookingItems_)
export default BookingItems
