
import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit'
import Axios from 'axios';

import { environment } from '../../../Environments/environment';
import { GetAsyncHelper } from '../../../redux/interfaces/types';


const initialState = {
    loading: false,
    subDestinations: []
}


export const getSubDestinations = createAsyncThunk(
    'DestinationSlice/getSubDestinations',
    async (query, thunkAPI) => {
      const dispatch = thunkAPI.dispatch;
      try {
  
        const id = query;
        // "destination/getSubDestinations/en?destinationId=2&limit=5&offset=0";
        let apiURL = environment.apiUrl + `destination/getSubDestinations/en?destinationId=${id}`;
  
        const response = await GetAsyncHelper(apiURL);
  
        if (response && response.statusText === "OK") {
          return response?.data?.result;
        }
  
      }
      catch {
       
        return null;
      }
    }
  )

export const DestinationSlice = createSlice({
    name: 'DestinationSlice',
    initialState: initialState,
    reducers: {
        updateSubDestinations: (state, action) => {
        },
    },
    extraReducers: {
        [getSubDestinations.pending]: (state, action) => {
        },
        [getSubDestinations.fulfilled]: (state, action) => {
            state.subDestinations = action.payload;
        },
    },
})

export const { updateSubDestinations } = DestinationSlice.actions

export const DestinationSliceReducer = DestinationSlice.reducer;