import { current } from "@reduxjs/toolkit";
import { TimeStringToSeconds, timeToDecimal } from "../../../../utility/utility";

const checkEmpty =(obj)=> {
    for(var prop in obj) {
        if(obj.hasOwnProperty(prop))
            return false;
    }

    return true;
}

export const formatFlightData = (state, action) => {
    let itinerariesArry = [];
    let flightListArry = [];
    let airlinesArray = []

    const itinerary = action?.payload?.itinerary_data;
    const hasData = itinerary[Object.keys(itinerary)[0]];
    const isEmpty= checkEmpty(hasData)
    const maxLimit =  state?.flightListData && state?.flightListData?.length < 100;

    if (!isEmpty && maxLimit) {
        Object.keys(action.payload.itinerary_data).forEach((key, index) => 
        {
            const data = action.payload.itinerary_data[key];
            const isEmptyData= checkEmpty(data);
            if(!isEmptyData){
                itinerariesArry.push(data);
            }
           
        })

        flightListArry = itinerariesArry.map((data) => {

            let sliceArray = []
            //format slice Data
            if (data?.slice_data) {
                Object.keys(data?.slice_data).forEach((key, index) => {
                    let tempArray = []

                    //format flight Data
                    Object.keys(data.slice_data[key].flight_data).forEach((flightkey, flightIndex) => tempArray.push(data.slice_data[key].flight_data[flightkey]));
                    data.slice_data[key].flight_data = tempArray

                    //format airlines
                    let airlineValue = data.slice_data[key].airline;
                    airlineValue.show = true;
                    airlinesArray.push(airlineValue)

                    sliceArray.push(data.slice_data[key])
                });

                data.slice_data = sliceArray;
                ///add all filters here
                data.airline = true;
                data.stops = true;
                data.duration = true;
                data.prices = true;
                data.times = true;
                return data;
            }


        });




        state.airlines = state.airlines.concat(airlinesArray);
        state.airlines = [...new Map(state.airlines.map(item => [item.code, item])).values()] //removeDuplicates
        state.airlines.sort((a, b) => a.name.localeCompare(b.name)) //sort

        state.itineraries = state.itineraries.concat(itinerariesArry);


        state.flightListData = state.flightListData.concat(flightListArry);


        // state.loading = false;

        let searchArray = [];

        if (action?.payload?.search_data) {
            Object.keys(action.payload.search_data).forEach((key, index) => searchArray.push(action.payload.search_data[key]));
        }


        state.searchData = state.searchData.concat(searchArray);

        state.searchData = [...new Map(state.searchData.map(item => [item.departure_date, item])).values()] //removeDuplicates


        state.searchData.forEach((flight, index) => {
            flight.departureTimes = []
            flight.arrivalTimes = []



            state.flightListData.forEach((data, index) => {
                if (data?.slice_data) {
                    data.slice_data.forEach((slice, sliceIndex) => {
                        if (flight.origin.code === slice.departure.airport.code) {
                            flight.departureTimes.push(timeToDecimal(slice.departure.datetime.time_24h));
                        }
                        if (flight.destination.code === slice.arrival.airport.code) {
                            flight.arrivalTimes.push(timeToDecimal(slice.arrival.datetime.time_24h));
                        }
                    })
                }


            })
        })


        if (state?.flightListData && state?.flightListData.length > 0) {
            state.minPrice = Math.round(Math.min(...state.flightListData.map(item => item?.price_details?.display_total_fare_per_ticket)));
            state.maxPrice = Math.round(Math.max(...state.flightListData.map(item => item?.price_details?.display_total_fare_per_ticket)));


            state.minDurationSeconds = Math.min(...state.flightListData.map(item => {
                let totalTime = 0;
                if (item?.slice_data) {
                    item.slice_data.forEach((slice, index) => {
                        totalTime += TimeStringToSeconds(slice?.info.duration);
                    })
                }

                return totalTime;
            }))
            state.maxDurationSeconds = Math.max(...state.flightListData.map(item => {
                let totalTime = 0;
                if (item?.slice_data) {
                    item.slice_data.forEach((slice, index) => {
                        totalTime += TimeStringToSeconds(slice?.info.duration);
                    })
                }

                return totalTime;
            }))
        }


    }

    else{
        state.reachedEnd = true;
        state.loading = false;
    }



}

