import * as Actions from '../../../redux/actions';

import {
    ConnectWithFacebookIcon,
    ConnectWithGoogleIcon,
    ConnectWithInstagramIcon,
    ConnectWithMailIcon,
} from "../Common/SVGBank"
import React, { Component } from 'react'

import { connect } from 'react-redux'
import { useHistory } from "react-router-dom"

const RequestToJoin_ = ({ screenSize, setSwitchProfileContainer }) => {

    const history = useHistory()

    const navigateToRegister = () => {
        history.push("/register")
    }

    let margin_top = 30
    let text_left = "text-left"
    let text_right = "text-right"
    let container_margin = "50px"
    let container_width = "460px"
    let container_height = "200px"
    let size_icon = "90"
    let left_icon = "40%"
    const screen_at = 1025
    if (!isNaN(screenSize)) {
        // topImageHeight = screenSize < screen_at ? screenSize / 1.8 : screenSize / 2.1
        margin_top = screenSize < screen_at ? 10 : 30
        text_left = screenSize < screen_at ? "text-center" : "text-left"
        text_right = screenSize < screen_at ? "text-center" : "text-right"
        container_margin = screenSize < screen_at ? "10px" : "20px"
        container_width = screenSize < screen_at ? "280px" : "460px"
        container_height = screenSize < screen_at ? "120px" : "200px"
        size_icon = screenSize < screen_at ? "45" : "90"
        left_icon = screenSize < screen_at ? "43%" : "40%"
    }
    return (
        <div>
            <div className={"margin_top_" + margin_top + " futura_hv_bt_36_36 text-center"}>Request to join</div>
            <div className={"row ml-0 mr-0 margin_top_30"}>
                <div className={"col-md-6 col-xs-12 " + text_right} >
                    <div className="d-inline-block text-center position-relative " style={{ margin: container_margin, width: container_width, height: container_height, background: "#F8F8F8 0% 0% no-repeat padding-box" }}>
                        <ConnectWithMailIcon className={"position-absolute"} style={{ left: left_icon, right: "0px", top: "-20%" }} width={size_icon} height={size_icon} />
                        <div
                            className="futura_hv_bt_24_28 position-absolute text-center" style={{ left: "0px", right: "0px", top: "45%", cursor: "pointer" }}
                            onClick={() => { navigateToRegister() }}
                        >
                            Connect with mail
                            </div>
                    </div>
                </div>
                <div className={"col-md-6 col-xs-12 " + text_left} >
                    <div className="d-inline-block text-center position-relative " style={{ margin: container_margin, width: container_width, height: container_height, background: "#F8F8F8 0% 0% no-repeat padding-box" }}>
                        <ConnectWithFacebookIcon className={"position-absolute"} style={{ left: left_icon, right: "0px", top: "-20%" }} width={size_icon} height={size_icon} />
                        <div className="futura_hv_bt_24_28 position-absolute text-center" style={{ left: "0px", right: "0px", top: "45%" }}>
                            Connect with Facebook <span style={{fontWeight:100}}>(soon)</span>
                            </div>
                    </div>
                </div>
                <div className={"col-md-6 col-xs-12 " + text_right} >
                    <div className="d-inline-block text-center position-relative " style={{ margin: container_margin, width: container_width, height: container_height, background: "#F8F8F8 0% 0% no-repeat padding-box" }}>
                        <ConnectWithGoogleIcon className={"position-absolute"} style={{ left: left_icon, right: "0px", top: "-20%" }} width={size_icon} height={size_icon} />
                        <div className="futura_hv_bt_24_28 position-absolute text-center" style={{ left: "0px", right: "0px", top: "45%" }}>
                            Connect with Google  <span style={{fontWeight:100}}>(soon)</span>
                            </div>
                    </div>
                </div>
                <div className={"col-md-6 col-xs-12 " + text_left} >
                    <div className="d-inline-block text-center position-relative " style={{ margin: container_margin, width: container_width, height: container_height, background: "#F8F8F8 0% 0% no-repeat padding-box" }}>
                        <ConnectWithInstagramIcon className={"position-absolute"} style={{ left: left_icon, right: "0px", top: "-20%" }} width={size_icon} height={size_icon} />
                        <div className="futura_hv_bt_24_28 position-absolute text-center" style={{ left: "0px", right: "0px", top: "45%" }}>
                            Connect with Instagram  <span style={{fontWeight:100}}>(soon)</span>
                            </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        screenSize: state.Setting.htmlBody.bodyClientWidth,
    }
};

const mapDispatchToProps = {
    setSwitchProfileContainer: Actions.setSwitchProfileContainer,

}

const RequestToJoin = connect(mapStateToProps, mapDispatchToProps)(RequestToJoin_)
export default RequestToJoin
