import * as Actions from '../../../redux/actions'

import { member_area_router, validate, validate_fields } from "../Common/RegExValidate"

import Banner from '../Common/Banner.jsx'
import Header from '../Header/Header.jsx'
import ItineraryMap from "../Common/Trip/ItineraryMap"
import Loader from 'react-loader-spinner'
import MemberActionsInitial from "../Common/MemberActions/MemberActionsInitial"
import MemberAreaContainer from "../SocialPage/MemberAreaContainer"
import PageNotFound from '../ErrorPage/PageNotFound.jsx'
import PrivateTripEstablishment from "../Common/Trip/PrivateTripEstablishment"
import Profile from '../Common/Profile/ProfileBar.jsx'
import React from 'react'
import { Redirect } from "react-router-dom"
import TimelineWizardContainer from "../Common/TimelineWizardContainer"
import TripComtainer from "../Common/Trip/TripContainer"
import { connect } from 'react-redux'
import { list_container_mode } from '../Common/RegExValidate'
import TermsConditionContainer from './TermsConditionContainer'
import TermsTopBar from './TermsConditionTopBar'

class TermsConditionPage_ extends React.Component {

    /* update screen size */
    updateDimensions = () => {
        this.props.setScreenSize(window.screen.width, window.screen.height)
        this.props.setBodyClientSize(document.body.clientWidth, document.documentElement.scrollHeight)
        this.props.setAppMenu(document.body.clientWidth)
    }
    /* initial component */
    componentDidMount() {
        window.addEventListener("resize", this.updateDimensions)
        this.props.setSwitchContainer(member_area_router.starred)
        this.updateDimensions()
        this.props.setAppMenuBookingButtonStatus(true)
    }
    /* destory component */
    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions)
    }
    render() {
        const { preload, switch_container, lang, tripMode } = this.props
        return (
            validate(lang, validate_fields.languages) ?
                <div style={{
                    height: "auto",
                }}>
                    {false ? <Loader
                        type="Rings"
                        color="#19BC9B"
                        className="se-pre-con"
                        height={150}
                        width={150}
                        style={{ backgroundColor: "#FFFFFF" }} /> : ""}
                    {switch_container === member_area_router.starred && 
                    <React.Fragment>
                         <Header bg_color={"#FFFFFF"} text_color={"var(--frameBlack)"} />
                    		<TermsTopBar/>
                            <TermsConditionContainer />
                            </React.Fragment>}

                    {switch_container === member_area_router.memberAreaTrip &&
                        <React.Fragment>
                            {tripMode === list_container_mode.map ?
                                <React.Fragment>
                                    <Header bg_color={"rgba(25,25,25, 1)"} text_color={"var(--mainWhite)"} header_height={"half"} />
                                    <TimelineWizardContainer listDisplayFormat={list_container_mode.map} />
                                    {window.google !== undefined && <ItineraryMap />}
                                </React.Fragment> :
                                (tripMode === list_container_mode.timeline ?
                                    <React.Fragment>
                                        <Header bg_color={"rgba(25,25,25, 1)"} text_color={"var(--mainWhite)"} />
                                        <TimelineWizardContainer listDisplayFormat={list_container_mode.timeline} />
                                        <PrivateTripEstablishment />
                                    </React.Fragment> :
                                    <TripComtainer />)}
                        </React.Fragment>}
                    {(switch_container === member_area_router.trips
                        || switch_container === member_area_router.bucketList
                        || switch_container === member_area_router.favorites
                        || switch_container === member_area_router.personalInfo
                        || switch_container === member_area_router.muvTribe)
                        &&
                        <React.Fragment>
                            <Header bg_color={"rgba(25,25,25, 1)"} text_color={"var(--mainWhite)"} />
                            <Banner />
                            <Profile />
                            <MemberAreaContainer />
                        </React.Fragment>}

                    <MemberActionsInitial />
                </div >
                :
                <Redirect to={"error"} component={PageNotFound} />
        )
    }
}


const mapStateToProps = state => {
    return {
        switch_container: state.Member.switch_container,
        preload: state.Setting.preload,
        screenSize: state.Setting.htmlBody.bodyClientWidth,
        lang: state.Setting.lang,
        tripMode: state.Member.tripMode,
    }
}

const mapDispatchToProps = {

    setSwitchContainer: Actions.setSwitchContainer,
    setBodyClientSize: Actions.setBodyClientSize,
    setScreenVertical: Actions.setScreenVertical,

    setScreenSize: Actions.setScreenSize,
    setAppMenu: Actions.setAppMenu,
    setScreenVerticalIsTop: Actions.setScreenVerticalIsTop,
    setAppMenuBookingButtonStatus: Actions.setAppMenuBookingButtonStatus,
}

const TermsConditionPage = connect(mapStateToProps, mapDispatchToProps)(TermsConditionPage_)
export default TermsConditionPage 
