import * as Actions from '../../../redux/actions'

import { AngleUpIcon, PlusSignIcon } from '../Common/SVGBank';
import React, { Component } from 'react'
import { animated, useSpring } from 'react-spring'
import { image_boxing, list_container_mode, loadingImage } from '../Common/RegExValidate'
import AddedToTrip from '../Common/AddedToTrip'
import CircularProgress from '@material-ui/core/CircularProgress'
import { EstablishmentDescription } from '../Common/EstablishmentDescription'
import EstablishmentName from '../Common/Establishment/EstablishmentName'
import Fab from '@material-ui/core/Fab';
import { Heart } from '../Common/MemberActions/Heart'
import {
    Image,
} from 'react-bootstrap';
import Influencers from '../Common/Influencers/Influencers.jsx'
import LazyLoadingScrollEventListener from './LazyLoadingScrollEventListener'
import { Plus } from '../Common/MemberActions/Plus'
import Rating from '@material-ui/lab/Rating'
import { ScrollTopLazyLoading } from "../Common/ScrollTopLazyLoading"
import { Star } from '../Common/MemberActions/Star'
import { connect } from 'react-redux'
import { member_area_router } from "../Common/RegExValidate"
import ActionsEllipsis from '../Common/actionsEllipsis';
import { Virtuoso } from 'react-virtuoso'
import { Progress } from '../Common/Progress';
import { Done } from '../Common/MemberActions/Done';

class Establishments_ extends Component {

    onDragOverEstablishment = (event) => {
        event.preventDefault()
        event.stopPropagation()
    }

    onDragEndEstablishment = (event) => {
        this.props.setTimelineDraggingEstablishment(null, "")
        event.preventDefault()
        event.stopPropagation()
    }

    onDragStartEstablishment = (event, establishment) => {
        this.props.setTimelineDraggingEstablishment(establishment, this.props.destinationId)
        // event.dataTransfer.setDragImage(document.getElementById('drag_establishment_' + establishment.establishmentID), 0, 0)
    }

    onDropDeleteActivities = (event) => {
        this.props.draggingActivity !== undefined &&
            this.props.draggingActivity.dragDeleteActivity !== undefined &&
            this.props.draggingActivity.dragDeleteActivity !== null &&
            this.props.deleteActivityToDayOfTripDestinationInTrip(
                this.props.draggingActivity.dragDeleteActivity.lang,
                this.props.draggingActivity.dragDeleteActivity.uuid,
                this.props.draggingActivity.dragDeleteActivity.tripDestinationId,
                this.props.draggingActivity.dragDeleteActivity.activityID,
                this.props.draggingActivity.dragDeleteActivity.tripId)
    }

    render() {
        const { switch_container, fetchEstablishmentsLazyloading, establishments, lang, uuid_auth, activeTrip, timelineWizardStatus, destinationId, activeTripDestinationId, activeTripDay, addActivityToTripDestinationInTrip, bodyClientWidth, isFetchingEstablishments, isMobile, destinaionListDisplayFormat, switchFeatureContainer, addSelectedSubTypeDestination, deleteSelectedSubTypeDestination, selectedSubtypes, lazyLoading, activeTripActivities } = this.props
        const activeTripId = activeTrip.tripId
        const activeDayInTripDestination = activeTripDay.dayInTripDestination

        let activeDestination = activeTrip.tripDestinations && activeTrip.tripDestinations.find(destination => destination.td_id === activeTripDestinationId);
        const daysInDestination = activeDestination && activeDestination.spanningDays;
        const activitiesInActiveDestinationDay = activeTrip.activities && activeTrip.activities.filter(a => a.activityDayInDestination === activeTripDay.dayInTripDestination && a.tripDestinationId === activeTripDay.tripDestinationId)
        const lastActivityInDay = activitiesInActiveDestinationDay && activitiesInActiveDestinationDay.length > 0 &&  activitiesInActiveDestinationDay[activitiesInActiveDestinationDay.length-1]
        const lastActivityTime = lastActivityInDay && addTimes(lastActivityInDay.activityStartTime,lastActivityInDay.activityDuration);
  
        const nextDayInTrip = activeTripDay && activeTripDay.dayInTrip && daysInDestination && daysInDestination.includes(Number(activeTripDay.dayInTrip)+1) ? Number(activeTripDay.dayInTrip)+1 : activeTripDay.dayInTrip   ;
        const nextDayInTripDestination = activeTripDay && activeTripDay.dayInTripDestination && daysInDestination && daysInDestination.includes(Number(activeTripDay.dayInTrip)+1) ? Number(activeTripDay.dayInTripDestination)+1: activeTripDay.dayInTripDestination ;

        const after6pmCutoff = lastActivityTime && compareTimes(lastActivityTime,"18:00");

        let widthContainer = ""
        let heightContainer = ""
        let gridContainer = ""
        let postionContainer = ""
        let positionScrollToTopLazyLoading = ""
        let positionInfluencers = establishments !== undefined && establishments.length < 5 ? establishments.length : 3
        const uuid = uuid_auth !== "" ? uuid_auth : "TMPUUID"
        if (!isNaN(isMobile)) {
            widthContainer = isMobile ? Math.floor(bodyClientWidth * 0.98) : Math.floor(timelineWizardStatus ? ((bodyClientWidth - 380) * 0.65 / 2) : (bodyClientWidth * 0.65 / 2))
            heightContainer = isMobile ? "" : Math.floor(timelineWizardStatus ? ((bodyClientWidth - 380) * 0.65 / 2 / 2) : (bodyClientWidth * 0.65 / 2 / 2))
            postionContainer = isMobile ? "block" : "d-flex"
            gridContainer = isMobile ? Math.floor(bodyClientWidth * 0.98 - 5) : Math.floor(timelineWizardStatus ? (bodyClientWidth * 0.535 / 4 - 18) : (bodyClientWidth * 0.7 / 4 - 18))
            positionScrollToTopLazyLoading = isMobile ? "" : (timelineWizardStatus ? "10%" : "10%")
        }

        const activitiesInDestinationDay = activeTrip.activities.filter(a => a.activityDay === activeTripDay.dayInTrip && a.tripDestinationId === activeTripDay.tripDestinationId)
        const lengthActivitiesInDestinationDay = activitiesInDestinationDay !== undefined ? activitiesInDestinationDay.length : 0
        const activitiesInDestinationDayEstablishmentIds = Array.from(activitiesInDestinationDay.map((a) => a.activityEstabID))
        const last_establishmentId = activitiesInDestinationDayEstablishmentIds[lengthActivitiesInDestinationDay - 1] !== undefined ? activitiesInDestinationDayEstablishmentIds[lengthActivitiesInDestinationDay - 1] : ""

        const activeTripDestinationIds = Array.from(new Set(activeTrip.tripDestinations.map(d => d.id)))
        const tripDuration = activeTrip.tripDuration

        const handleAddActivityToTripDestinationInTrip = (lang, uuid, activeTripDestinationId, activeDayInTripDestination, establishmentID, activeTripId, last_establishmentId, tripDuration, destinationId, activeTripDestinationIds,establishment) => {
            addActivityToTripDestinationInTrip(lang, uuid, activeTripDestinationId, activeDayInTripDestination, establishmentID, activeTripId, last_establishmentId, tripDuration, destinationId, activeTripDestinationIds,
                true, 1,3, "",
                after6pmCutoff,nextDayInTripDestination,nextDayInTrip,establishment.typeID)
                // const scrolltoLastActivityID = activitiesInActiveDestinationDay && activitiesInActiveDestinationDay.length > 0 && activitiesInActiveDestinationDay[activitiesInActiveDestinationDay.length -1].activityID;
               
            
                // const activitiesInNextDestinationDay = activeTrip.activities && activeTrip.activities.filter(a => a.activityDayInDestination == nextDayInTripDestination && a.tripDestinationId === activeTripDay.tripDestinationId)
                // const scrolltoNextLastActivityID = activitiesInNextDestinationDay && activitiesInNextDestinationDay.length > 0 && activitiesInNextDestinationDay[Math.round((activitiesInNextDestinationDay.length -1) / 2)].activityID;


                // if(establishment.typeID && (establishment.typeID !== "3" || establishment.typeID !== "4" || establishment.typeID !== "5") && after6pmCutoff){
                //     document.getElementById(activeTripDay.tripDestinationId + "_" + nextDayInTripDestination + "_" + nextDayInTrip) !== null && document.getElementById(activeTripDay.tripDestinationId + "_" + nextDayInTripDestination + "_" + nextDayInTrip).scrollIntoView({ behavior: 'smooth', block: 'start' })
                    
                
                // }
                // else{
                //     document.getElementById(activeTripDay.tripDestinationId + "_" + activeTripDay.dayInTripDestination + "_" + activeTripDay.dayInTrip) !== null && document.getElementById(activeTripDay.tripDestinationId + "_" + activeTripDay.dayInTripDestination + "_" + activeTripDay.dayInTrip).scrollIntoView({ behavior: 'smooth', block: 'start' })
                //  }

                
              
          
        }



        const loadMore = () => {
            fetchEstablishmentsLazyloading(lazyLoading)
        }


        return (
            <React.Fragment>

                { switch_container === member_area_router.memberAreaTrip ?

                    <div
                        style={{
                            zIndex: "1",
                        }}>
                        <div id="back_to_top_establishment"></div>
                        {establishments !== undefined && establishments.length > 0
                            && establishments.map((establishment, establishment_idx) => {
                                const availableDeleteActivities = activeTripActivities.filter(a => a.activityEstabID === establishment.establishmentID)
                                return (
                                    <React.Fragment key={establishment_idx}>
                                        <div
                                            draggable={!isFetchingEstablishments ? true : false}
                                            onDragOver={(e) => !isFetchingEstablishments ? this.onDragOverEstablishment(e) : {}}
                                            onDragEnd={(e) => !isFetchingEstablishments ? this.onDragEndEstablishment(e) : {}}
                                            onDragStart={(e) => !isFetchingEstablishments ? this.onDragStartEstablishment(e, establishment) : {}}
                                            onDrop={(e) => !isFetchingEstablishments ? this.onDropDeleteActivities(e) : {}}

                                            className={destinaionListDisplayFormat === list_container_mode.grid ? (!isFetchingEstablishments ? "d-inline-block draggable" : "") : (!isFetchingEstablishments ? "draggable" : "")}>
                                            {destinaionListDisplayFormat === list_container_mode.list &&
                                                <div key={establishment.establishmentID}>
                                                    <div className={timelineWizardStatus ? "margin_top_10" : "margin_top_20"}>
                                                        <div className={postionContainer}>
                                                            <div className="d-inline-block" style={{ width: widthContainer }}><Image
                                                                src={establishments !== undefined && establishments.length > 0 ? image_boxing(establishment.estabPicture, widthContainer, heightContainer) : loadingImage.src + "?func=crop&w=" + Math.floor(widthContainer) + "&h=" + Math.floor(heightContainer)}
                                                                alt="establishment" /></div>
                                                            <div style={{ width: widthContainer + "px", minHeight: heightContainer + "px", border: "1px solid #E8E8E8", verticalAlign: "middle", flexGrow: "1", paddingLeft: "10px", position: "relative" }}>

                                                                <div style={{ position: "relative", height: "25px" }} className={timelineWizardStatus ? "margin_top_10 box_1 padding_left_10 padding_right_20 text-right" : "margin_top_20 box_1 padding_left_10 padding_right_20 text-right"} >
                                                                    <div style={{ position: "absolute", top: "0px", right: "140px" }}>
                                                                        <Done
                                                                            unitID={establishment.establishmentID}
                                                                            feature={switchFeatureContainer}
                                                                            fill={"var(--mainGreen)"} />
                                                                    </div>

                                                                    <div style={{ position: "absolute", top: "0px", right: "100px" }}>
                                                                        <Heart
                                                                            unitID={establishment.establishmentID}
                                                                            feature={switchFeatureContainer}
                                                                            fill={"var(--mainGreen)"} />
                                                                    </div>
                                                                    <div style={{ position: "absolute", top: "0px", right: "60px" }}>
                                                                        <Plus
                                                                            unitID={establishment.establishmentID}
                                                                            feature={switchFeatureContainer}
                                                                            fill={"var(--mainGreen)"} />
                                                                    </div>
                                                                    <div style={{ position: "absolute", top: "0px", right: "20px" }}>
                                                                        <Star
                                                                            unitID={establishment.establishmentID}
                                                                            feature={switchFeatureContainer}
                                                                            fill={"var(--mainGreen)"} />
                                                                    </div>
                                                                </div>
                                                                {timelineWizardStatus && <div style={{ textAlign: "right", position: "absolute", bottom: "5px", right: "0px", zIndex: "2", }}>
                                                                    {availableDeleteActivities.length > 0 && !isFetchingEstablishments &&
                                                                        <AddedToTrip
                                                                            establishmentName={establishment.establishmentName}
                                                                            establishmentStarRating={establishment.establishmentStarRating}
                                                                            availableDeleteActivities={availableDeleteActivities}
                                                                            textFont={"14px/14px Futura Hv BT"}
                                                                            text={"Added to trip"}
                                                                        />}
                                                                    {!isFetchingEstablishments ? <React.Fragment>
                                                                        {availableDeleteActivities.length > 0 ?
                                                                            <div onClick={() => handleAddActivityToTripDestinationInTrip(lang, uuid, activeTripDestinationId, activeDayInTripDestination, establishment.establishmentID, activeTripId, last_establishmentId, tripDuration, destinationId, activeTripDestinationIds,establishment)} style={{ cursor: "pointer", color: "#707170", font: "12px/12px Futura Lt BT", padding: "0px 10px", display: "inline-block" }}><PlusSignIcon fill={"#707170"} width={7} height={10} style={{ marginRight: "5px" }} />add again</div>
                                                                            : <div onClick={() => handleAddActivityToTripDestinationInTrip(lang, uuid, activeTripDestinationId, activeDayInTripDestination, establishment.establishmentID, activeTripId, last_establishmentId, tripDuration, destinationId, activeTripDestinationIds,establishment)} style={{ cursor: "pointer", color: "var(--mainGreen)", font: "14px/14px Futura Hv BT", padding: "0px 10px", display: "inline-block" }}><PlusSignIcon width={10} height={13} style={{ marginRight: "5px" }} />Add to trip</div>
                                                                        }
                                                                    </React.Fragment> : <CircularProgress
                                                                        disableShrink
                                                                        size={15}
                                                                        style={{
                                                                            color: "#6A9EEC",
                                                                            textAlign: "right",
                                                                            marginTop: "15px",
                                                                            marginRight: "5px",
                                                                        }} />
                                                                    }
                                                                </div>}
                                                                <div className="box_1 padding_left_10 padding_right_10" style={{
                                                                    font: "14px/16px Futura Md BT",
                                                                    color: "var(--mainGreen)",
                                                                }}>
                                                                    {establishment.establishmentNeighborhood}
                                                                </div>
                                                                <EstablishmentName
                                                                    establishmentName={establishment.establishmentName}
                                                                    establishmentCity={establishment.establishmentCity}
                                                                    establishmentState={establishment.establishmentState}
                                                                    establishmentCountry={establishment.establishmentCountry}
                                                                    establishmentId={establishment.establishmentID}
                                                                    titleClass={"box_1"} titleStyle={{
                                                                        font: timelineWizardStatus ? "22px/33px Futura Hv BT" : "28px/43px Futura Hv BT",
                                                                        color: "#FFFFFF",
                                                                        cursor: "pointer",
                                                                        paddingLeft: "10px",
                                                                        paddingRight: "10px",
                                                                    }} />

                                                                {establishment.establishmentStarRating !== "0.0" && <div className="d-flex timeline_rating_color padding_left_10 padding_right_10 btn_rounded">
                                                                    <Rating name="timeline_rating" precision={0.5} max={5} value={parseFloat(establishment.establishmentStarRating)} disabled={true} />
                                                                    <div className="futura_md_bt_11_14_black margin_bottom_5 margin_left_5" style={{ color: "#FFFFFF" }}>({establishment.establishmentStarRating})</div>
                                                                </div>}
                                                                {establishment.establishmentDescription !== undefined && <div className={"padding_left_10 padding_right_10"}><EstablishmentDescription description={establishment.establishmentDescription} boxDescription={"box_3"} fontDescription={"14px/19px Futura Lt BT"} colorDescription={"var(--mainGrey)"} height={"57px"} /></div>}
                                                                <div className={timelineWizardStatus ? "padding_left_10 padding_right_10 text-left box_1 margin_top_5 margin_bottom_5" : "padding_left_10 padding_right_10 text-left box_1 margin_top_20 margin_bottom_10"}>
                                                                    {establishment.subTypes !== undefined && establishment.subTypes.length > 0 ?
                                                                        establishment.subTypes.map((subtype, subtype_idx) => {
                                                                            return <div key={subtype_idx}
                                                                                className="btn_rounded m-1 futura_md_bt_14_16_green upcoming_trip_style"
                                                                                style={{ cursor: "pointer" }}
                                                                                onClick={() => {
                                                                                    selectedSubtypes.find(val => val.id === subtype.subTypeID) ?
                                                                                        deleteSelectedSubTypeDestination({ id: subtype.subTypeID, title: subtype.subTypeName }, lazyLoading, selectedSubtypes)
                                                                                        : addSelectedSubTypeDestination({ id: subtype.subTypeID, title: subtype.subTypeName }, lazyLoading, selectedSubtypes)
                                                                                }}>
                                                                                {subtype.subTypeName}</div>
                                                                        })
                                                                        : ""}
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            {destinaionListDisplayFormat === list_container_mode.grid &&
                                                <PlacesElement
                                                    establishments={establishments}
                                                    establishment={establishment}
                                                    destinationId={destinationId}
                                                    lang={lang}
                                                    uuid={uuid}
                                                    gridContainer={gridContainer}
                                                    selectedSubtypes={selectedSubtypes}
                                                    switchFeatureContainer={switchFeatureContainer}
                                                    timelineWizardStatus={timelineWizardStatus}
                                                    availableDeleteActivities={availableDeleteActivities}
                                                    isFetchingEstablishments={isFetchingEstablishments}
                                                    handleAddActivityToTripDestinationInTrip={handleAddActivityToTripDestinationInTrip}
                                                    activeTripDestinationId={activeTripDestinationId}
                                                    activeDayInTripDestination={activeDayInTripDestination}
                                                    activeTripId={activeTripId}
                                                    last_establishmentId={last_establishmentId}
                                                    tripDuration={tripDuration}
                                                    activeTripDestinationIds={activeTripDestinationIds}
                                                    addSelectedSubTypeDestination={addSelectedSubTypeDestination}
                                                    deleteSelectedSubTypeDestination={deleteSelectedSubTypeDestination}
                                                    lazyLoading={lazyLoading}
                                                    textColor={"#FFFFFF"}
                                                />}

                                            <React.Fragment>
                                                {destinaionListDisplayFormat === list_container_mode.list && establishment_idx === positionInfluencers && <Influencers from={"establishment"} />}
                                                {
                                                    // timelineWizardStatus && <Image
                                                    //     id={"drag_establishment_" + establishment.establishmentID}
                                                    //     style={{ position: "absolute", right: "200%" }}
                                                    //     src={image_boxing(establishment.estabPicture, 90, 70)}
                                                    //     alt="establishment" />
                                                }
                                            </React.Fragment>
                                        </div>
                                    </React.Fragment>
                                )
                            })
                        }
                        <ScrollTopLazyLoading {...this.props} targetId={"#back_to_top_establishment"} rightPosition={positionScrollToTopLazyLoading}>
                            <Fab size="small" aria-label="scroll back to top" style={{
                                outline: "0px",
                                backgroundColor: "var(--mainGreen)"
                            }}>
                                <AngleUpIcon fill={"var(--mainWhite)"} style={{ marginLeft: "3px" }} />
                            </Fab>
                        </ScrollTopLazyLoading>
                        <LazyLoadingScrollEventListener />
                    </div >
                    : <div
                        style={{
                            zIndex: "1",
                            paddingBottom: 200
                        }}>
                        <div id="back_to_top_establishment"></div>
                        {destinaionListDisplayFormat === list_container_mode.grid &&
                            <div>
                                {establishments !== undefined && establishments.length > 0
                                    && establishments.map((establishment, establishment_idx) => {
                                        const availableDeleteActivities = activeTripActivities.filter(a => a.activityEstabID === establishment.establishmentID)
                                        return (
                                            <React.Fragment key={establishment_idx} >
                                                <div
                                                    draggable={!isFetchingEstablishments ? true : false}
                                                    onDragOver={(e) => !isFetchingEstablishments ? this.onDragOverEstablishment(e) : {}}
                                                    onDragEnd={(e) => !isFetchingEstablishments ? this.onDragEndEstablishment(e) : {}}
                                                    onDragStart={(e) => !isFetchingEstablishments ? this.onDragStartEstablishment(e, establishment) : {}}
                                                    onDrop={(e) => !isFetchingEstablishments ? this.onDropDeleteActivities(e) : {}}

                                                    className={destinaionListDisplayFormat === list_container_mode.grid ? (!isFetchingEstablishments ? "d-inline-block draggable" : "") : (!isFetchingEstablishments ? "draggable" : "")}>

                                                    <PlacesElement
                                                        establishments={establishments}
                                                        establishment={establishment}
                                                        destinationId={destinationId}
                                                        lang={lang}
                                                        uuid={uuid}
                                                        gridContainer={gridContainer}
                                                        selectedSubtypes={selectedSubtypes}
                                                        switchFeatureContainer={switchFeatureContainer}
                                                        timelineWizardStatus={timelineWizardStatus}
                                                        availableDeleteActivities={availableDeleteActivities}
                                                        isFetchingEstablishments={isFetchingEstablishments}
                                                        handleAddActivityToTripDestinationInTrip={handleAddActivityToTripDestinationInTrip}
                                                        activeTripDestinationId={activeTripDestinationId}
                                                        activeDayInTripDestination={activeDayInTripDestination}
                                                        activeTripId={activeTripId}
                                                        last_establishmentId={last_establishmentId}
                                                        tripDuration={tripDuration}
                                                        activeTripDestinationIds={activeTripDestinationIds}
                                                        addSelectedSubTypeDestination={addSelectedSubTypeDestination}
                                                        deleteSelectedSubTypeDestination={deleteSelectedSubTypeDestination}
                                                        lazyLoading={lazyLoading}
                                                        textColor={"#191919"}
                                                    />


                                                </div>
                                            </React.Fragment>
                                        )
                                    })}
                                <LazyLoadingScrollEventListener />
                            </div>



                        }

                        {destinaionListDisplayFormat === list_container_mode.list && <Virtuoso
                            useWindowScroll
                            endReached={loadMore}
                            overscan={400}
                            style={{paddingBottom: 70}}
                            totalCount={establishments.length}
                            itemContent={index => {
                                const establishment_idx = index;
                                const establishment = establishments[index];
                                const availableDeleteActivities = activeTripActivities.filter(a => a.activityEstabID === establishment.establishmentID)

                                return (
                                    <React.Fragment key={establishment_idx} >
                                        <div
                                            draggable={!isFetchingEstablishments ? true : false}
                                            onDragOver={(e) => !isFetchingEstablishments ? this.onDragOverEstablishment(e) : {}}
                                            onDragEnd={(e) => !isFetchingEstablishments ? this.onDragEndEstablishment(e) : {}}
                                            onDragStart={(e) => !isFetchingEstablishments ? this.onDragStartEstablishment(e, establishment) : {}}
                                            onDrop={(e) => !isFetchingEstablishments ? this.onDropDeleteActivities(e) : {}}

                                            className={destinaionListDisplayFormat === list_container_mode.grid ? (!isFetchingEstablishments ? "d-inline-block draggable" : "") : (!isFetchingEstablishments ? "draggable" : "")}>
                                            {destinaionListDisplayFormat === list_container_mode.list &&
                                                <div>
                                                    <div className={timelineWizardStatus ? "margin_top_10" : "margin_top_20"}>
                                                        <div className={postionContainer}>
                                                            <div className="d-inline-block" style={{ width: widthContainer }}><Image
                                                                src={establishments !== undefined && establishments.length > 0 ? image_boxing(establishment.estabPicture, widthContainer, heightContainer) : loadingImage.src + "?func=crop&w=" + Math.floor(widthContainer) + "&h=" + Math.floor(heightContainer)}
                                                                alt="establishment" /></div>
                                                            <div style={{ width: widthContainer + "px", minHeight: heightContainer + "px", border: "1px solid #E8E8E8", verticalAlign: "middle", flexGrow: "1", paddingLeft: "10px", position: "relative" }}>

                                                                <div style={{ position: "relative", height: "25px" }} className={timelineWizardStatus ? "margin_top_10 box_1 padding_left_10 padding_right_20 text-right" : "margin_top_20 box_1 padding_left_10 padding_right_20 text-right"} >

                                                                    <div style={{ position: "absolute", top: "0px", right: "140px" }}>
                                                                        <Done
                                                                            unitID={establishment.establishmentID}
                                                                            feature={switchFeatureContainer}
                                                                            fill={"var(--mainGreen)"} />
                                                                    </div>
                                                                    <div style={{ position: "absolute", top: "0px", right: "100px" }}>
                                                                        <Heart
                                                                            unitID={establishment.establishmentID}
                                                                            feature={switchFeatureContainer}
                                                                            fill={"var(--mainGreen)"} />
                                                                    </div>
                                                                    <div style={{ position: "absolute", top: "0px", right: "60px" }}>
                                                                        <Plus
                                                                            unitID={establishment.establishmentID}
                                                                            feature={switchFeatureContainer}
                                                                            fill={"var(--mainGreen)"} />
                                                                    </div>
                                                                    <div style={{ position: "absolute", top: "0px", right: "20px" }}>
                                                                        <Star
                                                                            unitID={establishment.establishmentID}
                                                                            feature={switchFeatureContainer}
                                                                            fill={"var(--mainGreen)"} />
                                                                    </div>
                                                                </div>
                                                                {timelineWizardStatus && <div style={{ textAlign: "right", position: "absolute", bottom: "5px", right: "0px", zIndex: "2", }}>
                                                                    {availableDeleteActivities.length > 0 && !isFetchingEstablishments &&
                                                                        <AddedToTrip
                                                                            establishmentName={establishment.establishmentName}
                                                                            establishmentStarRating={establishment.establishmentStarRating}
                                                                            availableDeleteActivities={availableDeleteActivities}
                                                                            textFont={"14px/14px Futura Hv BT"}
                                                                            text={"Added to trip"}
                                                                        />}
                                                                    {!isFetchingEstablishments ? <React.Fragment>
                                                                        {availableDeleteActivities.length > 0 ?
                                                                            <div onClick={() => handleAddActivityToTripDestinationInTrip(lang, uuid, activeTripDestinationId, activeDayInTripDestination, establishment.establishmentID, activeTripId, last_establishmentId, tripDuration, destinationId, activeTripDestinationIds,establishment)} style={{ cursor: "pointer", color: "#707170", font: "12px/12px Futura Lt BT", padding: "0px 10px", display: "inline-block" }}><PlusSignIcon fill={"#707170"} width={7} height={10} style={{ marginRight: "5px" }} />add again</div>
                                                                            : <div onClick={() => handleAddActivityToTripDestinationInTrip(lang, uuid, activeTripDestinationId, activeDayInTripDestination, establishment.establishmentID, activeTripId, last_establishmentId, tripDuration, destinationId, activeTripDestinationIds,establishment)} style={{ cursor: "pointer", color: "var(--mainGreen)", font: "14px/14px Futura Hv BT", padding: "0px 10px", display: "inline-block" }}><PlusSignIcon width={10} height={13} style={{ marginRight: "5px" }} />Add to trip</div>
                                                                        }
                                                                    </React.Fragment> : <CircularProgress
                                                                        disableShrink
                                                                        size={15}
                                                                        style={{
                                                                            color: "#6A9EEC",
                                                                            textAlign: "right",
                                                                            marginTop: "15px",
                                                                            marginRight: "5px",
                                                                        }} />
                                                                    }
                                                                </div>}
                                                                <div className="box_1 padding_left_10 padding_right_10" style={{
                                                                    font: "14px/16px Futura Md BT",
                                                                    color: "var(--mainGreen)",
                                                                }}>
                                                                    {establishment.establishmentNeighborhood}
                                                                </div>
                                                                <EstablishmentName
                                                                    establishmentName={establishment.establishmentName}
                                                                    establishmentCity={establishment.establishmentCity}
                                                                    establishmentState={establishment.establishmentState}
                                                                    establishmentCountry={establishment.establishmentCountry}
                                                                    establishmentId={establishment.establishmentID}
                                                                    titleClass={"box_1"} titleStyle={{
                                                                        font: timelineWizardStatus ? "22px/33px Futura Hv BT" : "28px/43px Futura Hv BT",
                                                                        color: "var(--frameBlack)",
                                                                        cursor: "pointer",
                                                                        paddingLeft: "10px",
                                                                        paddingRight: "10px",
                                                                    }} />

                                                                {establishment.establishmentStarRating !== "0.0" && <div className="d-flex timeline_rating_color padding_left_10 padding_right_10 btn_rounded">
                                                                    <Rating name="timeline_rating" precision={0.5} max={5} value={parseFloat(establishment.establishmentStarRating)} disabled={true} />
                                                                    <div className="futura_md_bt_11_14_black margin_bottom_5 margin_left_5">({establishment.establishmentStarRating})</div>
                                                                </div>}
                                                                {establishment.establishmentDescription !== undefined && <div className={"padding_left_10 padding_right_10"}><EstablishmentDescription description={establishment.establishmentDescription} boxDescription={"box_3"} fontDescription={"14px/19px Futura Lt BT"} colorDescription={"var(--mainGrey)"} height={"57px"} /></div>}
                                                                <div className={timelineWizardStatus ? "padding_left_10 padding_right_10 text-left box_1 margin_top_5 margin_bottom_5" : "padding_left_10 padding_right_10 text-left box_1 margin_top_20 margin_bottom_10"}>
                                                                    {establishment.subTypes !== undefined && establishment.subTypes.length > 0 ?
                                                                        establishment.subTypes.map((subtype, subtype_idx) => {
                                                                            return <div key={subtype_idx}
                                                                                className="btn_rounded m-1 futura_md_bt_14_16_green upcoming_trip_style"
                                                                                style={{ cursor: "pointer" }}
                                                                                onClick={() => {
                                                                                    selectedSubtypes.find(val => val.id === subtype.subTypeID) ?
                                                                                        deleteSelectedSubTypeDestination({ id: subtype.subTypeID, title: subtype.subTypeName }, lazyLoading, selectedSubtypes)
                                                                                        : addSelectedSubTypeDestination({ id: subtype.subTypeID, title: subtype.subTypeName }, lazyLoading, selectedSubtypes)
                                                                                }}>
                                                                                {subtype.subTypeName}</div>
                                                                        })
                                                                        : ""}
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }

                                            <React.Fragment>
                                                {destinaionListDisplayFormat === list_container_mode.list && establishment_idx === positionInfluencers && <Influencers from={"establishment"} />}
                                                {
                                                    // timelineWizardStatus && <Image
                                                    //     id={"drag_establishment_" + establishment.establishmentID}
                                                    //     style={{ position: "absolute", right: "200%" }}
                                                    //     src={image_boxing(establishment.estabPicture, 90, 70)}
                                                    //     alt="establishment" />
                                                }
                                            </React.Fragment>
                                        </div>
                                    </React.Fragment>
                                )
                            }}
                        />}


                        <ScrollTopLazyLoading {...this.props} targetId={"#back_to_top_establishment"} rightPosition={positionScrollToTopLazyLoading}>
                            <Fab size="small" aria-label="scroll back to top" style={{
                                outline: "0px",
                                backgroundColor: "var(--mainGreen)"
                            }}>
                                <AngleUpIcon fill={"var(--mainWhite)"} style={{ marginLeft: "3px" }} />
                            </Fab>
                        </ScrollTopLazyLoading>

                    </div >}
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
    destinaionListDisplayFormat: state.Destination.destination.destinaionListDisplayFormat,
    selectedSubtypes: state.Destination.destination.selectedSubtypes,
    lazyLoading: state.Destination.destination.lazyLoading,
    establishments: state.Destination.destination.resultSetsEstablishments,
    switchFeatureContainer: state.FormBank.FeatureFormat,
    destinationId: state.Destination.destination.activeId,
    isMobile: state.Setting.is_mobile,
    bodyClientWidth: state.Setting.htmlBody.bodyClientWidth,

    timelineWizardStatus: state.FormBank.TimelineWizard.status,
    draggingActivity: state.FormBank.TimelineWizard.draggingActivity,
    activeTripActivities: state.FormBank.TimelineWizard.activeTrip.activities,
    lang: state.Setting.lang,
    uuid_auth: state.Member.authModal.uuid,
    activeTrip: state.FormBank.TimelineWizard.activeTrip,
    activeTripDestinationId: state.FormBank.TimelineWizard.activeTripDestinationId,
    activeTripDay: state.FormBank.TimelineWizard.activeDay,
    isFetchingEstablishments: state.FormBank.TimelineWizard.isFetchingEstablishments,
    switch_container: state.Member.switch_container,

}
}

const mapDispatchToProps = {
    addSelectedSubTypeDestination: Actions.addSelectedSubTypeDestination,
    deleteSelectedSubTypeDestination: Actions.deleteSelectedSubTypeDestination,
    setTimelineDraggingEstablishment: Actions.setTimelineDraggingEstablishment,
    deleteActivityToDayOfTripDestinationInTrip: Actions.deleteActivityToDayOfTripDestinationInTrip,
    addActivityToTripDestinationInTrip: Actions.addActivityToTripDestinationInTrip,
    fetchEstablishmentsLazyloading: Actions.fetchEstablishmentsLazyloading,
}

const Establishments = connect(mapStateToProps, mapDispatchToProps)(Establishments_)
export default Establishments



const PlacesElement = ({ establishments, establishment, uuid, lang, gridContainer, selectedSubtypes,
    switchFeatureContainer, timelineWizardStatus, availableDeleteActivities, isFetchingEstablishments,
    handleAddActivityToTripDestinationInTrip, activeTripDestinationId, activeDayInTripDestination, activeTripId,
    last_establishmentId, tripDuration, activeTripDestinationIds, deleteSelectedSubTypeDestination,
    addSelectedSubTypeDestination, destinationId, lazyLoading, textColor
}) => {
    const [flipped, setFlipped] = React.useState(false)
    const { transform, opacity } = useSpring({
        opacity: flipped ? 1 : 0,
        transform: `perspective(600px) rotateX(${flipped ? 180 : 0}deg)`,
        config: { mass: 5, tension: 500, friction: 80 }
    })
    return (
        <div style={{
            position: "relative", width: gridContainer + 15, height: gridContainer + 120, display: "inline-block",

            paddingLeft: "7.5px",
            paddingRight: "7.5px",
            paddingBottom: "50px",
            paddingTop: "15px",
        }}>
            <animated.div style={{ position: "absolute", zIndex: flipped ? "0" : "1", opacity: opacity.interpolate(o => 1 - o), transform }}>
                <div style={{
                    position: "relative",
                }}>
                    <div className="d-inline-block">
                        <Image
                            src={establishments !== undefined && establishments.length > 0 ? image_boxing(establishment.estabPicture, gridContainer, gridContainer) : loadingImage.src + "?func=crop&w=" + Math.floor(gridContainer) + "&h=" + Math.floor(gridContainer / 2)}
                            alt="establishment"
                            onClick={() => setFlipped(state => !state)} />
                    </div>
                    <div className="d-flex flex-wrap rating_color pt-2 pl-2" style={{ width: gridContainer + "px", border: "1px solid #E8E8E8" }}>
                        <EstablishmentName

                            establishmentCity={establishment.establishmentCity}
                            establishmentState={establishment.establishmentState}
                            establishmentCountry={establishment.establishmentCountry}
                            establishmentName={establishment.establishmentName}
                            establishmentId={establishment.establishmentID}
                            divClass={"flex_1"}
                            titleClass={"box_1"} titleStyle={{
                                font: "16px/16px Futura Md BT",
                                color: textColor,
                                marginTop: "5px",
                                cursor: "pointer",
                            }} />
                        {establishment.establishmentStarRating !== "0.0" && <div className="d-flex timeline_rating_color p-0 btn_rounded">
                            <div
                                style={{
                                    paddingRight: "5px",
                                    marginTop: "8px",
                                    font: "11px/14px Futura Md BT",
                                    color: "var(--mainGreen)",
                                }}>{establishment.establishmentStarRating}</div>
                            <div style={{
                                marginTop: "5px",
                                paddingRight: "2px",
                            }}>
                                <Rating name="timeline_rating" precision={0.5} max={1} value={parseFloat((establishment.establishmentStarRating / 5).toFixed(1))} disabled={true} />
                            </div>
                        </div>}
                        {establishment.subTypes !== undefined && establishment.subTypes.length > 0
                            && establishment.subTypes[0] !== undefined
                            && establishment.subTypes[0].subTypeName !== undefined &&
                            <div className="btn_rounded mt-1 futura_md_bt_14_16_green upcoming_trip_style" style={{ height: "26px", cursor: "pointer" }} onClick={() => {
                                selectedSubtypes.find(val => val.id === establishment.subTypes[0].subTypeID) ?
                                    deleteSelectedSubTypeDestination({ id: establishment.subTypes[0].subTypeID, title: establishment.subTypes[0].subTypeName }, lazyLoading, selectedSubtypes)
                                    : addSelectedSubTypeDestination({ id: establishment.subTypes[0].subTypeID, title: establishment.subTypes[0].subTypeName }, lazyLoading, selectedSubtypes)
                            }}>{establishment.subTypes[0].subTypeName}</div>
                        }
                    </div>
                    <div style={{ position: "relative" }}>
                        {timelineWizardStatus && <div style={{ textAlign: "right", position: "absolute", top: "5px", right: "0px", zIndex: "2", }}>
                            {availableDeleteActivities.length > 0 && !isFetchingEstablishments &&
                                <AddedToTrip
                                    establishmentName={establishment.establishmentName}
                                    establishmentStarRating={establishment.establishmentStarRating}
                                    availableDeleteActivities={availableDeleteActivities}
                                    textFont={"14px/14px Futura Hv BT"}
                                    text={"Added to trip"}
                                />}
                            {!isFetchingEstablishments ? <React.Fragment>
                                {availableDeleteActivities.length > 0 ?
                                    <div onClick={() => handleAddActivityToTripDestinationInTrip(lang, uuid, activeTripDestinationId, activeDayInTripDestination, establishment.establishmentID, activeTripId, last_establishmentId, tripDuration, destinationId, activeTripDestinationIds,establishment)} style={{ cursor: "pointer", color: "#707170", font: "12px/12px Futura Lt BT", padding: "0px 10px", display: "inline-block" }}><PlusSignIcon fill={"#707170"} width={7} height={10} style={{ marginRight: "5px" }} />add again</div>
                                    : <div onClick={() => handleAddActivityToTripDestinationInTrip(lang, uuid, activeTripDestinationId, activeDayInTripDestination, establishment.establishmentID, activeTripId, last_establishmentId, tripDuration, destinationId, activeTripDestinationIds,establishment)} style={{ cursor: "pointer", color: "var(--mainGreen)", font: "14px/14px Futura Hv BT", padding: "0px 10px", display: "inline-block" }}><PlusSignIcon width={10} height={13} style={{ marginRight: "5px" }} />Add to trip</div>
                                }
                            </React.Fragment> : <CircularProgress
                                disableShrink
                                size={15}
                                style={{
                                    color: "#6A9EEC",
                                    textAlign: "right",
                                    marginTop: "15px",
                                    marginRight: "5px",
                                }} />
                            }
                        </div>}
                    </div>
                    <ActionsEllipsis id={establishment.establishmentID} switchFeatureContainer={switchFeatureContainer} />
                </div>
            </animated.div>
            <animated.div style={{ position: "absolute", zIndex: flipped ? "1" : "0", opacity, transform: transform.interpolate(t => `${t} rotateX(180deg)`) }}>
                <div style={{
                    position: "relative",
                }}>
                    <div className="d-inline-block"><Image
                        src={establishments !== undefined && establishments.length > 0 ? image_boxing(establishment.estabPicture, gridContainer, gridContainer) : loadingImage.src + "?func=crop&w=" + Math.floor(gridContainer) + "&h=" + Math.floor(gridContainer / 2)}
                        alt="establishment" /></div>
                    {establishment.establishmentDescription !== undefined &&
                        <div style={{ position: "absolute", top: "45px", zIndex: "1" }} className={"padding_left_10 padding_right_10"}>
                            <EstablishmentDescription description={establishment.establishmentDescription} boxDescription={"box_10"} fontDescription={timelineWizardStatus ? "14px/19px Futura Lt BT" : "18px/25px Futura Lt BT"} colorDescription={"#FFFFFF"} height={timelineWizardStatus ? "190px" : "250px"} />
                        </div>}

                    <div style={{ backgroundColor: "#19191980", position: "absolute", width: gridContainer + "px", height: gridContainer + "px", top: "0px" }} onClick={() => setFlipped(state => !state)} />
                    <div className="d-flex flex-wrap rating_color pt-2 pl-2" style={{ width: gridContainer + "px", border: "1px solid #E8E8E8" }}>
                        <EstablishmentName

                            establishmentCity={establishment.establishmentCity}
                            establishmentState={establishment.establishmentState}
                            establishmentCountry={establishment.establishmentCountry}
                            establishmentName={establishment.establishmentName}
                            establishmentId={establishment.establishmentID}
                            divClass={"flex_1"}
                            titleClass={"box_1"} titleStyle={{
                                font: "16px/16px Futura Md BT",
                                color: textColor,
                                marginTop: "5px",
                                cursor: "pointer",
                            }} />
                        {establishment.establishmentStarRating !== "0.0" && <div className="d-flex timeline_rating_color p-0 btn_rounded">
                            <div
                                style={{
                                    paddingRight: "5px",
                                    marginTop: "8px",
                                    font: "11px/14px Futura Md BT",
                                    color: "var(--mainGreen)",
                                }}>{establishment.establishmentStarRating}</div>
                            <div style={{
                                marginTop: "5px",
                                paddingRight: "2px",
                            }}>
                                <Rating name="timeline_rating" precision={0.5} max={1} value={parseFloat((establishment.establishmentStarRating / 5).toFixed(1))} disabled={true} />
                            </div>
                        </div>}
                        {establishment.subTypes !== undefined && establishment.subTypes.length > 0
                            && establishment.subTypes[0] !== undefined
                            && establishment.subTypes[0].subTypeName !== undefined &&
                            <div className="btn_rounded mt-1 futura_md_bt_14_16_green upcoming_trip_style" style={{ height: "26px", cursor: "pointer" }} onClick={() => {
                                selectedSubtypes.find(val => val.id === establishment.subTypes[0].subTypeID) ?
                                    deleteSelectedSubTypeDestination({ id: establishment.subTypes[0].subTypeID, title: establishment.subTypes[0].subTypeName }, selectedSubtypes)
                                    : addSelectedSubTypeDestination({ id: establishment.subTypes[0].subTypeID, title: establishment.subTypes[0].subTypeName }, selectedSubtypes)
                            }}>{establishment.subTypes[0].subTypeName}</div>
                        }
                    </div>
                    <div style={{ position: "relative" }}>
                        {timelineWizardStatus && <div style={{ textAlign: "right", position: "absolute", top: "5px", right: "0px", zIndex: "2", }}>
                            {availableDeleteActivities.length > 0 && !isFetchingEstablishments &&
                                <AddedToTrip
                                    establishmentName={establishment.establishmentName}
                                    establishmentStarRating={establishment.establishmentStarRating}
                                    availableDeleteActivities={availableDeleteActivities}
                                    textFont={"14px/14px Futura Hv BT"}
                                    text={"Added to trip"}
                                />}
                            {!isFetchingEstablishments ? <React.Fragment>
                                {availableDeleteActivities.length > 0 ?
                                    <div onClick={() => handleAddActivityToTripDestinationInTrip(lang, uuid, activeTripDestinationId, activeDayInTripDestination, establishment.establishmentID, activeTripId, last_establishmentId, tripDuration, destinationId, activeTripDestinationIds, establishment)} style={{ cursor: "pointer", color: "#707170", font: "12px/12px Futura Lt BT", padding: "0px 10px", display: "inline-block" }}><PlusSignIcon fill={"#707170"} width={7} height={10} style={{ marginRight: "5px" }} />add again</div>
                                    : <div onClick={() => handleAddActivityToTripDestinationInTrip(lang, uuid, activeTripDestinationId, activeDayInTripDestination, establishment.establishmentID, activeTripId, last_establishmentId, tripDuration, destinationId, activeTripDestinationIds, establishment)} style={{ cursor: "pointer", color: "var(--mainGreen)", font: "14px/14px Futura Hv BT", padding: "0px 10px", display: "inline-block" }}><PlusSignIcon width={10} height={13} style={{ marginRight: "5px" }} />Add to trip</div>
                                }
                            </React.Fragment> : <CircularProgress
                                disableShrink
                                size={15}
                                style={{
                                    color: "#6A9EEC",
                                    textAlign: "right",
                                    marginTop: "15px",
                                    marginRight: "5px",
                                }} />
                            }
                        </div>}
                    </div>
                    <ActionsEllipsis id={establishment.establishmentID} switchFeatureContainer={switchFeatureContainer} />
                </div>
            </animated.div>
        </div >
    )
}


function timeToMins(time) {
    var b = time.split(':');
    return b[0] * 60 + +b[1];
};

function timeFromMins(mins) {
    function z(n) {
        if (n < 0) return ('-0' + (n).toString().slice(1));
        return (n < 10 ? '0' : '') + n;
    };

    var h = (mins / 60 | 0);
    var m = mins % 60;
    return z(h) + ':' + z(m);
};

function addTimes(time0, time1) {
    return timeFromMins(timeToMins(time0) + timeToMins(time1));
};

function compareTimes(time1, time2){
    var str1 = time1;
var str2 = time2;

str1 =  str1.split(':');
str2 =  str2.split(':');

var totalSeconds1 = parseInt(str1[0] * 3600 + str1[1] * 60 + str1[0]);
var totalSeconds2 = parseInt(str2[0] * 3600 + str2[1] * 60 + str2[0]);
return totalSeconds1 > totalSeconds2;
}