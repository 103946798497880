import React from 'react'
import { connect } from 'react-redux'
import {Image} from 'react-bootstrap'
import { image_cloud_aliases } from "../Common/RegExValidate"
//import {DollarSignIcon, ClockIcon} from "../Common/SVGBank"
import {AttachMoney, AccessTime, Language, GroupAdd} from '@material-ui/icons'
import {Button} from '@material-ui/core'

const Experiences_ = ({ establishment, lang}) => {
    const { experiences } = establishment
    console.log(experiences)

    const  isExisted = experiences && experiences.length > 0
    return (
        <div>
            <div style={{font: "30px/36px Futura Lt BT", color: "#001A46", marginBottom: "10px"}}>Experiences</div>
            <div> 
                {isExisted &&  experiences.map(exp => {
                        let key = exp.experiencePicture[0].bucketName + "_" + exp.experiencePicture[0].folderName
                        let alias = image_cloud_aliases[key].alias + "/" + exp.experiencePicture[0].imageName
                        let imageUrl = "https://amhqajbken.cloudimg.io/v7/" + alias + "?func=crop&w=" + 350 + "&h=" + 300
                        let getArray = exp.whatYouGet.split(";")
                        console.log(getArray.length)
                        return <div key={exp.experienceId} style ={{display:"flex", flexBasis: "100%", flexDirection: "row", flexGrow: "1", flexShrink: "0"}}>
                            <div style={{flexBasis: "50%", flexGrow: "1", flexShrink: "0"}}>
                                <div style ={{marginLeft:"0px",marginTop:"30px", marginBottom:"10px"}}>
                                    <Image src={imageUrl} alt="image" width="350px" height="300px" />
                                 </div>
                                 <div style ={{marginLeft: "0px", width: "380px", marginTop:"20px", font: "24px/24px Futura Md BT"}}>
                                     {exp.experienceName}
                                 </div>
                            </div>
                            <div style={{flexBasis: "50%", flexGrow: "1", flexShrink: "0", marginTop: "25px"}}>
                                <div style={{ font: "18px/20px Futura Lt BT", color: "#707070", marginBottom: "10px"}}>
                                    {exp.experienceDesc}
                                </div>
                                <div style={{ font: "18px/20px Futura Lt BT", color: "#707070", marginBottom: "10px"}}>
                                    {exp.experienceDescLong}
                                </div>
                                <div style ={{marginTop: "10px"}}>
                                    <div style={{display:"flex"}}>
                                        <div style={{width:"250px"}}>
                                            <div style={{font: "18px/20px Futura Md BT", color: "#000000", marginLeft:"0px"}}>
                                                What you get:
                                            </div>
                                            {getArray && getArray.length > 0 && getArray.map (g=>
                                                  <div style={{font: "18px/20px Futura Lt BT", color: "#000000", marginLeft:"10px", marginTop:"4px"}}>
                                                     {"- " + g}
                                                 </div>
                                                )
                                            }
                                        </div>                                      
                                        <div style={{width:"180px", marginLeft:"40px"}}>
                                            <div style={{display: "flex", flexDirection: "row"}}>
                                                 <div style={{width:"40px"}}>
                                                        <AttachMoney/>
                                                </div>
                                                <div style={{width:"110px", font: "18px/24px Futura Hv BT", color:"#191919"}}>
                                                        {"$"+ parseInt(exp.experienceCost) + "/person"}
                                                </div>
                                            </div>
                                            <div style={{display: "flex", flexDirection: "row"}}>
                                                <div style={{width:"40px"}}>
                                                    <AccessTime/>
                                                </div>
                                                <div style={{width:"110px", font: "18px/20px Futura Lt BT", color:"#707070"}}>
                                                    {parseInt(exp.experienceDuration) + " hours"}
                                                </div>
                                            </div>
                                            <div style={{display: "flex", flexDirection: "row"}}>
                                                <div style={{width:"40px"}}>
                                                    <Language/>
                                                </div>
                                                <div style={{width:"110px", font: "18px/20px Futura Lt BT", color:"#191919"}}>
                                                    {exp.language}
                                                </div>
                                            </div>
                                            {parseInt(exp.groupUpTo)>0 && <div style={{display: "flex", flexDirection: "row"}}>
                                                <div style={{width:"40px"}}>
                                                    <GroupAdd/>
                                                </div>
                                                <div style={{width:"110px", font: "18px/20px Futura Lt BT", color:"#191919"}}>
                                                    {"Up to " + exp.groupUpTo + " people"}
                                                </div>
                                            </div>}
                                        </div>
                                    </div>

                                                                 
                                </div>
                                <div>
                                    <Button disabled style={{ width: "150px", height:"40px", color: "white", backgroundColor: "var(--lightGray)", opacity: "1", marginTop: "20px", marginLeft: "280px"}}>{"Book now >"}</Button>
                                </div>
                            </div>
                        </div> 
                    }) 
                }
            
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({

    establishment: state.FormBank.HotelSearch.establishment,
    //isMobile: state.Setting.is_mobile,
    lang: state.Setting.lang,
    //uuid: state.Member.authModal.uuid,

    //switchFeatureContainer: state.FormBank.FeatureFormat,
})

//const mapDispatchToProps = {

//}

const Experiences = connect(mapStateToProps, null)(Experiences_)
export default Experiences
