import "./UpComingTrip.css"

import {
    Col,
    Row,
} from 'react-bootstrap'

import { PropTypes } from "prop-types"
import React from 'react'
import { ReformatTripRangeDate } from '../Common/DateTimeFormatHandler'
import ShareTripDialog from "../Common/ShareTripDialog"
import { URL_REPLACE } from "../Common/RegExValidate"
import UpComingTripTimeline from './UpComingTripTimeline'
import { connect } from 'react-redux'
import { useHistory } from "react-router-dom"

/* upcomming trip component */
const UpComingTrip_ = ({ futureTrips, lang }) => {
    const history = useHistory()
    const goesTripPage = (tripId, tripName) => {
        const url = "/trip/" + tripName.replace(URL_REPLACE, "-") + "/" + tripId;
        history.push(url.toLowerCase())
    }
    let upcomingTrip = undefined
    let range_date = undefined
    if (futureTrips !== undefined && futureTrips.length > 0 && futureTrips[0] !== undefined) {
        upcomingTrip = futureTrips[0]
        range_date = ReformatTripRangeDate(upcomingTrip.tripStartDate, upcomingTrip.tripDaysCount)
    }
    return (
        <div className="pt-3 text-white">
            {upcomingTrip !== undefined &&
                <Row>
                    <Col sm={12} md={12} lg={12} xl={5} className="padding_125rem_lr_mobile">
                        <div className="futura_hv_bt_24_30_white margin_bottom_30">Upcoming Trip</div>
                        <div className="futura_lt_bt_18_24_white margin_left_20 margin_bottom_10">{range_date}</div>
                        <div className="futura_hv_bt_18_24_white margin_left_20 margin_bottom_10" onClick={() => goesTripPage(upcomingTrip.tripID, upcomingTrip.tripName)} style={{ cursor: "pointer" }}>{upcomingTrip.tripName}</div>
                        <div className="futura_md_bt_14_24_white margin_left_20 margin_bottom_10">{upcomingTrip.tripCost < 0 && "Current cost: $ " + upcomingTrip.tripCost}</div>
                        <div className="futura_lt_bt_16_18_grey margin_left_20 margin_bottom_10">{upcomingTrip.tripDescription}</div>
                        <div className="d-inline-flex flex-wrap margin_left_20 margin_bottom_10">
                            {upcomingTrip.tripCompanions !== undefined && upcomingTrip.tripCompanions.length > 0 &&
                                <div className="futura_hv_bt_18_24_white">Travel companions:
                                        {
                                        upcomingTrip.tripCompanions.map((companion, idx) => {
                                            return <div key={idx} className="futura_lt_bt_18_24_white"> @{companion.companionName} </div>
                                        })
                                    }
                                </div>
                            }
                        </div>
                        <div className="futura_hv_bt_16_19_green margin_bottom_10 margin_right_10 text-right"><ShareTripDialog from={"Offer to join"} inline={false} offerToJoinTrip={upcomingTrip} /></div>
                    </Col>
                    <Col sm={12} md={12} lg={12} xl={7} className="padding_0">
                        {
                            upcomingTrip.tripDestinations !== undefined
                            && upcomingTrip.tripDestinations.length > 0
                            && upcomingTrip.tripDestinations[0] !== undefined
                            && upcomingTrip.tripDestinations[0].destinationDays !== undefined &&
                            <UpComingTripTimeline trip={upcomingTrip} />
                        }
                    </Col>
                </Row>
            }
        </div>
    )

}


UpComingTrip_.propTypes = {
    futureTrips: PropTypes.array.isRequired,
}
const mapStateToProps = (state) => {
    return {
        futureTrips: state.Member.socialMember.futureTrips,
        lang: state.Setting.lang,
    }
}

const UpComingTrip = connect(mapStateToProps, null)(UpComingTrip_)
export default UpComingTrip
