import { Backdrop, Modal } from '@material-ui/core';
import React from 'react';
import { CameraIcon, RightArrowIcon, TimesIcon } from '../Common/SVGBank';
import "./PhotoUploadComponent.css"
import { connect, useDispatch, useSelector } from 'react-redux'
import { useDropzone } from 'react-dropzone';
import { uploadMediaPhoto, uploadMediaVideo } from './PhotoUploadComponent.thunk';
import { PlayCircleFilled } from '@material-ui/icons';

export const PhotoUploadComponent = ({ isMobile,open, closeModal, uuid, dataId, dataKey }) => {
    const dispatch = useDispatch()

    const [uploadedFiles, setuploadFiles] = React.useState([]);
    const [uploadedPhotos, setuploadPhotos] = React.useState([]);
    const [uploadedVideos, setuploadVideos] = React.useState([]);

    const [error, setError] = React.useState(false);
    const [uploadSuccess, setUploadSuccess] = React.useState(false);
    const [tooManyVideos, setTooManyVideos] = React.useState(false);

    const onDrop = (files) => {
        if (files && files.length > 0) {
            setTooManyVideos(false)

            setuploadFiles(files.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })));

            let videos = files.filter((file) => {
                return file.type.includes('video')
            })


           
            setuploadVideos((prevState) => {
                let newVideos = [...prevState, ...videos];
                if (newVideos && newVideos.length > 1) {
                    newVideos = [newVideos[newVideos.length -1]]
                    setTooManyVideos(true)
                }
                return newVideos
            })

            const photos = files.filter((file) => {
                return file.type.includes('image')
            })

            

            setuploadPhotos((prevState) => {
                return [...prevState, ...photos]
            })

        }

    }

    const onDropRejected = (files) => {
        setError(true)

        setTimeout(() => {
            setError(false)
        }, 3000);
    }


    let { acceptedFiles, getRootProps, getInputProps } = useDropzone({
        accept: 'image/*, video/*', onDrop, onDropRejected
    });


    const submitVideo = () =>{

        if(uploadedVideos && uploadedVideos.length >0){


            const formDataVideos = new FormData();
            formDataVideos.set('uuid', uuid);
            formDataVideos.set(dataKey, dataId);

            Array.prototype.forEach.call(uploadedVideos, (file, index_file) => {
                formDataVideos.append("videos[" + index_file + "]", file);
            });

            dispatch(uploadMediaVideo(formDataVideos)).then((response) => {
                if(response.meta.requestStatus === "fulfilled"){
                 setUploadSuccess(true)
                 setTimeout(() => {
                     setUploadSuccess(false)
                     setError(false)
                 }, 1500);
                }
             })

           }

    }


    const submitPhotos = () =>{

        if(uploadedPhotos && uploadedPhotos.length >0){
            const formData = new FormData();
            formData.set('uuid', uuid);
            formData.set(dataKey, dataId);

            Array.prototype.forEach.call(uploadedPhotos, (file, index_file) => {
                formData.append("photos[" + index_file + "]", file);
            });

            dispatch(uploadMediaPhoto(formData)).then((response) => {
                if(response.meta.requestStatus === "fulfilled"){
                 setUploadSuccess(true)
                 setTimeout(() => {
                     setUploadSuccess(false)
                     setError(false)
                 }, 1500);
                }
             })
        }
    }


    const clearFiles = () => {
        setuploadFiles([])
        setuploadVideos([])
        setuploadPhotos([])
    }

    const imageThumbs = uploadedPhotos && uploadedPhotos.length > 0 ? uploadedPhotos.map(file => (
        <div style={{}} key={file.name}>
            <div className="photoThumbs">
                <img alt="file preview"
                    src={file.preview}
                    style={{}}
                />
            </div>
        </div>
    )) : null;


    const videoThumbs = uploadedVideos && uploadedVideos.length > 0 ? uploadedVideos.map(file => (
        <div style={{}} key={file.name}>
            <div className="photoThumbs">
                <PlayCircleFilled className="playBtn" />
                <video src={file.preview}>

                </video>

            </div>
        </div>
    )) : null;


    React.useEffect(() => () => {
        // Make sure to revoke the data uris to avoid memory leaks
        uploadedFiles.forEach(file => URL.revokeObjectURL(file.preview));
        return () => {
            setTooManyVideos(false);
            setUploadSuccess(false)
            setuploadFiles([])
        }
    }, [uploadedFiles]);
    return (
        <div>
            <Modal
                open={open}
                onClose={(event, reason) => {
                    if (reason !== 'backdropClick') {
                        clearFiles()
                        closeModal()
                    }
                }}
                disableBackdropClick={true}
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,


                }}
            >
                <div style={{

                }} className="photoUpload">
                    <div onClick={() => {
                        clearFiles()
                        closeModal()
                    }} ><TimesIcon height={30} width={30} style={{ position: 'absolute', right:isMobile?"30%": 30, top: 30, cursor: "pointer", }} fill={'#2C2C2C'}  /></div>


                    <div >
                     
                        <div className="photoBorder" style={{
                            border: `1px dashed ${error ? 'red' : '#707070'}`,
                            height: '503px'
                        }}>
                               <div {...getRootProps({ className: 'dropzone' })}>

                            <input {...getInputProps()} />

                            {uploadedFiles && uploadedFiles.length < 1 && <div>
                                <CameraIcon style={{ position: "absolute", left: 190, top: 80 }} width={500} height={500} fill={'rgb(248, 248, 248)'} />


                                <div className="photoBody">

                                    <div>
                                        <h1>
                                            Drag and drop photos and videos here
                                     </h1>

                                        <h4>
                                            or
                                  </h4>


                                        <div style={{}}>
                                            <button className='recapButtons' style={{
                                                background: '#19BC9B', color: 'white', font: '14px/14px Futura Hv BT',
                                                marginTop: 80
                                            }} onClick={null}>
                                                Browse <RightArrowIcon height={14} width={14} style={{ marginLeft: 20 }} />
                                            </button>
                                        </div>

                                        <div style={{ color: '#2C2C2C', font: '14px/14px Futura Lt BT', marginTop: 35, width: 460, marginLeft: -50 }}>
                                            You can upload any number of photos in one shot but only one video at a time
                                </div>
                                    </div>

                                </div>
                            </div>}


                            {/* preview */}
                            {uploadedFiles && uploadedFiles.length > 0 && <div >
                                <div className="photoPreview">
                                {videoThumbs}
                                {imageThumbs}
                               </div>

                               <div className="previewBottom">
                                <div style={{
                                        color: '#0d3a7d', font: '14px/14px Futura Md BT',right: 0,
                                        position: "absolute", top: -105, width: 106, cursor: 'pointer'
                                    }}>
                                        Browse for more
                                </div>
                                </div>
                            </div>}
                        </div>
                      
                    </div>

                    {uploadedFiles && uploadedFiles.length > 0 && <div className="previewBottom">

                                    {tooManyVideos  && <span style={{
                                        color: '#FF0000', font: '14px/14px Futura Lt BT', marginRight: 30, marginTop: 10
                                    }}>
                                        Warning: only one video was uploaded, videos must be uploaded one at a time
                                </span>}

                              
                                    <button className='recapButtons' style={{
                                            background: '#19BC9B', color: 'white', font: '14px/14px Futura Hv BT',
                                        }} onClick={()=>{
                                            submitPhotos()
                                            submitVideo()
                                        }}>
                                            Submit <RightArrowIcon height={14} width={14} style={{ marginLeft: 20 }} />
                                        </button>
                                </div>}
                                
                    </div>

                    
                </div>
            </Modal>
        </div>)

}
