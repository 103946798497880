import "./PublicTripTimeline.css"

import { DownArrowIcon, UpArrowIcon } from "../Common/SVGBank"
import { validate, validate_fields } from "../Common/RegExValidate"

import { EstablishmentDescription } from '../Common/EstablishmentDescription'
import EstablishmentName from '../Common/Establishment/EstablishmentName'
import {
    Image,
} from 'react-bootstrap'
import Rating from '@material-ui/lab/Rating'
import React from 'react'
import { connect } from 'react-redux'
import { image_boxing } from '../Common/RegExValidate'
import moment from 'moment'
import { time_format } from "../Common/RegExValidate"

/* timeline component */
const PublicTripTimeline_ = ({ trip, screenSize, is_mobile }) => {

    const [open, setOpen] = React.useState(false)
    const [destinationNumberIndex, setDestinationNumberIndex] = React.useState(0)
    const setClose = () => {
        setOpen(false)
        document.getElementById("public_trip_timeline").scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
    const [tripDestinations, setTripDestinations] = React.useState([])

    const destinaitonRadioTimelineHandler = (num) => {
        setDestinationNumberIndex(num)
    }

    React.useEffect(() => {

        const tripDestinations = trip.tripDestinations

        if (tripDestinations !== undefined && tripDestinations.length > 0 && tripDestinations[0] !== undefined) {
            tripDestinations.sort((a, b) => parseFloat(a?.startingDayInTrip) - parseFloat(b?.startingDayInTrip));
            setTripDestinations(tripDestinations)
        }
    }, [trip.tripDestinations])

    let day_container = 0
    let day_container_display = 0
    let time_container = 0
    let time_container_dispaly = 0
    return (
        <div className="padding_left_right_white">
            <div className="d-flex padding_left_150_timeline padding_right_20_timeline row" style={{ marginLeft: "0px", marginRight: "0px" }} id={"public_trip_timeline"}>
                {tripDestinations !== undefined && tripDestinations.length > 0 &&
                    tripDestinations.map((destination, index) => {
                        return (
                            <React.Fragment key={index}>
                                { destination !== undefined &&
                                    destination.destinationDays !== undefined &&
                                    destination.destinationDays.length > 0 &&
                                    <div key={index}
                                        className={destinationNumberIndex === index ? "bg_color_btn_white destination_white_active col-lg-2 col-md-3 col-sm-6 text-center" : "bg_color_btn_white col-lg-2 col-md-3 col-sm-6 text-center"}
                                        onClick={() => destinaitonRadioTimelineHandler(index)}>
                                        <div className="futura_md_bt_18_14 box_1 text-center d-inline-block p-3">{destination.title}</div></div>
                                }
                            </React.Fragment>
                        )
                    })
                }
            </div>
            <div className="content_container">
                <div className="steps_timeline_hm text-left padding_left_150_timeline padding_right_20_timeline">
                    {tripDestinations[destinationNumberIndex]?.destinationDays !== undefined && tripDestinations[destinationNumberIndex]?.destinationDays.length > 0 &&

                        tripDestinations[destinationNumberIndex].destinationDays.map((activity, idx) => {
                            if (day_container !== activity.activityDay && validate(activity.activityDay, validate_fields.disallow_comma)) {
                                day_container = activity.activityDay
                                day_container_display = 1
                            } else {
                                day_container_display = 0
                            }
                            if (activity.activityStartTime !== undefined && activity.activityNoTime === "0") {
                                time_container = moment(activity.activityStartTime, "HH:mm:ss").format(time_format.hours_12)
                                time_container_dispaly = 1
                            } else {
                                time_container_dispaly = 0
                            }


                            return (
                                <React.Fragment key={idx}>
                                    {(idx < 3) || open ?
                                        <div className={(idx !== tripDestinations[destinationNumberIndex].destinationDays.length - 1) ?
                                            (day_container_display === 1 && idx !== 0 ? "step_day padding_bottom_20 padding_top_20" : "step padding_bottom_20") :
                                            (day_container_display === 1 && idx !== 0 ? "step_last padding_bottom_20 padding_top_20" : "padding_bottom_20")
                                        } >
                                            <div className={tripDestinations[destinationNumberIndex].destinationDays.length > 1 ? "title_hm" : ""} style={{ position: "relative" }}>

                                                {day_container_display === 1 ?
                                                    <div className={idx !== 0 ? "day" : "first_day"}>
                                                        {/* {trip.noDate === "1" ? "Day " + activity.activityDay :
                                                            <div>
                                                                <div className="text-center text_main_blue" style={{ font: screenSize > 1024 ? "14px/14px Futura Hv BT" : "7px/7px Futura Hv BT", }}>{moment(trip.tripStartDate, 'YYYY-MM-DD').add((activity.activityDay - 1), 'days').format('ddd ')}</div>
                                                                <div className="text-center text_main_blue" style={{ font: screenSize > 1024 ? "36px/40px Futura Hv BT" : "18px/20px Futura Hv BT", }}>{moment(trip.tripStartDate, 'YYYY-MM-DD').add((activity.activityDay - 1), 'days').format('D')}</div>
                                                                <div className="text-center text_main_black" style={{ font: screenSize > 1024 ? "14px/14px Futura Hv BT" : "7px/7px Futura Hv BT", }}>{moment(trip.tripStartDate, 'YYYY-MM-DD').add((activity.activityDay - 1), 'days').format('MMM')}</div>
                                                            </div>
                                                        } */}

                                                        {idx !== 0 && <div className="text-center text_main_black publicTimelineDays"
                                                            style={{ font: screenSize > 1024 ? "20px/20px Futura Hv BT" : "14px/14px Futura Hv BT", }}>

                                                            {"Day " + activity.activityDay}

                                                        </div>}



                                                    </div>
                                                    : ""}
                                                {day_container_display === 1 && idx === 0 &&  <div className="text_main_black "
                                                    style={{ font: "14px/20px Futura Hv BT",
                                                    textAlign:"left",padding:10 }}>

                                                    {"Day " + activity.activityDay}

                                                </div>}
                                               
                                                {time_container_dispaly === 1 ? <div className="day_time" style={{ left: "-120px", width: "85px" }}>{time_container}</div> : ""}
                                                <div className="d-inline-block">
                                                    <Image
                                                        src={image_boxing(activity.activityImage, "500", "265")}
                                                        alt="activity" style={{ width: "500px" }} /></div>
                                                <div className="d-inline-block" style={{ width: screenSize < 500 ? "100%" : "500px", border: "1px solid #E8E8E8", verticalAlign: "middle" }}>
                                                    <div className="futura_md_bt_14_16_black margin_top_10 box_1" style={{ paddingLeft: "10px" }}>{activity.activityNeighborhood}</div>
                                                    <EstablishmentName
                                                        establishmentId={activity.activityEstabID}
                                                        establishmentName={activity.activityName}
                                                        titleClass={"box_1"}
                                                        titleStyle={{ cursor: 'pointer', font: "24px/43px Futura Hv BT", textAlign: "left", color: "var(--frameBlack)", paddingLeft: "10px", }}
                                                    />
                                                    
                                                    
                                                    {activity.activityStarRating !== "0.0" && <div className="d-flex timeline_rating_color pl-2 btn_rounded" style={{ paddingLeft: "10px" }}>
                                                        <Rating name="timeline_rating" precision={0.1} max={5} value={parseFloat(activity.activityStarRating)} disabled={true} />
                                                        <div className="futura_md_bt_11_14_black margin_bottom_5 margin_left_5">({activity.activityStarRating})</div>
                                                    </div>}

                                                    {/* {<div style={{ paddingLeft: "10px" }}>
                                                        {activity.activityDescription !== undefined &&
                                                            <EstablishmentDescription description={activity.activityDescription}
                                                                />}
                                                        </div>} */}
                                                        
                                                    <div style={{ paddingLeft: "10px" }}>
                                                        {activity.activityDescription !== undefined &&
                                                            <EstablishmentDescription bottom={1} description={activity.activityDescription} boxDescription={"box_2"} fontDescription={"14px/19px Futura Lt BT"} colorDescription={"var(--mainGrey)"}
                                                                is_mobile={is_mobile}
                                                                // height={"102px"} 
                                                                />}
                                                    </div>
                                                   
                                                   
                                                    <div className="pl-2 text-left box_1" style={{ 
                                                        marginBottom: "35px"}}>
                                                        {activity.activitySubTypes !== undefined && activity.activitySubTypes.length > 0 &&
                                                            activity.activitySubTypes.map((element, idx) => { return <div key={idx} 
                                                            className="btn_rounded m-1 futura_md_bt_14_16_green upcoming_trip_style">
                                                                {element.subtypeName}</div> })
                                                        }
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        : ""}
                                </React.Fragment>
                            )
                        })
                    }
                </div>
                {!open && tripDestinations[destinationNumberIndex]?.destinationDays.length > 3 && <div className="content_text_container_bottom_white"
                    style={{
                        msTransform: tripDestinations[destinationNumberIndex]?.destinationDays !== undefined && tripDestinations[destinationNumberIndex]?.destinationDays.length > 3 ? "translateY(-30px)" : "",
                        transform: tripDestinations[destinationNumberIndex]?.destinationDays !== undefined && tripDestinations[destinationNumberIndex]?.destinationDays.length > 3 ? "translateY(-30px)" : ""
                    }} ></div>}

                {tripDestinations[destinationNumberIndex]?.destinationDays !== undefined && tripDestinations[destinationNumberIndex]?.destinationDays.length > 3 ?
                    open ?
                        <React.Fragment>
                            <div className="text-center">
                                <div className="d-inline-flex p-2 justify-content-center futura_md_bt_13_16_green" style={{ cursor: "pointer" }} onClick={() => setClose()}>
                                    <div className="m-0 p-0 futura_md_bt_16_19_green" >See Less</div>
                                    <UpArrowIcon fill={"#19BC9B"} style={{ marginTop: "3px", marginLeft: "8px" }} />
                                </div>
                            </div>
                        </React.Fragment>
                        :
                        <React.Fragment>
                            <div className="text-center">
                                <div className="d-inline-flex p-2 justify-content-center futura_md_bt_13_16_green" style={{ cursor: "pointer"}} onClick={() => setOpen(true)}>
                                    <div className="m-0 p-0 futura_md_bt_16_19_green" >See All</div>
                                    <DownArrowIcon fill={"#19BC9B"} style={{ marginTop: "3px", marginLeft: "8px" }} />
                                </div>

                            </div>
                        </React.Fragment>

                    : ""
                }
            </div>

        </div >
    )

}

const mapStateToProps = (state) => {
    return {
        trip: state.PublicTrip.trip,
        screenSize: state.Setting.htmlBody.bodyClientWidth,
        is_mobile: state.Setting.is_mobile,
    }
}

const mapDispatchToProps = {
}


const PublicTripTimeline = connect(mapStateToProps, mapDispatchToProps)(PublicTripTimeline_)
export default PublicTripTimeline
