import './terms.css';

import React from 'react'
import { useHistory  } from 'react-router-dom';

export const ContactUsComponent = () => {
    let padding_l = "15.7"
    let padding_r = "15.7"
    let font_size = "30"

    const history = useHistory()
    return (
        <div style={{
            paddingLeft: padding_l + "%",
            paddingRight: padding_r + "%",
            paddingBottom: 50
        }}>
            <div className="padding_top_50 padding_125rem_lr_mobile"
                style={{
                    color: "#000000",
                    font: font_size + "px/72px Futura Hv BT",
                }}
            >
                <iframe scrolling="no" src="https://www.muvtravel.com/contactus" style={{border: "none", width:"100%",height:680, marginTop:-220, overflow:"hidden"}} title="contact-us"></iframe>
         </div>
        </div>
    )
}