import * as Actions from '../../../../redux/actions';

import React, { Component } from 'react'

import { Menu } from 'semantic-ui-react'
import { connect } from 'react-redux'
import { member_area_router } from "../../Common/RegExValidate"
import { types_router } from '../RegExValidate';

class MenuPersType_ extends Component {
    render() {

        const { switch_container_type, switch_container, setSwitchPersTypeContainer, screenSize, token, uuid, lang } = this.props;
        let nav_menu_width = "240"

        let screen_at_500 = 500
        if (!isNaN(screenSize)) {
            nav_menu_width = screenSize < screen_at_500 ? screenSize / 3 : "240"
        }
        return (
            <Menu pointing secondary style={{
                backgroundColor: switch_container === member_area_router.personalInfo ? "#191919" : "#FFFFFF",
                borderBottom: "0", paddingTop: "10px"
            }}>

                <div className={switch_container_type === types_router.see.typeId ? "menu_link_title_text_create_account_active p-0 m-0" : "menu_link_title_text_create_account p-0 m-0"}
                    onClick={() => setSwitchPersTypeContainer(uuid, lang, types_router.see.typeId)}
                    style={{
                        width: nav_menu_width + "px",
                        cursor: token !== undefined && token !== "" ? "pointer" : "",
                    }}>{types_router.see.title}</div>
                <div className={switch_container_type === types_router.shop.typeId ? "menu_link_title_text_create_account_active p-0 m-0" : "menu_link_title_text_create_account p-0 m-0"}
                    onClick={() => setSwitchPersTypeContainer(uuid, lang, types_router.shop.typeId)}
                    style={{
                        width: nav_menu_width + "px",
                        cursor: token !== undefined && token !== "" ? "pointer" : "",
                    }}>{types_router.shop.title}</div>
                <div className={switch_container_type === types_router.eat.typeId ? "menu_link_title_text_create_account_active p-0 m-0" : "menu_link_title_text_create_account p-0 m-0"}
                    onClick={() => setSwitchPersTypeContainer(uuid, lang, types_router.eat.typeId)}
                    style={{
                        width: nav_menu_width + "px",
                        cursor: token !== undefined && token !== "" ? "pointer" : "",
                    }}>{types_router.eat.title}</div>
                <div className={switch_container_type === types_router.stay.typeId ? "menu_link_title_text_create_account_active p-0 m-0" : "menu_link_title_text_create_account p-0 m-0"}
                    onClick={() => setSwitchPersTypeContainer(uuid, lang, types_router.stay.typeId)}
                    style={{
                        width: nav_menu_width + "px",
                        cursor: token !== undefined && token !== "" ? "pointer" : "",
                    }}>{types_router.stay.title}</div>
                <div className={switch_container_type === types_router.drink.typeId ? "menu_link_title_text_create_account_active p-0 m-0" : "menu_link_title_text_create_account p-0 m-0"}
                    onClick={() => setSwitchPersTypeContainer(uuid, lang, types_router.drink.typeId)}
                    style={{
                        width: nav_menu_width + "px",
                        cursor: token !== undefined && token !== "" ? "pointer" : "",
                    }}>{types_router.drink.title}</div>
                <div className={switch_container_type === types_router.play.typeId ? "menu_link_title_text_create_account_active p-0 m-0" : "menu_link_title_text_create_account p-0 m-0"}
                    onClick={() => setSwitchPersTypeContainer(uuid, lang, types_router.play.typeId)}
                    style={{
                        width: nav_menu_width + "px",
                        cursor: token !== undefined && token !== "" ? "pointer" : "",
                    }}>{types_router.play.title}</div>
                <div className={switch_container_type === types_router.relax.typeId ? "menu_link_title_text_create_account_active p-0 m-0" : "menu_link_title_text_create_account p-0 m-0"}
                    onClick={() => setSwitchPersTypeContainer(uuid, lang, types_router.relax.typeId)}
                    style={{
                        width: nav_menu_width + "px",
                        cursor: token !== undefined && token !== "" ? "pointer" : "",
                    }}>{types_router.relax.title}</div>
                <div className={switch_container_type === types_router.watch.typeId ? "menu_link_title_text_create_account_active p-0 m-0" : "menu_link_title_text_create_account p-0 m-0"}
                    onClick={() => setSwitchPersTypeContainer(uuid, lang, types_router.watch.typeId)}
                    style={{
                        width: nav_menu_width + "px",
                        cursor: token !== undefined && token !== "" ? "pointer" : "",
                    }}>{types_router.watch.title}</div>
            </Menu>
        )
    }
}


const mapStateToProps = (state) => ({
    screenSize: state.Setting.htmlBody.bodyClientWidth,
    app_menu_height: state.Setting.appMenu.app_menu_height,
    is_mobile: state.Setting.is_mobile,
    token: state.Member.authModal.token,
    switch_container_type: state.FormBank.personalization.switch_container,

    lang: state.Setting.lang,
    uuid: state.Member.authModal.uuid,
    switch_container: state.Member.switch_container,
})

const mapDispatchToProps = {
    setSwitchPersTypeContainer: Actions.setSwitchPersTypeContainer,
}

const MenuPersType = connect(mapStateToProps, mapDispatchToProps)(MenuPersType_)
export default MenuPersType
