import PropTypes from 'prop-types';
import React from 'react'
import Zoom from '@material-ui/core/Zoom';
import { scrollToTopStyles } from '../Common/ComponentsStylingBank'
import useScrollTrigger from '@material-ui/core/useScrollTrigger';

export const ScrollTopLazyLoading = (props) => {
    const { children, window, targetId, rightPosition } = props;
    const classes = scrollToTopStyles();
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
        disableHysteresis: true,
        threshold: 500,
    });

    const handleClick = (event) => {
        const anchor = (event.target.ownerDocument || document).querySelector(targetId);

        if (anchor) {
            anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    };

    return (
        <Zoom in={trigger} >
            <div onClick={handleClick} role="presentation" className={classes.root} style={{ right: rightPosition, zIndex: "5" }}>
                {children}
            </div>
        </Zoom>
    );
}

ScrollTopLazyLoading.propTypes = {
    children: PropTypes.element.isRequired,
    targetId: PropTypes.string.isRequired,
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
};