import { ArrowLeftIcon, ArrowRightIcon, LeftQuoteIcon, RightArrowIcon, RightQuoteIcon } from '../../Common/SVGBank';
import React, { Component } from 'react'
import './BucketList.css'
import BucketListElementIdeas from './BucketListElementIdeas';
import Slider from 'react-slick';
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom';
import { URL_REPLACE } from '../../Common/RegExValidate';
import { ScrollMenu } from 'react-horizontal-scrolling-menu';
import { TrackEvent } from '../../../../utility/utility';

const SliderArrowLeft = ({ className, to, onClick }) => (
    <button type="button" onClick={onClick} className={`button button--text button--icon ${className}`} aria-label={to}>
        <ArrowLeftIcon className="icon" icon={to} style={{
            height: "60px",
            width: "60px",
            transform: 'translate(30px, -40px)',

        }} />
    </button>
)
const SliderArrowRight = ({ className, to, onClick }) => (
    <button type="button" onClick={onClick} className={`button button--text button--icon ${className}`} aria-label={to}>
        <ArrowRightIcon className="icon" icon={to} style={{
            height: "60px",
            width: "60px",
            transform: 'translate(-520px, -40px)',
        }} />
    </button>
)



const BucketList_ = ({ homepageBucketList, screenSize,
    // bodyClientWidth, 
    lang, is_mobile }) => {

    const history = useHistory()
    const goToBucketListPage = (description, feature_id) => {
        TrackEvent('HOME',"Click bucketlist",feature_id)
        const url = "/feature/" + description.replace(URL_REPLACE, "-") + "/" + feature_id
        history.push(url.toLowerCase())
    }
    const bucketList = homepageBucketList
    const display_element_length =
        screenSize >= 1024 ? 5 :
            screenSize >= 1367 ? 3 :
                screenSize > 500 ? 2 : 2

    const left_element = bucketList && bucketList.length < display_element_length ? "left_element" : ""
    const arrowValue = bucketList && bucketList.length > display_element_length ? true : false
    let col_9 = "col-9"
    let col_3 = "col-3"
    if (!isNaN(is_mobile)) {
        col_9 = is_mobile ? "col-12" : "col-9"
        col_3 = is_mobile ? "col-12" : "col-3"
        // heightContainer = bodyClientWidth * 0.82 / 10 + 100
    }


    const setting = {
        touchMove: false,
        dots: false,
        infinite: true,
        arrows: arrowValue,
        centerMode: false,
        className: "center",
        centerPadding: "0px",
        speed: 500,
        lazyLoad: false,
        autoplay: false,
        slidesToShow: 5,
        slidesToScroll: 3,
        pauseOnHover: false,
        prevArrow: <SliderArrowLeft to="prev" />,
        nextArrow: <SliderArrowRight to="next" />,
        responsive: [
            {
                breakpoint: 1367,
                settings: {
                    touchMove: true,
                    slidesToShow: 3,
                    slidesToScroll: 2,
                    arrows: false,

                }
            },
            {
                breakpoint: 500,
                settings: {
                    touchMove: true,
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    arrows: false,
                }
            },
        ]
    }
    return (
        <div className="row ml-0 mr-0" style={{ marginBottom: is_mobile ? "30px" : "60px", }}>


            <div style={{ minWidth: "300px", maxWidth: "450px" }} className={col_3}>
                <div className="text-left"></div>
                <div className="padding-ready">
                    <div className="text-left">
                        <RightQuoteIcon width={is_mobile ? "17px" : "34.473px"} height={is_mobile ? "12px" : "24.339px"} fill="#19bc9b" />
                    </div>
                    <div style={{
                        font: is_mobile ? "16px/20px Futura Hv BT" : "40px/48px Futura Hv BT",
                        color: "#202020", paddingRight: "30px", paddingLeft: "30px", width: is_mobile ? "70%" : "",
                    }}>
                        Ready to tick some items off your bucket list?
                            </div>
                    <div className="text-right" style={{ marginRight: is_mobile ? "30%" : "0px" }}>
                        <LeftQuoteIcon width={is_mobile ? "17px" : "34.473px"} height={is_mobile ? "12px" : "24.339px"} fill="#19bc9b" />
                    </div>
                    <p style={{ marginTop: is_mobile ? "15px" : "30px", marginRight: is_mobile ? "30%" : "0px", color: "#202020", font: is_mobile ? "10px/12px Futura Md BT" : "16px/22px Futura Md BT" }}>Explore hundreds of bucket list ideas and start checking them off, one by one. Don't waste a moment, life is too short.</p>
                    {!is_mobile && <div className="button-block mb-4">
                        <button onClick={() => goToBucketListPage("bucketlist", "4")} type="button" className="btn btn-primary ExploreMore"> Explore more <RightArrowIcon /></button>
                    </div>}
                </div>
            </div>
            <div className={col_9}>
                {is_mobile ?
                    <div className="mobileBucketlist">
                    <ScrollMenu LeftArrow={null} RightArrow={null}>
                        {bucketList !== undefined && bucketList.length > 0 &&
                            bucketList.map((bucketListIdea, idx) => {
                                return (
                                    <BucketListElementIdeas key={idx}
                                    hideMargin={true} bucketListIdea={bucketListIdea} lang={lang} />
                                )
                            })
                        }
                    </ScrollMenu>
                    </div>
                    :
                    <Slider {...setting} className={left_element + ' bucketListSlider'} >
                        {bucketList !== undefined && bucketList.length > 0 && bucketList.map((bucketListIdea, idx) => {
                            return (
                                <BucketListElementIdeas key={idx} bucketListIdea={bucketListIdea} lang={lang} />
                            )
                        })
                        }
                    </Slider>}
            </div>


            {is_mobile && <div className="button-block mb-4 col-12 text-right">
                <button type="button" onClick={() => goToBucketListPage("bucketlist", "4")} className="btn btn-primary ExploreMore" style={{ font: "10px/10px Futura Md BT", height: "40px" }}> Explore more <RightArrowIcon width={is_mobile ? "13" : "19"} height={is_mobile ? "13" : "19"} /></button>
            </div>}

        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        bodyClientWidth: state.Setting.htmlBody.bodyClientWidth,
        lang: state.Setting.lang,
        homepageBucketList: state.Home.homepage_bucket_list,
        is_mobile: state.Setting.is_mobile,
    }

}


const BucketList = connect(mapStateToProps, null)(BucketList_)
export default BucketList
