import * as Actions from '../../../../redux/actions'

import { CheckIcon, MinusSignIcon, PlusCircleIcon, PlusSignIcon } from '../SVGBank'

import AppBar from '@material-ui/core/AppBar'
import CircularProgress from '@material-ui/core/CircularProgress'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import {
  DELAY_ONE_SECOND, URL_REPLACE,
} from "../RegExValidate"
import React from 'react'
import { SearchTimelineDestinationTextField } from '../FormComponentsBank'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import { connect } from "react-redux"
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom';
import { arrayMove, horizontalListSortingStrategy, SortableContext } from '@dnd-kit/sortable'
import { DndContext } from '@dnd-kit/core'
import { DestinationDragItem } from './DestinationDrag'

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  }
}

export const TripDestinationTabs = connect((state) => ({
  lang: state.Setting.lang,
  uuid_auth: state.Member.authModal.uuid,
  searchDestination: state.FormBank.TimelineWizard.searchDestination,
  isFetchingEstablishments: state.FormBank.TimelineWizard.isFetchingEstablishments,

  activeTrip: state.FormBank.TimelineWizard.activeTrip,
  activeDestinationId: state.FormBank.TimelineWizard.activeDestinationId,
  switchFeatureContainer: state.FormBank.FeatureFormat,
  activeId: state.Destination.destination.activeId,
}), {
  fetchDestination: Actions.fetchDestination,
  fetchEstablishmentSuggestedTrips: Actions.fetchEstablishmentSuggestedTrips,
  fetchDestinationPhotoGallery: Actions.fetchDestinationPhotoGallery,
  fetchEstablishmentSortingOptions: Actions.fetchEstablishmentSortingOptions,
  fetchEstablishmentInfluencers: Actions.fetchEstablishmentInfluencers,
  fetchDestinationFeaturesAndTypes: Actions.fetchDestinationFeaturesAndTypes,
  setDestinationId: Actions.setDestinationId,
  setSearchDestinationSelected: Actions.setSearchDestinationSelected,

  setActiveTripDestinationId: Actions.setActiveTripDestinationId,
  addTripDestinatinInTrip: Actions.addTripDestinatinInTrip,
  addGoogleDestinatinInTrip: Actions.addGoogleDestinatinInTrip,
  setTimelineTripDestinationOpenStatus: Actions.setTimelineTripDestinationOpenStatus,
  dragDropTripDestinatinInTrip: Actions.dragDropTripDestinatinInTrip,
},
)(({
  activeTrip,
  tripId = activeTrip.tripId,
  tripDuration = activeTrip.tripDuration,
  uuid_auth,
  uuid = uuid_auth !== "" ? uuid_auth : "TMPUUID",
  destinations = activeTrip.tripDestinations,
  lengthDestinations = destinations.length,
  lastDestination = lengthDestinations > 0 && destinations[lengthDestinations - 1] !== undefined ? destinations[lengthDestinations - 1] : "",
  lastDestinationId = lastDestination !== "" && lastDestination.id !== undefined ? lastDestination.id : "",
  setSearchDestinationSelected,
  lang,
  activeDestinationId,
  searchDestination,
  destinationSelected = searchDestination.destinationSelected,
  isFetchingEstablishments,
  dragDropTripDestinatinInTrip,
  setDestinationId,
  fetchDestination,
  fetchDestinationPhotoGallery,
  fetchEstablishmentSuggestedTrips,
  fetchDestinationFeaturesAndTypes,
  fetchEstablishmentSortingOptions,
  fetchEstablishmentInfluencers,
  setActiveTripDestinationId,
  setTimelineTripDestinationOpenStatus,
  addTripDestinatinInTrip,
  addGoogleDestinatinInTrip,
  switchFeatureContainer,
  fillColor = switchFeatureContainer.color,
  activeId,
  from = "timeline",
  background_color = "",
}) => {

  const searchDestinationStyles = makeStyles((theme) => ({

    root: {
      background: background_color === "" ? "#FFFFFF 0% 0% no-repeat padding-box" : background_color + " 0% 0% no-repeat padding-box",
      boxShadow: "0px 3px 6px #00000029",
      border: "1px solid #B1B1B1",
      borderRadius: "4px",
      position: 'relative',
      marginLeft: 0,
      marginTop: "0px",
      height: '48px',
      width: "100%",
      padding: "0px 0px 0px 5px",

      '& .MuiFormLabel-root': {
        font: "14px/0px Futura Md BT",
        color: "#B1B1B1",
      },
      '& .MuiFormControl-root': {
        width: "100%",
      },
      '& .MuiAutocomplete-popupIndicator': {
        display: "none",
      },
      '& .MuiInput-underline:before': {
        transition: 0,
        borderBottom: 0,
      },

      '& .MuiInputBase-input': {
        color: background_color === "" ? "var(--bgBlack)" : "#FFFFFF80",
        font: "16px/16px Futura Md BT",
      },
      '& .MuiInput-underline:after': {
        transition: 0,
        borderBottom: 0,
      },
      '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
        borderBottom: 0,
      },
      '& .MuiFormLabel-root.Mui-focused': {
        color: "#B1B1B1",
      },
      '& .Mui-focused': {
        font: "14px/14px Futura Md BT",
      },
      '& .MuiFormLabel-filled': {
        font: "14px/14px Futura Md BT",
      },
      '& .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot': {
        paddingRight: "30px"
      },
      '& .MuiAutocomplete-inputRoot[class*="MuiInput-root"] .MuiAutocomplete-input': {
        padding: "0px"
      },
    },
  }))
  const tabsStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      width: '100%',
      backgroundColor: theme.palette.background.paper,
      paddingTop: 10,
      "& .MuiTab-wrapper": {
        font: "16px/19px Futura Md BT",
        color: "var(--frameBlack)",
      },
      "& .MuiButtonBase-root": {
        outline: "none",
      },
      "& .MuiAppBar-colorDefault": {
        backgroundColor: background_color === "" ? "var(--mainWhite)" : background_color,
        boxShadow: "none",
      },
      "& .MuiTab-root": {
        minWidth: "fit-content",
      },
      "& .MuiTabs-indicator": {
        backgroundColor: "#0D3A7D",
      },
      "& .MuiTabs-flexContainer": {
        // justifyContent: "center",
      },
    },
  }))
  const classesTabs = tabsStyles()
  const history = useHistory()
  const classesSearchBar = searchDestinationStyles()

  const handleChange = (event, tabIndexValue) => {
    if (destinations.length > 0 && destinations[tabIndexValue] !== undefined && parseInt(destinations[tabIndexValue].is_custom) === 0) {
      //  setActiveTripDestinationId(destinations[tabIndexValue]) //remove this code
      const url = "/destination/" + destinations[tabIndexValue].name.replace(URL_REPLACE, "-") + "/" + 1 + "/" + destinations[tabIndexValue].destId;
      history.push(url.toLowerCase())
      // fetchData(destinations[tabIndexValue].id, lang, destinations[tabIndexValue].td_id, "OPENSELECTED", destinations[tabIndexValue])
      // delay(function () {
      //   document.getElementById(destinations[tabIndexValue].td_id) !== null && document.getElementById(destinations[tabIndexValue].td_id).scrollIntoView({ behavior: 'smooth', block: 'start' })
      // }, DELAY_ONE_SECOND)
    }

    event.preventDefault()
  }
  const delay = (() => {
    let timer = 0
    return (callback, ms) => {
      clearTimeout(timer)
      timer = setTimeout(callback, ms)
    }
  })()
  const [openSearch, setOpenSearch] = React.useState("CLOSE")
  const [destinationsList, setDestinations] = React.useState(destinations)

  const handleSearchStatus = (status) => {
    setOpenSearch(status);
    (status === "CLOSE" || status === "COMPLETE") && setSearchDestinationSelected(null)
  }
  const anchorRef = React.useRef(null)

  React.useEffect(()=>{
    if(destinations && destinations?.length >0){
      setDestinations(destinations)
    }
  },[destinations])
  const selectDestination = (destination) => {
    console.log(destination,'222')
    setActiveTripDestinationId(destination)
    const url = "/destination/" + destination.name.replace(URL_REPLACE, "-") + "/" + 1 + "/" + destination.destId;
    history.push(url.toLowerCase())
  }

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }
    setOpenSearch("CLOSE")
    setSearchDestinationSelected(null)
  }
  const fetchData = (id, lang, tripDestinationId = "NO_UPDATE", open = "", destination) => {
    if ((destinations.length === 1 && activeDestinationId !== activeId) ||
      (destinations.length > 1 && id !== activeId)) {
      setDestinationId(id)
      fetchDestination(id, lang)
      fetchDestinationPhotoGallery(id, lang)
      fetchEstablishmentSuggestedTrips(id, lang)
      fetchDestinationFeaturesAndTypes(id, lang)
      fetchEstablishmentSortingOptions(lang)
      fetchEstablishmentInfluencers(id, lang)
    }
    tripDestinationId !== "NO_UPDATE" && setActiveTripDestinationId(destination)
    open === "OPENSELECTED" && setTimelineTripDestinationOpenStatus(tripDestinationId, "OPENSELECTED")
    //console.log("TripDestinationTabs : fetchData()")
    //console.log(activeDestinationId !== activeId)
    //console.log((destinations.length === 1))
    //console.log((destinations.length === 1 && activeDestinationId !== activeId))
    //console.log((destinations.length > 1 && activeDestinationId !== id))
    //console.log("fetch destination")
  }
  const handleAddDestinationToTrip = () => {
    //console.log("TripDestinationTabs handleAddDestinationToTrip()")
    if (!destinationSelected.source) {
      //console.log("selected destionation from MUV")
      addTripDestinatinInTrip(lang, uuid, destinationSelected, tripId, 1, lastDestinationId)
      fetchData(destinationSelected.destinationId, lang, "NO_UPDATE", "", null)
      handleSearchStatus("CLOSE")
    } else if (destinationSelected.source && destinationSelected.source === "Other destinations") {
      //console.log("selected destionation from GOOGLE")
      //console.log(destinationSelected)
      let geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({
        'address': destinationSelected.name
      }, function (results, status) {
        if (status === window.google.maps.GeocoderStatus.OK) {
          let latitude = results[0] !== undefined && results[0].geometry.location.lat()
          let longitude = results[0] !== undefined && results[0].geometry.location.lng()
          //console.log(`latitude = ${latitude}`)
          //console.log(`longitude = ${longitude}`)
          addGoogleDestinatinInTrip(lang, uuid, destinationSelected, tripId, 1, lastDestinationId, latitude, longitude) //add 1 day for custom new trips
        }
      })
      handleSearchStatus("CLOSE")
    }
  }

  const [isDraggingId, setDraggingId] = React.useState(null)
  const [isDropId, setDropId] = React.useState(null)

  const onDragOverDestination = (event) => {
    event.preventDefault()
    event.stopPropagation()
    event.dataTransfer.dropEffect = "move"
    //setDraggingId(null)
  }


  const onDragEnterDestination = (event,index_destination_drag) => {
    event.dataTransfer.dropEffect = "move"
    setDropId(index_destination_drag)
  }
  const onDragStartDestination = (event, index_destination_drag) => {
    event.dataTransfer.setData("index_destination_drag", index_destination_drag)
    setTimelineTripDestinationOpenStatus("", "CLOSEALL")
    setDraggingId(index_destination_drag)
    event.dataTransfer.effectAllowed = "move"
  }

  const onDropDestination = (event, index_destination_drop) => {
    const index_destination_drag = event.dataTransfer.getData("index_destination_drag")
    if (destinations !== undefined && destinations.length > 0
      && destinations[index_destination_drag] !== undefined
      && destinations[index_destination_drag].td_id !== undefined) {
      const destinationsRearrange = Array.from(new Set(destinations.filter(d => d.td_id !== destinations[index_destination_drag].td_id)))
      destinationsRearrange.splice(index_destination_drop, 0, destinations[index_destination_drag])
      dragDropTripDestinatinInTrip(lang, uuid, destinationsRearrange, tripId)
      setDraggingId(null)
      setDropId(null)
    }


  }

  const handleDragEnd = (event) => {
    const { active, over } = event;


    if (active.id !== over.id) {
      const oldIndex = active.id;
      const newIndex = over.id;

      const rearranged = arrayMove([...destinationsList], oldIndex, newIndex);
      // setDestinations(rearranged);
     dragDropTripDestinatinInTrip(lang, uuid, rearranged, tripId)
    }
  }


  //console.log("TripDestinationTabs destinationSelected : ")
  //console.log(destinationSelected)
  return (
    <div
      className={classesTabs.root} style={{ backgroundColor: background_color === "" ? "var(--mainWhite)" : background_color, }}>
      {
        openSearch === "CLOSE" &&
        <AppBar position="static" color="default" >
          <div style={{ display: from === "timeline" ? "flex" : "" }}>
            {from === "timeline" && <Tabs style={{ flexGrow: "1" }}
              value={false}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="inherit"
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs"
            >
              {/* {destinationsList.length > 0 && <DndContext
                onDragEnd={handleDragEnd}
                autoScroll
              >
                <SortableContext  items={destinationsList} strategy={horizontalListSortingStrategy}>
                  {destinationsList.map((destination, index_destination) => {
                    const cityName = destination && destination.customDestinationData ? destination.customDestinationData.city_name : destination.name || "";
                    return (
                      <div  key={destination.td_id} onClick={() => selectDestination(destination)}>
                    <DestinationDragItem  id={index_destination} cityName={cityName} />
                    </div>
                    
                    )
                  })}
                </SortableContext>

              </DndContext>} */}


              {destinations.length > 0 && destinations.map((destination, index_destination) => {
                const cityName = destination && destination.customDestinationData ? destination.customDestinationData.city_name : destination.name || "";
                return (
                  <div key={destination.td_id}
                    onClick={() => selectDestination(destination)}

                  >
                    <div
                      draggable={destinations.length > 1 && isDraggingId !== index_destination ? true : false}
                      onDragEnd={() => {setDraggingId(null); setDropId(null)}}
                      onDragStart={(e) => destinations.length > 1 ? onDragStartDestination(e, index_destination) : {}}
                      onDragEnter={(e) => destinations.length > 1 ? onDragEnterDestination(e, index_destination) : {}}
                      onDragOver={(e) => destinations.length > 1 ? onDragOverDestination(e) : {}}
                      onDrop={(e) => destinations.length > 1 ? onDropDestination(e, index_destination) : {}}
                      onDragLeave={() => {}}
                      className={destinations.length > 1 ? "draggable" : ""}
                      style={{
                        borderLeft: isDraggingId !== null  && isDraggingId > index_destination 
                        && isDropId === index_destination ? "2px solid rgba(25, 188, 155, 0.5)" : "",
                        borderRight: isDraggingId !== null  && isDraggingId <index_destination
                        && isDropId === index_destination? "2px solid rgba(25, 188, 155, 0.5)" : "",
                      }}>
                      <Tab label={cityName} {...a11yProps(destination.td_id)} />
                    </div>

                  </div>

                )
              })}
            </Tabs>}

            {openSearch === "CLOSE" ?

              (from === "timeline" ?
                <div style={{ flexGrow: "0", cursor: "pointer" }} ref={anchorRef} onClick={() => handleSearchStatus("OPEN")}><PlusSignIcon style={{ marginTop: "15px", marginRight: "5px" }} fill={fillColor} /></div>
                :
                <div class="addActivity" style={{ flexGrow: "0", cursor: "pointer" }} ref={anchorRef} onClick={() => handleSearchStatus("OPEN")}><div style={{ font: "18px/21px Futura Md BT", color: "#19BC9B", textAlign: "right", }}><PlusCircleIcon width={25} height={25} style={{ marginRight: "5px" }} fill={background_color === "" ? "#19BC9B" : "#19BC9B77"} /> {"Add a new destination"}</div></div>)
              : <div style={{ flexGrow: "0", }}>
                <CircularProgress
                  disableShrink
                  size={15}
                  style={{
                    color: "#6A9EEC",
                    textAlign: "right",
                    marginTop: "15px",
                    marginRight: "5px",
                  }} /></div>}
          </div>
        </AppBar>}
      {openSearch !== "CLOSE" &&
        <ClickAwayListener onClickAway={handleClose}>
          <div style={{ display: "flex" }}>
            <div className={classesSearchBar.root}>
              <SearchTimelineDestinationTextField label="Search Destination"
                required={false}
                id="search_destination"
                setOpenSearch={setOpenSearch} />
            </div>
            {openSearch === "OPEN" && <div style={{ flexGrow: "0", cursor: "pointer" }} onClick={() => { console.log("TripDestinationTabs.jsx"); handleSearchStatus("CLOSE") }}><MinusSignIcon style={{ marginTop: "15px", marginLeft: "15px", marginRight: "5px" }} fill={fillColor} /></div>}
            {openSearch === "COMPLETE" && <div style={{ flexGrow: "0", cursor: "pointer" }} onClick={() => { console.log("TripDestinationTabs.jsx"); handleAddDestinationToTrip() }}><CheckIcon style={{ marginTop: "15px", marginLeft: "10px", marginRight: "5px" }} fill={fillColor} /></div>}
          </div>
        </ClickAwayListener>
      }
    </div>
  )
})
