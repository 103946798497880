import { Backdrop, CircularProgress, FormControl, InputLabel, Link, Modal, Select } from '@material-ui/core'
import React from 'react'
import { PersonalizedTripDestination } from '../../Common/FormComponentsBank';
import { PlusSignIcon, RightArrowIcon, TimesIcon } from '../../Common/SVGBank'
import { searchDestinationStyles, PersonalizedModalUseStyles, DurationUseStyles, TripStartDateStyles } from './personalizedTripStyles';
import { useSelector, useDispatch } from 'react-redux'
import { toggleModal, createTripThunk } from './personalizedTrip.slice';
import { useHistory } from "react-router-dom"
import { URL_REPLACE } from '../../Common/RegExValidate';

const Destination = ({ setDestinations, destinations, index, intensity,defaultSelected }) => {
    const classesSearchBar = searchDestinationStyles()
    const durationClass = DurationUseStyles()
    var indexArray = ['first', 'second', 'third', 'fourth', 'fifth', 'sixth', 'seventh', 'eighth', 'ninth', 'tenth', 'eleventh', 'twelfth', 'thirteenth', 'fourteenth', 'fifteenth', 'sixteenth', 'seventeenth', 'eighteenth', 'nineteenth'];
    const destinationLabel = `Enter your ${indexArray[index]} destination`;
    const numberOfDays = intensity === '3' ? 15 : intensity === '5' ? 10 : 7;
    const [destination, setTripDestination] = React.useState({
        id: '',
        duration: null
    })

    const handleDurationChange = (event) => {
        const value = event.target.value;
        setTripDestination(prevState => {
            return { ...prevState, duration: value }
        })
    };

    React.useEffect(() => {
        let destinationsCopy = [...destinations];
        destinationsCopy[index] = destination;
        setDestinations(destinationsCopy)
        /// destination[index] = destination
        return () => {
        }
    }, [destination])

    return (
        <div className="row m-0">
            <div className={"col-8"} style={{
                marginTop: "20px",
                paddingLeft: "0px",
                paddingRight: "7px",
            }}>
                <div className={classesSearchBar.root}>
                    <PersonalizedTripDestination label={destinationLabel}
                        required={true} defaultSelected={defaultSelected}
                        id="search_destination" setDestination={setTripDestination} />
                </div>

            </div>
            <div className={"col-4"} style={{
                marginTop: "20px",
                paddingLeft: "10px",
                paddingRight: "0px",
            }}>

                <FormControl className={durationClass.formControl}>
                    <InputLabel htmlFor="duration">Duration</InputLabel>
                    <Select
                        native
                        value={destination.duration}
                        onChange={handleDurationChange}
                        inputProps={{
                            name: 'duration',
                            id: 'duration',
                        }}
                    >
                        <option aria-label="None" value={null} />
                        {numberOfDays && Array.apply(null, { length: numberOfDays }).map((element, index) => {
                            const value = index + 1;
                            return (
                                <option value={value} key={index}>{value} {value === 1 ? "day" : "days"} </option>
                            )
                        })}

                    </Select>
                </FormControl>

            </div>
        </div>

    )

}

export const PersonalizedTripComponent = () => {
    const [destinations, setDestinations] = React.useState([])
    const [creating, setCreating] = React.useState(false)
    const showModal = useSelector(state => state.PersonalizedTrip.modalOpen)
    const lang = useSelector(state => state.Setting.lang)
    const uuid = useSelector(state => state.Member.authModal.uuid)
    const tripId = useSelector(state => state.FormBank.TimelineWizard.activeTrip.tripId);
    const destination =  useSelector(state => state.Destination.destination)
    const defaultSelected = {destinationId:destination?.activeId,...destination?.generalInfo,}
    const [limit, setLimit] = React.useState(1);
    const classes = PersonalizedModalUseStyles();
    const dispatch = useDispatch()
    const classesDate = TripStartDateStyles()
    let arrow_icon_size = 15
    let arrow_font = "14px/14px Futura Md BT"

    const [intensity, setIntensity] = React.useState(null);
    const [datePicker, setDatePicker] = React.useState(false)

    const addDestination = () => {
        setLimit(prevState => {
            const newLimit = prevState + 1;
            return newLimit;
        })
    }
    const durationClass = DurationUseStyles()
    const history = useHistory()
    const [selectedDate, setSelectedDate] = React.useState(null);

    const handleDateChange = (date) => {
        setSelectedDate(date);
        setDatePicker(false)
    };

    const handleIntensityChange = (event) => {
        const value = event.target.value;
        setIntensity(value)
    };

    const createTrip = async () => {
        setCreating(true)
        const trip = {
            density: intensity,
            destinations: destinations,
            lang: lang,
            uuid: uuid || "TMPUUID",
            tripId: tripId || ""

        }
        const newTrip = await dispatch(createTripThunk(trip));
        const addedTrip = newTrip.payload;
        if (addedTrip) {
            setCreating(false)
            // const destination = addedTrip.tripDestinations[0];
            // const url = "/destination/" + destination.name.replace(URL_REPLACE, "-") + "-" + destination.countryName.replace(URL_REPLACE, "-") + "/" + 1 + "/" + destinations[0].id;
            // history.push(url.toLowerCase())
            clearData()
        }
        else{
            setCreating(false)
        }

        setTimeout(() => {
            setCreating(false)
        }, 8000);
    }



    const hasAllValues = () => {
        return destinations.length > 0 && destinations[0].id && intensity;
    }

    const clearData = () => {
        setLimit(1)
        setIntensity(null)
        setDestinations([])
        setSelectedDate(null)
        dispatch(toggleModal())
    }

    return (
        <React.Fragment>
            <Modal
                open={showModal}
                onClose={clearData}
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 1000,
                }}
            >
                <div style={{
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)'

                }} className={classes.paper}>

                    <Backdrop className={classes.backdrop} open={creating} >
                        <CircularProgress color="inherit" />
                    </Backdrop>

                    <div className="d-flex">
                        <div style={{
                            font: "24px/24px Futura Hv BT",
                            color: "#191919",
                            paddingLeft: '0px',
                            paddingBottom: '30px',
                            paddingTop: '15px',
                            width: "90%",
                            marginRight: "5%",
                            lineHeight: '32px'
                        }}>
                            Let us suggest a personalized itinerary based on your müver  profile
                                    </div>
                        <div
                            style={{
                                cursor: "pointer",
                            }}
                            onClick={clearData}><TimesIcon style={{ marginTop: "15px" }} width={'20'} height={'20'} /></div>

                    </div>




                    <div className="row m-0">
                        <div className={"col-8"} style={{
                            marginTop: "20px",
                            paddingLeft: "0px",
                            paddingRight: "7.5px",
                        }}>
                            <FormControl required className={durationClass.formControl}>
                                <InputLabel htmlFor="age-native-simple">Intensity</InputLabel>
                                <Select
                                    native
                                    value={intensity}
                                    onChange={handleIntensityChange}
                                    inputProps={{
                                        name: 'intensity',
                                        id: 'intensity',
                                    }}
                                >
                                    <option aria-label="None" value="" />
                                    <option value={3}>Relaxed (2-3 activities/day)</option>
                                    <option value={5}>Average (4-5 activities/day)</option>
                                    <option value={7}>Intense (6-7 activities/day)</option>
                                </Select>
                            </FormControl>
                        </div>
                    </div>

                    {Array.apply(null, { length: 10 }).slice(0, limit).map((element, index) => {
                        return (<Destination setDestinations={setDestinations} destinations={destinations} index={index} key={index} intensity={intensity}
                            defaultSelected={index === 0? defaultSelected: null} />)
                    })}

                    <div className="row m-0">
                        <Link onClick={() => addDestination()} style={{
                            font: "14px/14px Futura Md BT",
                            paddingTop: 20,
                            color: "var(--mainGreen)"
                        }}>
                            <PlusSignIcon style={{ marginTop: "-3px", marginRight: "5px" }} width={arrow_icon_size} height={arrow_icon_size} fill={"var(--mainGreen)"} />     add a destination
                </Link>
                    </div>





                    <div className="m-0">


                        <div onClick={() => hasAllValues() && createTrip()}
                            style={{
                                cursor: "pointer",
                                backgroundColor: !hasAllValues() ? "var(--lightGraySeparator)" : "var(--mainGreen)",
                                padding: "15px 3px 0px 14px",
                                height: 44,
                                width: 161,
                                marginTop: "40px",
                                display: "block",
                                border: "0",
                                float: "right"
                            }}>
                            <div style={{
                                textAlign: "left",
                                font: arrow_font,
                                letterSpacing: "0",
                                margin: "0",
                                color: "var(--mainWhite)",
                            }}>
                                Create my trip  <RightArrowIcon width={arrow_icon_size} height={arrow_icon_size} style={{ marginLeft: "15px" }} />
                            </div>
                        </div>
                    </div>

                </div>

            </Modal>

        </React.Fragment>
    )
}