import {
    FlightShapeIcon,
    PlusSignIcon,
    TimesIcon,
} from "../SVGBank"

import React from 'react'
import { connect } from 'react-redux'
import * as Actions from '../../../../redux/actions'

const NeedFlights_ = ({ setBookingFormStatus,setBookingFormActiveTab,openDefault, destination, switchFeatureContainer }) => {
    const [open, setOpen] = React.useState(openDefault);

    const searchFlight = () =>{
        setBookingFormStatus(true)
        setBookingFormActiveTab('flight')
       }

    return (
        <React.Fragment>
            {open ? <div style={{
                marginTop: "12px",
                marginLeft: "12px",
                marginRight: "0px",
                marginBottom: "12px",
                backgroundColor: "var(--mainWhite)",
                textAlign: "center",
                cursor: "pointer"   ,     
                position: "relative",
                                                             
            }} >
                       <div style={{ position: "absolute", top: "5px", right: "10px", cursor: "pointer" }} onClick={() => { setOpen(false);  }} >
                        <TimesIcon width={15} height={15} /></div>
                  <div >  
                <div onClick={searchFlight} style={{ paddingBottom: "15px", paddingTop: "30px",cursor: "pointer" }}><FlightShapeIcon width={31} height={31} fill={switchFeatureContainer.color} /></div>
                <div onClick={searchFlight} style={{ font: "18px/21px Futura Hv BT", color: "var(--frameBlack)" }}>Need a flight ?</div>
                <div onClick={searchFlight} style={{ font: "16px/19px Futura Md BT", color: "#B1B1B1", paddingTop: "15px", paddingBottom: "30px", }}>Search for best flights deals for your trip</div>
                <hr  onClick={searchFlight} style={{ border: "1px solid #E8E8E8", height: "0px", margin: "0px", }} />
                <div onClick={searchFlight} style={{  cursor: "pointer",font: "14px/16px Futura Hv BT", color: switchFeatureContainer.color, paddingTop: "12px", paddingBottom: "12px", }}><PlusSignIcon width={9} height={10} style={{ marginRight: "5px" }} fill={switchFeatureContainer.color} />{"find a flight"}</div>
                </div>
            </div>
                : <div style={{
                    marginTop: "12px",
                    marginLeft: "12px",
                    marginRight: "0px",
                    marginBottom: "12px",
                    backgroundColor: "var(--mainWhite)",
                    textAlign: "center",
                    top: "30px",
                    zIndex: "4",    
                    cursor: "pointer"
                }}
                    onClick={searchFlight}>
                    <div style={{ font: "14px/16px Futura Hv BT", color: switchFeatureContainer.color, paddingTop: "12px", paddingBottom: "12px", }}><PlusSignIcon width={9} height={10} style={{ marginRight: "5px" }} fill={switchFeatureContainer.color} />{"find a flight "}<FlightShapeIcon width={17} height={17} fill={switchFeatureContainer.color} /></div>
                </div>
            }
        </React.Fragment>
    )
}

const mapStateToProps = (state) => ({
    switchFeatureContainer: state.FormBank.FeatureFormat,

})

const mapDispatchToProps = {
    setBookingFormActiveTab: Actions.setBookingFormActiveTab,
    setBookingFormStatus: Actions.setBookingFormStatus,
}

const NeedFlights = connect(mapStateToProps, mapDispatchToProps)(NeedFlights_)
export default NeedFlights
