import { makeStyles } from '@material-ui/core/styles'

export const registerTextFieldUseStyles = makeStyles(theme => ({
    root: {
        '& .MuiTextField-root': {
            width: "100%",
            border: "2px solid #E8E8E8",
            font: "14px/24px Futura Md BT",
            padding: "10px 10px 10px 10px",
            height: "95px",
            margin: "0px",
        },
        '& .MuiFormLabel-root': {
            font: "14px/24px Futura Md BT",
            color: "#B1B1B1",
            padding: "10px",
        },
        '& .MuiInputBase-input': {
            font: "18px/24px Futura Md BT",
            color: "#707070",
        },
        '& .MuiFormLabel-root.Mui-focused': {
            color: "#B1B1B1"
        },
        '& .MuiInput-underline:before': {
            transition: 0,
            borderBottom: 0,
        },
        '& .MuiInput-underline:after': {
            transition: 0,
            borderBottom: 0,
        },
        '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
            borderBottom: 0,
        },

    },
}))

export const registerPasswordUseStyles = makeStyles(theme => ({
    root: {
        '& .MuiTextField-root': {
            width: "100%",
            border: "2px solid #E8E8E8",
            font: "14px/24px Futura Md BT",
            padding: "10px 10px 10px 10px",
            height: "95px",
            margin: "0px",
        },
        '& .MuiFormLabel-root': {
            font: "14px/24px Futura Md BT",
            color: "#B1B1B1",
            padding: "10px",
        },
        '& .MuiInputBase-input': {
            font: "18px/24px Futura Md BT",
            color: "#707070",
            marginRight: "40px",
        },
        '& .MuiFormLabel-root.Mui-focused': {
            color: "#B1B1B1"
        },
        '& .MuiInput-underline:before': {
            transition: 0,
            borderBottom: 0,
        },
        '& .MuiInput-underline:after': {
            transition: 0,
            borderBottom: 0,
        },
        '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
            borderBottom: 0,
        },

    },
}))

export const registerHandleUseStyles = makeStyles(theme => ({
    root: {
        '& .MuiTextField-root': {
            font: "16px/21px Futura Hv BT",
            color: "#19BC9B",
        },
        '& .MuiFormLabel-root': {
            font: "14px/24px Futura Md BT",
            color: "#19BC9B",
            padding: "0px",
        },
        '& .MuiInputBase-input': {
            font: "16px/21px Futura Hv BT",
            color: "#19BC9B",
            textAlign: "center"
        },
        '& .MuiFormLabel-root.Mui-focused': {
            color: "#19BC9B",
            left: "-10px",
        },
        '& .MuiFormLabel-root.MuiFormLabel-filled': {
            color: "#19BC9B",
            left: "-10px",
        },
        '& .MuiInput-underline:before': {
            transition: 0,
            borderBottom: 0,
        },
        '& .MuiInput-underline:after': {
            transition: 0,
            borderBottom: 0,
        },
        '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
            borderBottom: 0,
        },

        '& .Mui-focused':{
            // backgroundColor:"var(--mainGrey)"
            // border: '1px solid #707070',
            // paddingLeft: 55,
            // paddingRight: 55
        }

    },
}))

export const registerSignatureUseStyles = makeStyles(theme => ({
    root: {
        '& .MuiTextField-root': {
            font: "20px/28px Futura Lt BT",
            color: "#19BC9B",
        },
        '& .MuiFormLabel-root': {
            font: "14px/24px Futura Md BT",
            color: "#19BC9B",
            padding: "0px",
        },
        '& .MuiInputBase-input':{
            font: "16px/18px Futura Lt BT",
            color: "#FFFFFF",
            textAlign: "center"
        },
        //'& .MuiInputBase-input .Mui-focused':{
        //    backgroundColor:"var(--mainGrey)"
        //},
        '& .MuiFormLabel-root.Mui-focused': {
            color: "#19BC9B",
            left: "-10px",
        },
        '& .MuiFormLabel-root.MuiFormLabel-filled': {
            color: "#19BC9B",
            left: "-10px",
        },
        '& .MuiInput-underline:before': {
            transition: 0,
            borderBottom: 0,
        },
        '& .MuiInput-underline:after': {
            transition: 0,
            borderBottom: 0,
        },
        '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
            borderBottom: 0,
        },
        '& .Mui-focused':{
            backgroundColor:"var(--mainGrey)"
        }
    },
}))



export const bioAutocompleteUseStyles = makeStyles(theme => ({
    root: {
        '& .MuiAutocomplete-endAdornment .MuiButtonBase-root:nth-child(2)': {
            display: "none",
        },
        '& .MuiTextField-root': {
            width: "100%",
            border: "2px solid #E8E8E8",
            font: "14px/24px Futura Md BT",
            padding: "10px 10px 10px 10px",
            height: "95px",
            margin: "0px",
            backgroundColor: "#FFFFFF",
        },
        '& .MuiFormLabel-root': {
            font: "14px/24px Futura Md BT",
            color: "#B1B1B1",
            padding: "10px",
        },
        '& .MuiInputBase-input': {
            font: "18px/24px Futura Md BT",
            color: "#707070",
        },
        '& .MuiFormLabel-root.Mui-focused': {
            color: "#B1B1B1"
        },
        '& .MuiInput-underline:before': {
            transition: 0,
            borderBottom: 0,
        },
        '& .MuiInput-underline:after': {
            transition: 0,
            borderBottom: 0,
        },
        '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
            borderBottom: 0,
        },

    },
}))

export const dropdownUseStyles = makeStyles(theme => ({
    root: {
        '& .MuiTextField-root': {
            width: "100%",
            border: "2px solid #E8E8E8",
            font: "14px/24px Futura Md BT",
            padding: "10px 10px 10px 10px",
            height: "95px",
            margin: "0px",
            backgroundColor: "#FFFFFF",
        },
        '& .MuiFormLabel-root': {
            font: "14px/24px Futura Md BT",
            color: "#B1B1B1",
            padding: "10px",
        },
        '& .MuiInputBase-input': {
            font: "18px/24px Futura Md BT",
            color: "#707070",
        },
        '& .MuiFormLabel-root.Mui-focused': {
            color: "#B1B1B1"
        },
        '& .MuiInput-underline:before': {
            transition: 0,
            borderBottom: 0,
        },
        '& .MuiInput-underline:after': {
            transition: 0,
            borderBottom: 0,
        },
        '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
            borderBottom: 0,
        },

    },
}))

export const multipltDropdownUseStyles = makeStyles(theme => ({
    root: {
        '& .MuiTextField-root': {
            width: "100%",
            border: "2px solid #E8E8E8",
            font: "14px/24px Futura Md BT",
            padding: "10px 10px 10px 10px",
            minHeight: "95px",
            margin: "0px",

            backgroundColor: "#FFFFFF",
        },
        '& .MuiFormLabel-root': {
            font: "14px/24px Futura Md BT",
            color: "#B1B1B1",
            padding: "10px",
        },
        '& .MuiInputBase-input': {
            font: "10px/10px Futura Md BT",
            color: "#707070",
        },
        '& .MuiInput-formControl': {
            marginTop: "20px",
        },
        '& .MuiFormLabel-root.Mui-focused': {
            color: "#B1B1B1"
        },
        '& .MuiInput-underline:before': {
            transition: 0,
            borderBottom: 0,
        },
        '& .MuiInput-underline:after': {
            transition: 0,
            borderBottom: 0,
        },
        '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
            borderBottom: 0,
        },

    },
}))


export const multiLineTextFieldUseStyles = makeStyles(theme => ({
    root: {
        '& .MuiTextField-root': {
            width: "100%",
            border: "2px solid #E8E8E8",
            font: "14px/24px Futura Md BT",
            padding: "10px 10px 10px 10px",
            margin: "0px",
            backgroundColor: "#FFFFFF",
        },
        '& .MuiFormLabel-root': {
            font: "14px/0px Futura Md BT",
            color: "#B1B1B1",
            padding: "0 10px 0 10px",
        },
        '& .MuiInputBase-input': {
            font: "18px/24px Futura Md BT",
            color: "#707070",
        },
        '& .MuiFormLabel-root.Mui-focused': {
            font: "14px/34px Futura Md BT",
            color: "#B1B1B1"
        },

        '& .MuiFormLabel-root.MuiFormLabel-filled': {
            font: "14px/34px Futura Md BT",
            color: "#B1B1B1"
        },
        '& .MuiInput-underline:before': {
            transition: 0,
            borderBottom: 0,
        },
        '& .MuiInput-underline:after': {
            transition: 0,
            borderBottom: 0,
        },
        '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
            borderBottom: 0,
        },

    },
}))

export const loginModalUseStyles = makeStyles(theme => ({
    paper: {
        position: 'absolute',
        width: 600,
        height: 430,
        backgroundColor: theme.palette.background.paper,
        border: '0px',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        outline: "-webkit-focus-ring-color auto 0px",
    },
}))

export const registerModalUseStyles = makeStyles(theme => ({
    paper: {
        position: 'absolute',
        width: 600,
        height: 500,
        backgroundColor: theme.palette.background.paper,
        border: '0px',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        outline: "-webkit-focus-ring-color auto 0px",
    },
}))

export const modalMobileUseStyles = makeStyles(theme => ({
    paper: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        overflowY: 'scroll',
        backgroundColor: theme.palette.background.paper,
        border: '0px',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        outline: "-webkit-focus-ring-color auto 0px",
    },
}))



export const menuListMemberAreaStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        '& .MuiListItem-root': {
            boxShadow: "0 0 0 0 rgba(0,123,255,.25)",
            font: "16px/16px Futura Md BT",
            color: "#707070",
        },
        '& .MuiListItem-button:hover': {
            textDecoration: "none",
            backgroundColor: "rgba(25, 188, 155, 0.2)",
        },
    },
    buttonText: {
        color: "var(--mainGreen)",
        textAlign: "left",
        font: "16px/22px Futura Md BT",
        letterSpacing: "0",
        paddingTop: "7px",
        paddingBottom: "5px",
        paddingRight: "5px",
        paddingLeft: "16px",
        '&:focus': {
            border: "0",
            outline: "0px",

        },
        '& .MuiButton-label': {
            color: "var(--mainGreen)",
            textAlign: "left",
            font: "16px/24px Futura Md BT",
            paddingLeft: "16px",
        }
    },
    zIndex: {
        zIndex: "1"
    },
    paddingLeft30: {
        paddingLeft: "30px"
    },
    backgroundSelection: {
        '&:hover': {
            backgroundColor: "var(--mainGreen)"
        }
    },
    paper: {
        marginRight: theme.spacing(2),
    },
    dropdown: {

        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: `calc(420px + ${theme.spacing(4)}px)`,
        },
        marginTop: "8px",
        zIndex: "1",
    },
}))

export const menuListTopBarStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        '& .MuiListItem-root': {
            boxShadow: "0 0 0 0 rgba(0,123,255,.25)",
            font: "16px/16px Futura Md BT",
            color: "#707070",
        },
        '& .MuiListItem-button:hover': {
            textDecoration: "none",
            backgroundColor: "rgba(25, 188, 155, 0.2)",
        },
    },
    buttonText: {
        color: "var(--mainGreen)",
        textAlign: "left",
        font: "16px/22px Futura Md BT",
        letterSpacing: "0",
        padding: "5px",
        '&:focus': {
            border: "0",
            outline: "0px",

        },
        '& .MuiButton-label': {
            color: "var(--mainGreen)",
            textAlign: "left",
            font: "16px/24px Futura Md BT",
        }
    },
    zIndex: {
        zIndex: "1"
    },
    paddingLeft30: {
        paddingLeft: "30px"
    },
    backgroundSelection: {
        '&:hover': {
            backgroundColor: "var(--mainGreen)"
        }
    },
    paper: {
        marginRight: theme.spacing(2),
    },
    dropdown: {

        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: `calc(420px + ${theme.spacing(4)}px)`,
        },
        marginTop: "8px",
        zIndex: "1",
    },
}))





export const scrollToTopStyles = makeStyles((theme) => ({
    root: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
}))

export const destinationVisitedAutocompleteStyles = makeStyles(theme => ({
    root: {
        '& .MuiAutocomplete-endAdornment .MuiButtonBase-root:nth-child(2)': {
            display: "none",
        },
        '& .MuiTextField-root': {
            width: "100%",
            border: "2px solid #E8E8E8",
            font: "14px/24px Futura Md BT",
            padding: "10px 10px 10px 10px",
            height: "95px",
            margin: "0px",
        },
        '& .MuiFormLabel-root': {
            font: "14px/24px Futura Md BT",
            color: "#B1B1B1",
        },
        '& .MuiInputBase-input': {
            font: "18px/24px Futura Md BT",
            color: "#707070",
        },
        '& .MuiAutocomplete-inputRoot': {
            paddingTop: "18px",
        },
        '& .MuiAutocomplete-endAdornment': {
            top: "calc(50% - 5px)",
        },
        '& .MuiFormLabel-root.Mui-focused': {
            color: "#B1B1B1"
        },
        '& .MuiInput-underline:before': {
            transition: 0,
            borderBottom: 0,
        },
        '& .MuiInput-underline:after': {
            transition: 0,
            borderBottom: 0,
        },
        '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
            borderBottom: 0,
        },

    },
}))


export const generalSearchStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        '& .MuiListItem-root': {
            boxShadow: "0 0 0 0 rgba(0,123,255,.25)",
            font: "16px/16px Futura Md BT",
            color: "#707070",
        },
        '& .MuiListItem-button:hover': {
            textDecoration: "none",
            backgroundColor: "rgba(25, 188, 155, 0.2)",
        },
    },
    buttonText: {
        color: "var(--mainGreen)",
        textAlign: "left",
        font: "16px/22px Futura Md BT",
        letterSpacing: "0",
        paddingTop: "7px",
        paddingBottom: "5px",
        paddingRight: "5px",
        paddingLeft: "16px",
        '&:focus': {
            border: "0",
            outline: "0px",

        },
        '& .MuiButton-label': {
            color: "var(--mainGreen)",
            textAlign: "left",
            font: "16px/24px Futura Md BT",
            paddingLeft: "16px",
        }
    },
    zIndex: {
        zIndex: "1"
    },
    paddingLeft30: {
        paddingLeft: "30px"
    },
    backgroundSelection: {
        '&:hover': {
            backgroundColor: "var(--mainGreen)"
        }
    },
    paper: {
        marginRight: theme.spacing(2),
    },
    dropdown: {

        transition: theme.transitions.create('width'),
        width: '100%',
        marginTop: "8px",
        zIndex: "1",
    },
}))
export const headerMobileStyles = makeStyles(theme => ({
    root: {
        '& .MuiList-padding': {
            padding: "0px"
        },
        '& .MuiDivider-root': {
            margin: "0 15% 0 15%",
            backgroundColor: "var(--lightGraySeparator)",
        },
    },
    outline: {
        '& .MuiList-root .MuiList-padding': {
            outline: "0px",
        }
    },
}))


export const deleteActivitiesModalUseStyles = makeStyles(theme => ({
    paper: {
        position: 'absolute',
        width: 435,
        height: "auto",
        backgroundColor: theme.palette.background.paper,
        border: '0px',
        boxShadow: theme.shadows[5],
        padding: "22px 26px 42px 42px",
        outline: "-webkit-focus-ring-color auto 0px",
    },
}))

export const activityTimeModalUseStyles = makeStyles(theme => ({
    paper: {
        position: 'absolute',
        minWidth: "310px",
        minHeight: "305px",
        height: "auto",
        backgroundColor: theme.palette.background.paper,
        border: '0px',
        boxShadow: theme.shadows[5],
        outline: "-webkit-focus-ring-color auto 0px",
    },
}))


export const tripDescriptionMultiLineTextFieldUseStyles = makeStyles(theme => ({
    root: {
        '& .MuiTextField-root': {
            width: "100%",
            font: "14px/17px Futura Lt BT",
            margin: "0px",
        },
        '& .MuiFormLabel-root': {
            font: "14px/0px Futura Md BT",
            color: "#B1B1B1",
            padding: "0 10px 0 10px",
        },
        '& .MuiInputBase-input': {
            font: "18px/24px Futura Md BT",
            color: "#707070",
        },
        '& .MuiFormLabel-root.Mui-focused': {
            font: "14px/34px Futura Md BT",
            color: "#c7c7c7",
            background: '#2c2c2c'
        },

        '& .MuiFormLabel-root.MuiFormLabel-filled': {
            font: "14px/34px Futura Md BT",
            color: "#c7c7c7",
        },
        '& .MuiInput-underline:before': {
            transition: 0,
            borderBottom: 0,
        },
        '& .MuiInput-underline:after': {
            transition: 0,
            borderBottom: 0,
        },
        '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
            borderBottom: 0,
        },
        '& .Mui-focused':{
            color: "#c7c7c7",
            background: '#2c2c2c'
        }

    },
}))


export const airportAutocompleteUseStyles = makeStyles(theme => ({
    root: {
        width: "50%",
        padding: "0px 10px 0px 10px",
        '& .MuiAutocomplete-endAdornment .MuiButtonBase-root:nth-child(2)': {
            display: "none",
        },
        '& .MuiTextField-root': {
            width: "100%",
            border: "2px solid #E8E8E8",
            font: "14px/24px Futura Md BT",
            paddingLeft: "10px",
            paddingRight: "10px",
            margin: "0px",
            backgroundColor: "#FFFFFF",
        },
        '& .MuiFormLabel-root': {
            font: "14px/24px Futura Md BT",
            color: "#B1B1B1",
        },
        '& .MuiInputBase-input': {
            font: "14px/24px Futura Md BT",
            color: "#707070",
        },
        '& .MuiFormLabel-root.Mui-focused': {
            color: "#B1B1B1"
        },
        '& .MuiInput-underline:before': {
            transition: 0,
            borderBottom: 0,
        },
        '& .MuiInput-underline:after': {
            transition: 0,
            borderBottom: 0,
        },
        '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
            borderBottom: 0,
        },

    },
}))

export const companionTextFieldUseStyles = makeStyles(theme => ({
    root: {
        '& .MuiTextField-root': {
            width: "100%",
            border: "2px solid #E8E8E8",
            font: "14px/24px Futura Md BT",
            padding: "0px 10px 0px 10px",
            height: "60px",
            margin: "0px",
            backgroundColor: "#FFFFFF",
        },
        '& .MuiFormLabel-root': {
            font: "14px/10px Futura Md BT",
            color: "#B1B1B1",
            padding: "0px 10px 0px 10px",
        },
        '& .MuiInputBase-input': {
            font: "18px/35px Futura Md BT",
            color: "#707070",
        },
        '& label + .MuiInput-formControl': {
            marginTop: "10px",
        },
        '& .MuiFormLabel-root.Mui-focused': {
            color: "#B1B1B1",
        },
        '& .MuiInput-underline:before': {
            transition: 0,
            borderBottom: 0,
        },
        '& .MuiInput-underline:after': {
            transition: 0,
            borderBottom: 0,
        },
        '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
            borderBottom: 0,
        },
        '& .MuiFormHelperText-root.Mui-error': {
            color: "#f44336",
            marginTop: "20px",
        },
        '& .MuiFormHelperText-filled': {
            marginTop: "-6px",
        }

        
    },
}))

export const hotelTextFieldUseStyles = makeStyles(theme => ({
    root: {
        '& .MuiTextField-root': {
            width: "100%",
            border: "2px solid #E8E8E8",
            font: "14px/24px Futura Md BT",
            padding: "10px 10px 10px 10px",
            height: "95px",
            margin: "0px",
        },
        '& .MuiFormLabel-root': {
            font: "18px/24px Futura Md BT",
            color: "#000000",
            paddingBottom: "10px",
        },
        '& .MuiInputBase-input': {
            font: "14px Futura Md BT",
            color: '#B1B1B1',
        },
        '& .MuiFormLabel-root.Mui-focused': {
            font: "18px/24px Futura Md BT",
            color: "#000000",
            paddingBottom: "10px",
        },
        '& .MuiInput-underline:before': {
            transition: 0,
            borderBottom: 0,
        },
        '& .MuiInput-underline:after': {
            transition: 0,
            borderBottom: 0,
        },
        '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
            borderBottom: 0,
        },

    },
}))
