import { animated, useSpring } from 'react-spring'
import * as Actions from '../../../../redux/actions'
import {
    Image,
} from 'react-bootstrap'
import React from 'react'
import { Star } from '../../Common/MemberActions/Star'
import { connect } from 'react-redux'
import { features_router, member_area_router, URL_REPLACE } from "../../Common/RegExValidate"
import { image_boxing } from '../../Common/RegExValidate'
import BucketListName from '../../Common/BucketList/BucketListName';
import { useHistory } from 'react-router-dom';


const BucketListElementIdeas_ = ({ hideMargin,bucketListIdea, is_mobile,setSwitchContainer }) => {
    const [props, set] = useSpring(() => ({ xys: [0, 0, 1], config: { mass: 20, tension: 350, friction: 40 } }))

    let imageW = "272"
    let imageH = "405"
    if (!isNaN(is_mobile)) {
        imageW = is_mobile ? "163" : "272"
        imageH = is_mobile ? "260" : "405"
    }
    const history = useHistory()

    const handleClick = () => {
        setSwitchContainer(member_area_router.bucketList)
        const url = "/bucketlist/" + bucketListIdea.name.replace(URL_REPLACE, "-") + "/" + bucketListIdea.id;
        history.push(url.toLowerCase())
    }


    return (
        <div style={{
            marginBottom: "20px",
            marginRight: hideMargin? 12:"20px",
            marginLeft:  hideMargin? 12:"20px",
           
            marginTop: '20px',
            cursor: "pointer",
            width: imageW+'px'
            // margin: is_mobile ? "20px 10px 20px 10px" : "40px 15px 40px 15px",
        }}  onClick={()=> handleClick()}>
        <animated.div
        
            className=""
            style={{ backgroundColor: "black", position: "relative"}}>

<Image className="min_width_100 img_fit_cover"
                         src={image_boxing(bucketListIdea.image, imageW, imageH)}
                        alt="bucket list" style={{ opacity: '0.8' }}  />



                    <div style={{
                        top: "0px",
                        right: "0px",
                        height: "10%",
                        position: "absolute",
                        alignItems: "center",
                        display: "inline-block"
                    }}>

                <div style={{ height: "100%", textAlign: "right", padding: "10px", }}>
                    <Star
                        unitID={bucketListIdea.id}
                        feature={features_router.bucket_list}
                        fill={"var(--mainWhite)"} />
                </div>


                    </div>

                    <div style={{
                        bottom: "30px",
                        left: "30px",
                        right: "0px",
                        height: "20%",
                    }}>
                        <div style={{ height: "100%", textAlign: "left", }}>
                            <div className="d-flex" style={{ paddingLeft: "10px", paddingBottom: "26px", position: "absolute", bottom: "10px" }}>

                                <div className="futura_md_bt_16_22_white flex_1 box_1" style={{ marginLeft: "10px", marginRight: "10px" }}>
                                    <BucketListName
                                        bucketName={bucketListIdea.name}
                                        bucketId={bucketListIdea.id}
                                        titleStyle={{
                                            cursor: "pointer",
                                            paddingLeft: "10px",
                                            paddingRight: "10px",
                                        }} />
                                </div>
                            </div>
                        </div>
                    </div>
             
        </animated.div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    lang: state.Setting.lang,

    is_mobile: state.Setting.is_mobile,
})

const mapDispatchToProps = {
    setSwitchContainer: Actions.setSwitchContainer,
}

const BucketListElementIdeas = connect(mapStateToProps, mapDispatchToProps)(BucketListElementIdeas_)
export default BucketListElementIdeas
