import * as Actions from '../../../redux/actions'

import ElementTitle from "../Common/ElementTitle"
import HandleName from "../Common/Profile/HandleName"
import {
    Image,
} from 'react-bootstrap'
import React from 'react'
import { Star } from '../Common/MemberActions/Star'
import { URL_REPLACE } from "../Common/RegExValidate"
import { connect } from 'react-redux'
import { features_router } from "../Common/RegExValidate"
import { image_boxing } from "../Common/RegExValidate"
import { useHistory } from "react-router-dom"

/* other trip element */
const OtherTripsElement_ = ({ otherTrip, lang_public, getPublicTrip, setTripToTimeline, getSelectedTripInformation, lang_private, textColor, from, screenSize, uuidPrivate, uuidPublic }) => {
    const history = useHistory()
    const goesTripPage = (tripID, tripName) => {
        const url = "/trip/" + tripName.replace(URL_REPLACE, "-") + "/" + tripID;
        history.push(url.toLowerCase())
        getPublicTrip(tripID, lang);
    }
    const lang = from === "trip_public" ? lang_public : lang_private
    const uuid = from === "trip_public" ? uuidPublic : uuidPrivate
    const getSelectedTripInformationHander = (uuid, tripID) => {
        getSelectedTripInformation(uuid, tripID)
        setTripToTimeline(tripID, uuid)
        window.scrollTo(0, 0);
    }
    let trip_image_width = "212"
    let trip_image_height = "207"
    if (!isNaN(screenSize)) {
        trip_image_width = screenSize < 1024 ? "100" : "212"
        trip_image_height = screenSize < 1024 ? "97" : "207"
    }
    return (
        <div>
            {from === "trip_public" &&
                <div className={"padding_left " + textColor}>
                    <div className="content_container m-2"  >
                        <Image className="min_width_100 img_fit_cover"
                            src={image_boxing(otherTrip.tripImage, "381", "292")}
                            alt="otherTrips" />
                        <div style={{
                            top: "0",
                            left: "0",
                            right: "0",
                            height: "15%",
                            position: "absolute",
                            alignItems: "center",
                        }}>
                            <div style={{ height: "100%", backgroundColor: "rgba(25, 25, 25, 0.2)", textAlign: "right", padding: "5px", }}>
                                <Star
                                    unitID={otherTrip.tripID}
                                    feature={features_router.trips}
                                    fill={"var(--mainWhite)"} />
                            </div>
                        </div>
                        <div style={{
                            top: "15%",
                            left: "0",
                            right: "0",
                            height: "55%",
                            position: "absolute",
                            alignItems: "center",
                            cursor: "pointer",
                        }} onClick={() => goesTripPage(otherTrip.tripID, otherTrip.tripName)} >
                            <div style={{ height: "100%", cursor: "pointer", backgroundColor: "rgba(25, 25, 25, 0.2)", textAlign: "right", padding: "5px", }}>
                            </div>
                        </div>
                        <div style={{
                            bottom: "0",
                            left: "0",
                            right: "0",
                            height: "30%",
                            position: "absolute",
                            alignItems: "center",
                        }}>
                            <div style={{ height: "100%", backgroundColor: "rgba(25, 25, 25, 0.2)", textAlign: "left", }}>

                                <div className="d-flex" style={{ paddingBottom: "26px", position: "absolute", bottom: "0" }}>
                                    {otherTrip.ownerHandle !== undefined && otherTrip.ownerHandle !== "" && <HandleName classNameHandle={"futura_md_bt_16_22_white flex_1 box_1 padding_left_10 padding_right_10"} styleHandle={{ cursor: "pointer" }} muverHandle={otherTrip.ownerHandle} />}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="pl-2 pr-2 ">
                        <div className="p-2 m-1 d-flex">
                            <div className="futura_md_bt_14_14 box_1 flex_grow_1 ">{otherTrip.tripDestName}</div>
                        </div>
                        <div className={"futura_hv_bt_22_26 box_1 m-2 " + textColor} onClick={() => goesTripPage(otherTrip.tripID, otherTrip.tripName)} style={{ cursor: "pointer" }} >{otherTrip.tripName}</div>
                        <div className="futura_lt_bt_14_19_grey box_3 pl-2 pr-2 pt-2">{otherTrip.tripDescription}</div>
                    </div>
                </div>
            }
            {from === "trip_private" &&
                <div className={"padding_left" + textColor}>
                    <div className="content_container m-2" onClick={() => getSelectedTripInformationHander(uuid, otherTrip.tripID)} style={{ cursor: "pointer" }} >
                        <Image className="min_width_100 img_fit_cover"
                            src={image_boxing(otherTrip.headerImage, trip_image_width, trip_image_height)}
                            alt="trip" />
                    </div>
                    <div className="pl-2 pr-2 ">
                        <div onClick={() => getSelectedTripInformationHander(uuid, otherTrip.tripID)} style={{ cursor: "pointer", font: "18px/20px Futura Hv BT" }} className={"box_1 m-2 " + textColor}>{otherTrip.tripName}</div>
                        <div className="pl-2 pr-2 pt-2"><ElementTitle title={otherTrip.tripDescription} style={{
                            font: "14px/ 19px Futura Lt BT",
                            letterSpacing: "0",
                            color: "var(--mainGrey)",
                        }} /></div>
                    </div>
                </div>
            }
        </div>
    )

}

const mapStateToProps = (state) => ({
    lang_public: state.Setting.lang,
    lang_private: state.Setting.lang,
    screenSize: state.Setting.htmlBody.bodyClientWidth,
    uuidPublic: state.Social.member_uuid,
    uuidPrivate: state.Member.authModal.uuid,
})

const mapDispatchToProps = {
    getPublicTrip: Actions.getPublicTrip,
    getSelectedTripInformation: Actions.getSelectedTripInformation,
    setTripToTimeline: Actions.setTripToTimeline,
}

const OtherTripsElement = connect(mapStateToProps, mapDispatchToProps)(OtherTripsElement_)
export default OtherTripsElement
