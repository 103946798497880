import React from 'react'
import { connect } from 'react-redux'

import { AmenitiesList } from "./AmenitiesList"

const Amenities_ = ({ establishment }) => {
    const { generalInfo } = establishment
    const { amenities } = generalInfo

    return (
        <div>
            <div style={{ font: "24px/36px Futura Hv BT", color: "#000000", marginBottom: "30px", }}>Amenities</div>
            <div>
                <AmenitiesList 
                    amenities={amenities} 
                    boxDescription="boxclass" 
                    fontDescription="16px/24px Futura Lt BT" 
                    colorDescription="#929292" 
                    height={460} />
               
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({

    establishment: state.FormBank.HotelSearch.establishment,
    isMobile: state.Setting.is_mobile,
    lang: state.Setting.lang,
    uuid: state.Member.authModal.uuid,

    switchFeatureContainer: state.FormBank.FeatureFormat,
})

const mapDispatchToProps = {

}

const Amenities = connect(mapStateToProps, mapDispatchToProps)(Amenities_)
export default Amenities
