import * as Actions from '../../../redux/actions'

import React, { Component } from 'react'
import { URL_REPLACE } from "../Common/RegExValidate"
import { Menu } from 'semantic-ui-react'
import { connect } from 'react-redux'
import { member_area_router, feature_color } from "../Common/RegExValidate"
import { useHistory } from 'react-router-dom';
import AboutDestination from './AboutDestination'

const Features_ = ({ isAboutPage,generalInfo, activeFeatureId, destination, activeId, switch_container, setSwitchDestinationFeatureContainer, switchFeatureContainer, isMobile, curentDestinationId, lang }) => {
    let fontSize = 18
    let menuItemHeight = 40
    let borderBottomSize = 10
    let menuItemWidth = 160
    if (!isNaN(isMobile)) {
        fontSize = isMobile ? "9" : "18"
        menuItemHeight = isMobile ? "20" : "40"
        borderBottomSize = isMobile ? "5" : "10"
        menuItemWidth = isMobile ? "80" : "160"
    }
    const history = useHistory()
    const goesFeaturePage = (description, feature_id) => {
        const url = "/destination/" + description.replace(URL_REPLACE, "-") + "/" + feature_id + "/" + activeId;
        history.push(url.toLowerCase())
    }

    const mapColor = (featureID) => {
        let feature = feature_color.find(o => o.featureId === String(featureID));
        return feature.color || '';
    }

    return (
        <React.Fragment>

            { switch_container === member_area_router.memberAreaTrip ?

                <Menu pointing secondary style={{ backgroundColor: "#2C2C2C", borderBottom: "0", paddingTop: "10px" }}>
                     {generalInfo && generalInfo?.about && <div
                     className="features"
                        style={{
                            font: fontSize + "px/0px Futura Md BT",
                            letterSpacing: "0",
                            backgroundColor: "initial",
                            opacity: "1",
                            textAlign: "center",
                            height: menuItemHeight + "px",
                            width: menuItemWidth + "px",
                            paddingTop: "0",
                            paddingLeft: "15px",
                            paddingRight: "15px",
                            color: isAboutPage ? mapColor("1") : "var(--frameBlack)",
                            borderBottom: isAboutPage ? borderBottomSize + "px solid " + mapColor("1") : borderBottomSize + "px solid var(--lightGrayF)",
                            cursor: "pointer",
                        }}
                        onClick={() => goesFeaturePage("about", "1")}>
                        <p style={{ verticalAlign: "middle" }}>About</p>
                    </div>}
                    {destination !== undefined
                        && destination.features !== undefined
                        && destination.features.length > 0
                        && destination.features.map((feature, feature_index) => {
                            if (feature.disable !== '1') {
                                return (
                                    <div key={feature_index}
                                    className="features"
                                        style={{
                                            font: fontSize + "px/0px Futura Md BT",
                                            letterSpacing: "0",
                                            backgroundColor: "initial",
                                            opacity: "1",
                                            textAlign: "center",
                                            height: menuItemHeight + "px",
                                            width: menuItemWidth + "px",
                                            paddingTop: "0",
                                            paddingLeft: "15px",
                                            paddingRight: "15px",
                                            color: !isAboutPage &&  activeFeatureId === feature.feature_id ? mapColor(feature.feature_id) : "#FFFFFF",
                                            borderBottom: !isAboutPage &&  activeFeatureId === feature.feature_id ? borderBottomSize + "px solid " + mapColor(feature.feature_id) : borderBottomSize + "px solid var(--mainWhite)",
                                            cursor: "pointer",
                                        }}
                                        onClick={() => goesFeaturePage(feature.title, feature.feature_id)}>
                                        <p style={{ verticalAlign: "middle" }}>{feature.title}</p>
                                    </div>
                                )
                            }
                        })
                    }
                </Menu>
                :
                <Menu pointing secondary style={{ backgroundColor: "#FFFFFF", borderBottom: "0", paddingTop: "10px" }}>
                    {generalInfo && generalInfo?.about && <div
                                      className="features"
                        style={{
                            font: fontSize + "px/0px Futura Md BT",
                            letterSpacing: "0",
                            backgroundColor: "initial",
                            opacity: "1",
                            textAlign: "center",
                            height: menuItemHeight + "px",
                            width: menuItemWidth + "px",
                            paddingTop: "0",
                            paddingLeft: "15px",
                            paddingRight: "15px",
                            color: isAboutPage ? mapColor("1") : "var(--frameBlack)",
                            borderBottom: isAboutPage ? borderBottomSize + "px solid " + mapColor("1") : borderBottomSize + "px solid var(--lightGrayF)",
                            cursor: "pointer",
                        }}
                        onClick={() => goesFeaturePage("about", "1")}>
                        <p style={{ verticalAlign: "middle" }}>About</p>
                    </div>}
                    {destination !== undefined
                        && destination.features !== undefined
                        && destination.features.length > 0
                        && destination.features.map((feature, feature_index) => {
                            if (feature.disable !== '1') {
                                return (
                                    <div key={feature_index}
                                    className="features"
                                        style={{
                                            font: fontSize + "px/0px Futura Md BT",
                                            letterSpacing: "0",
                                            backgroundColor: "initial",
                                            opacity: "1",
                                            textAlign: "center",
                                            height: menuItemHeight + "px",
                                            width: menuItemWidth + "px",
                                            paddingTop: "0",
                                            paddingLeft: "15px",
                                            paddingRight: "15px",
                                            color:!isAboutPage &&  activeFeatureId === feature.feature_id ? mapColor(feature.feature_id) : "var(--frameBlack)",
                                            borderBottom: !isAboutPage && activeFeatureId === feature.feature_id ? borderBottomSize + "px solid " + mapColor(feature.feature_id) : borderBottomSize + "px solid var(--lightGrayF)",
                                            cursor: "pointer",
                                        }}
                                        onClick={() => goesFeaturePage(feature.title, feature.feature_id)}>
                                        <p style={{ verticalAlign: "middle" }}>{feature.title}</p>
                                    </div>
                                )
                            }
                        })
                    }
                </Menu>
            }
        </React.Fragment>
    )
}
const mapStateToProps = state => {
    return {
        destination: state.Destination.destination,
        isMobile: state.Setting.is_mobile,
        activeId: state.Destination.destination.activeId,
        curentDestinationId: state.Destination.destination.activeId,
        activeFeatureId: state.Feature.feature.activeId,
        switchFeatureContainer: state.FormBank.FeatureFormat,
        switch_container: state.Member.switch_container,
        generalInfo: state.Destination.destination.generalInfo,
    }
}

const mapDispatchToProps = {
    setSwitchDestinationFeatureContainer: Actions.setSwitchDestinationFeatureContainer,
    cleanSearchQueryDestination: Actions.cleanSearchQueryDestination,
}

const Features = connect(mapStateToProps, mapDispatchToProps)(Features_)
export default Features
