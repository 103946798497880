import * as Actions from '../../../redux/actions'

import React, { Component } from 'react'

import { Menu } from 'semantic-ui-react'
import { connect } from 'react-redux'
import { member_area_router } from "../Common/RegExValidate"

export class Features_ extends Component {
    render() {
        const { destination, switch_container, setSwitchDestinationFeatureContainer, switchFeatureContainer, isMobile, curentDestinationId, lang } = this.props
        let fontSize = 18
        let menuItemHeight = 40
        let borderBottomSize = 10
        let menuItemWidth = 160
        if (!isNaN(isMobile)) {
            fontSize = isMobile ? "9" : "18"
            menuItemHeight = isMobile ? "20" : "40"
            borderBottomSize = isMobile ? "5" : "10"
            menuItemWidth = isMobile ? "80" : "160"
        }
        return (
            <React.Fragment>

                { switch_container === member_area_router.memberAreaTrip ?

                    <Menu pointing secondary style={{ backgroundColor: "#2C2C2C", borderBottom: "0", paddingTop: "10px" }}>
                        {destination !== undefined
                            && destination.features !== undefined
                            && destination.features.length > 0
                            && destination.features.map((feature, feature_index) => {
                                return (
                                    <div key={feature_index}
                                    className="features"
                                        style={{
                                            font: fontSize + "px/0px Futura Md BT",
                                            letterSpacing: "0",
                                            backgroundColor: "initial",
                                            opacity: "1",
                                            textAlign: "center",
                                            height: menuItemHeight + "px",
                                            width: menuItemWidth + "px",
                                            paddingTop: "0",
                                            paddingLeft: "15px",
                                            paddingRight: "15px",
                                            color: switchFeatureContainer.featureId === feature.feature_id ? switchFeatureContainer.color : "#FFFFFF",
                                            borderBottom: switchFeatureContainer.featureId === feature.feature_id ? borderBottomSize + "px solid " + switchFeatureContainer.color : borderBottomSize + "px solid var(--mainWhite)",
                                            cursor: "pointer",
                                        }}
                                        onClick={() => setSwitchDestinationFeatureContainer(curentDestinationId, lang, feature)}>
                                        <p style={{ verticalAlign: "middle" }}>{feature.title}</p>
                                    </div>
                                )
                            })
                        }
                    </Menu>
                    :
                    <Menu pointing secondary style={{ backgroundColor: "#FFFFFF", borderBottom: "0", paddingTop: "10px" }}>
                        {destination !== undefined
                            && destination.features !== undefined
                            && destination.features.length > 0
                            && destination.features.map((feature, feature_index) => {
                                return (
                                    <div key={feature_index}
                                    className="features"
                                        style={{
                                            font: fontSize + "px/0px Futura Md BT",
                                            letterSpacing: "0",
                                            backgroundColor: "initial",
                                            opacity: "1",
                                            textAlign: "center",
                                            height: menuItemHeight + "px",
                                            width: menuItemWidth + "px",
                                            paddingTop: "0",
                                            paddingLeft: "15px",
                                            paddingRight: "15px",
                                            color: switchFeatureContainer.featureId === feature.feature_id ? switchFeatureContainer.color : "var(--frameBlack)",
                                            borderBottom: switchFeatureContainer.featureId === feature.feature_id ? borderBottomSize + "px solid " + switchFeatureContainer.color : borderBottomSize + "px solid var(--lightGrayF)",
                                            cursor: "pointer",
                                        }}
                                        onClick={() => setSwitchDestinationFeatureContainer(curentDestinationId, lang, feature)}>
                                        <p style={{ verticalAlign: "middle" }}>{feature.title}</p>
                                    </div>
                                )
                            })
                        }
                    </Menu>}
            </React.Fragment>
        )
    }
}
const mapStateToProps = state => {
    return {
        destination: state.Destination.destination,
        isMobile: state.Setting.is_mobile,

        curentDestinationId: state.Destination.destination.activeId,

        switchFeatureContainer: state.FormBank.FeatureFormat,
        switch_container: state.Member.switch_container,
    }
}

const mapDispatchToProps = {
    setSwitchDestinationFeatureContainer: Actions.setSwitchDestinationFeatureContainer,
    cleanSearchQueryDestination: Actions.cleanSearchQueryDestination,
}

const Features = connect(mapStateToProps, mapDispatchToProps)(Features_)
export default Features 
