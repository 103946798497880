import {
    DELAY_ONE_SECOND,
    IMAGE_CLOUD_MAX,
} from "../Common/RegExValidate"
import { image_boxing, loadingImage } from '../Common/RegExValidate'
import "./EstablishmentPage.css"
import { Image } from "react-bootstrap"
import { PhotoVideoIcon } from '../Common/SVGBank'
import React from 'react'
import { SRLWrapper } from "simple-react-lightbox"
import SimpleReactLightbox from "simple-react-lightbox" // Import Simple React Lightbox
import { connect } from 'react-redux'

// import { CameraRegulerIcon } from '../Common/SVGBank'
import { Star } from '../Common/MemberActions/Star';
import AddPhotoComponent from "../Common/AddPhotoComponent"





const TopBarSlider_ = ({ headerHeight, app_menu_height, sliderImages, generalInfo, screenSize, isMobile, switchFeatureContainer, activeId }) => {

    const [imageRef, setImageRef] = React.useState(null)
    const [open, setOpen] = React.useState(false)

    React.useEffect(() => {
        setOpen(false)
        setTimeout(
            () => setOpen(true)
            , DELAY_ONE_SECOND)
    }, [activeId])



    const destinationName = generalInfo !== undefined && generalInfo !== null ? generalInfo.name : ""

    let topImageHeight = isMobile ? "180" : "650"
    let containerH = 500
    let fontDestinationName = isMobile ? "25" : "50"
    let fontIcon = isMobile ? "10" : "16"
    let widthIcon = isMobile ? "110px" : "150px"
    let heightIcon = isMobile ? "30px" : "45px"
    let fontHeightIcon = isMobile ? "17px" : "30px"
    // let fontIconCameraReguler = isMobile ? "15" : "20"
    if (topImageHeight !== undefined) {
        containerH = topImageHeight + "px"
    }
    let sliderIndex = 0

    let destinationImage = sliderImages !== undefined && sliderImages.length > 0 && sliderImages[sliderIndex] !== undefined ? image_boxing(sliderImages[sliderIndex], screenSize, topImageHeight) : loadingImage.src + "?func=crop&w=" + Math.floor(screenSize) + "&h=" + Math.floor(topImageHeight)
    const isDefaultImg = destinationImage && destinationImage.includes("grayBackground");
    if (isDefaultImg) {
        topImageHeight = isMobile ? "180" : "250"
    }

    const display_letters = screenSize > 1024 ? 50 : 20
    const options = open && sliderImages !== undefined && sliderImages.length > 0 ? {
        overlayColor: "rgb(25, 25, 25)",
        captionColor: "rgba(25, 188, 155, 1)",
        captionFontFamily: "Futura Md BT",
        captionFontSize: "22px",
        captionFontWeight: "300",
        captionFontStyle: "capitalize",
        buttonsBackgroundColor: "#191919",
        buttonsIconColor: "rgba(25, 188, 155, 1)",
        autoplaySpeed: 1500,
        transitionSpeed: 900,

        buttons: {
            showFullscreenButton: false,
        },
    } : {}
    let fontIconPhotoVideo = isMobile ? "18" : "25"
    console.log(`${isDefaultImg}`)

    function setOverlay(check){
        if(check){
            return "#18b490";
        }else{
            console.log("NO OVERLAY")
        }
    }
   
    return (
        <div className="position-relative z_index_2 topBarEstablishment" style={{ backgroundColor: (setOverlay(isDefaultImg))}}>
            <img
                src={destinationImage}
                alt="destinationimage" width={screenSize} height={topImageHeight} 
                // style={{opacity: '0.8',}}
                
                />
            {sliderImages === undefined || sliderImages.length <= 0 || sliderImages[sliderIndex] === undefined && <div className="mask_block" style={{ height: containerH }}></div>}

            <div style={{
                position: "absolute", top: "60px", right: "84px",
                width: "29px", height: "28px"
            }} className="starEst" >
                <div> </div>
                {generalInfo && generalInfo.destinationID ?
                    <Star
                        unitID={generalInfo.destinationID}
                        feature={switchFeatureContainer}
                        fill={"#FFFFFF"} /> : null
                }
            </div>

            <div className="z_index_3"
                style={{
                    top: "30%",
                    left: "0",
                    right: "0",
                    position: "absolute",
                }}>
                <div className="box_1 text-left establishmentTitle"
                    style={{
                        font: "30px/36px Futura Lt BT",
                        fontWeight: "bold",
                        color: "#FFFFFF",
                        paddingLeft: "15.5%",
                        paddingBottom: "39px",
                    }}>PLACE</div>
                <div className="box_1 text-left establishmentName"
                    style={{
                        font: fontDestinationName + "px/80px Futura Md BT",
                        color: "#FFFFFF",
                        paddingLeft: "15.5%",
                        textShadow: '0px 6px 6px #191919'
                    }} >{destinationName !== undefined && destinationName.length > display_letters + 1 ? destinationName.substr(0, display_letters) + "..." : destinationName}</div>
            </div>
            {open && sliderImages !== undefined && sliderImages.length > 0 && sliderImages[0] !== undefined
                && sliderImages[0].imageName !== undefined &&
                <SimpleReactLightbox>
                    <div className="z_index_3"
                        style={{
                            bottom: "10%",
                            left: "0",
                            right: "60px",
                            position: "absolute",
                            zIndex: "5",
                        }}>
                        <div className="box_1 text-right"
                            style={{
                                color: "#FFFFFF",
                                paddingLeft: "8.5%"
                            }} >
                            <div className="d-inline-block"
                            >
                                <SRLWrapper options={options} >

                                    {sliderImages.map((image, index) => {

                                        return (
                                            index === 0 ?
                                                <Image
                                                    key={index}
                                                    style={{
                                                        position: "absolute",
                                                        right: "200%"
                                                    }}
                                                    src={image_boxing(image, screenSize * 0.9, screenSize * 0.4)}
                                                    className="img_fit_cover d-block w-100"
                                                    ref={imageClick => setImageRef(imageClick)}
                                                    alt={image.description} />
                                                : (index < IMAGE_CLOUD_MAX &&
                                                    <Image
                                                        key={index}
                                                        style={{
                                                            position: "absolute",
                                                            right: "200%"
                                                        }}
                                                        src={image_boxing(image, screenSize * 0.9, screenSize * 0.4)}
                                                        className="img_fit_cover d-block w-100"
                                                        alt={image.description} />)
                                        )
                                    })}
                                </SRLWrapper>
                            </div>


                            <span className="addPhotosEst">




                                <div className="d-inline-block viewGalleryEst"
                                    onClick={() => imageRef? imageRef?.click():null}
                                    style={{
                                        width: widthIcon,
                                        height: heightIcon,
                                        background: "#FFFFFF",
                                        color: switchFeatureContainer.color,
                                        border: "1px solid " + switchFeatureContainer.color,
                                        borderRadius: "39px",
                                        textAlign: "center",
                                        paddingTop: '5px',
                                        font: fontIcon + "px/" + fontHeightIcon + " Futura Md BT",
                                        marginRight: "8px",
                                        cursor: "pointer",
                                    }}>
                                    <PhotoVideoIcon style={{ marginTop: -5 }} width={fontIconPhotoVideo} fill={switchFeatureContainer.color} />{" View Gallery"}


                                </div>

                                <AddPhotoComponent dataId={activeId} dataKey={"placeId"} />  </span>
                        </div>
                    </div>
                </SimpleReactLightbox>
            }

        </div >
    )

}


const mapStateToProps = state => {
    return {
        screenSize: Math.min(state.Setting.htmlBody.bodyClientWidth, document.body.clientWidth),
        isMobile: state.Setting.is_mobile,
        activeId: state.Destination.establishment.activeId,
        sliderImages: state.Destination.establishment.sliderImages,
        generalInfo: state.Destination.establishment.generalInfo,
        switchFeatureContainer: state.FormBank.FeatureFormat,
        app_menu_height: state.Setting.appMenu.app_menu_height,
    }
}

const mapDispatchToProps = {
}

const TopBarSlider = connect(mapStateToProps, mapDispatchToProps)(TopBarSlider_)
export default TopBarSlider
