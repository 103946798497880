import React, { useState } from 'react'
import { connect } from 'react-redux'
import { RightArrowIcon, CircleDownIcon, CircleUpIcon } from "../Common/SVGBank"
import ReactDOM from 'react-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUsers, faHotel, faRuler, faBed } from '@fortawesome/free-solid-svg-icons'
import * as Actions from '../../../redux/actions'
import { priceline_image_boxing, loadingImage } from '../Common/RegExValidate'
import { Image } from 'react-bootstrap';

import Button from '@material-ui/core/Button'
import Icon from '@material-ui/core/Icon';
import { Action } from 'rxjs/internal/scheduler/Action'
import { ActionsObservable } from 'redux-observable'
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom"

String.prototype.toProperCase = function () {
    return this.replace(/\w\S*/g, function (txt) { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); });
}

const Rooms_ = ({ establishment, hotel_form_state, lang,isMobile, uuid, addBundleToHotelBooking, setHotelBookingState, booking_bundles, booking_info }) => {
    const { generalInfo } = establishment
    const { establishmentPricelineID, room_data, amenity_data } = generalInfo
    const hotel_id = establishmentPricelineID
    const history = useHistory()

    const showRooms = room_data !== undefined ? true : false

    let currRoomId = ""
    let newRoom = true
    let optionNo = 1
    let prevOptionsNum = 0
    let prevRoomId = ""

    const boardType = {
        NONE: "No breakfast",
        BREAKFAST: "Breakfast",
        ALL_INCLUSIVE: "All inclusive"
    }

    const widthContainer = 300
    const heightContainer = 250

    const useStyles = makeStyles({
        optionsHdr: {
            color: "rgba(0, 0, 0, 1)",
            font: "18px/28px Futura Hv BT",
        },
        optionsRooms: {
            color: "rgba(0, 0, 0, 1)",
            font: "16px/28px Futura Hv BT",
        },
        optionsText: {
            color: "#929292",
            font: "14px/18px Futura Lt BT",
        },
        optionsGreenText: {
            color: "rgba(28, 188, 155, 1)",
            font: "14px/28px Futura Lt BT",
        },
        optionsBtn: {
            //backgroundColor: "rgba(28, 188, 155, 1)",
            color: "rgba(255, 255, 255, 1)",
            font: "14px/14px Futura Hv BT",
            width: "150px",
            height: "44px",
            padding: "14px 22px",
            cursor: "pointer",
            backgroundColor: 'var(--mainGreen)',
        },
        optionBlock: {
            marginLeft: isMobile ? 0 :widthContainer,
        }
    });

    const isLoggedIn = () => {
        return uuid && uuid.length > 0
    }

    const classes = useStyles();

    const handleAddRoomToBooking = ({ lang, uuid, trip_id, hotel_id, ppn_book_bundle, check_in, check_out, rooms, adults, booking_bundles }) => {

        // if booking_info.establishmentPricelineID !== hotel_id then clear booking
        /*   if(booking_info.establishmentPricelineID !== undefined && booking_info.establishmentPricelineID !== hotel_id) {
               console.log("clear");
               //clearHotelBooking();
               booking_bundles=[]
               setHotelBookingState({
                   hotel_id, 
                   ppn_book_bundle, 
                   check_in, 
                   check_out, 
                   rooms,
                   adults
               })
           }
           */

        addBundleToHotelBooking({ hotel_id, ppn_book_bundle, check_in, check_out, rooms, adults, booking_bundles })

        //setHotelEstablishmentId(pricelineId)
        //setSwitchContainer(member_area_router.establishment)
        history.push("/booking"
            + (trip_id ? "-" + trip_id : "")
            + "?check_in=" + hotel_form_state.check_in
            + "&check_out=" + hotel_form_state.check_out
            + "&rooms=" + (hotel_form_state.rooms ? hotel_form_state.rooms : 1)
            + "&adults=" + (hotel_form_state.adults ? hotel_form_state.adults : 1)
            + (ppn_book_bundle ? "&ppn_book_bundle=" + ppn_book_bundle : ""))
    }

    let [, setState] = React.useState();

    //const [openId, setOpenId] = React.useState("")
    const [openId, setOpenId] = React.useState([])

    const showOptions = (id) => {
        let arry = openId
        arry.push(id)
        setOpenId(arry)
        setState({})
    }
    const hideOptions = (id) => {
        let arry = openId
        arry = arry.filter(item => item !== id)
        setOpenId(arry)
        setState({})
    }

    function randomIntFromInterval(min, max) { // min and max included 
        return Math.floor(Math.random() * (max - min + 1) + min)
    }


    const hotelRooms = room_data?.room_data;
    const photos = room_data?.photo_data;

    const defaultVals = hotelRooms? Array.from(Array(hotelRooms.length).keys(), n => 2): null;
    const [rateLimit, setRateLimit] = React.useState(defaultVals);
    const [selectedOption, setSelectedOption] = React.useState(null);

   
    return (
        <div>
            <div style={{ font: "24px/16px Futura Hv BT", color: "#000000" }}>Choose Room</div>
            <div>
                <div style={{ font: "16px/18px Futura Md BT", color: "#000000" }}>

                    {showRooms && hotelRooms && hotelRooms.sort((a,b)=> a?.rate_data[0]?.price_details?.baseline_price - b?.rate_data[0]?.price_details?.baseline_price).map((room, idx) => {

                        newRoom = (currRoomId !== room.id)
                        prevRoomId = newRoom ? currRoomId : prevRoomId
                        currRoomId = newRoom ? room.id : currRoomId
                        prevOptionsNum = newRoom ? optionNo : optionNo + 1
                        optionNo = newRoom ? 1 : optionNo + 1
 

                        const ppn_book_bundle = room.ppn_book_bundle;

                        // if(room.rate_type === "SMOP"){
                        //     console.log(room,"room") 
                        // }
                        
                      
                        const averageSleeps = room?.rate_data?.reduce((total, next) => total + next?.occupancy_limit, 0) / room?.rate_data?.length;

                        const titleArray = room?.title.split(',');
                        const roomName = titleArray ? titleArray[1] : null;
                    
                        const toggleRateLimit = () => {
                            setSelectedOption((oldId)=>{
                                return idx === oldId? null: idx;
                            })
                          
                        }

                        const photoIndex = photos && photos?.length > 0 ? randomIntFromInterval(0, photos?.length) : 1;

                        return (<div key={idx} >
                            {newRoom &&
                                <React.Fragment>
                                    

                                    <div style={{ width: "100%", height: 30, float: "none", clear: "both", borderBottom: idx !== 0 ? "2px solid rgb(232, 232, 232)" : "0px none", marginBottom: 15 }}></div>
                                    <div style={{ font: "18px/24px Futura Hv BT", color: "#000000", marginBottom: 10 }}>{room?.title?.replace(".", "")}</div>
                                    {room && room?.description && <div style={{ font: "16px/24px Futura Lt BT", color: "#929292", marginBottom: 10 }}>{room?.description?.replace(".", "")}</div>}
                                    <div>
                                        {/* {roomName && <React.Fragment>
                                            <FontAwesomeIcon icon={faBed} style={{ marginRight: "5px" }} />
                                            <span className={classes.optionsRooms}>{roomName + " bed"}</span>
                                        </React.Fragment>} */}
                                        {room.room_square_footage > 0 && <React.Fragment>
                                            <FontAwesomeIcon icon={faRuler} style={{ marginLeft: "15px", marginRight: "5px" }} /><span className={classes.optionsRooms}>{room.room_square_footage + " sq ft"}</span>
                                        </React.Fragment>}
                                        {averageSleeps && <React.Fragment>
                                            <FontAwesomeIcon icon={faUsers} style={{ marginLeft: "15px", marginRight: "5px" }} /><span className={classes.optionsRooms}>Sleeps {Math.round(averageSleeps)}</span>
                                        </React.Fragment>}


                                    </div>
                                    {photos && photos?.length > 0 && <div style={{ width: widthContainer, position: "static", float: isMobile? "none":"left" }}>
                                        <Image style={{  objectFit: "cover", objectPosition: "center", width: widthContainer, height: heightContainer }}
                                            alt="establishment" src={photos[idx+1]||
                                               "https://amhqajbken.cloudimg.io/v7/_muvestab_/000_c…on/grayBackgroundCamera.jpg?func=crop&w=359&h=300" }
                                        />
                                    </div>}


                                </React.Fragment>
                            }

                            {room && room?.rate_data && room?.rate_data?.sort((a,b)=> a.benchmark_price_details.display_price - b.benchmark_price_details.display_price).slice(0, selectedOption === idx? room?.rate_data?.length: 2 ).map((rate, indexRate) => {
                                const specialPrice = isLoggedIn() && rate?.rate_type === "SMOP" && rate.benchmark_price_details;
                                const signInforSpecial = !isLoggedIn() && rate?.rate_type === "SMOP" && rate.benchmark_price_details;

                                let ratePriceText = specialPrice ? "müver special price" : signInforSpecial ? "sign in for müver special price" : "per night";

                                let rateTextColor = specialPrice ? "#0D3A7D" : signInforSpecial ? "#19BC9B" : "#929292";

                                

                                return (
                                    <div className={classes.optionBlock} key={indexRate} >

                                        <div style={{ marginLeft: isMobile? 0 : 20, marginTop: 10, font: "18px/28px Futura Hv BT", color: "#000000" }}>Option {indexRate + 1}</div>
                                        {/* <div style={{ marginLeft: 20, width: "29%", display: "inline-block", verticalAlign: "top" }}>
                                            <div><span className={classes.optionsText}>{rate.title}</span></div>
                                            <div><span className={classes.optionsText}>{boardType[rate.board_type]}</span></div> 
                                        </div> */}
                                        <div className={classes.optionsText} style={{ width: "24%", marginLeft:isMobile?0: 20, display: "inline-block", verticalAlign: "top", textTransform: "capitalize" }}>
                                            {rate?.rate_amenity_data !== undefined && rate?.rate_amenity_data !== null && rate?.rate_amenity_data.length > 0 && rate?.rate_amenity_data.map((amenity, i) => {
                                                return (<div key={i} style={{ textTransform: "capitalize" }}>{amenity?.toLowerCase()}</div>)
                                            })}
                                        </div>




                                        {<div style={{ width: "22%", marginLeft: isMobile ? 0:20, display: "inline-block", verticalAlign: "top" }}>
                                            {rate.refund_type && <div><span className={classes.optionsText}>{rate.refund_type === "NONE" ? "Non-Cancellable" : "Cancellable"}</span></div>}
                                            {rate.refund_type && <div><span className={classes.optionsText}>{rate.refund_type === "NONE" ? "Non-Refundable" : "Refundable"}</span></div>}
                                            {rate.occupancy_limit > 0 && <div><span className={classes.optionsText}>Max Occupancy: {rate.occupancy_limit}</span></div>}

                                        </div>}

                                        <div style={{ width: "100%", display: "inline-block", verticalAlign: "top", marginLeft: isMobile ? 0: 20, marginTop: 15,marginBottom:isMobile?20:0 }}>
                                            <div className={classes.optionsGreenText} style={{ width: '51%', float: isMobile?'none': 'left', paddingTop: 20 }}>{rate.available_rooms ? `We have ${rate.available_rooms} rooms left` : ""}</div>
                                            <div style={{ display: "inline-grid", width: isMobile? "50%": "27%", textAlign: 'right', paddingRight: 15 }}><span className={classes.optionsHdr}>
                                                {specialPrice && <span style={{ font: "14px/28px Futura Lt BT", color: 'red', textDecoration: 'line-through', marginRight: 12 }}>{rate.price_details.display_symbol + (rate?.benchmark_price_details?.display_price.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","))}</span>}
                                                {signInforSpecial ? rate.price_details.display_symbol + (rate?.benchmark_price_details?.display_price?.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")) : rate.price_details.display_symbol + (rate.price_details.display_price.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","))}</span>
                                                <span className={classes.optionsText} style={{ color: rateTextColor }}>{ratePriceText}</span></div>
                                            <div onClick={() => handleAddRoomToBooking({ lang, uuid, ppn_book_bundle:rate.ppn_bundle, booking_bundles, hotel_id, ...hotel_form_state })
                                            }
                                                className={classes.optionsBtn}
                                                style={{ display: "inline-block", height: 50, opacity: 1 }} >Book <RightArrowIcon width={15} height={15} style={{ marginLeft: "40px" }} /></div>

                                        </div>

                                      

                                    </div>
                                )
                            })}
                              {room?.rate_data && room?.rate_data?.length > 2 && <div className="" style={{ color:"var(--mainGreen)", 
                              marginLeft: isMobile?0:317, marginTop: isMobile? 15:"5px" }}>
                                           {selectedOption === idx &&<div style={{cursor:"pointer"}}  onClick={()=> toggleRateLimit()}> <CircleUpIcon /> Hide more options</div>}
                                            {selectedOption !== idx  && <div style={{cursor:"pointer"}}  onClick={()=> toggleRateLimit()}> <CircleDownIcon /> Show more options</div>}
                                        </div>}



                        </div>)
                    })}

                </div>
            </div>
            <div style={{ width: "100%", height: 0, float: "none", clear: "both" }}></div>
        </div>
    )
}

const mapStateToProps = (state) => ({

    establishment: state.FormBank.HotelSearch.establishment,
    hotel_form_state: state.FormBank.HotelSearch.hotel_form_state,
    isMobile: state.Setting.is_mobile,
    lang: state.Setting.lang,
    uuid: state.Member.authModal.uuid,

    booking_bundles: state.FormBank.BookingPriceline.booking_bundles,
    switchFeatureContainer: state.FormBank.FeatureFormat,
    booking_info: state.FormBank.BookingPriceline.booking_info,
})

const mapDispatchToProps = {
    addBundleToHotelBooking: Actions.addBundleToHotelBooking,
    setHotelBookingState: Actions.setHotelBookingState,
}

const Rooms = connect(mapStateToProps, mapDispatchToProps)(Rooms_)
export default Rooms
