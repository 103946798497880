import "./MuvTribePage.css";

import {
  Col,
  Image,
  Row,
} from 'react-bootstrap'
import * as Actions from '../../../../redux/actions'
import { PropTypes } from "prop-types"
import React from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { image_boxing } from "../../Common/RegExValidate";
import { getFriendRequests, getUserFriends, ModifyFriendRequest } from "../../SocialPage/social.slice";
import Slider from 'react-slick'
import { ArrowLeftIcon, ArrowRightIcon } from "../../Common/SVGBank";


const SliderArrowRight = ({ className, to, onClick }) => (
  <button type="button" onClick={onClick} className={`button button--text button--icon ${className}`} aria-label={to}>
    <ArrowRightIcon className="icon" icon={to} style={{
      height: "40px",
      width: "40px",
      transform: 'translate(-10px, -55px)'
    }} />
  </button>
)



const SliderArrowLeft = ({ className, to, onClick }) => (
  <button type="button" onClick={onClick} className={`button button--text button--icon ${className}`} aria-label={to}>
    <ArrowLeftIcon className="icon" icon={to} style={{
      height: "40px",
      width: "40px",
      transform: 'translate(30px, -55px)'
    }} />
  </button>
)


/* tribe friends component */
const MuvTribeFriendRequests_ = ({ uuid, title, screenSize }) => {
  const dispatch = useDispatch()
  const friendRequests = useSelector(state => state.SocialPage.muvTribe?.friendRequests);

  const friendRequestsCount = friendRequests ? friendRequests?.length : 0;
  const userFollowing = useSelector(state => state.SocialPage.muvTribe?.following);
  React.useEffect(() => {


  }, [])




  const goToBlackPage = (handle) => {
    if (handle) {
      const url = "/" + handle;
      window.open(url.toLowerCase());
    }
  }

  const acceptRequest = async (accept,friendID) => {
    if(friendID&& friendID?.length >0){
      const response = await dispatch(ModifyFriendRequest({
        accept:accept,
        friendID: friendID,
        uuid:uuid
      }))
  
      if(response){
        dispatch(getUserFriends(uuid))
        dispatch(getFriendRequests(uuid))
      }
    }
    
  }


  let settings = {
    touchMove: false,
    dots: false,
    infinite: false,
    arrows: friendRequests && friendRequests.length > 6 ? true : false,
    speed: 500,
    autoplay: false,
    lazyLoad: false,
    slidesToShow: 6,
    slidesToScroll: 3,
    pauseOnHover: true,
    centerMode: false,
    centerPadding: "70px",
    prevArrow: <SliderArrowLeft to="prev" />,
    nextArrow: <SliderArrowRight to="next" />,
    responsive: [
      {
        breakpoint: 1724,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
          centerPadding: "60px",
          arrows: friendRequests && friendRequests.length > 4 ? true : false,
        }
      },
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          centerPadding: "60px",
          arrows: friendRequests && friendRequests.length > 3 ? true : false,
        }
      },
      {
        breakpoint: 1100,
        settings: {
          touchMove: true,
          slidesToShow: 2,
          slidesToScroll: 2,
          arrows: false,
          centerPadding: "60px",
        }
      },
      {
        breakpoint: 768,
        settings: {
          touchMove: true,
          slidesToShow: 3,
          slidesToScroll: 2,
          arrows: false,
          centerPadding: "60px",

        }
      },
      {
        breakpoint: 500,
        settings: {
          touchMove: true,
          slidesToShow: 2,
          slidesToScroll: 2,
          arrows: false,
          centerPadding: "15px",
        }
      },
    ]
  }



  const display_element_length = screenSize > 1724 ? 5 :
    screenSize > 1500 ? 4 :
      screenSize > 1100 ? 3 :
        screenSize >= 1024 ? 2 :
          screenSize >= 768 ? 3 :
            screenSize > 500 ? 1 : 2
  const left_element = friendRequests && friendRequests.length < display_element_length ? "left_element" : ""


  if(!friendRequests || !(friendRequests?.length >0)){

    return null
  }

  return (
    <div style={{
      paddingBottom: 0,
      paddingTop: 30
    }}>
      <div className="margin_bottom_50">
        <div className="d-flex margin_bottom_20 margin_top_30 margin_right_20 padding_125rem_lr_mobile">
          <div className="futura_hv_bt_24_26_white ">{title}</div>
          <div className=""
            style={{
              paddingTop: 10,
              paddingLeft: 10,
              font: "12px/12px Futura Lt BT",
            }}
          >{friendRequestsCount}</div>
          {/* <div className="futura_hv_bt_16_19_green my_home_faverites_top_btn_container text-right">All</div> */}
        </div>

        <Slider {...settings} className={left_element}>
          {friendRequests && friendRequests?.length > 0 && friendRequests?.map((influencer, idx) => {
            return (
              <div key={idx}>

                <div>
                  <div className="text-center mb-2" onClick={() => goToBlackPage(influencer?.receivedFriendHandle)}
                    style={{ cursor: "pointer" }}>
                    <Image className="rounded-circle d-inline img_fit_cover"
                      src={influencer?.receivedFriendAvatar?.fullUrl}
                      style={{height:100,width:100}}
                      alt="avatar_inflencer" />
                  </div>
                  <div className="text-center" style={{ marginTop: "10px", marginBottom: "10px" }}>
                    <div onClick={() => goToBlackPage(influencer?.receivedFriendHandle)}
                      style={{ cursor: "pointer" }} className="futura_md_bt_18_20_white text-center">{influencer.receivedFriendFirstName !== "" && influencer?.receivedFriendLastName !== "" ? influencer.receivedFriendFirstName?.charAt().toUpperCase() + influencer.receivedFriendFirstName?.substring(1) + ' ' + influencer?.receivedFriendLastName?.charAt().toUpperCase() + '.' : ''}
                      {<div className="text-center futura_md_bt_14_16_green box_1">
                        {influencer?.receivedFriendHandle !== ""  ? "@"+influencer?.receivedFriendHandle: <>&#8203;</>}
                        </div>}
                    </div>

                  </div>

                  <div style={{display:'flex',
                marginLeft:"10%",
                marginTop:15}} >

                    <div style={{
                       backgroundColor: "var(--mainGreen)",
                      color: "white",
                      font: "14px/16px Futura Hv BT",
                      borderRadius: 25,
                      marginRight: 5,
                      marginBottom: 5,
                      height: 28,
                      width: "fit-content",
                      padding: "5px 14px",
                      cursor: 'pointer'
                    }}
                    onClick={()=>{
                      acceptRequest(true,influencer?.receivedFriendUID)
                    }}>
                      Accept
                    </div>
                    <div style={{
                      backgroundColor: "#E8E8E8",
                      color: "#191919CC",
                      font: "14px/16px Futura Hv BT",
                      borderRadius: 25,
                      marginRight: 5,
                      marginBottom: 5,
                      height: 28,
                      width: "fit-content",
                      padding: "5px 14px",
                      cursor: 'pointer'
                    }}
                    onClick={()=>{
                      acceptRequest(false,influencer?.receivedFriendUID)
                    }}>
                      Reject
                    </div>
                  </div>
                </div>


              </div>
            )
          })}
        </Slider>


      </div>
    </div>
  )
}


const mapStateToProps = (state) => {
  return {
    friends: state.Member.socialMember.friends,
    screenSize: state.Setting.htmlBody.bodyClientWidth,
    uuid: state.Member.authModal.uuid,
  }
};

const mapDispatchToProps = {
  modifyFollowing: Actions.modifyFollowing,
}

const MuvTribeFriendRequests = connect(mapStateToProps, mapDispatchToProps)(MuvTribeFriendRequests_)
export default MuvTribeFriendRequests
