import {
    DELAY_ONE_SECOND,
    IMAGE_CLOUD_MAX,
} from "../Common/RegExValidate"
import { priceline_image_boxing, loadingImage } from '../Common/RegExValidate'

import Rating from '@material-ui/lab/Rating'
import ReviewsPricelineFeature from "../Common/Establishment/ReviewsPricelineFeature"
import EstablishmentMap from './EstablishmentMap'
import HotelImageSlider from './HotelImageSlider'
import Image from "../Common/Image"
import { PhotoVideoIcon } from '../Common/SVGBank'
import React from 'react'
import { SRLWrapper } from "simple-react-lightbox"
import SimpleReactLightbox from "simple-react-lightbox" // Import Simple React Lightbox
import { connect } from 'react-redux'

// import { CameraRegulerIcon } from '../Common/SVGBank'
import { Star } from '../Common/MemberActions/Star';





const TopBarSlider_ = ({ sliderImages, establishment, screenSize, isMobile, switchFeatureContainer, activeId, timelineStatus,
    review_rating_desc, reviewsAverage, setMenu, paddingLeft, paddingRight, sliderIndex }) => {

    const slideIndex = sliderIndex!==undefined ? sliderIndex : 0
    const [imgIndex, setImgIndex] = React.useState(0);
    const [, setState] = React.useState({});
    const [open, setOpen] = React.useState(false)

    React.useEffect(() => {
        setOpen(false)
        setTimeout(
            () => setOpen(true)
            , DELAY_ONE_SECOND)
    }, [activeId])

    React.useEffect(() => {
        setImgIndex(-1)
        setTimeout(
            () => setImgIndex(sliderIndex!==undefined ? sliderIndex : 0)
            , 100)
    }, [sliderIndex])

    const generalInfo = establishment.generalInfo

    const establishmentStarRating = establishment.generalInfo.establishmentStarRating

    const imageWidth = screenSize-(screenSize*paddingLeft)-(screenSize*paddingRight)
    
    const hotelName = establishment.generalInfo !== undefined && establishment.generalInfo !== null ? establishment.generalInfo.name : ""
    let topImageHeight = isMobile ? 180 : 450
    let topImageHeightMin = topImageHeight + 165
    let containerH = 500
    let fontDestinationName = isMobile ? "14" : "24"
    let fontIcon = isMobile ? "10" : "16"
    let widthIcon = isMobile ? "110px" : "150px"
    let heightIcon = isMobile ? "30px" : "45px"
    let fontHeightIcon = isMobile ? "17px" : "30px"
    // let fontIconCameraReguler = isMobile ? "15" : "20"
    if (topImageHeight !== undefined) {
        containerH = topImageHeight + "px"
    }
    
    const destinationImage = sliderImages !== undefined && sliderImages.length > 0 && sliderImages[imgIndex] !== undefined 
            ? priceline_image_boxing(sliderImages[imgIndex], imageWidth, topImageHeight) 
            : loadingImage.src + "?func=crop&w=" + Math.floor(imageWidth) + "&h=" + Math.floor(topImageHeight)
    const destinationImage2 = sliderImages !== undefined && sliderImages.length > 1 && sliderImages[imgIndex+1] !== undefined 
            ? priceline_image_boxing(sliderImages[imgIndex+1], imageWidth, topImageHeight) 
            : loadingImage.src + "?func=crop&w=" + Math.floor(imageWidth) + "&h=" + Math.floor(topImageHeight)
    const display_letters = screenSize > 1024 ? 50 : 20
    const options = open && sliderImages !== undefined && sliderImages.length > 0 ? {
        overlayColor: "rgb(25, 25, 25)",
        captionColor: "rgba(25, 188, 155, 1)",
        captionFontFamily: "Futura Md BT",
        captionFontSize: "22px",
        captionFontWeight: "300",
        captionFontStyle: "capitalize",
        buttonsBackgroundColor: "#191919",
        buttonsIconColor: "rgba(25, 188, 155, 1)",
        autoplaySpeed: 1500,
        transitionSpeed: 900,

        buttons: {
            showFullscreenButton: false,
        },
    } : {}
    let fontIconPhotoVideo = isMobile ? "18" : "25"

    const address = generalInfo !== undefined && generalInfo.address !== undefined && generalInfo.address !== null && generalInfo.address !== "" ? generalInfo.address + ", " : ""
    const country = generalInfo !== undefined && generalInfo.countryName !== undefined && generalInfo.countryName !== null && generalInfo.countryName !== "" ? generalInfo.countryName + " " : ""
    const postalCode = generalInfo !== undefined && generalInfo.postalCode !== undefined && generalInfo.postalCode !== null && generalInfo.postalCode !== "" ? generalInfo.postalCode : ""
    const city = generalInfo !== undefined && generalInfo.cityName !== undefined && generalInfo.cityName !== null && generalInfo.cityName !== "" ? generalInfo.cityName + ", " : ""
    const state = generalInfo !== undefined && generalInfo.stateName !== undefined && generalInfo.stateName !== null && generalInfo.stateName !== "" ? generalInfo.stateName + ", " : ""
    
    const locationURL =  generalInfo !== undefined && generalInfo.address !== undefined && generalInfo.address !== null && generalInfo.address !== ""
                         && generalInfo.countryName !== undefined && generalInfo.countryName !== null && generalInfo.countryName !== ""
                    ? (address + city + state + country + postalCode).replace(" ","+"):""

    //console.log({destinationImage,locationURL,establishment,generalInfo,address});

    return (
        <div className="position-relative z_index_2" style={{backgroundColor: "black", minHeight:{topImageHeightMin}}}>
            {imgIndex !== -1 && <Image fallbackSrc={destinationImage2}
                src={destinationImage} alt="destination image" width="100%" height={topImageHeight} 
                style={{opacity: '0.8', width: "100%", objectFit: "cover", objectPosition: "center", 
                        height: Math.floor(topImageHeight), width: Math.floor(imageWidth), 
                        //paddingLeft: "15%", paddingRight: "15%"
                }}  />}
            {imgIndex == -1 && <Image fallbackSrc={destinationImage2}
                src={destinationImage} alt="destination image" width="100%" height={topImageHeight} 
                style={{opacity: '0.8', width: "100%", objectFit: "cover", objectPosition: "center", 
                        height: Math.floor(topImageHeight), width: Math.floor(imageWidth), 
                        //paddingLeft: "15%", paddingRight: "15%"
                }}  />}
            {sliderImages === undefined || sliderImages.length <= 0 || sliderImages[imgIndex] === undefined && 
                <div className="mask_block" style={{ height: containerH }}>
                </div>}

            <div style={{ position: "absolute", top: "60px", right: "84px",
                    width:"29px", height:"28px" }}>
                        <div> </div>
                              { establishment.generalInfo && establishment.generalInfo.destinationID?
                                    <Star
                                    unitID={establishment.generalInfo.destinationID}
                                    feature={switchFeatureContainer}
                                    fill={"#FFFFFF"} />:null
                              }
            </div>
         
            {!isMobile && <div className="z_index_3"
                style={{
                    top: "25px",
                    right: "25px",
                    position: "absolute",
                    width: "325px",
                    height: "400px",
                    backgroundColor: "rgb(255, 255, 255)",
                    padding: "20px",
                }}>
                        
                <div className="box_3_wrap text-left"    //futura_hv_bt_24_28
                    style={{
                        font: fontDestinationName + "px/28px Futura Hv BT", 
                        //color: "#FFFFFF",
                        //textShadow: '0px 6px 6px #191919'
                    }} >{hotelName !== undefined && hotelName}
                </div>
                <div className="box_3_wrap text-left"    //futura_hv_bt_24_28
                    style={{
                        font: fontDestinationName + "px/28px Futura Hv BT", 
                        marginTop: 5,        
                        //color: "#FFFFFF",
                        //paddingLeft: "8.5%",
                        //textShadow: '0px 6px 6px #191919'
                    }} >
                    {establishmentStarRating !== undefined && <Rating name="reviews_rating" style={{color: "var(--mainGreen)", opacity: "1", lineHeight:"24px",float:"left",marginRight:5,}} precision={0.5} max={5} value={parseFloat(establishmentStarRating)} disabled={true} />}
                    <div className="futura_md_bt_11_14_black margin_bottom_5 margin_left_5" style={{color:"#C7C7C7"}}>({establishmentStarRating})</div>
                    <div style={{marginTop:"1.0rem"}}>
                        {generalInfo !== undefined && generalInfo.reviewsAverage && <div style={{display: "inline-block"}}>
                            <span style={{color: "var(--mainGreen)", font: "32px/36px Futura Md BT", marginTop:"1.5rem"}}>{generalInfo &&generalInfo.reviewsAverage  ? generalInfo.reviewsAverage.toFixed(1): ''}</span><span>/10</span></div>}
                            {review_rating_desc !== undefined && <span style={{color: "var(--mainGreen)", fontFamily: "Futura Md BT", marginLeft:"1rem"}}>{review_rating_desc}</span>}
                    </div>
                    
                    {establishment.reviewsGoogleAndYelp !== undefined && establishment.reviewsGoogleAndYelp.priceline !== undefined && 
                        establishment.reviewsGoogleAndYelp.priceline!==null && establishment.reviewsGoogleAndYelp.priceline[0].priceline_review_1_text!=="" && 
                        <div>
                            <ReviewsPricelineFeature setMenu={setMenu} reviewsPriceline={establishment.reviewsGoogleAndYelp.priceline} />
                        </div>}

                    <div style={{ flexGrow: "1", height: 150, position: "absolute", height: 94, width: 268, bottom: 20 }}>
                        {window.google !== undefined && generalInfo !== undefined && generalInfo.countryName!=="" &&
                            establishment.generalInfo.latitude !== "" && 
                            establishment.generalInfo.latitude !== null &&
                            establishment.generalInfo.longitude !== null &&
                            establishment.generalInfo.longitude !== "" &&
                            <div onClick={()=>window.open('https://www.google.com/maps/place/'+locationURL+'/@'+establishment.generalInfo.latitude+','+establishment.generalInfo.longitude)}>
                                <EstablishmentMap 
                                    zoom={13}
                                    height={94}
                                    lat={establishment.generalInfo.latitude} 
                                    lng={establishment.generalInfo.longitude} />
                            </div>}
                    </div>

                </div>
            </div>}
            
            {sliderImages !== undefined && sliderImages.length > 0 && sliderImages[0] !== undefined
                  && sliderImages[0].imageName !== undefined &&
                  <HotelImageSlider timelineStatus={timelineStatus} />
            }

        </div >
    )

}


const mapStateToProps = state => {
    return {
        screenSize: Math.min(state.Setting.htmlBody.bodyClientWidth, document.body.clientWidth),
        isMobile: state.Setting.is_mobile,
        activeId: state.FormBank.HotelSearch.establishment.activeId,
        sliderImages: state.FormBank.HotelSearch.establishment.sliderImages,
        //generalInfo: state.FormBank.HotelSearch.establishment.generalInfo,
        reviewsAverage: state.FormBank.HotelSearch.establishment.reviewsAverage,
        review_rating_desc: state.FormBank.HotelSearch.establishment.review_rating_desc,
        switchFeatureContainer: state.FormBank.FeatureFormat,
        
        establishment: state.FormBank.HotelSearch.establishment,
        sliderIndex: state.FormBank.HotelSearch.establishment.sliderIndex,
        
    }
}

const mapDispatchToProps = {
}

const TopBarSlider = connect(mapStateToProps, mapDispatchToProps)(TopBarSlider_)
export default TopBarSlider
