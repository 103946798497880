import * as Actions from '../../../../redux/actions'

import React from 'react'
import { connect } from 'react-redux'
import { list_container_mode } from '../../Common/RegExValidate'
import { member_area_router } from '../../Common/RegExValidate'
import {
    useHistory
} from "react-router-dom"

const LinkDestination_ = ({ from = "", link_style, setSwitchContainer, setTripDisplayMode, text, setPageYOffset, trip_id = "" }) => {
    const history = useHistory()
    const goesToBookingPage = () => {
        trip_id === "" ? history.push("/booking") : history.push("/booking-" + trip_id)
        setPageYOffset(0)
        setTripDisplayMode(list_container_mode.timeline)

        setSwitchContainer(member_area_router.booking)
    }
    return (
        <div style={link_style} onClick={() => goesToBookingPage()}>
            {text}
        </div>
    )
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = {
    setPageYOffset: Actions.setPageYOffset,
    setTripDisplayMode: Actions.setTripDisplayMode,
    setSwitchContainer: Actions.setSwitchContainer,
}

const LinkDestination = connect(mapStateToProps, mapDispatchToProps)(LinkDestination_)
export default LinkDestination
