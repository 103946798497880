import * as Actions from '../../../redux/actions'

import React, { Component } from 'react'

import ClipSubtype from "./ClipSubtype"
import { connect } from 'react-redux'

export class Clips extends Component {
    render() {
        const { selectedSubtypes, cleanSelectedSubTypeDestination, lazyLoading, switchFeatureContainer} = this.props
        return (
            <div className="row margin_left_30 m-0" style={{ flexGrow: "1", paddingTop: "15px" }}>
                {
                    selectedSubtypes !== undefined
                    && selectedSubtypes.length > 0
                    && selectedSubtypes.map((subtype, subtype_idx) => { return <ClipSubtype key={subtype_idx} element={subtype} /> })
                }
                {
                    selectedSubtypes !== undefined
                    && selectedSubtypes.length > 0
                    && <div onClick={() => cleanSelectedSubTypeDestination(lazyLoading)}
                        style={{
                            color: switchFeatureContainer.color,
                            cursor: "pointer",
                            font: "14px/18px Futura Md BT",
                            paddingTop: "10px",
                            paddingLeft: "10px",
                        }}>clear all</div>
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    selectedSubtypes: state.Destination.destination.selectedSubtypes,
    lazyLoading: state.Destination.destination.lazyLoading,
    
    switchFeatureContainer: state.FormBank.FeatureFormat,
})

const mapDispatchToProps = {
    cleanSelectedSubTypeDestination: Actions.cleanSelectedSubTypeDestination,
}

export default connect(mapStateToProps, mapDispatchToProps)(Clips)
