import * as Actions from '../../../../redux/actions'

import { EllipsisVIcon, ListULIcon, TimesIcon, PlusSignIcon, HeartIcon } from "../SVGBank"

import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import DeleteFromListDialog from "../DeleteFromListDialog"
import Grow from '@material-ui/core/Grow'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import React from 'react'
import { connect } from 'react-redux'

import { features_router, list_container_mode, list_memeber_action_mode, member_area_router, URL_REPLACE } from '../RegExValidate'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from "react-router-dom"

/*  inject css to menu component */
const useStyles = makeStyles(theme => ({
    root: {
        display: 'inline-block',
    },
    positionTimeline: style_props => ({
        zIndex: "33",
        top: "-200px !important",
        left: "5px !important",
    }),
    position: style_props => ({
        zIndex: "33",
        top: "-30px !important",
        left: "-170px !important",
    }),
    backgroundSelection: {
        '&:hover': {
            backgroundColor: "var(--mainGreen)"
        },
        minHeight: "10px"
    },
    paper: {
        marginRight: theme.spacing(2),
    },

    hr: {
        width: "65%",
        height: "1px",
        display: "block",
        position: "relative",
        marginRight: "10%",
        marginTop: "5px",
        marginLeft: "25%",
        marginBottom: "5px",
        border: "1px dashed rgba(0,0,0,.1)",
    },
    timesDiv: {
        textAlign: "right",
        paddingRight: "10px",
    },
    hidden: {
        zIndex: -1,
        top: "-5000px !important",
    }

}))
/* menu list composition function component 

param  trip object

*/
const MemberActionPlaceEllipsis_ = ({ unitID, feature, activeTrip, userAction,userActionId, tripMode, uuid, switch_container, toggle_ellipsis_close_disable, 
    screenSize, setSwitchContainer, setTripDisplayMode, addingPlus, addingHeart, lang, destinationId, destination, addActivityToTripDestinationInTrip,
    activeTripDestinationId, activeTripDay}) => {
    const style_props = { screenSize: Math.min(screenSize, document.body.clientWidth) }
    const classes = useStyles(style_props)
    const [open, setOpen] = React.useState(false)
    const history = useHistory()

    const [openTripAction, setOpenTripAction] = React.useState(false)
    const [openTripInformation, setOpenTripInformation] = React.useState(true)

    const handleEllipsis = (event) => {
        event.preventDefault()
        setOpen(prevOpen => !prevOpen)
    }
    const anchorRef = React.useRef(null)
    const handleClose = event => {
        if (!toggle_ellipsis_close_disable) {
            if (anchorRef.current && anchorRef.current.contains(event.target)) {
                return
            }
            setOpen(false)
        }
    }

    const handleTripInformation = (from) => {
        setTripDisplayMode(from)
        setOpen(false)
    }
    const handlePrintTripAction = () => {
        window.open(`http://dev.muvtravel.com/trip/schedule_print/${activeTrip.tripId}`)
    }

    const tripNoDate = activeTrip.tripNoDate
    
    const activeTripId = activeTrip.tripId
    const activeDayInTripDestination = activeTripDay.dayInTripDestination

    const activitiesInDestinationDay = activeTrip.activities.filter(a => a.activityDay === activeTripDay.dayInTrip && a.tripDestinationId === activeTripDay.tripDestinationId)
    const lengthActivitiesInDestinationDay = activitiesInDestinationDay !== undefined ? activitiesInDestinationDay.length : 0
    const activitiesInDestinationDayEstablishmentIds = Array.from(activitiesInDestinationDay.map((a) => a.activityEstabID))
    const last_establishmentId = activitiesInDestinationDayEstablishmentIds[lengthActivitiesInDestinationDay - 1] !== undefined ? activitiesInDestinationDayEstablishmentIds[lengthActivitiesInDestinationDay - 1] : ""

    const activeTripDestinationIds = Array.from(new Set(activeTrip.tripDestinations.map(d => d.id)))
    const tripDuration = activeTrip.tripDuration

    const handleAddActivityToTripDestinationInTrip = (lang, uuid, activeTripDestinationId, 
        activeDayInTripDestination, unitID, activeTripId, 
         last_establishmentId, tripDuration, destinationId, activeTripDestinationIds) => {
        addActivityToTripDestinationInTrip(lang, uuid, activeTripDestinationId, 
                                                activeDayInTripDestination, unitID, activeTripId, 
                                                last_establishmentId, tripDuration, destinationId, activeTripDestinationIds)

        const url = "/destination/" + destination?.partialUri +  "/" + 1 + "/" + destination.destinationId;
       
        history.push(url.toLowerCase())
        setSwitchContainer(member_area_router.destination)
    }

    const handleAddingPlus = (uuid, unitID, feature) => {
        addingPlus(uuid, unitID, feature)
        setSwitchContainer(member_area_router.bucketList)

        var checkExist = setInterval(function() {
            let element_header = document.getElementById("plus_Places_" + destinationId)
            let element_target = document.getElementById("plus_Places_" + destinationId + "_element")

            if (element_header && element_target) {
                window.scrollTo({top: element_header.getBoundingClientRect().top - 150, behavior: "smooth"})
                clearInterval(checkExist);
            }
        }, 100);
    }

    const handleAddingHeart = (uuid, unitID, feature) => {
        addingHeart(uuid, unitID, feature)
        setSwitchContainer("favorites")

        var checkExist = setInterval(function() {
            let element_header = document.getElementById("favorite_Places_" + destinationId)
            let element_target = document.getElementById("favorite_Places_" + destinationId + "_element")

            if (element_header && element_target) {
                window.scrollTo({top: element_header.getBoundingClientRect().top - 150, behavior: "smooth"})
                clearInterval(checkExist);
            }
        }, 100);
    }

    return (
        <React.Fragment>

            <div className={classes.root}>
                <div
                    ref={anchorRef}
                    style={{ cursor: "pointer", }}
                    onClick={e => handleEllipsis(e)}>
                    <EllipsisVIcon height={'25'} fill={"#FFFFFF"} />
                </div>
                <ClickAwayListener onClickAway={handleClose}>
                    <Popper
                        open={open}
                        anchorEl={anchorRef.current}
                        role={undefined}
                        className={toggle_ellipsis_close_disable ? classes.hidden : ((tripMode === list_container_mode.timeline || tripMode === list_container_mode.map) ? classes.positionTimeline : classes.position)}
                        transition
                        disablePortal
                        placement={"bottom-start"}
                        modifiers={{
                            flip: {
                                enabled: false,
                            },
                        }}
                    >
                        {({ TransitionProps, placement }) => (
                            <Grow
                                {...TransitionProps}
                                style={{zIndex: "5", transformOrigin: placement === 'bottom' ? 'right top' : 'right top' }}
                            >
                                <Paper>
                                    <MenuList
                                        autoFocusItem={false}
                                        disableListWrap={true}
                                        dense={false}
                                        id="trip_ellipsis_timeline" >
                                        <div className={classes.timesDiv} onClick={handleClose}>{screenSize > 1024 ? <TimesIcon width={20} height={20} /> : <TimesIcon width={15} height={15} />}</div>

                                        {activeTrip && activeTrip.tripId !== "" && feature.featureId !== features_router.bucket_list.featureId && <MenuItem className={classes.backgroundSelection} 
                                            onClick={() => handleAddActivityToTripDestinationInTrip(lang, uuid, activeTripDestinationId, 
                                                activeDayInTripDestination, unitID, activeTripId, 
                                                last_establishmentId, tripDuration, destinationId, activeTripDestinationIds)} >
                                            <ListULIcon />{list_memeber_action_mode.addToTrip}
                                        </MenuItem>}
                                        <MenuItem className={classes.backgroundSelection} onClick={() => handleAddingPlus(uuid, unitID, feature)} ><PlusSignIcon width={20} height={20} fill={"#707070"} style={{ marginRight: "10px", }}/>{list_memeber_action_mode.addToBucketList}</MenuItem>
                                        <MenuItem className={classes.backgroundSelection} onClick={() => handleAddingHeart(uuid, unitID, feature)} ><HeartIcon width={20} height={20} fill={"#707070"} style={{ marginRight: "10px", }}/>{list_memeber_action_mode.addToFavorites}</MenuItem>

                                        <div className={classes.hr}></div>
                                        <MenuItem className={classes.backgroundSelection} ><DeleteFromListDialog unitID={unitID} feature={feature} setOpenTripEllipsis={setOpen} from={"Remove from List"} userAction={userAction}/></MenuItem>
                                    </MenuList>
                                </Paper>
                            </Grow>
                        )}
                    </Popper>

                </ClickAwayListener>
            </div>
        </React.Fragment >
    )
}


const mapStateToProps = (state) => ({
    screenSize: state.Setting.htmlBody.bodyClientWidth,
    is_mobile: state.Setting.is_mobile,
    app_menu_height: state.Setting.appMenu.app_menu_height,
    logo_height: state.Setting.appMenu.logo.height,

    toggle_ellipsis_close_disable: state.Member.memberArea.toggle_ellipsis_close_disable,

    switch_container: state.Member.switch_container,
    tripMode: state.Member.tripMode,
    uuid: state.Member.authModal.uuid,

    lang: state.Setting.lang,

    activeTrip: state.FormBank.TimelineWizard.activeTrip,
    activeTripDestinationId: state.FormBank.TimelineWizard.activeTripDestinationId,
    activeTripDay: state.FormBank.TimelineWizard.activeDay,
})

const mapDispatchToProps = {
    setSwitchContainer: Actions.setSwitchContainer,
    setTripDisplayMode: Actions.setTripDisplayMode,
    addingPlus: Actions.addingPlus,
    addingHeart: Actions.addingHeart,
    addActivityToTripDestinationInTrip: Actions.addActivityToTripDestinationInTrip,
}


const MemberActionPlaceEllipsis = connect(mapStateToProps, mapDispatchToProps)(MemberActionPlaceEllipsis_)
export default MemberActionPlaceEllipsis