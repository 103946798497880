import { Grid } from "@material-ui/core"
import moment from "moment"
import React from 'react'
import { DurationLayover, secondsToHms } from "../../../../utility/utility"
import { convertObjArray } from "./FlightManagementList"


export const FlightPaymentDetails = ({ customer,price }) => {
   const total = price?.display_currency + " "+ price?.display_total_fare;
   const name =    customer?.name
   const email =    customer?.email
   const phone =    customer?.phone_number
   const address =  customer?.address
   const postal =    customer?.postal_code
   const city =    customer?.city
   const country =    customer?.country
   const province =    customer?.state
   return (
      <Grid item style={{ background:"#E8E8E8", marginBottom: 15, width: '100%', padding: "40px 50px" }}>
         <div
            style={{ color: '#FF0000', font: "26px/26px Futura Md BT", marginBottom: 40 }}
         >
            Your Payment:
            </div>

         <div style={{paddingLeft: 0}}>

         {total && <div
            style={{ color: '#FF0000', font: "22px/22px Futura Md BT", marginBottom: 30 }}
         >
            {total}
            </div>}


            {name && <div
            style={{color:'#000000',font: "20px/20px Futura Lt BT",marginBottom:5}}
            >
               <span style={{}}> Name:  </span>

               <span> {name} </span>
            </div>}

            {email && <div
            style={{color:'#000000',font: "20px/20px Futura Lt BT",marginBottom:5}}
            >
               <span style={{}}> Email:  </span>

               <span> {email} </span>
            </div>}

            {phone && <div
            style={{color:'#000000',font: "20px/20px Futura Lt BT",marginBottom:5}}
            >
               <span style={{}}> Phone:  </span>

               <span> {phone} </span>
            </div>}

            {address && <div
            style={{color:'#000000',font: "20px/20px Futura Lt BT",marginBottom:5}}
            >
               <span style={{}}> Address:  </span>

               <span> {address} </span>
            </div>}

            {postal &&<div
            style={{color:'#000000',font: "20px/20px Futura Lt BT",marginBottom:5}}
            >
               <span style={{}}> Postal Code:  </span>

               <span> {postal} </span>
            </div>}

            {city && <div
            style={{color:'#000000',font: "20px/20px Futura Lt BT",marginBottom:5}}
            >
               <span style={{}}> City:  </span>

               <span> {city} </span>
            </div>}

            {country &&<div
            style={{color:'#000000',font: "20px/20px Futura Lt BT",marginBottom:5}}
            >
               <span style={{}}> Country:  </span>

               <span> {country} </span>
            </div>}

            {province && <div
            style={{color:'#000000',font: "20px/20px Futura Lt BT",marginBottom:5}}
            >
               <span style={{}}> Province:  </span>

               <span> {province} </span>
            </div>}



            </div>


      </Grid>
   )
}