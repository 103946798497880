import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import React from 'react'
import { Element, Link } from 'react-scroll'

export default function ReviewsDescription({ setMenu, description, boxDescription, fontDescription, colorDescription, height, width }) {
    const [open, setOpen] = React.useState(false)

    const handleToggle = () => {
        setOpen(prevOpen => !prevOpen)

    }
    const handleClose = event => {
        setOpen(false)
    }
    return (
        
        <Link to="reviews" spy={true} smooth={true} duration={1000} offset={-125} style={{font: "14px/16px Futura Hv BT",color: "var(--mainGreen)", cursor: "pointer"}} >
        <div className={boxDescription} style={{
                cursor: 'pointer',
                font: fontDescription,
                color: colorDescription,
                width: width,
            }} onClick={() => {}}>{description}</div>
        </Link>
    )
}