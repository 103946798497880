import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import React from 'react'

export default function ElementTitle({ title, style, box = "box_2 flex_1" }) {
    const [open, setOpen] = React.useState(false)

    const handleToggle = () => {
        setOpen(prevOpen => !prevOpen);

    };
    const handleClose = event => {
        setOpen(false);
    };
    return (
        <ClickAwayListener onClickAway={handleClose}>
            <div className={open ? "flex_1" : box} onClick={() => handleToggle()}>
                <div style={style}>
                    {title}
                </div>
            </div>
        </ClickAwayListener>
    )
}