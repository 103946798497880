import React from 'react'
import { connect } from 'react-redux'

const About_ = ({ bucketlist }) => {
    const { generalInfo } = bucketlist
    return (
        <div>
            <div style={{ font: "30px/36px Futura Hv BT", color: "#191919", marginBottom: "30px", }}>About</div>
            <div>
                <div style={{      font:  "19px / 19px Futura Lt BT",
                    color: "var(--mainGrey)",
                    lineHeight: 1.3,
                    textAlign: "justify", width: "70%"}}>
                    {generalInfo !== null && generalInfo.bucketListDescription}
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({

    bucketlist: state.Destination.bucketlist,
    isMobile: state.Setting.is_mobile,
    lang: state.Setting.lang,
    uuid: state.Member.authModal.uuid,

    switchFeatureContainer: state.FormBank.FeatureFormat,
})

const mapDispatchToProps = {

}

const About = connect(mapStateToProps, mapDispatchToProps)(About_)
export default About
