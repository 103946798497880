import * as Actions from '../../../../redux/actions'

import CircularProgress from '@material-ui/core/CircularProgress'
import { DeleteIcon, } from '../SVGBank'
import React from 'react'
import { connect } from 'react-redux'

const DeleteActivityDay_ = ({ activeTrip, uuid_auth, deleteDayFromTripDestinationInTrip, lang, destination, dayInTripDestination, fetchingStatus, activeDay }) => {
    const tripId = activeTrip.tripId
    const tripDuration = activeTrip.tripDuration
    const uuid = uuid_auth !== "" ? uuid_auth : "TMPUUID"
    const [fetching, setFetching] = React.useState(fetchingStatus)
    const deleteActivityDayHandler = (lang, uuid, destination, tripId, tripDuration, dayInTripDestination, activeDay) => {
        setFetching(true)
        deleteDayFromTripDestinationInTrip(lang, uuid, destination, tripId, tripDuration, dayInTripDestination, activeDay)
    }
    React.useEffect(() => {
        setFetching(false)
    }, [activeTrip.activities])
    return (
        <div style={{ position: "absolute", top: "5px", right: "10px", }}>
            {!fetching ?
                <div style={{ cursor: "pointer", textAlign: "right", }} className="addActivity"
                    onClick={() => deleteActivityDayHandler(lang, uuid, destination, tripId, tripDuration, dayInTripDestination, activeDay)}
                >
                    <DeleteIcon width={14} height={16} style={{ marginRight: "0px" }} />
                </div>
                :
                <CircularProgress
                    disableShrink
                    size={17}
                    style={{
                        color: "#6A9EEC",
                        textAlign: "right",
                    }} />
            }
        </div>
    )
}

const mapStateToProps = (state) => ({

    activeTrip: state.FormBank.TimelineWizard.activeTrip,
    activeDay: state.FormBank.TimelineWizard.activeDay,
    lang: state.Setting.lang,
    uuid_auth: state.Member.authModal.uuid,
})

const mapDispatchToProps = {
    deleteDayFromTripDestinationInTrip: Actions.deleteDayFromTripDestinationInTrip,

}

const DeleteActivityDay = connect(mapStateToProps, mapDispatchToProps)(DeleteActivityDay_)
export default DeleteActivityDay
