import * as Actions from '../../../../redux/actions'

import React from 'react'
import { URL_REPLACE } from "../../Common/RegExValidate"
import { connect } from 'react-redux'
import { member_area_router } from '../../Common/RegExValidate'
import { useHistory } from "react-router-dom"

const TripName_ = ({ tripId, tripName, divClass = "", titleClass, setSwitchContainer, switch_container, titleStyle, lang }) => {
    const history = useHistory()
    const handleClick = () => {
        setSwitchContainer(member_area_router.publicTrip)
        const url = "/trip/" + tripName.replace(URL_REPLACE, "-") + "/" + tripId;
        history.push(url.toLowerCase())
    }
    return (
        <div style={{ display: "inline-block" }} className={divClass}>
            <div style={titleStyle} className={titleClass} onClick={() => handleClick()}>
                {tripName}
            </div>
        </div >
    )
}

const mapStateToProps = (state) => ({
    lang: state.Setting.lang,
    switch_container: state.Member.switch_container,
})

const mapDispatchToProps = {
    setSwitchContainer: Actions.setSwitchContainer,
}

const TripName = connect(mapStateToProps, mapDispatchToProps)(TripName_)
export default TripName