import React from 'react'
import './flights.css';
import { FlightsBarsIcon, TimesIcon, TimesMapIcon } from '../Common/SVGBank';
import { FlightsSlider } from './flightStyles';
import { FlightCheckBox } from './flightsComponents';
import { useDispatch, useSelector } from 'react-redux';
import { DecimalToTime, toHHMMSS } from '../../../utility/utility';
import { filterByAirline, clearFlightFilters, filterByPrices, filterByDuration, filterByTime } from './services/Flights.slice';




export const FlightsFilter = ({filterStops,stops,clearStops}) => {
    const [showMenu, setMenu] = React.useState(false);
    const maxPrice = useSelector(state => state.Flights.maxPrice) || 0;
    const minPrice = useSelector(state => state.Flights.minPrice) || 0;
    const maxDuration = useSelector(state => state.Flights.maxDurationSeconds) || 0;
    const minDuration = useSelector(state => state.Flights.minDurationSeconds) || 0;
    const currency = useSelector(state => state.Flights.currency)
    const airlines = useSelector(state => state.Flights.airlines);
    const searchData = useSelector(state => state.Flights.searchData);
    const [flightType, setFlight] = React.useState(1);
    const [airlineLimit, setAirlineLimit] = React.useState(4);
    const [pricesDefault, setPricesDefault] = React.useState();
    const [durationDefault, setDurationDefault] = React.useState();
    const [clearFilters, setClearFilters] = React.useState(false);

    React.useEffect(() => {
        setPricesDefault([minPrice, maxPrice])
        setDurationDefault([minDuration, maxDuration])

        return () => {
        }
    }, [maxDuration, maxPrice, minDuration, minPrice])

    const dispatch = useDispatch();
    const handleClick = () => {
        setMenu(!showMenu);
    };
   

    const filterAirlines = (index) => {
        dispatch(filterByAirline(index))
    }

    const selectFlightType = (val) => {
        setFlight(val)
    };


    const toggleAirlineLimit = () => {
        const val = airlineLimit === 4 ? airlines.length : 4
        setAirlineLimit(val)
    }

    const clearAllFilters = async () => {
        setClearFilters(true);
        clearStops()
        setPricesDefault([minPrice, maxPrice])
        setDurationDefault([minDuration, maxDuration])
        const doneClearing = await dispatch(clearFlightFilters())
        if (doneClearing) {
            setClearFilters(false);
        }
    }

    const pricesToggle = (event, value) => {
        setPricesDefault(value)
        dispatch(filterByPrices(value))
    }

    const durationToggle = (event, value) => {
        setDurationDefault(value)
        dispatch(filterByDuration(value))
    }


    return (
        <div>
            <div onClick={handleClick} style={{ float: 'left', cursor: 'pointer', paddingTop: 22, paddingRight: 40 }}> <FlightsBarsIcon /> </div>


            {showMenu &&
                <div className="flightsFilter" >

                    <div >
                        <button style={{ font: '18px/18px Futura Hv BT' }} className="topFlightBtn"> Filters</button>
                        <button onClick={handleClick} style={{ float: "right", font: '14px/14px Futura Hv BT', color: '#19BC9B' }} className="topFlightBtn"> Done</button>
                    </div>

                    <div style={{ paddingTop: 30 }} >
                        <p style={{ font: '16px/16px Futura Hv BT' }}>Prices</p>

                        <FlightsSlider
                            ThumbComponent={(props) => FlightsCircleComponent(props, currency)}
                            value={pricesDefault}
                            min={minPrice}
                            max={maxPrice}
                            valueLabelDisplay="on"
                            onChange={pricesToggle}
                        />
                    </div>



                    <div style={{ paddingTop: 40 }} >

                        <p style={{ font: '16px/16px Futura Hv BT' }}>Duration</p>
                        <FlightsSlider
                            ThumbComponent={(props) => FlightsCircleComponent(props, null, true)}
                            value={durationDefault}
                            min={minDuration}
                            max={maxDuration}
                            step={100}
                            onChange={durationToggle}
                            valueLabelDisplay="off"
                        />
                    </div>

                    <div style={{ paddingTop: 30 }} >

                        <p style={{ font: '16px/16px Futura Hv BT' }}>Stops</p>

                        {stops.map((stop, index) => {
                            return (
                                <div onClick={(e) => {
                                    e.preventDefault();
                                    filterStops(index)
                                }} key={index} ><FlightCheckBox label={stop.name} checked={stop.show} /></div>
                            )
                        })}
                    </div>

                    <div style={{ paddingTop: 30 }} >
                        <p style={{ font: '16px/16px Futura Hv BT' }}>Airlines</p>

                        {airlines.slice(0, airlineLimit).map((airline, index) => {
                            return (
                                <div onClick={(e) => {
                                    e.preventDefault();
                                    filterAirlines(index)
                                }} key={index} ><FlightCheckBox label={airline.name} checked={airline.show} /></div>
                            )
                        })}


                        {airlines.length > airlineLimit && <button className="showMoreAirlines" onClick={() => toggleAirlineLimit()}> Show More  </button>}
                        {airlines.length === airlineLimit && airlineLimit !== 4 && <button className="showMoreAirlines" onClick={() => toggleAirlineLimit()}> Show Less </button>}
                    </div>

                    <div style={{ paddingTop: 30 }} >
                        <p style={{ font: '16px/16px Futura Hv BT' }}>Times</p>

                        <div className="timesBtn">
                            <button style={{
                                color: flightType === 1 ? '#FFFFFF' : '#000000', background: flightType === 1 ? '#19BC9B' : 'transparent',
                                border: flightType === 1 ? 'none' : '1px solid #DFDFDF'
                            }} onClick={() => selectFlightType(1)} > Take-off</button>
                            <button style={{
                                color: flightType === 2 ? '#FFFFFF' : '#000000', background: flightType === 2 ? '#19BC9B' : 'transparent',
                                border: flightType === 2 ? 'none' : '1px solid #DFDFDF'
                            }} onClick={() => selectFlightType(2)} > Landing</button>
                        </div>


                        {searchData.map((flight, index) => {
                            return (
                                <div key={index} style={{ paddingTop: 10 }}>
                                    {flight.departureTimes.length > 0 &&  flightType === 1 &&
                                      <TakeOffComponent flight={flight} index={index} clearFilters={clearFilters} />
                                    }

                                    {flight.arrivalTimes.length > 0  && flightType === 2 &&
                                     <LandingComponent flight={flight} index={index} clearFilters={clearFilters} />
                                     }
                                </div>
                            )
                        })}


                    </div>

                    <button onClick={() => clearAllFilters()} className="clearFlightsFilters"> <TimesMapIcon height={12} />  Clear Filters</button>
                </div>

            }
        </div>
    )
}


export const TakeOffComponent = ({ index, flight, clearFilters }) => {
    const minDeparture = Math.min(...flight.departureTimes);
    const maxDepature = Math.max(...flight.departureTimes)
    const [takeOffDefault, setTakeOffDefault] = React.useState([minDeparture, maxDepature]);
    const selectedDeparture = useSelector(state => state.Flights.selectedDepartureFlight);
    const flightMode = useSelector(state => state.Flights.flightData.flightMode);

    const dispatch = useDispatch()


    const takeOffToggle = (value) => {
        setTakeOffDefault(value)
        const data = {
            type: 'departure',
            value: value,
            index: index,
            flightMode: flightMode,

        }
        dispatch(filterByTime(data))
    }
    React.useEffect(() => {
        setTakeOffDefault([minDeparture, maxDepature])
        return () => {
        }
    }, [clearFilters])



    return (
        <div key={index}>
            <p style={{ font: '14px/14px Futura Lt BT', paddingTop: 20 }}> Take-off from  {flight.origin.code}</p>

            <FlightsSlider
                ThumbComponent={(props) => FlightsCircleComponent(props, null, null, true)}
                value={takeOffDefault}
                min={minDeparture}
                max={maxDepature}
                valueLabelDisplay="on"
                onChange={(event, value) => {
                    takeOffToggle(value)
                }}
            />
        </div>
    )
}


export const LandingComponent = ({ index, flight, clearFilters }) => {
    const minArrival = Math.min(...flight.arrivalTimes);
    const maxArrival = Math.max(...flight.arrivalTimes)
    const [landingDefault, setLandingDefault] = React.useState([minArrival, maxArrival]);
    const selectedDeparture = useSelector(state => state.Flights.selectedDepartureFlight);
    const flightMode = useSelector(state => state.Flights.flightData.flightMode);

    const dispatch = useDispatch()

    const landingToggle = (value) => {
        setLandingDefault(value)
        const data = {
            type: 'arrival',
            value: value,
            index: index,
            flightMode: flightMode,
        }
        dispatch(filterByTime(data))
    }

    React.useEffect(() => {
        setLandingDefault([minArrival, maxArrival])
        return () => {
        }
    }, [clearFilters])


    return (
        <div key={index}>
            <p style={{ font: '14px/14px Futura Lt BT', paddingTop: 20 }}> Landing at {flight.destination.code}</p>

            <FlightsSlider
                ThumbComponent={(props) => FlightsCircleComponent(props, null, null, true)}
                value={landingDefault}
                min={minArrival}
                max={maxArrival}
                valueLabelDisplay="on"
                onChange={(event, value) => {
                    landingToggle(value)
                }}

            />
        </div>
    )
}

export function FlightsCircleComponent(props, currency, isTime, isDecimal) {
    const dataIndex = props['data-index']
    const value = props['aria-valuenow'];
    const time = isTime ? toHHMMSS(value) : null;

    const decimalTime = isDecimal ? DecimalToTime(value) : null;
    return (
        <span {...props}>
            <span style={{ height: 8, width: 8, borderRadius: '50%', background: '#19BC9B', position: 'absolute' }}></span>

            <span style={{ display: 'block', color: '#7F7F7F', marginTop: 56, font: "16px/16px Futura Md BT", marginLeft: dataIndex === 0 ? 14 : 0, marginRight: dataIndex === 1 ? 22 : 0 }}>
                {currency && currency + value}
                {isTime && time}
                {isDecimal && decimalTime}
            </span>
        </span>
    );
}

