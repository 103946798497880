import * as Actions from '../../../redux/actions'

import {
    DELAY_ZERO_SECOND,
    validate,
    validate_fields,
    member_area_router,
} from "../Common/RegExValidate"

import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWindowClose } from '@fortawesome/free-solid-svg-icons'

import React from 'react';

import { SearchIcon, TimesIcon } from "../Common/SVGBank"
import { connect } from "react-redux";
import { makeStyles } from '@material-ui/core/styles'
import  ClickAwayListener  from '@material-ui/core/ClickAwayListener';
import  SearchBarHotelsAutocomplete  from './SearchBarHotelsAutocomplete';


const searchStyles = makeStyles((theme) => ({
    title: {
        flexGrow: 1,
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
    search: {
        background: "#FFFFFF 0% 0% no-repeat padding-box",
        boxShadow: "0px 3px 6px #00000029",
        border: "1px solid #B1B1B1",
        borderRadius: "4px",
        position: 'relative',
        marginTop: "15px",
        [theme.breakpoints.up('sm')]: {
            // marginLeft: theme.spacing(1),
            width: 'auto',
        },
        height: '40px',
        width: "100%",
    },
    inputRoot: {
        color: 'var(--bgBlack)',
        font: "16px/16px Futura Md BT",
    },
    inputInput: {
        padding: theme.spacing(1, 0, 1, 1),
        // vertical padding + font size from searchIcon
        paddingRight: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '20ch',
            // '&:focus': {
            //     width: '20ch',
            // },
        },
    },
}));

export const SearchBarHotels = connect((state) => {
    return {
        lang: state.Setting.lang,
        activeFeatureId: state.Feature.feature.activeId,
        destinationList: state.Destination.destination.destinationSearchList,
        searchQuery: state.Feature.feature.loading.searchQuery,
        types: state.Feature.feature.types,
        lazyLoading: state.FormBank.HotelSearch.lazyLoading,
        switch_container: state.Member.switch_container,
        establishmentsToFilter: state.FormBank.HotelSearch.establishmentsToFilter,
        selectedSubtypes: state.FormBank.HotelSearch.selectedSubtypes,
        hotel_form_state: state.FormBank.HotelSearch.hotel_form_state,
        subtypes: state.FormBank.HotelSearch.subtypes,
        establishments: state.FormBank.HotelSearch.establishments,
    
    }
}, {
    cleanSearchQueryDestination: Actions.cleanSearchQueryDestination,
    setSearchQueryFeature: Actions.setSearchQueryFeature,
    setSwitchFeatureTypeContainerOfFeature: Actions.setSwitchFeatureTypeContainerOfFeature,
    cleanSelectedDestinationsFeature: Actions.cleanSelectedDestinationsFeature,
    cleanSelectedSubTypeFeature: Actions.cleanSelectedSubTypeFeature,
    setSearchAutocompleteDestination: Actions.setSearchAutocompleteDestination,
    setSearchQueryDestination: Actions.setSearchQueryDestination,
    setFoundHotels: Actions.setFoundHotels,
    addQuerySubtype: Actions.addQuerySubtype,
    addSelectedSubTypeHotels: Actions.addSelectedSubTypeHotels,
},
)(({
    lang,
    types,
    searchQuery,
    activeFeatureId,
    destinationList,
    lazyLoading,
    switch_container,
    establishmentsToFilter,
    setSearchQueryDestination,
    setSearchQueryFeature,
    cleanSearchQueryDestination,
    setSearchAutocompleteDestination,
    setSwitchFeatureTypeContainerOfFeature,
    cleanSelectedDestinationsFeature,
    cleanSelectedSubTypeFeature,
    setFoundHotels,
    addSelectedSubTypeHotels,
    addQuerySubtype,
    selectedSubtypes,
    hotel_form_state,
    establishments,
    subtypes,
}) => {
    const [query, setQuery] = React.useState("")
    let  [,setState]=React.useState();
    
    const anchorRef = React.useRef(null);
    const classes = searchStyles()
    const delay = (() => {
        let timer = 0
        return (callback, ms) => {
            clearTimeout(timer)
            timer = setTimeout(callback, ms)
        }
    })()
    const updateState = (search, error) => {
        if (!error) {
            setSearchAutocompleteDestination(search,lazyLoading)
          //  setSearchQueryFeature(search, activeFeatureId, lang)
        }
    }
    const handleChangeHandleTextField = event => {
        console.log("handleChangeHandleTextField", event.target.value);
        let search = event.target.value
        setQuery(search)
    //    delay(function () {
    //        updateState(search, !validate(String(search).trim(), validate_fields.search))
    //    }, DELAY_ZERO_SECOND)
    }

    const searchFilter = (query, establishmentsToFilter, selectedSubtypes ) =>{
        let types = selectedSubtypes!== undefined ? selectedSubtypes.slice().filter(val => val.type !== "query") : []
    
        console.log("types", types, selectedSubtypes);

        const subtype ={
            title: query,
            id: "q-"+query,
            type: "query"
        }

        console.log("searchFilter", query);
        //addQuerySubtype(query, types)
        addSelectedSubTypeHotels(establishmentsToFilter, subtype, types, hotel_form_state, lazyLoading)

        //setFoundHotels(query, establishments, selectedSubtypes)
        setQuery("")
        //setState({});
        
    }

    const handleCloseAway = event => {
        console.log("handleCloseAway", event);
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        // setQuery("")
        // cleanSearchQueryDestination()
    };
    const handleClose = event => {
        setQuery("")
        setSearchQueryFeature("")
        setSearchQueryDestination("", lazyLoading)
        cleanSearchQueryDestination()

    };
    const handleClear = () => {
        setQuery("")
        

    }

    //console.log(subtypes);
    return (
        <React.Fragment>
            

            {switch_container !== member_area_router.hotelSearch &&
            <ClickAwayListener onClickAway={handleCloseAway}>
                <div
                    ref={anchorRef} style={{ position: "relative" }}>
                    <div className={classes.search}>

                        <InputBase
                            placeholder="Search"
                            classes={{
                                root: classes.inputRoot,
                                input: classes.inputInput,
                            }}
                            inputProps={{ 'aria-label': 'search' }}
                            onChange={handleChangeHandleTextField}
                            value={query}
                            endAdornment={
                                <IconButton
                                  size='small'
                                  onClick={handleClear}>
                                  <FontAwesomeIcon icon={faWindowClose} style={{marginRight: "5px"}} /> 
                                </IconButton>
                              } 
                        />
                        {query === "" &&
                            <div
                                style={{
                                    right: "10px",
                                    bottom: "12px",
                                    position: 'absolute',
                                    pointerEvents: 'none',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <SearchIcon />
                            </div>}
                    </div>
                    {query !== "" && false &&
                        <div
                            style={{
                                cursor: "pointer",
                                right: "10px",
                                bottom: "12px",
                                position: 'absolute',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                            onClick={() => searchFilter(query, establishmentsToFilter, selectedSubtypes)}>
                            <SearchIcon    />
                        </div>}
                
                
                    {/*destinationList.length > 0 && < SearchBarHotelsAutocomplete
                        anchorRef={anchorRef}
                        handleClose={handleClose}
                        open={destinationList.length > 0}
                    />*/}
                </div>
            </ClickAwayListener>
        
        }

        {switch_container === member_area_router.hotelSearch &&
            
            <div
                ref={anchorRef} style={{ position: "relative" }}>
                <div className={classes.search}>

                    <InputBase
                        placeholder="Search"
                        classes={{
                            root: classes.inputRoot,
                            input: classes.inputInput,
                        }}
                        inputProps={{ 'aria-label': 'search' }}
                        onChange={handleChangeHandleTextField}
                        value={query}
                        disabled = {!( establishments !== undefined &&  establishments?.length > 0)}
                    />
                    {query === "" &&
                        <div
                            style={{
                                right: "10px",
                                bottom: "12px",
                                position: 'absolute',
                                pointerEvents: 'none',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <SearchIcon />
                        </div>}
                </div>
                {query !== "" &&
                    <div
                        style={{
                            cursor: "pointer",
                            right: "10px",
                            bottom: "12px",
                            position: 'absolute',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                        onClick={() => searchFilter(query, establishmentsToFilter, selectedSubtypes)}>
                        <SearchIcon    />
                    </div>}
            
            </div>
        }

        </React.Fragment>
    )
})
