import * as Actions from '../../../../redux/actions'

import Divider from '@material-ui/core/Divider';
import Grow from '@material-ui/core/Grow';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import React from 'react'
import { TimesIcon } from "../SVGBank"
import { connect } from "react-redux";
import { makeStyles } from '@material-ui/core/styles'

const menuListStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        '& .MuiListItem-root': {
            boxShadow: "0 0 0 0 rgba(0,123,255,.25)",
            font: "16px/16px Futura Md BT",
            color: "#707070",
        },
        '& .MuiListItem-button:hover': {
            textDecoration: "none",
            backgroundColor: "rgba(25, 188, 155, 0.2)",
        },
    },
    buttonText: {
        color: "var(--mainGreen)",
        textAlign: "left",
        font: "16px/22px Futura Md BT",
        letterSpacing: "0",
        paddingTop: "7px",
        paddingBottom: "5px",
        paddingRight: "5px",
        paddingLeft: "16px",
        '&:focus': {
            border: "0",
            outline: "0px",

        },
        '& .MuiButton-label': {
            color: "var(--mainGreen)",
            textAlign: "left",
            font: "16px/24px Futura Md BT",
            paddingLeft: "16px",
        }
    },
    zIndex: {
        zIndex: "1"
    },
    paddingLeft30: {
        paddingLeft: "30px"
    },
    backgroundSelection: {
        '&:hover': {
            backgroundColor: "var(--mainGreen)"
        }
    },
    paper: {
        marginRight: theme.spacing(2),
    },
    dropdown: {

        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: `calc(420px + ${theme.spacing(4)}px)`,
        },
        marginTop: "8px",
        zIndex: "1",
    },
}));
export const DestinationVisitedAttachment = connect((state) => ({
    destinationVisitedSearch: state.FormBank.destinationVisitedSearch,
    isMobile: state.Setting.isMobile,
    lang: state.Setting.lang,
    uuid: state.Member.authModal.uuid,

}), {
    /* actions */
    selectedDestinationVisitedMerge: Actions.selectedDestinationVisitedMerge,
    clearDestinationVisitedSearch: Actions.clearDestinationVisitedSearch,
})(({
    uuid,
    lang,
    anchorRef,
    handleClose,
    clearInput,
    isMobile,
    destinationVisitedSearch,
    selectedDestinationVisitedMerge,
    clearDestinationVisitedSearch,
    selectedDestinationSets = destinationVisitedSearch.selectedDestinationSets,
    open = destinationVisitedSearch.open,
}) => {


    const classes = menuListStyles();

    const handleSelectedDestinationVisitedMerge = (destination, selectedDestinationSets, uuid, lang) => {
        selectedDestinationVisitedMerge(destination, selectedDestinationSets, uuid, lang)
        clearDestinationVisitedSearch()
        clearInput()
    }
    return (
        <div className={classes.root}>
            {destinationVisitedSearch.open}
            <div>
                <Popper anchorEl={anchorRef.current} open={true} role={undefined} className={classes.dropdown}
                    placement={"bottom-start"}
                    modifiers={{
                        flip: {
                            enabled: false,
                        },
                    }} transition disablePortal>
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{ transformOrigin: placement === 'bottom' ? 'left top' : 'left bottom' }}
                        >
                            <Paper>
                                <MenuList autoFocusItem={open} id="menu-list-sort-trips" >
                                    {destinationVisitedSearch !== undefined && destinationVisitedSearch.destinations !== undefined && destinationVisitedSearch.destinations.length > 0 &&
                                        <div>
                                            <div style={{ display: "flex", outline: "0" }}>
                                                <div style={{
                                                    flexGrow: "1",
                                                    paddingTop: "10px",
                                                    paddingBottom: "6px",
                                                    paddingLeft: "6px",
                                                    cursor: "pointer",
                                                    outline: "0px",
                                                    font: "16px / 16px Futura Md BT",
                                                }}>Destinations</div>
                                                <div onClick={handleClose} style={{ marginRight: "10px" }}>{isMobile ? <TimesIcon width={13} height={13} /> : <TimesIcon width={20} height={20} />}</div>
                                            </div>
                                            <Divider light />
                                            {destinationVisitedSearch.destinations.map((destination, destinations_idx) => {
                                                return (
                                                    <div key={destinations_idx} >
                                                        <MenuItem className={"btn btn-sm flex-wrap member_menu_top_btn member_menu"} onClick={() => handleSelectedDestinationVisitedMerge(destination, destinationVisitedSearch.selectedDestinationSets, uuid, lang)}>{destination.name}</MenuItem>
                                                    </div>
                                                )
                                            })}
                                        </div>}

                                </MenuList>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </div>
        </div>
    );
})
