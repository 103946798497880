import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import "./styles.css";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'grid',
    gridTemplateColumns: '50% 50%',
    alignItems: 'center',
    marginTop: 340,
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      marginTop: 20
    },
    [theme.breakpoints.between('sm', 'md')]: {
      display: 'grid',
      gridTemplateColumns: '50% 50%',
      alignItems: 'center',
      marginTop: 80,
    },
  },
  stepBackgroundImage: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
    paddingRight: 40,
    [theme.breakpoints.down("sm")]: {
      paddingTop: 150,
      paddingRight: 0,
    },
  },
  chartBar: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  chartBarContent: {
    width: 70,
    [theme.breakpoints.down("sm")]: {
      width: 45,
    },
  },
  xAxisLabel: {
    font: "normal normal 500 18px/36px Futura Md BT",
    color: "#707070",
    [theme.breakpoints.down("sm")]: {
      font: "normal normal 500 10px/30px Futura Md BT",
    },
  },
  topRate: {
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    top: -128,
    width: 160,
    height: 120,
    background: "#19BC9B 0% 0% no-repeat padding-box",
    opacity: 1,
    [theme.breakpoints.down("sm")]: {
      width: 115,
      height: 100,
    },
  },
  starGroup: {
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      top: 4,
    },
  },
  trophySection: {
    width: 60,
    textAlign: "center",
    borderBottom: "1px solid #ffffff",
    [theme.breakpoints.down("sm")]: {
      width: 40,
    },
  },
  trophyImage: {
    width: 30,
    [theme.breakpoints.down("sm")]: {
      width: 20,
    },
  },
}));

export default function LandingStepFour() {
  const classes = useStyles();
  const screenSize = document.body.clientWidth;
  let screen_at_500 = 500;

  return (
    <div className={classes.root} style={{paddingBottom: "8%"}}>
      {screenSize < screen_at_500 ? (
        <div className="landing-step-container">
          <div className="step-description-section">
            <h1 className="step-number">4</h1>
            <div className="step-description">
              <h2 className="step-title">Share and get rewarded</h2>
              <p className="futura_lt_bt_20_30">
              Are you a Globetrotter, a World Trekker or a Jetsetter. 
              Share as many adventures, reviews, images or places as you can and get rewarded with increasingly improved status. Earn badges in other categories, too. Are you also a Gourmand or a Fashionista. Every level has its perks: discounts, free upgrades and free services. 
              Become a true müver and the world will be your oyster.
              </p>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      <div className={classes.stepBackgroundImage}>
        <div className={classes.chartBar}>
          <div
            className={classes.chartBarContent}
            style={{
              height: "90px",
              background: "#19BC9B 0% 0% no-repeat padding-box",
              opacity: 0.2,
            }}
          ></div>
          <span className={classes.xAxisLabel}>JAN</span>
        </div>
        <div className={classes.chartBar}>
          <div
            className={classes.chartBarContent}
            style={{
              height: "254px",
              background: "#19BC9B 0% 0% no-repeat padding-box",
              opacity: 0.2,
            }}
          ></div>
          <span className={classes.xAxisLabel}>FEB</span>
        </div>
        <div className={classes.chartBar}>
          <div
            className={classes.chartBarContent}
            style={{
              height: "166px",
              background: "#19BC9B 0% 0% no-repeat padding-box",
              opacity: 0.2,
            }}
          ></div>
          <span className={classes.xAxisLabel}>MAR</span>
        </div>
        <div className={classes.chartBar}>
          <div
            className={classes.chartBarContent}
            style={{
              height: "254px",
              background: "#19BC9B 0% 0% no-repeat padding-box",
              opacity: 0.2,
            }}
          ></div>
          <span className={classes.xAxisLabel}>APR</span>
        </div>
        <div className={classes.chartBar}>
          <div className={classes.topRate}>
            <div className={classes.starGroup}>
              <div
                style={{ position: "relative", textAlign: "center", top: -10 }}
              >
                <img src={require("../../../assets/images/star1.svg")} />
                <img src={require("../../../assets/images/star2.svg")} />
                <img src={require("../../../assets/images/star3.svg")} />
              </div>

              <div
                style={{ position: "relative", textAlign: "center", top: -21 }}
              >
                <img src={require("../../../assets/images/star4.svg")} />
                <img src={require("../../../assets/images/star5.svg")} />
                <img src={require("../../../assets/images/star6.svg")} />
                <img src={require("../../../assets/images/star7.svg")} />
              </div>

              <div
                style={{
                  width: "100%",
                  position: "absolute",
                  textAlign: "center",
                  top: 14,
                }}
              >
                <img src={require("../../../assets/images/star8.svg")} />
                <img src={require("../../../assets/images/star9.svg")} />
                <img src={require("../../../assets/images/star10.svg")} />
              </div>

              <div
                style={{
                  width: "100%",
                  position: "absolute",
                  textAlign: "center",
                  top: 25,
                }}
              >
                <img src={require("../../../assets/images/star11.svg")} />
                <img src={require("../../../assets/images/star12.svg")} />
              </div>

              <div
                style={{
                  width: "100%",
                  position: "absolute",
                  textAlign: "center",
                  bottom: -10,
                }}
              >
                <img src={require("../../../assets/images/star13.svg")} />
              </div>
            </div>
            <div className={classes.trophySection}>
              <img
                className={classes.trophyImage}
                src={require("../../../assets/images/trophy-1.svg")}
              />
            </div>
            <span style={{ color: "#ffffff", lineHeight: "25px" }}>May</span>
          </div>
          <div
            className={classes.chartBarContent}
            style={{
              height: "360px",
              background: "#19BC9B 0% 0% no-repeat padding-box",
              opacity: 1,
            }}
          ></div>
          <span className={classes.xAxisLabel}>MAY</span>
        </div>
        <div className={classes.chartBar}>
          <div
            className={classes.chartBarContent}
            style={{
              height: "254px",
              background: "#19BC9B 0% 0% no-repeat padding-box",
              opacity: 0.2,
            }}
          ></div>
          <span className={classes.xAxisLabel}>JUNE</span>
        </div>
      </div>

      {screenSize > screen_at_500 ? (
        <div className="landing-step-container" >
          <div className="step-description-section">
            <h1 className="step-number">4</h1>
            <div className="step-description">
              <h2 className="step-title">Share and get rewarded</h2>
              <p className="futura_lt_bt_20_30">
              Are you a Globetrotter, a World Trekker or a Jetsetter. 
              Share as many adventures, reviews, images or places as you can and get rewarded with increasingly improved status. Earn badges in other categories, too. Are you also a Gourmand or a Fashionista. Every level has its perks: discounts, free upgrades and free services. 
              Become a true müver and the world will be your oyster.
              </p>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
