import * as Actions from '../../../redux/actions'

import DestinationsFilter from './DestinationsFilter'
import Fab from '@material-ui/core/Fab'
import FeatureListContainer from "./FeatureListContainer"
import { MapDuotoneIcon } from "../Common/SVGBank"
import React from 'react'
import Features from './Features'
import { SearchBar } from "./SearchBar"
import { SortingBar } from "./SortingBar"
import SubTypes from "./SubTypes"
import TopBarSlider from './TopBarSlider'
import Types from './Types'
import { connect } from 'react-redux'
import { features_router, list_container_mode } from '../Common/RegExValidate'
import { useState } from 'react';
import Breadcrumb from './Breadcrumb';
import DestinationTopBarSlider from '../DestinationPage/TopBarSlider';
import { capitalizeWord } from '../../../utility/utility'
import { Link, makeStyles, Modal } from '@material-ui/core'
import { PersonalizedTripComponent } from './personalizedTrip/personalizedTrip'
import { useDispatch } from 'react-redux'
import { toggleModal } from './personalizedTrip/personalizedTrip.slice'
import { SubDestinations } from './Subdestinations'
import { getSubDestinations } from './Destination.slice'
import { useHistory } from 'react-router-dom';
import Header from '../Header/Header'
import { HeaderMobile } from '../Header/HeaderMobile'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRoute } from '@fortawesome/free-solid-svg-icons'
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const FeatureContainer_ = ({ featureList, subtypes, preload, ActiveTrip, activeId, props, lang, is_mobile, app_menu_height, timelineStatus,
    setTripDisplayMode, setPageYOffset, setFeaturePage, addSelectedDestinationsFeature, selectedDestinations }) => {
    const [isDestination, setIsDestination] = useState(false)
    const featureID = props.match.params.featureID
    const destinationID = props.match.params.destinationID
    const dispatch = useDispatch()
    const isAboutPage = props?.match?.params?.desc === "about" || false;
    const isPlacesPage = props?.match?.params?.desc === "places" || false;

    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.only('sm'));
    const medScreen = useMediaQuery(theme.breakpoints.only('md'));
    const lgScreen = useMediaQuery(theme.breakpoints.only('lg'));
    const xlScreen = useMediaQuery(theme.breakpoints.only('xl'));


    const setDestinationPage = () => {
        if (destinationID !== undefined &&
            destinationID !== null &&
            destinationID !== "") {
            setIsDestination(true);
            addSelectedDestinationsFeature(destinationID, [])  //filters page by id
            dispatch(getSubDestinations(destinationID))
        }
    }

    const history = useHistory()

    const goToAboutPage = () => {
        const url = "/destination/about/1/" + activeId;
        history.push(url.toLowerCase())
    }

    React.useEffect(() => {
        setDestinationPage()  //use as destination page
    }, [props.match.params])

    React.useEffect(() => {
        // const hasSeeType = subtypes.find((type) => type.id === "1");
        // if (!hasSeeType && !preload && featureID === "1" && !isAboutPage) {
        //     goToAboutPage() ///force about page
        // }
        if ((!subtypes || !(subtypes?.length > 0)) && !preload && !isAboutPage && !featureID) {
            goToAboutPage() ///force about page
        }

    }, [subtypes])
    let padding_l = 15
    let padding_r = 15


    if (!isNaN(is_mobile)) {
        padding_l = is_mobile ? "1" : (timelineStatus ? lgScreen? '36': "31" : "15")
        padding_r = is_mobile ? "1" : (timelineStatus ? "13" : "15")
    }
    const handleMap = () => {
        setTripDisplayMode(list_container_mode.map)
        setPageYOffset(0)
    }





    return (
        <div style={{ position: "relative" }}>
            {!is_mobile ? null : <HeaderMobile bg_color={"var(--frameBlack)"} text_color={"var(--mainWhite)"} />}

            {!isDestination && <TopBarSlider />}
            {isDestination && <DestinationTopBarSlider />}
            {isDestination && <Breadcrumb />}
            {isDestination &&
                <div style={{
                    paddingTop: "10px",
                    marginLeft: padding_l + "%",
                    paddingRight: padding_r + "%",
                    paddingBottom: "10px",
                }}>
                    <SubDestinations />
                </div>}

            <div  style={{
                paddingTop: "10px",
                marginLeft: padding_l + "%",
                paddingRight: padding_r + "%",
                paddingBottom: "10px",
                position: "sticky",
                top: is_mobile ? "56px" : (Math.ceil(app_menu_height / 2)) + "px",
                backgroundColor:is_mobile? "var(--frameBlack)": "#FFFFFF",
                zIndex: "3",
            }}>
                {isDestination &&
                    <div>
                        <Features lang={lang} setFeaturePage={setFeaturePage} isAboutPage={isAboutPage} />
                        <PersonalizedTripComponent />
                    </div>}

                {!isAboutPage && <Types />}
                {!isDestination && <DestinationsFilter />}
                <div className="d-flex margin_top_10">
                    {!is_mobile && !isAboutPage && <SubTypes />}
                    {!is_mobile && !isAboutPage && <SearchBar isDestination={isDestination} />}
                    {!is_mobile && !isAboutPage && <SortingBar />}
                </div>
                {is_mobile && !isAboutPage && <div style={{ paddingLeft: "15px", paddingRight: "15px" }}><SearchBar isDestination={isDestination} /></div>}
                {is_mobile && !isAboutPage && <div style={{ paddingLeft: "15px", paddingRight: "15px" }}><SortingBar /></div>}
            </div>
            <div style={{
                paddingLeft: padding_l + "%",
                paddingRight: padding_r + "%",
                paddingBottom: "10px",
            }}>
                <FeatureListContainer isAboutPage={isAboutPage} />
            </div>

            {isDestination && !is_mobile && <div style={{ position: "fixed", top: "50%", right: "100px", zIndex: "3" }}>

                <Fab size="large" aria-label="back to list" style={{
                    outline: "0px",
                    backgroundColor: "#F8F8F8",
                }} onClick={() => handleMap()}>
                    <MapDuotoneIcon width={30} height={30} fill={"#0D3A7D"} style={{ marginLeft: "3px" }} />
                </Fab>
            </div>}

           
        </div >
    )
}

const mapStateToProps = (state) => {
    return {
        lang: state.Setting.lang,
        is_mobile: state.Setting.is_mobile,
        app_menu_height: state.Setting.appMenu.app_menu_height,
        timelineStatus: state.FormBank.TimelineWizard.status,
        feature: state.Feature.feature,
        subtypes: state.Feature.feature.subtypes,
        selectedSubtypes: state.Feature.feature.loading.selectedSubtypes,
        selectedDestinations: state.Feature.feature.loading.selectedDestinations,
        ActiveTrip: state.ActiveTrip,
        featureList: state.Feature.feature.featureList,
        activeId: state.Destination.destination.activeId,
        preload: state.Setting.preload,
    }
}


const mapDispatchToProps = {
    setTripDisplayMode: Actions.setTripDisplayMode,
    setPageYOffset: Actions.setPageYOffset,
    addSelectedDestinationsFeature: Actions.addSelectedDestinationsFeature,
}


const FeatureContainer = connect(mapStateToProps, mapDispatchToProps)(FeatureContainer_)
export default FeatureContainer
