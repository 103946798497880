import React from 'react'
import { connect } from 'react-redux'

const Policies_ = ({ establishment }) => {
    const { generalInfo } = establishment
    const { allowed_cards_data, check_in_time, check_out_time,} = generalInfo

    return (
        <div>
            <div>
                <div style={{ display:"inline-block", width: "50%", height: "auto" }}>
                    <div style={{ font: "24px/36px Futura Hv BT", color: "#191919", marginBottom: "30px", }}>Policies</div> 

                        <div style={{ font: "18px/24px Futura Hv BT", color: "#191919", marginBottom: "10px", }}>Check-in</div>
                        <div style={{ font: "16px/22px Futura Lt BT", color: "#929292", }}>
                            Check-in time starts at {check_in_time}</div>
                        <div style={{ font: "16px/22px Futura Lt BT", color: "#929292", }}>
                            Check-in time ends at midnight</div>

                        <div style={{ font: "18px/24px Futura Hv BT", color: "#191919", marginBottom: "10px", marginTop: "10px", }}>Check-out</div>
                        <div style={{ font: "16px/22px Futura Lt BT", color: "#929292", marginBottom: "10px", }}>
                            Check-out time is {check_out_time}</div>
                        
                        <div style={{ font: "18px/24px Futura Hv BT", color: "#191919", marginBottom: "10px", }}>Children and extra beds</div>
                        <div style={{ font: "16px/22px Futura Lt BT", color: "#929292", }}>
                            Children are welcome {}</div>
                        <div style={{ font: "16px/22px Futura Lt BT", color: "#929292", }}>
                            Free cribs (infant beds) {}</div>

                        <div style={{ font: "18px/24px Futura Hv BT", color: "#191919", marginBottom: "10px", marginTop: "10px", }}>Payment types</div>
                        <div style={{ font: "16px/22px Futura Lt BT", color: "#929292", marginBottom: "10px", }}>
                            {allowed_cards_data!==undefined && allowed_cards_data.map((card,idx) => {
                                return <li key={idx}>{card.name}</li>
                            })}
                        </div>
                        
                        
                        
                </div>
                <div style={{ display:"inline-block", width: "50%", height: "auto", verticalAlign: "top"  }}>
                    <div style={{ font: "24px/36px Futura Hv BT", color: "#191919", marginBottom: "30px", }}>Important Information</div> 

                    <div style={{ font: "18px/24px Futura Hv BT", color: "#191919", marginBottom: "10px", }}>Optional Extras</div>
                    <div style={{ font: "16px/22px Futura Lt BT", color: "#929292", }}>
                        The following fees and deposits sre charged by the property at the time of service, check-in, check-out.
                    </div>
                    <div style={{ font: "16px/22px Futura Lt BT", color: "#929292", }}>
                        {generalInfo.rate_amenity_data !==undefined && generalInfo.rate_amenity_data !==null && generalInfo.rate_amenity_data.map((amenity,idx) => {
                                return <li key={idx}>{amenity.name}</li>
                            })}
                    </div>
                    <div style={{ font: "16px/22px Futura Lt BT", color: "#929292", }}>
                        The above list may not be comprehensive.  Fees and deposits may not include tax and are subject to change.
                    </div>

                    <div style={{ font: "18px/24px Futura Hv BT", color: "#191919", marginBottom: "10px", marginTop: "10px", }}>You need to know</div>
                    <div style={{ font: "16px/22px Futura Lt BT", color: "#929292", }}>
                        Extra person charges may apply and vary depending on property policy.</div>
                    <div style={{ font: "16px/22px Futura Lt BT", color: "#929292", }}>
                        Government-issued photo identification and a credit card, debit card, or cash deposit are required at check-in for incidental charges.</div>

                    <div style={{ font: "16px/22px Futura Lt BT", color: "#929292", }}>
                        Special requests are subject to availability upon check-in and may incur additional charges.  Special requests cannot be guaranteed.</div>
                    
                    {false && <div style={{ font: "18px/24px Futura Hv BT", color: "#191919", marginBottom: "10px", }}>We should mention</div>}
                    
                
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({

    establishment: state.FormBank.HotelSearch.establishment,
    isMobile: state.Setting.is_mobile,
    lang: state.Setting.lang,
    uuid: state.Member.authModal.uuid,

    switchFeatureContainer: state.FormBank.FeatureFormat,
})

const mapDispatchToProps = {

}

const Policies = connect(mapStateToProps, mapDispatchToProps)(Policies_)
export default Policies
