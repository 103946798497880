import "./MuvTribePage.css";

import {
  Col,
  Image,
  Row,
} from 'react-bootstrap'

import { PropTypes } from "prop-types"
import React from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { image_boxing } from "../../Common/RegExValidate";
import { getUserFollowers, getUserFollowing, getUserFriends } from "../../SocialPage/social.slice";
import MuvTribeFollowSlider from "./MuvTribeFollowSlider";


/* tribe friends component */
const MuvTribeFollowers_ = ({ friends, uuid, screenSize }) => {
  const dispatch = useDispatch()
  const userFollowing= useSelector(state => state.SocialPage.muvTribe?.following);
  const userFollowers = useSelector(state => state.SocialPage.muvTribe?.followers);





  return (
    <div style={{
      paddingBottom: 20
    }}>
      <div className="margin_bottom_50">
        <div className="d-flex margin_bottom_20 margin_top_30 margin_right_20 padding_125rem_lr_mobile">
        </div>

        
       {userFollowers &&  <MuvTribeFollowSlider
          title="Following" followers={userFollowing} isFollowing={true}/>
       }
       {userFollowing && <MuvTribeFollowSlider
          title="My Followers" followers={userFollowers}  />}


      </div>
    </div>
  )
}


const mapStateToProps = (state) => {
  return {
    friends: state.Member.socialMember.friends,
    screenSize: state.Setting.htmlBody.bodyClientWidth,
    uuid: state.Member.authModal.uuid,
  }
};

const MuvTribeFollowers = connect(mapStateToProps, null)(MuvTribeFollowers_)
export default MuvTribeFollowers
