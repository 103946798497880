import React from 'react'
import { connect } from 'react-redux'

const Neighborhood_ = ({ establishment }) => {
    const { neighborhood } = establishment
    const  isExisted = neighborhood && neighborhood.neighborhoodId
    return (
        <div>
            <div style={{ font: "30px/36px Futura Hv BT", color: "#191919", marginBottom: "30px", }}>Neighborhood</div>
            <div>
                <div style={{ float: "right", width: "50%", height: "120px" }}></div>
                
                {isExisted &&  <div>
                    <div style={{ font: "18px/24px Futura Md BT", color: "green", marginBottom: "10px"}}>
                            {neighborhood.neighborhoodTitle }
                    </div>
                    <div style={{ font: "16px/20px Futura Md BT", color: "#707070", marginBottom: "10px" }}>
                            <a href={neighborhood.neighborhoodWiki}>{neighborhood.neighborhoodWiki}</a>
                    </div>
                    <div style={{ font: "16px/20px Futura Md BT", color: "#707070", marginBottom: "10px" }}>
                            {neighborhood.neighborhoodDescription}
                    </div>
                </div>}         
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({

    establishment: state.Destination.establishment,
    isMobile: state.Setting.is_mobile,
    lang: state.Setting.lang,
    uuid: state.Member.authModal.uuid,

    switchFeatureContainer: state.FormBank.FeatureFormat,
})

const mapDispatchToProps = {

}

const Neighborhood = connect(mapStateToProps, mapDispatchToProps)(Neighborhood_)
export default Neighborhood
