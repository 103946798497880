import * as Actions from '../../../../redux/actions'

import {
    DELAY_ZERO_SECOND,
    validate,
    validate_fields,
} from "../RegExValidate"

import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Clip from './Clip'
import { DestinationVisitedAttachment } from './DestinationVisitedAttachment'
import InputBase from '@material-ui/core/InputBase'
import { Map } from './Map'
import React from 'react'
import { TimesIcon } from "../SVGBank"
import { connect } from "react-redux"
import { makeStyles } from '@material-ui/core/styles'

export const DestinationVisited = connect((state) => ({
    destinationVisitedSearch: state.FormBank.destinationVisitedSearch,
    hometownTextField: state.FormBank.hometownTextField,
    lang: state.Setting.lang,
    uuid: state.Member.authModal.uuid,
}), {
    fetchDestinationVisitedSelectedSets: Actions.fetchDestinationVisitedSelectedSets,
    fetchDestinationVisitedSearch: Actions.fetchDestinationVisitedSearch,
    clearDestinationVisitedSearch: Actions.clearDestinationVisitedSearch,
},
)(({
    uuid,
    lang,
    destinationVisitedSearch,
    clearDestinationVisitedSearch,
    fetchDestinationVisitedSearch,
    fetchDestinationVisitedSelectedSets,
    selectedDestinationSets = destinationVisitedSearch.selectedDestinationSets,
}) => {

    const [fetchedSelectedDestination, setFetchedSelectedDestination] = React.useState(false)
    if (!fetchedSelectedDestination) {
        setFetchedSelectedDestination(true)
        fetchDestinationVisitedSelectedSets(uuid, lang)
    }

    const searchStyles = makeStyles((theme) => ({
        title: {
            flexGrow: 1,
            display: 'none',
            [theme.breakpoints.up('sm')]: {
                display: 'block',
            },
        },
        search: {
            background: "#FFFFFF 0% 0% no-repeat padding-box",
            position: 'relative',
            margin: "0px",
            padding: "10px 10px 10px 10px",
            width: "100%",
            border: "2px solid #E8E8E8",
            height: '95px',
        },
        searchIcon: {
            right: "16px",
            top: "calc(50% - 10px )",
            position: 'absolute',
            // pointerEvents: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        inputRoot: {
            color: '#707070',
            font: "18px/24px Futura Md BT",
        },
        inputInput: {
            marginTop: "20px",
            // padding: theme.spacing(1, 0, 0.5, 2),
            // vertical padding + font size from searchIcon
            // paddingRight: '80px',
            transition: theme.transitions.create('width'),
            width: '100%',
            [theme.breakpoints.up('sm')]: {
                width: '450px',
            },
        },
    }));
    const classes = searchStyles();
    const delay = (() => {
        let timer = 0;
        return (callback, ms) => {
            clearTimeout(timer);
            timer = setTimeout(callback, ms);
        };
    })();
    const updateState = (search, error) => {
        if (!error) {
            fetchDestinationVisitedSearch(search)
        }
    }
    const handleChangeHandleTextField = event => {
        let search = event.target.value
        setValueQuery(event.target.value)
        delay(function () {
            updateState(search, !validate(String(search).trim(), validate_fields.destination_visited_search))
        }, DELAY_ZERO_SECOND);
    };

    const anchorRef = React.useRef(null);

    const handleClose = event => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        clearDestinationVisitedSearch()
    };
    const clearInput = () => {
        setValueQuery('')
        clearDestinationVisitedSearch()
    }
    const [valueQuery, setValueQuery] = React.useState('');
    return (
        <div>
            <ClickAwayListener onClickAway={handleClose}>
                <div>
                    <div className={classes.search}>
                        <div className={classes.searchIcon} onClick={() => clearInput()} style={{ cursor: "pointer" }}>
                            <TimesIcon width={25} height={20} />
                        </div>
                        <InputBase

                            ref={anchorRef}
                            placeholder="ie: New York, France, Tuscany etc."
                            classes={{
                                root: classes.inputRoot,
                                input: classes.inputInput,
                            }}
                            inputProps={{ 'aria-label': 'search' }}
                            onChange={handleChangeHandleTextField}
                            value={valueQuery}
                        />
                    </div>
                    {destinationVisitedSearch.open && <DestinationVisitedAttachment anchorRef={anchorRef} clearInput={clearInput} handleClose={handleClose} />}

                </div>
            </ClickAwayListener>
            <div style={{ marginTop: "15px", minHeight: "38px" }}>
                {
                    selectedDestinationSets !== undefined && selectedDestinationSets.length > 0 &&
                    selectedDestinationSets.map((destination, destination_idx) => {
                        return (
                            <Clip key={destination_idx} element={destination} />
                        )
                    })
                }
            </div>
            {window.google !== undefined && <Map />}
        </div>
    );
})
