import Rating from '@material-ui/lab/Rating'
import React from 'react'
import ReviewsDescription from "./ReviewsDescription"
import { connect } from 'react-redux'
import moment from 'moment'
import {
    Image,
} from 'react-bootstrap'
const ReviewsYelp_ = ({ reviewsYelp }) => {

    const reviews =  reviewsYelp?.reviews || null
    console.log(reviewsYelp)

    const goToYelp = ()=>{
        if(reviewsYelp?.yelp_url){
            window.open(reviewsYelp.yelp_url)
        }
    }
    return (

        <div>
            {reviews !== null && reviews.length > 0 && reviews.map((review, review_index) => {
                const yelp_review_1_date = review.reviewDate !== undefined ? moment(review.reviewDate, 'YYYY-MM-DD').format("MMM Do[,] YYYY") : ""
                const yelp_review_1_rating = review.rating !== undefined ? review.rating : ""
                const yelp_review_1_text = review.text !== undefined ? review.text : ""
                const yelp_review_1_user = review.userName !== undefined ? review.userName : ""
                return (
                    <div key={review_index} >
                        {
                            yelp_review_1_date !== "Invalid date" && <div style={{ display: "flex", flexDirection: "column", marginTop: "30px" }}>
                                {review?.userAvatar && <div style={{flexGrow: "1"}}>
                                    {review?.userAvatar &&<img className=""
                                        src={review.userAvatar} alt=""
                                        onError={(event)=>event.target.setAttribute("src","default-image-link")}  
                                        width={"70px"} height={"70px"} />}
                                    </div>}
                                <div style={{ flexGrow: "1", flexWrap: "wrap" }}>
                                    <div style={{ whiteSpace: "nowrap", }}><span style={{ font: "18px/24px Futura Hv BT", color: "#707070" }}>{yelp_review_1_user}</span> {" - "} <span style={{ font: "14px/24px Futura Lt BT", color: "#707070", whiteSpace: "nowrap", }}>{yelp_review_1_date}</span></div>
                                    <Rating name="reviews_rating" style={{
                                        color: "var(--mainGreen)", opacity: "1",
                                    }} precision={0.5} max={5} value={parseFloat(yelp_review_1_rating)} disabled={true} />
                                </div>
                                <div style={{ flexGrow: "1", flexWrap: "wrap" }}>
                                   <ReviewsDescription description={yelp_review_1_text} boxDescription={"box_3"} fontDescription={"16px/22px Futura Md BT"} colorDescription={"#191919"} height={"90px"} width={"630px"} />
                                </div>
                           
 
                            </div>
                        }
                       
                    </div>
                )
            })}
                                      
          <div style={{ textDecoration: "underline", color: "#0D3A7D", font: "15px/26px Futura Md BT", textAlign: "right", marginTop: "30px", cursor: "pointer", }} onClick={goToYelp}>{"See more yelp reviews"}</div>
        </div>
    )
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = {

}

const ReviewsYelp = connect(mapStateToProps, mapDispatchToProps)(ReviewsYelp_)
export default ReviewsYelp 
