import {
    CREATE_TRIP_FAILURE,
    FETCH_AIRPORT_LIST_FAILURE,
    FETCH_AIRPORT_LIST_SUCCESS,
    FETCH_CITIES_LIST_FAILURE,
    FETCH_CITIES_LIST_SUCCESS,
    PL_FETCH_AIRPORT_LIST_FAILURE,
    PL_FETCH_AIRPORT_LIST_SUCCESS,
    PL_FETCH_CITIES_LIST_FAILURE,
    PL_FETCH_CITIES_LIST_SUCCESS,
    FETCH_DESTINATION_LIST_FAILURE,
    FETCH_DESTINATION_LIST_SUCCESS,
    FETCH_DISCOVERY,
    FETCH_DISCOVERY_ERROR,
    FETCH_HOMEPAGE_BUCKET_LIST_FAILURE,
    FETCH_HOMEPAGE_BUCKET_LIST_SUCCESS,
    FETCH_HOMEPAGE_FAILURE,
    FETCH_HOMEPAGE_GENERAL_INFO_FAILURE,
    FETCH_HOMEPAGE_GENERAL_INFO_SUCCESS,
    FETCH_HOMEPAGE_MUVER_PRESS_FAILURE,
    FETCH_HOMEPAGE_MUVER_PRESS_SUCCESS,
    FETCH_HOMEPAGE_MUVER_STYLE_FAILURE,
    FETCH_HOMEPAGE_MUVER_STYLE_SUCCESS,
    FETCH_HOMEPAGE_SLIDER_IMAGE_FAILURE,
    FETCH_HOMEPAGE_SLIDER_IMAGE_SUCCESS,
    FETCH_HOMEPAGE_SUCCESS,
    IS_FETCHED_DISCOVERY,
    SWITCH_DISCOVER_EXTENDER,
} from "../interfaces/types"

const initialState = {
    homepage: {},
    homepage_general_info: {},
    homepage_bucket_list: [],
    homepage_slider_image: [],
    homepage_muver_style: {},
    homepage_muver_press: {},
    search_list_destinations: [],
    search_list_cities: [],
    search_list_airports: [],
    error: '',
    discover: {
        discoverSets: [],
        apiError: '',
        isFetched: false,
        extender: false,
    },
}

/* get homepage reducer */
export default (state = initialState, action) => {
    switch (action.type) {
        case FETCH_HOMEPAGE_SUCCESS:
            return Object.assign({}, state, {
                homepage: action.payload
            });
        case FETCH_HOMEPAGE_FAILURE:
            return Object.assign({}, state, {
                error: action.payload,
                homepage: {}
            });
        case FETCH_HOMEPAGE_GENERAL_INFO_SUCCESS:
            return Object.assign({}, state, {
                homepage_general_info: action.payload
            });
        case FETCH_HOMEPAGE_GENERAL_INFO_FAILURE:
            return Object.assign({}, state, {
                error: action.payload,
                homepage_general_info: []
            });
        case FETCH_HOMEPAGE_BUCKET_LIST_SUCCESS:
            // return Object.assign({}, state, { homepage_bucket_list: action.payload});
            return Object.assign({}, state, {
                homepage_bucket_list: action.payload
            });
        case FETCH_HOMEPAGE_BUCKET_LIST_FAILURE:
            return Object.assign({}, state, {
                error: action.payload,
                homepage_bucket_list: []
            });
        case FETCH_HOMEPAGE_SLIDER_IMAGE_SUCCESS:
            return Object.assign({}, state, {
                homepage_slider_image: action.payload
            });
        case FETCH_HOMEPAGE_SLIDER_IMAGE_FAILURE:
            return Object.assign({}, state, {
                error: action.payload,
                homepage_slider_image: []
            });
        case FETCH_HOMEPAGE_MUVER_STYLE_SUCCESS:
            return Object.assign({}, state, {
                homepage_muver_style: action.payload
            });
        case FETCH_HOMEPAGE_MUVER_STYLE_FAILURE:
            return Object.assign({}, state, {
                error: action.payload,
                homepage_muver_style: {}
            });
        case FETCH_HOMEPAGE_MUVER_PRESS_SUCCESS:
            return Object.assign({}, state, {
                homepage_muver_press: action.payload
            });
        case FETCH_HOMEPAGE_MUVER_PRESS_FAILURE:
            return Object.assign({}, state, {
                error: action.payload,
                homepage_muver_press: {}
            });
        case FETCH_DESTINATION_LIST_FAILURE:
            return Object.assign({}, state, {
                error: action.payload,
                search_list_destinations: []
            });
        case FETCH_DESTINATION_LIST_SUCCESS:
            return Object.assign({}, state, {
                search_list_destinations: action.payload
            });
        case FETCH_CITIES_LIST_FAILURE:
            return Object.assign({}, state, {
                error: action.payload,
                search_list_cities: []
            });
        case FETCH_CITIES_LIST_SUCCESS:
            return Object.assign({}, state, {
                search_list_cities: action.payload
            });
        case PL_FETCH_CITIES_LIST_FAILURE:
            return Object.assign({}, state, {
                error: action.payload,
                search_list_cities: []
            });
        case PL_FETCH_CITIES_LIST_SUCCESS:
            return Object.assign({}, state, {
                search_list_cities: action.payload
            });
        case FETCH_AIRPORT_LIST_FAILURE:
            return Object.assign({}, state, {
                error: action.payload,
                search_list_airports: []
            });
        case FETCH_AIRPORT_LIST_SUCCESS:
            return Object.assign({}, state, {
                search_list_airports: action.payload
            });
        case PL_FETCH_AIRPORT_LIST_FAILURE:
            return Object.assign({}, state, {
                error: action.payload,
                search_list_airports: []
            });
        case PL_FETCH_AIRPORT_LIST_SUCCESS:
            return Object.assign({}, state, {
                search_list_airports: action.payload
                });
        case CREATE_TRIP_FAILURE:
            return Object.assign({}, state, {
                error: action.payload
            });
        case FETCH_DISCOVERY:
            return {
                ...state,
                discover: {
                    ...state.discover,
                    discoverSets: action.discover_sets,
                }
            };
        case IS_FETCHED_DISCOVERY:
            return {
                ...state,
                discover: {
                    ...state.discover,
                    isFetched: action.is_fetched,
                }
            };
        case FETCH_DISCOVERY_ERROR:
            return {
                ...state,
                discover: {
                    ...state.discover,
                    apiError: action.api_error,
                }
            };
        case SWITCH_DISCOVER_EXTENDER:
            return {
                ...state,
                discover: {
                    ...state.discover,
                    extender: !state.discover.extender,
                }
            };
        default:
            return {
                ...state
            }
    }
}