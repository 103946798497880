import * as Actions from '../../../../redux/actions'

import Breadcrumb from '../../DestinationPage/Breadcrumb'
import DestinationListContainer from '../../DestinationPage/DestinationListContainer'
import Fab from '@material-ui/core/Fab'
import { ListIcon } from "../SVGBank"
import React from 'react'
import TopBarSlider from '../../DestinationPage/TopBarSlider'
import { connect } from 'react-redux'
import { list_container_mode } from '../RegExValidate'

const PrivateTripEstablishment_ = ({ id, lang, is_mobile, setTripDisplayMode, setSwitchFeatureListContainer, setPageYOffset, fetchDestination, fetchDestinationPhotoGallery, fetchDestinationFeaturesAndTypes, fetchEstablishmentSortingOptions, fetchEstablishmentInfluencers, fetchEstablishmentSuggestedTrips }) => {
    /* fetch destination */
    React.useEffect(() => {

        fetchDestination(id, lang)
        fetchDestinationPhotoGallery(id, lang)
        fetchEstablishmentSuggestedTrips(id, lang)
        fetchDestinationFeaturesAndTypes(id, lang)
        fetchEstablishmentSortingOptions(lang)
        fetchEstablishmentInfluencers(id, lang)

    })
    const handleSwitchContainer = () => {
        setSwitchFeatureListContainer(list_container_mode.list)
        setTripDisplayMode(list_container_mode.schedule)
        setPageYOffset(0)
    }

    return (
        <div style={{ position: "relative" }}>

            <TopBarSlider />
            <Breadcrumb />
            <DestinationListContainer />
            <div style={{ position: "fixed", top: "50%", right: is_mobile ? "10px" : "100px", zIndex: "3" }}>

                <Fab size="large" aria-label="back to list" style={{
                    outline: "0px",
                    backgroundColor: "#0D3A7D"
                }} onClick={() => handleSwitchContainer()}>
                    <ListIcon fill={"var(--mainWhite)"} style={{ marginLeft: "3px" }} />
                </Fab>
            </div>
        </div>
    )
}


const mapStateToProps = state => {
    return {
        lang: state.Setting.lang,
        id: state.Destination.destination.activeId,
        is_mobile: state.Setting.is_mobile,
    }
}

const mapDispatchToProps = {
    fetchDestination: Actions.fetchDestination,
    fetchDestinationPhotoGallery: Actions.fetchDestinationPhotoGallery,
    fetchDestinationFeaturesAndTypes: Actions.fetchDestinationFeaturesAndTypes,
    fetchEstablishmentInfluencers: Actions.fetchEstablishmentInfluencers,
    fetchEstablishmentSortingOptions: Actions.fetchEstablishmentSortingOptions,
    fetchEstablishmentSuggestedTrips: Actions.fetchEstablishmentSuggestedTrips,

    setSwitchFeatureListContainer: Actions.setSwitchFeatureListContainer,
    setPageYOffset: Actions.setPageYOffset,
    setTripDisplayMode: Actions.setTripDisplayMode,
}

const PrivateTripEstablishment = connect(mapStateToProps, mapDispatchToProps)(PrivateTripEstablishment_)
export default PrivateTripEstablishment
