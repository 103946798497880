import {
    FETCH_PHOTOS_FAILURE,
    FETCH_PHOTOS_SUCCESS,
    FETCH_VIDEOS_FAILURE,
    FETCH_VIDEOS_SUCCESS,
} from "../interfaces/types"

const initialState = {
    photos: {},
    videos: {},
    member_uuid: '',
    trip_id: '',
    error_photos: '',
    error_videos: '',
}

/* get media reducer */
export default (state = initialState, action) => {
    switch (action.type) {
        case FETCH_PHOTOS_SUCCESS:
            return {
                ...state,
                photos: action.payload,
                    member_uuid: action.member_uuid,
                    trip_id: action.trip_id,
                    error_photos: ''
            };
        case FETCH_PHOTOS_FAILURE:
            return {
                ...state,
                photos: {},
                    member_uuid: '',
                    trip_id: '',
                    error_photos: action.payload
            };
        case FETCH_VIDEOS_SUCCESS:
            return {
                ...state,
                videos: action.payload,
                    member_uuid: action.member_uuid,
                    error_videos: ''
            };
        case FETCH_VIDEOS_FAILURE:
            return {
                ...state,
                videos: {},
                    member_uuid: '',
                    error_videos: action.payload
            };
        default:
            return {
                ...state
            }
    }
}