import * as Actions from '../../../../redux/actions'
import { URL_REPLACE } from "../RegExValidate"
import { groupBy } from 'lodash';
import {
    AddressMapIcon,
    CalendarDuotoneIcon,
    TimesIcon,
} from "../SVGBank"

import {
    AsyncTypeahead, Menu, Highlighter,
    MenuItem,
} from 'react-bootstrap-typeahead'
import DatePicker from "react-datepicker"
import React, {useState, useEffect} from 'react'
import { connect, useSelector } from 'react-redux'
import moment from 'moment'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { useHistory,useLocation } from "react-router-dom"
import './booking.css'


export const HotelSearchFormEmbedded_ = ({ lang, uuid, is_mobile, setSwitchContainer, setBookingFormStatus, 
                                   activeTrip, switch_container, embedForm, lazyLoading, bookingForm,                     
                                   setCitiesData, searchListCities, getCitiesList, getAirportList, 
                                   hotelSearchForm, setHotelSearchForm, getPriceLineHotels}) => {

    const history = useHistory()  
    
    const location = useLocation()
    const [checkInDate, setCheckInDate] = React.useState(hotelSearchForm.check_in ? moment(hotelSearchForm.check_in).toDate() :"")
    const [checkOutDate, setCheckOutDate] = React.useState(hotelSearchForm.check_out ? moment(hotelSearchForm.check_out).toDate():"")
    

   
    const [defaultCurrency, setDefaultCurrency] = React.useState(null);
    

    const disableButton = hotelSearchForm.city_id==="" || hotelSearchForm.check_in==="" || hotelSearchForm.check_out===""  
  

    const selectedCity = hotelSearchForm.city_id!=="" ? {
        cityid_ppn: hotelSearchForm.city_id,
        city: hotelSearchForm.where.split(",")[0],
        country: hotelSearchForm.where.slice(hotelSearchForm.where.split(", ")[0].length+2),
        latitude: hotelSearchForm.city_latitude,
        longitude: hotelSearchForm.city_longitude,
         hotel_name: hotelSearchForm?.hotel_name,
        address: {
            city_name: hotelSearchForm?.address?.city_name,
            country_code: hotelSearchForm?.address?.country_code
        },
        type: hotelSearchForm?.type,
        hotelid_ppn:hotelSearchForm?.hotelid_ppn
    } : null


    const handleChangeCheckInDate = (date) => {
        setCheckInDate(date)
        let hotel_form_state = { ...hotelSearchForm };
        console.log(hotel_form_state);
        hotel_form_state.check_in = moment(date).format("YYYY-MM-DD");
        hotel_form_state.isNew = true;

        setHotelSearchForm(hotel_form_state)
        //setCitiesData(city.destinationId, date, city.duration, city.positionId, city.availableSelectDate)
    }
    const handleChangeCheckOutDate = (date) => {
        setCheckOutDate(date)
        let hotel_form_state = { ...hotelSearchForm};
        console.log(hotel_form_state);
        hotel_form_state.check_out = moment(date).format("YYYY-MM-DD");
        hotel_form_state.isNew = true;

        setHotelSearchForm(hotel_form_state);
    }
    const handleRoomsChange = (e) => {
        let hotel_form_state = { ...hotelSearchForm};
        console.log(hotel_form_state);
        hotel_form_state.rooms = e.target.value;
        hotel_form_state.isNew = true;

        setHotelSearchForm(hotel_form_state);
    }
    const handleGuestsChange = (e) => {
        let hotel_form_state = { ...hotelSearchForm};
        console.log(hotel_form_state);
        hotel_form_state.adults = e.target.value;
        hotel_form_state.isNew = true;

        setHotelSearchForm(hotel_form_state);
    }
    const handleSearchCities = (query) => {
        //console.log(`PlanTripFormDestinationElement handleSearchDestinations isRoadTrip =${isRoadTrip}`)
        getCitiesList(query)
    }
    
    const setSelectedCity = (selectedItem) => {
        let hotel_form_state = { ...hotelSearchForm}
        
        if (selectedItem.length) {
            hotel_form_state.city_id = selectedItem[0].cityid_ppn;
            let city = selectedItem[0].city !== undefined ? selectedItem[0].city + ', ' : ""
            let state = selectedItem[0].state !== undefined ? selectedItem[0].state + ', ' : ""
            let country = selectedItem[0].country !== undefined ? selectedItem[0].country : ""
            hotel_form_state.where = city  + state  + country;
            hotel_form_state.city_latitude = selectedItem[0].latitude;
            hotel_form_state.city_longitude = selectedItem[0].longitude;
            hotel_form_state.selectedCity = selectedItem;
            hotel_form_state.isNew = true;
            
            Object.assign(hotel_form_state, {
                hotel_name: selectedItem[0]?.hotel_name || '',
                address: {
                    city_name: selectedItem[0]?.address?.city_name || '',
                    country_code: selectedItem[0]?.address?.country_code || ''
                },
                type:  selectedItem[0].type  || "",
                hotelid_ppn: selectedItem[0]?.hotelid_ppn || ""
            });

            const where = city  + state  + country;
            hotel_form_state.where = where || '';
            if(selectedItem[0] && selectedItem[0]?.type === 'hotel'){
                city = selectedItem[0]?.address.city_name? selectedItem[0]?.address.city_name + ', ' : ""
                state = selectedItem[0]?.address?.state_name? selectedItem[0]?.address?.state_name+ ', ' : ""
                country = selectedItem[0]?.address?.country_code?  selectedItem[0]?.address?.country_code : ""
               const coords = selectedItem[0]?.coordinate?.split(',');
              
               if(coords && coords.length > 0 ){
                hotel_form_state.city_latitude = coords[0];
                hotel_form_state.city_longitude = coords[1];
               }
               
               hotel_form_state.where = selectedItem[0]?.hotel_name

           }
           

            setHotelSearchForm(hotel_form_state);

        } else {
            hotel_form_state.city_id = '';
            hotel_form_state.where = '';
            hotel_form_state.city_latitude = '';
            hotel_form_state.city_longitude = '';
            hotel_form_state.selectedCity = [];
            Object.assign(hotel_form_state, {
                hotel_name:'',
                address: {
                    city_name: '',
                    country_code: ''
                },
                type: "",
                hotelid_ppn: ""
            });
            setHotelSearchForm(hotel_form_state);
            
        }
    }

    let  [,setState]=useState();
    const selectedCurrency = useSelector(state => state.Setting.currency.selected.shortCode);
    const fullCurrency =  selectedCurrency ? selectedCurrency.toUpperCase(): "CAD";



    React.useEffect(() => { 
        if (hotelSearchForm.isSearching && hotelSearchForm.city_id !== "") {
            console.log("setCheckInDate",hotelSearchForm.check_in,{selectedCity})
            setCheckInDate(new Date(hotelSearchForm.check_in))
            setCheckOutDate(new Date(hotelSearchForm.check_out))
            setSelectedCity([selectedCity])     
            setState({})
            //console.log("selectedCity",hotelSearchForm.check_in,{selectedCity})
        }
    }, [hotelSearchForm.isSearching])
    
    React.useEffect(() => { 
       if(selectedCurrency  && defaultCurrency){
            handleHotelSearchForm()
       }
    }, [selectedCurrency])

   

    React.useEffect(() => { 
        setDefaultCurrency(selectedCurrency)
    }, [])

  

    const handleHotelSearchForm = () => {
        
        let hotel_form_state = { ...hotelSearchForm }
        // if query and dates are entered, do search

        const rooms = (hotel_form_state.rooms ? hotel_form_state.rooms : 1) 
        const guests = (hotel_form_state.adults ? hotel_form_state.adults : 2)
        const obj = {
            rooms:hotel_form_state.rooms,
            adults:hotel_form_state.adults,
            isNew: true,
            isSearching: true,
        }


        hotel_form_state = Object.assign(hotel_form_state, obj )

        if(hotel_form_state.city_id !== "" && hotel_form_state.check_in !== ""  && hotel_form_state.check_out !== ""){
            //console.log({hotel_form_state,embedForm,bookingForm});
           
            setHotelSearchForm(hotel_form_state);

            // open new page with params
            //setSwitchContainer(member_area_router.destination)
            history.push("/hotel-search?city_id=" + hotel_form_state.city_id 
                        + "&check_in=" + hotel_form_state.check_in 
                        + "&check_out=" +hotel_form_state.check_out 
                        + "&rooms=" + (hotel_form_state.rooms ? hotel_form_state.rooms : 1) 
                        + "&guests=" + (hotel_form_state.adults ? hotel_form_state.adults : 2)
                        + "&city_lat=" + (hotel_form_state.city_latitude)
                        + "&city_lng=" + (hotel_form_state.city_longitude)
                        + "&hotels=" + hotel_form_state?.hotelid_ppn
                        + "&where=" + (hotel_form_state.where.replace(URL_REPLACE, "_")))
            // http://host/hotel-search?city_id=800049480&check_in=2020-02-02&check_out=2020-02-13&room=1&guests=2  
        
            //setHotelSearchState({city_id, check_in, check_out, rooms, adults, where})
            
            
            if(embedForm=="results") {console.log({hotel_form_state,embedForm,bookingForm});
            getPriceLineHotels(hotel_form_state, lazyLoading,fullCurrency)
        
        }     
            
            // FormBankAction  [fetchPricelineHotels]
            
        }
        
    }

    // const where = hotel_form_state?.where.replace(URL_REPLACE, "_");

    const isHotelSearch = hotelSearchForm?.featuredHotels &&  hotelSearchForm?.featuredHotels.length > 0;
   
    return (
        <React.Fragment>                            
            <div className="row m-0 p-0">
                <div className="col-12 col-md-11 p-0">                
                    <div className="row m-0 p-0">
                                
                        <div className="col-12 col-md-4 mt-3 p-0">                
                            <div className="position-relative">
                                <AsyncTypeahead
                                    id="hotel-cities-typeahead"
                                    //defaultInputValue= {hotelSearchForm.where}
                                    defaultSelected = {selectedCity!==null ? [selectedCity]:[]}
                                    labelKey={(option) =>  {
                                        if(option?.hotel_name){
                                           return `${option?.hotel_name} - ${option?.address?.city_name}${option?.address?.country_code ? ", " + option?.address?.country_code : ""}`
                                        }
                                        if(isHotelSearch && !option?.hotel_name){
                                            return `${option?.city}`
                                        }
                                        return  option.state !== undefined ? `${option.city}, ${option.state}, ${option.country}`
                                        : `${option.city}, ${option.country}`
                                    }}
                                    onChange={val => setSelectedCity(val)}
                                    options={searchListCities}
                                    placeholder="Choose your destination"
                                    shouldSelect={true}
                                    minLength={1}
                                    onSearch={query => handleSearchCities(query)}
                                    renderMenu={(results, menuProps) => {
                                        let index = 0;
                                        const types = groupBy(results, 'type');
                                        const items = Object.keys(types)
                                            .sort()
                                            .map((option) => (
                                                <React.Fragment key={option}>
                                                    {index !== 0 && <Menu.Divider />}
                                                    <Menu.Header style={{
                                                        font: "16px/30px Futura Md BT",
                                                        color: 'black'
                                                    }}>
                                                        {option === 'hotel' ? "Hotels" : "Destinations"}
                                                    </Menu.Header>
                                                    {types[option].map((value) => {

                                                        let optionText;


                                                        if (value?.type === "city") {
                                                            optionText = value.state !== undefined && value.state !== "" ?
                                                                `${value?.city}, ${value.state}, ${value.country}` :
                                                                `${value?.city}, ${value.country}`;
                                                        }
                                                        else {
                                                            optionText =
                                                                `${value?.hotel_name} - ${value?.address?.city_name}${value?.address?.country_code ? ", " + value?.address?.country_code : ""}`
                                                        }


                                                        let item = (
                                                            <MenuItem key={index} option={value} position={index}>
                                                                {optionText}
                                                            </MenuItem>
                                                        );

                                                        index += 1;
                                                        return item;
                                                    })}
                                                </React.Fragment>
                                            ));

                                        return <Menu {...menuProps}>{items}</Menu>;


                                    }
                                    }
                                    isLoading={false}
                                    size={'large'}
                                    delay={100}
                                    clearButton={true}
                                />
                                <div className="position-absolute" style={{ top: is_mobile ? "10px" : "14px", left: "7px" }}>
                                    <AddressMapIcon width={15} height={15} />
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-md-2 mt-3 p-0 hotelDates">
                            <div className="position-relative" >
                                <DatePicker
                                    selected={checkInDate}
                                    onChange={date => handleChangeCheckInDate(date)}
                                    placeholderText="Check-In date"
                                    dateFormat="yyyy/MM/dd"
                                    minDate={new Date()} 
                                    maxDate={new Date(checkOutDate)}                                        
                                    className="form-control" />
                                <div className="position-absolute" style={{ top: is_mobile ? "12px" : "14px", left: "7px" }}>
                                    <CalendarDuotoneIcon width={15} height={15} />
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-md-2 mt-3 p-0 hotelDates">
                            <div className="position-relative" >
                                <DatePicker
                                    selected={checkOutDate} 
                                    minDate={checkInDate !== '' ? new Date(checkInDate).getTime() + 86400000 : new Date()}
                                    maxDate={null}  // 21 day limit      
                                    dateFormat="yyyy/MM/dd" 
                                    onChange={date => handleChangeCheckOutDate(date)} 
                                    placeholderText="Check-Out date" 
                                    className="form-control" />
                                <div className="position-absolute" style={{ top: is_mobile ? "12px" : "14px", left: "7px" }}>
                                    <CalendarDuotoneIcon width={15} height={15} />
                                </div>
                            </div>
                        </div>
                        

                        <div className="col-6 col-md-2 mt-3 p-0">
                            <select className="form-control pl-3" id="rooms" placeholder="Rooms" 
                                defaultValue={hotelSearchForm.rooms != "" ? hotelSearchForm.rooms : "1"} 
                                onChange={e => handleRoomsChange(e)} >
                                <option value="placeholder" disabled>Rooms</option>
                                <option value="1">1 Room</option>
                                <option value="2">2 Rooms</option>
                                <option value="3">3 Rooms</option>
                                <option value="4">4 Rooms</option>
                                <option value="5">5 Rooms</option>
                                <option value="6">6 Rooms</option>
                                <option value="7">7 Rooms</option>
                                <option value="8">8 Rooms</option>
                                <option value="9">9 Rooms</option>
                            </select>
                        </div>
                        <div className="col-6 col-md-2 mt-3 p-0">
                            <select className="form-control pl-3" id="guests" placeholder="Guests" 
                                defaultValue={hotelSearchForm.adults != "" ? hotelSearchForm.adults : "2"} 
                                onChange={e => handleGuestsChange(e)} >
                                <option value="placeholder" disabled>Guests</option>
                                <option value="1">1 Guest</option>
                                <option value="2">2 Guests</option>
                                <option value="3">3 Guests</option>
                                <option value="4">4 Guests</option>
                                <option value="5">5 Guests</option>
                                <option value="6">6 Guests</option>
                                <option value="7">7 Guests</option>
                                <option value="8">8 Guests</option>
                                <option value="9">9 Guests</option>
                            </select>
                        </div> 
                    </div>
                </div>
                <div className="col-12 col-md-1 p-0">
                    <div className="row m-0 p-0">   
                        <div className="col-12 p-0">
                            <button disabled={disableButton} className="btn btn-default submitBtn col-12 mt-3 p-0" 
                                    onClick={() => handleHotelSearchForm()}>
                                Search
                            </button>
                        </div>
                    </div>

                </div>
            </div>
        </React.Fragment>
    )

}


const mapStateToProps = (state) => ({
    lang: state.Setting.lang,
    uuid: state.Member.authModal.uuid,
    activeTrip: state.FormBank.TimelineWizard.activeTrip,
    switch_container: state.Member.switch_container,
    searchListCities: state.Home.search_list_cities,
    hotelSearchForm: state.FormBank.HotelSearch.hotel_form_state,
    lazyLoading: state.FormBank.HotelSearch.lazyLoading,
    bookingForm: state.FormBank.BookingForm
})

const mapDispatchToProps = {
    //createMultipleDestinationTrip: Actions.createMultipleDestinationTrip,
    setBookingFormStatus: Actions.setBookingFormStatus,
    setSwitchContainer: Actions.setSwitchContainer,

    getCitiesList: Actions.getPriceLineCitiesList,      // HomeAction.js, PL_FETCH_CITIES_LIST_SUCCESS
    //getCitiesList: Actions.getCitiesList,               // HomeAction.js, FETCH_CITIES_LIST_SUCCESS
    getAirportList: Actions.getPriceLineAirportList,    // HomeReducer.js

    setHotelSearchForm: Actions.setHotelSearchForm,     // FormBankActions, FormBankReducer    
    getPriceLineHotels: Actions.getPriceLineHotels,

}

const HotelSearchFormEmbedded = connect(mapStateToProps, mapDispatchToProps)(HotelSearchFormEmbedded_)
export default HotelSearchFormEmbedded
