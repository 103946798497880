import React from 'react';
import FlightForm from '../Common/BookingForm/FlightForm';

export const FlightSearchComponent = ({bodyClientWidth}) =>{
    return (
        <div style={{
            height: "auto",
            backgroundColor: "#F5F7F6",
            padding: "30px",
        }}
            className="background-img-row booking_form_container flightsSearch">
            
            <FlightForm />
        </div>
    )
}

