import * as Actions from '../../../redux/actions'

import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Divider from '@material-ui/core/Divider'
import Grow from '@material-ui/core/Grow'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import React from 'react'
import { connect } from "react-redux"
import { menuListMemberAreaStyles } from "./ComponentsStylingBank"

export const Currency = connect((state) => ({
    currency: state.Setting.currency,
    is_mobile: state.Setting.is_mobile,
    token: state.Member.authModal.token,
    tokenCurrency: state.Member.authModal.currency,
    textColor: state.Setting.appMenu.textColor,
    bodyClientWidth: state.Setting.htmlBody.bodyClientWidth,

    logo_width: state.Setting.appMenu.logo.width,
}), {
    setCurrentCurrency: Actions.setCurrentCurrency,
    fetchCurrencySets: Actions.fetchCurrencySets,
})(({
    token,
    currency,
    setCurrentCurrency,
    fetchCurrencySets,
    tokenCurrency,
    textColor,
    is_mobile,
    logo_width,
    bodyClientWidth,
    fetch_currency = currency !== undefined && !currency.isFetched && fetchCurrencySets(),
}) => {
    const classes = menuListMemberAreaStyles()
    const [open, setOpen] = React.useState(false)
    const anchorRef = React.useRef(null)

    const handleToggle = () => {
        setOpen(prevOpen => !prevOpen)
    }

    const handleClose = event => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return
        }

        setOpen(false)
    }
    const handlerOnclick = (currency) => {
        setCurrentCurrency(currency, token)
        setOpen(false)
    }


    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open)
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus()
        }

        prevOpen.current = open
    }, [open])


    return (
        <div className={classes.root}>
            <div>
                <div
                    ref={anchorRef}
                    aria-controls={open ? 'menu-list-sort-trips' : undefined}
                    aria-haspopup="true"
                    onClick={handleToggle}>
                    {!is_mobile && <div style={{
                        width: "50px",
                        textAlign: "left",
                        font: "16px/36px Futura Md BT",
                        letterSpacing: "0",
                        color: textColor,
                        cursor: "pointer",
                        paddingLeft: "16px",
                    }}
                    >
                        {currency.selected.name + currency.selected.symbol}
                    </div>}
                    {is_mobile && <div style={{
                        width: logo_width + "px",
                        textAlign: "center",
                        font: "14px/23px Futura Md BT",
                        letterSpacing: "0",
                        color: textColor,
                        cursor: "pointer",
                        paddingLeft: "0px",
                    }}
                    >
                        {currency.selected.name + currency.selected.symbol}
                    </div>}
                    {is_mobile && <div style={{
                        font: "8px/28px Futura Lt BT",
                        color: textColor,
                        outline: "0"
                    }}>Currency</div>}
                </div>
                <ClickAwayListener onClickAway={handleClose}>
                    <Popper open={open} anchorEl={anchorRef.current} role={undefined} className={classes.zIndex}
                        placement={bodyClientWidth > 500 ? "bottom-start" : "right-start"}
                        modifiers={{
                            flip: {
                                enabled: false,
                            },
                        }}
                        transition disablePortal>
                        {({ TransitionProps, placement }) => (
                            <Grow
                                {...TransitionProps}
                                style={{ transformOrigin: placement === 'bottom' ? 'left top' : 'left bottom' }}
                            >
                                <Paper>
                                    <MenuList autoFocusItem={open} id="menu-list-sort-trips" >

                                        {currency !== undefined && currency.currencySets !== undefined && currency.currencySets.length > 0 &&
                                            currency.currencySets.map((currency, currency_idx) => {
                                                return (
                                                    <div key={currency_idx} >
                                                        {currency_idx !== 0 && <Divider light />}
                                                        <MenuItem className={"btn btn-sm flex-wrap member_menu_top_btn member_menu"} onClick={() => handlerOnclick(currency)}>{currency.name + currency.symbol}</MenuItem>
                                                    </div>
                                                )
                                            })}
                                    </MenuList>
                                </Paper>
                            </Grow>
                        )}
                    </Popper>

                </ClickAwayListener>
            </div>
        </div>
    )
})
