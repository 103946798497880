import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit'
import { environment } from '../../../Environments/environment';
import { ALERT_BARS_HANDLE, GetAsyncHelper, PostAsyncHelper } from '../../../redux/interfaces/types';


const initialMuvTribe = {
  friends: [],
  followers: [],
  following: [],
  relatedInfluencers: [],
  friendRequests: []
}
const initialState = {
  photos: [],
  videos: [],
  homeFavourites: [],
  travelFavourites: [],
  pendingRequests: [],
  muvTribe: initialMuvTribe
}


export const getSocialPhotos = createAsyncThunk(
  'SocialPage/getSocialPhotos',
  async (query, thunkAPI) => {
    try {
      const state = thunkAPI.getState();
      const uuid = query;

      const post = {
        limit: 20,
        uuid: uuid,
        offset: 0,
      }

      let apiURL = environment.apiUrl + `media/getUgcImages?uuid=${uuid}`;

      const response = await GetAsyncHelper(apiURL);
      if (response && response.statusText === "OK") {
        return response?.data?.result;
      }
    }
    catch {
      return null;
    }
  }
)


export const getSocialVideos = createAsyncThunk(
  'SocialPage/getSocialVideos',
  async (query, thunkAPI) => {
    try {
      const state = thunkAPI.getState();
      const uuid = query;


      const post = {
        limit: 20,
        uuid: uuid,
        offset: 0,
      }

      let apiURL = environment.apiUrl + `media/getUgcVideos?uuid=${uuid}`;

      const response = await GetAsyncHelper(apiURL);
      if (response && response.statusText === "OK") {
        return response?.data?.result;
      }
    }
    catch {
      return null;
    }
  }
)


export const deleteSocialPhotos = createAsyncThunk(
  'SocialPage/DeleteSocialPhotos',
  async (query, thunkAPI) => {
    const uuid = query.uuid;
    const photoId = query.photoId;
    const dispatch = thunkAPI.dispatch;
    try {
      let apiURL = environment.apiUrl + `media/deleteUploadedPhoto?uuid=${uuid}&userImageId=${photoId}`
      const response = await PostAsyncHelper(apiURL);
      if (response && response.data) {

        dispatch({
          type: ALERT_BARS_HANDLE,
          message_type: "success",
          message: "Your photo has been deleted"
        })
        dispatch(getSocialPhotos(uuid))
        return response.data;
      }
      dispatch({
        type: ALERT_BARS_HANDLE,
        message_type: "error",
        message: "Photo was not deleted"
      })

    }
    catch {
      dispatch({
        type: ALERT_BARS_HANDLE,
        message_type: "error",
        message: "Photo was not deleted"
      })
      return null;
    }
  }
)

export const deleteSocialVideos = createAsyncThunk(
  'SocialPage/DeleteSocialVideos',
  async (query, thunkAPI) => {
    const uuid = query.uuid;
    const videoId = query.videoId;
    const dispatch = thunkAPI.dispatch;
    try {
      let apiURL = environment.apiUrl + `media/deleteUploadedVideo?uuid=${uuid}&userVideoId=${videoId}`
      const response = await PostAsyncHelper(apiURL);
      if (response && response.data) {


        dispatch({
          type: ALERT_BARS_HANDLE,
          message_type: "success",
          message: "Your video has been deleted"
        })
        dispatch(getSocialVideos(uuid))
        return response.data;
      }
      dispatch({
        type: ALERT_BARS_HANDLE,
        message_type: "error",
        message: "Video was not deleted"
      })

    }
    catch {
      dispatch({
        type: ALERT_BARS_HANDLE,
        message_type: "error",
        message: "Video was not deleted"
      })
      return null;
    }
  }
)

export const inviteFriends = createAsyncThunk(
  'SocialPage/inviteFriends',
  async (query, thunkAPI) => {
    const uuid = query.uuid;
    const emails = query.emails;
    const dispatch = thunkAPI.dispatch;
    try {
      let apiURL = environment.apiUrl + `memberarea/inviteFriendsToMuv/${uuid}?emails=${emails}`
      const response = await PostAsyncHelper(apiURL);
      if (response && response.data) {
        dispatch({
          type: ALERT_BARS_HANDLE,
          message_type: "success",
          message: "Invite email sent!"
        })
        return response.data;
      }

      dispatch({
        type: ALERT_BARS_HANDLE,
        message_type: "error",
        message: "Invite email not sent"
      })


    }
    catch {
      dispatch({
        type: ALERT_BARS_HANDLE,
        message_type: "error",
        message: "Invite email not sent"
      })
      return null;
    }
  }
)


export const getHomeFavourites = createAsyncThunk(
  'SocialPage/getHomeFavourites',
  async (query, thunkAPI) => {
    try {
      const state = thunkAPI.getState();
      const uuid = query;


      let apiURL = environment.apiUrl + `publicprofile/getPublicMemberFavorites/${uuid}/en?homeonly=1`;

      const response = await GetAsyncHelper(apiURL);
      if (response && response.statusText === "OK") {
        return response?.data?.homeFavorites;
      }
    }
    catch {
      return null;
    }
  }
)

export const getTravelFavourites = createAsyncThunk(
  'SocialPage/getTravelFavourites',
  async (query, thunkAPI) => {
    try {
      const state = thunkAPI.getState();
      const uuid = query;


      let apiURL = environment.apiUrl + `publicprofile/getPublicMemberFavorites/${uuid}/en?homeonly=0`;

      const response = await GetAsyncHelper(apiURL);
      if (response && response.statusText === "OK") {
        return response?.data?.homeFavorites;
      }
    }
    catch {
      return null;
    }
  }
)


export const getPendingFriendRequests = createAsyncThunk(
  'SocialPage/getPendingFriendRequests',
  async (query, thunkAPI) => {
    try {
      const state = thunkAPI.getState();
      const uuid = query;
      let apiURL = environment.apiUrl + `memberFriends/getSentFriendRequests/${uuid}`;

      const response = await GetAsyncHelper(apiURL);
      if (response && response.statusText === "OK") {
        return response?.data;
      }
    }
    catch {
      return null;
    }
  }
)



export const getRelatedInfluencers = createAsyncThunk(
  'SocialPage/getRelatedInfluencers',
  async (query, thunkAPI) => {
    try {
      const state = thunkAPI.getState();
      const uuid = query;
      let apiURL = environment.apiUrl + `getMuverRelatedInfluencers/${uuid}`;
      // let apiURL = "https://api.muvtravel.com/api/public/" + `memberFriends/getFriends/${uuid}`;

      const response = await GetAsyncHelper(apiURL);
      if (response && response.statusText === "OK") {
        return response?.data?.result;
      }
    }
    catch {
      return null;
    }
  }
)


export const getUserFriends = createAsyncThunk(
  'SocialPage/getUserFriends',
  async (query, thunkAPI) => {
    try {
      const state = thunkAPI.getState();
      const uuid = query;
      let apiURL = environment.apiUrl + `memberFriends/getFriends/${uuid}`;
      // let apiURL = "https://api.muvtravel.com/api/public/" + `memberFriends/getFriends/${uuid}`;

      const response = await GetAsyncHelper(apiURL);
      if (response && response.statusText === "OK") {
        return response?.data;
      }
    }
    catch {
      return null;
    }
  }
)

export const getUserFollowers = createAsyncThunk(
  'SocialPage/getUserFollowers',
  async (query, thunkAPI) => {
    try {
      const state = thunkAPI.getState();
      const uuid = query;
      let apiURL = environment.apiUrl + `getMuverFollow/${uuid}?followType=followed`;
      // let apiURL = "https://api.muvtravel.com/api/public/" + `memberFriends/getFriends/${uuid}`;

      const response = await GetAsyncHelper(apiURL);
      if (response && response.statusText === "OK") {
        return response?.data?.result;
      }
    }
    catch {
      return null;
    }
  }
)

export const getUserFollowing = createAsyncThunk(
  'SocialPage/getUserFollowing',
  async (query, thunkAPI) => {
    try {
      const state = thunkAPI.getState();
      const uuid = query;
      let apiURL = environment.apiUrl + `getMuverFollow/${uuid}?followType=following`;
      // let apiURL = "https://api.muvtravel.com/api/public/" + `memberFriends/getFriends/${uuid}`;

      const response = await GetAsyncHelper(apiURL);
      if (response && response.statusText === "OK") {
        return response?.data?.result;
      }
    }
    catch {
      return null;
    }
  }
)


export const getFriendRequests = createAsyncThunk(
  'SocialPage/getFriendRequests',
  async (query, thunkAPI) => {
    try {
      const state = thunkAPI.getState();
      const uuid = query;
      let apiURL = environment.apiUrl + `memberFriends/FriendRequests/${uuid}`;

      const response = await GetAsyncHelper(apiURL);
      if (response && response.statusText === "OK") {
        return response?.data;
      }
    }
    catch {
      return null;
    }
  }
)


export const ModifyFriendRequest = createAsyncThunk(
  'SocialPage/ModifyFriendRequest',
  async (query, thunkAPI) => {
    const uuid = query.uuid;
    const accept = query.accept;
    const friendID = query.friendID;
    const dispatch = thunkAPI.dispatch;
    const url = accept ? "confirmFriendRequest" : "removeFriendRequest";

    try {
      let apiURL = environment.apiUrl + `memberFriends/${url}?uuid=${uuid}&friendID=${friendID}`
      const response = await PostAsyncHelper(apiURL);
      if (response && response.data
        && response?.data?.status !== "fail") {
        dispatch({
          type: ALERT_BARS_HANDLE,
          message_type: "success",
          message: `Friend request was successfully ${accept ? "accepted" : "rejected"}`
        })

        return response.data;
      }
      dispatch({
        type: ALERT_BARS_HANDLE,
        message_type: "error",
        message: response?.data?.message || "Error, Please try again later"
      })

    }
    catch (error) {
      dispatch({
        type: ALERT_BARS_HANDLE,
        message_type: "error",
        message: "Error, Please try again later"
      })
      return null;
    }
  }
)


export const SendFriendRequest = createAsyncThunk(
  'SocialPage/SendFriendRequest',
  async (query, thunkAPI) => {
    const uuid = query.uuid;
    const member_id = query.member_id;
    const member_name = query.member_name;
    const dispatch = thunkAPI.dispatch;

    try {
      let apiURL = environment.apiUrl + `memberFriends/addFriendRequest?uuid=${uuid}&friendID=${member_id}`
      const response = await PostAsyncHelper(apiURL);
      if (response && response.data) {


        dispatch({
          type: ALERT_BARS_HANDLE,
          message_type: "success",
          message: `Your friend request was successfully sent to ${member_name}`
        })

        return response.data;
      }
      dispatch({
        type: ALERT_BARS_HANDLE,
        message_type: "error",
        message: "Your friend request was not sent. Please try again later"
      })

    }
    catch {
      dispatch({
        type: ALERT_BARS_HANDLE,
        message_type: "error",
        message: "Your friend request was not sent. Please try again later"
      })
      return null;
    }
  }
)


export const ContactUsThunk = createAsyncThunk(
  'SocialPage/ContactUsThunk',
  async (query, thunkAPI) => {
    const email = query?.email;
    const firstName = query?.firstName;
    const lastName = query?.lastName;
    const message = query?.message;
    const subject = query?.subject;

    const dispatch = thunkAPI.dispatch;
    try {

      if(email && firstName && lastName && message && subject){
        
        let apiURL = environment.apiUrl + `notifications/contactUs?
        email=${email}&firstName=${firstName}&lastName=${lastName}&message=${message}&subject=${subject}`
        const response = await PostAsyncHelper(apiURL);
        if (response && response.data) {
  
  
          dispatch({
            type: ALERT_BARS_HANDLE,
            message_type: "success",
            message: "Message sent"
          })
          return response.data;
        }
        dispatch({
          type: ALERT_BARS_HANDLE,
          message_type: "error",
          message: response || "Message not sent"
        })
  
      }
     
    }
    catch {
      dispatch({
        type: ALERT_BARS_HANDLE,
        message_type: "error",
        message: "Message not sent"
      })
      return null;
    }
  }
)





export const SocialSlice = createSlice({
  name: 'SocialPage',
  initialState: initialState,
  reducers: {
    clearMuvtribe: (state, action) => {
      state.muvTribe = initialMuvTribe
    },
  },
  extraReducers: {
    [getSocialPhotos.pending]: (state, action) => {
    },
    [getSocialPhotos.fulfilled]: (state, action) => {
      state.photos = action.payload;
    },
    [getSocialPhotos.rejected]: (state, action) => {
    },
    [getSocialVideos.fulfilled]: (state, action) => {
      state.videos = action.payload;
    },
    [getTravelFavourites.fulfilled]: (state, action) => {
      state.travelFavourites = action.payload;
    },
    [getTravelFavourites.rejected]: (state, action) => {
    },
    [getHomeFavourites.fulfilled]: (state, action) => {
      state.homeFavourites = action.payload;
    },
    [getHomeFavourites.rejected]: (state, action) => {
    },
    [SendFriendRequest.fulfilled]: (state, action) => {
      state.homeFavourites = action.payload;
    },
    [SendFriendRequest.rejected]: (state, action) => {
    },
    [getPendingFriendRequests.fulfilled]: (state, action) => {
      state.pendingRequests = action.payload;
    },
    [getPendingFriendRequests.rejected]: (state, action) => {
    },
    [getUserFriends.fulfilled]: (state, action) => {
      state.muvTribe.friends = action.payload;
    },
    [getUserFriends.rejected]: (state, action) => {
      state.muvTribe.friends = []
    },
    [getUserFollowers.fulfilled]: (state, action) => {
      state.muvTribe.followers = action.payload;
    },
    [getUserFollowers.rejected]: (state, action) => {
      state.muvTribe.followers = []
    },
    [getUserFollowing.fulfilled]: (state, action) => {
      state.muvTribe.following = action.payload;
    },
    [getUserFollowing.rejected]: (state, action) => {
      state.muvTribe.following = []
    },
    [getRelatedInfluencers.fulfilled]: (state, action) => {
      state.muvTribe.relatedInfluencers = action.payload;
    },
    [getRelatedInfluencers.rejected]: (state, action) => {
      state.muvTribe.relatedInfluencers = []
    },
    [getFriendRequests.fulfilled]: (state, action) => {
      state.muvTribe.friendRequests = action.payload;
    },
    [getFriendRequests.rejected]: (state, action) => {
      state.muvTribe.friendRequests = []
    },
  },

})


export const { clearMuvtribe } = SocialSlice.actions
export const SocialPageReducer = SocialSlice.reducer;