import React from 'react'
import { useDispatch } from 'react-redux'
import { ALERT_BARS_HANDLE } from '../../../redux/interfaces/types'
import { ContactUsThunk } from '../SocialPage/social.slice'


export const ContactUsForm = ({ }) => {
    const dispatch = useDispatch()

    const initialForm = {
        firstName: "",
        lastName: "",
        email: "",
        subject: "",
        message: ""
    }
    const [form, setForm] = React.useState(initialForm)

    const updateForm = (field, val) => {
        setForm({ ...form, [field]: val })
    }

    const contact = async () => {
        if (form.email && form.firstName && form.lastName && form.subject && form.message) {
            const response = await dispatch(ContactUsThunk(form))
            const success = response?.payload?.status === "success" || response?.meta?.requestStatus === "fulfilled";
            if (success) {
                setForm(initialForm)
            }
        }
        else {

            dispatch({
                type: ALERT_BARS_HANDLE,
                message_type: "error",
                message: "All fields are required"
            })
        }
    }


    return (
        <div>


            <div style={{ display: 'flex' }}>
                <div style={{ width: '100%', marginBottom: 10, marginRight: 10 }}>
                    <div style={{
                        font: '20px/30px Futura Lt BT',
                        color: "white"
                    }}>
                        First name

            </div>

                    <input style={{
                        height: 50, background: 'white',
                        width: '100%',
                        paddingLeft: 10
                    }}
                        value={form.firstName}
                        onChange={(event) => {
                            const val = event.target.value
                            updateForm('firstName', val)
                        }}>
                    </input>
                </div>

                <div style={{ width: '100%' }}>
                    <div style={{
                        font: '20px/30px Futura Lt BT',
                        color: "white",
                    }}>
                        Last name

            </div>

                    <input style={{
                        height: 50, background: 'white',
                        width: '100%', paddingLeft: 10
                    }}
                    value={form.lastName}
                        onChange={(event) => {
                            const val = event.target.value
                            updateForm('lastName', val)
                        }}>
                    </input>

                </div>
            </div>


            <div style={{ width: '100%', marginBottom: 10, marginRight: 0 }}>
                <div style={{
                    font: '20px/30px Futura Lt BT',
                    color: "white"
                }}>
                    Email

            </div>

                <input style={{
                    height: 50, background: 'white',
                    width: '100%', paddingLeft: 10

                }}
                value={form.email}
                    onChange={(event) => {
                        const val = event.target.value
                        updateForm('email', val)
                    }}>
                </input>
            </div>


            <div style={{ width: '100%', marginBottom: 10, marginRight: 0 }}>
                <div style={{
                    font: '20px/30px Futura Lt BT',
                    color: "white"
                }}>
                    Subject

            </div>

                <input style={{
                    height: 50, background: 'white',
                    width: '100%', paddingLeft: 10

                }}
                value={form.subject}
                    onChange={(event) => {
                        const val = event.target.value
                        updateForm('subject', val)
                    }}>
                </input>
            </div>



            <div style={{ width: '100%', marginBottom: 10, marginRight: 0 }}>
                <div style={{
                    font: '20px/30px Futura Lt BT',
                    color: "white"
                }}>
                    Message

            </div>

                <textarea style={{
                    height: 200, background: 'white',
                    width: '100%',
                    paddingLeft: 10,
                    paddingTop: 10,
                    font: '18px/30px Futura Lt BT',

                }}
                value={form.message}
                    onChange={(event) => {
                        const val = event.target.value
                        updateForm('message', val)
                    }}>
                </textarea>

                <div className="" >
                    <button onClick={contact} className='recapButtons' style={{
                        opacity: false ? 0.4 : 1, background: '#19BC9B',
                        color: 'white', font: '14px/14px Futura Hv BT',
                    }} >
                        Send
                        </button>
                </div>

            </div>

        </div>
    )


}