import * as Actions from '../../../redux/actions'

import Button from '@material-ui/core/Button'
import { DeleteIcon } from "./SVGBank"
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import React from 'react'
import { connect } from "react-redux"
import { list_container_mode } from '../Common/RegExValidate'
import { member_area_router } from '../Common/RegExValidate'

/* delete dialog function component */
const DeleteFromListDialog_ = ({fillColor, uuid,userAction, unitID, feature, deletingStar, setOpenTripEllipsis = () => { }, deleteTrip, setSwitchContainer, setTripDisplayMode, from, toggle_ellipsis_close_disable, switch_container,
  token, cookiesMemberActionSets, hideTitle }) => {
  const [open, setOpen] = React.useState(false)
  /* open dialog */
  const handleClickOpen = () => {
    setOpen(true)
  }
  /* close dialog */
  const handleClose = () => {
    setOpen(false)
  }



  const handleDeletingStar = (uuid, unitID, feature, cookiesMemberActionSets) => {
    deletingStar(uuid, unitID, feature, cookiesMemberActionSets,userAction)
   setSwitchContainer(member_area_router.starred)
    // setTripDisplayMode(list_container_mode.schedule)
    setOpenTripEllipsis && setOpenTripEllipsis(false);
    handleClose()
  }

  return (
    <React.Fragment>
      <div>
        <div onClick={handleClickOpen}>
          <DeleteIcon  fill={fillColor} /> {toggle_ellipsis_close_disable || hideTitle ? "" : from}
        </div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {"Do you want to " + from + "?"}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} >
              Cancel
            </Button>
            <Button onClick={() => handleDeletingStar(uuid, unitID, feature, cookiesMemberActionSets)} color="secondary" autoFocus >
              <DeleteIcon />{"Remove"}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = state => ({
  uuid: state.Member.authModal.uuid,
  trip: state.Member.memberArea.selectedTrip,
  toggle_ellipsis_close_disable: state.Member.memberArea.toggle_ellipsis_close_disable,
  switch_container: state.Member.switch_container,

  token: state.Member.authModal.token,
  alartModalStatusDeleting: state.FormBank.TimelineWizard.alartModalStatusDeleting,
  activeTrip: state.FormBank.TimelineWizard.activeTrip,
  cookiesMemberActionSets: state.MemberActions.cookiesMemberActionSets,
})

const mapDispatchToProps = {
  /* member area */
  deletingStar: Actions.deletingStar,

  setSwitchContainer: Actions.setSwitchContainer,
  setTripDisplayMode: Actions.setTripDisplayMode,
  /* end member area */

  /* timeline */
  setOpenAuthModal: Actions.setOpenAuthModal,
  setTimelineWizardDeleteAlartStatus: Actions.setTimelineWizardDeleteAlartStatus,

  deletingHeart: Actions.deletingHeart,

}


const DeleteFromListDialog = connect(mapStateToProps, mapDispatchToProps)(DeleteFromListDialog_)
export default DeleteFromListDialog 
