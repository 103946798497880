import * as Actions from '../../../../redux/actions'

import {
    CheckedBoxReglarIcon,
    UnCheckBoxReglarIcon,
} from '../SVGBank'

import React from 'react'
import { connect } from 'react-redux'
import { member_area_router } from "../../Common/RegExValidate"

export const TripType = ({ fetchTripTypesSets, switch_container, tripTypeSets, selectedTypeId, setTripType }) => {
    const [loaded, setLoaded] = React.useState(false)
    React.useEffect(() => {
        if (!loaded) {
            fetchTripTypesSets()
        }
        setLoaded(true)
    })
    return (
        <React.Fragment>

            { switch_container === member_area_router.memberAreaTrip ?
                <React.Fragment>
                    <div style={{ font: "23px/25px Futura Md BT", color: "#FFFFFF", textAlign: "left", paddingBottom: "10px" }}>{"Trip Type"}</div>
                    <div style={{ display: "flex", flexWrap: "wrap", }}>
                        {tripTypeSets !== undefined && tripTypeSets.length > 0 && tripTypeSets.map((type, type_index) => {
                            return (
                                <div key={type_index}
                                    onClick={() => setTripType(type)}
                                    style={{ paddingTop: "7px", paddingRight: "10px", cursor: "pointer", whiteSpace: "nowrap", font: "14px/18px Futura Lt BT", color: "#C7C7C7" }}>
                                    {type.typeID === selectedTypeId ? <CheckedBoxReglarIcon style={{ marginBottom: "2px", marginRight: "5px" }} /> : <UnCheckBoxReglarIcon style={{ marginBottom: "2px", marginRight: "5px" }} />}
                                    {type.typeName}
                                </div>
                            )
                        })}
                    </div>
                </React.Fragment>
                :
                <React.Fragment>
                    <div style={{ font: "23px/25px Futura Md BT", color: "#191919", textAlign: "left", paddingBottom: "10px" }}>{"Trip Type"}</div>
                    <div style={{ display: "flex", flexWrap: "wrap", }}>
                        {tripTypeSets !== undefined && tripTypeSets.length > 0 && tripTypeSets.map((type, type_index) => {
                            return (
                                <div key={type_index}
                                    onClick={() => setTripType(type)}
                                    style={{ paddingTop: "7px", paddingRight: "10px", cursor: "pointer", whiteSpace: "nowrap", font: "14px/18px Futura Lt BT", color: "#191919" }}>
                                    {type.typeID === selectedTypeId ? <CheckedBoxReglarIcon style={{ marginBottom: "2px", marginRight: "5px" }} /> : <UnCheckBoxReglarIcon style={{ marginBottom: "2px", marginRight: "5px" }} />}
                                    {type.typeName}
                                </div>
                            )
                        })}
                    </div>
                </React.Fragment>}

        </React.Fragment>
    )
}

const mapStateToProps = (state) => ({
    tripTypeSets: state.FormBank.TimelineWizard.activeTrip.tripType.sets,
    selectedTypeId: state.FormBank.TimelineWizard.activeTrip.tripType.selectedTypeId,

    switch_container: state.Member.switch_container,
})

const mapDispatchToProps = {
    fetchTripTypesSets: Actions.fetchTripTypesSets,
    setTripType: Actions.setTripType,
}

export default connect(mapStateToProps, mapDispatchToProps)(TripType)
