import React from 'react'
import PostsUser from "./PostsUser"
import ReviewsGoogle from "./ReviewsGoogle"
import ReviewsYelp from "./ReviewsYelp"
import ReviewsPriceline from "./ReviewsPriceline"
import { connect } from 'react-redux'

const EstablishmentReviews_ = ({ establishment }) => {
    const reviewsGoogleAndYelp = establishment.reviewsGoogleAndYelp;
    const [open, setOpen] = React.useState(false)
    const hasGoogleReviews = reviewsGoogleAndYelp !== null && reviewsGoogleAndYelp !== undefined &&
        reviewsGoogleAndYelp.google !== null && reviewsGoogleAndYelp.google !== undefined &&
        reviewsGoogleAndYelp.google.length > 0

    return (
        <div>
            <div style={{
            }}>
                <div style={{ font: "28px/36px Futura Hv BT", marginTop: "45px" }}>
                        <PostsUser open={open} />
                </div>
                {hasGoogleReviews &&
                    <div style={{ font: "28px/36px Futura Hv BT", marginTop: "45px" }}>
                        {"Google Reviews"}
                        <ReviewsGoogle reviewsGoogle={reviewsGoogleAndYelp.google} open={open} />
                    </div>}
                {(open || !hasGoogleReviews) && reviewsGoogleAndYelp !== null && reviewsGoogleAndYelp !== undefined &&
                    reviewsGoogleAndYelp.yelp !== undefined &&
                    reviewsGoogleAndYelp.yelp?.reviews.length > 0 &&
                    <div style={{ font: "28px/36px Futura Hv BT", marginTop: "45px" }}>
                        {"Yelp Reviews"}
                        <ReviewsYelp reviewsYelp={reviewsGoogleAndYelp.yelp} />
                    </div>}
                {(open || !hasGoogleReviews) && reviewsGoogleAndYelp !== null && reviewsGoogleAndYelp !== undefined &&
                    reviewsGoogleAndYelp.priceline !== undefined && reviewsGoogleAndYelp.priceline !== null &&
                    reviewsGoogleAndYelp.priceline.length > 0 &&
                    <div style={{ font: "28px/36px Futura Hv BT", marginTop: "45px" }}>
                        <ReviewsPriceline reviewsPriceline={reviewsGoogleAndYelp.priceline} />
                    </div>}
            </div>
            {open ? <div style={{ color: "#0D3A7D", font: "18px/26px Futura Md BT", textAlign: "center", marginTop: "30px", cursor: "pointer", }} onClick={() => setOpen(false)}>{"less reviews"}</div>
                : (hasGoogleReviews ? <div style={{ color: "#0D3A7D", font: "18px/26px Futura Md BT", textAlign: "center", marginTop: "30px", cursor: "pointer", }} onClick={() => setOpen(true)}>{"more reviews"}</div> : "")
            }
        </div >
    )
}

const mapStateToProps = (state) => ({
    establishment: state.Destination.establishment,

})

const mapDispatchToProps = {

}

const EstablishmentReviews = connect(mapStateToProps, mapDispatchToProps)(EstablishmentReviews_)
export default EstablishmentReviews
