import "./TravelFavorites.css"

import * as Actions from '../../../redux/actions'

import { ArrowLeftIcon, ArrowRightIcon } from "../Common/SVGBank"

import FavoritesElement from "../Common/FavoritesElement"
import { PropTypes } from 'prop-types'
import React from 'react'
import Slider from 'react-slick'
import { connect } from "react-redux"

const SliderArrowRight = ({ className, to, onClick }) => (
    <button type="button" onClick={onClick}
        className={`button button--text button--icon ${className}`} aria-label={to}>
        <ArrowRightIcon className="icon" icon={to} style={{
            height: "60px",
            width: "60px",
            transform: 'translateY(-66px)'
        }} />
    </button>
)

const SliderArrowLeft = ({ className, to, onClick }) => (
    <button type="button" onClick={onClick} className={`button button--text button--icon ${className}`} aria-label={to}>
        <ArrowLeftIcon className="icon" icon={to} style={{
            height: "60px",
            width: "60px",
            transform: 'translateY(-66px)'
        }} />
    </button>
)

/* travel favorites component */
const TravelFavorites_ = ({ travelFavourites, screenSize, fatchTravelFavoritesSlider, lang, uuid, destinationFavoritesLimit, destinationFavoritesOffset, destinationFavoritesIsFetch }) => {

    const heightContainer = "400px"
    const settings = {
        touchMove: false,
        dots: false,
        infinite: false,
        arrows: true,
        speed: 500,
        lazyLoad: false,
        autoplay: false,
        slidesToShow: 5,
        slidesToScroll: 3,
        pauseOnHover: true,
        centerMode: false,
        prevArrow: <SliderArrowLeft to="prev" />,
        nextArrow: <SliderArrowRight to="next" />,
        responsive: [
            {
                breakpoint: 1724,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 1500,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 1100,
                settings: {
                    touchMove: true,
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    arrows: false,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    touchMove: true,
                    slidesToShow: 3,
                    slidesToScroll: 2,
                    arrows: false,

                }
            },
            {
                breakpoint: 500,
                settings: {
                    touchMove: true,
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    arrows: false,
                }
            },
        ]
    }

    const display_element_length = screenSize > 1724 ? 5 :
        screenSize > 1500 ? 4 :
            screenSize > 1100 ? 3 :
                screenSize >= 1024 ? 2 :
                    screenSize >= 768 ? 3 :
                        screenSize > 500 ? 1 : 2
    const left_element = travelFavourites && travelFavourites.length < display_element_length ? "left_element" : ""

    return (
        <div className="margin_top_20">
            <div className="d-flex margin_top_30 margin_right_20 padding_125rem_lr_mobile">
                <div className="futura_hv_bt_24_26_white margin_bottom_20">Travel Favorites</div>
                {/* <div className="futura_hv_bt_16_19_green my_home_faverites_top_btn_container text-right">All</div> */}
            </div>

            <Slider {...settings} className={left_element} style={{ height: heightContainer }}>
                {travelFavourites !== undefined && travelFavourites[0] !== undefined &&
                    travelFavourites.map((favorite, idx) => (
                        <FavoritesElement favorite={favorite} key={idx} from={"travel"} />
                    ))
                }
            </Slider>
        </div>
    )
}

TravelFavorites_.propTypes = {
    destinationFavorites: PropTypes.array.isRequired,
}
const mapStateToProps = (state) => {
    return {
        destinationFavorites: state.Member.socialMember.destinationFavorites,
        travelFavourites: state.SocialPage.travelFavourites,
        destinationFavoritesOffset: state.Member.socialMember.destinationFavoritesOffset,
        destinationFavoritesLimit: state.Member.socialMember.destinationFavoritesLimit,
        destinationFavoritesIsFetch: state.Member.socialMember.destinationFavoritesIsFetch,
        screenSize: state.Setting.htmlBody.bodyClientWidth,
        uuid: state.Social.member_uuid,
        lang: state.Setting.lang,
    }
};

const mapDispatchToProps = {
    fatchTravelFavoritesSlider: Actions.fatchTravelFavoritesSlider,
};
const TravelFavorites = connect(mapStateToProps, mapDispatchToProps)(TravelFavorites_)
export default TravelFavorites;