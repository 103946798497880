import { createSlice, current } from '@reduxjs/toolkit'
import { flightReducer } from './flights.reducer';
import { formatFlightData } from './flights.thunk.reducer';
import { getAirportsListThunk, getFlightContractThunk, getFlightsListThunk, getReturnFlightThunk ,bookFlightThunk, getNearByDestinationCode, getNearByPricelineCode, lookUpFlightThunk} from './Flights.thunks';
import { FlightTestSlice, multiCityData } from './flightTestData';


const initialState = {
    airportsList: [],
    ppnBundle: '',
    flightData: {
        flightMode: 'round_trip',
        cabinClass: 'economy',
        numberAdult: '1',
        numberChild: '0',
        flights: [{
            airportFrom: null,
            airportTo: null,
            departureDateFormat: "",
            departureDate: "",
            availableSelectDate: new Date().toDateString(),
            positionId: "d-0",
        },
        {
            airportFrom: null,
            airportTo: null,
            departureDateFormat: "",
            departureDate: "",
            availableSelectDate: new Date().toDateString(),
            positionId: "d-1",
        }],
        currency: ''
    },
    itineraries: [],
    searchData: [],
    flightListData: [],
    airlines: [],
    maxPrice: null,
    minPrice: null,
    selectedDepartureFlight: null,
    selectedReturnFlight:   null,
    selectedFlightMode: null,
    selectedFlight:null,
    flightContract:null,
    maxDurationSeconds: null,
    minDurationSeconds: null,
    numberAdult: 0,
    numberChild: 0,
    page: 1,
    loading: false,
    loadingMore: false,
    bookingSuccess: false,
    bookingFailed:false,
    bookingMessage:'',
    bookingData: null,
    isEmptySearch: false,
    showExpired: false,
    nearByDestination: null,
    nearByPriceline: null,
    reachedEnd:false,
    savedFlight: [],
    lookUpData:{},
    contactInfo:{},
}

export const FlightsSlice = createSlice({
    name: 'Flights',
    initialState: initialState,
    reducers: flightReducer,
    extraReducers: {
        [getAirportsListThunk.pending]: (state, action) => {
        },
        [getAirportsListThunk.fulfilled]: (state, action) => {
            state.airportsList = action.payload;
        },
        [getReturnFlightThunk.pending]: (state, action) => {
            state.loading = true;
            state.isEmptySearch= false
        },
        [getReturnFlightThunk.fulfilled]: (state, action) => {
            state.itineraries = initialState.itineraries;
            state.airlines = initialState.airlines;
            state.flightListData = initialState.flightListData;
            state.searchData = initialState.searchData;
            state.page = initialState.page;
            formatFlightData(state, action)
            state.loading = false;
            if(state.flightListData.length === 0) {
                state.isEmptySearch = true;
            }
        },
        [getReturnFlightThunk.rejected]: (state, action) => {
            state.loading = false;
            state.isEmptySearch = false;
        },
        [getFlightsListThunk.pending]: (state, action) => {
            state.isEmptySearch= false;
            state.loading = true;
            if (state.page === 1) {
              
            }
            else {
                // state.loadingMore = true;
            }
        },
        [getFlightContractThunk.pending]: (state, action) => {
        },
        [getFlightContractThunk.fulfilled]: (state, action) => {
            state.flightContract = action.payload;  
        },
        [getFlightsListThunk.fulfilled]: (state, action) => {
            state.flightContract = null;  // //clear previous selection
            state.selectedDepartureFlight = null;  //clear previous selection
            
            if(action?.payload){
                formatFlightData(state, action)
            }
           // state.loadingMore = false;

            if(state.flightListData.length === 0  || !action?.payload ) {
                state.isEmptySearch = true;
            }
            ///sort flight by best

            state.loading = false;
        },
        [getFlightsListThunk.rejected]: (state, action) => {
            state.itineraries = initialState.itineraries;
            state.airlines = initialState.airlines;
            state.flightListData = initialState.flightListData;
            state.selectedDepartureFlight = null;  //clear previous selection
            state.loading = false;
            state.loadingMore = false;
            state.flightContract = null;  // //clear previous selection
            state.isEmptySearch = false;
        },
        [bookFlightThunk.pending]: (state, action) => {
        },
        [bookFlightThunk.fulfilled]: (state, action) => {
            state.bookingSuccess = true;  
            state.bookingData = action.payload;
        },
        [bookFlightThunk.rejected]: (state, action) => {
            state.bookingMessage = action.payload;
            state.bookingFailed = true;  
        },
        [getNearByDestinationCode.fulfilled]: (state, action) => {
            state.nearByDestination = action.payload;
        },
        [getNearByPricelineCode.fulfilled]: (state, action) => {
            state.nearByPriceline = action.payload;
        },
        [lookUpFlightThunk.pending]: (state, action) => {
        },
        [lookUpFlightThunk.fulfilled]: (state, action) => {
            state.lookUpData = action.payload;
        },      
    },
})

export const {setExpiredFlight, saveFlight,saveBookingContact,updateFlightData, updateFlightMode,deleteFlightCart, deleteFlightReturnItem,deleteFlightDepartureItem, updateFlightDataItem, resetFlightPage, updateFlightPage, sortFlightList, updateReturnFlight, updateDepartureFlight,
    filterByStops, filterByAirline,clearNonStop,filterByNonStop, filterByPrices, clearFlightFilters, filterByDuration, filterByTime,clearFlightBooking,cancelLoading } = FlightsSlice.actions

export const FlightsReducer = FlightsSlice.reducer;