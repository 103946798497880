import * as Actions from '../../../redux/actions'
import ReactDOMServer from 'react-dom/server';

import { InfoWindow, Marker } from "react-google-maps"

import { EstablishmentDescription } from "./EstablishmentDescription"
import EstablishmentName from './Establishment/EstablishmentName'
import EstablishmentNamePriceline from '../Common/HotelEstablishment/EstablishmentName'
import {
    Image,
} from 'react-bootstrap'
import { PlusSignIcon } from '../Common/SVGBank'
import Rating from '@material-ui/lab/Rating'
import React from 'react'
import { connect } from 'react-redux'
import { image_boxing, priceline_image_boxing, member_area_router, map_type_icon_components } from './RegExValidate'
import FeatureTypeIcon from './FeatureTypeIcon'
import { environment } from '../../../Environments/environment';
import Axios from 'axios';

export const PlacesMarkers_ = ({ pushPlacesWindow, clearPlacesWindow, checkWindowOpen, icon, establishment, zoom, switchFeatureContainer, switch_container, addActivityToTripDestinationInTrip, activeTripDay, index_establishment, uuid_auth, lang, activeTrip, activeTripDestinationId, placesNearByCenter, activeDestinationId }) => {
   
    const [establishmentImage, setEstabImage] = React.useState()
    const uuid = uuid_auth !== "" ? uuid_auth : "TMPUUID"
    const activeTripId = activeTrip.tripId
    const activeDayInTripDestination = activeTripDay.dayInTripDestination

    var timer;


    const switchFeatureContainerColorLight = switchFeatureContainer.color + "35"
    const activitiesInDestinationDay = activeTrip.activities.filter(a => a.activityDay === activeTripDay.dayInTrip && a.tripDestinationId === activeTripDay.tripDestinationId)
    const lengthActivitiesInDestinationDay = activitiesInDestinationDay !== undefined ? activitiesInDestinationDay.length : 0
    const activitiesInDestinationDayEstablishmentIds = Array.from(activitiesInDestinationDay.map((a) => a.activityEstabID))
    const last_establishmentId = activitiesInDestinationDayEstablishmentIds[lengthActivitiesInDestinationDay - 1] !== undefined ? activitiesInDestinationDayEstablishmentIds[lengthActivitiesInDestinationDay - 1] : ""

    const activeTripDestinationIds = Array.from(new Set(activeTrip.tripDestinations.map(d => d.id)))
    const tripDuration = activeTrip.tripDuration

    let clearWindowTimeout;





    const getEstablishmentImage = async () => {
        if (establishment?.id) {
            const url = environment.apiUrl + 'establishment/getSingleimage/' + establishment?.id
            const response = await Axios.get(url)
            const data = response.data?.results;

            if (data) {
                const imageLink = image_boxing(data, "260", "130");
                setEstabImage(imageLink)
            }

        }


    }


    const toggleOpenInfoWindow = () => {
        if (!checkWindowOpen(establishment?.id)) {
            if (establishment && establishment.id) {

                timer = setTimeout(() => {
                    getEstablishmentImage()
                    pushPlacesWindow(establishment.id)

                }, 500);
                // pushPlacesWindow(establishment.id)

            }

        }
        else {
            clearPlacesWindow()
        }


    }



    const mouseOutMarker = () => {
        clearTimeout(timer)
        clearPlacesWindow()

    }

  

    const removeHoverOpenInfoWindow = () => {
        clearTimeout(timer);
        clearWindowTimeout = setTimeout(() => {
            clearPlacesWindow()
        }, 8000)
        //    clearTimeout(clearWindowTimeout)
    }

    React.useEffect(() => {
        // setIsOpenInfoWindow(false)
        // clearPlacesWindow()
    }, [placesNearByCenter])



    const infoWindowRef = React.useRef(null);

    const handleAddActivityToTripDestinationInTrip = (lang, uuid, activeTripDestinationId, activeDayInTripDestination, establishmentID, activeTripId, last_establishmentId, tripDuration, destinationId, activeTripDestinationIds) => {
        let activeMapTripDestinationId = activeTripDestinationId
        let activeDayMapInTripDestination = activeDayInTripDestination
        let activeDayMapDayInTrip = activeTripDay.dayInTrip
        const isSetActiveTrip = destinationId !== activeDestinationId
        if (isSetActiveTrip) {
            const existDestinationArray = activeTrip.tripDestinations.filter(d => d.id === String(destinationId))
            const existDestinationArrayLength = existDestinationArray !== undefined ? existDestinationArray.length : 0
            const exsitDestination = existDestinationArray !== undefined ? existDestinationArray[existDestinationArrayLength - 1] : undefined
            activeMapTripDestinationId = exsitDestination === undefined ? activeTripDestinationId : exsitDestination.td_id
            const destinationDays = exsitDestination !== undefined && exsitDestination.spanningDays !== undefined ? exsitDestination.spanningDays.split(',') : undefined
            const destinationDaysLength = destinationDays !== undefined ? destinationDays.length : 0
            activeDayMapInTripDestination = exsitDestination === undefined ? activeDayInTripDestination : destinationDaysLength
            activeDayMapDayInTrip = exsitDestination === undefined ? activeTripDay.dayInTrip : destinationDays[destinationDaysLength - 1]
        }

        addActivityToTripDestinationInTrip(lang, uuid, activeMapTripDestinationId, activeDayMapInTripDestination, establishmentID, activeTripId, last_establishmentId, tripDuration, destinationId, activeTripDestinationIds, isSetActiveTrip, activeDayMapDayInTrip)
        document.getElementById(activeMapTripDestinationId + "_" + activeDayMapInTripDestination + "_" + activeDayMapDayInTrip) !== null && document.getElementById(activeMapTripDestinationId + "_" + activeDayMapInTripDestination + "_" + activeDayMapDayInTrip).scrollIntoView({ behavior: 'smooth', block: 'start' })

    }

    const [infoWindowPosition, setInfoPosition] = React.useState(0)

    const infoWindowPositionChanged = () => {

    }

    const TypeIcon = ReactDOMServer.renderToString(<FeatureTypeIcon
        icon={icon}
        fill={'white'}
        style={{
            width: "15px",
            height: "16px",
            marginLeft: "5px",
            marginRight: "10px",
        }} />)

    const imageSrc = switch_container === member_area_router.hotelSearch ? priceline_image_boxing(establishment.image, "260", "130") : establishmentImage || image_boxing(establishment.image, "260", "130");


    return (
        <div 
            onMouseLeave={mouseOutMarker}>
            <Marker
                zIndex={2}
                position={{
                    lat: parseFloat(establishment.latitude),
                    lng: parseFloat(establishment.longitude)
                }}
                onMouseOver={() => toggleOpenInfoWindow()}
                onMouseOut={()=> clearTimeout(timer)}
                onClick={() => toggleOpenInfoWindow()}
                icon={{
                    url: (checkWindowOpen(establishment?.id)) ?
                        index_establishment < 9 ?
                            `data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 45 45">
                        <g fill-rule="evenodd">    
                            <circle cx="22.5" cy="22.5" r="12" stroke="rgba(13, 58, 125, 0.4)" stroke-width="20" fill="rgba(13, 58, 125, 1)" />
                            <svg  width="45" height="45"  viewBox="8 -22 1 60" >
                            ${TypeIcon}
                            </svg>  
                        </g>
                    </svg>`
                            :
                            `data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 45 45">
                        <g fill-rule="evenodd">    
                            <circle cx="22.5" cy="22.5" r="12" stroke="rgba(13, 58, 125, 0.4)" stroke-width="20" fill="rgba(13, 58, 125, 1)" />
                           <svg  width="45" height="45"  viewBox="8 -22 1 60" >
                     ${TypeIcon}
                     </svg> 
                        </g>
                    </svg>`
                        :
                        index_establishment < 9 ?
                            `data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 45 45">
                        <g fill-rule="evenodd">    
                            <circle cx="22.5" cy="22.5" r="12" fill="rgba(13, 58, 125, 1)" />
                            <circle width="45" height="45" cx="22.5" cy="22.5" r="20" fill="none" stroke-dasharray="2, 2" stroke-width="2" stroke="rgba(13, 58, 125, 1)"/> 
                        </g>
                        <svg  width="45" height="45"  viewBox="8 -22 1 60" >
                     ${TypeIcon}
                     </svg>
                        </svg>`
                            :
                            `data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 45 45">
                        <g fill-rule="evenodd">    
                            <circle cx="22.5" cy="22.5" r="12" fill="rgba(13, 58, 125, 1)" />
                            <circle width="45" height="45" cx="22.5" cy="22.5" r="20" fill="none" stroke-dasharray="2, 2" stroke-width="2" stroke="rgba(13, 58, 125, 1)"/>
                          
                        </g>
                        <svg  width="45" height="45"  viewBox="8 -22 1 60" >
                        ${TypeIcon}
                        </svg>
                        </svg>`
                    ,
                    size: { width: 45, height: 45 },
                    // anchor: {x: 0, y: 0},
                    scaledSize: { width: zoom ? zoom * 2.7 : 35, height: zoom ? zoom * 2.7 : 35 },
                }}

            >
                {(checkWindowOpen(establishment?.id)) &&
                    <InfoWindow ref={infoWindowRef}
                        onPositionChanged={infoWindowPositionChanged}
                        options={{
                            disableAutoPan: true, zIndex: 200,
                            pixelOffset: new window.google.maps.Size(-4, 5)
                        }}
                        onCloseClick={() => toggleOpenInfoWindow()}>
                        <div style={{ marginBottom: "10px" }}>

                            {imageSrc && <Image className="img_fit_cover"
                                src={imageSrc}
                                alt="establishment" width={260} height={130} />}

                            <div style={{ display: "flex", width: "260px", padding: "10px 0px 0px 10px" }}>
                                <div className={"box_1"} style={{ flexGrow: "1", font: "14px/12px Futura Hv BT", color: "var(--mainGreen)", }}>
                                    {establishment.destinationName}
                                </div>

                                {establishment.five_star_rating !== undefined && establishment.five_star_rating !== "0.0" && <div className="d-flex timeline_rating_color">
                                    <div
                                        style={{
                                            font: "10px/12px Futura Md BT",
                                            color: "var(--mainGreen)",
                                        }}>{establishment.five_star_rating}</div>
                                    <div style={{
                                        paddingRight: "2px",
                                    }}>
                                        <Rating name="timeline_rating" size={"small"} precision={0.5} max={1} value={parseFloat((establishment.five_star_rating / 5).toFixed(1))} disabled={true} />
                                    </div>
                                </div>}
                            </div>
                            <div style={{ textAlign: "left", padding: "0px 10px 0px 10px", width: "260px" }}>
                                {switch_container !== member_area_router.hotelSearch && <EstablishmentName
                                    establishmentCity={establishment.cityName}
                                    establishmentState={establishment.stateName}
                                    establishmentCountry={establishment.countryName}
                                    establishmentName={establishment.establishmentName}
                                    establishmentId={establishment.id}
                                    titleClass={"box_1"}
                                    titleStyle={{ cursor: 'pointer', font: "14px/16px Futura Hv BT", textAlign: "left", color: "var(--frameBlack)", }}
                                />}
                                {switch_container === member_area_router.hotelSearch && <EstablishmentNamePriceline
                                    establishmentCity={establishment.cityName}
                                    establishmentState={establishment.stateName}
                                    establishmentCountry={establishment.countryName}
                                    establishmentName={establishment.establishmentName}
                                    establishmentId={establishment.id}
                                    pricelineId={establishment.establishmentPricelineID}
                                    ppn_bundle={establishment.ppn_bundle}
                                    titleClass={"box_1"}
                                    titleStyle={{ cursor: 'pointer', font: "14px/16px Futura Hv BT", textAlign: "left", color: "var(--frameBlack)", }}
                                />}
                            </div>
                            {establishment.establishmentDescription !== "" && <div style={{ textAlign: "left", padding: "10px 10px 0px 10px", width: "260px" }}>
                                <EstablishmentDescription description={establishment.establishmentDescription} boxDescription={"box_2"}
                                    bottom={'0px'} fontDescription={"14px/15px Futura Lt BT"} colorDescription={"var(--mainGrey)"} height={"60px"}
                                    textLength={100}
                                />
                            </div>}

                            <div style={{ display: "flex", textAlign: "left", padding: "0px 10px", width: "260px" }}>
                                <div style={{ flexGrow: "1", }}>
                                    {establishment.subType !== undefined && establishment.subType !== null
                                        && establishment.subType.subTypeName !== undefined && <div style={{ flexGrow: "1" }}>
                                            <div className="btn_rounded" style={{
                                                font: "10px/13px Futura Hv BT",
                                                marginTop: "10px",
                                                color: switchFeatureContainer.color,
                                                backgroundColor: switchFeatureContainerColorLight,
                                                borderColor: switchFeatureContainerColorLight,


                                            }}>{establishment.subType.subTypeName}</div>
                                        </div>}
                                </div>
                                {activeTripId !== "" &&
                                    <div style={{ marginTop: "10px" }}>
                                        <div onClick={() => handleAddActivityToTripDestinationInTrip(lang, uuid, activeTripDestinationId, activeDayInTripDestination, establishment.id, activeTripId, last_establishmentId, tripDuration, establishment.destinationId, activeTripDestinationIds)} style={{ cursor: "pointer", color: "var(--mainGreen)", font: "10px/13px Futura Hv BT", padding: "0px 20px 10px 5px", display: "inline-block" }}><PlusSignIcon width={10} height={13} style={{ marginRight: "5px" }} />Add to trip</div>
                                    </div>}
                            </div>
                        </div>
                    </InfoWindow>}
            </Marker >
        </div>
    )
}

const mapStateToProps = (state) => ({
    lang: state.Setting.lang,
    uuid_auth: state.Member.authModal.uuid,
    activeTrip: state.FormBank.TimelineWizard.activeTrip,
    activeTripDestinationId: state.FormBank.TimelineWizard.activeTripDestinationId,
    activeDestinationId: state.FormBank.TimelineWizard.activeDestinationId,
    activeTripDay: state.FormBank.TimelineWizard.activeDay,
    switchFeatureContainer: state.FormBank.FeatureFormat,
    switch_container: state.Member.switch_container,
})

const mapDispatchToProps = {
    addActivityToTripDestinationInTrip: Actions.addActivityToTripDestinationInTrip,
    deleteActivityToDayOfTripDestinationInTrip: Actions.deleteActivityToDayOfTripDestinationInTrip,
}

const PlacesMarkers = connect(mapStateToProps, mapDispatchToProps)(PlacesMarkers_)
export default PlacesMarkers
