import * as Actions from '../../../../redux/actions'

import {DoneIcon, DoneEmptyIcon} from '../SVGBank'

import React from 'react'
import { connect } from "react-redux"
import { LightTooltip } from './tooltipActions'

export const Done = connect((state) => ({
    screenSize: state.Setting.htmlBody.bodyClientWidth,
    isMobile: state.Setting.is_mobile,
    fetchMemeberActions: state.MemberActions.fetchMemeberActions,
    fetchFeatures: state.MemberActions.fetchFeatures,
    fetchDoneSets: state.MemberActions.fetchDoneSets,
    uuid: state.Member.authModal.uuid,
}), {
    addingDone: Actions.addingDone,
    deletingDone: Actions.deletingDone,
})(({
    fill,
    uuid,
    feature,
    // isMobile,
    // fetchMemeberActions,
    fetchFeatures,
    fetchDoneSets,
    // screenSize,
    addingDone,
    deletingDone,
    unitID,
    caption,

}) => {

    const unique = unitID + "_" + feature.featureId + "_done"
    return (
        <div style={{
            font: "14px / 14px Futura Lt BT",
        }}> 

            {
                uuid !== undefined && uuid !== "" &&
                <div>
                    {fetchDoneSets !== undefined && fetchFeatures.isFetched &&
                        fetchDoneSets.dones.filter(Done => Done.featureUnitId + "_" + Done.featureId + "_done" === unique).length <= 0 ?
                        <div>
                        {caption? 
                        
                        <div style={{ cursor: "pointer", color: fill,
                        padding: '20px',
                        paddingRight: '40px',
                        margin: '-20px'
                        }} onClick={() => addingDone(uuid, unitID, feature)}>
                        <DoneEmptyIcon fill={fill} width={25} height={25} /> &nbsp; Done it
                         </div>:
                        
                        <LightTooltip title="Done it" placement="top-start">
                         <div style={{ cursor: "pointer", color: fill}} onClick={() => addingDone(uuid, unitID, feature)}>
                            <DoneEmptyIcon fill={fill} width={25} height={25} /> 
                        </div>
                        </LightTooltip>}
                        </div>

                      
                        :

                        <div>
                        {caption? 
                        
                        <div style={{ cursor: "pointer", color: fill,        padding: '20px',
                        paddingRight: '40px',
                        margin: '-20px'}} onClick={() => deletingDone(uuid, unitID, feature)}>
                        <DoneIcon fill={fill} width={25} height={25} /> &nbsp; Checked
                        </div>:
                        
                        <LightTooltip title="Checked" placement="top-start">
                          <div style={{ cursor: "pointer", color: fill }} onClick={() => deletingDone(uuid, unitID, feature)}>
                            <DoneIcon fill={fill} width={25} height={25} />
                        </div>
                        </LightTooltip>}
                        </div>

                      
                    }
                </div>
                // :
                // <div>
                //     {
                //         <div style={{ cursor: "pointer" }} onClick={() => console.log("force login")}>
                //             <MinusSignIcon fill={fill} width={25} height={25} />
                //         </div>

                //     }
                // </div>
            }
        </div>
    )
})