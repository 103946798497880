import * as Actions from '../../../../redux/actions'

import {
    TripNameTextField,
    TripStartDateTextField
} from "../FormComponentsBank"

import { CaretLeft, } from "../SVGBank"
import CircularProgress from '@material-ui/core/CircularProgress'
import LinkBooking from '../../Common/TimelineWizard/LinkBooking'
import ReCalculateTripCostTimelineWizard from './ReCalculateTripCostTimelineWizard'
import React from 'react'
import { TripDestinationTabPanel } from './TripDestinationTabPanel'
import { TripDestinationTabs } from "./TripDestinationTabs"
import TripEllipsisTimeline from "../Trip/TripEllipsisTimeline"
import { connect, useSelector } from 'react-redux'
import { list_container_mode } from '../../Common/RegExValidate'
import { member_area_router } from '../../Common/RegExValidate'
import { Backdrop, makeStyles, Popover } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

const TimelineWizard_ = ({ hideTab, timelineStatus, savedTrip, lang, uuid, optimizeTimeline, activeTrip, listDisplayFormat, app_menu_height, switchFeatureContainer, screenVertical, setTimeLineStatus }) => {
    const timelineExtendTopConstant = 126
    let timelineExtendTop = listDisplayFormat === member_area_router.booking ? app_menu_height + 10 : timelineExtendTopConstant
    let timelineContainerMarginTop = timelineExtendTop + 180
    //let timelineExtendTop = listDisplayFormat === member_area_router.booking ? app_menu_height : 420
    //const timelineExtendTopConstant = listDisplayFormat === member_area_router.booking ? app_menu_height : 420
    if (screenVertical.pageYOffset !== undefined) {
        timelineExtendTop = timelineExtendTop - screenVertical.pageYOffset < app_menu_height / 1.7 ? app_menu_height / 1.7 : timelineExtendTop - screenVertical.pageYOffset
        timelineContainerMarginTop = timelineExtendTop + 180
    }
    timelineExtendTop = listDisplayFormat === list_container_mode.map ? (app_menu_height / 1.72) : timelineExtendTop
    const timelineFlights = useSelector(state => state.ActiveTrip.timelineFlights);
    const [optimizing, setOptimize] = React.useState(false);
    const classes = useStyles();


    const optimizeTrip = () => {
        optimizeTimeline(lang, uuid, activeTrip.tripId, setOptimize)
    }

    const isRoadTrip = savedTrip && savedTrip?.trip && savedTrip?.trip?.tripTravelCategory?.code === "road";

    const [anchorElCost, setAnchorEl] = React.useState(null);
    const openCost = Boolean(anchorElCost);
    const costId = openCost ? 'cost-popover' : undefined;
    const handleClickCost = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseCost = () => {
        setAnchorEl(null);
    };

     const dest1 = timelineFlights && timelineFlights?.length > 0? timelineFlights[0]:null
     const dest2 = timelineFlights && timelineFlights?.length > 0? timelineFlights[timelineFlights.length-1]:null

     const returnFlight= dest1 && dest2? {

     }:null;

    return (
        <div>
            <Backdrop className={classes.backdrop} open={optimizing} onClick={() => setOptimize(false)}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <div style={{
                height: "auto",
                zIndex: "2",
                // width: "330px", 
            }}
                id={"back_to_top_timeline"}>
                {!hideTab && <div style={{
                    position: "fixed", width: "340px", zIndex: "4",
                    top: timelineExtendTop
                }}>
                    <div style={{
                        // minWidth: "350px",
                        background: `${isRoadTrip ? '#D8521D' : '#0D3A7D'} 0% 0% no-repeat padding-box`,
                        border: "0px solid #707070",
                        color: "var(--mainWhite)",
                        textAlign: "center",
                        paddingTop: "8px",
                        paddingBottom: "28px",
                        marginBottom: "0px",
                        position: "relative",
                    }}>
                        <div style={{ position: "absolute", top: "5px", right: "10px", cursor: "pointer" }} >
                            <TripEllipsisTimeline />
                        </div>
                        <div style={{
                            position: "absolute", top: "0px", right: "-23px", cursor: "pointer", width: "23px",
                            height: "48px",
                            background: "rgba(255,255,255,0.9)",
                            borderLeft: "1px solid #D4D4D4",
                            boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.3)",
                        }} onClick={() => setTimeLineStatus(false)}>
                            <CaretLeft fill={"#C7C7C7"} style={{ marginTop: "15px" }} />
                        </div>
                        <div style={{ position: "absolute", top: "105px", right: "10px", cursor: "pointer" }} >
                            <ReCalculateTripCostTimelineWizard />
                        </div>
                        <div style={{ font: "24px/29px Futura Hv BT", marginBottom: "5px", }}>
                            {activeTrip.tripName !== "" && <TripNameTextField inputStyle={{
                                font: "28px/28px Futura Md BT",
                                color: "#FFFFFF",
                                textAlign: "center",
                                width: "300px",
                            }} />
                            }</div>
                        <div style={{ font: "16px/19px Futura Lt BT", color: isRoadTrip ? 'white' : "#6A9EEC", marginLeft: "30px" }}><TripStartDateTextField /></div>
                        <div style={{ height: "15px", color: "#6A9EEC" }} />
                        <div onClick={handleClickCost}>
                            <span style={{ font: "16px/19px Futura Lt BT", cursor: "pointer" }}>Cost if booked today:</span>
                            {!activeTrip.tripCost.fetching
                                && activeTrip.tripCost !== undefined
                                && activeTrip.tripCost !== undefined
                                && activeTrip.tripCost.cost !== undefined
                                && activeTrip.tripCost.cost !== null ?
                                <span style={{ font: "16px/19px Futura Md BT", cursor: "pointer", color: activeTrip.tripCost.fetching ? "#6A9EEC" : "#FFFFFF", marginLeft: "5px" }}>{activeTrip.tripCost.cost.currency_symbol + " " + activeTrip.tripCost.cost.cost}
                                    <span style={{ font: "12px/19px Futura Lt BT", cursor: "pointer", color: '#E8E8E8' }}> (details)</span></span>
                                :
                                <CircularProgress
                                    disableShrink
                                    size={15}
                                    style={{
                                        color: "#6A9EEC",
                                        marginLeft: "5px"
                                    }} />
                            }
                        </div>
                        {!activeTrip.tripCost.fetching
                            && activeTrip.tripCost !== undefined
                            && activeTrip.tripCost !== undefined
                            && activeTrip.tripCost.cost !== undefined
                            && activeTrip.tripCost.cost !== null && <Popover
                                id={costId}
                                open={openCost}
                                anchorEl={anchorElCost}
                                onClose={handleCloseCost}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: -30,
                                    horizontal: 'center',
                                }}
                                PaperProps={{
                                    style: { borderRadius: 0, width: 180, padding: 10 }
                                }}
                                elevation={2}

                            >
                                <div style={{ font: "12px/19px Futura Lt BT", color: '#274674' }}>

                                    <div style={{ display: 'flex', font: "12px/19px Futura Lt BT", color: '#274674', marginBottom: 1 }}>
                                        <div style={{ flex: '0 0 50%' }}>
                                            Flight(s):
                                        </div>
                                        <div style={{ flex: '0 0 50%', textAlign: 'right' }}>
                                            {activeTrip.tripCost.cost.currency_symbol + "" + Number(activeTrip.tripCost.cost?.costForFlight).toFixed(2)}
                                        </div>
                                    </div>

                                    <div style={{ display: 'flex', font: "12px/19px Futura Lt BT", color: '#274674', marginBottom: 1 }}>
                                        <div style={{ flex: '0 0 50%' }}>
                                            Hotel(s):
                                        </div>
                                        <div style={{ flex: '0 0 50%', textAlign: 'right' }}>
                                            {activeTrip.tripCost.cost.currency_symbol + "" + Number(activeTrip.tripCost.cost?.costForHotel).toFixed(2)}
                                        </div>
                                    </div>

                                    <div style={{ display: 'flex', font: "12px/19px Futura Lt BT", color: '#274674', marginBottom: 1 }}>
                                        <div style={{ flex: '0 0 50%' }}>
                                            Activities:
                                        </div>
                                        <div style={{ flex: '0 0 50%', textAlign: 'right' }}>
                                            {activeTrip.tripCost.cost.currency_symbol + "" + Number(activeTrip.tripCost.cost?.costForActivities).toFixed(2)}
                                        </div>
                                    </div>
                                    <hr style={{ borderTop: "dashed 1px #274674", marginBottom: 4, marginTop: 2 }} />

                                    <div style={{ display: 'flex', font: "12px/19px Futura Lt BT", color: '#274674', marginTop: -3 }}>
                                        <div style={{ flex: '0 0 50%' }}>
                                            TOTAL:
                                        </div>
                                        <div style={{ flex: '0 0 50%', textAlign: 'right' }}>
                                            {activeTrip.tripCost.cost?.currency + activeTrip.tripCost.cost.currency_symbol + "" + Number(activeTrip.tripCost.cost.cost).toFixed(2)}
                                        </div>
                                    </div>


                                </div>
                            </Popover>}
                        {isRoadTrip && <div style={{
                            font: "14px/16px Futura Md BT", color: '#D8521D', textAlign: "center", background: 'white', padding: 5, width: 136,
                            border: '0.5px solid #C7C7C7', position: "absolute", left: 0, right: 0, marginLeft: 'auto', marginRight: 'auto',
                            top: '90%'
                        }}>
                            ROAD TRIP
                        </div>}

                    </div>
                    <TripDestinationTabs />
                </div>}

                {/* {timelineFlights && destinationFlight  &&  destinationFlight?.length > 0  &&
                                    <TimelineSavedFlight flight={destinationFlight[0]} />
                                    } */}

                <div style={{
                    backgroundColor: "#FFFFFF"
                }}>
                    <TripDestinationTabPanel />
                    <div style={{
                        padding: "12px",
                        paddingRight: "0px",
                    }}>

                        <div onClick={() => {
                            optimizeTrip()
                        }} style={{
                            cursor: "pointer",
                            padding: "10px", textAlign: "center", font: "14px/16px Futura Md BT", backgroundColor: "#0D3A7D", color: "var(--mainWhite)",
                        }}>Optimize Route</div>
                        <div style={{
                            marginTop: "12px", padding: "10px", textAlign: "center", backgroundColor: switchFeatureContainer.color, color: "var(--mainWhite)",
                        }} >
                            <LinkBooking text={"Book this trip"} link_style={{ font: "14px/16px Futura Md BT", display: "inline-block", cursor: "pointer", }} trip_id={activeTrip.tripId} />
                            {!activeTrip.tripCost.fetching
                                && activeTrip.tripCost !== undefined
                                && activeTrip.tripCost !== undefined
                                && activeTrip.tripCost.cost !== undefined
                                && activeTrip.tripCost.cost !== null &&
                                <span style={{ font: "14px/16px Futura Md BT", color: activeTrip.tripCost.fetching ? "#6A9EEC" : "#FFFFFF", marginLeft: "5px" }}>{"(" + activeTrip.tripCost.cost.currency_symbol + " " + activeTrip.tripCost.cost.cost + ")"}</span>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({

    is_mobile: state.Setting.is_mobile,
    activeTrip: state.FormBank.TimelineWizard.activeTrip,
    app_menu_height: state.Setting.appMenu.app_menu_height,
    screenVertical: state.Setting.screenVertical,
    timelineStatus: state.FormBank.TimelineWizard.status,
    lang: state.Setting.lang,
    switchFeatureContainer: state.FormBank.FeatureFormat,
    uuid: state.Member.authModal.uuid,
    savedTrip: state.ActiveTrip,

})

const mapDispatchToProps = {
    setTimeLineStatus: Actions.setTimeLineStatus,
    optimizeTimeline: Actions.optimizeTimeline,
}

const TimelineWizard = connect(mapStateToProps, mapDispatchToProps)(TimelineWizard_)
export default TimelineWizard

