import * as Actions from '../../../../redux/actions';

import { DeleteIcon, ImageIcon, LeftArrowChevronIcon, PlusCircleIcon, RightArrowChevronIcon } from '../SVGBank'
import { animated, useSpring } from 'react-spring'

import ActivityEllipsis from '../TimelineWizard/ActivityEllipsis';
import AddEstabishment from '../TimelineWizard/AddEstabishment';
import DeleteActivityDay from '../TimelineWizard/DeleteActivityDay'
import Divider from '@material-ui/core/Divider'
import EstablishmentName from '../Establishment/EstablishmentName'
import Fab from '@material-ui/core/Fab'
import Flight from "../Fight"
import Hotel from "../Hotel"
import {
    Image,
} from 'react-bootstrap'
import React from "react"
import { TripDestinationTabs } from "../TimelineWizard/TripDestinationTabs"
import { connect } from "react-redux"
import { image_boxing } from '../RegExValidate'
import { list_container_mode } from "../RegExValidate"
import { member_area_router } from "../RegExValidate"
import moment from "moment"
import { time_format } from "../RegExValidate"

/* trip schedule component */
const TripCalendar_ = ({ switch_container, logo_height, activeTrip, setTripDisplayMode, setDestinationId, lang, uuid_auth, app_menu_height, setActiveDayInTrip, deleteTripDestinationInTrip, addDayToTripDestinationInTrip }) => {
    const tripId = activeTrip.tripId
    const tripDuration = activeTrip.tripDuration
    const tripStartDate = activeTrip.tripStartDate
    const tripNoDate = activeTrip.tripNoDate
    const tripDestinations = activeTrip.tripDestinations
    const tripActivities = activeTrip.activities
    const uuid = uuid_auth !== "" ? uuid_auth : "TMPUUID"

    const [calendarStartDate, setCalendarStartDate] = React.useState(tripStartDate)
    React.useEffect(() => {
        activeTrip.tripNoDate === "1" && setTripDisplayMode(list_container_mode.schedule)
        activeTrip.tripNoDate === "0" && setCalendarStartDate(activeTrip.tripStartDate)
    }, [activeTrip.tripNoDate, activeTrip.tripId, activeTrip.tripStartDate])

    const setActiveDayInTripHandle = (tripDestinationId, dayInTrip, dayInTripDestination, destinationId) => {
        setActiveDayInTrip(tripDestinationId, dayInTrip, dayInTripDestination, destinationId)
        setTripDisplayMode(list_container_mode.timeline)
        setDestinationId(destinationId)
    }
    const calendarPreviousArrow = () => {
        setCalendarStartDate(moment(String(calendarStartDate), "YYYY-MM-DD").subtract(1, 'days').format("YYYY-MM-DD"))

    }
    const calendarNextArrow = () => {
        setCalendarStartDate(moment(String(calendarStartDate), "YYYY-MM-DD").add(1, 'days').format("YYYY-MM-DD"))

    }
    const handleSetCalendarDay = (day_in_trip) => {
        setCalendarStartDate(moment(String(tripStartDate), 'YYYY-MM-DD').add((day_in_trip - 1), 'days').format("YYYY-MM-DD"))
        document.getElementById("trip_description_bar").scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
    const tripDaylist = []
    for (let step = 1; step <= tripDuration; step++) {
        tripDaylist.push(step)
    }

    const calendarColumns = []
    const stepMax = 7
    for (let step = 1; step <= stepMax; step++) {
        calendarColumns.push(moment(calendarStartDate, 'YYYY-MM-DD').add((step - 1), 'days'))
    }
    const timeHeihgtRate = 1
    const calendarTimes = ["00:00", "01:00", "02:00", "03:00", "04:00", "05:00", "06:00", "07:00", "08:00", "09:00", "10:00", "11:00", "12:00", "13:00", "14:00", "15:00", "16:00", "17:00", "18:00", "19:00", "20:00", "21:00", "22:00", "23:00", "24:00"]
    return (
        <React.Fragment>

            { switch_container === member_area_router.memberAreaTrip ?
                <div style={{ paddingTop: "20px", position: "relative", textAlign: "right" }}>
                    {/* calendar canvas  */}
                    <div style={{ position: "sticky", top: (Math.floor(app_menu_height / 2 + 105)) + "px", textAlign: "center", zIndex: "30", backgroundColor: "#2C2C2C" }}>
                        {tripNoDate === "0" && calendarColumns.length > 0 && calendarColumns.map((calendarColumn, index_calendar_column) => {
                            return (
                                <React.Fragment key={index_calendar_column}>
                                    {tripNoDate === "0" && <div style={{ color: "#191919", display: "inline-block", backgroundColor: "#FFFFFF", width: "200px", height: "50px", font: "12px/43px  Futura Md BT", margin: "0px 3px 0px 3px", textAlign: "center", borderBottom: "6px solid #191919", borderTop: "3px solid #191919" }}>{calendarColumn.format('ddd, MMM D')}</div>}
                                </React.Fragment>
                            )
                        })}
                        <div style={{ position: "absolute", left: "15px", top: "1px", cursor: "pointer" }} onClick={() => calendarPreviousArrow()}>
                            <Fab size="small" disabled={false} style={{
                                outline: "0px",
                                backgroundColor: "#E8E8E8",
                            }}>
                                <LeftArrowChevronIcon fill={"#191919"} width={13} height={13} style={{ marginRight: "2px" }} />
                            </Fab>
                        </div>
                        <div style={{ position: "absolute", right: "15px", top: "1px", cursor: "pointer" }} onClick={() => calendarNextArrow()}>
                            <Fab size="small" disabled={false} style={{
                                outline: "0px",
                                backgroundColor: "#19bC9B",
                            }} >
                                <RightArrowChevronIcon fill={"#FFFFFF"} width={13} height={13} style={{ marginLeft: "3px" }} />
                            </Fab>
                        </div>
                    </div>
                    <div style={{ position: "sticky", top: (Math.floor(app_menu_height / 2 + 155)) + "px", textAlign: "center", zIndex: "26" }}>
                        <div style={{ position: "absolute", right: "10px", top: "20px" }}>
                            {tripNoDate === "0" && tripDaylist.length > 0 && tripDaylist.map((day_in_trip, index_day_in_trip) => {
                                const currentDate = moment(tripStartDate, 'YYYY-MM-DD').add((day_in_trip - 1), 'days')
                                return (
                                    <div key={index_day_in_trip} style={{
                                        color: currentDate.format("YYYY-MM-DD") === calendarStartDate ? "#19BC9B" : "#FFFFFF", display: "inline-block",
                                        font: "14px/38px  Futura Lt BT", textAlign: "center",
                                        display: "flex", flexDirection: "column", width: "50px", textAlign: "right",
                                        cursor: "pointer"
                                    }} onClick={() => handleSetCalendarDay(day_in_trip)}>
                                        {currentDate.format('MMM D')}
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    {calendarTimes.map((calendar_time, index_calendar_time) => {
                        return (
                            <React.Fragment key={index_calendar_time}>
                                {index_calendar_time !== (calendarTimes.length - 1) && < div style={{ position: "relative", display: "flex", justifyContent: "center", zIndex: calendarTimes.length - index_calendar_time }}>
                                    <div style={{ position: "absolute", left: "0px", top: index_calendar_time === 0 ? "2px" : "-6px", font: "12px/12px Futura Md BT", color: "#FFFFFF" }}>{moment(calendar_time, "HH:mm").format(time_format.hours_12)}</div>
                                    {calendarColumns.map((calendarColumn, index_calendar_column) => {
                                        return (
                                            <div key={index_calendar_column} style={{
                                                color: "#191919", backgroundColor: "#FFFFFF", position: "relative", zIndex: "0", width: logo_height + "px", margin: "0px 3px 0px 3px",
                                                width: "200px", height: Math.floor(60 * timeHeihgtRate) + "px", textAlign: "center", borderBottom: "1px dashed #1919191a"
                                            }}>
                                                {tripActivities !== undefined && tripActivities.length > 0 &&
                                                    tripActivities
                                                        .filter(a =>
                                                            moment(String(tripStartDate), 'YYYY-MM-DD').add((a.activityDay - 1), 'days').format("YYYY-MM-DD") === calendarColumn.format('YYYY-MM-DD')
                                                            && moment(a.activityStartTime, 'HH:mm').isSameOrAfter(moment(calendar_time, 'HH:mm'))
                                                            && moment(a.activityStartTime, 'HH:mm').isBefore(moment(calendarTimes[index_calendar_time + 1], 'HH:mm'))
                                                        )
                                                        .map((activity, index_activity) => {
                                                            return (
                                                                <div key={index_activity} style={{
                                                                    position: "absolute",
                                                                    height: Math.floor(Number((moment(activity.activityDuration, 'HH:mm').format("H")) * 60) + Number(moment(activity.activityDuration, 'HH:mm').format("m"))) + "px",
                                                                    width: "200px",
                                                                    top: moment(activity.activityStartTime, 'HH:mm').format("m") + "px"
                                                                }}>
                                                                    <ActivityElement
                                                                        activity={activity}
                                                                        widthContainer={"200"}
                                                                        heightContainer={String(Math.floor(Number((moment(activity.activityDuration, 'HH:mm').format("H")) * 60) + Number(moment(activity.activityDuration, 'HH:mm').format("m"))))}
                                                                    />
                                                                </div>
                                                            )
                                                        })

                                                }
                                            </div>
                                        )
                                    })}
                                </div>}
                            </React.Fragment>
                        )
                    })}

                    {/* end canvas */}


                    <div style={{ textAlign: "right", marginTop: "30px", paddingRight: "75px" }}>
                        <div style={{ width: "400px", display: "inline-block", textAlign: "right", height: "70px", }}  >
                            <TripDestinationTabs from={list_container_mode.schedule} background_color={"#2C2C2C"} />
                        </div>
                    </div>
                </div >
                :
                <div style={{ paddingTop: "20px", position: "relative", textAlign: "right" }}>
                    {/* calendar canvas  */}
                    <div style={{ position: "sticky", top: (Math.floor(app_menu_height / 2 + 105)) + "px", textAlign: "center", zIndex: "30", backgroundColor: "#E8E8E8" }}>
                        {tripNoDate === "0" && calendarColumns.length > 0 && calendarColumns.map((calendarColumn, index_calendar_column) => {
                            return (
                                <React.Fragment key={index_calendar_column}>
                                    {tripNoDate === "0" && <div style={{ color: "#191919", display: "inline-block", backgroundColor: "#FFFFFF", width: "200px", height: "50px", font: "12px/43px  Futura Md BT", margin: "0px 3px 0px 3px", textAlign: "center", borderBottom: "6px solid #E8E8E8", borderTop: "3px solid #E8E8E8" }}>{calendarColumn.format('ddd, MMM D')}</div>}
                                </React.Fragment>
                            )
                        })}
                        <div style={{ position: "absolute", left: "15px", top: "1px", cursor: "pointer" }} onClick={() => calendarPreviousArrow()}>
                            <Fab size="small" disabled={false} style={{
                                outline: "0px",
                                backgroundColor: "#E8E8E8",
                            }}>
                                <LeftArrowChevronIcon fill={"#191919"} width={13} height={13} style={{ marginRight: "2px" }} />
                            </Fab>
                        </div>
                        <div style={{ position: "absolute", right: "15px", top: "1px", cursor: "pointer" }} onClick={() => calendarNextArrow()}>
                            <Fab size="small" disabled={false} style={{
                                outline: "0px",
                                backgroundColor: "#19bC9B",
                            }} >
                                <RightArrowChevronIcon fill={"#FFFFFF"} width={13} height={13} style={{ marginLeft: "3px" }} />
                            </Fab>
                        </div>
                    </div>
                    <div style={{ position: "sticky", top: (Math.floor(app_menu_height / 2 + 155)) + "px", textAlign: "center", zIndex: "26" }}>
                        <div style={{ position: "absolute", right: "15px", top: "20px" }}>
                            {tripNoDate === "0" && tripDaylist.length > 0 && tripDaylist.map((day_in_trip, index_day_in_trip) => {
                                const currentDate = moment(tripStartDate, 'YYYY-MM-DD').add((day_in_trip - 1), 'days')
                                return (
                                    <div key={index_day_in_trip} style={{
                                        color: currentDate.format("YYYY-MM-DD") === calendarStartDate ? "#19BC9B" : "#191919", display: "inline-block",
                                        font: "14px/38px  Futura Lt BT", textAlign: "center",
                                        display: "flex", flexDirection: "column", width: "50px", textAlign: "right",
                                        cursor: "pointer"
                                    }} onClick={() => handleSetCalendarDay(day_in_trip)}>
                                        {currentDate.format('MMM D')}
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    {calendarTimes.map((calendar_time, index_calendar_time) => {
                        return (
                            <React.Fragment key={index_calendar_time}>
                                {index_calendar_time !== (calendarTimes.length - 1) && < div style={{ position: "relative", display: "flex", justifyContent: "center", zIndex: calendarTimes.length - index_calendar_time }}>
                                    <div style={{ position: "absolute", left: "12px", top: index_calendar_time === 0 ? "2px" : "-6px", font: "12px/12px Futura Md BT", color: "#191919" }}>{moment(calendar_time, "HH:mm").format(time_format.hours_12)}</div>
                                    {calendarColumns.map((calendarColumn, index_calendar_column) => {
                                        return (
                                            <div key={index_calendar_column} style={{
                                                color: "#191919", backgroundColor: "#FFFFFF", position: "relative", zIndex: "0", width: logo_height + "px", margin: "0px 3px 0px 3px",
                                                width: "200px", height: Math.floor(60 * timeHeihgtRate) + "px", textAlign: "center", borderBottom: "1px dashed #1919191a"
                                            }}>
                                                {tripActivities !== undefined && tripActivities.length > 0 &&
                                                    tripActivities
                                                        .filter(a =>
                                                            moment(String(tripStartDate), 'YYYY-MM-DD').add((a.activityDay - 1), 'days').format("YYYY-MM-DD") === calendarColumn.format('YYYY-MM-DD')
                                                            && moment(a.activityStartTime, 'HH:mm').isSameOrAfter(moment(calendar_time, 'HH:mm'))
                                                            && moment(a.activityStartTime, 'HH:mm').isBefore(moment(calendarTimes[index_calendar_time + 1], 'HH:mm'))
                                                        )
                                                        .map((activity, index_activity) => {
                                                            return (
                                                                <div key={index_activity} style={{
                                                                    position: "absolute",
                                                                    height: Math.floor(Number((moment(activity.activityDuration, 'HH:mm').format("H")) * 60) + Number(moment(activity.activityDuration, 'HH:mm').format("m"))) + "px",
                                                                    width: "200px",
                                                                    top: moment(activity.activityStartTime, 'HH:mm').format("m") + "px"
                                                                }}>
                                                                    <ActivityElement
                                                                        activity={activity}
                                                                        widthContainer={"200"}
                                                                        heightContainer={String(Math.floor(Number((moment(activity.activityDuration, 'HH:mm').format("H")) * 60) + Number(moment(activity.activityDuration, 'HH:mm').format("m"))))}
                                                                    />
                                                                </div>
                                                            )
                                                        })

                                                }
                                            </div>
                                        )
                                    })}
                                </div>}
                            </React.Fragment>
                        )
                    })}

                    {/* end canvas */}


                    <div style={{ textAlign: "right", marginTop: "6px", paddingTop: "30px", paddingRight: "75px" }}>
                        <div style={{ width: "400px", display: "inline-block", textAlign: "right", height: "70px", }}  >
                            <TripDestinationTabs from={list_container_mode.schedule} background_color={"#E8E8E8"} />
                        </div>
                    </div>
                </div >}
        </React.Fragment>
    )
}

const mapStateToProps = (state) => ({

    lang: state.Setting.lang,
    uuid_auth: state.Member.authModal.uuid,

    trip: state.Member.memberArea.selectedTrip,
    logo_height: state.Setting.appMenu.logo.height,
    app_menu_height: state.Setting.appMenu.app_menu_height,

    activeTrip: state.FormBank.TimelineWizard.activeTrip,
    activeTripDestinationId: state.FormBank.TimelineWizard.activeTripDestinationId,

    listDestinationId: state.Destination.destination.activeId,
    activeDestinationId: state.FormBank.TimelineWizard.activeDestinationId,
    switch_container: state.Member.switch_container,

})

const mapDispatchToProps = {
    deleteTripDestinationInTrip: Actions.deleteTripDestinationInTrip,
    addDayToTripDestinationInTrip: Actions.addDayToTripDestinationInTrip,
    setActiveDayInTrip: Actions.setActiveDayInTrip,
    setTripDisplayMode: Actions.setTripDisplayMode,
    setDestinationId: Actions.setDestinationId,

}

const TripCalendar = connect(mapStateToProps, mapDispatchToProps)(TripCalendar_)
export default TripCalendar




const ActivityElement = ({
    activity,
    widthContainer,
    heightContainer
}) => {
    const [flipped, setFlipped] = React.useState(false)
    const { transform, opacity } = useSpring({
        opacity: flipped ? 1 : 0,
        transform: `perspective(600px) rotateX(${flipped ? 180 : 0}deg)`,
        config: { mass: 5, tension: 500, friction: 80 }
    })
    return (
        <React.Fragment>
            <animated.div style={{ position: "absolute", zIndex: flipped ? "0" : "1", opacity: opacity.interpolate(o => 1 - o), transform }}>
                <div style={{
                    position: "relative",
                    cursor: "pointer",
                }}>
                    <Image
                        src={image_boxing(activity.image, widthContainer, heightContainer)}
                        alt="activity" width={widthContainer} height={heightContainer} />
                    <div
                        onClick={() => setFlipped(true)}
                        style={{
                            backgroundColor: "rgba(25, 25, 25, 0.2)", position: "absolute", width: widthContainer + "px", height: heightContainer + "px", top: "0px", zIndex: "1",
                        }}></div>
                    <div style={{
                        position: "absolute",
                        top: "-2px",
                        right: "2px",
                        cursor: "pointer",
                        zIndex: "3",
                    }} onClick={() => setFlipped(false)}>
                        <ActivityEllipsis activity={activity} />
                    </div>
                    <div style={{
                        position: "absolute", zIndex: "2", top: "0px", left: "0px"
                    }}>
                        < EstablishmentName
                            establishmentId={activity.activityEstabID}
                            establishmentName={activity.activityName}
                            titleClass={"box_1"}
                            titleStyle={{ flexGrow: "1", cursor: 'pointer', font: "12px/18px Futura Hv BT", textAlign: "left", color: "#FFFFFF", padding: "0px 2px", marginRight: "35px" }}
                        />
                    </div>
                </div>

            </animated.div>
            <animated.div style={{ position: "absolute", zIndex: flipped ? "1" : "0", opacity, transform: transform.interpolate(t => `${t} rotateX(180deg)`) }}>
                <div style={{
                    position: "relative",
                    backgroundColor: "#19BC9B33",
                    width: widthContainer + "px",
                    height: heightContainer + "px",
                    borderLeft: "5px solid #19BC9B",
                }}>
                    <div style={{
                        position: "absolute",
                        top: "-1px",
                        left: "0px",
                        cursor: "pointer",
                        zIndex: "3",
                        display: "inline-block",
                    }} onClick={() => setFlipped(false)}>
                        <ImageIcon />
                    </div>
                    <div style={{
                        position: "absolute",
                        top: "-2px",
                        right: "2px",
                        cursor: "pointer",
                        zIndex: "3",
                    }} >
                        <ActivityEllipsis activity={activity} />
                    </div>
                    <div style={{
                        position: "absolute", zIndex: "2", top: "0px", left: "20px"
                    }}>
                        < EstablishmentName
                            establishmentId={activity.activityEstabID}
                            establishmentName={activity.activityName}
                            titleClass={"box_1"}
                            titleStyle={{ flexGrow: "1", cursor: 'pointer', font: "12px/18px Futura Hv BT", textAlign: "left", color: "#191919", padding: "0px 2px", marginRight: "35px" }}
                        />
                    </div>
                </div>
            </animated.div>
        </React.Fragment >
    )
}