import React from 'react'
import { connect, useSelector } from 'react-redux'
import * as Actions from '../../../../redux/actions'
import { Grid } from "@material-ui/core"
import moment from "moment"
import { Image } from "react-bootstrap"
import { Rating } from "@material-ui/lab"
import EstablishmentName from "../Establishment/EstablishmentName"

const TripScheduleHotel_ = ({ hotel,isMobile, bodyClientWidth, logo_width }) => {

    const widthContainer = isMobile ? Math.floor(bodyClientWidth * 0.98) : Math.floor(((bodyClientWidth - 17) * 0.83) - (logo_width * 2) - 40)
    const widthImage = widthContainer / 2
    const heightImage = widthImage / 2
    const imageUrl = hotel?.images && hotel?.images?.length > 0? hotel?.images[0].full_url: null;
    const checkIn = hotel.check_in?  moment(hotel.check_in).format('ddd MMM DD') : "" ;
    const checkOut = hotel.check_out? moment(hotel.check_out).format('ddd MMM DD')  : "";
    const bookingId = hotel?.booking_trip_number;
    return (
        <div style={{
            display: "flex",
            position: "relative",
            margin: "0px",
            color: "#FFFFFF",
            border: "1px solid #707070",
            width: widthContainer,
            marginLeft: 40,
        }}>
            <Grid container spacing={0} style={{
            }}>
                <Grid item lg={6}  >
                    <Image src={imageUrl}
                        alt={hotel?.name}
                        style={{}} width={widthImage} height={heightImage} />
                </Grid>
                <Grid item lg={6} style={{ paddingLeft: 20, paddingTop: 30 }}>
                    <div>
                        <EstablishmentName
                            establishmentId={hotel?.customAccomodationId}
                            establishmentName={hotel?.name}
                            divClass={"flex_1"}
                            titleClass={"box_1"} titleStyle={{
                                font: "24px/32px Futura Hv BT", textAlign: "left", color: "#FFFFFF",
                                marginTop: "10px",
                                cursor: "pointer",
                            }}
                        />
                    </div>
                    <div className="d-flex timeline_rating_color" style={{ marginTop: "10px" }}>
                        <Rating name="timeline_rating" precision={0.5} max={5} value={parseFloat(hotel?.starRating)} disabled={true} />
                        <div className="futura_md_bt_11_14_black margin_bottom_5 margin_left_5" style={{
                            font: "11px/15px Futura Md BT",
                            color: "#E8E8E888",
                            marginLeft: "5px",
                            bottomBottom: "5px",
                        }}>({hotel?.starRating})</div>
                    </div>


                    <div style={{
                        marginTop: "10px",
                    }}>
                        <span
                            style={{
                                width: "30%",
                                display: "inline-block",
                                font: "18px/15px Futura Lt BT",
                            }}>
                            Check in:
                    </span>

                        <span
                            style={{
                                font: "18px/15px Futura Lt BT",
                            }}>
                            {checkIn}
                            {/* <span
                                style={{
                                    font: "18px/15px Futura Md BT",
                                }}> (4:00 pm)</span> */}
                        </span>
                    </div>

                    <div style={{
                        marginTop: "10px",
                    }}>
                        <span
                            style={{
                                width: "30%",
                                display: "inline-block",
                                font: "18px/15px Futura Lt BT",
                            }}>
                            Check out:
                    </span>

                        <span
                            style={{
                                font: "18px/15px Futura Lt BT",
                            }}>
                           {checkOut}
                            {/* <span
                                style={{
                                    font: "18px/15px Futura Md BT",
                                }}> (4:00 pm)</span> */}
                        </span>
                    </div>


                    <div style={{
                        marginTop: "10px",
                    }}>
                        <span
                            style={{
                                width: "30%",
                                display: "inline-block",
                                font: "18px/15px Futura Lt BT",
                            }}>
                            Stay:
                    </span>

                        <span
                            style={{
                                font: "18px/15px Futura Lt BT",
                            }}>
                            {hotel?.lengthOfStay + " days"}
                    </span>
                    </div>


                    <div style={{
                        marginTop: "10px",
                    }}>
                        <span
                            style={{
                                width: "30%",
                                display: "inline-block",
                                font: "18px/15px Futura Lt BT",
                            }}>
                            Confirmation #:
                    </span>

                        <span
                            style={{
                                font: "18px/15px Futura Lt BT",
                            }}>
                            {bookingId}
                    </span>
                    </div>

                    <div style={{
                        marginTop: "10px",
                    }}>
                        <span
                            style={{
                                width: "30%",
                                display: "inline-block",
                                font: "18px/15px Futura Lt BT",
                            }}>
                            Address:
                    </span>

                        <span
                            style={{
                                font: "18px/15px Futura Lt BT",
                            }}>
                            {hotel?.address}
                    </span>
                    </div>




                </Grid>
            </Grid>
        </div>
    )
}

const mapStateToProps = (state) => ({
    bodyClientWidth: state.Setting.htmlBody.bodyClientWidth,
    logo_width: state.Setting.appMenu.logo.width,
    isMobile: state.Setting.is_mobile,
    switchFeatureContainer: state.FormBank.FeatureFormat,
    logo_height: state.Setting.appMenu.logo.height,

    switch_container: state.Member.switch_container,
})

const mapDispatchToProps = {
}

const TripScheduleHotel = connect(mapStateToProps, mapDispatchToProps)(TripScheduleHotel_)
export default TripScheduleHotel
