

import { Checkbox, FormControlLabel } from '@material-ui/core';
import { CheckBoxOutlineBlankOutlined, CheckBoxOutlined } from '@material-ui/icons';
import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Menu } from 'semantic-ui-react';
import './flights.css';
import { FlightsFilter } from './flightsFilters';
import { sortFlightList, resetFlightPage, updateFlightDataItem, filterByNonStop, filterByStops, clearNonStop } from './services/Flights.slice';
import { getFlightsListThunk } from './services/Flights.thunks';



export const FlightSortComponent = ({ is_mobile }) => {
    const [selectedType, setType] = React.useState('Best')
    const [selectNonStop, setNonStop] = React.useState(false)
    const initalStops = [{
        name: 'Non-Stop',
        value: 1,
        show: false
    }, {
        name: '1-Stop',
        value: 2,
        show: true
    }, {
        name: '2+Stop',
        value: 3,
        show: true
    }];

    const [stops, setStops] = React.useState(initalStops)
    const [hasNonStop, setHasNonstop] = React.useState(false)
    const flights = useSelector(state => state.Flights);
    const searchData = useSelector(state => state.Flights.searchData);
    const flightData = useSelector(state => state.Flights.flightData);
    const loading = useSelector(state => state.Flights.loading);
    const reachedEnd = useSelector(state => state.Flights.reachedEnd);

    React.useEffect(() => {
        if (flights && flights.flightListData.length > 0) {
            const nonstop = checkNonstop(flights)
            setHasNonstop(nonstop)

            //sort flight
            dispatch(sortFlightList(selectedType));

        }

        return () => {
        };
    }, [flights, flights.flightListData]);


    //apply filters persistent
    React.useEffect(() => {
        if (selectNonStop) {
            dispatch(filterByNonStop())
        }
        return () => {
        };
    }, [searchData]);



    React.useEffect(() => {
        clearStops()
        return () => {
        };
    }, [flightData]);



    const checkNonstop = (flightData) => {
        let found = false;
        if (flightData && flightData.flightListData.length > 0) {
            flightData.flightListData.forEach(data => {
                data.slice_data.forEach(slice => {
                    if (slice.flight_data.length === 1) {
                        found = true;
                    }
                })
            })
        }
        return found
    }



    const filterStops = (index) => {
        setStops((prevState) => {
            const newValues = [...prevState];
            newValues[index].show = !newValues[index].show;
            if (index === 0) {
                filterNonStop()
            }
            else {
                dispatch(filterByStops(newValues[index].value));
            }


            let onlyNonstop = true;
            newValues.forEach((value, valueIndex) => {
                if (valueIndex !== 0) {
                    if (value.show) {
                        onlyNonstop = false;
                    }
                }
                else {
                    if (value.show) {
                        onlyNonstop = true;
                    }
                    else {
                        onlyNonstop = false;
                    }
                }
            })

            if (onlyNonstop) {
                setNonStop(true)
            } else {
                setNonStop(false)
            }

            return newValues;
        })
    }


    const filterNonStop = () => {
        if (!selectNonStop) {
            selectNonstop()
        }
        else {
            clearStops()

        }
    }



    const selectNonstop = () => {
        setStops((prevState) => {
            const newValues = [...prevState];
            newValues[0].show = true;
            newValues[1].show = false;
            newValues[2].show = false;
            return newValues;
        })
        dispatch(filterByNonStop())
        setNonStop(true)


    }
    const clearStops = () => {
        dispatch(clearNonStop())
        setNonStop(false)
        setStops(initalStops);
    }

    const selectedClass = useSelector(state => state.Flights.flightData.cabinClass);
    const flightType = ['Cheapest', 'Best', 'Quickest', 'Earliest'];
    const flightClass = [{ name: 'Economy', value: 'economy' },
    { name: 'Premium Economy', value: 'premium' }, { name: 'Business', value: 'business' },
    { name: 'First', value: 'first' }];

    const dispatch = useDispatch();

    const selectType = (value) => {
        setType(value)
        dispatch(sortFlightList(value));
    }


    const selectClass = async (value) => {
        await dispatch(updateFlightDataItem({ type: 'class', value: value }))
        dispatch(resetFlightPage())
        dispatch(getFlightsListThunk())
    }



    return (
        <div style={{
            backgroundColor: "#FFFFFF",
            position: "sticky",
            height: "auto",
            top: 45, paddingTop: 20,
            paddingBottom: 20,
            zIndex: 2
        }}>


            <FlightsFilter filterStops={filterStops} stops={stops} clearStops={clearStops} />





            <Menu pointing secondary style={{
                marginTop: 20,
                borderBottom: "10px solid var(--lightGrayF)",
                paddingLeft: is_mobile ? 0 : '22%',
                width: is_mobile ? "78%" : "100%"
            }}>

                {flightType.map((type, index) => {
                    return (
                        <div key={index}
                            style={{
                                letterSpacing: "0",
                                backgroundColor: "initial",
                                opacity: "1",
                                textAlign: "center",
                                height: 40,
                                width: "130px",
                                paddingTop: "0px",
                                paddingLeft: is_mobile ? 0 : "15px",
                                paddingRight: "15px",
                                color: selectedType === type ? "#19BC9B" : '#000000',
                                borderBottom: selectedType === type ? '10px solid #19BC9B' : '10px solid var(--lightGrayF)',
                                marginBottom: -48,
                                marginRight: is_mobile ? 0 : 40,
                                marginLeft: is_mobile ? -15 : 0,
                                cursor: "pointer",
                            }}
                            onClick={() => selectType(type)}>
                            <p style={{ verticalAlign: "middle", font: "18px/18px Futura Md BT" }}>{type}</p>
                        </div>
                    )

                })}
                {!is_mobile && <div
                    style={{
                        letterSpacing: "0",
                        backgroundColor: "initial",
                        opacity: hasNonStop ? "1" : '0.2',
                        textAlign: "center",
                        height: 40,
                        width: "100%",
                        marginTop: "-6px",
                        paddingLeft: "15px",
                        paddingRight: "15px",
                        marginBottom: -48,
                        marginRight: 40,
                        cursor: "pointer",
                    }}
                    onClick={hasNonStop ? filterNonStop : null}>
                    <NonStopCheckBox label={stops[0].name} checked={selectNonStop} />
                </div>}
            </Menu>

            <div style={{ marginTop: 10, paddingLeft: is_mobile ? 12 : '22%', textAlign: is_mobile ? 'center' : 'inherit', display: is_mobile ? 'block' : 'inline', width: is_mobile ? "100%" : 400 }}>
                {flightClass.map((classType, index) => {
                    return (
                        <span key={index}
                            style={{
                                letterSpacing: "0",
                                font: is_mobile ? "14px/14px Futura Md BT" : "16px/16px Futura Md BT",
                                opacity: "1",
                                textAlign: "center",
                                height: 40,
                                width: "130px",
                                paddingTop: "0px",
                                paddingLeft: is_mobile ? 5 : "55px",
                                paddingRight: is_mobile ? 5 : "55px",
                                color: selectedClass === classType.value ? "#19BC9B" : '#7F7F7F',
                                cursor: "pointer",
                            }}
                            onClick={() => selectClass(classType.value)}>
                            {classType.name}
                        </span>
                    )

                })}
            </div>

            {is_mobile && <div
                style={{
                    letterSpacing: "0",
                    backgroundColor: "initial",
                    opacity: hasNonStop ? "1" : '0.2',
                    textAlign: "right",
                    height: 40,
                    width: "100%",
                    marginTop: 10,
                    paddingLeft: "15px",
                    paddingRight: "15px",
                    marginBottom: 0,
                    marginRight: 40,
                    cursor: "pointer",
                }}
                onClick={hasNonStop ? filterNonStop : null}>
                <NonStopCheckBox label={stops[0].name} checked={selectNonStop} />
            </div>}

        </div>
    )
}


export const NonStopCheckBox = ({ label, checked }) => {
    return (
        <FormControlLabel
            control={
                <Checkbox
                    checked={checked}
                    icon={<CheckBoxOutlineBlankOutlined style={{ font: '22px/22px Futura Hv BT', marginTop: -5, color: '#000000' }} />}
                    checkedIcon={<CheckBoxOutlined style={{ font: '22px/22px Futura Hv BT', marginTop: -5, color: '#000000' }} />}
                    name={label}
                />
            }
            style={{ display: 'block', marginBottom: -5 }}
            label={<span style={{ font: "18px/18px Futura Md BT", color: '#000000' }}>
                {label}
            </span>}
        />
    )
}
