import {
    DeleteIcon,
    RightArrowIcon,
    StarIcon,
} from "../SVGBank"

import React from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import * as Actions from '../../../../redux/actions'
import { Grid } from "@material-ui/core"
import { AirplaneComponent, FlightDetailComponent } from "../../FlightsPage/flightsComponents"
import { secondsToHms, tConv24 } from "../../../../utility/utility"
import '../../FlightsPage/flights.css';
import moment from "moment"
import { useHistory, useLocation } from "react-router-dom"
import { resetFlightPage, updateFlightData } from "../../FlightsPage/services/Flights.slice"
import { deleteFlightTimeline, getFlightsListThunk } from "../../FlightsPage/services/Flights.thunks"



const TimelineSavedFlight_ = ({ flight, nextFlight,switchFeatureContainer }) => {
    const departureDate = flight?.departure_date ? moment(flight.departure_date).format('MMM Do') : "";
    const arrivalDate = flight?.arrival_date ? moment(flight.arrival_date).format('MMM Do') : "";
    const history = useHistory()
    const booked = flight?.booked_booking_status === "ACCEPTED";
    const selectedCurrency = useSelector(state => state.Setting.currency.selected.shortCode);
    const {uuid} = useSelector(state => state.Member.authModal);
    const activeTrip = useSelector(state => state.FormBank.TimelineWizard.activeTrip);
    const activeTripId = activeTrip?.tripId;
    const fullCurrency = selectedCurrency ? selectedCurrency.toUpperCase() : "CAD";
    const email = flight?.booked_email;
    const bookingId = flight?.priceline_trip_number;
    const isBooked =  flight?.booked_booking_status === "Success";
    const dispatch = useDispatch()
    const location = useLocation()


    const bookNow = () => {

    }

    const deleteFlight = () => {
        const tripDestId = flight?.trip_destinations_id;
        const flightId = flight?.id;

        const payload= {
            uuid: uuid,
            tripId: activeTripId,
            postData:{
                tripDestinationId:tripDestId,
                flightId:flightId
            },
            wait: nextFlight? true:false
        }

     

        if(uuid){
           dispatch(deleteFlightTimeline(payload))
        }

        ///if theres another flight delete both
        if(nextFlight){
            const payload2= {
                uuid: uuid,
                tripId: activeTripId,
                postData:{
                    tripDestinationId:tripDestId,
                    flightId:nextFlight?.id
                }
            }

            if(uuid){
               dispatch(deleteFlightTimeline(payload2))
            }
        }
        
    }



    const goToBooking = () => {

        if (email && bookingId) {
            const url = `/flight/booking_details?booking_id=${bookingId}&email=${email}`;
            window.open(url.toLowerCase());
        }
    }


    const searchFlight = () => {
        const flights = [
            {
                "airportFrom": {
                    "country": "",
                    "coordinate": "",
                    "city": flight?.departure_city,
                    "latitude": "",
                    "city_code": flight?.departure_iata,
                    "type": "main",
                    "country_code": "",
                    "cityid_t": "",
                    "state_name": "",
                    "cityid_ppn": "",
                    "rank": "",
                    "cityid_air": "",
                    "state_code": "",
                    "longitude": "",
                    "score": "",
                    "iata": flight?.departure_iata,
                    "airport": "All Airports"
                },
                "airportTo": {
                    "country": "",
                    "coordinate": "",
                    "city": flight?.arrival_city,
                    "latitude": "",
                    "city_code": flight?.arrival_iata,
                    "type": "main",
                    "country_code": "",
                    "cityid_t": "",
                    "state_name": "",
                    "cityid_ppn": "",
                    "rank": "",
                    "cityid_air": "",
                    "state_code": "",
                    "longitude": "",
                    "score": "",
                    "iata": flight?.arrival_iata,
                    "airport": "All Airports"
                },
                "departureDateFormat": flight?.departure_date ? new Date(moment(flight?.departure_date).format("YYYY-MM-DD 00:00")) : new Date().toDateString(),
                "departureDate": flight?.departure_date ? new Date(moment(flight?.departure_date).format("YYYY-MM-DD 00:00")) : new Date().toDateString(),
                "availableSelectDate": new Date().toDateString(),
                "positionId": "d-0"
            }]
        const dataToBookingFlights = {
            flightMode: 'one_way',
            cabinClass: 'economy',
            numberAdult: '1',
            numberChild: '0',
            flights: flights,
            currency: fullCurrency

        }
        const path = location.pathname;

        dispatch(updateFlightData(dataToBookingFlights))
        dispatch(resetFlightPage())
        dispatch(getFlightsListThunk(dataToBookingFlights))

        if (path !== '/flight/search') {
            history.push('/flight/search')
        }
    }

    
    return (
        <div >
            <Grid container style={{
                height: 'auto', padding: '20px 0px 0px 0px', backgroundColor: "var(--mainWhite)",
                marginLeft: "12px",
                marginRight: "0px",
                marginBottom: "12px", marginTop: "20px", overflow: 'auto'
            }}>
                {!isBooked && <div style={{
                    cursor: "pointer", textAlign: "right", position: "absolute",
                    right: '10px', marginTop: '-10px',zIndex:10
                }}
                    onClick={deleteFlight}
                >
                    <DeleteIcon width={14} height={16} style={{ marginRight: "0px" }} />
                </div>}
                <img src={flight?.airline_logo} height={25} alt="logo" style={{ paddingLeft: 20 }} />

                <Grid container spacing={0} className="flightTimeline" >
                    <div className='line' style={{ zIndex: 0 }}></div>

                    <Grid item xs={12} md={4} lg={4} xl={4} style={{ width: 100, zIndex: 1, marginLeft: -15 }} >
                        <div style={{ textAlign: 'right', background: 'white' }}>
                            <FlightDetailComponent flightText={'Departs From'} airportCode={flight.departure_iata}
                                country={flight.departure_city} date={departureDate} time={tConv24(flight.departure_time)}
                                fromTimeline={true} />
                        </div>
                    </Grid>

                    <Grid item xs={12} md={4} lg={4} xl={4} style={{ width: 100, zIndex: 1 }}>
                        <div onClick={searchFlight} style={{ marginTop: 49, cursor: "pointer" }}>
                            <AirplaneComponent time={secondsToHms(flight.overall_duration)} />
                        </div>
                    </Grid>
                    <Grid item xs={12} md={4} lg={4} xl={4} style={{ marginLeft: 6, zIndex: 1 }}>
                        <div style={{ textAlign: 'left', background: 'white' }}>
                            <FlightDetailComponent flightText={'Arrive at'} airportCode={flight.arrival_iata}
                                country={flight.arrival_city} date={arrivalDate} time={tConv24(flight.arrival_time)}
                                fromTimeline={true} />
                        </div>
                    </Grid>

                </Grid>

                {<div onClick={booked ? goToBooking : searchFlight} style={{
                    borderTop: "1px solid rgb(232, 232, 232)", width: "100%", marginTop: 10, textAlign: "center",
                    font: "14px/16px Futura Md BT", cursor: "pointer", color: "#19BC9B", paddingTop: "15px", paddingBottom: "15px",
                }}>{booked ? 'Flight Booking Details' : 'Book Now'}</div>
                }
            </Grid>

        </div>
    )
}

const mapStateToProps = (state) => ({
    switchFeatureContainer: state.FormBank.FeatureFormat,

})

const mapDispatchToProps = {
}

const TimelineSavedFlight = connect(mapStateToProps, mapDispatchToProps)(TimelineSavedFlight_)
export default TimelineSavedFlight
