import { BusIcon, CarIcon, FlightShapeIcon, TrainDuotoneIcon, WalkIcon,BicycleIcon } from "../SVGBank"
import * as Actions from '../../../../redux/actions'
import Divider from '@material-ui/core/Divider'
import Fab from '@material-ui/core/Fab'
import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { makeStyles } from '@material-ui/core/styles'
import ClickAwayListener  from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import { useLocation } from 'react-router-dom';

const TimeToReach_ = ({activeTrip, activity, fillIcon, color, fabColor = "", textColor = "",uuid ,
setTimeToReachOptions,lang,fetchTimeToReachOptions,timeToReachOptions,indexActivity}) => {
    const time = activity && activity.timeToReach? activity.timeToReach.time: "00:30"
    const transportMode = activity && activity.timeToReach? activity.timeToReach.code: "car"
    const classes  = useStyles();

    const options = timeToReachOptions ? [...timeToReachOptions] : null;
    if(options && activity && activity.timeToReach ){
        options.forEach(function(item,i){
            if(item.code === activity.timeToReach.code){
                options.push(options.splice(i, 1)[0]);
            }
          });
    }
    const location = useLocation()
    const isPrintPage =  location && location.pathname.includes("print");

    const [open, setOpen] = React.useState(false);

    // console.log(activity.activityID)
    const anchorRef = React.useRef(null);

    const handleToggle = () => {
        setOpen(prevOpen => !prevOpen);
        const id  = activity?.activityID || null;
        fetchTimeToReachOptions(lang,uuid,id)
    };
    const handleClose = event => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    //const time = activity.timeToReach.time
    //const transportMode = activity.timeToReach.code
    //console.log(`TimeToReach ${time}`)
    //console.log(`TimeToReach ${transportMode }`)
    const activityTimeToReach = (time) => {
        const timeToreach = moment(time, "HHmm").format('H') === "0" ?
        moment(time, "HHmm").format('m') + "min" :
        (moment(time, "HHmm").format('m') === "0" ?
            moment(time, "HHmm").format('H') + "h" :
            moment(time, "HHmm").format('H') + "h" + moment(time, "HHmm").format('m') + "min")
        return timeToreach;  
    }
    
    const getIcon = (option) =>{
        if(option.code === "walk"){
            return <WalkIcon fill={transportMode===option.code ? fillIcon: "#C7C7C7"} />
        }
        if(option.code === "public_transport"){
            return <BusIcon fill={transportMode===option.code ? fillIcon: "#C7C7C7"}  />
        }
        if(option.code === "bicycle"){
            return <BicycleIcon fill={transportMode===option.code ? fillIcon: "#C7C7C7"}  />
        }
        if(option.code === "car"){
            return <CarIcon fill={transportMode===option.code ? fillIcon: "#C7C7C7"}  />
        }
        if(option.code === "plane"){
            return <FlightShapeIcon fill={transportMode===option.code ? fillIcon: "#C7C7C7"} />
        }
        if(option.code === "train"){
            return <TrainDuotoneIcon fill={transportMode===option.code ? fillIcon: "#C7C7C7"} />
        }
    }
    
    const setTimeToReach = (timeToReach) =>{
        const tripId = activeTrip?.tripId;
        setTimeToReachOptions(lang,uuid,activity.activityID,timeToReach,tripId) 
        setOpen(false);
    }

    const checkHotel = () =>{
        let typeStay = false;
        activity.activityTypes && activity.activityTypes.forEach(element => {
            if((element.typeName).toLowerCase().includes("stay")){
                typeStay = true;
            }
        });
        if(indexActivity === 0 && typeStay ){
            return true;
        }
        if(indexActivity !== 0 ){
            return true;
        }
        return false;
    }

    return (
        <div>
            <div style={{ textAlign: "center", }}>
                <div style={{ display: "inline-block", }}>
                   <div style={{ display: "flex", flexDirection: "column", alignItems: "center", position: "relative" }}>
                   {checkHotel() && <Divider orientation="vertical" style={{ display: "inline-block", backgroundColor: color, height: "20px" }} />}

                        { (uuid !== undefined && uuid !== "" || isPrintPage) && checkHotel() &&
               <div>
                <div
                    ref={anchorRef}
                    aria-controls={open ? 'menu_ellipsis' : undefined}
                    aria-haspopup="true"
                    onClick={handleToggle}
                    className={classes.button_text}
                    style={{ cursor: "pointer", }}
                >
                     <Fab size="small" disabled={true} 
                     style={{
                            outline: "0px",
                            backgroundColor: fabColor === "" ? "#27467433" : fabColor,
                        }}>
                            {(transportMode === "walking" || transportMode === "walk") && <WalkIcon fill={fillIcon} />}
                            {(transportMode === "bus" ||  transportMode === "public_transport" )&& <BusIcon fill={fillIcon} />}
                            {transportMode === "bicycle" && <BicycleIcon fill={fillIcon} />}
                            {transportMode === "car" && <CarIcon fill={fillIcon} />}
                            {transportMode === "plane" && <FlightShapeIcon fill={fillIcon} />}
                            {transportMode === "train" && <TrainDuotoneIcon fill={fillIcon} />}
                        </Fab>
                </div>

                <ClickAwayListener onClickAway={handleClose}>
                    <Popper
                        open={open}
                        anchorEl={anchorRef.current}
                        className={classes.positionZIndex}

                        role={undefined}
                        transition
                        disablePortal
                        placement={'bottom-end'}
                        modifiers={{
                            flip: {
                                enabled: false,
                            },
                        }}

                    >
                        {({ TransitionProps, placement }) => (
                            <Grow
                                {...TransitionProps}
                                style={{ transformOrigin: placement, }}
                                {...({ timeout: 1000 })}
                            >
                                <Paper >
                                    <MenuList

                                        autoFocusItem={false}
                                        disableListWrap={true}
                                        dense={false}
                                        id="menu_ellipsis" 
                                        >
                                       
                        {options && options.map((option, option_index) => {
                            return <MenuItem className={classes.backgroundSelection} key={option_index} onClick={()=>setTimeToReach(option)} >
                            <Fab size="small" disabled={true} 
                     style={{
                            outline: "0px",
                            backgroundColor: "#E8E8E8",
                        }}>
                            
                           {getIcon(option)} 
                        </Fab>
                           &nbsp;  {activityTimeToReach(option.time)}
                           
                               </MenuItem>
                        })}           
                                    </MenuList>
                                </Paper>
                            </Grow>
                        )}
                    </Popper>

                </ClickAwayListener>
                </div>}
                    { checkHotel() && <>
                        <Divider orientation="vertical" style={{ display: "inline-block", backgroundColor: color, height: "20px" }} />
                        <div style={{ position: "absolute", top: "30px", left: "50px", color: textColor === "" ? "#191919" : textColor }}>{activityTimeToReach(time)}
                        </div>
                    </>}
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
    activeDay: state.FormBank.TimelineWizard.activeDay,
    timeToReachOptions:state.FormBank.TimelineWizard.timeToReachOptions,
    uuid: state.Member.authModal.uuid,
    lang: state.Setting.lang,
    activeTrip: state.FormBank.TimelineWizard.activeTrip,
    switchFeatureContainer: state.FormBank.FeatureFormat,
}
}

const mapDispatchToProps = {
    fetchTimeToReachOptions: Actions.fetchTimeToReachOptions,
    setTimeToReachOptions: Actions.setTimeToReachOptions

}

const TimeToReach = connect(mapStateToProps, mapDispatchToProps)(TimeToReach_)
export default TimeToReach



/*  inject css to menu component */
const useStyles = makeStyles((theme) => {

    return {
        root: {
            display: 'inline-block',
        },
        button_text: {
            color: "var(--mainGreen)",
            textAlign: "left",
            font: "16px/22px Futura Md BT",
            letterSpacing: "0",
            '&:focus': {
                border: "0",
                outline: "0px"
            }
        },
        positionZIndex: {
            top: "-210px !important",
            left: "81px !important",
            zIndex: "34 !important",
            width: 137



        },



        paddingLeft30: {
            paddingLeft: "30px"
        },
        backgroundSelection: {
            '&:hover': {
                backgroundColor: ""
            },
            minHeight: "10px",
        },
        paper: {
            marginRight: theme.spacing(2),
        },

        hr: {

            width: "100%",
            height: "1px",
            display: "block",
            position: "relative",
            marginRight: "10%",
            marginTop: "5px",
            marginBottom: "5px",
            border: "1px solid rgba(0,0,0,.05)",
        },
        timesDiv: {
            marginRight: "10px",
            marginLeft: "115px",
            display: "inline-block",
            textAlign: "right",
        },

    }
});
