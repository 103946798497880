import * as Actions from '../../../redux/actions'

import Button from '@material-ui/core/Button';
import { CopyIcon } from "./SVGBank"
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import React from 'react';
import TextField from '@material-ui/core/TextField';
import { connect } from "react-redux";

/* modify trip name dialog function component */

const ModifyTripNameDialog_ = ({ from ,trip, uuid,activeTrip, copyTrip, setToggleEllipsisCloseDisable, setToggleEllipsisCloseEnable, toggle_ellipsis_close_disable, inline }) => {
    const selectedTrip = trip && trip?.tripID ? trip:activeTrip;
    const [tripNameValue, setTripNameValue] = React.useState(selectedTrip.tripName);
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setToggleEllipsisCloseDisable()
        setOpen(true);
    };

    const handleClose = () => {
        setToggleEllipsisCloseEnable()
        setOpen(false);
    };

    const handleChangeTripNameField = event => {
        setTripNameValue(event.target.value);
    };
    const handleChangeTripName = (trip, name) => {
        copyTrip(trip.tripID, name, trip, uuid)
        setOpen(false);
        setToggleEllipsisCloseEnable()
    };

    return (
        <div>
            <div onClick={handleClickOpen}>
                {inline ? "" : <CopyIcon />}
                {!inline && toggle_ellipsis_close_disable ? "" : from}
            </div>
            <Dialog open={open}
                aria-labelledby={open ? 'form_dialog_title' : undefined}
            >
                <DialogTitle id={open ? "form_dialog_title" : undefined}>{selectedTrip?.tripName + " (Copy)"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {"To copy trip, please enter trip name here."}
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="Text Field"
                        label="Trip Name"
                        fullWidth
                        value={tripNameValue === undefined ? selectedTrip?.tripName : tripNameValue }
                        onChange={handleChangeTripNameField}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={() => handleChangeTripName(selectedTrip, tripNameValue === undefined ? selectedTrip?.tripName : tripNameValue )} color="primary">
                        <CopyIcon />{"Copy"}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

const mapStateToProps = state => ({
    lang: state.Setting.lang,
    uuid: state.Member.authModal.uuid,
    trip: state.Member.memberArea.selectedTrip,
    activeTrip: state.FormBank.TimelineWizard.activeTrip,
    toggle_ellipsis_close_disable: state.Member.memberArea.toggle_ellipsis_close_disable,
});

const mapDispatchToProps = {
    copyTrip: Actions.copyTrip,
    setToggleEllipsisCloseDisable: Actions.setToggleEllipsisCloseDisable,
    setToggleEllipsisCloseEnable: Actions.setToggleEllipsisCloseEnable,
}
const ModifyTripNameDialog = connect(mapStateToProps, mapDispatchToProps)(ModifyTripNameDialog_);
export default ModifyTripNameDialog 