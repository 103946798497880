import {
    FETCH_API_ERROR_MEMBER_ACTIONS,
    FETCH_DONE_SETS_MEMBER_ACTIONS,
    FETCH_FEATURE_MEMBER_ACTIONS,
    FETCH_FOLLOWERS_SETS,
    FETCH_HEART_SETS_MEMBER_ACTIONS,
    FETCH_MEMBER_ACTIONS_MEMBER_ACTIONS,
    FETCH_MEMBER_ACTION_SETS,
    FETCH_PLUS_SETS_MEMBER_ACTIONS,
    FETCH_STAR_SETS_MEMBER_ACTIONS,
    IS_FETCHED_FEATURES_MEMBER_ACTIONS,
    IS_FETCHED_FOLLOWERS_SETS,
    IS_FETCHED_HEART_MEMBER_ACTIONS,
    IS_FETCHED_DONES_MEMBER_ACTIONS,
    IS_FETCHED_MEMBER_ACTIONS_MEMBER_ACTIONS,
    IS_FETCHED_PLUS_MEMBER_ACTIONS,
    IS_FETCHED_STARS_MEMBER_ACTIONS,
    SET_COOKIES_MEMBER_ACTIONS_SETS,
    SET_SELECTED_MEMBER_ACTIONS,
} from "../interfaces/types"

const initialState = {
    /* listing actions */
    memberActionSets: {
        features: [],
        memberActions: [],
    },
    /* add or delete actions */
    currentMemberActions: {
        unitID: null,
        feature: null,
        action: null,
    },
    cookiesMemberActionSets: [],
    fetchStarSets: {
        stars: [],
        isFetched: false,
    },
    fetchFavoriteSets: {
        favorites: [],
        isFetched: false,
    },
    fetchPlusSets: {
        pluses: [],
        isFetched: false,
    },
    fetchDoneSets: {
        dones: [],
        isFetched: false,
    },

    fetchFeatures: {
        features: [],
        isFetched: false,
    },
    fetchMemeberActions: {
        memberActions: [],
        isFetched: false
    },
    fetchFollowers: {
        followersSets: [],
        isFetched: false
    },
    apiError: false,

}
export default (state = initialState, action) => {
    switch (action.type) {

        case SET_SELECTED_MEMBER_ACTIONS:
            return {
                ...state,
                currentMemberActions: {
                    ...state.currentMemberActions,
                    unitID: action.unitID,
                    feature: action.feature,
                    action: action.action
                }
            };
        case SET_COOKIES_MEMBER_ACTIONS_SETS:
            return {
                ...state,
                cookiesMemberActionSets: action.cookies_member_action_sets
            };
        case FETCH_FEATURE_MEMBER_ACTIONS:
            return {
                ...state,
                fetchFeatures: {
                    ...state.fetchFeatures,
                    features: action.features,
                }
            };
        case FETCH_STAR_SETS_MEMBER_ACTIONS:
            return {
                ...state,
                fetchStarSets: {
                    ...state.fetchStarSets,
                    stars: action.stars,
                }
            };
        case IS_FETCHED_STARS_MEMBER_ACTIONS:
            return {
                ...state,
                fetchStarSets: {
                    ...state.fetchStarSets,
                    isFetched: action.is_fetched,
                }
            };
        case FETCH_HEART_SETS_MEMBER_ACTIONS:
            return {
                ...state,
                fetchFavoriteSets: {
                    ...state.fetchFavoriteSets,
                    favorites: action.favorites,
                }
            };
        case IS_FETCHED_HEART_MEMBER_ACTIONS:
            return {
                ...state,
                fetchFavoriteSets: {
                    ...state.fetchFavoriteSets,
                    isFetched: action.is_fetched,
                }
            };
        case FETCH_PLUS_SETS_MEMBER_ACTIONS:
            return {
                ...state,
                fetchPlusSets: {
                    ...state.fetchPlusSets,
                    pluses: action.pluses,
                }
            };
        case IS_FETCHED_PLUS_MEMBER_ACTIONS:
            return {
                ...state,
                fetchPlusSets: {
                    ...state.fetchPlusSets,
                    isFetched: action.is_fetched,
                }
            };
        case FETCH_DONE_SETS_MEMBER_ACTIONS:
            return {
                ...state,
                fetchDoneSets: {
                    ...state.fetchDoneSets,
                    dones: action.dones,
                }
            } ;
            case IS_FETCHED_DONES_MEMBER_ACTIONS:
                return {
                    ...state,
                    fetchDoneSets: {
                        ...state.fetchDoneSets,
                        isFetched: action.is_fetched,
                    }
                };
        case IS_FETCHED_FEATURES_MEMBER_ACTIONS:
            return {
                ...state,
                fetchFeatures: {
                    ...state.fetchFeatures,
                    isFetched: action.is_fetched,
                }
            };
        case FETCH_MEMBER_ACTIONS_MEMBER_ACTIONS:
            return {
                ...state,
                fetchMemeberActions: {
                    ...state.fetchMemeberActions,
                    memberActions: action.member_actions
                }
            };
        case IS_FETCHED_MEMBER_ACTIONS_MEMBER_ACTIONS:
            return {
                ...state,
                fetchMemeberActions: {
                    ...state.fetchMemeberActions,
                    isFetched: action.is_fetched,
                }
            };
        case FETCH_FOLLOWERS_SETS:
            return {
                ...state,
                fetchFollowers: {
                    ...state.fetchFollowers,
                    followersSets: action.followers,
                }
            };
        case IS_FETCHED_FOLLOWERS_SETS:
            return {
                ...state,
                fetchFollowers: {
                    ...state.fetchFollowers,
                    isFetched: action.is_fetched,
                }
            };
        case FETCH_API_ERROR_MEMBER_ACTIONS:
            return {
                ...state,
                apiError: action.api_error
            };
        case FETCH_MEMBER_ACTION_SETS:
            return {
                ...state,
                memberActionSets: {
                    ...state.memberActionSets,
                    features: action.features,
                    memberActions: action.member_actions,
                }
            };
       
        default:
            return {
                ...state
            }
    }
}