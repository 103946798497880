
import { createAsyncThunk } from "@reduxjs/toolkit";
import { environment } from "../../../Environments/environment";
import { ALERT_BARS_HANDLE, PostAsyncHelper, PostAsyncHelperImage } from "../../../redux/interfaces/types";

export const uploadMediaPhoto = createAsyncThunk(
  'feature/uploadMediaPhoto',
  async (query, thunkAPI) => {
    const dispatch = thunkAPI.dispatch;
    try {

      const postData = query;
      let apiURL = environment.apiUrl + "media/uploadPhoto";

      const response = await PostAsyncHelperImage(apiURL, postData);

      if (response && response.data) {
        dispatch({
          type: ALERT_BARS_HANDLE,
          message_type: "success",
          message: "Saved!"
        })
        return response.data;
      }

      dispatch({
        type: ALERT_BARS_HANDLE,
        message_type: "error",
        message: "Photo upload failed, please try again"
      })
    }
    catch {
      dispatch({
        type: ALERT_BARS_HANDLE,
        message_type: "error",
        message: "Photo upload failed, please try again"
      })
      return null;
    }
  }
)


export const uploadMediaVideo = createAsyncThunk(
  'feature/uploadMediaVideo',
  async (query, thunkAPI) => {
    const dispatch = thunkAPI.dispatch;
    try {

      const postData = query;
      let apiURL = environment.apiUrl + "media/uploadVideo";

      const response = await PostAsyncHelperImage(apiURL, postData);

      if (response && response.data) {
        dispatch({
          type: ALERT_BARS_HANDLE,
          message_type: "success",
          message: "Saved!"
        })
        return response.data;
      }

      dispatch({
        type: ALERT_BARS_HANDLE,
        message_type: "error",
        message: "Video upload failed, please try again"
      })
    }
    catch {
      dispatch({
        type: ALERT_BARS_HANDLE,
        message_type: "error",
        message: "Video upload failed, please try again"
      })
      return null;
    }
  }
)