import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import Cookies from 'js-cookie';
import { environment } from '../../../../Environments/environment';
import { setTripToTimeline } from '../../../../redux/actions';
import {
    PostAsyncHelper, SET_TIMELINE_WIZARD_STATUS, ALERT_BARS_HANDLE,
} from '../../../../redux/interfaces/types';



export const createTripThunk = createAsyncThunk(
    'personalizedTrip/createTrip',
    async (tripObj, thunkAPI) => {
        let apiURL = environment.apiUrl + "memberarea/trip/generatePersonalizedTrip/" + tripObj.lang + "/" + tripObj.uuid;
        const response = await PostAsyncHelper(apiURL, tripObj);
        const dispatch = thunkAPI.dispatch;
        dispatch({
            type: SET_TIMELINE_WIZARD_STATUS,
            status: true
        })
     
        try {
        const trip = response?.data?.result?.trip
        if (trip) {
            
           
            dispatch({
                type: ALERT_BARS_HANDLE,
                message_type: "success",
                message: trip.trip_name + " created"
            })
            const tripID = trip.id || null;
            if(tripID){
                dispatch(setTripToTimeline(tripID, tripObj?.uuid))
            }
        
            return trip;
        }
        dispatch({
            type: ALERT_BARS_HANDLE,
            message_type: "error",
            message: 'Could not create trip, please try again later'
        })
        return thunkAPI.rejectWithValue(false) 
    }
    catch(err){
        dispatch({
            type: ALERT_BARS_HANDLE,
            message_type: "error",
            message: err.response.data || 'Could not create trip, please try again later'
        })
        return thunkAPI.rejectWithValue(false) 
    }
    }
)


export const createPersonalizedTrip = createSlice({
    name: 'personalizedTrip',
    initialState: {
        modalOpen: false,
        tripAdded: false,
    },
    reducers: {
        toggleModal: (state) => {
            state.modalOpen = !state.modalOpen;
        },
    },
    extraReducers: {
        [createTripThunk.pending]: (state, action) => {
        },
        [createTripThunk.fulfilled]: (state, action) => {
            state.tripAdded = true;
        },
    },
})

// Action creators are generated for each case reducer function
export const { toggleModal } = createPersonalizedTrip.actions

export const PersonalizedTripReducer = createPersonalizedTrip.reducer;