import * as Actions from '../../../../redux/actions'

import { HeartEmptyIcon, HeartIcon } from '../SVGBank'

import React from 'react'
import { connect } from "react-redux"
import { LightTooltip } from './tooltipActions'

export const Heart = connect((state) => ({
    screenSize: state.Setting.htmlBody.bodyClientWidth,
    isMobile: state.Setting.is_mobile,
    fetchMemeberActions: state.MemberActions.fetchMemeberActions,
    fetchFeatures: state.MemberActions.fetchFeatures,
    fetchFavoriteSets: state.MemberActions.fetchFavoriteSets,
    uuid: state.Member.authModal.uuid,
}), {
    addingHeart: Actions.addingHeart,
    deletingHeart: Actions.deletingHeart,
})(({
    fill,
    uuid,
    feature,
    // isMobile,
    // fetchMemeberActions,
    fetchFeatures,
    fetchFavoriteSets,
    // screenSize,
    addingHeart,
    deletingHeart,
    unitID,
    caption,
}) => {


  

    const unique = unitID + "_" + feature.featureId + "_favorite"
    return (
        <div style={{
            font: "14px / 14px Futura Lt BT",
        }}>

            {
                uuid !== undefined && uuid !== "" &&
                <div>
                    {fetchFavoriteSets !== undefined && fetchFeatures.isFetched &&
                        fetchFavoriteSets.favorites.filter(Heart => Heart.featureUnitId + "_" + Heart.featureId + "_favorite" === unique).length <= 0 ?
                        <div>
                        {caption? 
                        
                        <div style={{ cursor: "pointer", color: fill,      padding: '20px',
                        paddingRight: '40px',
                        margin: '-20px'}} onClick={() => addingHeart(uuid, unitID, feature)}>
                            
                            <HeartEmptyIcon fill={fill} width={25} height={25} /> &nbsp; Love it
                          
                        </div>:
                        
                        <LightTooltip title="Love it" placement="top-start">
                        <div style={{ cursor: "pointer", color: fill }} onClick={() => addingHeart(uuid, unitID, feature)}>
                            
                            <HeartEmptyIcon fill={fill} width={25} height={25} />
                          
                        </div>
                        </LightTooltip>}
                        </div>
                        :
                        <div>
                        {caption? 
                        
                        <div style={{ cursor: "pointer", color: fill,      padding: '20px',
                        paddingRight: '40px',
                        margin: '-20px'}} onClick={() => deletingHeart(uuid, unitID, feature)}>
                        <HeartIcon fill={fill} width={25} height={25} /> &nbsp; Loved
                         </div>:
                        
                        <LightTooltip title="Loved" placement="top-start">
                         <div style={{ cursor: "pointer", color: fill }} onClick={() => deletingHeart(uuid, unitID, feature)}>
                            <HeartIcon fill={fill} width={25} height={25} />
                        </div>
                        </LightTooltip>}
                        </div>

                       
                    }
                </div>
                // :
                // <div>
                //     {
                //         <div style={{ cursor: "pointer" }} onClick={() => console.log("force login")}>
                //             <HeartEmptyIcon fill={fill} width={25} height={25} />
                //         </div>

                //     }
                // </div>
            }
        </div>
    )
})