import "./WhyJoin.css"

import { ArrowLeftIcon, ArrowRightIcon, RightArrowIcon } from "../../Common/SVGBank"

import React from 'react'
import Slider from 'react-slick'
import { connect } from 'react-redux'
import { useHistory } from "react-router-dom"
import { TrackEvent } from "../../../../utility/utility"
import { Grid } from "@material-ui/core"

const WhyJoin_ = ({ homepageMuverStyle, lang, is_mobile }) => {

  const history = useHistory()

  const goesLandingPage = () => {
    TrackEvent('HOME', "Click become a muver", "landing")
    history.push("/landing")
  }


  return (
    <div className="whyJoin">

      <div className="title text-center">Why Join?</div>

      <div className="text-center subtitle" style={{
        font: '27px/30px Futura Lt BT', marginTop: -20
      }}>
        Make your travel journey a breeze. Get personalized suggestions, connect with like-minded travelers & locals, get group discounts.
        </div>


      <div>
        <Grid container style={{ margin: '6% 0px 3% 0px' }}  spacing={4}>
          <Grid item xs={6} md={4} style={{ marginBottom: '2%',textAlign:'center' }}>
            <img src={require("../../../../assets/images/why1.png")} alt=""
            style={{height:60,marginBottom:15}}/>
            <div className="text-center" style={{
              font: '18px/30px Futura Hv BT',
            }}>
              Personalized recommendations
        </div>

            <div className="text-center " style={{
              font: '18px/30px Futura Lt BT',
              color: 'var(--mainGrey)'
            }}>
             Aren't you tired of one-for-all mass tourism guides. Tell us a bit about your interests and let our AI engine suggest the best places for YOU improving as you use the platform.
        </div>


          </Grid>
          <Grid item xs={6} md={4} style={{ marginBottom: '2%',textAlign:'center' }}>
          <img src={require("../../../../assets/images/why2.png")} alt=""
            style={{height:60,marginBottom:15}}/>
            <div className="text-center " style={{
              font: '18px/30px Futura Hv BT',
            }}>
              Group Discounts
        </div>
            <div className="text-center " style={{
              font: '18px/30px Futura Lt BT',
              color: 'var(--mainGrey)'
            }}>
              Ever heard of Closed User Group prices. These are prices we can only show you, let alone give you, if you are part of a group like the müvment. Discounts up to 77%.
        </div>

          </Grid>
          <Grid item xs={6} md={4} style={{ marginBottom: '2%',textAlign:'center' }}>
          <img src={require("../../../../assets/images/why3.png")} alt=""
            style={{height:60,marginBottom:15}}/>
            <div className="text-center " style={{
              font: '18px/30px Futura Hv BT',
            }}>
              All in One Place
        </div>

            <div className="text-center " style={{
              font: '18px/30px Futura Lt BT',
              color: 'var(--mainGrey)'
            }}>
              Stop spending days going from site to site to plan your trips. Find inspiration, plan, book, manage, save and share memories, all right here.
        </div>
          </Grid>


          <Grid item xs={6} md={4} style={{ marginBottom: '2%',textAlign:'center' }}>
          <img src={require("../../../../assets/images/why4.png")} alt=""
            style={{height:60,marginBottom:15}}/>
            <div className="text-center " style={{
              font: '18px/30px Futura Hv BT',
            }}>
              Find out what others did
        </div>

            <div className="text-center " style={{
              font: '18px/30px Futura Lt BT',
              color: 'var(--mainGrey)'
            }}>
              Check out influencers' or other like-minded müvers' trips and favorites, images and videos. You can save places and copy itineraries you like and customize them.
        </div>
          </Grid>

          <Grid item xs={6} md={4} style={{ marginBottom: '2%',textAlign:'center' }} >
          <img src={require("../../../../assets/images/why5.png")} alt=""
            style={{height:60,marginBottom:15}}/>
            <div className="text-center " style={{
              font: '18px/30px Futura Hv BT',
            }}>
              Save Your Trips
        </div>

            <div className="text-center " style={{
              font: '18px/30px Futura Lt BT',
              color: 'var(--mainGrey)'
            }}>
              Want to save or share a past trip? Don't remember your itinerary? Send us a link to an album of geolocated photos, we will recreate it and try to match nearby attractions.
        </div>
          </Grid>

          <Grid item xs={6} md={4} style={{ marginBottom: '2%' ,textAlign:'center'}}>
          <img src={require("../../../../assets/images/why6.png")} alt=""
            style={{height:60,marginBottom:15}}/>
            <div className="text-center " style={{
              font: '18px/30px Futura Hv BT',
            }}>
              Make great connections
        </div>

            <div className="text-center " style={{
              font: '18px/30px Futura Lt BT',
              color: 'var(--mainGrey)'
            }}>
              Our AI engine will match you with like-minded travelers and locals. You can connect with them, exchange recommendations, maybe meet them.
        </div>
          </Grid>
        </Grid>

      </div>


      <div className=" hidden_1366" style={{
        font: '16px/30px Futura Lt BT', width: '15vw', textAlign: 'left',
        margin: "auto 0 auto auto"
      }}>
        <div className="dicover-landscape"
          onClick={goesLandingPage}
          style={{
            padding: 15, marginTop: 5, cursor: "pointer", font: "16px/20px Futura Md BT", color: "var(--mainGreen)",
            border: '2px solid var(--mainGreen)',
          }}>
          Join the müvment <RightArrowIcon fill="var(--mainGreen)" style={{ marginLeft: "15px " }} />
        </div>
        {/* <div className="dicover-landscape"
          style={{
            font: "16px/20px Futura Md BT",
            marginTop: 10,
          }}>
          Travel influencers, <a style={{ color: "var(--mainGreen)" }} href="/inspire">
            click here
          </a>
        </div> */}
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  bodyClientWidth: state.Setting.htmlBody.bodyClientWidth,
  lang: state.Setting.lang,
  homepage: state.Home.homepage,
  is_mobile: state.Setting.is_mobile,

  homepageMuverStyle: state.Home.homepage_muver_style,
})

const mapDispatchToProps = {

}

const WhyJoin = connect(mapStateToProps, mapDispatchToProps)(WhyJoin_)
export default WhyJoin