import * as Actions from '../../../redux/actions'

import { Discover } from "./Discover.jsx"
import { FooterWhite } from "../Footer/FooterWhite.jsx"
import Header from '../Header/Header.jsx'
import { InfoTrip } from "./InfoTrip.jsx"
import { OtherTrips } from "./OtherTrips.jsx"
import { PhotoGallery } from "./PhotoGallery.jsx"
import PublicTripItineraryMap from "./PublicTripItineraryMap.jsx"
import PublicTripTimeline from "./PublicTripTimeline.jsx"
import React from 'react'
import { BookTrip, SaveTrip } from "./BookTrip.jsx"
import { TripImageBar } from "./TripImageBar.jsx"
import { VideoGallery } from "./VideoGallery.jsx"
import { connect } from 'react-redux'
import { HeaderMobile } from '../Header/HeaderMobile'
import { MuverTripMap } from './MuverTripMap'

export const MuverTripContainer_ = ({ getPublicTripShare, props, trip, is_mobile, lang, getPublicTrip,
    activities = trip?.activities,
    destinations = trip?.tripDestinations,
}) => {
    let padding_l = "8.5"
    let padding_r = "8.5"

    const destinationRef = React.useRef(null)
    const [mapDestinations, setMapDestinations] = React.useState(destinations || []);
    const [zoomDestination, setZoomDestination] = React.useState(14)
    const [mapCenterDestination, setMapCenterDestination] = React.useState({
        lat: 0,
        lng: 0
    })


    React.useEffect(() => {
        if (destinations && destinations?.length > 0) {
            setMapDestinations(destinations)
        }
    }, [destinations]);

    /* fetch trip */
    React.useEffect(() => {
        const shareID = props.match.params?.shareID;
        if (props.match.params.tripID !== undefined &&
            props.match.params.tripID !== null &&
            props.match.params.tripID !== "") {
            getPublicTrip(props.match.params.tripID, lang)
        }
        if (shareID) {
            getPublicTripShare(shareID)
        }
    }, [props.match.params.tripID, props.match.params?.shareID])

    if (!isNaN(is_mobile)) {
        padding_l = is_mobile ? "1" : "8.5"
        padding_r = is_mobile ? "1" : "8.5"
    }

    return (
        <div>
            <>

                {!is_mobile ? <Header bg_color={"#FFFFFF"} text_color={"var(--frameBlack)"} /> :
                    <HeaderMobile bg_color={"#FFFFFF"} text_color={"var(--frameBlack)"} />}
            </>
            <TripImageBar className="bg_white" from={"trip_public"} />
            <div style={{
                paddingLeft: padding_l + "%",
                paddingRight: padding_r + "%",
            }}>
                <BookTrip />
                {trip !== undefined
                    && trip.tripDescription === undefined
                    && trip.tripDescription === ""
                    && trip.tripCompanions !== undefined
                    && trip.tripCompanions.length > 0 &&
                    <React.Fragment>
                        <hr className="line_separator_w margin_top_50 margin_bottom_50" />
                        <InfoTrip />
                    </React.Fragment>}


                <div className="futura_hv_bt_30_32_black pl-2 margin_bottom_50">Trip Map</div>

                {window.google !== undefined && destinations &&
                    destinations?.length > 0 && <MuverTripMap
                        tripId={trip?.tripID}
                        destinations={mapDestinations}
                        zoom={zoomDestination}
                        setZoom={setZoomDestination}
                        mapCenter={mapCenterDestination}
                        setMapCenter={setMapCenterDestination}
                        refMap={destinationRef} />}

                <hr className="line_separator_w margin_top_50 margin_bottom_50" />

                <div className="futura_hv_bt_30_32_black pl-2 margin_bottom_50">Daily Itineraries</div>
                {window.google !== undefined && <PublicTripItineraryMap />}
                {trip.tripPhotoGallery !== undefined && trip.tripPhotoGallery.length > 0 &&
                    <React.Fragment>
                        <hr className="line_separator_w margin_top_50 margin_bottom_50" />
                        <div className="futura_hv_bt_30_32_black pl-2 margin_bottom_50">Photo Gallery</div>
                        <PhotoGallery />
                    </React.Fragment>}
                {trip.tripPhotoGallery !== undefined && trip.tripPhotoGallery.length > 0 &&
                    <React.Fragment>
                        <hr className="line_separator_w margin_top_50 margin_bottom_50" />
                        <div className="futura_hv_bt_30_32_black pl-2 margin_bottom_50">Videos</div>
                        <VideoGallery />
                    </React.Fragment>}
                {trip.tripDestinations !== undefined
                    && trip.tripDestinations.length > 0
                    && trip.tripDestinations[0] !== undefined
                    && trip.tripDestinations[0].destinationDays !== undefined
                    && trip.tripDestinations[0].destinationDays.length > 0
                    && trip.tripDestinations[0].destinationDays[0] !== undefined &&
                    <React.Fragment>
                        < hr className="line_separator_w margin_top_50 margin_bottom_50" />
                        <div className="futura_hv_bt_30_32_black pl-2 margin_bottom_50"
                            style={{
                                font: "18px/20px Futura Hv BT"
                            }}>Timeline</div>
                        <PublicTripTimeline />
                    </React.Fragment>}


                <div className="top_bar_container" onClick={() => null} style={{
                    cursor: "pointer", marginTop:30,
                    background: 'var(--mainGreen)', height: 60, borderRadius: 15,
                    width: "100%", textAlign: 'center'
                }}>
                    <div  style={{
                         font: "22px/50px Futura Md BT",
                         color: "var(--mainWhite)",
                    }} >
                        Book this trip
                    </div>
                </div>

                {/* <hr className="line_separator_w margin_top_50 margin_bottom_50" />
                                    <div className="futura_hv_bt_30_32_black pl-2 margin_bottom_50">Travel journal</div>
                                    <TravelJournal screenSize={this.state.screenSize} /> */}
                {trip.otherTrips !== undefined && trip.otherTrips.length > 0 &&
                    <React.Fragment>
                        <hr className="line_separator_w margin_top_50 margin_bottom_50" />
                        <div className="futura_hv_bt_30_32_black pl-2 margin_bottom_50">Other trips you might like</div>
                        <OtherTrips from={"trip_public"} textColor={"text_main_black"} />
                        <div className="margin_top_50 margin_bottom_50"></div>

                    </React.Fragment>}
            </div>
            <div style={{
                paddingLeft: padding_l + "%",
                paddingRight: padding_r + "%",
                background: "#efefef",
                marginTop: is_mobile ? "50px" : "150px",
            }}>
                <Discover />
            </div>

            <FooterWhite />
        </div>
    )
}

const mapStateToProps = (state) => ({
    trip: state.PublicTrip.trip,
    is_mobile: state.Setting.is_mobile,
    lang: state.Setting.lang,
    activeTrip: state.FormBank.TimelineWizard.activeTrip,

})

const mapDispatchToProps = {
    getPublicTrip: Actions.getPublicTrip,
    getPublicTripShare: Actions.getPublicTripShare
}

const MuverTripContainer = connect(mapStateToProps, mapDispatchToProps)(MuverTripContainer_)
export default MuverTripContainer
