import * as Actions from '../../../../redux/actions'
import React from 'react'
import { connect } from 'react-redux'
import { useHistory } from "react-router-dom"
import { member_area_router, URL_REPLACE } from '../RegExValidate'

const BucketListName_ = ({ bucketId, bucketName, setSwitchContainer, divClass = "", titleClass, titleStyle, lang = "" }) => {
    const history = useHistory()
    const handleClick = () => {
        setSwitchContainer(member_area_router.bucketList)
        const url = "/bucketlist/" + bucketName.replace(URL_REPLACE, "-") + "/" + bucketId;
        history.push(url.toLowerCase())
    }
    return (
        <div style={{
            display: "inline-block",
        }} >
            <div style={titleStyle} className="bucketListName" onClick={() => handleClick()}>
                {bucketName}
            </div>
        </div >
    )
}

const mapStateToProps = (state) => ({
    lang: state.Setting.lang,
})

const mapDispatchToProps = {
    setSwitchContainer: Actions.setSwitchContainer,
}

const BucketListName = connect(mapStateToProps, mapDispatchToProps)(BucketListName_)
export default BucketListName