import * as Actions from '../../../../redux/actions'

import { DirectionsRenderer, GoogleMap, withGoogleMap } from "react-google-maps"
import { compose, lifecycle, withProps } from "recompose"
import React from 'react'

import mapStyles from "../../TripPage/mapStyles"
import DestinationMarkers from '../destinationMarkers'
import Polyline from 'react-google-maps/lib/components/Polyline'
import { AirPlane } from '../SVGBank'
import PlacesMarkers from '../PlacesMarkers'
import { renderIconDestination } from './DestinationIconsMap'
import { useDispatch, useSelector } from 'react-redux'
import { getDestinationNearbyPlaces } from './ActiveTrip.slice'
import MarkerClusterer from 'react-google-maps/lib/components/addons/MarkerClusterer'


const contains = (arr, obj) => {
    let i = arr.length;
    while (i--) {
        if (JSON.stringify(arr[i]) === JSON.stringify(obj)) {
            return true;
        }
    }
    return false;
}

const isEqual = (obj1, obj2) => {
    let n = 0
    if (obj1.length !== obj2.length) {
        return false;
    }
    for (let i = 0; i < obj1.length; i++) {
        if (contains(obj2, obj1[i])) {
            n++
        }
    }
    return n === obj1.length
}


/* wrap map high order component */

export const DestinationMap = compose(
    withProps({
        // googleMapURL: "https://maps.googleapis.com/maps/api/js?libraries=geometry,drawing,places&key=" + GOOGLE_API_KEY,
        loadingElement: <div style={{ height: `100%` }} />,
        containerElement: <div style={{ height: `100%` }} />,
        mapElement: <div style={{ height: `100%` }} />
    }),
    withGoogleMap,
    lifecycle({
        componentDidUpdate(prevProps) {
            const { tripId, destinations, activeDay, destination, } = this.props


            if (prevProps.tripId !== tripId
                || prevProps.activeDay.tripDestinationId !== activeDay.tripDestinationId
                || prevProps.activeDay.dayInTrip !== activeDay.dayInTrip
                || prevProps.activeDay.dayInTripDestination !== activeDay.dayInTripDestination
                || !isEqual(prevProps.destinations, destinations)) {
                let waypts = []
                let first_lat = ""
                let first_lng = ""

                this.setState({
                    directions: {},
                    allDirections: [],
                    display: false
                })

            

                destinations && destinations.map((dest, index) => {

                    first_lat = dest.latitude;
                    first_lng = dest.longitude;
                    if (!dest.home) { ///index >1 is because we pickup origin
                        const isFlight = dest?.transportMode === "plane"
                        const isBus = dest?.transportMode === "public_transport"
                        const isTrain = dest?.transportMode === "train";

                        if (!isFlight) {
                            const geo = dest?.mapGeo;


                            const path1 = {
                                lat: parseFloat(geo?.origin?.latitude),
                                lng: parseFloat(geo?.origin?.longitude)
                            }
                            const path2 = {
                                lat: parseFloat(geo?.destination?.latitude),
                                lng: parseFloat(geo?.destination?.longitude)
                            };
                            const DirectionsService = new window.google.maps.DirectionsService()

                            DirectionsService.route({
                                origin: path1,
                                destination: path2,
                                // waypoints: way_points,
                                provideRouteAlternatives: true,
                                optimizeWaypoints: true,
                                travelMode: (isTrain) ? window.google.maps.TravelMode.TRANSIT : window.google.maps.TravelMode.DRIVING,
                                transitOptions: {
                                    modes: [window.google.maps.TransitMode.TRAIN]
                                }
                                ,
                            }, (result, status) => {
                                if (status === window.google.maps.DirectionsStatus.OK) {
                                    if (result && result.routes) {
                                        let directions = [...this.state.allDirections];
                                        directions.push({ directions: result, transportMode: dest?.transportMode })

                                        this.setState({
                                            allDirections: directions
                                        })

                                    }

                                }
                                else {
                                    const geo = dest?.mapGeo;

                                    const dest1 = `${geo?.origin?.name}, ${geo?.origin?.stateName}, ${geo?.origin?.countryName}`;
                                    const dest2 = `${geo?.destination?.name}, ${geo?.destination?.stateName}, ${geo?.destination?.countryName}`;


                                    if (dest1 && dest2) {
                                        DirectionsService.route({
                                            origin: dest1,
                                            destination: dest2,
                                            // waypoints: way_points,
                                            provideRouteAlternatives: true,
                                            optimizeWaypoints: true,
                                            travelMode: (isTrain) ? window.google.maps.TravelMode.TRANSIT : window.google.maps.TravelMode.DRIVING,
                                            transitOptions: {
                                                modes: isTrain ? [window.google.maps.TransitMode.TRAIN] : null
                                            }
                                        }, (result, status) => {


                                            if (status === window.google.maps.DirectionsStatus.OK) {
                                                let directions = [...this.state.allDirections];
                                                directions.push({ directions: result, transportMode: dest?.transportMode })

                                                this.setState({
                                                    allDirections: directions
                                                })
                                            } else {

                                            }
                                        })
                                    }
                                }
                            })

                        }



                    }
                    const destPath = {
                        lat: parseFloat(dest.latitude),
                        lng: parseFloat(dest.longitude)
                    }

                    waypts.push(destPath)

                    return ""
                })


                const points = [...waypts]


                let first = waypts.shift()
                let last = waypts.pop()
                let way_points = waypts.map(obj => {
                    let key_obj = {}
                    key_obj["location"] = obj
                    key_obj["stopover"] = true
                    return key_obj
                })



                if (points && points.length > 0) {
                    const bounds = new window.google.maps.LatLngBounds();
                    points.forEach((value, index) => {
                        bounds.extend(value);
                    })

                    if (points.length > 1) {
                        this.props.refMap.current.fitBounds(bounds);
                    }
                    this.props.setMapCenter(bounds.getCenter())
                    this.props.setZoom(10)
                }



                // if (points && points.length > 0) {
                //     const bounds = new window.google.maps.LatLngBounds();
                //     points.forEach((value, index) => {
                //         bounds.extend(value);
                //     })

                //     this.props.refMap.current.fitBounds(bounds);
                //     // this.props.setMapCenter(bounds.getCenter())
                //     // this.props.setZoom(8)
                // }

                // this.props.setMapCenter({
                //     lat: parseFloat(destination?.latitude),
                //     lng: parseFloat(destination?.longitude)
                // })
                // this.props.setZoom(10)


            }
        },
        componentDidMount() {
            const { destination, destinations, handleCenterChanged } = this.props
            handleCenterChanged()
            this.setState({
                directions: {},
                allDirections: [],
                display: false
            })
            let waypts = []
            let first_lat = ""
            let first_lng = ""



            destinations && destinations.map((dest, index) => {

                first_lat = dest.latitude;
                first_lng = dest.longitude;
                if (!dest.home) { ///index >1 is because we pickup origin
                    const isFlight = dest?.transportMode === "plane"
                    const isBus = dest?.transportMode === "public_transport"
                    const isTrain = dest?.transportMode === "train";

                    if (!isFlight) {
                        const geo = dest?.mapGeo;


                        const path1 = {
                            lat: parseFloat(geo?.origin?.latitude),
                            lng: parseFloat(geo?.origin?.longitude)
                        }
                        const path2 = {
                            lat: parseFloat(geo?.destination?.latitude),
                            lng: parseFloat(geo?.destination?.longitude)
                        };
                        const DirectionsService = new window.google.maps.DirectionsService()

                        const destinationName = "";
                        DirectionsService.route({
                            origin: path1,
                            destination: path2,
                            // waypoints: way_points,
                            provideRouteAlternatives: true,
                            optimizeWaypoints: true,
                            travelMode: (isTrain) ? window.google.maps.TravelMode.TRANSIT : window.google.maps.TravelMode.DRIVING,
                            transitOptions: {
                                modes: isTrain ? [window.google.maps.TransitMode.TRAIN] : null
                            }
                            ,
                        }, (result, status) => {

                            if (status === window.google.maps.DirectionsStatus.OK) {

                                if (result && result.routes) {
                                    let directions = [...this.state.allDirections];
                                    directions.push({ directions: result, transportMode: dest?.transportMode })

                                    this.setState({
                                        allDirections: directions
                                    })

                                }

                            }
                            else {
                                const geo = dest?.mapGeo;

                                const dest1 = `${geo?.origin?.name}, ${geo?.origin?.stateName}, ${geo?.origin?.countryName}`;
                                const dest2 = `${geo?.destination?.name}, ${geo?.destination?.stateName}, ${geo?.destination?.countryName}`;


                                if (dest1 && dest2) {
                                    DirectionsService.route({
                                        origin: dest1,
                                        destination: dest2,
                                        // waypoints: way_points,
                                        provideRouteAlternatives: true,
                                        optimizeWaypoints: true,
                                        travelMode: (isTrain) ? window.google.maps.TravelMode.TRANSIT : window.google.maps.TravelMode.DRIVING,
                                        transitOptions: {
                                            modes: isTrain ? [window.google.maps.TransitMode.TRAIN] : null
                                        }
                                    }, (result, status) => {


                                        if (status === window.google.maps.DirectionsStatus.OK) {
                                            let directions = [...this.state.allDirections];
                                            directions.push({ directions: result, transportMode: dest?.transportMode })

                                            this.setState({
                                                allDirections: directions
                                            })
                                        } else {

                                        }
                                    })
                                }

                            }

                        })

                    }



                }
                const destPath = {
                    lat: parseFloat(dest.latitude),
                    lng: parseFloat(dest.longitude)
                }

                waypts.push(destPath)


                return ""
            })
            const points = [...waypts]


            let first = waypts.shift()
            let last = waypts.pop()
            let way_points = waypts.map(obj => {

                let key_obj = {}
                key_obj["location"] = obj
                key_obj["stopover"] = true
                return key_obj
            })






            if (points && points.length > 0) {
                const bounds = new window.google.maps.LatLngBounds();
                points.forEach((value, index) => {
                    bounds.extend(value);
                })

                if (points.length > 1) {
                    this.props.refMap.current.fitBounds(bounds);
                }
                this.props.setMapCenter(bounds.getCenter())
                this.props.setZoom(10)
            }


        }
    })
)((props) => {

    const dispatch = useDispatch();
    const destinationNearByPlaces = useSelector(state => state.ActiveTrip.destinationNearByPlaces);
    const destinationNearByDestinations = useSelector(state => state.ActiveTrip.destinationNearByDestinations);

    React.useEffect(() => {
        if (props.overviewPath) {
            const data = {
                coords: props.overviewPath,
                selectedTypes: props.selectedTypes || []
            }
            dispatch(getDestinationNearbyPlaces(data))
        }
        return () => {
        };
    }, [props.overviewPath]);

    const [trackInfoArray, setTrackInfoArray] = React.useState([]);
    const [trackDestArray, setTrackDestArray] = React.useState([]);

    const pushPlacesWindow = (id) => {
        const tempArray = [];
        tempArray.push(id)
        setTrackInfoArray(tempArray);
    }

    const pushDestWindow = (id) => {
        const tempArray = [];
        tempArray.push(id)
        setTrackDestArray(tempArray);
    }
    const clearDestWindow = () => {
        setTrackDestArray([]);
    }
    const checkDestOpen = (id) => {
        const showWindow = trackDestArray.find(element => element === id);
        return showWindow ? true : false;
    }


    const clearPlacesWindow = () => {
        setTrackInfoArray([]);
    }

    const checkWindowOpen = (id) => {
        const showWindow = trackInfoArray.find(element => element === id);
        return showWindow ? true : false;
    }

    const mapClick = () => {
        clearPlacesWindow()
        clearDestWindow()
    }


 

    return (
        <GoogleMap
            defaultZoom={props.zoom}
            zoom={props.zoom}
            defaultCenter={props.mapCenter}
            center={props.mapCenter}
            defaultOptions={{
                controlSize: 20,
                mapTypeControl: false,
                fullscreenControl: false,
                maxZoom: 17,
                minZoom: 4,
                styles: mapStyles
            }}
            onCenterChanged={props.handleCenterChanged}
            onZoomChanged={props.handleZoomChanged}
            ref={props.refMap}
            onClick={mapClick}
        >
            {/* /// destination path for */}
            {props.destinations
                && props.destinations.length > 0 && props.destinations.map((destination, index_destination) => {

                    const isFlight = destination?.transportMode === "plane"
                    if (isFlight) {
                        const geo = destination?.mapGeo;


                        if (geo) {
                            const path1 = {
                                lat: parseFloat(geo?.origin?.latitude),
                                lng: parseFloat(geo?.origin?.longitude)
                            }
                            const path2 = {
                                lat: parseFloat(geo?.destination?.latitude),
                                lng: parseFloat(geo?.destination?.longitude)
                            };

                            const valuesArray = path2 ? [path1, path2] : [path1]
                            ///plotting values against eachother


                            return (
                                <Polyline
                                    key={index_destination}
                                    path={valuesArray}
                                    options={{
                                        suppressMarkers: true,
                                        strokeColor: "#19BC9B",
                                        strokeOpacity: 0.5,
                                        strokeWeight: 4,
                                        radius: 30000,
                                        geodesic: true,
                                        icons: [
                                            {
                                                icon: renderIconDestination("plane"),
                                                offset: "50%",
                                            },

                                        ]
                                    }}

                                />
                            )
                        }
                    }
                    return null;
                })}


            {props.destinations.length > 0 && props.destinations.map((destination, index_destination) => {
                const lengthDestinations = props.destinations.length;
                return (
                    <DestinationMarkers key={index_destination} lengthDestinations={lengthDestinations} destination={destination} color={{ fill: 'rgba(25, 188, 155, 1)', stroke: 'rgba(25, 188, 155, 0.4)' }}
                        index_destination={index_destination} mapProps={props} isRegularTrip={false}
                        pushDestWindow={pushDestWindow}
                        clearDestWindow={clearDestWindow}
                        checkDestOpen={checkDestOpen}
                        isItinerary={true}
                        dblClickDestination={props?.dblClickDestination} />
                )
            })}

            <MarkerClusterer
                averageCenter
                enableRetinaIcons
                gridSize={100}
            defaultMinimumClusterSize={2}
                calculator={(markers, numStyles) => {
                    if (markers.length >= 0 && markers.length < 10) return { text: markers.length, index: 1 };
                    return { text: markers.length, index: 0 };
                }
                }
                styles={[{
                    height: 36,
                    url: "https://storage.googleapis.com/muvimages/muv_assets/icons/Destination%20cluster2.png",
                    width: 35,
                    textColor: "white",
                }
                ]}
            >

                {destinationNearByDestinations && props.showNearBy && destinationNearByDestinations.length > 0 && destinationNearByDestinations.map((destination, index_destination) => {
                    const lengthDestinations = destinationNearByDestinations.length;
                    return (
                        <DestinationMarkers key={index_destination} lengthDestinations={lengthDestinations} destination={destination} color={{ fill: 'rgba(216, 82, 29, 1)', stroke: 'rgba(216, 82, 29, 0.4)' }}
                            index_destination={index_destination} mapProps={props} isRegularTrip={false} textColor={"rgba(25, 188, 155, 1)"}
                            pushDestWindow={pushDestWindow}
                            clearDestWindow={clearDestWindow}
                            checkDestOpen={checkDestOpen}
                            dblClickDestination={props?.dblClickDestination} />
                    )
                })}
            </MarkerClusterer>


            <MarkerClusterer
                averageCenter
                enableRetinaIcons
                gridSize={60}
                defaultMinimumClusterSize={5}
                // calculator={(markers,numStyles)=>{
                //     return {text: markers.length, index: 0};    }          
                // }
                styles={[{
                    height: 36,
                    url: "https://storage.googleapis.com/muvimages/muv_assets/icons/Activity%20cluster2.png",
                    width: 35,
                    textColor: "white",
                },]}
            >

                {/* show nearby places if its not a flight */}
                {destinationNearByPlaces && props.selectedTypes && props.selectedTypes.length > 0 &&
                    destinationNearByPlaces.length > 0 && destinationNearByPlaces.map((establishment, index_establishment) => {


                        const typeID = establishment?.types && establishment?.types[0]?.typeID;
                        const type = props.types ? props.types.find(type => type.typeID === typeID) : null;
                        const icon = type && type.icon ? type.icon : null;
                        const zoom = props && props.refMap ? props.refMap?.current?.getZoom() : 55;

                        return (
                            <React.Fragment key={index_establishment}>

                                <PlacesMarkers zoom={zoom} icon={icon} establishment={establishment} index_establishment={index_establishment} placesNearByCenter={destinationNearByPlaces}
                                    pushPlacesWindow={pushPlacesWindow}
                                    clearPlacesWindow={clearPlacesWindow}
                                    checkWindowOpen={checkWindowOpen}
                                />
                            </React.Fragment>
                        )
                    })}
            </MarkerClusterer>



            {/* plots a trip for each transit or car route */}
            {props?.allDirections && props?.destinations && props?.destinations.length > 0
            
            && props?.allDirections.length > 0 && props?.allDirections.map((destination, index_direction) => {

                return (
                    <DirectionsRenderer
                        key={index_direction + "direction"}
                        directions={destination.directions}
                        options={{
                            preserveViewport: true,
                            suppressMarkers: true,
                            polylineOptions: {
                                strokeColor: "#19BC9B",
                                strokeOpacity: 1,
                                strokeWeight: 4,
                                icons: [
                                    {
                                        icon: renderIconDestination(destination?.transportMode),
                                        offset: "50%",
                                        fixedRotation: true
                                    },

                                ]
                            },

                        }}
                    />
                )
            })}




        </GoogleMap>)

}
)
