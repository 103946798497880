import CircularProgress from '@material-ui/core/CircularProgress'
import React from 'react'
import { connect } from "react-redux"
import { makeStyles } from '@material-ui/core/styles'

export const Progress = connect((state) => ({
    progress: state.Destination.destination.lazyLoading.progress,
    switchFeatureContainer: state.FormBank.FeatureFormat,
}),
)(({
    progress,
    switchFeatureContainer,
}) => {

    const processStyles = makeStyles(theme => ({
        root: {
            '& .MuiCircularProgress-root': {
                color: switchFeatureContainer.color,
                marginTop: "15px",
                marginBottom: "15px",
                textAlign: "center",
            },
        },
    }))
    const classes = processStyles()


    return (
        <div className={classes.root} style={{ textAlign: "center" }}>
            <CircularProgress disableShrink />
        </div>
    )
})
