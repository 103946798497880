import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import "./styles.css";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'grid',
    gridTemplateColumns: '50% 50%',
    alignItems: 'center',
    marginTop: 140,
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      marginTop: 20
    },
    [theme.breakpoints.between('sm', 'md')]: {
      display: 'grid',
      gridTemplateColumns: '50% 50%',
      alignItems: 'center',
      marginTop: 80,
    },
  },
  stepBackgroundImage: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
    paddingRight: 40,
    [theme.breakpoints.down("sm")]: {
      paddingTop: 150,
      paddingRight: 0,
    },
  },
  chartBar: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  chartBarContent: {
    width: 70,
    [theme.breakpoints.down("sm")]: {
      width: 45,
    },
  },
  xAxisLabel: {
    font: "normal normal 500 18px/36px Futura Md BT",
    color: "#707070",
    [theme.breakpoints.down("sm")]: {
      font: "normal normal 500 10px/30px Futura Md BT",
    },
  },
  topRate: {
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    top: -128,
    width: 160,
    height: 120,
    background: "#ED6A5F 0% 0% no-repeat padding-box",
    opacity: 1,
    [theme.breakpoints.down("sm")]: {
      width: 115,
      height: 100,
    },
  },
  starGroup: {
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      top: 4,
    },
  },
  trophySection: {
    width: 60,
    textAlign: "center",
    borderBottom: "1px solid #ffffff",
    [theme.breakpoints.down("sm")]: {
      width: 40,
    },
  },
  trophyImage: {
    width: 30,
    [theme.breakpoints.down("sm")]: {
      width: 20,
    },
  },
}));

export default function InfluencerStepFour() {
  const classes = useStyles();
  const screenSize = document.body.clientWidth;
  let screen_at_500 = 500;

  return (
    <div className={classes.root} style={{paddingBottom: "8%"}}>
      {screenSize < screen_at_500 ? (
        <div className="landing-step-container" >
          <div className="step-description-section">
            <h1 className="step-number-influencers">4</h1>
            <div className="step-description">
              <h2 className="step-title-influencer">Own your referrals, forever</h2>
              <p className="futura_lt_bt_20_30"
              style={{color: '#B1B1B1'}}>
              You are a pioneer, and we know it. And to thank you for helping us build müvTravel into the next big thing in online travel, we will assign any new müver, who signs up and copies one of your itineraries or bucket list idea, to you, forever. So as we start sharing booking revenue with our ambassadors, you will have a head start and reap benefits fast.
              </p>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      <div className={classes.stepBackgroundImage}>
        <div className={classes.chartBar}>
          <div
            className={classes.chartBarContent}
            style={{
              height: "90px",
              background: "#ED6A5F 0% 0% no-repeat padding-box",
              opacity: 0.2,
            }}
          ></div>
          <span className={classes.xAxisLabel}>JAN</span>
        </div>
        <div className={classes.chartBar}>
          <div
            className={classes.chartBarContent}
            style={{
              height: "120px",
              background: "#ED6A5F 0% 0% no-repeat padding-box",
              opacity: 0.2,
            }}
          ></div>
          <span className={classes.xAxisLabel}>FEB</span>
        </div>
        <div className={classes.chartBar}>
          <div
            className={classes.chartBarContent}
            style={{
              height: "166px",
              background: "#ED6A5F 0% 0% no-repeat padding-box",
              opacity: 0.2,
            }}
          ></div>
          <span className={classes.xAxisLabel}>MAR</span>
        </div>
        <div className={classes.chartBar}>
          <div
            className={classes.chartBarContent}
            style={{
              height: "214px",
              background: "#ED6A5F 0% 0% no-repeat padding-box",
              opacity: 0.2,
            }}
          ></div>
          <span className={classes.xAxisLabel}>APR</span>
        </div>
        <div className={classes.chartBar}>
          
          <div
            className={classes.chartBarContent}
            style={{
              height: "300px",
              background: "#ED6A5F 0% 0% no-repeat padding-box",
              opacity: 0.2,
            }}
          ></div>
          <span className={classes.xAxisLabel}>MAY</span>
        </div>
        <div className={classes.chartBar}>
          <div
            className={classes.chartBarContent}
            style={{
              height: "370px",
              background: "#ED6A5F 0% 0% no-repeat padding-box",
              opacity: 0.2,
            }}
          ></div>
          <span className={classes.xAxisLabel}>JUNE</span>
        </div>
      </div>

      {screenSize > screen_at_500 ? (
        <div className="landing-step-container" >
          <div className="step-description-section">
            <h1 className="step-number-influencers">4</h1>
            <div className="step-description">
              <h2 className="step-title-influencer">Own your referrals, forever</h2>
              <p className="futura_lt_bt_20_30"
              style={{color: '#B1B1B1'}}>
              You are a pioneer, and we know it. And to thank you for helping us build müvTravel into the next big thing in online travel, we will assign any new müver, who signs up and copies one of your itineraries or bucket list idea, to you, forever. So as we start sharing booking revenue with our ambassadors, you will have a head start and reap benefits fast.
              </p>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
