import * as Actions from '../../../../redux/actions'

import {
    HotelDuotoneIcon,
    PlusSignIcon,
    TimesIcon,
} from "../SVGBank"
import TextField from '@material-ui/core/TextField';
import { HotelAddressTextField } from '../FormComponentsBank'
import React from 'react'
import { connect } from 'react-redux'
import throttle from 'lodash/throttle';

const Hotels_ = ({ setBookingFormStatus, setBookingFormActiveTab, openDefault, /*uuid_auth, activeTrip, lang, */ destination, switchFeatureContainer }) => {
    const [open, setOpen] = React.useState(openDefault)
    const [lookingRoom, setLookingRoom] = React.useState(false)
    const [hotelName, setHotelName] = React.useState("")
    const [hotelAddresses, setHotelAddresses] = React.useState([])
    const geocoder = new window.google.maps.Geocoder();
    const fetch = React.useMemo(
        () =>
            throttle((request, callback) => {
                geocoder.geocode(request, callback);
            }, 300),
        [],
    );
    React.useEffect(() => {
        const query = hotelName + "," + destination.name
        if (!query || query === '') {
            return undefined;
        }
        fetch({
            'address': query
        }, (results, status) => {
            if (status === window.google.maps.GeocoderStatus.OK) {
                //console.log (results)
                const addresses = results.map(result => result.formatted_address)
                //console.log(addresses)
                setHotelAddresses(addresses)
            }
        });
    }, [hotelName])
    //const classes = hotelTextStyles(); 
    const handleCheckIn = event => {
        let checkin = event.target.value
        setCheckinTime(checkin)
        //console.log(`checkin ${checkin}`)
    };

    const handleCheckOut = event => {
        let checkout = event.target.value
        setCheckoutTime(checkout)
        //console.log(`checkout ${checkout}`)
    };


    var dateObj = new Date();
    var month = dateObj.getUTCMonth() + 1; //months from 1-12
    var day = dateObj.getUTCDate();
    var year = dateObj.getUTCFullYear();
    let newdate = year + "-" + month + "-" + day + "," + "12:55 PM";
    //console.log(newdate)

    const [checkinTime, setCheckinTime] = React.useState(newdate)
    const [checkoutTime, setCheckoutTime] = React.useState(newdate)

    const handleSubmit = () => {
        const submitObj = {
            address: hotelName + "," + hotelAddresses[0],
            checkin: checkinTime,
            checkout: checkoutTime,
        }
        console.log(submitObj)
        setLookingRoom(false)
    };

    const searchHotel = () => {
        setBookingFormStatus(true)
        setBookingFormActiveTab('hotel')
    }

    return (
        <React.Fragment>
            {open ? <div style={{
                marginTop: "12px",
                marginLeft: "12px",
                marginRight: "0px",
                marginBottom: "12px",
                backgroundColor: "var(--mainWhite)",
                textAlign: "center",
                position: "relative",
                cursor: "pointer"   , 

            }}  >
                {<div>
                    <div style={{ position: "absolute", top: "5px", right: "10px", cursor: "pointer" }} onClick={() => { setOpen(false); setLookingRoom(true) }} >
                        <TimesIcon width={15} height={15} /></div>
                    <div onClick={searchHotel} >
                        <div style={{ paddingBottom: "15px", paddingTop: "30px", }}><HotelDuotoneIcon width={31} height={31} fill={switchFeatureContainer.color} /></div>
                        <div style={{ font: "18px/21px Futura Hv BT", color: "var(--frameBlack)", }}>Need a place to stay ?</div>
                        <div style={{ font: "16px/19px Futura Md BT", color: "#B1B1B1", paddingTop: "15px", paddingBottom: "30px", }}>See available rooms for your trip</div>
                        <hr style={{ border: "1px solid #E8E8E8", height: "0px", margin: "0px", }} />

                        <div  style={{ font: "14px/16px Futura Hv BT", cursor: "pointer" , color: switchFeatureContainer.color, paddingTop: "12px", paddingBottom: "12px", }}><PlusSignIcon width={9} height={10} style={{ marginRight: "5px" }} fill={switchFeatureContainer.color} />{"see rooms"}</div>
                    </div>
                    {/*<div style={{ font: "14px/16px Futura Hv BT", color: switchFeatureContainer.color, paddingTop: "12px", paddingBottom: "12px", }} onClick={()=>setLookingRoom(true)}><PlusSignIcon width={12} height={12} style={{ marginRight: "5px" }} fill={switchFeatureContainer.color}/>add custom or booked place to stay</div>*/}
                </div>}
                {/*lookingRoom && <div>*/}
                {false && <div>
                    <div style={{ paddingTop: "15px" }}>
                        <div style={{ font: "16px/16px Futura Md BT", color: "#000000", textAlign: "left", paddingLeft: "20px" }}> {"+ Add a place to stay in " + destination.name}
                        </div>
                        <div style={{ position: "absolute", top: "15px", right: "10px", cursor: "pointer" }} onClick={() => setLookingRoom(false)} >
                            <TimesIcon width={15} height={15} />
                        </div>
                    </div>
                    <div style={{ marginTop: "10px", marginBottom: "15px" }}>
                        <div style={{ height: "75px", border: "1px solid #E8E8E8", margin: "auto", marginTop: "10px", padding: "20px", textAlign: "left" }}>
                            <HotelAddressTextField setHotelName={setHotelName} />
                        </div>
                        <div style={{ height: "120px", border: "1px solid #E8E8E8", margin: "auto", marginTop: "20px", padding: "20px", textAlign: "left", overflow: "scroll" }}>
                            <p style={{ font: "14px/16px Futura Md BT", color: "#000000" }}>Address</p>
                            {false && hotelAddresses.length > 0 && hotelAddresses.map((hotel, index) => <p key={hotel = "_" + destination.name + "_" + index} style={{ font: "14px/16px Futura Md BT", color: "#B1B1B1" }}>{hotel}</p>)}
                            <p style={{ font: "14px/16px Futura Md BT", color: "#B1B1B1" }}>{hotelAddresses[0]}</p>
                        </div>
                        <div style={{ height: "82px", border: "1px solid #E8E8E8", margin: "auto", marginTop: "10px", padding: "20px", textAlign: "left" }}>

                            <TextField
                                id="datetime-local_checkout"
                                label="Check-in"
                                type="datetime-local"
                                InputProps={{
                                    disableUnderline: true
                                }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={handleCheckIn}
                                value={checkinTime}
                            />

                        </div>
                        <div style={{ height: "82px", border: "1px solid #E8E8E8", margin: "auto", marginTop: "10px", padding: "20px", textAlign: "left" }}>
                            <form noValidate>
                                <TextField
                                    id="datetime-local_checkout"
                                    label="Check-out"
                                    type="datetime-local"
                                    InputProps={{
                                        disableUnderline: true
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={handleCheckOut}
                                    value={checkoutTime}
                                />
                            </form>
                        </div>
                        <div style={{ font: "14px Futura Md BT", color: "var(--mainWhite)", textAlign: "center", paddingTop: "15px", height: "45px", border: "1px solid #E8E8E8", margin: "auto", marginTop: "10px", backgroundColor: "#8F8F8F" }}
                            onClick={handleSubmit}>
                            Submit
                                </div>

                    </div>

                </div>}
            </div>
                :
                <div style={{
                    marginTop: "12px",
                    marginLeft: "12px",
                    marginRight: "0px",
                    marginBottom: "12px",
                    backgroundColor: "var(--mainWhite)",
                    textAlign: "center",
                    position: "relative",
                    cursor: "pointer",
                }}
                    onClick={searchHotel}>
                    <div style={{ font: "14px/16px Futura Hv BT", color: switchFeatureContainer.color, paddingTop: "12px", paddingBottom: "12px", }}><PlusSignIcon width={9} height={10} style={{ marginRight: "5px" }} fill={switchFeatureContainer.color} />{"see rooms "} <HotelDuotoneIcon width={20} height={20} fill={switchFeatureContainer.color} /> {" in " + destination.name}</div>
                </div>


            }</React.Fragment>
    )
}

const mapStateToProps = (state) => ({
    switchFeatureContainer: state.FormBank.FeatureFormat,
    lang: state.Setting.lang,
    uuid_auth: state.Member.authModal.uuid,
    activeTrip: state.FormBank.TimelineWizard.activeTrip,

})

const mapDispatchToProps = {
    fetchTimelineTripHotels: Actions.fetchTimelineTripHotels,
    setBookingFormActiveTab: Actions.setBookingFormActiveTab,
    setBookingFormStatus: Actions.setBookingFormStatus,
}

const Hotels = connect(mapStateToProps, mapDispatchToProps)(Hotels_)
export default Hotels
