import * as Actions from '../../../../redux/actions'

import {
    LinkIcon,
} from "../SVGBank"
import React from 'react'
import { connect } from 'react-redux'
import { member_area_router } from "../../Common/RegExValidate"
import { useHistory } from "react-router-dom"

const SocialPageLink_ = ({ setSwitchContainer,  muverHandle, setAppMenuBookingButtonStatus }) => {
    const history = useHistory()
    const goesMemberAreaPage = () => {
        history.push("/@" + muverHandle)
        setSwitchContainer(member_area_router.social)
        setAppMenuBookingButtonStatus(true)
    }
    return (
        <div style={{ position: "absolute", top: "5px", right: "0px", cursor: "pointer" }} onClick={() => goesMemberAreaPage()}><LinkIcon /></div>
    )
}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = {
    setSwitchContainer: Actions.setSwitchContainer,
    setAppMenuBookingButtonStatus: Actions.setAppMenuBookingButtonStatus,

}

const SocialPageLink = connect(mapStateToProps, mapDispatchToProps)(SocialPageLink_)
export default SocialPageLink 
