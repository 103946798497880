import React, { Component } from 'react';

import { Image } from "react-bootstrap"

/* page not found */
class PageNotFound extends Component {
    render() {
        console.log('page not found')
        return (
            <div className="">
                <Image src="/img/page_not_found.png" />
            </div>
        )
    }
}

export default PageNotFound;
