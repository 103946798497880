import * as Actions from '../../../redux/actions'

import ElementTitle from "../Common/ElementTitle"
import HandleName from "../Common/Profile/HandleName"
import {
    Image,
} from 'react-bootstrap'
import React from 'react'
import { Star } from '../Common/MemberActions/Star'
import { URL_REPLACE } from "../Common/RegExValidate"
import { connect } from 'react-redux'
import { features_router } from "../Common/RegExValidate"
import { image_boxing } from "../Common/RegExValidate"
import { useHistory } from "react-router-dom"

/* other trip element */
const SubDestinationsElement_ = ({destination ,  textColor, screenSize}) => {
    const history = useHistory()
    
    const goesToDestinationPage = () => {
        const url ="/destination/" + destination?.partialUri + "/" + 1 + "/" + destination.destinationId;
        history.push(url.toLowerCase())
    }
    
    let dest_image_width = "212"
    let dest_image_height = "207"
    // if (!isNaN(screenSize)) {
    //     dest_image_width = screenSize < 1024 ? "100" : "212"
    //     dest_image_height = screenSize < 1024 ? "97" : "207"
    // }
    return (
        <div>
                <div className={"padding_left" + textColor} onClick={() => {goesToDestinationPage()}} style={{ cursor: "pointer" }}>
                    <div className="content_container m-2"  >
                        <Image className="min_width_100 img_fit_cover"
                            src={image_boxing(destination.destinationImage, dest_image_width, dest_image_height)}
                            alt="trip" />
                    </div>
                    <div className="pl-2 pr-2 ">
                        <div  style={{ cursor: "pointer", font: "17px/17px Futura Md BT",color:textColor }} className={"m-2 " + textColor}>{destination.name}</div>
                        {/* <div className="pl-2 pr-2 pt-2"><ElementTitle title={otherTrip.tripDescription} style={{
                            font: "14px/ 19px Futura Lt BT",
                            letterSpacing: "0",
                            color: "var(--mainGrey)",
                        }} /></div> */}
                    </div>
                </div>
        </div>
    )

}

const mapStateToProps = (state) => ({
    lang_public: state.Setting.lang,
    lang_private: state.Setting.lang,
    screenSize: state.Setting.htmlBody.bodyClientWidth,
    uuidPublic: state.Social.member_uuid,
    uuidPrivate: state.Member.authModal.uuid,
})

const mapDispatchToProps = {
    getPublicTrip: Actions.getPublicTrip,
    getSelectedTripInformation: Actions.getSelectedTripInformation,
    setTripToTimeline: Actions.setTripToTimeline,
}

const SubDestinationsElement = connect(mapStateToProps, mapDispatchToProps)(SubDestinationsElement_)
export default SubDestinationsElement
