import React, { Component } from 'react'

import { AirPlane } from "./SVGBank"
import { Image } from "react-bootstrap"
import { connect } from 'react-redux'
import moment from 'moment';

/* fight component */
class Fight_ extends Component {
    render() {
        const { flight, screenSize } = this.props
        let diff = ""
        if (flight !== undefined
            && flight.departure_date !== ""
            && moment(flight.departure_date).isValid()
            && flight.arrival_date !== ""
            && moment(flight.arrival_date).isValid()) {
            diff = moment(flight.arrival_date).diff(moment(flight.departure_date), 'days')
            diff = diff < 0 ? diff : (diff === 0 ? '' : '+' + diff)
        }
        return ( 
            <div className="flight_padding_top padding_bottom_20 d-inline-block hotel_margin_right" style={{width:  screenSize > 1024 ? "340px" : "170px"}}>
                {flight !== undefined ?
                    <div className="text_main_white">
                        <div className="futura_md_bt_14_20">{flight.arrival_date !== "" ? moment(flight.departure_date).format('ddd[.] D MMM[.]') : ''}</div>
                        <div className="row text-center m-0">
                            <div style={{ paddingTop: "7px" }}>
                                <div className="futura_md_bt_36_36 text-right">{flight.departure_iata}</div>
                                <div className="futura_lt_bt_24_24 text-center" style={{ paddingTop: "7px" }}>{flight.departure_time !== "" ? moment(flight.departure_time, "h:mma").format('h:mm a') : flight.departure_time}</div>
                                <div className="futura_lt_bt_12_20 text-center" style={{ paddingTop: "7px" }}>{flight.departure_city + " (" + flight.departure_iata + ")"}</div>
                            </div>
                            <div className="hr_line" />
                            <div>
                                <div className="text-center">{screenSize <= 1024 ? <AirPlane width={30} height={30} /> : <AirPlane />}</div>
                                <div className="futura_lt_bt_18_24 text-center" style={{ paddingTop: "7px" }}>{
                                    flight.overall_duration !== "" ?
                                        moment(flight.overall_duration, "DD:HH:mm").format('[+]D h[h]mm') === "Invalid date" ?
                                            moment(flight.overall_duration, "HH:mm:ss").format('m[h]ss')
                                            :
                                            moment(flight.overall_duration, "DD:HH:mm").format('D') * 24 +
                                            moment(flight.overall_duration, "DD:HH:mm").format('H') * 1 + "h" +
                                            moment(flight.overall_duration, "DD:HH:mm").format('mm')
                                        : ""}
                                </div>
                            </div>
                            <div className="hr_line" />
                            <div style={{ paddingTop: "7px" }}>
                                <div className="futura_md_bt_36_36 text-left">{flight.arrival_iata}</div>
                                <div className="futura_lt_bt_24_24 text-center row m-0" style={{ paddingTop: "7px" }}>
                                    <div className="row m-0">
                                        <div>
                                            {flight.arrival_time !== "" ? moment(flight.arrival_time, "h:mma").format('h:mm a') : flight.arrival_time}
                                        </div>
                                        <div className="futura_md_bt_14_20 text_red pl-2">
                                            {diff}
                                        </div>
                                    </div>
                                </div>
                                <div className="futura_lt_bt_12_20 text-center" style={{ paddingTop: "7px" }}>{flight.arrival_city + " (" + flight.arrival_iata + ")"}</div>
                            </div>
                        </div>
                        <div className="futura_lt_bt_12_20" style={{ paddingTop: "7px" }}>
                            <Image className="" src={"https://amhqajbken.cloudimg.io/v7/" + flight.airline_logo + "?func=crop&w=&h=15"} />
                            <span className="pl-1">{flight.airline_name}</span>
                            <span className="pl-1">{flight.flight_number}</span>
                        </div>
                        <div className="futura_lt_bt_12_20" style={{ paddingTop: "7px" }}>{"Operated by " + flight.airline_name}</div>
                        <div className="futura_lt_bt_18_32" style={{ paddingTop: "7px" }}>{"Booking #: "}<span className="futura_md_bt_24_32">{flight.booking_number.toUpperCase()}</span></div>

                    </div >
                    : ""}
            </div >
        )
    }
}

const mapStateToProps = state => {
    return {
        screenSize: state.Setting.htmlBody.bodyClientWidth,
    }
};

const mapDispatchToProps = {

}

const Flight = connect(mapStateToProps, mapDispatchToProps)(Fight_)
export default Flight
