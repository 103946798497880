import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { loadCSS } from 'fg-loadcss';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';

import './styles.css';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'grid',
    gridTemplateColumns: '50% 50%',
    alignItems: 'center',
    marginTop: 150,
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      marginTop: 20
    },
    [theme.breakpoints.between('sm', 'md')]: {
      display: 'grid',
      gridTemplateColumns: '50% 50%',
      alignItems: 'center',
    },
  },
  cardContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: 320,
    [theme.breakpoints.down('md')]: {
      width: 220,

      '& .MuiTypography-root': {
        font: '10px/15px Futura Lt BT'
      },
      '& .MuiCardContent-root': {
        paddingTop: 0
      },
      '& .MuiIcon-root': {
        fontSize: 12
      },
    },
    [theme.breakpoints.down('sm')]: {
      width: 150,

      '& .MuiTypography-root': {
        font: '7px/10px Futura Lt BT'
      },
      '& .MuiIcon-root': {
        fontSize: 8
      },
    },

    '& .MuiCardHeader-root': {
      display: 'block',
      textAlign: 'center',
      paddingLeft: 30,
      paddingRight: 30,
      [theme.breakpoints.down('md')]: {
        paddingBottom: 10,
        paddingLeft: 20,
        paddingRight: 20,
      },
      [theme.breakpoints.down('sm')]: {
        paddingBottom: 6,
        paddingTop: 6,
        paddingLeft: 10,
        paddingRight: 10,
      },
    },

    '& .MuiCardHeader-avatar': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginRight: 0
    },

    '& .MuiCardHeader-title': {
      font: 'normal normal 900 24px/28px Futura Hv BT',
      letterSpacing: '0px',
      [theme.breakpoints.down('md')]: {
        font: 'normal normal 900 14px/24px Futura Hv BT',
      },
      [theme.breakpoints.down('sm')]: {
        font: 'normal normal 900 12px/20px Futura Hv BT',
      },
    },

    '& .MuiCardHeader-subheader': {
      font: 'normal normal 600 16px/21px Futura Md BT',
      letterSpacing: '0px',
      color: '#19BC9B',
      [theme.breakpoints.down('md')]: {
        font: 'normal normal 900 12px/12px Futura Hv BT',
      },
      [theme.breakpoints.down('sm')]: {
        font: 'normal normal 900 10px/12px Futura Hv BT',
      },
    },
    '& .MuiCardContent-root': {
      textAlign: 'center',
      paddingLeft: 30,
      paddingRight: 30,
      [theme.breakpoints.down('md')]: {
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 15
      },
      [theme.breakpoints.down('sm')]: {
        paddingLeft: 10,
        paddingRight: 10,
        paddingBottom: 15
      },
    },
  },
  cardProfileAvatar: {
    width: theme.spacing(15),
    height: theme.spacing(15),
    [theme.breakpoints.down('md')]: {
      width: theme.spacing(8),
      height: theme.spacing(8),
    },
  },
  socialMediaIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 30,
    height: 30,
    margin: '0px 5px',
    borderRadius: '50%',
    background: '#19BC9B 0% 0% no-repeat padding-box',
    [theme.breakpoints.down('md')]: {
      width: 20,
      height: 20,
    },
    [theme.breakpoints.down('sm')]: {
      width: 16,
      height: 17,
      margin: '0px 1px',
    },
  },
  stepBackgroundImage: {
    position: 'relative',
    paddingLeft: 20,
    paddingRight: 20,
    [theme.breakpoints.down('sm')]: {
      top: 70,
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  noviceTitle: {
    font: 'normal normal 500 14px/16px Futura Md BT',
    color: '#B1B1B1',
    marginTop: 10,
    [theme.breakpoints.down('md')]: {
      font: 'normal normal 500 10px/16px Futura Md BT',
      marginTop: 8
    },
    [theme.breakpoints.down('sm')]: {
      font: 'normal normal 500 9px/12px Futura Md BT',
      marginTop: 4
    },
  },

  largeCirlceDash: {
    position: 'relative',
    left: 65,
    top: 70,
    width: 450,
    height: 450,
    border: '3px dashed #E8E8E8',
    borderRadius: '50%',
    [theme.breakpoints.down('md')]: {
      width: 230,
      height: 230,
      top: 45,
      left: 0
    },
  },

  mediumCirlceDash: {
    position: 'absolute',
    top: -30,
    right: 0,
    width: 400,
    height: 400,
    border: '3px dashed #E8E8E8',
    borderRadius: '50%',
    [theme.breakpoints.down('md')]: {
      width: 180,
      height: 180,
    },
  },

  largeProfileImageCircle: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 100,
    height: 100,
    border: '10px solid #b1ebe0',
    borderRadius: '50%',
    [theme.breakpoints.down('md')]: {
      width: 50,
      height: 50,
      border: '6px solid #b1ebe0'
    },
  },

  mediumProfileImageCircle: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 80,
    height: 80,
    border: '8px solid #b1ebe0',
    borderRadius: '50%',
    [theme.breakpoints.down('md')]: {
      width: 40,
      height: 40,
      border: '5px solid #b1ebe0'
    },
  },

  largeStarDot: {
    position: 'absolute',
    width: 15,
    height: 15,
    borderRadius: '50%',
    background: '#19BC9B 0% 0% no-repeat padding-box',
    [theme.breakpoints.down('md')]: {
      width: 10,
      height: 10,
    },
  },

  mediumStarDot: {
    position: 'absolute',
    width: 10,
    height: 10,
    borderRadius: '50%',
    background: '#19BC9B 0% 0% no-repeat padding-box',
    [theme.breakpoints.down('md')]: {
      width: 7,
      height: 7,
    },
  },

  smallStarDot: {
    position: 'absolute',
    width: 5,
    height: 5,
    borderRadius: '50%',
    background: '#19BC9B 0% 0% no-repeat padding-box'
  },
  profileOneImageSection: {
    position: 'absolute',
    bottom: -10,
    right: 26,
    [theme.breakpoints.down('md')]: {
      right: 24,
    },
  },
  profileTwoImageSection: {
    position: 'absolute',
    top: -130,
    left: 10,
    [theme.breakpoints.down('sm')]: {
      top: -67,
      left: 25,
    },
  },
  profileThreeImageSection: {
    position: 'absolute',
    top: 30,
    right: 0,
    [theme.breakpoints.down('sm')]: {
      top: 0
    },
  },
  profileFourImageSection: {
    position: 'absolute',
    top: 187,
    left: -45,
    [theme.breakpoints.down('sm')]: {
      top: 174,
      left: 17,
    },
  },
  profileFiveImageSection: {
    position: 'absolute',
    bottom: -20,
    left: 85,
    [theme.breakpoints.down('sm')]: {
      left: 122,
    },
  }

}));

export default function LandingStepOne() {
  const classes = useStyles();

  React.useEffect(() => {
    const node = loadCSS(
      'https://use.fontawesome.com/releases/v5.12.0/css/all.css',
      document.querySelector('#font-awesome-css'),
    );

    return () => {
      node.parentNode.removeChild(node);
    };
  }, []);

  return (
    <div className={classes.root}>
      <div className="landing-step-container">
        <div className="step-description-section">
            <h1 className="step-number">1</h1>
            <div className="step-description">
                <h2 className="step-title">Create your profile</h2>
                <p className="futura_lt_bt_20_30">
                Tell us a bit about yourself, your travel style and your interests. It will allow us to match you with activities and trips you will like and with like-minded travellers and locals you can learn from. The more we know about you, the better the recommendations. And don't worry, your private information will always remain private.
                </p>
            </div>
        </div>
      </div>
      
      <div className={classes.stepBackgroundImage}>
        <div style={{position: 'relative'}}>
          <div className={classes.largeCirlceDash}>
            <div className={classes.profileTwoImageSection}>
              <div className={classes.mediumProfileImageCircle}>
                <img style={{width: '100%'}} src={require('../../../assets/images/profile2.png')}/>
                <div className={classes.largeStarDot} style={{top: -50, right: 0}}></div>
                <div className={classes.largeStarDot} style={{bottom: -50, right: 0}}></div>
                <div className={classes.mediumStarDot} style={{left: -40}}></div>
                <div className={classes.smallStarDot} style={{bottom: -40}}></div>
                <div className={classes.smallStarDot} style={{top: 0, left: -30}}></div>
              </div>
            </div>

            <div className={classes.profileFourImageSection}>
              <div className={classes.largeProfileImageCircle}>
                <img style={{width: '100%'}} src={require('../../../assets/images/profile4.png')}/>
                <div className={classes.largeStarDot} style={{top: -23, left: -24}}></div>
                <div className={classes.largeStarDot} style={{bottom: -30, left: -15}}></div>
                <div className={classes.mediumStarDot} style={{right: -45}}></div>
                <div className={classes.smallStarDot} style={{bottom: 0, right: -15}}></div>
                <div className={classes.smallStarDot} style={{top: -18, left: -40}}></div>
              </div>
            </div>

            <div className={classes.profileFiveImageSection}>
              <div className={classes.mediumProfileImageCircle}>
                <img style={{width: '100%'}} src={require('../../../assets/images/profile5.png')}/>
              </div>
            </div>
            
          </div>
          <div className={classes.mediumCirlceDash}>
            <div className={classes.profileThreeImageSection}>
              <div className={classes.largeProfileImageCircle}>
                <img style={{width: '100%'}} src={require('../../../assets/images/profile3.png')}/>
                <div className={classes.largeStarDot} style={{top: -60, right: 0}}></div>
                <div className={classes.largeStarDot} style={{bottom: -60, right: 0}}></div>
                <div className={classes.mediumStarDot} style={{left: -40}}></div>
                <div className={classes.smallStarDot} style={{bottom: -40}}></div>
                <div className={classes.smallStarDot} style={{top: 0, left: -30}}></div>
              </div>
            </div>

            <div className={classes.profileOneImageSection}>
              <div className={classes.largeProfileImageCircle}>
                <img style={{width: '100%'}} src={require('../../../assets/images/profile1.png')}/>
                <div className={classes.largeStarDot} style={{top: -23, left: -24}}></div>
                <div className={classes.largeStarDot} style={{bottom: -30, left: -15}}></div>
                <div className={classes.mediumStarDot} style={{right: -28}}></div>
                <div className={classes.smallStarDot} style={{bottom: 0, right: -15}}></div>
                <div className={classes.smallStarDot} style={{top: -18, left: -40}}></div>
              </div>
            </div>
          </div>
        </div>
        <Card className={classes.cardContainer}>
          <CardHeader
            avatar={
              <Avatar aria-label="avatar" className={classes.cardProfileAvatar} src={require('../../../assets/images/avatar.png')}>
              </Avatar>
            }
            title="John Doe"
            subheader="@johndoe"
          />
          <CardContent>
            <Typography variant="body2" color="textSecondary" component="p">
              «Traveling allows you to become so many different versions of yourself, leaving you speechless, then turns you into a storyteller»
            </Typography>

            <div className="social-media-icons">
              <div className={classes.socialMediaIcon}>
                <Icon className="fab fa-facebook-f" />
              </div>
              <div className={classes.socialMediaIcon}>
                <Icon className="fab fa-twitter" />
              </div>
              <div className={classes.socialMediaIcon}>
                <Icon className="fab fa-instagram" style={{ color: '#ffffff' }}/>
              </div>
              <div className={classes.socialMediaIcon}>
                <Icon className="fab fa-pinterest-p" />
              </div>
              <div className={classes.socialMediaIcon}>
                <Icon className="fas fa-bell" style={{ color: '#ffffff' }}/>
              </div>
            </div>

            <div className="comments-section">
              <div className="followers">
                <img className="comment-icons" src={require('../../../assets/images/add-friend.svg')}/>
                <div className="comment-number">679K</div>
                <div className="comment-title">Followers</div>
              </div>
              <div className="trips">
                <img className="comment-icons" src={require('../../../assets/images/airplane-shape.svg')}/>
                <div className="comment-number">24</div>
                <div className="comment-title">Trips</div>
              </div>
              <div className="novice">
                <img className="comment-icons" src={require('../../../assets/images/trophy.svg')}/>
                <div className={classes.noviceTitle}>MUV</div>
                <div className="comment-title">Novice</div>
              </div>
              
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  );
}

