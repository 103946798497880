import React from 'react'
import { connect } from 'react-redux'
import "./ReviewsUser.css"
import ReviewsUserRating from "./ReviewsUserRating"
import ReviewsUserPost from "./ReviewsUserPost"
import ReviewsRatingComponent from '../Common/ReviewsRating/ReviewsUserRating';


const ReviewsUser_ = ({ lang, establishment, uuid,switchFeatureContainer }) => {
    let {generalInfo} = establishment
    let {reviews} = establishment

    return (
        <div className="reviewsUser">
            <div className="rating">  
                <ReviewsRatingComponent reviews= {reviews} color={'#19BC9B'}/>    
            </div>
            <div className="posting">
                 <ReviewsUserPost generalInfo ={generalInfo} uuid={uuid} lang ={lang}/>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
        lang: state.Setting.lang,
        establishment: state.Destination.establishment,
        uuid: state.Member.authModal.uuid,
        switchFeatureContainer: state.FormBank.FeatureFormat,
})

const mapDispatchToProps = {

}

const ReviewsUser = connect(mapStateToProps, mapDispatchToProps)(ReviewsUser_)
export default ReviewsUser
