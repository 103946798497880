import {
    Image,
} from 'react-bootstrap'
import Rating from '@material-ui/lab/Rating'
import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import ReviewsDescription from '../Common/Establishment/ReviewsDescription';
import { Grid } from '@material-ui/core'



const ReviewsList_ = ({ reviews,open }) => {
    const userReviews = reviews? reviews.reviews: null;
    return (
        <div>
            { userReviews !== undefined && userReviews !== null && userReviews.length > 0 && userReviews.map((review, review_index) => {
                const date_created = review.created_at !== undefined ? moment(review.created_at, 'YYYY-MM-DD').format("MMM Do[,] YYYY") : ""
                const rating = review.rating !== undefined ? review.rating : ""
                const text = review.comment !== undefined ? review.comment : ""
                const author_name = review.member !== undefined ? review.member.muverFirstName : ""
                const profile_photo_url = review.member.muverAvatar !== undefined ? review.member.muverAvatar.fullUrl : ""

                
                return (
                    <React.Fragment key={review_index}>
                        {(open || review_index < 3) && <React.Fragment>
                            {profile_photo_url === "" ?
                                <div style={{  marginTop: "30px" }}>
                                     <Grid container spacing={3}>
                                   
                                   <Grid item xs={4}>
                        
                                        <div>
                                            <span style={{ font: "18px/24px Futura Hv BT", color: "#707070" }}>{author_name}</span> {" - "} <span style={{ font: "14px/24px Futura Lt BT", color: "#707070", whiteSpace: "nowrap", }}>{date_created}</span></div>
                                        <Rating name="reviews_rating" className="buckelistRating" style={{
                                            color: "var(--mainOrange)", opacity: "1",
                                        }} precision={0.5} max={5} value={parseFloat(rating)} disabled={true} />
                                    </Grid>

                                    <Grid item xs={8}>
                                    <div style={{flexGrow:"1"}}>
                                    {text !== "" && <ReviewsDescription description={text} boxDescription={"box_3_wrap"} fontDescription={"16px/22px Futura Md BT"} colorDescription={"#707070"} height={"90px"} width={"100%"} />}
                                    </div>
                                    </Grid>
                                    </Grid>
                                </div>
                                :
                                <div style={{  marginTop: "30px" }}>
                                    <Grid container spacing={3}>
                                   
                                    <Grid item xs={4}>
                                    <div style={{width: '70px',float: 'left'}}>
                                    <Image className="" style={{  borderRadius: '50%'}}
                                        src={ profile_photo_url}
                                        alt="google profile photo" width={"50px"} height={"50px"} />
                                    </div>
                                    <div style={{ marginLeft: "10px" }}>
                                        <div>
                                            <span style={{ font: "18px/24px Futura Hv BT", color: "#707070" }}>{author_name}</span> {" - "} <span style={{ font: "14px/24px Futura Lt BT", color: "#707070", whiteSpace: "nowrap", }}>{date_created}</span></div>
                                        <Rating name="reviews_rating"className="buckelistRating"   style={{
                                            color: "var(--mainOrange)", opacity: "1",
                                        }} precision={0.5} max={5} value={parseFloat(rating)} disabled={true} />
                                    </div>
                                    </Grid>

                                    <Grid item xs={8}>
                                    <div style= {{}}>
                                    {text !== "" && <ReviewsDescription description={text} boxDescription={"box_3_wrap"} fontDescription={"16px/22px Futura Md BT"} colorDescription={'#707070'} height={"90px"} width={"100%"} />}
                                    </div>
                                    </Grid>
                                    </Grid>

                                </div>}
               
                               
                        </React.Fragment>}
                    </React.Fragment>
                )
            })}
        </div >
    )
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = {

}

const ReviewsList = connect(mapStateToProps, mapDispatchToProps)(ReviewsList_)
export default ReviewsList 
