import React from 'react'
import { RightArrowIcon, } from '../../Common/SVGBank'
import TrendingCard from "./TrendingCard"
import { URL_REPLACE } from "../../Common/RegExValidate"
import { connect } from 'react-redux'
import { useHistory } from "react-router-dom"
import { TrackEvent } from '../../../../utility/utility'

const Trending_ = ({ homepage, is_mobile }) => {
    const history = useHistory()
    const goesFeatureTripPage = (description, feature_id) => {
        
        TrackEvent('HOME',"Click trending trips",feature_id)
        const url = "/feature/" + description.replace(URL_REPLACE, "-") + "/" + feature_id;
        history.push(url.toLowerCase())
    }

    const display_index_trending = is_mobile ? 5 : 8
    return (
        <div>
            {is_mobile && <div className="col-md-3">
                <div className="card">
                    <div className="col-md-12 card-body text-left" style={{ height: "110px", }}>
                        <h5 className="card-title-1" style={{ font: "16px/20px Futura Hv BT", marginBottom: "5px",color: "#202020" }}>A world of inspiring journeys</h5>
                        <p className="card-text" style={{ font: "10px/12px Futura Lt BT", color: "#202020", marginBottom: "5px", marginRight: "30%" }}>Discover the itineraries of influencers and like-minded travellers</p>
                    </div>
                </div>
            </div>}
            <div className="row m-0">
                {homepage.trendingTrips && homepage.trendingTrips.length > 0 && homepage.trendingTrips.map((item, i) => {
                    return (
                        i === 3 ?
                            !is_mobile && <div className="col-lg-3 col-md-4" key={item.tripID} style={{ }}>
                                <div className="card">
                                    <div className="col-md-12 card-body text-left">
                                        <h6 className="card-subtitle mb-2 text-muted" style={{ font: "16px/28px Futura Md BT" }}>Trending</h6>
                                        <h5 className="card-title-1" style={{ font: "2.7vw/48px Futura Hv BT", color: "#202020" }}>A world of inspiring journeys</h5>
                                        <p className="card-text" style={{ font: "16px/22px Futura Lt BT", color: "#202020" }}>Discover the itineraries of influencers and like-minded travellers</p>
                                        <div className="btn btn-primary ExploreMore" onClick={() => goesFeatureTripPage("trips", "3")} style={{ font: "16px/28px Futura Md BT" }}>Explore more <RightArrowIcon /></div>
                                    </div>
                                </div>
                            </div>
                            : ((i < display_index_trending) ?
                                <div className="col-6 col-lg-3 col-md-4" style={{ padding: is_mobile ? "5px" : "" }} key={item.tripID}>
                                    <TrendingCard trendingItem={item}/>
                                </div>
                                : "")
                    )
                }
                )}
            </div>
            {is_mobile && <div className={"col-12 text-right"}><div className="btn btn-primary ExploreMore" style={{ font: "10px/10px Futura Md BT", height: "40px" }}>Explore more <RightArrowIcon width={"13"} height={"13"} /></div></div>}
        </div>
    )
}

const mapStateToProps = (state) => ({
    bodyClientWidth: state.Setting.htmlBody.bodyClientWidth,
    lang: state.Setting.lang,
    homepage: state.Home.homepage,
    is_mobile: state.Setting.is_mobile,
})

const mapDispatchToProps = {

}

const Trending = connect(mapStateToProps, mapDispatchToProps)(Trending_)
export default Trending
