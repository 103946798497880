import React, { Component } from "react";
import { useHistory } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import { TrackEvent } from "../../../utility/utility";
import "./SuggestedDestinationsPage.css";

export const SuggestedMapCategories_ = ({ fetchData,is_mobile }) => {
  const history = useHistory();
  const suggestedCategoriesMap = useSelector(
    (state) => state.ActiveTrip.suggestedCategoriesMap
  );


  const [selectedCategory, setSelected] = React.useState([])
  const allSelected = selectedCategory?.length === 0;

///clear selection on search
  React.useEffect(() => {
    // setSelected([])
    return () => {
    };
}, [suggestedCategoriesMap]);


  const linkPage = (destinationId, city, state, country, uri) => {
    // TrackEvent('HOME',"Click suggested destination",destinationId)
    const url = "/destination/" + uri + "/" + 1 + "/" + destinationId;
    history.push(url.toLowerCase());
  };

  const handleSelect = (id) => {
    let tmp = [...selectedCategory];
    if (tmp.includes(id)) {
      tmp = tmp.filter(el => el !== id)
      setSelected(tmp)
    } else {
      tmp.push(id);
      setSelected(tmp)
    }

    const payload = tmp.map((data)=>{
      return {categoryId: data}
    })
    
    if(payload?.length >0){
      fetchData(payload)
    }
    else{
      selectAll()
    }
  }

  const isSelected = (id) => {
    return selectedCategory?.includes(id)
  }

  const selectAll = () => {
    fetchData(null)
    setSelected([])
  }



  return (
    <div className="">
      {suggestedCategoriesMap &&
        suggestedCategoriesMap?.length > 0 && <div
          style={{
            position: "absolute",
            top: is_mobile ? "15%" : "10%",
            right: "16%",
            zIndex: "9",
            margin: "0",
            left: is_mobile ? "10%" : "16%",
          }}
        >
          <div
          onClick={()=> selectAll()}
            style={{
              backgroundColor: allSelected ? "var(--mainOrange)" : "var(--mainWhite)",
              color: allSelected ? "var(--mainWhite)" : "var(--mainOrange)",
              border: "1px solid var(--mainOrange)",
              height: "30px",
              width: "auto",
              minWidth: 50,
              display: "inline-flex",
              borderRadius: "15px",
              flexWrap: "wrap",
              cursor: "pointer",
              verticalAlign: "middle",
              padding: "5px 15px",
              marginRight: "6px",
              marginBottom: 10,
              textAlign: "center",
              font: "16px/18px Futura Lt BT",
            }}
          >
            {" "}
          All
        </div>

          {
            suggestedCategoriesMap.map((category, key) => {
              return (
                <div
                  key={key + "suggestedCategory"}
                  onClick={() => handleSelect(category?.categoryId)}
                  style={{
                    backgroundColor: isSelected(category?.categoryId) ? "var(--mainOrange)" : "var(--mainWhite)",
                    color: isSelected(category?.categoryId) ? "var(--mainWhite)" : "var(--mainOrange)",
                    border: "1px solid var(--mainOrange)",
                    height: "30px",
                    width: "auto",
                    minWidth: 50,
                    display: "inline-flex",
                    borderRadius: "15px",
                    flexWrap: "wrap",
                    cursor: "pointer",
                    verticalAlign: "middle",
                    padding: "5px 15px",
                    marginRight: "6px",
                    textAlign: "center",
                    marginBottom: 10,
                    font: "16px/18px Futura Lt BT",
                  }}
                >
                  {" "}
                  {category?.categoryName}
                </div>
              );
            })}
        </div>}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    screenSize: state.Setting.htmlBody.bodyClientWidth,
    lang: state.Setting.lang,
    homepage: state.Home.homepage,
    is_mobile: state.Setting.is_mobile,
  };
};

const SuggestedMapCategories = connect(mapStateToProps)(
  SuggestedMapCategories_
);
export default SuggestedMapCategories;
