import * as Actions from '../../../redux/actions'

import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Divider from '@material-ui/core/Divider';
import { DownArrowIcon } from '../Common/SVGBank';
import Grow from '@material-ui/core/Grow';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import React from 'react'
import { connect } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({

    root: {
        background: "#FFFFFF 0% 0% no-repeat padding-box",
        boxShadow: "0px 3px 6px #00000029",
        border: "1px solid #B1B1B1",
        borderRadius: "4px",
        position: 'relative',
        marginLeft: 0,
        marginTop: "15px",
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(1),
            width: 'auto',
        },
        height: '40px',
        width: "100%",
        font: "16px/16px Futura Md BT",
        color: "var(--mainGrey)",
        paddingLeft: "10px",
        paddingRight: "10px",

        '& .MuiListItem-button:hover': {
            textDecoration: "none",
            backgroundColor: "rgba(25, 188, 155, 0.2)",
        },
    },

    optionRoot: {
        color: 'var(--bgBlack)',
        font: "16px/16px Futura Md BT",
    },

}));

/* menu list compo */
const SortingBar_ = ({ setSortingOptionsFeature, sortingOptions, selectedSortingOption }) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);

    const handleToggle = () => {
        setOpen(prevOpen => !prevOpen);
    };

    const handleListSelected = (selectedOption) => {
        let selected = []
        selected.push(selectedOption)
        setSortingOptionsFeature(selected)
        setOpen(false);
    };

    const handleClose = event => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };
    return (
        <div className={classes.root}>
            <div>
                <div
                    ref={anchorRef}
                    aria-controls={open ? 'menu-list-sort-trips' : undefined}
                    aria-haspopup="true"
                    onClick={handleToggle}
                    className="rankingSelect"
                    style={{
                        paddingTop: "10px",
                        display: "flex",
                        width: "9em",
                    }}
                >

                    <div style={{
                        flexWrap: "nowrap",
                        flexGrow: "1",
                        font: "16px/16px Futura Md BT",
                        marginRight: "8px",
                        color: selectedSortingOption !== undefined
                            && selectedSortingOption !== null &&  selectedSortingOption.length > 0
                            && selectedSortingOption[0].title !== undefined ? "var(--bgBlack)" : "var(--mainGrey)"
                    }}>
                        {selectedSortingOption !== undefined
                            && selectedSortingOption !== null &&  selectedSortingOption.length > 0
                            && selectedSortingOption[0].title !== undefined
                            ? selectedSortingOption[0].title :
                            "Sort by"}
                    </div>
                    <div>
                        <DownArrowIcon style={{ marginRight: "8px" }} fill={selectedSortingOption !== undefined
                            && selectedSortingOption !== null
                            && selectedSortingOption.title !== undefined ? "var(--bgBlack)" : "var(--mainGrey)"} />
                    </div>
                </div>

                <ClickAwayListener onClickAway={handleClose}>
                    <Popper open={open} anchorEl={anchorRef.current} role={undefined} className={classes.zIndex}

                        placement={"bottom-start"}
                        modifiers={{
                            flip: {
                                enabled: false,
                            },
                        }} transition disablePortal>
                        <Grow
                            in={open}
                            style={{ transformOrigin: '0 0 0' }}
                            {...(open ? { timeout: 500 } : {})}
                        >
                            <Paper>
                                <MenuList autoFocusItem={open} id="menu-list-sort-trips">
                                    {sortingOptions !== undefined && sortingOptions.length > 0 && sortingOptions.map((option, option_index) => {
                                        return (
                                            <div key={option_index} >
                                                {option_index !== 0 && <Divider light />}
                                                <MenuItem className={classes.optionRoot} onClick={() => handleListSelected(option)}>{option.title}</MenuItem>
                                            </div>
                                        )
                                    })}
                                </MenuList>
                            </Paper>
                        </Grow>
                    </Popper>
                </ClickAwayListener>
            </div>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        selectedSortingOption: state.Feature.feature.loading.selectedSortingOption,
        sortingOptions: state.Feature.feature.sortingOptions,
    }
}
const mapDispatchToProps = {
    setSortingOptionsFeature: Actions.setSortingOptionsFeature,
}
export const SortingBar = connect(mapStateToProps, mapDispatchToProps)(SortingBar_);
