import './Photos.css';

import {
    Col,
    Image,
    Row
} from 'react-bootstrap'

import { PropTypes } from 'prop-types'
import React from 'react'
import { SRLWrapper } from "simple-react-lightbox";
import SimpleReactLightbox from "simple-react-lightbox" // Import Simple React Lightbox
import { connect, useDispatch } from "react-redux"
import { deleteSocialPhotos, } from './social.slice';
import Slider from 'react-slick';
import { ArrowLeftIcon, ArrowRightIcon, DeleteIcon, TimesIcon } from '../Common/SVGBank';

const SliderArrowRight = ({ className, to, onClick }) => (
    <button type="button" onClick={onClick} className={`button button--text button--icon ${className}`} aria-label={to}>
        <ArrowRightIcon className="icon" icon={to} style={{
            height: "60px",
            width: "60px",
            transform: 'translateY(-66px)'
        }} />
    </button>
)


const SliderArrowLeft = ({ className, to, onClick }) => (
    <button type="button" onClick={onClick} className={`button button--text button--icon ${className}`} aria-label={to}>
        <ArrowLeftIcon className="icon" icon={to} style={{
            height: "60px",
            width: "60px",
            transform: 'translateY(-66px)'
        }} />
    </button>
)


/* photo component */
const Photos_ = ({photos,uuid,member_uuid}) => {

    const dispatch = useDispatch();
    const [selected, setSelected] = React.useState(null);

 
    React.useEffect(() => {
        if(photos){
            setSelected(photos[0])
        }
      }, [photos]) 

   
    const options = {
        overlayColor: "rgb(25, 25, 25)",
        captionColor: "rgba(25, 188, 155, 1)",
        captionFontFamily: "Futura Md BT",
        captionFontSize: "22px",
        captionFontWeight: "300",
        captionFontStyle: "capitalize",
        buttonsBackgroundColor: "#191919",
        buttonsIconColor: "rgba(25, 188, 155, 1)",
        autoplaySpeed: 1500,
        transitionSpeed: 900,

    };

    
    let settings = {
        dots: false,
        infinite: false,
        arrows: photos && photos.length > 5 ? true : false,
        speed: 500,
        lazyLoad: false,
        autoplay: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        pauseOnHover: true,
        centerMode: false,
        centerPadding: "70px",
        prevArrow: <SliderArrowLeft to="prev" />,
        nextArrow: <SliderArrowRight to="next" />,
        responsive: [
            {
                breakpoint: 1724,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    centerPadding: "60px",
                    arrows: photos && photos.length > 4 ? true : false,
                }
            },
            {
                breakpoint: 1500,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    centerPadding: "60px",
                    arrows: photos && photos.length > 3 ? true : false,
                }
            },
            {
                breakpoint: 1100,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    arrows: false,
                    centerPadding: "60px",
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    arrows: false,
                    centerPadding: "60px",

                }
            },
            {
                breakpoint: 500,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    arrows: false,
                    centerPadding: "15px",
                }
            },
        ]
    }

    const class_name = photos && photos.length < 5? "left_element":""
    const isProfileOwner =uuid && member_uuid && member_uuid ===uuid;
 
    const deletePhoto = (photo) =>{
        const data = {
            uuid:uuid,
            photoId: photo.userImageId
        }
      
        dispatch(deleteSocialPhotos(data))
    }



    return (
        <SimpleReactLightbox>
            <div className="margin_top_20">
                <div className="futura_hv_bt_24_26_white margin_top_50 padding_125rem_lr_mobile">Photos</div>
                {/* <div className="margin_left_20 margin_top_30 margin_right_50 futura_md_bt_24_20_white padding_125rem_lr_mobile">(needs API )One week in Barcelona</div>
                <div className="futura_lt_bt_16_18_grey box_2 margin_right_50 padding_125rem_lr_mobile">(needs API )Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa.</div> */}
                <div className="d-flex padding_125rem_lr_mobile">
                    {/* <div className="futura_hv_bt_16_19_green">Prev(need data)</div>
                    <div className="futura_hv_bt_16_19_green ml-auto">Next(need data)</div> */}
                </div>
                <div className="margin_top_20">
                    {photos && photos.length > 0 ?
                        <div >
                            {selected && selected.userImageUri !== undefined && <Image src={selected.userImageUri} alt={selected.photoDescription} 
                            style={{width: '100%', height:390, objectFit:'cover'}}/>}

            <Slider {...settings} className={class_name+" videoSlider"} style={{ height: "300px",marginTop: 20,marginBottom: -60, }}>
                    { photos && photos.map((photo, idx) => {
                        return (
                            <div key={photo} className="padding_left" style={{width:271}}>
                           
                                <div  >
                                   <div className="photoDeleteThumbs" > 
                                      <Image   onClick={() => {setSelected(photo)}} src={photo.userImageUri} className="img_fit_cover d-block w-100 socialPhotoImg" alt={photo?.imageName} />
                                        {isProfileOwner && <div className="photoDeleteIcon" onClick={() => {deletePhoto(photo)}}> <DeleteIcon height={20} width={20}  fill={'rgb(248, 248, 248)'}  />  </div>}
                                        </div>
                                </div>
                            </div>
                        )
                    })}
                </Slider>

                         

                        </div>
                        : ""}

                </div>
            </div>
        </SimpleReactLightbox>
    )
}

Photos_.propTypes = {
    photos: PropTypes.array.isRequired,
}
const mapStateToProps = (state) => {
    return {
        photos: state.SocialPage.photos,
        member_uuid: state.Member.socialMember.member_uuid,
        uuid: state.Member.authModal.uuid,
    }
};
const Photos = connect(mapStateToProps, null)(Photos_)
export default Photos;