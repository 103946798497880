import * as Actions from '../../../redux/actions'

import { ListIcon, ThIcon } from "../Common/SVGBank"
import { MinusSignIcon, PlusSignIcon } from "../Common/SVGBank"

import FeatureTypeIcon from '../Common/FeatureTypeIcon'
import React from 'react'
import { connect } from 'react-redux'
import { list_container_mode } from '../Common/RegExValidate'
import { member_area_router } from "../Common/RegExValidate"

const Types_ = ({ destination, setSwitchFeatureTypeContainerOfDestination, switch_container, switchFeatureContainer, isMobile, curentDestinationId, lang, setSwitchDestinationListContainer }) => {
    const [extender, setExtender] = React.useState(false)
    const { switchFeatureTypeContainer } = destination
    if (!isNaN(isMobile)) {
    }
    return (
        <React.Fragment>

            { switch_container === member_area_router.memberAreaTrip ?

                <div className="d-flex margin_top_30">
                    <div style={{ flexGrow: "1" }}>
                        {destination !== undefined
                            && destination.types !== undefined
                            && destination.types.length > 0
                            && destination.types.map((type, type_index) => {
                                return (
                                    (type_index < 8 || extender) &&
                                    <div
                                        key={type_index}
                                        onClick={() => setSwitchFeatureTypeContainerOfDestination(curentDestinationId, lang, switchFeatureContainer, type)}
                                        style={{
                                            backgroundColor: switchFeatureTypeContainer.typeID === type.typeID ? switchFeatureContainer.color : "var(--mainWhite)",
                                            color: switchFeatureTypeContainer.typeID === type.typeID ? "var(--mainWhite)" : "var(--mainGrey)",
                                            height: "30px",
                                            display: "inline-flex",
                                            borderRadius: "25px",
                                            flexWrap: "wrap",
                                            cursor: "pointer",
                                            verticalAlign: "middle",
                                            padding: ".375rem .75rem",
                                            marginRight: "6px",
                                            marginTop: "6px",
                                        }}>
                                        <FeatureTypeIcon
                                            icon={type.icon}
                                            fill={switchFeatureTypeContainer.typeID === type.typeID ? "#FFFFFF" : "#707070"}
                                            style={{
                                                width: "15px",
                                                height: "16px",
                                                marginLeft: "5px",
                                                marginRight: "10px",
                                            }} />
                                        <div style={{
                                            font: "16px/18px Futura Lt BT",
                                            color: switchFeatureTypeContainer.typeID === type.typeID ? "#FFFFFF" : "#707070",
                                        }}>{type.typeName}</div>
                                    </div>
                                )
                            })
                        }
                        {destination.types.length > 8 && !extender && <div onClick={() => setExtender(true)} style={{ display: "inline-block", }}><PlusSignIcon style={{ marginTop: "6px", }} fill={switchFeatureContainer.color} /></div>}
                        {destination.types.length > 8 && extender && <div onClick={() => setExtender(false)} style={{ display: "inline-block", }}><MinusSignIcon style={{ marginTop: "6px", }} fill={switchFeatureContainer.color} /></div>}
                    </div>
                    <div onClick={() => setSwitchDestinationListContainer(list_container_mode.list)}>
                        <ListIcon style={{ marginLeft: "10px", marginRight: "10px", cursor: "pointer" }}
                            fill={destination.destinaionListDisplayFormat === list_container_mode.list ? switchFeatureContainer.color : "var(--lightGray)"} />
                    </div>
                    <div onClick={() => setSwitchDestinationListContainer(list_container_mode.grid)}>
                        <ThIcon style={{ marginLeft: "10px", marginRight: "10px", cursor: "pointer" }}
                            fill={destination.destinaionListDisplayFormat === list_container_mode.grid ? switchFeatureContainer.color : "var(--lightGray)"} />
                    </div>
                </div>
                :

                <div className="d-flex margin_top_30">
                    <div style={{ flexGrow: "1" }}>
                        {destination !== undefined
                            && destination.types !== undefined
                            && destination.types.length > 0
                            && destination.types.map((type, type_index) => {
                                return (
                                    (type_index < 8 || extender) &&
                                    <div
                                        key={type_index}
                                        onClick={() => setSwitchFeatureTypeContainerOfDestination(curentDestinationId, lang, switchFeatureContainer, type)}
                                        style={{
                                            backgroundColor: switchFeatureTypeContainer.typeID === type.typeID ? switchFeatureContainer.color : "var(--mainWhite)",
                                            color: switchFeatureTypeContainer.typeID === type.typeID ? "var(--mainWhite)" : "var(--mainGrey)",
                                            border: "1px solid #E8E8E8",
                                            height: "30px",
                                            display: "inline-flex",
                                            borderRadius: "25px",
                                            flexWrap: "wrap",
                                            cursor: "pointer",
                                            verticalAlign: "middle",
                                            padding: ".375rem .75rem",
                                            marginRight: "6px",
                                            marginTop: "6px",
                                        }}>
                                        <FeatureTypeIcon
                                            icon={type.icon}
                                            fill={switchFeatureTypeContainer.typeID === type.typeID ? "#FFFFFF" : "#707070"}
                                            style={{
                                                width: "15px",
                                                height: "16px",
                                                marginLeft: "5px",
                                                marginRight: "10px",
                                            }} />
                                        <div style={{
                                            font: "16px/18px Futura Lt BT",
                                            color: switchFeatureTypeContainer.typeID === type.typeID ? "#FFFFFF" : "#707070",
                                        }}>{type.typeName}</div>
                                    </div>
                                )
                            })
                        }
                        {destination.types.length > 8 && !extender && <div onClick={() => setExtender(extender)} style={{ display: "inline-block", }}><PlusSignIcon style={{ marginTop: "6px", }} fill={switchFeatureContainer.color} /></div>}
                        {destination.types.length > 8 && extender && <div onClick={() => setExtender(extender)} style={{ display: "inline-block", }}><MinusSignIcon style={{ marginTop: "6px", }} fill={switchFeatureContainer.color} /></div>}
                    </div>
                    <div onClick={() => setSwitchDestinationListContainer(list_container_mode.list)}>
                        <ListIcon style={{ marginLeft: "10px", marginRight: "10px", cursor: "pointer" }}
                            fill={destination.destinaionListDisplayFormat === list_container_mode.list ? switchFeatureContainer.color : "var(--lightGray)"} />
                    </div>
                    <div onClick={() => setSwitchDestinationListContainer(list_container_mode.grid)}>
                        <ThIcon style={{ marginLeft: "10px", marginRight: "10px", cursor: "pointer" }}
                            fill={destination.destinaionListDisplayFormat === list_container_mode.grid ? switchFeatureContainer.color : "var(--lightGray)"} />
                    </div>
                </div>}
        </React.Fragment>
    )
}

const mapStateToProps = state => {
    return {
        destination: state.Destination.destination,
        isMobile: state.Setting.is_mobile,
        curentDestinationId: state.Destination.destination.activeId,
        switchFeatureContainer: state.FormBank.FeatureFormat,
        switch_container: state.Member.switch_container,
    }
}
const mapDispatchToProps = {
    setSwitchFeatureTypeContainerOfDestination: Actions.setSwitchFeatureTypeContainerOfDestination,
    setSwitchDestinationListContainer: Actions.setSwitchDestinationListContainer,
}

const Types = connect(mapStateToProps, mapDispatchToProps)(Types_)
export default Types
