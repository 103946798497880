import "./SocialPage.css"

import * as Actions from '../../../redux/actions'
import {withRouter} from 'react-router-dom';
import { member_area_router, validate, validate_fields } from "../Common/RegExValidate"

import Banner from '../Common/Banner.jsx'
import Header from '../Header/Header.jsx'
import ItineraryMap from "../Common/Trip/ItineraryMap"
import Loader from 'react-loader-spinner'
import MemberActionsInitial from "../Common/MemberActions/MemberActionsInitial"
import MemberAreaContainer from "./MemberAreaContainer"
import PageNotFound from '../ErrorPage/PageNotFound.jsx'
import PrivateTripEstablishment from "../Common/Trip/PrivateTripEstablishment"
import Profile from '../Common/Profile/ProfileBar.jsx'
import { PropTypes } from "prop-types"
import React from 'react'
import { Redirect } from "react-router-dom"
import SocialContainer from "./SocialContainer.jsx"
import TimelineWizardContainer from "../Common/TimelineWizardContainer"
import TripComtainer from "../Common/Trip/TripContainer"
import { connect } from 'react-redux'
import { list_container_mode } from '../Common/RegExValidate'

/* home member page component */
class SocialPage_ extends React.Component {
  /* fatch tirp  */

  /* update screen size */
  updateDimensions = () => {
    this.props.setScreenSize(window.screen.width, window.screen.height)
    this.props.setBodyClientSize(document.body.clientWidth, document.documentElement.scrollHeight)
    this.props.setAppMenu(document.body.clientWidth)
  }
  /* initial component */
  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions)
    this.updateDimensions()

    this.props.setSwitchContainer(member_area_router.social)
    this.props.setAppMenuBookingButtonStatus(true)
  }
  /* destory component */
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions)
  }
  render() {
    const lang = this.props.lang
    const { uuid, preload, switch_container, bodyClientWidth, bodyClientHeight, tripMode } = this.props
    let containerH = 150
    if (!isNaN(bodyClientWidth)) {
      const imageHeight = bodyClientWidth / 7
      containerH = (imageHeight > 200 ? 200 : (imageHeight < 120 ? 120 : imageHeight))
    }


    if((uuid !== null|| uuid !== undefined)  && uuid === "Handle Not found"){
      this.props.history.push('/landing');
    }
    return (
      validate(lang, validate_fields.languages) ?
        <div style={{
          height: "auto",
        }}>
          {
            preload ? <Loader
              type="Rings"
              color="#19BC9B"
              className="se-pre-con"
              height={150}
              width={150}
              style={{ backgroundColor: "#2C2C2C" }} /> : ""

          }
          {switch_container === member_area_router.social &&
            <div style={{ backgroundColor: "#2C2C2C", minHeight: bodyClientHeight, height: "auto", paddingBottom: containerH }}>
              {uuid !== undefined && uuid !== "Handle Not found" &&
                <React.Fragment>
                  <Header bg_color={"rgba(25,25,25, 1)"} text_color={"var(--mainWhite)"} />
                  <Banner />
                  <Profile />
                  <SocialContainer props={this.props} />
                </React.Fragment>
              }
            </div>}
          {switch_container === member_area_router.memberAreaTrip &&
            <React.Fragment>
              {tripMode === list_container_mode.map ?
                <React.Fragment>
                  <Header bg_color={"rgba(25,25,25, 1)"} text_color={"var(--mainWhite)"} header_height={"half"} />
                  <TimelineWizardContainer listDisplayFormat={list_container_mode.map} />
                  {window.google !== undefined && <ItineraryMap />}
                </React.Fragment> :
                (tripMode === list_container_mode.timeline ?
                  <React.Fragment>
                    <Header bg_color={"rgba(25,25,25, 1)"} text_color={"var(--mainWhite)"} />
                    <TimelineWizardContainer listDisplayFormat={list_container_mode.timeline} />
                    <PrivateTripEstablishment />
                  </React.Fragment> :
                  <TripComtainer />)}
            </React.Fragment>}
          {(switch_container === member_area_router.trips
            || switch_container === member_area_router.bucketList
            || switch_container === member_area_router.favorites
            || switch_container === member_area_router.personalInfo
            || switch_container === member_area_router.muvTribe)
            &&
            <React.Fragment>
              <Header bg_color={"rgba(25,25,25, 1)"} text_color={"var(--mainWhite)"} />
              <Banner />
              <Profile />
              <MemberAreaContainer />
            </React.Fragment>}

          <MemberActionsInitial />
        </div>
        :
        <Redirect to={"error"} component={PageNotFound} />
    )
  }
}


SocialPage_.propTypes = {
  error: PropTypes.string.isRequired,
  uuid: PropTypes.string.isRequired,
}
const mapStateToProps = (state) => {
  return {
    error: state.Social.error,
    lang: state.Setting.lang,
    uuid: state.Social.member_uuid,
    preload: state.Setting.preload,
    switch_container: state.Member.switch_container,
    bodyClientWidth: state.Setting.htmlBody.bodyClientWidth,
    bodyClientHeight: state.Setting.htmlBody.bodyClientHeight,
    tripMode: state.Member.tripMode,

  }
}
const mapDispatchToProps = {
  setSwitchContainer: Actions.setSwitchContainer,
  setScreenSize: Actions.setScreenSize,
  setBodyClientSize: Actions.setBodyClientSize,
  setAppMenu: Actions.setAppMenu,

  setAppMenuBookingButtonStatus: Actions.setAppMenuBookingButtonStatus,
}

const SocialPage = connect(mapStateToProps, mapDispatchToProps)(SocialPage_)
export default withRouter(SocialPage)


