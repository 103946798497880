import * as Actions from '../../../redux/actions'

import React, { Component } from 'react'

import BuscketListContainer from "../MemberArea/BucketList/BucketListContainer"
import FavoritesContainer from "../MemberArea/Favorites/FavoritesContainer"
import MuvTribeContainer from "../MemberArea/Muver/MuvTribePageContainer"
import ProfileContainer from "../MemberArea/Profile/ProfileContainer"
import TripsContainer from "../MemberArea/Trip/TripsContainer"
import { connect } from 'react-redux'
import { member_area_router, } from "../Common/RegExValidate"

class MemberAreaContainer_ extends Component {

  

    componentDidMount() {
        const { authModal, getMemberAreaMuverInfo } = this.props
        authModal.uuid !== undefined && authModal.uuid !== "" && getMemberAreaMuverInfo(authModal.uuid, authModal.language.short_code)

        window.addEventListener("resize", this.updateDimensions)
    }
    

    
    render() {
        const { switch_container } = this.props

        return (
            <div>
                {switch_container === member_area_router.trips && <TripsContainer />}
                {switch_container === member_area_router.bucketList && <BuscketListContainer />}
                {switch_container === member_area_router.favorites && <FavoritesContainer />}
                {switch_container === member_area_router.muvTribe && <MuvTribeContainer />}
                {switch_container === member_area_router.personalInfo && <ProfileContainer />}
            </div >
        )
    }
}

const mapStateToProps = state => {
    return {
        authModal: state.Member.authModal,
        switch_container: state.Member.switch_container,
    }
};

const mapDispatchToProps = {
    getMemberAreaMuverInfo: Actions.getMemberAreaMuverInfo,
    
};


const MemberAreaContainer = connect(mapStateToProps, mapDispatchToProps)(MemberAreaContainer_)
export default MemberAreaContainer
