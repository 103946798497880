import React from 'react'
import { connect, useSelector } from 'react-redux'
import "./ReviewsUser.css"
import ReviewsUserRating from "./ReviewsUserRating"
import ReviewsUserPost from "./ReviewsUserPost"
import ReviewsRatingComponent from './ReviewsUserRating';


const ReviewsUser_ = ({ lang, establishment, uuid,switchFeatureContainer }) => {
    

    //if(hasReviews) console.log("ReviewsUser_ ",establishment.generalInfo,establishment.generalInfo.guest_score_categories);

    const hotelReviews = useSelector(state => state.HotelBooking.reviews);
    const reviewData = hotelReviews?.results;
    const reviewCount = hotelReviews?.data?.review_count;
    const hasReviews = reviewCount && reviewData;



    return (
        <div className="reviewsUser">
            {hasReviews && <div className="rating">  
                <ReviewsRatingComponent generalInfo = {establishment.generalInfo} color={'#19BC9B'}/>    
            </div>}
        </div>)    
}

const mapStateToProps = (state) => ({
        lang: state.Setting.lang,
        establishment: state.FormBank.HotelSearch.establishment,
        uuid: state.Member.authModal.uuid,
        switchFeatureContainer: state.FormBank.FeatureFormat,
})

const mapDispatchToProps = {

}

const ReviewsUser = connect(mapStateToProps, mapDispatchToProps)(ReviewsUser_)
export default ReviewsUser
