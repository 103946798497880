import Rating from '@material-ui/lab/Rating';
import React from 'react'
import { connect } from 'react-redux'

const BucketListBasicInfo_ = ({ bucketlist, is_mobile }) => {
    const { generalInfo, subtypes } = bucketlist
    const establishmentStarRating = 4.5
    return (
        <div >
            <div className="d-flex timeline_rating_color padding_left_10 padding_right_10 btn_rounded" style={{ marginBottom: "5px", }}>
                {/* <div style={{ font: "16px/19px Futura Md BT", color: "#707070" }}>
                    <Rating name="timeline_rating" precision={0.5} max={5} value={parseFloat(establishmentStarRating)} disabled={true} /> 
                    
                    {"(" + establishmentStarRating + ")"}</div> */}
            </div>
            {subtypes !== undefined && subtypes.length > 0 &&
                subtypes.map((subtype, subtype_idx) => {
                    return <div key={subtype_idx} style={{backgroundColor: 'rgba(251, 136, 77,0.2)'}}
                        className="btn_rounded m-1 futura_md_bt_14_16_orange upcoming_trip_style">
                        {subtype.subTypeTitle}</div>
                })}
        </div>
    )
}

const mapStateToProps = (state) => ({

    bucketlist: state.Destination.bucketlist,
    is_mobile: state.Setting.is_mobile,
    lang: state.Setting.lang,
    uuid: state.Member.authModal.uuid,

    switchFeatureContainer: state.FormBank.FeatureFormat,
})

const mapDispatchToProps = {

}

const BucketListBasicInfo = connect(mapStateToProps, mapDispatchToProps)(BucketListBasicInfo_)
export default BucketListBasicInfo
