import {
    CURRENT_BODY_CLIENT_WIDTH,
    CURRENT_LANGUAGE,
    CURRENT_PAGE_Y_OFFSET,
    CURRENT_SCREEN_SIZE,
    CURRENT_SCREEN_VERTICAL,
    CURRENT_SCREEN_VERTICAL_IS_TOP,
    FETCH_API_CURRENCY_SETS,
    FETCH_API_CURRENCY_SETS_ERROR,
    FETCH_API_CURRENCY_SETS_FETCHED,
    FETCH_API_LANGUAGE_SETS,
    FETCH_API_LANGUAGE_SETS_ERROR,
    FETCH_API_LANGUAGE_SETS_FETCHED,
    SET_APP_MENU,
    SET_APP_MENU_BACK_GROUND_COLOR,
    SET_APP_MENU_BACK_GROUND_COLOR_MOBILE,
    SET_APP_MENU_BOOKING_FORM_STATUS,
    SET_APP_MENU_TEXT_COLOR,
    SET_CURRENT_CURRENCY,
    SET_CURRENT_LANGUAGE,
    SET_LOADER,
} from "../interfaces/types"

const initialState = {
    is_mobile: false,
    lang: "en",
    preload: true,
    currency: {
        selected: {
            shortCode: "usd",
            symbol: "$",
            name: "USD",
        },
        currencySets: [],
        apiError: false,
        isFetched: false,
    },
    language: {
        selected: {
            shortCode: "en",
            longCode: "eng",
            englishName: "EN",
            nativeName: "English",
        },
        languageSets: [],
        apiError: false,
        isFetched: false,
    },
    screenSize: {
        width: window.screen.width,
        height: window.screen.height,
    },
    htmlBody: {
        bodyClientWidth: 0,
        bodyClientHeight: 0,
    },
    screenVertical: {
        windowHeight: 0,
        windowBottom: 0,
        isBottom: false,
        isTop: true,
        pageYOffset: 0,
    },
    appMenu: {
        logo: {
            width: 51,
            height: 51,
        },
        app_menu_height: 69,
        textColor: "",
        backGroundColor: "",
        backGroundColorMobile: "#191919",
        appMenuBookingButtonStatus: false,
    },


}


/* display activity reducer */
export default (state = initialState, action) => {
    switch (action.type) {
        case CURRENT_LANGUAGE:
            return {
                ...state,
                lang: action.lang,
            };
        case SET_LOADER:
            return {
                ...state,
                preload: action.preload,
            };

        case CURRENT_SCREEN_VERTICAL:
            return {
                ...state,
                screenVertical: {
                    ...state.screenVertical,
                    windowHeight: action.vertical_obj.windowHeight,
                    windowBottom: action.vertical_obj.windowBottom,
                    isBottom: action.vertical_obj.isBottom,
                }
            };
        case CURRENT_SCREEN_VERTICAL_IS_TOP:
            return {
                ...state,
                screenVertical: {
                    ...state.screenVertical,
                    isTop: action.is_top,
                }
            };
        case CURRENT_PAGE_Y_OFFSET:
            return {
                ...state,
                screenVertical: {
                    ...state.screenVertical,
                    pageYOffset: action.page_y_offest,
                }
            };
        case CURRENT_SCREEN_SIZE:
            return {
                ...state,
                screenSize: {
                    ...state.screenSize,
                    width: action.width,
                    height: action.height,
                }
            };
        case CURRENT_BODY_CLIENT_WIDTH:
            return {
                ...state,
                htmlBody: {
                    ...state.htmlBody,
                    bodyClientWidth: action.client_width,
                    bodyClientHeight: action.client_height,
                }
            };

        case SET_APP_MENU:
            return {
                ...state,
                appMenu: {
                        ...state.appMenu,
                        logo: {
                            ...state.appMenu.logo,
                            width: action.logo_width,
                            height: action.logo_height,
                        },
                        app_menu_height: action.app_menu_height,
                    },
                    is_mobile: action.is_mobile,
            };
        case SET_CURRENT_CURRENCY:
            return {
                ...state,
                currency: {
                    ...state.currency,
                    selected: {
                        ...state.currency.selected,
                        shortCode: action.short_code,
                        symbol: action.symbol,
                        name: action.name,
                    },
                }
            };
        case FETCH_API_CURRENCY_SETS:
            return {
                ...state,
                currency: {
                    ...state.currency,
                    currencySets: action.currency_sets,
                }
            };
        case FETCH_API_CURRENCY_SETS_ERROR:
            return {
                ...state,
                currency: {
                    ...state.currency,
                    apiError: action.api_error,
                }
            };
        case FETCH_API_CURRENCY_SETS_FETCHED:
            return {
                ...state,
                currency: {
                    ...state.currency,
                    isFetched: action.is_fetched,
                }
            };
        case SET_CURRENT_LANGUAGE:
            return {
                ...state,
                language: {
                    ...state.language,
                    selected: {
                        ...state.language.selected,
                        shortCode: action.short_code,
                        longCode: action.long_code,
                        englishName: action.english_name,
                        nativeName: action.native_name,
                    },
                }
            };
        case FETCH_API_LANGUAGE_SETS:
            return {
                ...state,
                language: {
                    ...state.language,
                    languageSets: action.language_sets,
                }
            };
        case FETCH_API_LANGUAGE_SETS_ERROR:
            return {
                ...state,
                language: {
                    ...state.language,
                    apiError: action.api_error,
                }
            };
        case FETCH_API_LANGUAGE_SETS_FETCHED:
            return {
                ...state,
                language: {
                    ...state.language,
                    isFetched: action.is_fetched,
                }
            };
        case SET_APP_MENU_BACK_GROUND_COLOR:
            return {
                ...state,
                appMenu: {
                    ...state.appMenu,
                    backGroundColor: action.color
                },
            };
        case SET_APP_MENU_BACK_GROUND_COLOR_MOBILE:
            return {
                ...state,
                appMenu: {
                    ...state.appMenu,
                    backGroundColorMobile: action.color
                },
            };
        case SET_APP_MENU_BOOKING_FORM_STATUS:
            return {
                ...state,
                appMenu: {
                    ...state.appMenu,
                    appMenuBookingButtonStatus: action.status
                },
            };
        case SET_APP_MENU_TEXT_COLOR:
            return {
                ...state,
                appMenu: {
                    ...state.appMenu,
                    textColor: action.color
                },
            };

        default:
            return {
                ...state
            }
    }
}