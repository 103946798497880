import React from 'react'
import { connect } from 'react-redux'
import * as Actions from '../../../../redux/actions'
import PageNotFound from '../../ErrorPage/PageNotFound'
import { Redirect } from "react-router-dom"
import { list_container_mode, member_area_router, validate, validate_fields } from '../../Common/RegExValidate'
import Loader from 'react-loader-spinner'
import Header from '../../Header/Header'
import './FlightManagement.css';
import TimelineWizardContainer from '../../Common/TimelineWizardContainer'
import FlightManagementContainer from './FlightManagementContainer'
/* flights page component */

const FlightManagementPage_ = ({ setScreenSize, setBodyClientSize, setAppMenu, preload, lang, tripMode, featureListDisplayFormat,
    setSwitchContainer, setTripDisplayMode,timelineStatus,fetchActiveTripFromCookies, setAppMenuBookingButtonStatus, setPageYOffset

}) => {

    const updateDimensions = () => {
        setScreenSize(window.screen.width, window.screen.height)
        setBodyClientSize(document.body.clientWidth, document.documentElement.scrollHeight)
        setAppMenu(document.body.clientWidth)
    }

    React.useEffect(() => {
        window.addEventListener("resize", updateDimensions)
        updateDimensions()
        setPageYOffset(0)
        setSwitchContainer(member_area_router.flightSearch)
        setAppMenuBookingButtonStatus(true)
        setTripDisplayMode(list_container_mode.timeline)
        fetchActiveTripFromCookies("", lang, false)
        return () => {
            window.removeEventListener("resize", updateDimensions)
        }
    }, [])

   
    return (
        validate(lang, validate_fields.languages) ?
            <div style={{
                height: "auto",
            }}>
               
                <React.Fragment>


                     <Header bg_color={"#FFFFFF"} text_color={"var(--frameBlack)"} />
                    <TimelineWizardContainer listDisplayFormat={featureListDisplayFormat} />
                    <FlightManagementContainer />
                    
                </React.Fragment>

            </div>
            :
            <Redirect to={"error"} component={PageNotFound} />
    )
}


const mapStateToProps = (state) => {
    return {
        uuid: state.Member.authModal.uuid,
        lang: state.Setting.lang,
        preload: state.Setting.preload,
        tripMode: state.Member.tripMode,
        timelineStatus: state.FormBank.TimelineWizard.status,
    }

}

const mapDispatchToProps = {
    setSwitchContainer: Actions.setSwitchContainer,
    setScreenSize: Actions.setScreenSize,
    setBodyClientSize: Actions.setBodyClientSize,
    setAppMenu: Actions.setAppMenu,
    setPageYOffset: Actions.setPageYOffset,
    setTripDisplayMode: Actions.setTripDisplayMode,
    fetchActiveTripFromCookies: Actions.fetchActiveTripFromCookies,
    setAppMenuBookingButtonStatus: Actions.setAppMenuBookingButtonStatus,
}
const FlightManagementPage = connect(mapStateToProps, mapDispatchToProps)(FlightManagementPage_)

export default FlightManagementPage