import {
    StarIcon,
} from "../SVGBank"

import React from 'react'
import { connect } from 'react-redux'
import * as Actions from '../../../../redux/actions'
import { Grid } from "@material-ui/core"
import moment from "moment"

const TimelineBookedHotel_ = ({ hotel, switchFeatureContainer }) => {
    const checkIn = hotel.check_in?  moment(hotel.check_in).format('ddd MMM DD') : "" ;
    const checkOut = hotel.check_out? moment(hotel.check_out).format('ddd MMM DD')  : "";
    const imageUrl = hotel?.images && hotel?.images.length > 0 ? hotel?.images[0]?.full_url:null;
    const bookingId = hotel?.booking_trip_number;
    const email = hotel?.booking_email;
    const canViewDetails = email &&bookingId&&imageUrl;


    const goToBooking  =() =>{
    
        if(canViewDetails){
            const url = `/hotel/booking_details?booking_id=${bookingId}&email=${email}&imageUrl=${imageUrl}`;
            window.open(url);   
        }
    }


    return (
        <div style={{
            marginTop: "20px",
            marginLeft: "12px",
            marginRight: "0px",
            marginBottom: "12px",
            backgroundColor: "var(--mainWhite)",
            textAlign: "center",
            position: "relative",
        }}>
            <div style={{
                background: "var(--frameBlack)"
            }}>
                 {hotel && hotel.images && hotel.images.length > 0 && <img height={156} style={{
                    width: "100%",
                    opacity: 0.6,
                    objectFit: "cover"
                }}  src={hotel.images[0]?.full_url} alt="hotelImage" />}
            </div>
          <Grid container spacing={3} style={{paddingTop:10,marginBottom:10,
            font: "16px/21px Futura Md BT", color: "white",  position: "absolute", marginTop: -50, marginLeft: 0}}>
                <Grid item xs={9} md={9} lg={9} xl={9} style={{paddingLeft: 20, textAlign: "left",maxHeight: 30, whiteSpace: "nowrap",
                 overflow: "hidden", textOverflow: "ellipsis"}} >
                {hotel.name}
                </Grid>
                <Grid item xs={3} md={3} lg={3} xl={3} style={{paddingLeft: 20, textAlign: "left",padding: 0, paddingTop: 12}} >
                <span style={{ color: "#19BC9B", font: "16px/21px Futura Hv BT", paddingLeft: 5 }}> {hotel.starRating}  <StarIcon width={15} height={15} color="#19BC9B" style={{marginTop:-5}} /> </span>
                </Grid>
            </Grid>
            <Grid container spacing={3} style={{paddingTop:10,paddingBottom:10}}>
                <Grid item style={{paddingLeft: 20, textAlign: "left"}} >
                <div style={{ font: "14px/16px Futura LT BT", color: "var(--frameBlack)", paddingBottom:6,  }}>Check In:</div>
                <div style={{ font: "14px/16px Futura Md BT", color: "var(--frameBlack)" }}> {checkIn}</div>
                </Grid>
                <Grid item style={{paddingLeft: 20, textAlign: "left"}} >
                <div style={{ font: "14px/16px Futura LT BT", color: "var(--frameBlack)", paddingBottom:6,  }}>Check Out:</div>
                <div style={{ font: "14px/16px Futura Md BT", color: "var(--frameBlack)" }}> {checkOut}</div>
                </Grid>
                <Grid  item style={{paddingLeft: 20, textAlign: "left"}} >
                <div style={{ font: "14px/16px Futura LT BT", color: "var(--frameBlack)", paddingBottom:6,  }}>Address:</div>
                <div style={{ font: "14px/16px Futura Md BT", color: "var(--frameBlack)" }}>{hotel.address}</div>
                </Grid>
                <Grid  item style={{paddingLeft: 20, textAlign: "left"}} >
                <div style={{ font: "14px/16px Futura LT BT", color: "var(--frameBlack)", paddingBottom:6,  }}>Confirmation #:</div>
                <div style={{ font: "14px/16px Futura Md BT", color: "var(--frameBlack)" }}>{hotel.booking_trip_number}</div>
                </Grid>
            </Grid>
            {canViewDetails && <div>
            <hr style={{ border: "1px solid #E8E8E8", height: "0px", margin: "0px", }} />
            <div onClick={goToBooking} style={{ font: "14px/16px Futura Md BT",cursor:"pointer", color: "#19BC9B", paddingTop: "15px", paddingBottom: "15px", }}>Hotel Booking Details</div>
            </div>}
        </div>
    )
}

const mapStateToProps = (state) => ({
    switchFeatureContainer: state.FormBank.FeatureFormat,

})

const mapDispatchToProps = {
}

const TimelineBookedHotel = connect(mapStateToProps, mapDispatchToProps)(TimelineBookedHotel_)
export default TimelineBookedHotel
