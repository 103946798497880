import * as Actions from '../../../../redux/actions'

import React, { Component } from 'react'

import { connect, useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom';
import { ScrollTopLazyLoading } from '../../Common/ScrollTopLazyLoading';
import { Backdrop, CircularProgress, Fab, Grid, makeStyles } from '@material-ui/core';
import { AngleUpIcon } from '../../Common/SVGBank';
import { useHistory } from "react-router-dom";
import { FlightPaymentForm } from '../PaymentPage/PaymentForm';
import { FlightTotalComponent } from '../RecapPage/FlightTotalComponent';
import { clearFlightBooking } from '../services/Flights.slice';
import { CongratulationsBookedFlight } from '../RecapPage/FlightSharedComponents';
import { FlightSearchComponent } from '../FlightsSearch';


const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));


const FlightsPaymentContainer_ = ({ lang, bodyClientWidth, pageYOffset, is_mobile, timelineWizardStatus }) => {
    const params = useParams()
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch()
    let width;
    let marginLeft;
    let marginRight;
    React.useEffect(() => {
        return () => {
            dispatch(clearFlightBooking())
        }
    }, [])

    let positionScrollToTopLazyLoading = ""
    if (!isNaN(is_mobile)) {
        width = is_mobile ? '90%' : '67%';
        marginLeft = is_mobile ? '5%' : timelineWizardStatus ? '31%' : '15.5%';
        marginRight = is_mobile ? '5%': 0;
        positionScrollToTopLazyLoading = is_mobile ? "" : (timelineWizardStatus ? "10%" : "10%")
    }
   
    const loading = useSelector(state => state.Flights.loading);
    const bookingSuccess = useSelector(state => state.Flights.bookingSuccess);




    return (
        <div>
            <div style={{
                position: "relative",
                width: width,
                marginLeft: marginLeft,
            }}>
               <div>
                <div id="back_to_top_flights"></div>

                {bookingSuccess && <div>
                        <FlightSearchComponent bodyClientWidth={bodyClientWidth} />
                    </div>}

                <Grid container className="flightRecap">

                    <Grid item md={8} sm={12} xs={12} className="flightRecapBody paymentPage" >
                         
                
                    {bookingSuccess ?
                        <CongratulationsBookedFlight/>:<FlightPaymentForm/>}

                    </Grid>

                    {/* spacing */}
                    <Grid item xs={false} sm={false} md={1} style={{ maxWidth: 40 }}>
                    </Grid>


                    {!is_mobile &&<Grid item md sm={12} xs={12} >
                    <FlightTotalComponent />
                    </Grid>}


                </Grid>

                </div>

               
                <Backdrop className={classes.backdrop} open={loading} >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </div>


            <ScrollTopLazyLoading targetId={"#back_to_top_flights"} rightPosition={positionScrollToTopLazyLoading}>
                <Fab size="small" aria-label="scroll back to top" style={{
                    outline: "0px",
                    backgroundColor: '#19BC9B',
                }}>
                    <AngleUpIcon fill={"var(--mainWhite)"} style={{ marginLeft: "3px" }} />
                </Fab>
            </ScrollTopLazyLoading>
        </div>
    )
}


const mapStateToProps = state => {
    return {
        lang: state.Setting.lang,
        is_mobile: state.Setting.is_mobile,
        lazyLoading: state.FormBank.HotelSearch.lazyLoading,
        hotelSearchForm: state.FormBank.HotelSearch.hotel_form_state,
        bookingForm: state.FormBank.BookingForm,
        bodyClientWidth: state.Setting.htmlBody.bodyClientWidth,
        pageYOffset: state.Setting.screenVertical.pageYOffset,
        switchFeatureContainer: state.FormBank.FeatureFormat,
        timelineWizardStatus: state.FormBank.TimelineWizard.status,
    }
}

const mapDispatchToProps = {
    setTripDisplayMode: Actions.setTripDisplayMode,
    setPageYOffset: Actions.setPageYOffset
}

const FlightsPaymentContainer = connect(mapStateToProps, mapDispatchToProps)(FlightsPaymentContainer_)
export default FlightsPaymentContainer
