import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import React from 'react'

export function AmenitiesList({ amenities, boxDescription, fontDescription, colorDescription, height }) {
  const [open, setOpen] = React.useState(false)

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen)

  }
  const handleClose = event => {
    setOpen(false)
  }
  return (
    <ClickAwayListener onClickAway={handleClose}>
      {!open ?<div  >
                <div className={boxDescription} style={{ maxHeight: height, overflow:"hidden", font: fontDescription, color: colorDescription, columns: 2 }}>
                  
                    {amenities !== undefined && amenities.map((amenity, idx) => {
                        return <div key={idx}>{amenity.name}</div>
                    })}                        
                </div>   
                <div onClick={() => handleToggle()} 
                     style={{cursor: 'pointer', marginBottom:15, font: "14px/16px Futura Md BT", color: "var(--mainGreen)",textAlign:"left", marginRight:5, marginTop:5}} > see more</div>                 
              </div>
            :
            <div  >
              <div className={boxDescription} style={{ font: fontDescription, color: colorDescription, columns: 2 }}>
                
                  {amenities !== undefined && amenities.map((amenity, idx) => {
                      return <div key={idx}>{amenity.name}</div>
                  })}                        
              </div>   
              <div onClick={() => handleToggle()}
                   style={{cursor: 'pointer', marginBottom:15, font: "14px/16px Futura Md BT", color: "var(--mainGreen)",textAlign:"left", marginRight:5, marginTop:5}} > see less</div>                 
            </div>
        
      }
    </ClickAwayListener>
  )
}