import React from 'react'
import { connect } from 'react-redux'
import { image_boxing } from '../Common/RegExValidate'

const TopBarSlider_ = ({ topImage, screenSize, is_mobile }) => {

    let topImageHeight = is_mobile ? "180" : "250"
    let containerH = 500
    if (topImageHeight !== undefined) {
        containerH = topImageHeight + "px"
    }

    let srcImage = ""
    srcImage = topImage !== undefined && topImage !== null ? image_boxing(topImage, screenSize, topImageHeight) : ""
    return (
        <div className="position-relative z_index_2">
            <div style={{
                width: "100%",
                display: "block",
                position: "relative",
                top: "0",
                left: "0",
                bottom: "0",
                right: "0",
                border: "0",
                height: containerH,
                backgroundImage: "url('" + srcImage + "')",
                backgroundRepeat: "no-repeat",
            }}></div>
            <div className="mask_block" style={{ height: containerH }}></div>

        </div >
    )

}


const mapStateToProps = state => {
    return {
        screenSize: Math.min(state.Setting.htmlBody.bodyClientWidth, document.body.clientWidth),
        is_mobile: state.Setting.is_mobile,
        switchFeatureContainer: state.FormBank.FeatureFormat,
        topImage: state.Feature.feature.topImage,
    }
}

const mapDispatchToProps = {
}

const TopBarSlider = connect(mapStateToProps, mapDispatchToProps)(TopBarSlider_)
export default TopBarSlider
