import * as Actions from '../../../redux/actions'

import React, { Component } from 'react'
import { image_boxing, list_container_mode, member_area_router, URL_REPLACE } from '../Common/RegExValidate'

import { AngleUpIcon, EllipsisVIcon } from '../Common/SVGBank'
import Fab from '@material-ui/core/Fab'
import { Heart } from '../Common/MemberActions/Heart'
import {
    Image,
} from 'react-bootstrap'
import { Plus } from '../Common/MemberActions/Plus'
import Rating from '@material-ui/lab/Rating'
import { ScrollTopLazyLoading } from "../Common/ScrollTopLazyLoading"
import { Star } from '../Common/MemberActions/Star'
import { TripDescription } from '../Common/Trip/TripDescription'
import TripName from '../Common/Trip/TripName'
import { connect } from 'react-redux'
import ActionsEllipsis from '../Common/actionsEllipsis';
import { hexToRGB } from '../../../utility/utility';
import { Done } from '../Common/MemberActions/Done'
import { useState } from 'react';
import { Element, scroller } from 'react-scroll'
import InfiniteScroll from 'react-infinite-scroll-component';
import { useHistory } from "react-router-dom"


const Establishments_ = ({ featureList, loadMoreAllFeatureList, offset, lang, loadingFeatureListLessFeature,
    setSwitchContainer, timelineWizardStatus, selectedSubtypes, activeFeatureId, types, bodyClientWidth, loading,
    loadingFeatureListMoreFeature, isMobile, featureListDisplayFormat, switchFeatureContainer, }) => {
    const [open, setOpen] = React.useState(false)
    const [anchorEl, setAnchorEl] = React.useState(null);
    let widthContainer = ""
    let heightContainer = ""
    let gridContainer = ""
    let postionContainer = ""
    let positionScrollToTopLazyLoading = ""
    if (!isNaN(isMobile)) {
        widthContainer = isMobile ? (bodyClientWidth * 0.98) : (timelineWizardStatus ? ((bodyClientWidth - 380) * 0.65 / 2) : (bodyClientWidth * 0.65 / 2))
        heightContainer = isMobile ? "" : (timelineWizardStatus ? ((bodyClientWidth - 380) * 0.65 / 2 / 2) : (bodyClientWidth * 0.65 / 2 / 2))
        postionContainer = isMobile ? "block" : "d-flex"
        gridContainer = isMobile ? (bodyClientWidth * 0.98 - 5) : (timelineWizardStatus ? (Math.floor(bodyClientWidth * 0.535 / 3 - 8)) : (bodyClientWidth * 0.7 / 4 - 8 - 10))
        positionScrollToTopLazyLoading = isMobile ? "" : (timelineWizardStatus ? "10%" : "10%")
    }

    const handleEllipsis = () => {
        setOpen(prevOpen => !prevOpen)
    }
    const handleClose = () => {
        setAnchorEl(null);
    };

    const loadMore = () => {
        loadMoreAllFeatureList(activeFeatureId, loading, lang, offset)
    }


    return (

        <div
            style={{
                zIndex: "1",
            }}>
            <div id="back_to_top_trip"></div>
            <InfiniteScroll
                style={{ overflow: 'hidden', width: '100%' }}
                scrollThreshold={0.8}
                dataLength={featureList.length} //This is important field to render the next data
                next={loadMore}
                hasMore={true}
            >
                {featureList !== undefined && featureList.length > 0 &&
                    featureList.map((feature, feature_index) => {
                        const trips = feature.features // It's trips
                        return (
                            <div key={feature_index}>

                                <Element name={feature.title} className="element">
                                    {trips !== undefined && trips.length > 0 && <div style={{
                                        position: 'sticky',
                                        top: 240,
                                        background: 'white',
                                        zIndex: 2,
                                        font: "36px/72px Futura Hv BT", color: "var(--frameBlack)", marginTop: "10px"
                                    }}>{feature.title}</div>}

                                    <TripsComponent
                                        trips={trips}
                                        feature={feature}
                                        featureListDisplayFormat={featureListDisplayFormat}
                                        gridContainer={gridContainer}
                                        widthContainer={widthContainer}
                                        postionContainer={postionContainer}
                                        switchFeatureContainer={switchFeatureContainer}
                                        heightContainer={heightContainer}
                                        timelineWizardStatus={timelineWizardStatus}
                                        setSwitchContainer={setSwitchContainer}
                                    />
                                </Element>
                            </div>)
                    })}
            </InfiniteScroll>

            {featureList && featureList.length > 0  && featureList[0].features < 1 && <div className="noResults"  style={{font: "21px/21px Futura Md BT", marginTop: 30
                        }}> Sorry, could not find any results for your search </div>}

            
            <ScrollTopLazyLoading targetId={"#back_to_top_trip"} rightPosition={positionScrollToTopLazyLoading}>
                <Fab size="small" aria-label="scroll back to top" style={{
                    outline: "0px",
                    backgroundColor: switchFeatureContainer.color
                }}>
                    <AngleUpIcon fill={"var(--mainWhite)"} style={{ marginLeft: "3px" }} />
                </Fab>
            </ScrollTopLazyLoading>

            <style dangerouslySetInnerHTML={{
                __html: `
                .MuiRating-iconEmpty {
                    color: ${hexToRGB(switchFeatureContainer.color, '0.7')} !important;
                }
                `}} />
        </div >
    )
}


const mapStateToProps = (state) => {
    return {
        featureListDisplayFormat: state.Feature.feature.featureListDisplayFormat,
        selectedSubtypes: state.Feature.feature.loading.selectedSubtypes,
        loading: state.Feature.feature.loading,
        featureList: state.Feature.feature.featureList,
        types: state.Feature.feature.types,
        offset: state.Feature.feature.offset,
        lang: state.Setting.lang,
        isMobile: state.Setting.is_mobile,
        bodyClientWidth: state.Setting.htmlBody.bodyClientWidth,

        switchFeatureContainer: state.FormBank.FeatureFormat,
        timelineWizardStatus: state.FormBank.TimelineWizard.status,
        activeFeatureId: state.Feature.feature.activeId,
    }
}

const mapDispatchToProps = {
    addSelectedSubTypeFeature: Actions.addSelectedSubTypeFeature,
    deleteSelectedSubTypeFeature: Actions.deleteSelectedSubTypeFeature,
    loadingFeatureListLessFeature: Actions.loadingFeatureListLessFeature,
    loadingFeatureListMoreFeature: Actions.loadingFeatureListMoreFeature,
    loadMoreAllFeatureList: Actions.loadMoreAllFeatureList,
    setSwitchContainer: Actions.setSwitchContainer,
}

const Establishments = connect(mapStateToProps, mapDispatchToProps)(Establishments_)
export default Establishments

function checkImage(check){
    if(check){
        return "0.5"
    }else {}
}


const TripsComponent = ({ feature, trips, featureListDisplayFormat, gridContainer, widthContainer,
    postionContainer, switchFeatureContainer, heightContainer, timelineWizardStatus, setSwitchContainer
}) => {

    const history = useHistory();

    

    const handleClick = (trip) => {
        setSwitchContainer(member_area_router.publicTrip)
        const url = "/trip/" + trip.tripName.replace(URL_REPLACE, "-") + "/" + trip.tripID;
        history.push(url.toLowerCase())
    }

    return (

        <div>
            {trips !== undefined && trips.length > 0
                && trips.map((trip, trip_idx) => {
                    return (
                        <React.Fragment key={trip_idx}>
                            <div

                                className={featureListDisplayFormat === list_container_mode.grid ? "d-inline-block" : ""}>
                                {featureListDisplayFormat === list_container_mode.list &&
                                    <div className="tripsCard">
                                        <div className={timelineWizardStatus ? "margin_top_10" : "margin_top_20"}>
                                            <div className={postionContainer}>
                                                <div  onClick={() => handleClick(trip)} className="d-inline-block" style={{ width: widthContainer, cursor: "pointer" ,backgroundColor: "#5acdcf"}}>
                                                    <Image
                                                    src={image_boxing(trip.tripPicture, widthContainer, heightContainer)}
                                                    alt="trip"  className="tripsImage" style={{opacity: checkImage(trip?.tripPicture?.imageName?.includes("grayBackgroundCamera"))}}/></div>

                                                <div className="tripsBody"  style={{ width: widthContainer + "px", minHeight: heightContainer + "px", border: "1px solid #E8E8E8", verticalAlign: "middle", flexGrow: "1", paddingLeft: "10px", position: "relative" }}>

                                                    <div style={{ position: "relative", height: "25px" }} className={timelineWizardStatus ? "margin_top_10 box_1 padding_left_10 padding_right_20 text-right" : "margin_top_20 box_1 padding_left_10 padding_right_20 text-right"} >

                                                        <div style={{ position: "absolute", top: "0px", right: "140px" }}>
                                                            <Done
                                                                unitID={trip.tripID}
                                                                feature={switchFeatureContainer}
                                                                fill={switchFeatureContainer.color} />
                                                        </div>

                                                        <div style={{ position: "absolute", top: "0px", right: "100px" }}>
                                                            <Heart
                                                                unitID={trip.tripID}
                                                                feature={switchFeatureContainer}
                                                                fill={switchFeatureContainer.color} />
                                                        </div>
                                                        <div style={{ position: "absolute", top: "0px", right: "60px" }}>
                                                            <Plus
                                                                unitID={trip.tripID}
                                                                feature={switchFeatureContainer}
                                                                fill={switchFeatureContainer.color} />
                                                        </div>
                                                        <div style={{ position: "absolute", top: "0px", right: "20px" }}>
                                                            <Star
                                                                unitID={trip.tripID}
                                                                feature={switchFeatureContainer}
                                                                fill={switchFeatureContainer.color} />
                                                        </div>
                                                    </div>

                                                    <div className="box_1 padding_left_10 padding_right_10" style={{
                                                        font: "14px/16px Futura Md BT",
                                                        color: switchFeatureContainer.color,
                                                    }}>
                                                        {trip.destinationName}
                                                    </div>
                                                    <TripName

                                                        tripName={trip.tripName}
                                                        tripId={trip.tripID}
                                                        titleClass={"box_1"} titleStyle={{
                                                            font: timelineWizardStatus ? "22px/33px Futura Hv BT" : "28px/43px Futura Hv BT",
                                                            color: "var(--frameBlack)",
                                                            cursor: "pointer",
                                                            paddingLeft: "10px",
                                                            paddingRight: "10px",
                                                        }} />

                                                    {trip.starsCount !== "0.0" && trip.starsCount !== "0" && <div className="d-flex timeline_rating_color padding_left_10 padding_right_10 btn_rounded">
                                                        <Rating name="timeline_rating" precision={0.5} max={5} value={parseFloat(trip.starsCount)} disabled={true}
                                                            style={{ color: switchFeatureContainer.color, opacity: "1" }} />
                                                        <div className="futura_md_bt_11_14_black margin_bottom_5 margin_left_5">({trip.starsCount})</div>
                                                    </div>}
                                                    {trip.tripDescription !== undefined && <div className={"padding_left_10 padding_right_10"}><TripDescription description={trip.tripDescription} boxDescription={"box_3"} fontDescription={"14px/19px Futura Lt BT"} colorDescription={"var(--mainGrey)"} height={"57px"} /></div>}
                                                    {/* <div className={timelineWizardStatus ? "padding_left_10 padding_right_10 text-left box_1 margin_top_5 margin_bottom_5" : "padding_left_10 padding_right_10 text-left box_1 margin_top_20 margin_bottom_10"}>
                                                      {establishment.subTypes !== undefined && establishment.subTypes.length > 0 ?
                                                          establishment.subTypes.map((subtype, subtype_idx) => {
                                                              return <div key={subtype_idx}
                                                                  className="btn_rounded m-1 futura_md_bt_14_16_green upcoming_trip_style"
                                                                  style={{ cursor: "pointer" }}
                                                                  onClick={() => {
                                                                      selectedSubtypes.find(val => val.id === subtype.subTypeID) ?
                                                                          deleteSelectedSubTypeFeature({ id: subtype.subTypeID, title: subtype.subTypeName }, selectedSubtypes)
                                                                          : addSelectedSubTypeFeature({ id: subtype.subTypeID, title: subtype.subTypeName }, selectedSubtypes)
                                                                  }}>
                                                                  {subtype.subTypeName}</div>
                                                          })
                                                          : ""}
                                                  </div> */}

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {featureListDisplayFormat === list_container_mode.grid &&
                                    <div style={{
                                        paddingLeft: "0px",
                                        paddingRight: "15px",
                                        paddingBottom: "50px",
                                        paddingTop: "15px",
                                        position: "relative",
                                    }}>
                                        <div onClick={() => handleClick(trip)} className="d-inline-block" style={{ cursor: "pointer", backgroundColor: "#5acdcf"}}>
                                            <Image
                                            src={image_boxing(trip.tripPicture, gridContainer, Math.floor(gridContainer * 0.58))}
                                            alt="trip"  style={{opacity: checkImage(trip?.tripPicture?.imageName?.includes("grayBackgroundCamera"))}} /></div>

                                        <div className="rating_color pt-2" style={{
                                            width: gridContainer + "px",
                                            height: '130px',
                                            overflow: "hidden",
                                        }}>
                                            <div className="d-flex flex-wrap ">
                                                <div className="box_1 padding_right_10" style={{
                                                    font: "14px/14px Futura Md BT",
                                                    color: switchFeatureContainer.color,
                                                    flexGrow: "1",
                                                    height: "23px",
                                                }}>
                                                    {trip.destinationName}
                                                </div>
                                                {trip.starsCount !== "0.0" && trip.starsCount !== "0"
                                                    && <div className="d-flex timeline_rating_color p-0" style={{ height: "23px" }}>
                                                        <div
                                                            style={{
                                                                paddingRight: "5px",
                                                                font: "11px/17px Futura Hv BT",
                                                                color: switchFeatureContainer.color,
                                                                flexGrow: "1",
                                                            }}>{trip.starsCount}</div>
                                                        <div style={{
                                                            paddingRight: "2px",
                                                        }}>
                                                            <Rating
                                                                style={{ color: switchFeatureContainer.color, opacity: "1" }}
                                                                name="timeline_rating" precision={0.5} max={1} value={parseFloat((trip.starsCount / 5).toFixed(1))} disabled={true} size={"small"} />
                                                        </div>
                                                    </div>}
                                                {/* {establishment.subTypes !== undefined && establishment.subTypes.length > 0
                                              && establishment.subTypes[0] !== undefined
                                              && establishment.subTypes[0].subTypeName !== undefined &&
                                              <div className="btn_rounded mt-1 futura_md_bt_14_16_green upcoming_trip_style" style={{ height: "26px", cursor: "pointer" }} onClick={() => {
                                                  selectedSubtypes.find(val => val.id === establishment.subTypes[0].subTypeID) ?
                                                      deleteSelectedSubTypeFeature({ id: establishment.subTypes[0].subTypeID, title: establishment.subTypes[0].subTypeName }, selectedSubtypes)
                                                      : addSelectedSubTypeFeature({ id: establishment.subTypes[0].subTypeID, title: establishment.subTypes[0].subTypeName }, selectedSubtypes)
                                              }}>{establishment.subTypes[0].subTypeName}</div>
                                          } */}
                                            </div>
                                            <TripName

                                                tripName={trip.tripName}
                                                tripId={trip.tripID}
                                                divClass={"flex_1"}
                                                titleClass={"bucketListName"} titleStyle={{
                                                    font: "22px/26px Futura Hv BT",
                                                    color: "var(--frameBlack)",
                                                    cursor: "pointer",
                                                }} />
                                            <div style={{ height: "65px", position: "relative" }}>
                                                {trip.tripDescription !== undefined && <div style={{ position: "absolute" }} className={"padding_right_10"}><TripDescription description={trip.tripDescription} boxDescription={"box_3"} fontDescription={"14px/19px Futura Lt BT"} colorDescription={"var(--mainGrey)"} height={"57px"} /></div>}
                                            </div>
                                        </div>
                                        <ActionsEllipsis id={trip.tripID} switchFeatureContainer={switchFeatureContainer} />

                                    </div>

                                }
                                <React.Fragment>
                                    {
                                        // timelineWizardStatus && <Image
                                        //     id={"drag_establishment_" + establishment.establishmentID}
                                        //     style={{ position: "absolute", right: "200%" }}
                                        //     src={image_boxing(establishment.estabPicture, 90, 70)}
                                        //     alt="establishment" />
                                    }
                                </React.Fragment>
                            </div>
                        </React.Fragment>
                    )
                })
            }

         


        </div>



    )
}