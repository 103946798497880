import "./PhotoGallery";

import {
    Col,
    Image,
    Row
} from 'react-bootstrap';
import React, { Component } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PropTypes } from "prop-types"
import { connect } from 'react-redux'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';

/* photo gallery component */
class PhotoGallery_ extends Component {
    render() {
        let { screenSize , trip } = this.props;
        if (!trip.tripPhotoGallery && trip.tripPhotoGallery === undefined){
            return(<div className="futura_md_bt_18_21_lightGray pl-2">No photos found.</div>);
        }   
        let containerH = 250;
        if (screenSize !== undefined) {
            containerH = screenSize / 8;
            containerH = containerH < 150 ? 150 : (containerH > 250 ? 250 : containerH);
        }
        return (
            <div>
                <Row>
                    { trip.tripPhotoGallery && trip.tripPhotoGallery !==undefined?
                    trip.tripPhotoGallery.map((photo, index)=>{                       
                        return(
                            index<11?
                            <Col sm={6} md={3} lg={2} xl={2} key ={ index } className="text-center margin_top_5 margin_bottom_5">
                                <Image src={"https://amhqajbken.cloudimg.io/v7/_images_/"+photo.photoMuvFolder+"/"+photo.photoGoogleFolder+"/"+photo.photoImageName+"?func=crop&w=250&h=250"}  style={{ height: containerH }} />
                            </Col>
                            :""
                        )   
                        
                    }):""}
                    { trip.tripPhotoGallery && trip.tripPhotoGallery !==undefined?
                    <Col sm={6} md={3} lg={2} xl={2} key ={ 12 } className="text-center margin_top_5 margin_bottom_5 position-relative">
                         <div className="vertical_center w-100 text-center futura_md_bt_24_29_green">See more<FontAwesomeIcon icon={faAngleDown} size="1x" className="text_muv ml-2"/></div>
                    </Col>
                    :""}   
                </Row>
            </div>
        )
    }
}


PhotoGallery_.propTypes = {
    trip: PropTypes.object.isRequired,
    tripId: PropTypes.string.isRequired,
    lang: PropTypes.string.isRequired,
}

const mapStateToProps = state => {
    return {
        trip: state.PublicTrip.trip,
        tripId: state.PublicTrip.tripId,
        lang: state.Setting.lang,
        screenSize: state.Setting.htmlBody.bodyClientWidth,
    }
};
const mapDispatchToProps = {
    
}

export const PhotoGallery = connect(mapStateToProps, mapDispatchToProps)(PhotoGallery_)
