import React from 'react'
import { connect } from 'react-redux'
import * as Actions from '../../../redux/actions'
import PageNotFound from '../ErrorPage/PageNotFound'
import { Redirect } from "react-router-dom"
import { list_container_mode, member_area_router, validate, validate_fields } from '../Common/RegExValidate'
import Header from '../Header/Header'
import TimelineWizardContainer from '../Common/TimelineWizardContainer'
import TimelinePageContainer from './TimelinePageContainer'
import { Fab } from '@material-ui/core'
import { ListIcon, MapDuotoneIcon, TimelineIcon } from '../Common/SVGBank'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRoute } from '@fortawesome/free-solid-svg-icons'
/* flights page component */

const TimelinePage_ = ({activeTrip, authModal, tokenLogin, setScreenSize, setBodyClientSize, setAppMenu, preload, lang, tripMode, featureListDisplayFormat,
    setSwitchContainer, setTripDisplayMode, timelineStatus, fetchActiveTripFromCookies, setAppMenuBookingButtonStatus, setPageYOffset

}) => {

    const updateDimensions = () => {
        setScreenSize(window.screen.width, window.screen.height)
        setBodyClientSize(document.body.clientWidth, document.documentElement.scrollHeight)
        setAppMenu(document.body.clientWidth)
    }

    const isLoggedIn = () => {
        return authModal?.uuid && authModal?.uuid.length > 0 ? true : false
    }




    React.useEffect(() => {
        window.addEventListener("resize", updateDimensions)
        updateDimensions()
        setPageYOffset(0)
        setSwitchContainer(member_area_router.flightSearch)
        setAppMenuBookingButtonStatus(true)
        setTripDisplayMode(list_container_mode.timeline)
        fetchActiveTripFromCookies("", lang, false)
        if (!isLoggedIn()) {
            tokenLogin()
        }
        return () => {
            window.removeEventListener("resize", updateDimensions)
        }
    }, [activeTrip])


    return (
        validate(lang, validate_fields.languages) ?
            <div style={{
                height: "auto",
            }}>
                <React.Fragment>

                    <TimelinePageContainer />

                    <div style={{
                        position: "fixed", top: "75%",     left: "53%",
                        transform: "translateX(-50%)", zIndex: 3,
                        backgroundColor: "#274674",
                        color:"var(--mainWhite)",
                        font: "18px/18px Futura Lt BT",
                        textAlign: "center",
                        padding:10,
                        borderRadius:30
                    }}>

                    <span> 
                    <FontAwesomeIcon icon={faRoute} size="1x"
                    style={{color:"#FFFFFF"}} /> {` `} Trip </span> 
                    <span style={{  color:"var(--mainGrey)",}}>|</span>
                        <span> <MapDuotoneIcon width={20} height={20} fill={"#FFFFFF"} /> {` `} Map </span>
                    </div>

                </React.Fragment>

            </div>
            :
            <Redirect to={"error"} component={PageNotFound} />
    )
}


const mapStateToProps = (state) => {
    return {
        uuid: state.Member.authModal.uuid,
        lang: state.Setting.lang,
        preload: state.Setting.preload,
        tripMode: state.Member.tripMode,
        timelineStatus: state.FormBank.TimelineWizard.status,
        authModal: state.Member.authModal,
        activeTrip: state.FormBank.TimelineWizard.activeTrip,
    }

}

const mapDispatchToProps = {
    setSwitchContainer: Actions.setSwitchContainer,
    setScreenSize: Actions.setScreenSize,
    setBodyClientSize: Actions.setBodyClientSize,
    setAppMenu: Actions.setAppMenu,
    setPageYOffset: Actions.setPageYOffset,
    setTripDisplayMode: Actions.setTripDisplayMode,
    fetchActiveTripFromCookies: Actions.fetchActiveTripFromCookies,
    setAppMenuBookingButtonStatus: Actions.setAppMenuBookingButtonStatus,
    tokenLogin: Actions.tokenLogin,
}
const TimelinePage = connect(mapStateToProps, mapDispatchToProps)(TimelinePage_)

export default TimelinePage