import "./MuverPage.css"

import * as Actions from '../../../redux/actions'

import React, { Component } from 'react'
import { member_area_router, profile_router, validate, validate_fields } from "../Common/RegExValidate"
import Banner from '../Common/Banner.jsx'
import Header from '../Header/Header.jsx'
import Loader from 'react-loader-spinner'
import MemberActionsInitial from "../Common/MemberActions/MemberActionsInitial"
import MuverContainer from "./MuverContainer"
import PageNotFound from '../ErrorPage/PageNotFound.jsx'
import Profile from '../Common/Profile/ProfileBar.jsx'
import { PropTypes } from "prop-types"
import { Redirect } from "react-router-dom"
import { connect } from 'react-redux'
import { list_container_mode } from '../Common/RegExValidate'
import { useParams } from 'react-router-dom';
import TimelineWizardContainer from "../Common/TimelineWizardContainer"
import ItineraryMap from "../Common/Trip/ItineraryMap"
import PrivateTripEstablishment from "../Common/Trip/PrivateTripEstablishment"
import TripComtainer from "../Common/Trip/TripContainer"
import { HeaderMobile } from "../Header/HeaderMobile"

const MuverPage_ = ({ reload,is_mobile, setSwitchFeatureTypeContainerOfFeature,fetchTypesAndSubtypesFeature,setScreenSize, setTripToTimeline, setTripDisplayMode, getSelectedTripInformation, setBodyClientSize, setAppMenu, switch_container, setSwitchProfileContainer, setSwitchContainer, setPrivateArea, tripMode, lang, uuid }) => {

	const params = useParams()


	/* update screen size */
	const updateDimensions = () => {
		setScreenSize(window.screen.width, window.screen.height)
		setBodyClientSize(document.body.clientWidth, document.documentElement.scrollHeight)
		setAppMenu(document.body.clientWidth)
	}
	const routeContainer = (params) => {
		setTripDisplayMode(list_container_mode.schedule)  //load initial
		switch (params.container) {
			case list_container_mode.calendar.toLowerCase():
				return list_container_mode.calendar
			case list_container_mode.grid.toLowerCase():
				return list_container_mode.grid
			case list_container_mode.list.toLowerCase():
				return list_container_mode.list
			case list_container_mode.timeline.toLowerCase():
				return list_container_mode.timeline
			case list_container_mode.map.toLowerCase():
				return list_container_mode.map
			default:
				return list_container_mode.schedule;
		}
	}

	const setDefaultTypeTag = (types) => {  //see type default
		if (types) {
			var defaultType = types.find(obj => {
				return obj.typeID === "1"
			})
			if(defaultType){
				const type = {
					typeID: defaultType.typeID
				}
				setSwitchFeatureTypeContainerOfFeature('1', lang, types, type)
			}    
		}
	}

	const routeArea = (params) => {
		switch (params.area) {
			case member_area_router.bucketList:
				return member_area_router.bucketList
			case member_area_router.favorites:
				return member_area_router.favorites
			case "trip":
				return member_area_router.memberAreaTrip
			case member_area_router.muvTribe:
				return member_area_router.muvTribe
			case member_area_router.personalInfo:
				return member_area_router.personalInfo
			default:
				return member_area_router.trips;
		}
	}

	React.useEffect(() => {
		if (params && params.area) {
			const route = routeArea(params);
			setSwitchContainer(route)
			updateDimensions()
			fetchTypesAndSubtypesFeature("1", lang,setDefaultTypeTag, true)
		
			if (route === member_area_router.memberAreaTrip && params.tripID) {
				getSelectedTripInformation(uuid, params.tripID)
				setTripToTimeline(params.tripID, uuid)
				if (params.container) {
					const container = routeContainer(params);
					setTripDisplayMode(container)
				}
				else{
					setTripDisplayMode(list_container_mode.schedule)  //load default
				}
			}
			else {
				setSwitchProfileContainer(profile_router.interests)
				setPrivateArea(true)
			}

		}
	}, [getSelectedTripInformation, params, setAppMenu, setBodyClientSize, setPrivateArea, setScreenSize, setSwitchContainer, setSwitchProfileContainer, setTripDisplayMode, setTripToTimeline, uuid])
	
	
	return (
		validate(lang, validate_fields.languages) ?
			<div style={{
				height: "auto",
			}}>
				{!uuid && <Loader
					type="Rings"
					color="#19BC9B"
					className="se-pre-con"
					height={150}
					width={150}
					style={{
						backgroundColor: "#FFFFFF"
					}} />}

				{switch_container === member_area_router.memberAreaTrip &&
					<React.Fragment>
						{tripMode === list_container_mode.map ?
							<React.Fragment>
								<Header bg_color={"rgba(25,25,25, 1)"} text_color={"var(--mainWhite)"} header_height={"half"} />
								<TimelineWizardContainer listDisplayFormat={list_container_mode.map} />
								{window.google !== undefined && <ItineraryMap />}
							</React.Fragment> :
							(tripMode === list_container_mode.timeline ?
								<React.Fragment>
									<Header bg_color={"rgba(25,25,25, 1)"} text_color={"var(--mainWhite)"} />
									<TimelineWizardContainer listDisplayFormat={list_container_mode.timeline} />
									<PrivateTripEstablishment />
								</React.Fragment> :
								<TripComtainer />
								)}
					</React.Fragment>}

				{(switch_container === member_area_router.trips
					|| switch_container === member_area_router.bucketList
					|| switch_container === member_area_router.favorites
					|| switch_container === member_area_router.personalInfo
					|| switch_container === member_area_router.muvTribe)
					&&
					<React.Fragment>
						{ is_mobile ? <HeaderMobile bg_color={"#191919"} text_color={"var(--mainWhite)"} /> : <Header bg_color={"rgba(25,25,25, 1)"} text_color={"var(--mainWhite)"} />}
						<Banner />
						<Profile />
						<MuverContainer />
					</React.Fragment>}


				<MemberActionsInitial />
			</div >
			:
			<Redirect to={"error"} component={PageNotFound} />
	)
}

MuverPage_.propTypes = {
	bodyClientWidth: PropTypes.number.isRequired,
	error: PropTypes.string.isRequired,
	uuid: PropTypes.string.isRequired,
}

const mapStateToProps = (state) => {
	return {
		error: state.Social.error,
		uuid: state.Member.authModal.uuid,
		lang: state.Setting.lang,
		preload: state.Setting.preload,
		suggestedDestinations: state.Home.homepage.suggestedDestinations,
		switch_container: state.Member.switch_container,
		bodyClientWidth: state.Setting.htmlBody.bodyClientWidth,
		tripMode: state.Member.tripMode,
		activeTrip: state.FormBank.TimelineWizard.activeTrip,
		is_mobile: state.Setting.is_mobile,
	}

}

const mapDispatchToProps = {
	setSwitchContainer: Actions.setSwitchContainer,
	setScreenSize: Actions.setScreenSize,
	setBodyClientSize: Actions.setBodyClientSize,
	setAppMenu: Actions.setAppMenu,
	setScreenVerticalIsTop: Actions.setScreenVerticalIsTop,
	clearSelectedTripInformation: Actions.clearSelectedTripInformation,
	setPrivateArea: Actions.setPrivateArea,
	setSwitchProfileContainer: Actions.setSwitchProfileContainer,
	setTripDisplayMode: Actions.setTripDisplayMode,
	getSelectedTripInformation: Actions.getSelectedTripInformation,
	setTripToTimeline: Actions.setTripToTimeline,
	setSwitchFeatureTypeContainerOfFeature: Actions.setSwitchFeatureTypeContainerOfFeature,
	fetchTypesAndSubtypesFeature: Actions.fetchTypesAndSubtypesFeature,
}
const MuverPage = connect(mapStateToProps, mapDispatchToProps)(MuverPage_)

export default MuverPage