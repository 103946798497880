import * as Actions from '../../../redux/actions'

import Establishments from "./Establishments"
import React from 'react'
import Trips from "./Trips"
import { connect } from 'react-redux'
import { features_router } from '../Common/RegExValidate'
import BucketList from './BucketList'
import AboutDestination from './AboutDestination'

const FeatureListContainer_ = ({ isAboutPage, activeFeatureId, loading, lang, loadingFeatureListFeature }) => {
    React.useEffect(() => {
        loading.selectedSortingOption !== null && loadingFeatureListFeature(activeFeatureId, loading, lang)
    }, [
        loading.selectedTypes,
        loading.selectedDestinations,
        loading.selectedSubtypes,
        loading.searchQuery,
        loading.selectedSortingOption,
    ])

  
    return (
        <div>
            {isAboutPage ? <AboutDestination /> : <div>
                {activeFeatureId === features_router.places.featureId && <Establishments />}
                {activeFeatureId === features_router.trips.featureId && <Trips />}
                {activeFeatureId === features_router.bucket_list.featureId && <BucketList />}
            </div>}
        </div>
    )
}

const mapStateToProps = (state) => ({
    lang: state.Setting.lang,

    activeFeatureId: state.Feature.feature.activeId,
    loading: state.Feature.feature.loading,

})

const mapDispatchToProps = {
    loadingFeatureListFeature: Actions.loadingFeatureListFeature
}

const FeatureListContainer = connect(mapStateToProps, mapDispatchToProps)(FeatureListContainer_)
export default React.memo(FeatureListContainer)
