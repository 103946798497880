import {
    CLEAN_SELECTED_SUBTYPES_DESTINATION,
    DESTINATION_SWITCH_FEATURE_TYPE_CONTAINER,
    FETCH_API_ERROR_DESTINATION,
    FETCH_API_ERROR_ESTABLISHMENT,
    FETCH_BREADCRUMB_DESTINATION,
    FETCH_BREADCRUMB_ESTABLISHMENT,
    FETCH_ESTABLISMENT_SETS_DESTINATION,
    FETCH_ESTABLISMENT_SETS_LAZYLOADING_DESTINATION,
    FETCH_EXPERIENCES_ESTABLISHMENT,
    FETCH_NEIGHBORHOOD_ESTABLISHMENT,
    FETCH_FEATURES_DESTINATION,
    FETCH_FEATURE_SUBTYPES_DESTINATION,
    FETCH_FEATURE_TYPES_DESTINATION,
    FETCH_GENERAL_INFO_DESTINATION,
    FETCH_GENERAL_INFO_ESTABLISHMENT,
    FETCH_GOOGLE_AND_YELP_REVIEWS_ESTABLISHMENT,
    FETCH_BREADCRUMB_BUCKETLIST ,
    FETCH_API_ERROR_BUCKETLIST ,
    FETCH_GENERAL_INFO_BUCKETLIST,
   FETCH_REVIEWS_BUCKETLIST ,
   FETCH_REVIEWS_AVERAGE_RATING_BUCKETLIST ,
   SET_BUCKETLIST_ID_BUCKETLIST,
   FETCH_SLIDER_IMAGES_BUCKETLIST,
   FETCH_DESTINATION_SEARCH_LIST_FEATURE,
   FETCH_GOOGLE_AND_YELP_REVIEWS_BUCKETLIST ,
   FETCH_SUBTYPES_BUCKETLIST,
   SET_USER_REVIEWS_RATINGS_BUCKETLIST,
   SET_USER_REVIEWS_RATINGS_ESTABLISHMENT,
    FETCH_INFLUENCERS_DESTINATION,
    FETCH_NESTED_IDS_DESTINATION,
    FETCH_REVIEWS_AVERAGE_RATING_ESTABLISHMENT,
    FETCH_REVIEWS_ESTABLISHMENT,
    FETCH_SLIDER_IMAGES_DESTINATION,
    FETCH_SLIDER_IMAGES_ESTABLISHMENT,
    FETCH_SORTING_OPTION_DESTINATION,
    FETCH_SUBTYPES_ESTABLISHMENT,
    FETCH_SUGGESTEDTRIPS_DESTINATION,
    FETCH_TRIP_SETS_DESTINATION,
    FETCH_TRIP_SETS_LAZYLOADING_DESTINATION,
    SET_DESTINATION_ID_DESTINATION,
    SET_ESTABLISHMENT_ID_ESTABLISHMENT,
    SET_LIST_DISPLAY_FORMAT_DESTINATION,
    UPDATE_LAZYLOADING_DESTINATION,
    UPDATE_LAZYLOADING_IS_FATCH_ALL_DESTINATION,
    UPDATE_LAZYLOADING_LIMIT_DESTINATION,
    UPDATE_LAZYLOADING_OFFSET_DESTINATION,
    UPDATE_LAZYLOADING_PROGRESS_DESTINATION,
    UPDATE_LAZYLOADING_URL_REQUESTS_DESTINATION,
    UPDATE_SEARCH_QUERY_DESTINATION,
    UPDATE_SELECTED_SUBTYPES_DESTINATION,
    FETCH_BUCKET_LIST_DESTINATION,
    FETCH_BUCKETLIST_SETS_LAZYLOADING_DESTINATION,
} from "../interfaces/types"

const initialState = {
    destination: {
        activeId: 0,
        lazyLoading: {
            isFetching: false,
            progress: 0,
            noProgress: true,
            url: "",
            requests: {
                featureID: 0,
                typeID: 0,
                limit: 0,
                offset: 0,
                subtype: [],
                sortingOrder: null,
                sortingDirection: "",
                searchquery: "",
            }
        },
        switchFeatureTypeContainer: {
            typeID: "",
            typeName: "",
            icon: "",
            // disable: "",
        },
        generalInfo: null,
        sliderImages: [],
        breadcrumb: [],
        NestedIds: [],
        features: [],
        types: [],
        subtypes: [],
        selectedSubtypes: [],
        destinationSearchList: [],
        resultSetsTrips: [],
        resultSetsBucketList: [],
        resultSetsEstablishments: [],
        influencers: [],
        sortingOptions: [],
        suggestedTrips: [],
        destinaionListDisplayFormat: "List",
        error: false,
    },
    establishment: {
        activeId: 0,
        generalInfo: null,
        breadcrumb: [],
        subtypes: [],
        sliderImages: [],
        reviews: null,
        reviewsGoogleAndYelp: null,
        reviewsAverage: null,
        experiences: [],
        menu: [],
        selectedMenu: "",
        error: false,
    },
}

/* display activity reducer */
export default (state = initialState, action) => {
    switch (action.type) {

        case DESTINATION_SWITCH_FEATURE_TYPE_CONTAINER:
            return {
                ...state,
                destination: {
                    ...state.destination,
                    switchFeatureTypeContainer: {
                        ...state.destination.switchFeatureTypeContainer,
                        typeID: action.typeSelected.typeID,
                        typeName: action.typeSelected.typeName,
                        icon: action.typeSelected.icon,
                        // disable: action.featureSelected.disable,
                    }
                }
            };
        case FETCH_GENERAL_INFO_DESTINATION:
            return {
                ...state,
                destination: {
                    ...state.destination,
                    generalInfo: action.general_info,
                }
            };
        case FETCH_SLIDER_IMAGES_DESTINATION:
            return {
                ...state,
                destination: {
                    ...state.destination,
                    sliderImages: action.slider_images,
                }
            }

            ;
        case FETCH_BREADCRUMB_DESTINATION:
            return {
                ...state,
                destination: {
                    ...state.destination,
                    breadcrumb: action.breadcrumb,
                }
            };
        case FETCH_NESTED_IDS_DESTINATION:
            return {
                ...state,
                destination: {
                    ...state.destination,
                    NestedIds: action.nested_ids,
                }
            };
        case FETCH_FEATURES_DESTINATION:
            return {
                ...state,
                destination: {
                    ...state.destination,
                    features: action.features,
                }
            };
        case FETCH_FEATURE_TYPES_DESTINATION:
            return {
                ...state,
                destination: {
                    ...state.destination,
                    types: action.types,
                }
            };
        case FETCH_FEATURE_SUBTYPES_DESTINATION:
            return {
                ...state,
                destination: {
                    ...state.destination,
                    subtypes: action.subtypes,
                }
            };
        case FETCH_TRIP_SETS_DESTINATION:
            return {
                ...state,
                destination: {
                    ...state.destination,
                    resultSetsTrips: action.result_sets_trips,
                }
            };
        case FETCH_ESTABLISMENT_SETS_DESTINATION:
            return {
                ...state,
                destination: {
                    ...state.destination,
                    resultSetsEstablishments: action.result_sets_establishments,
                }
            };
            case FETCH_BUCKET_LIST_DESTINATION:
                return {
                    ...state,
                    destination: {
                        ...state.destination,
                        resultSetsBucketList: action.result_sets_bucketlist,
                    }
                };   
        case FETCH_API_ERROR_DESTINATION:
            return {
                ...state,
                destination: {
                    ...state.destination,
                    error: action.api_error,
                }
            };
        case UPDATE_SELECTED_SUBTYPES_DESTINATION:
            return {
                ...state,
                destination: {
                    ...state.destination,
                    selectedSubtypes: action.subtypes,
                }
            };
        case CLEAN_SELECTED_SUBTYPES_DESTINATION:
            return {
                ...state,
                destination: {
                    ...state.destination,
                    selectedSubtypes: [],
                }
            };
        case UPDATE_LAZYLOADING_URL_REQUESTS_DESTINATION:
            return {
                ...state,
                destination: {
                    ...state.destination,
                    lazyLoading: {
                        ...state.destination.lazyLoading,
                        url: action.api_url,
                        requests: {
                            ...state.destination.lazyLoading.requests,
                            featureID: action.requests.featureID,
                            typeID: action.requests.typeID,
                            limit: action.requests.limit,
                            offset: action.requests.offset,
                            subtype: action.requests.subtype,
                            sortingOrder: action.requests.sortingOrder,
                            sortingDirection: action.requests.sortingDirection,
                            searchquery: action.requests.searchquery,
                        }
                    },
                }
            };
        case UPDATE_LAZYLOADING_OFFSET_DESTINATION:
            return {
                ...state,
                destination: {
                    ...state.destination,
                    lazyLoading: {
                        ...state.destination.lazyLoading,
                        requests: {
                            ...state.destination.lazyLoading.requests,
                            offset: action.offset,
                        }
                    },
                }
            };
        case UPDATE_LAZYLOADING_LIMIT_DESTINATION:
            return {
                ...state,
                destination: {
                    ...state.destination,
                    lazyLoading: {
                        ...state.destination.lazyLoading,
                        requests: {
                            ...state.destination.lazyLoading.requests,
                            limit: action.limit,
                        }
                    },
                }
            };
        case UPDATE_LAZYLOADING_DESTINATION:
            return {
                ...state,
                destination: {
                    ...state.destination,
                    lazyLoading: {
                        ...state.destination.lazyLoading,
                        isFetching: action.is_fetching,
                    },
                }
            };
        case UPDATE_LAZYLOADING_PROGRESS_DESTINATION:
            return {
                ...state,
                destination: {
                    ...state.destination,
                    lazyLoading: {
                        ...state.destination.lazyLoading,
                        progress: action.progress,
                    },
                }
            };
        case UPDATE_LAZYLOADING_IS_FATCH_ALL_DESTINATION:
            return {
                ...state,
                destination: {
                    ...state.destination,
                    lazyLoading: {
                        ...state.destination.lazyLoading,
                        noProgress: action.no_progress,
                    },
                }
            };
        case FETCH_TRIP_SETS_LAZYLOADING_DESTINATION:
            return {
                ...state,
                destination: {
                    ...state.destination,
                    resultSetsTrips: state.destination.resultSetsTrips.concat(action.result_sets_trips),
                }
            };
        case FETCH_ESTABLISMENT_SETS_LAZYLOADING_DESTINATION:
            return {
                ...state,
                destination: {
                    ...state.destination,
                    resultSetsEstablishments: state.destination.resultSetsEstablishments.concat(action.result_sets_establishments),
                }
            };
            case FETCH_BUCKETLIST_SETS_LAZYLOADING_DESTINATION:
                return {
                    ...state,
                    destination: {
                        ...state.destination,
                        resultSetsBucketList: state.destination.resultSetsBucketList.concat(action.result_sets_bucketlist),
                    }
                };
        case FETCH_INFLUENCERS_DESTINATION:
            return {
                ...state,
                destination: {
                    ...state.destination,
                    influencers: action.influencers,
                }
            };
        case FETCH_SORTING_OPTION_DESTINATION:
            return {
                ...state,
                destination: {
                    ...state.destination,
                    sortingOptions: action.sorting_options,
                }
            };
        case FETCH_SUGGESTEDTRIPS_DESTINATION:
            return {
                ...state,
                destination: {
                    ...state.destination,
                    suggestedTrips: action.suggested_trips,
                }
            };
        case UPDATE_SEARCH_QUERY_DESTINATION:
            return {
                ...state,
                destination: {
                    ...state.destination,
                    lazyLoading: {
                        ...state.destination.lazyLoading,
                        requests: {
                            ...state.destination.lazyLoading.requests,
                            searchquery: action.search_query,
                        }
                    },
                }
            };

            case FETCH_DESTINATION_SEARCH_LIST_FEATURE:
                return {
                    ...state,
                    destination: {
                        ...state.destination,
                        destinationSearchList: action.destination_search_list,
                    }
                };
        case SET_LIST_DISPLAY_FORMAT_DESTINATION:
            return {
                ...state,
                destination: {
                    ...state.destination,
                    destinaionListDisplayFormat: action.container_format,
                }
            };
        case SET_DESTINATION_ID_DESTINATION:
            return {
                ...state,
                destination: {
                    ...state.destination,
                    activeId: action.active_id,
                }
            }

            ;

        case FETCH_SLIDER_IMAGES_ESTABLISHMENT:
            return {
                ...state,
                establishment: {
                    ...state.establishment,
                    sliderImages: action.slider_images,
                }
            };
        case FETCH_GOOGLE_AND_YELP_REVIEWS_ESTABLISHMENT:
            return {
                ...state,
                establishment: {
                    ...state.establishment,
                    reviewsGoogleAndYelp: action.reviews,
                }
            };
        case FETCH_REVIEWS_AVERAGE_RATING_ESTABLISHMENT:
            return {
                ...state,
                establishment: {
                    ...state.establishment,
                    reviewsAverage: action.average_rating,
                }
            };
        case FETCH_REVIEWS_ESTABLISHMENT:
            return {
                ...state,
                establishment: {
                    ...state.establishment,
                    reviews: action.reviews,
                }
            };
        case FETCH_EXPERIENCES_ESTABLISHMENT:
            return {
                ...state,
                establishment: {
                    ...state.establishment,
                    experiences: action.experiences,
                }
            };
            
        case FETCH_NEIGHBORHOOD_ESTABLISHMENT:
            return {
                ...state,
                establishment: {
                    ...state.establishment,
                    neighborhood: action.neighborhood,
                }
            };
        case FETCH_API_ERROR_ESTABLISHMENT:
            return {
                ...state,
                establishment: {
                    ...state.establishment,
                    error: action.api_error,
                }
            };
        case SET_ESTABLISHMENT_ID_ESTABLISHMENT:
            return {
                ...state,
                establishment: {
                    ...state.establishment,
                    activeId: action.active_id,
                }
            };
        case FETCH_GENERAL_INFO_ESTABLISHMENT:
            return {
                ...state,
                establishment: {
                    ...state.establishment,
                    generalInfo: action.general_info,
                }
            };
        case FETCH_BREADCRUMB_ESTABLISHMENT:
            return {
                ...state,
                establishment: {
                    ...state.establishment,
                    breadcrumb: action.breadcrumb,
                }
            };
        case FETCH_SUBTYPES_ESTABLISHMENT:
            return {
                ...state,
                establishment: {
                    ...state.establishment,
                    subtypes: action.subtypes,
                }
            };

            case FETCH_REVIEWS_AVERAGE_RATING_BUCKETLIST:
                return {
                    ...state,
                    bucketlist: {
                        ...state.bucketlist,
                        reviewsAverage: action.average_rating,
                    }
                };
            case FETCH_REVIEWS_BUCKETLIST:
                return {
                    ...state,
                    bucketlist: {
                        ...state.bucketlist,
                        reviews: action.reviews,
                    }
                }
            case FETCH_API_ERROR_BUCKETLIST:
                return {
                    ...state,
                    bucketlist: {
                        ...state.bucketlist,
                        error: action.api_error,
                    }
                };
            case SET_BUCKETLIST_ID_BUCKETLIST:
                return {
                    ...state,
                    bucketlist: {
                        ...state.bucketlist,
                        activeId: action.active_id,
                    }
                };
                case FETCH_SLIDER_IMAGES_BUCKETLIST:
                    return {
                        ...state,
                        bucketlist: {
                            ...state.bucketlist,
                            sliderImages: action.slider_images,
                        }
                    };
            case FETCH_GENERAL_INFO_BUCKETLIST:
                return {
                    ...state,
                    bucketlist: {
                        ...state.bucketlist,
                        generalInfo: action.general_info,
                    }
                };
            case FETCH_BREADCRUMB_BUCKETLIST:
                return {
                    ...state,
                    bucketlist: {
                        ...state.bucketlist,
                        breadcrumb: action.breadcrumb,
                    }
                };
            case FETCH_SUBTYPES_BUCKETLIST:
                return {
                    ...state,
                    bucketlist: {
                        ...state.bucketlist,
                        subtypes: action.subtypes,
                    }
                };
        
                
        default:
            return {
                ...state
            }
    }
}