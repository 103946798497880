import * as Actions from '../../../redux/actions';
import "./Videos.css"
import { ArrowLeftIcon, ArrowRightIcon, TimesIcon, DeleteIcon } from "../Common/SVGBank"
import React, { Component } from 'react'

import { Image } from "react-bootstrap"
import { PropTypes } from "prop-types"
import Slider from 'react-slick';
import YouTube from "react-youtube";
import { connect, useDispatch } from 'react-redux'
import { getSocialVideos, deleteSocialVideos } from './social.slice';

const SliderArrowRight = ({ className, to, onClick }) => (
    <button type="button" onClick={onClick} className={`button button--text button--icon ${className}`} aria-label={to}>
        <ArrowRightIcon className="icon" icon={to} style={{
            height: "60px",
            width: "60px",
            transform: 'translateY(-66px)'
        }} />
    </button>
)


const SliderArrowLeft = ({ className, to, onClick }) => (
    <button type="button" onClick={onClick} className={`button button--text button--icon ${className}`} aria-label={to}>
        <ArrowLeftIcon className="icon" icon={to} style={{
            height: "60px",
            width: "60px",
            transform: 'translateY(-66px)'
        }} />
    </button>
)



/* video component */
const Videos_ = ({ videos, youtube_id, video_desc, current_video_index, screenSize, displayVideo,uuid,member_uuid }) => {

    const dispatch = useDispatch();
    const [selected, setSelected] = React.useState(null);

    React.useEffect(() => {
        if (videos) {
            setSelected(videos[0])
        }

    }, [videos])


    const isProfileOwner =uuid && member_uuid && member_uuid ===uuid;
 
    let settings = {
        dots: false,
        infinite: false,
        arrows: videos && videos.length > 5 ? true : false,
        speed: 500,
        lazyLoad: false,
        autoplay: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        pauseOnHover: true,
        centerMode: false,
        centerPadding: "70px",
        prevArrow: <SliderArrowLeft to="prev" />,
        nextArrow: <SliderArrowRight to="next" />,
        responsive: [
            {
                breakpoint: 1724,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    centerPadding: "60px",
                    arrows: videos && videos.length > 4 ? true : false,
                }
            },
            {
                breakpoint: 1500,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    centerPadding: "60px",
                    arrows: videos && videos.length > 3 ? true : false,
                }
            },
            {
                breakpoint: 1100,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    arrows: false,
                    centerPadding: "60px",
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    arrows: false,
                    centerPadding: "60px",

                }
            },
            {
                breakpoint: 500,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    arrows: false,
                    centerPadding: "15px",
                }
            },
        ]
    }

        let containerH = screenSize / 8;
        containerH = (containerH < 96) ? 150 : (containerH > 300 ? 300 : containerH);
        let videoSize = containerH * 2.6;
        let opts_hb = {
            height: videoSize,
            width: '100%',
            playerVars: {
                autoplay: 0,
                controls: 1,
                disablekb: 0,
                fs: 0,
                hl: "en",
                iv_load_policy: 3,
                loop: 0,
                modestbranding: 1,
                rel: 0,
                cc_load_policy: 1,
                enablejsapi: 0
            }
        }

        const class_name = videos && videos.length < 5? "left_element":""

        const deleteVideo = (video) =>{
            const data = {
                uuid:uuid,
                videoId: video.userVideoId
            }
          
            dispatch(deleteSocialVideos(data))
        }
        return (
            <React.Fragment>
                <div className="futura_hv_bt_24_26_white margin_right_50 margin_top_50 padding_125rem_lr_mobile">Videos</div>
                <div className="d-flex padding_125rem_lr_mobile">
                    {/* <div className="futura_md_bt_24_26_white margin_left_50 margin_top_20 margin_bottom_10 ">Euro tour 2015 trip - Prague(need API)</div> */}
                    {/* <div className="futura_hv_bt_16_19_green ml-auto d-inline" ><span onClick={() => {}}>Next</span></div> */}
                </div>
                {selected && <video width="100%" height="540" src={selected.userVideoUri}  style={{marginTop: 40}} controls/>}
             
                {/* <div style={{ background: "#2C2C2C 0% 0% no-repeat padding-box" }} className="p-2 futura_md_bt_18_30_white">
                    {video_desc}
                </div> */}
                <Slider {...settings} className={class_name+" videoSlider"} style={{ height: "300px",marginTop: 20,marginBottom: -60, }}>
                    { videos && videos.map((video, idx) => {
                        return (
                            <div key={video.userVideoId} className="padding_left" style={{width:271}}>
                           
                                <div  >
                                   <div className="videoDeleteThumbs" > <video className="" src={video.userVideoUri} style={{objectFit: 'cover',}}
                                        onClick={() => {setSelected(video)}}
                                        width="100%" height="220" />
                                        {isProfileOwner && <div className="videoDeleteIcon" onClick={() => {deleteVideo(video)}}> <DeleteIcon height={20} width={20}  fill={'rgb(248, 248, 248)'}  />  </div>}
                                        </div>
                                    <div className="box_3">
                                        <div className="futura_md_bt_16_22_white margin_top_10 margin_bottom_10">{video.videoDescription}</div>
                                    
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </Slider>
            </React.Fragment>
        )

}


Videos_.propTypes = {
    videos: PropTypes.array.isRequired,
}

const mapStateToProps = state => {
    return {
        videos: state.SocialPage.videos,
        youtube_id: state.DisplayVideo.youtube_id,
        video_desc: state.DisplayVideo.video_desc,
        current_video_index: state.DisplayVideo.index,
        screenSize: state.Setting.htmlBody.bodyClientWidth,
        muverSocial: state.Member.socialMember.muver,
        muverMemebr: state.Member.memberArea.memberMuver,
        switch_container: state.Member.switch_container,
        uuid: state.Member.authModal.uuid,
        member_uuid: state.Member.socialMember.member_uuid
    }
};
const mapDispatchToProps = {
    displayVideo: Actions.displayVideo,
}

const Videos = connect(mapStateToProps, mapDispatchToProps)(Videos_)
export default Videos
