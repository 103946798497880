import { TextField } from '@material-ui/core'
import React from 'react'
import { flightTextField } from '../flightStyles'
import { LeftArrowIcon, RightArrowIcon } from '../../Common/SVGBank';

export const FlightContactForm = ({ contactInfo, setContactInfo, nextStep, previousStep }) => {

    const contact = { ...contactInfo };

    const updateValue = (field, value) => {
        setValues(field, value)
    };

    const setValues = (field, value) => {
        contact[field] = value;
        setContactInfo(contact)
    }

    const classesAutocomplete = flightTextField()

    const isDisabled = () => {
        const disabled = contact.contact_name && contact.email && contact.phone_number;
        return disabled ? false : true;
    }


    var windowWidth = window.screen.width < window.outerWidth ?
        window.screen.width : window.outerWidth;
    var mobile = windowWidth < 500;


    return (
        <div>

            <div className="row">
                <div className={mobile ? 'col-12' : 'col-6'} style={{
                    marginTop: "20px", paddingRight: 0
                }}>

                    <div className={classesAutocomplete.root} style={{ border: !contact.contact_name ? "1px solid red" : "" }}>
                        <TextField
                            label='Contact Name'
                            id='Contact Name'
                            required={true}
                            defaultValue={contact.contact_name}
                            onChange={(event) => updateValue('contact_name', event.target.value)}
                        />
                    </div>
                </div>
                <div className={mobile ? 'col-12' : 'col-6'} style={{
                    marginTop: "20px",
                }}>

                    <div className={classesAutocomplete.root} style={{ border: !contact.email ? "1px solid red" : "" }}>
                        <TextField
                            label='Email'
                            required={true}
                            type='email'
                            id='Email'
                            defaultValue={contact.email}
                            onChange={(event) => updateValue('email', event.target.value)}
                        />
                    </div>
                </div>
            </div>

            <div className="row">
                <div className={mobile ? 'col-12' : 'col-6'} style={{
                    marginTop: "20px", paddingRight: 0
                }}>

                    <div className={classesAutocomplete.root} style={{ border: !contact.phone_number ? "1px solid red" : "" }}>
                        <TextField
                            label='Mobile Phone'
                            required={true}
                            id='Mobile Phone'
                            type='tel'
                            defaultValue={contact.phone_number}
                            onChange={(event) => updateValue('phone_number', event.target.value)}
                        />
                    </div>
                </div>

            </div>


            <div style={{ padding: '16px 35px', marginTop: 30, marginBottom: 30, borderLeft: '3px solid #19BC9B', background: '#19BC9B33', color: '#19BC9B', font: '14px/14px Futura Lt BT' }}>

                <p style={{ lineHeight: 1.5 }}> We will send your booking confirmation and check-in info to this email address. Please ensure you are able to receive emails when traveling before submitting your booking. </p>
            </div>

            <div>
                <div style={{ marginBottom: 20 }}>
                    <button className='recapButtons' style={{ background: mobile? 'transparent':'#DFDFDF' }} onClick={previousStep}>
                        <LeftArrowIcon fill={'#7F7F7F'} height={14} width={14} style={{ marginRight: 10 }} />  Previous step
                    </button>


                    <div style={{ float: "right" }} className="nextBtn">
                        <button disabled={isDisabled()} className='recapButtons' style={{ opacity: isDisabled() ? 0.4 : 1, background: '#19BC9B', color: 'white', font: '14px/14px Futura Hv BT' }} onClick={nextStep} >
                            Next step  <RightArrowIcon height={14} width={14} style={{ marginLeft: 90 }} />
                        </button>
                    </div>

                </div>

            </div>


        </div>
    )
}