import {
    CURRENT_FAVORITES_TYPE_SELECTED,
    FETCH_HOMETOWN_FAVORITES_TYPE_FAILURE,
    FETCH_HOMETOWN_FAVORITES_TYPE_SUCCESS,
    FETCH_MEMBER_DESTINATION_INTERESTS,
    FETCH_MEMBER_DESTINATION_INTERESTS_ERROR,
    FETCH_MEMBER_FAILURE,
    FETCH_MEMBER_FOLLOWERS,
    FETCH_MEMBER_FOLLOWERS_ERROR,
    FETCH_MEMBER_FRIENDS,
    FETCH_MEMBER_FRIENDS_ERROR,
    FETCH_MEMBER_FUTURE_TRIPS_FAILURE,
    FETCH_MEMBER_FUTURE_TRIPS_SUCCESS,
    FETCH_MEMBER_INFLUENCERS,
    FETCH_MEMBER_INFLUENCERS_ERROR,
    FETCH_MEMBER_LAST_TRIP_FAILURE,
    FETCH_MEMBER_LAST_TRIP_SUCCESS,
    FETCH_MEMBER_SUCCESS,
    FETCH_MUVER_FAILURE,
    FETCH_MUVER_SUCCESS,
    FETCH_PHOTOS_FAILURE,
    FETCH_PHOTOS_SUCCESS,
    FETCH_SOCIAL_MEMBER_DESTINATION_FAVORITES,
    FETCH_SOCIAL_MEMBER_DESTINATION_FAVORITES_ERROR,
    FETCH_SOCIAL_MEMBER_DESTINATION_FAVORITES_IS_FETCH,
    FETCH_SOCIAL_MEMBER_DESTINATION_FAVORITES_MERGE,
    FETCH_SOCIAL_MEMBER_HOMETOWN_FAVORITES,
    FETCH_SOCIAL_MEMBER_HOMETOWN_FAVORITES_ERROR,
    FETCH_SOCIAL_MEMBER_HOMETOWN_FAVORITES_IS_FETCH,
    FETCH_SOCIAL_MEMBER_HOMETOWN_FAVORITES_MERGE,
    FETCH_SOCIAL_MEMBER_PAST_TRIPS,
    FETCH_SOCIAL_MEMBER_PAST_TRIPS_ERROR,
    FETCH_SOCIAL_MEMBER_PAST_TRIPS_IS_FETCH,
    FETCH_SOCIAL_MEMBER_PAST_TRIPS_MERGE,
    FETCH_UUID_FAILURE,
    FETCH_UUID_SUCCESS,
    FETCH_VIDEOS_FAILURE,
    FETCH_VIDEOS_SUCCESS,
    GetAsyncHelper,
    SET_LOADER,
} from "../interfaces/types"

import Axios from 'axios';
import { environment } from "../../Environments/environment";

// import { getPhotos } from "./GetMediaAction.js"

/* get uuid */
export const fetchSocialInfo = (handle, lang) => async dispatch => {

    try {

        /* Get uuid */
        const response = await Axios.get(environment.apiUrl +'memberuuid?handle=' + handle)
        const uuid = response.data.toString()
        if (response.statusText !== "OK") {
            throw Error(response.statusText);
        }
        dispatch({
            type: FETCH_UUID_SUCCESS,
            payload: uuid,
            handle: handle,
            error: ""
        })
        /* get member */
        GetAsyncHelper(environment.apiUrl +'member/' + uuid + '/en').then(response => {
            if (response.statusText !== "OK") {
                dispatch({
                    type: FETCH_MEMBER_FAILURE,
                    lang: "",
                    payload: response.message
                })
            } else {
                dispatch({
                    type: FETCH_MEMBER_SUCCESS,
                    payload: response.data,
                    member_uuid: uuid,
                    lang: lang,
                })
            }

        })
        /* get member favorites */
        GetAsyncHelper(environment.apiUrl +'memberfavorites/' + uuid + '/' + 'en' + '?offset=0&limit=11&feature=1&type=0&homeonly=1').then(responseHomeFavorites => {
            if (responseHomeFavorites.statusText !== "OK") {
                dispatch({
                    type: FETCH_SOCIAL_MEMBER_HOMETOWN_FAVORITES_ERROR,
                    error: true,
                })

            } else {

                if (responseHomeFavorites.data.homeFavorites.length < 11) {
                    dispatch({
                        type: FETCH_SOCIAL_MEMBER_HOMETOWN_FAVORITES,
                        hometown_favorites: responseHomeFavorites.data.homeFavorites,
                        limit: 3,
                        offset: 11,
                    })
                    dispatch({
                        type: FETCH_SOCIAL_MEMBER_HOMETOWN_FAVORITES_IS_FETCH,
                        is_fatch: true,
                    })
                } else {
                    dispatch({
                        type: FETCH_SOCIAL_MEMBER_HOMETOWN_FAVORITES,
                        hometown_favorites: responseHomeFavorites.data.homeFavorites,
                        limit: 3,
                        offset: 11,
                    })
                    dispatch({
                        type: FETCH_SOCIAL_MEMBER_HOMETOWN_FAVORITES_IS_FETCH,
                        is_fatch: false,
                    })
                }
                /* get member favorites */

                GetAsyncHelper(environment.apiUrl +'memberfavorites/' + uuid + '/' + 'en' + '?offset=0&limit=11&feature=1&type=0&homeonly=0').then(responseTravelFavorites => {
                    if (responseTravelFavorites.statusText !== "OK") {
                        dispatch({
                            type: FETCH_SOCIAL_MEMBER_DESTINATION_FAVORITES_ERROR,
                            error: true,
                        })
                    } else {
                        if (responseTravelFavorites.data.homeFavorites.length < 11) {
                            dispatch({
                                type: FETCH_SOCIAL_MEMBER_DESTINATION_FAVORITES,
                                destination_favorites: responseTravelFavorites.data.homeFavorites,
                                limit: 3,
                                offset: 11,
                            })
                            dispatch({
                                type: FETCH_SOCIAL_MEMBER_DESTINATION_FAVORITES_IS_FETCH,
                                is_fatch: true,
                            })
                        } else {
                            dispatch({
                                type: FETCH_SOCIAL_MEMBER_DESTINATION_FAVORITES,
                                destination_favorites: responseTravelFavorites.data.homeFavorites,
                                limit: 3,
                                offset: 11,
                            })
                            dispatch({
                                type: FETCH_SOCIAL_MEMBER_DESTINATION_FAVORITES_IS_FETCH,
                                is_fatch: false,
                            })
                        }
                    }
                })
            }
        })

        /* get memebre futrue trips */
        GetAsyncHelper(environment.apiUrl +'memberfuturetrips/' + uuid + '/en?limit=1').then(response => {
            if (response.statusText !== "OK") {
                dispatch({
                    type: FETCH_MEMBER_FUTURE_TRIPS_FAILURE,
                    payload: response.message
                })
            } else {
                /* show page */
                dispatch({
                    type: SET_LOADER,
                    payload: false
                })
                dispatch({
                    type: FETCH_MEMBER_FUTURE_TRIPS_SUCCESS,
                    payload: response.data.trips,
                })
            }
        })
        /* get memebr past trips */
        GetAsyncHelper(environment.apiUrl +'memberpasttrips/' + uuid + '/en?limit=11&lightOnly=1').then(response => {
            if (response.statusText !== "OK") {
                dispatch({
                    type: FETCH_SOCIAL_MEMBER_PAST_TRIPS_ERROR,
                    error: true,
                })
            } else {
                if (response?.data?.trips?.length < 11) {
                    dispatch({
                        type: FETCH_SOCIAL_MEMBER_PAST_TRIPS,
                        past_trips: response.data.trips,
                        limit: 3,
                        offset: 11,
                    })
                    dispatch({
                        type: FETCH_SOCIAL_MEMBER_PAST_TRIPS_IS_FETCH,
                        is_fatch: true,
                    })
                } else {
                    dispatch({
                        type: FETCH_SOCIAL_MEMBER_PAST_TRIPS,
                        past_trips: response.data.trips,
                        limit: 3,
                        offset: 11,
                    })
                    dispatch({
                        type: FETCH_SOCIAL_MEMBER_PAST_TRIPS_IS_FETCH,
                        is_fatch: false,
                    })
                }

            }
        })

        /* get memebr last trip */
        GetAsyncHelper(environment.apiUrl +'memberpasttrips/' + uuid + '/en?limit=1').then(response => {
            if (response.statusText !== "OK") {
                dispatch({
                    type: FETCH_MEMBER_LAST_TRIP_FAILURE,
                    payload: response.message
                })
            } else {
                dispatch({
                    type: FETCH_MEMBER_LAST_TRIP_SUCCESS,
                    payload: response.data.trips,
                })
            }
        })

        /*  get member trip media */
        GetAsyncHelper(environment.apiUrl +'membertripmedia/' + uuid + '?type=photos').then(response => {
            if (response.statusText !== "OK") {
                dispatch({
                    type: FETCH_PHOTOS_FAILURE,
                    payload: response.message
                })
            } else {
                dispatch({
                    type: FETCH_PHOTOS_SUCCESS,
                    payload: response.data,
                    member_uuid: uuid,
                    trip_id: ""
                })
            }
        })


        /* get trip media */
        GetAsyncHelper(environment.apiUrl +'membertripmedia/' + uuid + '?type=videos').then(response => {
            if (response.statusText !== "OK") {
                dispatch({
                    type: FETCH_VIDEOS_FAILURE,
                    member_uuid: uuid,
                    payload: response.message
                })
            } else {
                dispatch({
                    type: FETCH_VIDEOS_SUCCESS,
                    payload: response.data,
                    member_uuid: uuid,
                })
            }
        })

        /* get member info  */
        GetAsyncHelper(environment.apiUrl +'memberinfo/' + uuid).then(response => {
            if (response.statusText !== "OK") {
                dispatch({
                    type: FETCH_MUVER_FAILURE,
                    payload: response.message
                })
            } else {
                dispatch({
                    type: FETCH_MUVER_SUCCESS,
                    payload: response.data.muver,
                })
            }
        })


        /* get feature types */
        GetAsyncHelper(environment.apiUrl +'memberfavoritetypes/' + uuid + '/en').then(response => {
            if (response.statusText !== "OK") {
                dispatch({
                    type: FETCH_HOMETOWN_FAVORITES_TYPE_FAILURE,
                    payload: response.message
                })
            } else {
                dispatch({
                    type: FETCH_HOMETOWN_FAVORITES_TYPE_SUCCESS,
                    payload: response.data.types,
                })
            }
        })



        /* Inflencers */
        GetAsyncHelper(environment.apiUrl +'getMuverRelatedInfluencers/' + uuid).then(response => {
            if (response.statusText === "OK") {
                dispatch({
                    type: FETCH_MEMBER_INFLUENCERS,
                    influencers: response.data.result
                })
            } else {
                dispatch({
                    type: FETCH_MEMBER_INFLUENCERS_ERROR,
                    error: true,
                })
            }

        })
        /* frindes */
        GetAsyncHelper(environment.apiUrl +'memberFriends/getFriends/' + uuid).then(response => {
            if (response.statusText === "OK") {
                dispatch({
                    type: FETCH_MEMBER_FRIENDS,
                    friends: response.data
                })
            } else {
                dispatch({
                    type: FETCH_MEMBER_FRIENDS_ERROR,
                    error: true,
                })
            }

        })

        /* followers */
        GetAsyncHelper(environment.apiUrl +'getMuverFollow/' + uuid + '?followType=following').then(response => {
            if (response.statusText === "OK") {
                dispatch({
                    type: FETCH_MEMBER_FOLLOWERS,
                    followers: response.data.result
                })
            } else {
                dispatch({
                    type: FETCH_MEMBER_FOLLOWERS_ERROR,
                    error: true,
                })
            }

        })
        /* landing interests of destination */
        GetAsyncHelper(environment.apiUrl +'memberLandingDestinations/' + uuid + '/' + 'en').then(response => {
            if (response.statusText === "OK") {
                dispatch({
                    type: FETCH_MEMBER_DESTINATION_INTERESTS,
                    destination_interests: response.data
                })
            } else {
                dispatch({
                    type: FETCH_MEMBER_DESTINATION_INTERESTS_ERROR,
                    error: true,
                })
            }

        })




        /* get member dream trips */
        // GetAsyncHelper('https://devapi.muvtravel.com/api/public/memberdreamtrips/' + uuid + '/en?limit=10').then(response => {
        //     if (response.statusText !== "OK") {
        //         dispatch({
        //             type: FETCH_MEMBER_DREAM_TRIPS_FAILURE,
        //             payload: response.message
        //         })
        //     } else {
        //         dispatch({
        //             type: FETCH_MEMBER_DREAM_TRIPS_SUCCESS,
        //             payload: response.data.trips,
        //         })
        //     }
        // })
    } catch (error) {
        dispatch({
            type: FETCH_UUID_FAILURE,
            handle: handle,
            payload: error.message
        })
    }

}

export const fatchPastTripsSlider = (uuid, lang, limit, offset) => async dispatch => {
    dispatch({
        type: FETCH_SOCIAL_MEMBER_PAST_TRIPS_IS_FETCH,
        is_fatch: true,
    })
    GetAsyncHelper(environment.apiUrl +'memberpasttrips/' + uuid + '/' + lang + '?limit=' + limit + '&offset=' + offset + '&lightOnly=1').then(response => {
        if (response.statusText !== "OK") {
            dispatch({
                type: FETCH_SOCIAL_MEMBER_PAST_TRIPS_ERROR,
                error: true,
            })
        } else {
            if (response.data.trips.length < limit) {
                dispatch({
                    type: FETCH_SOCIAL_MEMBER_PAST_TRIPS_MERGE,
                    past_trips: response.data.trips,
                    offset: limit + offset,
                })
                dispatch({
                    type: FETCH_SOCIAL_MEMBER_PAST_TRIPS_IS_FETCH,
                    is_fatch: true,
                })

            } else {
                dispatch({
                    type: FETCH_SOCIAL_MEMBER_PAST_TRIPS_MERGE,
                    past_trips: response.data.trips,
                    offset: limit + offset,
                })
                dispatch({
                    type: FETCH_SOCIAL_MEMBER_PAST_TRIPS_IS_FETCH,
                    is_fatch: false,
                })
            }
        }
    })
}

export const fatchHometownFavoritesSlider = (uuid, lang, limit, offset, typeId = 0) => async dispatch => {
    dispatch({
        type: FETCH_SOCIAL_MEMBER_HOMETOWN_FAVORITES_IS_FETCH,
        is_fatch: true,
    })
    GetAsyncHelper(environment.apiUrl +'memberfavorites/' + uuid + '/' + lang + '?limit=' + limit + '&offset=' + offset + '&feature=1&type=' + typeId + '&homeonly=1').then(response => {
        if (response.statusText !== "OK") {
            dispatch({
                type: FETCH_SOCIAL_MEMBER_HOMETOWN_FAVORITES_ERROR,
                error: true,
            })
        } else {
            if (response.data.homeFavorites.length < limit) {
                dispatch({
                    type: FETCH_SOCIAL_MEMBER_HOMETOWN_FAVORITES_MERGE,
                    hometown_favorites: response.data.homeFavorites,
                    offset: limit + offset,
                })
                dispatch({
                    type: FETCH_SOCIAL_MEMBER_HOMETOWN_FAVORITES_IS_FETCH,
                    is_fatch: true,
                })

            } else {
                dispatch({
                    type: FETCH_SOCIAL_MEMBER_HOMETOWN_FAVORITES_MERGE,
                    hometown_favorites: response.data.homeFavorites,
                    offset: limit + offset,
                })
                dispatch({
                    type: FETCH_SOCIAL_MEMBER_HOMETOWN_FAVORITES_IS_FETCH,
                    is_fatch: false,
                })
            }
        }
    })
}

export const fatchTravelFavoritesSlider = (uuid, lang, limit, offset) => async dispatch => {
    dispatch({
        type: FETCH_SOCIAL_MEMBER_DESTINATION_FAVORITES_IS_FETCH,
        is_fatch: true,
    })
    GetAsyncHelper(environment.apiUrl +'memberfavorites/' + uuid + '/' + lang + '?limit=' + limit + '&offset=' + offset + '&feature=1&type=&type=0&homeonly=0').then(response => {
        if (response.statusText !== "OK") {
            dispatch({
                type: FETCH_SOCIAL_MEMBER_DESTINATION_FAVORITES_ERROR,
                error: true,
            })
        } else {
            if (response.data.homeFavorites.length < limit) {
                dispatch({
                    type: FETCH_SOCIAL_MEMBER_DESTINATION_FAVORITES_MERGE,
                    destination_favorites: response.data.homeFavorites,
                    offset: limit + offset,
                })
                dispatch({
                    type: FETCH_SOCIAL_MEMBER_DESTINATION_FAVORITES_IS_FETCH,
                    is_fatch: true,
                })

            } else {
                dispatch({
                    type: FETCH_SOCIAL_MEMBER_DESTINATION_FAVORITES_MERGE,
                    destination_favorites: response.data.homeFavorites,
                    offset: limit + offset,
                })
                dispatch({
                    type: FETCH_SOCIAL_MEMBER_DESTINATION_FAVORITES_IS_FETCH,
                    is_fatch: false,
                })
            }
        }
    })
}


/*  set favorites type seletected */
export const setFavoritesTypeSelected = (uuid, typeId, typeName, lang) => dispatch => {
    /*  fetch data  */
    GetAsyncHelper(environment.apiUrl +'memberfavorites/' + uuid + '/' + 'en' + '?feature=1&type=' + typeId + '&homeonly=1&offset=0&limit=7').then(responseHomeFavorites => {
        if (responseHomeFavorites.statusText !== "OK") {
            dispatch({
                type: FETCH_SOCIAL_MEMBER_HOMETOWN_FAVORITES_ERROR,
                error: true,
            })

        } else {

            if (responseHomeFavorites.data.homeFavorites.length < 7) {
                dispatch({
                    type: FETCH_SOCIAL_MEMBER_HOMETOWN_FAVORITES,
                    hometown_favorites: responseHomeFavorites.data.homeFavorites,
                    limit: 3,
                    offset: 7,
                })
                dispatch({
                    type: FETCH_SOCIAL_MEMBER_HOMETOWN_FAVORITES_IS_FETCH,
                    is_fatch: true,
                })
            } else {
                dispatch({
                    type: FETCH_SOCIAL_MEMBER_HOMETOWN_FAVORITES,
                    hometown_favorites: responseHomeFavorites.data.homeFavorites,
                    limit: 3,
                    offset: 7,
                })
                dispatch({
                    type: FETCH_SOCIAL_MEMBER_HOMETOWN_FAVORITES_IS_FETCH,
                    is_fatch: false,
                })
            }
        }
    })
    dispatch({
        type: CURRENT_FAVORITES_TYPE_SELECTED,
        type_id: typeId,
        type_name: typeName
    })
}
