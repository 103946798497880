import * as Actions from '../../../redux/actions'

import React, { Component } from 'react'
import { image_boxing, list_container_mode } from '../Common/RegExValidate'

import { AngleUpIcon } from '../Common/SVGBank'
import Fab from '@material-ui/core/Fab'
import { Heart } from '../Common/MemberActions/Heart'
import {
    Image,
} from 'react-bootstrap'
import { Plus } from '../Common/MemberActions/Plus'
import Rating from '@material-ui/lab/Rating'
import { ScrollTopLazyLoading } from "../Common/ScrollTopLazyLoading"
import { Star } from '../Common/MemberActions/Star'
import { TripDescription } from '../Common/Trip/TripDescription'
import TripName from '../Common/Trip/TripName'
import { connect } from 'react-redux'
import { BucketListView } from '../Common/BucketList/BucketListView';
import { useState } from 'react';
import { Element, scroller } from 'react-scroll'
import BucketListCardView from '../Common/BucketList/BucketListCardView';
import InfiniteScroll from 'react-infinite-scroll-component';

const BucketList_ = ({ featureList, lang, timelineWizardStatus, activeFeatureId, types, bodyClientWidth, selectedSubtypes,
    loading,offset, loadingFeatureListMoreFeature,setSwitchContainer, loadMoreAllFeatureList,loadingFeatureListLessFeature, isMobile, featureListDisplayFormat, switchFeatureContainer }) => {
    let widthContainer = ""
    let heightContainer = ""
    let gridContainer = ""
    let postionContainer = ""
    let positionScrollToTopLazyLoading = ""
    if (!isNaN(isMobile)) {
        widthContainer = isMobile ? (bodyClientWidth * 0.98) : (timelineWizardStatus ? ((bodyClientWidth - 380) * 0.65 / 2) : (bodyClientWidth * 0.65 / 2))
        heightContainer = isMobile ? "" : (timelineWizardStatus ? ((bodyClientWidth - 380) * 0.65 / 2 / 2) : (bodyClientWidth * 0.65 / 2 / 2))
        postionContainer = isMobile ? "block" : "d-flex"
        gridContainer = isMobile ? (bodyClientWidth * 0.98 - 5) : (timelineWizardStatus ? (bodyClientWidth * 0.535 / 4 - 8 - 10) : (bodyClientWidth * 0.7 / 4 - 8 - 10))
        positionScrollToTopLazyLoading = isMobile ? "" : (timelineWizardStatus ? "10%" : "10%")
    }

    const loadMore = () => {
        loadMoreAllFeatureList(activeFeatureId, loading, lang, offset)
    }

    return (

        <div
            style={{
                zIndex: "1",
            }}>
            <div id="back_to_top_trip"></div>
            <InfiniteScroll
               style={{overflow:'hidden', width: '100%'}}
                    scrollThreshold={0.8}
                    dataLength={featureList.length} //This is important field to render the next data
                    next={loadMore}
                    hasMore={true}
                >
            {featureList !== undefined && featureList.length > 0 &&
                featureList.map((feature, feature_index) => {
                    const bucketlist = feature.features // It's bucketlist
                    return (
                       <div key={feature_index}>
                           
                            <BucketListComponent
                            bucketlist={bucketlist}
                            feature={feature}
                            featureListDisplayFormat={featureListDisplayFormat}
                            isMobile={isMobile}
                            widthContainer={widthContainer}
                            postionContainer={postionContainer}
                            switchFeatureContainer={switchFeatureContainer}
                            heightContainer={heightContainer}
                            timelineWizardStatus={timelineWizardStatus}
                            types={types}
                            setSwitchContainer={setSwitchContainer}
                        />
                           </div>)

                })}
            </InfiniteScroll>

            {featureList && featureList.length > 0  && featureList[0].features < 1 && <div className="noResults"  style={{font: "21px/21px Futura Md BT", marginTop: 30
                        }}> Sorry, could not find any results for your search </div>}

            <ScrollTopLazyLoading targetId={"#back_to_top_trip"} rightPosition={positionScrollToTopLazyLoading}>
                <Fab size="small" aria-label="scroll back to top" style={{
                    outline: "0px",
                    backgroundColor: "var(--mainOrange)"
                }}>
                    <AngleUpIcon fill={"var(--mainWhite)"} style={{ marginLeft: "3px" }} />
                </Fab>
            </ScrollTopLazyLoading>
            <style dangerouslySetInnerHTML={{
                __html: `
                   *::-webkit-scrollbar-thumb {
                    background-color: var(--lightGraySeparator);
                    border-radius: 20px;
                    border: 3px solid ${switchFeatureContainer.color};
                  }
                `}} />

        </div >
    )
}

const mapStateToProps = (state) => {
    return {
        featureListDisplayFormat: state.Feature.feature.featureListDisplayFormat,
        selectedSubtypes: state.Feature.feature.loading.selectedSubtypes,
        loading: state.Feature.feature.loading,
        featureList: state.Feature.feature.featureList,
        types: state.Feature.feature.types,
        offset: state.Feature.feature.offset,
        lang: state.Setting.lang,
        isMobile: state.Setting.is_mobile,
        bodyClientWidth: state.Setting.htmlBody.bodyClientWidth,

        switchFeatureContainer: state.FormBank.FeatureFormat,
        timelineWizardStatus: state.FormBank.TimelineWizard.status,
        activeFeatureId: state.Feature.feature.activeId
    }
}

const mapDispatchToProps = {
    addSelectedSubTypeFeature: Actions.addSelectedSubTypeFeature,
    deleteSelectedSubTypeFeature: Actions.deleteSelectedSubTypeFeature,

    loadingFeatureListMoreFeature: Actions.loadingFeatureListMoreFeature,
    loadingFeatureListLessFeature: Actions.loadingFeatureListLessFeature,
    loadMoreAllFeatureList: Actions.loadMoreAllFeatureList,
    setSwitchContainer: Actions.setSwitchContainer,
}

const BucketList = connect(mapStateToProps, mapDispatchToProps)(BucketList_)
export default BucketList



const BucketListComponent = ({feature, bucketlist, featureListDisplayFormat, isMobile, widthContainer,
    postionContainer, switchFeatureContainer, heightContainer, timelineWizardStatus, types,setSwitchContainer
}) => {

    return (
        <Element name={feature.title} className="element">
            {bucketlist !== undefined && bucketlist.length > 0 && <div style={{
                position: 'sticky',
                top: 240,
                background: 'white',
                zIndex: 2,
                font: "36px/72px Futura Hv BT", color: "var(--frameBlack)", marginTop: "10px"
            }}>

                {feature.title}
            </div>}
            <div>
                {bucketlist !== undefined && bucketlist.length > 0
                    && bucketlist.map((bucket, bucket_idx) => {
                        return (
                            <React.Fragment key={bucket_idx}>

                                { featureListDisplayFormat === list_container_mode.grid &&


                                    <div
                                        className={featureListDisplayFormat === list_container_mode.grid ? "d-inline-block" : ""}>

                                        <BucketListCardView bucketItem={bucket} is_mobile={isMobile}
                                            widthContainer={widthContainer}
                                            heightContainer={heightContainer}
                                            timelineWizardStatus={timelineWizardStatus} />

                                        <React.Fragment>
                                            {
                                                // timelineWizardStatus && <Image
                                                //     id={"drag_establishment_" + establishment.establishmentID}
                                                //     style={{ position: "absolute", right: "200%" }}
                                                //     src={image_boxing(establishment.estabPicture, 90, 70)}
                                                //     alt="establishment" />
                                            }
                                        </React.Fragment>
                                    </div>}
                                { featureListDisplayFormat === list_container_mode.list &&

                                    <div
                                        className={featureListDisplayFormat === list_container_mode.grid ? "d-inline-block" : ""}>

                                        <BucketListView timelineWizardStatus={timelineWizardStatus}
                                            postionContainer={postionContainer}
                                            widthContainer={widthContainer}
                                            heightContainer={heightContainer}
                                            bucketItem={bucket}
                                            switchFeatureContainer={switchFeatureContainer}
                                            isMember={false}
                                            setSwitchContainer={setSwitchContainer}
                                            is_mobile={isMobile}  />
                                       

                                        <React.Fragment>
                                            {
                                                // timelineWizardStatus && <Image
                                                //     id={"drag_establishment_" + establishment.establishmentID}
                                                //     style={{ position: "absolute", right: "200%" }}
                                                //     src={image_boxing(establishment.estabPicture, 90, 70)}
                                                //     alt="establishment" />
                                            }
                                        </React.Fragment>
                                    </div>}
                            </React.Fragment>
                        )
                    })
                }
               
            </div>
     
        </Element>
    )
}