import React from 'react'
import { AngleUpIcon, RightArrowIcon } from '../Common/SVGBank';
import airplane from '../../../assets/images/airplane-6.svg'
import './flights.css';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { CheckBoxOutlineBlankOutlined, CheckBoxOutlined } from '@material-ui/icons';
import { useSelector } from 'react-redux';



export const FlightBack = ({ city }) => {

    return (
        <div style={{ color: "#19BC9B", background: '#19BC9B33', padding: 23, font: "14px/14px Futura Md BT", marginBottom: 15 }}>
            Flights back to {city}
        </div>
    )
}


export const FlightDetailComponent = ({ flightText, airportCode, country, date, time,fromTimeline,diffDays }) => {
    return (
        <div style={{ padding: fromTimeline?  '0px 20px':'0px 40px' }}>
            <div style={{ color: '#6b6b6b', font: "14px/14px Futura LT BT", height: fromTimeline? 27: 'auto', marginTop: 20, marginBottom: 3 }}>
                {flightText}
            </div>
            <div style={{ font: "24px/24px Futura Hv BT", marginBottom: 5 }}>
                {airportCode}
            </div>
            <div style={{ font: "16px/16px Futura Hv BT", marginBottom: 10 }}>
                {country}
            </div>
            <div style={{ color: '#6b6b6b', font: "14px/14px Futura Md BT", marginBottom: 5 }}>
                {date}
            </div>
            <div style={{ font: "14px/14px Futura Md BT", textTransform: 'capitalize' }}>
                {time}  {diffDays > 0 && <sup  style={{ color: '#E63155',top:-5,marginLeft:-3, font: "11px/11px Futura Md BT"}}>+{diffDays}</sup>}
            </div>
        </div>
    )
}

export const AirplaneComponent = ({ time,background }) => {

    return (
        <div style={{ width: 80, margin: '0 auto', background:background? background: '#fff', paddingLeft: 15, paddingRight: 15 }}>
            <img src={airplane} height={50} alt="airplane" />
            <div style={{ color: background? '#fff': '#6b6b6b', textAlign: 'center', font: "14px/14px Futura Lt BT", marginTop: 10 }}>
                {time}
            </div>
        </div>
    )
}

export const MidAirport = ({ time, airportCode,city }) => {
    return (
        <div style={{ width: '100%', margin: '0 auto', marginTop: -10, background: '#fff', paddingLeft: 15, paddingRight: 15 }}>
            <div style={{ font: "24px/24px Futura Hv BT", marginBottom: 5 }}>
                {airportCode}
            </div>
            <div style={{ font: "16px/16px Futura Hv BT", marginBottom: 5 }}>
                {city}
            </div>
            <div style={{ color: '#6b6b6b', textAlign: 'center', font: "14px/14px Futura Lt BT", marginTop: 5 }}>
                {time}
            </div>
        </div>
    )
}

export const SelectFlight = ({ cost, total, selectFlight, isDisplay,currency,flightSymbol,isRoundTrip }) => {

    const formatCost = cost ? cost.toString().split(".") : null;
    const wholePriceCost = formatCost ? formatCost[0] : null;
    const centsCost = formatCost ? formatCost[1] : null;


    const formatTotal = total ? total.toString().split(".") : null;
    const wholePriceTotal = formatTotal ? formatTotal[0] : null;
    const centsTotal = formatTotal ? formatTotal[1] : null;


    return (
        <div className="selectFlight">
            <div style={{ font: "26px/26px Futura Hv BT", paddingBottom: 5, textAlign: 'center', width: '100%' }}>
            <span style={{ font: "16px/16px Futura Md BT", }}>{isRoundTrip? "+"+ currency: currency}</span>
            {wholePriceCost}.<sup style={{fontSize: '50%', top: '-0.7em'}}>{centsCost}</sup>
            </div>
           {!isDisplay && <div style={{ color: '#6b6b6b', textAlign: 'center', font: "14px/14px Futura Lt BT", marginTop: 5 }}>
                Total: {wholePriceTotal}.<sup style={{fontSize: '50%', top: '-0.7em'}}>{centsTotal}</sup>
            </div>}
             {!isDisplay && <button className='selectButton' onClick={selectFlight}>
                Select <RightArrowIcon width={14} height={14} style={{ marginLeft: 60 }} />
            </button>}
        </div>
    )
}


export const FlightCheckBox = ({label,checked}) =>{
    return (
        <FormControlLabel
        control={
            <Checkbox
                 checked={checked}
                icon={<CheckBoxOutlineBlankOutlined style={{ font: '22px/22px Futura Hv BT', marginTop: -5, color: '#19BC9B' }} />}
                checkedIcon={<CheckBoxOutlined style={{ font: '22px/22px Futura Hv BT', marginTop: -5, color: '#19BC9B' }} />}
                name={label}
            />
        }
        style={{ display: 'block',marginBottom: -5}}
        label={<span style={{ color: '#7F7F7F',  font: "16px/16px Futura Md BT" }}>
            {label}
          </span>}
    />
    )
}
