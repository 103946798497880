import React from 'react'
import { connect, useSelector } from 'react-redux'
import * as Actions from '../../../../redux/actions'
import { Grid } from "@material-ui/core"
import moment from "moment"
import { Image } from "react-bootstrap"
import { Rating } from "@material-ui/lab"
import EstablishmentName from "../Establishment/EstablishmentName"
import { AirplaneComponent, FlightDetailComponent } from '../../FlightsPage/flightsComponents'
import { secondsToHms, tConv24 } from '../../../../utility/utility'
import './TripSection.css';

const TripScheduleFlight_ = ({ flight, isMobile, bodyClientWidth, logo_width }) => {
    const departureDate = flight?.departure_date ? moment(flight.departure_date).format('ddd MMM D') : "";
    const arrivalDate = flight?.arrival_date ? moment(flight.arrival_date).format('MMM Do') : "";
    const booked = flight?.booked_booking_status === "ACCEPTED";
    const email = flight?.booked_email;
    const bookingId = flight?.priceline_trip_number;
    const widthContainer = isMobile ? Math.floor(bodyClientWidth * 0.98) : Math.floor(((bodyClientWidth - 17) * 0.83) - (logo_width * 2) - 40)
    const widthImage = widthContainer / 2
    const heightImage = widthImage / 2


    return (
        <div style={{
            position: "relative",
            margin: "0px",
            color: "#FFFFFF",
            border: "1px solid #707070",
            width: widthContainer,
            marginLeft: 40,
            padding: "30px 60px"
        }}>
            <div
                style={{
                    display: "inline-block",
                    font: "14px/15px Futura Lt BT",
                }}>
                {departureDate}
                    </div>

            <Grid container spacing={0} style={{
                marginTop: 30
            }}>

                <Grid item lg={flight?.booking_id?2:3}  >
                    <div
                        style={{
                            font: "36px/15px Futura Md BT",
                            marginBottom: 20
                        }}>
                        {flight?.departure_iata}
                    </div>
                    <div
                        style={{
                            font: "24px/15px Futura Lt BT",
                            marginBottom: 20,
                            textTransform: "lowercase"
                        }}>
                        {tConv24(flight?.departure_time)}
                    </div>

                    <div
                        style={{
                            font: "18px/15px Futura Lt BT",
                            marginBottom: 20
                        }}>
                        {flight?.departure_city}
                    </div>

                </Grid>
                <Grid item lg={flight?.booking_id? 4: 6}  >
                    <div style={{
                        zIndex: 0
                    }}
                        className="schedulePlane">

                    </div>
                    <div style={{
                        marginTop: 0, zIndex: 1, width: "100%",
                        marginLeft: -40
                    }}>
                        <AirplaneComponent time={secondsToHms(flight?.overall_duration)}
                            background="inherit" />
                    </div>
                </Grid>
                <Grid item lg={flight?.booking_id? 2:3}  >

                    <div
                        style={{
                            font: "36px/15px Futura Md BT",
                            marginBottom: 20
                        }}>
                        {flight?.arrival_iata}
                    </div>
                    <div
                        style={{
                            font: "24px/15px Futura Lt BT",
                            marginBottom: 20,
                            textTransform: "lowercase"
                        }}>
                        {tConv24(flight?.arrival_time)}
                    </div>

                    <div
                        style={{
                            font: "18px/15px Futura Lt BT",
                            marginBottom: 20
                        }}>
                        {flight?.arrival_city}
                    </div>


                </Grid>
                {flight?.booking_id &&<Grid item lg={4} style={{
                    borderLeft: "1px solid #707070",
                    marginTop: -10,
                    textAlign: 'center',
                    paddingTop: 30
                }} >

                    <div
                        style={{
                            font: "24px/15px Futura Lt BT",
                            marginBottom: 20
                        }}>
                        Booking #:
                       <span
                            style={{
                                font: "24px/15px Futura Md BT",
                            }}>
                            {flight?.booking_id}
                        </span>
                    </div>

                </Grid>}

               
            </Grid>

            <div style={{display: 'block'}}>
                    <img src={flight?.airline_logo}
                        alt="airline logo"
                        style={{
                            height: 25, width: 25
                        }} />
                    <span
                        style={{
                            font: "18px/15px Futura Lt BT",
                            marginBottom: 20,
                            paddingLeft: 20
                        }}>
                        {flight?.airline_name}
                    </span>
                </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    bodyClientWidth: state.Setting.htmlBody.bodyClientWidth,
    logo_width: state.Setting.appMenu.logo.width,
    isMobile: state.Setting.is_mobile,
    switchFeatureContainer: state.FormBank.FeatureFormat,
    logo_height: state.Setting.appMenu.logo.height,

    switch_container: state.Member.switch_container,
})

const mapDispatchToProps = {
}

const TripScheduleFlight = connect(mapStateToProps, mapDispatchToProps)(TripScheduleFlight_)
export default TripScheduleFlight
