import * as Actions from '../../../redux/actions'

import { AngleUpIcon, PlusSignIcon } from '../Common/SVGBank';
import React, { Component } from 'react'
import { animated, useSpring } from 'react-spring'
import { image_boxing, list_container_mode, loadingImage, member_area_router, URL_REPLACE } from '../Common/RegExValidate'

import AddedToTrip from '../Common/AddedToTrip'
import CircularProgress from '@material-ui/core/CircularProgress'
import { EstablishmentDescription } from '../Common/EstablishmentDescription'
import EstablishmentName from '../Common/Establishment/EstablishmentName'
import Fab from '@material-ui/core/Fab';
import { Heart } from '../Common/MemberActions/Heart'
import {
    Image,
} from 'react-bootstrap';
import { Plus } from '../Common/MemberActions/Plus'
import Rating from '@material-ui/lab/Rating'
import { ScrollTopLazyLoading } from "../Common/ScrollTopLazyLoading"
import { Star } from '../Common/MemberActions/Star'
import { connect } from 'react-redux'
import ActionsEllipsis from '../Common/actionsEllipsis';
import { Done } from '../Common/MemberActions/Done';
import LazyLoadingScrollEventListener from './LazyLoadingScrollEventListener';
import InfiniteScroll from 'react-infinite-scroll-component';
import { hexToRGB } from '../../../utility/utility';
import { useHistory } from "react-router-dom"


function checkImage(check) {
    if (check) {
        return "0.8"
    } else { }}

const PlacesElement = ({ establishments, establishment, uuid, lang, gridContainer, selectedSubtypes, addSelectedSubTypeFeature,
    deleteSelectedSubTypeFeature, switchFeatureContainer, timelineWizardStatus, availableDeleteActivities, isFetchingEstablishments,
    handleAddActivityToTripDestinationInTrip, activeTripDestinationId, activeDayInTripDestination, activeTripId,
    last_establishmentId, tripDuration, activeTripDestinationIds
}) => {
    const [flipped, setFlipped] = React.useState(false)
    const { transform, opacity } = useSpring({
        opacity: flipped ? 1 : 0,
        transform: `perspective(600px) rotateX(${flipped ? 180 : 0}deg)`,
        config: { mass: 5, tension: 500, friction: 80 }
    })
    return (
        <div style={{
            position: "relative", width: gridContainer + 15, height: gridContainer + 120, display: "inline-block",
            paddingLeft: "7.5px",
            paddingRight: "7.5px",
            paddingBottom: "50px",
            paddingTop: "15px"
        }}>
            <animated.div style={{position: "absolute", zIndex: flipped ? "0" : "1", opacity: opacity.interpolate(o => 1 - o), transform}}>
                <div style={{
                    position: "relative"
                }}>
                  
                    <div className="d-inline-block" style={{backgroundColor: "var(--mainGreen)"}}>
                        <Image
                            src={establishments !== undefined && establishments.length > 0 ? image_boxing(establishment.estabPicture, gridContainer, gridContainer) : loadingImage.src + "?func=crop&w=" + Math.floor(gridContainer) + "&h=" + Math.floor(gridContainer / 2)}
                            alt="establishment"
                            onClick={() => setFlipped(state => !state)} style={{opacity: checkImage(establishment?.estabPicture?.imageName?.includes("grayBackground"))}} />
                    </div>
                    
                    <div className="d-flex flex-wrap rating_color pt-2 pl-2" style={{ width: gridContainer + "px", border: "1px solid #E8E8E8", display: "block"}}>

                        <div style={{marginLeft: "0px", width: "80%"}}>
                            <EstablishmentName
                                establishmentCity={establishment.establishmentCity}
                                establishmentState={establishment.establishmentState}
                                establishmentCountry={establishment.establishmentCountry}
                                establishmentName={establishment.establishmentName}
                                establishmentId={establishment.establishmentID}
                                divClass={"flex_1"}
                                titleClass={"box_1"} titleStyle={{
                                    font: "16px/16px Futura Md BT",
                                    color: "var(--frameBlack)",
                                    marginTop: "5px",
                                    cursor: "pointer",
                                }} />
                        </div>

                        <div style={{marginLeft: "auto", marginRight: "0"}}>
                            {establishment.establishmentStarRating !== "0.0" && 
                            <div className="d-flex timeline_rating_color p-0 btn_rounded">
                                
                            <div
                                style={{
                                    paddingRight: "5px",
                                    marginTop: "8px",
                                    font: "11px/14px Futura Md BT",
                                    color: "var(--mainGreen)",
                                }}>
                                    {establishment.establishmentStarRating}
                                    </div>
                                
                            <div style={{
                                marginTop: "5px",
                                paddingRight: "2px",
                            }}>
                                
                                <Rating name="timeline_rating" precision={0.5} max={1} value={parseFloat((establishment.establishmentStarRating / 5).toFixed(1))} disabled={true} />
                            </div>

                            </div>
                        }
                        </div>
                        
                        <div style={{marginLeft: "0px",paddingBottom: "5px", width: "100%"}}>

                        {
                            establishment.typeTitle &&
                            <div className="btn_rounded mt-1 futura_md_bt_14_16_green upcoming_trip_style" style={{ height: "26px" }}>
                                {establishment.typeTitle}</div>
                        }


                        {establishment.subTypes !== undefined && establishment.subTypes.length > 0
                            && establishment.subTypes[0] !== undefined
                            && establishment.subTypes[0].subTypeName !== undefined &&
                            <div className="btn_rounded mt-1 futura_md_bt_14_16_green upcoming_trip_style" style={{ height: "26px", cursor: "pointer" }} onClick={() => {
                                selectedSubtypes.find(val => val.id === establishment.subTypes[0].subTypeID) ?
                                    deleteSelectedSubTypeFeature({ id: establishment.subTypes[0].subTypeID, title: establishment.subTypes[0].subTypeName }, selectedSubtypes)
                                    : addSelectedSubTypeFeature({ id: establishment.subTypes[0].subTypeID, title: establishment.subTypes[0].subTypeName }, selectedSubtypes)
                            }}>{establishment.subTypes[0].subTypeName}</div>
                        }
                        </div>

                    </div>


                    {activeTripId && <div style={{ position: "relative" }}>
                        { <div style={{ textAlign: "right", position: "absolute", top: "5px", right: "0px", zIndex: "2", }}>
                            {availableDeleteActivities.length > 0 && !isFetchingEstablishments &&
                                <AddedToTrip
                                    establishmentName={establishment.establishmentName}
                                    establishmentStarRating={establishment.establishmentStarRating}
                                    availableDeleteActivities={availableDeleteActivities}
                                    textFont={"14px/14px Futura Hv BT"}
                                    text={"Added to trip"}
                                />}
                            {!isFetchingEstablishments ? <React.Fragment>
                                {availableDeleteActivities.length > 0 ?
                                    <div onClick={() => handleAddActivityToTripDestinationInTrip(lang, uuid, activeTripDestinationId, activeDayInTripDestination, establishment.establishmentID, activeTripId, last_establishmentId, tripDuration, establishment.destinationId, activeTripDestinationIds)} style={{ cursor: "pointer", color: "#707170", font: "12px/12px Futura Lt BT", padding: "0px 10px", display: "inline-block" }}><PlusSignIcon fill={"#707170"} width={7} height={10} style={{ marginRight: "5px" }} />add again</div>
                                    : <div onClick={() => handleAddActivityToTripDestinationInTrip(lang, uuid, activeTripDestinationId, activeDayInTripDestination, establishment.establishmentID, activeTripId, last_establishmentId, tripDuration, establishment.destinationId, activeTripDestinationIds)} style={{ cursor: "pointer", color: "var(--mainGreen)", font: "14px/14px Futura Hv BT", padding: "0px 10px", display: "inline-block" }}><PlusSignIcon width={10} height={13} style={{ marginRight: "5px" }} />Add to trip</div>
                                }
                            </React.Fragment> : <CircularProgress
                                disableShrink
                                size={15}
                                style={{
                                    color: "#6A9EEC",
                                    textAlign: "right",
                                    marginTop: "15px",
                                    marginRight: "5px",
                                }} />
                            }
                        </div>}
                    </div>}




                    <ActionsEllipsis id={establishment.establishmentID} switchFeatureContainer={switchFeatureContainer} />

                </div>
            </animated.div>
            <animated.div style={{ position: "absolute", zIndex: flipped ? "1" : "0", opacity, transform: transform.interpolate(t => `${t} rotateX(180deg)`) }}>
                <div style={{
                    position: "relative",
                }}>
                    <div className="d-inline-block"><Image
                        src={establishments !== undefined && establishments.length > 0 ? image_boxing(establishment.estabPicture, gridContainer, gridContainer) : loadingImage.src + "?func=crop&w=" + Math.floor(gridContainer) + "&h=" + Math.floor(gridContainer / 2)}
                        alt="establishment" /></div>
                        
                    {establishment.establishmentDescription !== undefined &&
                        <div style={{ position: "absolute", top: "45px", zIndex: "1" }} className={"padding_left_10 padding_right_10"}>
                            <EstablishmentDescription   bottom={1} description={establishment.establishmentDescription} boxDescription={"box_10"}     textLength={280} fontDescription={timelineWizardStatus ? "14px/19px Futura Lt BT" : "18px/25px Futura Lt BT"} colorDescription={"#FFFFFF"} height={timelineWizardStatus ? "190px" : "250px"} />
                        </div>}

                    <div style={{ backgroundColor: "#19191980", position: "absolute", width: gridContainer + "px", height: gridContainer + "px", top: "0px" }} onClick={() => setFlipped(state => !state)} />
                    <div className="d-flex flex-wrap rating_color pt-2 pl-2" style={{ width: gridContainer + "px", border: "1px solid #E8E8E8" }}>
                        <EstablishmentName

                            establishmentCity={establishment.establishmentCity}
                            establishmentState={establishment.establishmentState}
                            establishmentCountry={establishment.establishmentCountry}
                            establishmentName={establishment.establishmentName}
                            establishmentId={establishment.establishmentID}
                            divClass={"flex_1"}
                            titleClass={"box_1"} titleStyle={{
                                font: "16px/16px Futura Md BT",
                                color: "var(--frameBlack)",
                                marginTop: "5px",
                                cursor: "pointer",
                            }} />
                        {establishment.establishmentStarRating !== "0.0" && <div className="d-flex timeline_rating_color p-0 btn_rounded">
                            <div
                                style={{
                                    paddingRight: "5px",
                                    marginTop: "8px",
                                    font: "11px/14px Futura Md BT",
                                    color: "var(--mainGreen)",
                                }}>{establishment.establishmentStarRating}</div>
                            <div style={{
                                marginTop: "5px",
                                paddingRight: "2px",
                            }}>
                                <Rating name="timeline_rating" precision={0.5} max={1} value={parseFloat((establishment.establishmentStarRating / 5).toFixed(1))} disabled={true} />
                            </div>
                        </div>}
                      
                        
                    </div>
                    <div style={{ position: "relative" }}>
                    <div style={{ textAlign: "right", position: "absolute", top: "5px", right: "0px", zIndex: "2", }}>
                    {
                            establishment.typeTitle &&
                            <div className="btn_rounded mt-1 futura_md_bt_14_16_green upcoming_trip_style" style={{ height: "26px" }}>
                                {establishment.typeTitle}</div>
                        }
                    {establishment.subTypes !== undefined && establishment.subTypes.length > 0
                            && establishment.subTypes[0] !== undefined
                            && establishment.subTypes[0].subTypeName !== undefined &&
                            <div className="btn_rounded mt-1 futura_md_bt_14_16_green upcoming_trip_style" style={{ height: "26px", cursor: "pointer" }} onClick={() => {
                                selectedSubtypes.find(val => val.id === establishment.subTypes[0].subTypeID) ?
                                    deleteSelectedSubTypeFeature({ id: establishment.subTypes[0].subTypeID, title: establishment.subTypes[0].subTypeName }, selectedSubtypes)
                                    : addSelectedSubTypeFeature({ id: establishment.subTypes[0].subTypeID, title: establishment.subTypes[0].subTypeName }, selectedSubtypes)
                            }}>{establishment.subTypes[0].subTypeName}</div>
                        }
                        </div>
                        { activeTripId && <div style={{ textAlign: "right", position: "absolute", top: "5px", right: "0px", zIndex: "2", }}>
                            {availableDeleteActivities.length > 0 && !isFetchingEstablishments &&
                                <AddedToTrip
                                    establishmentName={establishment.establishmentName}
                                    establishmentStarRating={establishment.establishmentStarRating}
                                    availableDeleteActivities={availableDeleteActivities}
                                    textFont={"14px/14px Futura Hv BT"}
                                    text={"Added to trip"}
                                />}
                            {!isFetchingEstablishments ? <React.Fragment>
                                {availableDeleteActivities.length > 0 ?
                                    <div onClick={() => handleAddActivityToTripDestinationInTrip(lang, uuid, activeTripDestinationId, activeDayInTripDestination, establishment.establishmentID, activeTripId, last_establishmentId, tripDuration, establishment.destinationId, activeTripDestinationIds)} style={{ cursor: "pointer", color: "#707170", font: "12px/12px Futura Lt BT", padding: "0px 10px", display: "inline-block" }}><PlusSignIcon fill={"#707170"} width={7} height={10} style={{ marginRight: "5px" }} />add again</div>
                                    : <div onClick={() => handleAddActivityToTripDestinationInTrip(lang, uuid, activeTripDestinationId, activeDayInTripDestination, establishment.establishmentID, activeTripId, last_establishmentId, tripDuration, establishment.destinationId, activeTripDestinationIds)} style={{ cursor: "pointer", color: "var(--mainGreen)", font: "14px/14px Futura Hv BT", padding: "0px 10px", display: "inline-block" }}><PlusSignIcon width={10} height={13} style={{ marginRight: "5px" }} />Add to trip</div>
                                }
                            </React.Fragment> : <CircularProgress
                                disableShrink
                                size={15}
                                style={{
                                    color: "#6A9EEC",
                                    textAlign: "right",
                                    marginTop: "15px",
                                    marginRight: "5px",
                                }} />
                            }
                        </div>}
                    </div>
                    <ActionsEllipsis id={establishment.establishmentID} switchFeatureContainer={switchFeatureContainer} />
                </div>
            </animated.div>
        </div >
    )
}
const Establishments_ = ({destination, featureList, offset, loadMoreAllFeatureList, lang, uuid_auth, activeTrip, timelineWizardStatus, activeFeatureId, types, activeTripDestinationId, activeTripDay, activeDestinationId, addActivityToTripDestinationInTrip, bodyClientWidth, loading, loadingFeatureListMoreFeature, isFetchingEstablishments, isMobile, featureListDisplayFormat, switchFeatureContainer, 
    addSelectedSubTypeFeature,draggingActivity,setTimelineDraggingEstablishment,deleteActivityToDayOfTripDestinationInTrip,
     deleteSelectedSubTypeFeature, selectedSubtypes, activeTripActivities,setEstablishmentId,setSwitchContainer }) => {


       
    const onDragOverEstablishment = (event) => {
        event.preventDefault()
        event.stopPropagation()
    }

   const  onDragEndEstablishment = (event) => {
        setTimelineDraggingEstablishment(null, "")
        event.preventDefault()
        event.stopPropagation()
    }


    const onDragStartEstablishment = (event, establishment) => {
        setTimelineDraggingEstablishment(establishment, establishment.destinationId)
        // event.dataTransfer.setDragImage(document.getElementById('drag_establishment_' + establishment.establishmentID), 0, 0)
    }

    const onDropDeleteActivities = (event) => {
       draggingActivity !== undefined &&
        draggingActivity.dragDeleteActivity !== undefined &&
        draggingActivity.dragDeleteActivity !== null &&
         deleteActivityToDayOfTripDestinationInTrip(
             draggingActivity.dragDeleteActivity.lang,
            draggingActivity.dragDeleteActivity.uuid,
              draggingActivity.dragDeleteActivity.tripDestinationId,
            draggingActivity.dragDeleteActivity.activityID,
             draggingActivity.dragDeleteActivity.tripId)
    }

        const activeTripId = activeTrip.tripId
        const activeDayInTripDestination = activeTripDay.dayInTripDestination

        let widthContainer = ""
        let heightContainer = ""
        let gridContainer = ""
        let postionContainer = ""
        let positionScrollToTopLazyLoading = ""
        const uuid = uuid_auth !== "" ? uuid_auth : "TMPUUID"
        if (!isNaN(isMobile)) {
            widthContainer = isMobile ? Math.floor(bodyClientWidth * 0.98) : Math.floor(timelineWizardStatus ? ((bodyClientWidth - 380) * 0.65 / 2) : (bodyClientWidth * 0.65 / 2))
            heightContainer = isMobile ? "" : Math.floor(timelineWizardStatus ? ((bodyClientWidth - 380) * 0.65 / 2 / 2) : (bodyClientWidth * 0.65 / 2 / 2))
            postionContainer = isMobile ? "block" : "d-flex"
            gridContainer = isMobile ? Math.floor(bodyClientWidth * 0.98 - 5) : Math.floor(timelineWizardStatus ? (bodyClientWidth * 0.535 / 4 - 18) : (bodyClientWidth * 0.7 / 4 - 18))
            positionScrollToTopLazyLoading = isMobile ? "" : (timelineWizardStatus ? "10%" : "10%")
        }

        const activitiesInDestinationDay = activeTrip.activities.filter(a => a.activityDay === activeTripDay.dayInTrip && a.tripDestinationId === activeTripDay.tripDestinationId)
        const lengthActivitiesInDestinationDay = activitiesInDestinationDay !== undefined ? activitiesInDestinationDay.length : 0
        const activitiesInDestinationDayEstablishmentIds = Array.from(activitiesInDestinationDay.map((a) => a.activityEstabID))
        const last_establishmentId = activitiesInDestinationDayEstablishmentIds[lengthActivitiesInDestinationDay - 1] !== undefined ? activitiesInDestinationDayEstablishmentIds[lengthActivitiesInDestinationDay - 1] : ""

        const activeTripDestinationIds = Array.from(new Set(activeTrip.tripDestinations.map(d => d.id)))
        const tripDuration = activeTrip.tripDuration

        const handleAddActivityToTripDestinationInTrip = (lang, uuid, activeTripDestinationId, activeDayInTripDestination, establishmentID, activeTripId, last_establishmentId, tripDuration, destinationId, activeTripDestinationIds) => {
            let activeMapTripDestinationId = activeTripDestinationId
            let activeDayMapInTripDestination = activeDayInTripDestination
            let activeDayMapDayInTrip = activeTripDay.dayInTrip
            const isSetActiveTrip = destinationId !== activeDestinationId
            if (isSetActiveTrip) {
                const existDestinationArray = activeTrip.tripDestinations.filter(d => d.id === String(destinationId))
                const existDestinationArrayLength = existDestinationArray !== undefined ? existDestinationArray.length : 0
                const exsitDestination = existDestinationArray !== undefined ? existDestinationArray[existDestinationArrayLength - 1] : undefined
                activeMapTripDestinationId = exsitDestination === undefined ? activeTripDestinationId : exsitDestination.td_id
                const destinationDays = exsitDestination !== undefined && exsitDestination.spanningDays !== undefined ? exsitDestination.spanningDays.split(',') : undefined
                const destinationDaysLength = destinationDays !== undefined ? destinationDays.length : 0
                activeDayMapInTripDestination = exsitDestination === undefined ? activeDayInTripDestination : destinationDaysLength
                activeDayMapDayInTrip = exsitDestination === undefined ? activeTripDay.dayInTrip : destinationDays[destinationDaysLength - 1]
            }

            addActivityToTripDestinationInTrip(lang, uuid, activeMapTripDestinationId, activeDayMapInTripDestination, establishmentID, activeTripId, last_establishmentId, tripDuration, destinationId, activeTripDestinationIds, isSetActiveTrip, activeDayMapDayInTrip)
            document.getElementById(activeMapTripDestinationId + "_" + activeDayMapInTripDestination + "_" + activeDayMapDayInTrip) !== null && document.getElementById(activeMapTripDestinationId + "_" + activeDayMapInTripDestination + "_" + activeDayMapDayInTrip).scrollIntoView({ behavior: 'smooth', block: 'start' })

        }
        const loadMore = () => {
            loadMoreAllFeatureList(activeFeatureId, loading, lang, offset)
        }
        const history = useHistory()

        // const parentLocation = (establishment) => {
        //     if(window.location.pathname.includes("/undefined/1")){
        //         // console.log("Country")
        //         return(establishment.establishmentCity)
        //     }else{
        //         return(establishment.establishmentNeighborhood)
        //     }
        // }


        const handleClick = (establishment) => {
            const city = establishment.establishmentCity !== "" ? "-" + establishment.establishmentCity.replace(URL_REPLACE, "-") : ""
            const state = establishment.establishmentState !== "" ? "-" + establishment.establishmentState.replace(URL_REPLACE, "-") : ""
            const country = establishment.establishmentCountry !== "" ? "-" + establishment.establishmentCountry.replace(URL_REPLACE, "-") : ""

            setEstablishmentId(establishment.establishmentID)
            setSwitchContainer(member_area_router.establishment)
            const url = "/establishment/" + establishment.establishmentName.replace(URL_REPLACE, "-") + city + state + country 
            + "/" + establishment.establishmentID;
            history.push(url.toLowerCase())
          }

          const { breadcrumb } = destination;
          const level =  breadcrumb ? breadcrumb?.length : 1;
  
      
        return (

            <div
                style={{
                    zIndex: "1",
                }}>
                <div id="back_to_top_establishment"></div>
                <InfiniteScroll
                     style={{overflow:'hidden', width: '100%'}}
                    scrollThreshold={0.8}
                    dataLength={featureList.length} //This is important field to render the next data
                    next={loadMore}
                    hasMore={true}
                >
                    {featureList !== undefined && featureList.length > 0 &&
                        featureList.map((feature, feature_index) => {
                            const establishments = feature.features // It's establishments
                         
                            return (
                                <div key={feature_index} >
                                    {establishments !== undefined && establishments.length > 0 && 
                                    <div style={{ font: "36px/72px Futura Hv BT", color: "var(--frameBlack)", marginTop: "10px" }}>
                                        {feature.title}
                                        </div>}
                                    <div>
                                        {establishments !== undefined && establishments.length > 0
                                            && establishments.map((establishment, establishment_idx) => {
                                                const availableDeleteActivities = activeTripActivities.filter(a => a.activityEstabID === establishment.establishmentID)
                                                
                                                return (
                                                    <React.Fragment key={establishment_idx}>
                                                        <div
                                                            draggable={!isFetchingEstablishments ? true : false}
                                                            onDragOver={(e) => !isFetchingEstablishments ? onDragOverEstablishment(e) : {}}
                                                            onDragEnd={(e) => !isFetchingEstablishments ? onDragEndEstablishment(e) : {}}
                                                            onDragStart={(e) => !isFetchingEstablishments ? onDragStartEstablishment(e, establishment) : {}}
                                                            onDrop={(e) => !isFetchingEstablishments ? onDropDeleteActivities(e) : {}}

                                                            className={featureListDisplayFormat === list_container_mode.grid ? (!isFetchingEstablishments ? "d-inline-block draggable" : "d-inline-block") : (!isFetchingEstablishments ? "draggable" : "")}>
                                                           
                                                            {featureListDisplayFormat === list_container_mode.list &&
                                                                <div>
                                                                    <div className={timelineWizardStatus ? "margin_top_10" : "margin_top_20"}>
                                                                        <div className={postionContainer}>
                                                                       
                                                                            <div onClick={()=> handleClick(establishment)} className="d-inline-block" style={{ width: widthContainer }}>
                                                                            
                                                                                <div  className="establishmentImageContainer" style={{backgroundColor: "var(--mainGreen)"}}>
                                                                                <Image
                                                                                src={image_boxing(establishment.estabPicture, widthContainer, isMobile? "200":300)}
                                                                                
                                                                                alt="establishment" 
                                                                                className="establishmentImage" 
                                                                                style={{opacity: checkImage((establishment?.estabPicture?.imageName)?.includes("grayBackground"))}}/>
                                                                                

                                                                                </div>
                                                                                
                                                                                </div>
                                                                            
                                                                            <div  style={{ width: widthContainer + "px", height: 300 + "px", border: "1px solid #E8E8E8", verticalAlign: "middle", flexGrow: "1", paddingLeft: "10px", position: "relative" }}
                                                                            className="establishmentBody">

                                                                                <div style={{ position: "relative", height: "25px" }} className={timelineWizardStatus ? "margin_top_10 box_1 padding_left_10 padding_right_20 text-right" : "margin_top_20 box_1 padding_left_10 padding_right_20 text-right"} >
                                                                                    <div style={{ position: "absolute", top: "0px", right: "140px" }}>
                                                                                        <Done
                                                                                            unitID={establishment.establishmentID}
                                                                                            feature={switchFeatureContainer}
                                                                                            fill={"var(--mainGreen)"} />
                                                                                    </div>

                                                                                    <div style={{ position: "absolute", top: "0px", right: "100px" }}>
                                                                                        <Heart
                                                                                            unitID={establishment.establishmentID}
                                                                                            feature={switchFeatureContainer}
                                                                                            fill={"var(--mainGreen)"} />
                                                                                    </div>
                                                                                    <div style={{ position: "absolute", top: "0px", right: "60px" }}>
                                                                                        <Plus
                                                                                            unitID={establishment.establishmentID}
                                                                                            feature={switchFeatureContainer}
                                                                                            fill={"var(--mainGreen)"} />
                                                                                    </div>
                                                                                    <div style={{ position: "absolute", top: "0px", right: "20px" }}>
                                                                                        <Star
                                                                                            unitID={establishment.establishmentID}
                                                                                            feature={switchFeatureContainer}
                                                                                            fill={"var(--mainGreen)"} />
                                                                                    </div>
                                                                                </div>
   
                                                                                <div style={{
                                                                                    background:'white',
                                                                                    position: "absolute",
                                                                                    bottom:0,
                                                                                    width:'91%',
                                                                                    paddingTop: 55
                                                                                }}>
                                                                                <div className={timelineWizardStatus ? "padding_left_10 padding_right_10 text-left box_1 margin_top_5 margin_bottom_5" : "padding_left_10 padding_right_10 text-left box_1 margin_top_20 margin_bottom_10"}
                                                                                style={{textAlign: "right", position: "absolute", bottom: 0,zIndex: "2", paddingTop:0, paddingBottom:0,background:'white',width:isMobile? "62%": timelineWizardStatus?'78%':'92%',
                                                                                overflowX: "auto",
                                                                                overflowY: "hidden",
                                                                                whiteSpace:"nowrap"
                                                                                
                                                                                }}>
                                                                                    {
                                                                                        establishment.typeTitle &&
                                                                                        <div className="btn_rounded m-1 futura_md_bt_14_16_green upcoming_trip_style" style={{
                                                                                            background: 'white',
                                                                                            border: `1px solid ${switchFeatureContainer.color} `
                                                                                        }}>
                                                                                            {establishment.typeTitle}
                                                                                            </div>
                                                                                    }
                                                                                    {establishment.subTypes !== undefined && establishment.subTypes.length > 0 ?
                                                                                        establishment.subTypes.map((subtype, subtype_idx) => {
                                                                                            return <div key={subtype_idx}
                                                                                                className="btn_rounded m-1 futura_md_bt_14_16_green upcoming_trip_style"
                                                                                                style={{ cursor: "pointer" }}
                                                                                                onClick={() => {
                                                                                                    selectedSubtypes.find(val => val.id === subtype.subTypeID) ?
                                                                                                        deleteSelectedSubTypeFeature({ id: subtype.subTypeID, title: subtype.subTypeName }, selectedSubtypes)
                                                                                                        : addSelectedSubTypeFeature({ id: subtype.subTypeID, title: subtype.subTypeName }, selectedSubtypes)
                                                                                                }}>
                                                                                                {subtype.subTypeName}
                                                                                                </div>
                                                                                        })
                                                                                        : ""}
                                                                                </div>

                                                                                {activeTripId && <div style={{ textAlign: "right", position: "absolute", bottom: "15px", right: "10px", zIndex: "2", }}>
                                                                                    {availableDeleteActivities.length > 0 && !isFetchingEstablishments &&
                                                                                        <AddedToTrip
                                                                                            establishmentName={establishment.establishmentName}
                                                                                            establishmentStarRating={establishment.establishmentStarRating}
                                                                                            availableDeleteActivities={availableDeleteActivities}
                                                                                            textFont={"14px/14px Futura Hv BT"}
                                                                                            text={"Added to trip"}
                                                                                        />}
                                                                                    {!isFetchingEstablishments ? <React.Fragment>
                                                                                        {availableDeleteActivities.length > 0 ?
                                                                                            <div onClick={() => handleAddActivityToTripDestinationInTrip(lang, uuid, activeTripDestinationId, activeDayInTripDestination, establishment.establishmentID, activeTripId, last_establishmentId, tripDuration, establishment.destinationId, activeTripDestinationIds)} style={{ cursor: "pointer", color: "#707170", font: "12px/12px Futura Lt BT", padding: "0px 10px", display: "inline-block" }}><PlusSignIcon fill={"#707170"} width={7} height={10} style={{ marginRight: "5px" }} />add again</div>
                                                                                            : <div onClick={() => handleAddActivityToTripDestinationInTrip(lang, uuid, activeTripDestinationId, activeDayInTripDestination, establishment.establishmentID, activeTripId, last_establishmentId, tripDuration, establishment.destinationId, activeTripDestinationIds)} style={{ cursor: "pointer", color: "var(--mainGreen)", font: "14px/14px Futura Hv BT", padding: "0px 10px", display: "inline-block" }}><PlusSignIcon width={10} height={13} style={{ marginRight: "5px" }} />Add to trip</div>
                                                                                        }
                                                                                    </React.Fragment> : <CircularProgress
                                                                                        disableShrink
                                                                                        size={15}
                                                                                        style={{
                                                                                            color: "#6A9EEC",
                                                                                            textAlign: "right",
                                                                                            marginTop: "15px",
                                                                                            marginRight: "5px",
                                                                                        }} />
                                                                                    }
                                                                                </div>}
                                                                                </div>

                                                                                <div className="box_1 padding_left_10 padding_right_10" style={{
                                                                                    font: "14px/16px Futura Md BT",
                                                                                    color: "var(--mainGreen)",
                                                                                }}>
                                                                                    
                                                                                    {/* {establishment.establishmentCity} */}
                                                                                    {/* {establishment.establishmentNeighborhood} */}
                                                                                    {/* {console.log(window.location.pathname)} */}
                                                                                    {level&& level >3 ? establishment?.establishmentNeighborhood : establishment?.establishmentCity}
                                                                                    {/* {console.log(establishment)} */}
                                                                                </div>

                                                                                <EstablishmentName

                                                                                    establishmentCity={establishment.establishmentCity}
                                                                                    establishmentState={establishment.establishmentState}
                                                                                    establishmentCountry={establishment.establishmentCountry}
                                                                                    establishmentName={establishment.establishmentName}
                                                                                    establishmentId={establishment.establishmentID}
                                                                                    titleClass={"box_1"} titleStyle={{
                                                                                        font: timelineWizardStatus ? "22px/33px Futura Hv BT" : "28px/43px Futura Hv BT",
                                                                                        color: "var(--frameBlack)",
                                                                                        cursor: "pointer",
                                                                                        paddingLeft: "10px",
                                                                                        paddingRight: "10px",
                                                                                    }} />

                                                                                {establishment.establishmentStarRating !== "0.0" && <div className="d-flex timeline_rating_color padding_left_10 padding_right_10 btn_rounded">
                                                                                    <Rating name="timeline_rating" precision={0.5} max={5} value={parseFloat(establishment.establishmentStarRating)} disabled={true} />
                                                                                    <div className="futura_md_bt_11_14_black margin_bottom_5 margin_left_5">({establishment.establishmentStarRating})</div>
                                                                                </div>}
                                                                                {establishment.establishmentDescription !== undefined && <div className={"padding_left_10 padding_right_10"} >
                                                                                <EstablishmentDescription 
                                                                                is_mobile={isMobile}
                                                                                bottom={1}
                                                                                textLength={280}
                                                                                description={establishment.establishmentDescription} boxDescription={"box_3"} fontDescription={"14px/19px Futura Lt BT"} colorDescription={"var(--mainGrey)"} 
                                                                                height={(100) +"px"} /></div>}


                                                                               

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            }
                                                            
                                                            {featureListDisplayFormat === list_container_mode.grid &&
                                                                <PlacesElement
                                                                    establishments={establishments}
                                                                    establishment={establishment}
                                                                    lang={lang}
                                                                    uuid={uuid}
                                                                    gridContainer={gridContainer}
                                                                    selectedSubtypes={selectedSubtypes}
                                                                    addSelectedSubTypeFeature={addSelectedSubTypeFeature}
                                                                    deleteSelectedSubTypeFeature={deleteSelectedSubTypeFeature}
                                                                    switchFeatureContainer={switchFeatureContainer}
                                                                    timelineWizardStatus={timelineWizardStatus}
                                                                    availableDeleteActivities={availableDeleteActivities}
                                                                    isFetchingEstablishments={isFetchingEstablishments}
                                                                    handleAddActivityToTripDestinationInTrip={handleAddActivityToTripDestinationInTrip}
                                                                    activeTripDestinationId={activeTripDestinationId}
                                                                    activeDayInTripDestination={activeDayInTripDestination}
                                                                    activeTripId={activeTripId}
                                                                    last_establishmentId={last_establishmentId}
                                                                    tripDuration={tripDuration}
                                                                    activeTripDestinationIds={activeTripDestinationIds}
                                                                />}

                                                                
                                                            <React.Fragment>
                                                                {
                                                                    // timelineWizardStatus && <Image
                                                                    //     id={"drag_establishment_" + establishment.establishmentID}
                                                                    //     style={{ position: "absolute", right: "200%" }}
                                                                    //     src={image_boxing(establishment.estabPicture, 90, 70)}
                                                                    //     alt="establishment" />
                                                                }
                                                            </React.Fragment>
                                                        </div>
                                                    </React.Fragment>
                                                )
                                            })
                                        }
                                    </div>
                                
                                </div>
                            )
                        })}

                      
                </InfiniteScroll>

                {featureList && featureList.length > 0  && featureList[0].features < 1 && <div className="noResults" style={{font: "21px/21px Futura Md BT", marginTop: 30
                        }}> Sorry, could not find any results for your search </div>}

                <ScrollTopLazyLoading  targetId={"#back_to_top_establishment"} rightPosition={positionScrollToTopLazyLoading}>
                    <Fab size="small" aria-label="scroll back to top" style={{
                        outline: "0px",
                        backgroundColor: "var(--mainGreen)"
                    }}>
                        <AngleUpIcon fill={"var(--mainWhite)"} style={{ marginLeft: "3px" }} />
                    </Fab>
                </ScrollTopLazyLoading>


            </div>

        )
}

const mapStateToProps = (state) => ({
    featureListDisplayFormat: state.Feature.feature.featureListDisplayFormat,
    selectedSubtypes: state.Feature.feature.loading.selectedSubtypes,
    loading: state.Feature.feature.loading,
    offset: state.Feature.feature.offset,
    featureList: state.Feature.feature.featureList,
    
    types: state.Feature.feature.types,

    lang: state.Setting.lang,
    uuid_auth: state.Member.authModal.uuid,
    isMobile: state.Setting.is_mobile,
    bodyClientWidth: state.Setting.htmlBody.bodyClientWidth,
    destination: state.Destination.destination,
    switchFeatureContainer: state.FormBank.FeatureFormat,
    timelineWizardStatus: state.FormBank.TimelineWizard.status,
    draggingActivity: state.FormBank.TimelineWizard.draggingActivity,
    activeTripActivities: state.FormBank.TimelineWizard.activeTrip.activities,
    activeTrip: state.FormBank.TimelineWizard.activeTrip,
    activeTripDestinationId: state.FormBank.TimelineWizard.activeTripDestinationId,
    activeTripDay: state.FormBank.TimelineWizard.activeDay,
    isFetchingEstablishments: state.FormBank.TimelineWizard.isFetchingEstablishments,
    activeDestinationId: state.FormBank.TimelineWizard.activeDestinationId,
    activeFeatureId: state.Feature.feature.activeId,
})

const mapDispatchToProps = {
    addSelectedSubTypeFeature: Actions.addSelectedSubTypeFeature,
    deleteSelectedSubTypeFeature: Actions.deleteSelectedSubTypeFeature,

    setTimelineDraggingEstablishment: Actions.setTimelineDraggingEstablishment,
    deleteActivityToDayOfTripDestinationInTrip: Actions.deleteActivityToDayOfTripDestinationInTrip,
    addActivityToTripDestinationInTrip: Actions.addActivityToTripDestinationInTrip,
    loadingFeatureListMoreFeature: Actions.loadingFeatureListMoreFeature,
    loadMoreAllFeatureList: Actions.loadMoreAllFeatureList,
    setSwitchContainer: Actions.setSwitchContainer,
    setEstablishmentId: Actions.setEstablishmentId,
}

const Establishments = connect(mapStateToProps, mapDispatchToProps)(Establishments_)
export default Establishments
