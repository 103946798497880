import * as Actions from '../../../../redux/actions'

import React, { Component } from 'react'

import { connect, useDispatch, useSelector } from 'react-redux'
import { useParams,useLocation } from 'react-router-dom';
import { FlightSearchComponent } from '../FlightsSearch'
import { ScrollTopLazyLoading } from '../../Common/ScrollTopLazyLoading';
import { Backdrop, CircularProgress, Fab, Grid, makeStyles } from '@material-ui/core';
import { AngleUpIcon } from '../../Common/SVGBank';
import { useHistory } from "react-router-dom";
import { FlightRecapList } from './FlightManagementList';
import { FlightCancel,FlightConfirmed, FlightSupport} from './FlightManagementComponent';
import { setOpenAuthModal } from '../../../../redux/actions';
import { CancelFlightThunk, getFlightContractThunk, getNearByDestinationCode, lookUpFlightThunk, saveFlightTimeline } from '../services/Flights.thunks';
import { FlightExpired } from '../flightExpired';
import { setExpiredFlight } from '../services/Flights.slice';
import { URL_REPLACE } from "../../Common/RegExValidate";
import moment from 'moment';
import { FlightPassengers } from './FlightPassengers';
import { FlightPaymentDetails } from './FlightPaymentDetails';
import Loader from 'react-loader-spinner'

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));


const FlightManagementContainer_ = ({ lang, uuid,bodyClientWidth, pageYOffset, is_mobile, timelineWizardStatus,activeTrip }) => {
    const search = useLocation().search;
    const query = new URLSearchParams(search);
    let booking_id = query.get('booking_id');
    let email = query.get('email');

    const classes = useStyles();
    const history = useHistory();
    const nearByDestination  = useSelector(state => state.Flights.nearByDestination);
    const selectedFlight = useSelector(state => state.Flights.selectedFlight);
    const selectedFlightMode = useSelector(state => state.Flights.selectedFlightMode);

    let arrivalCode = selectedFlight && selectedFlight.slice_data ? selectedFlight.slice_data[selectedFlight.slice_data.length -1].arrival.airport.code : '';
    if (selectedFlightMode === 'round_trip') {
         arrivalCode = selectedFlight && selectedFlight.slice_data ? selectedFlight.slice_data[selectedFlight.slice_data.length -1].departure.airport.code : '';
    }
    const activeTripId = activeTrip?.tripId;
    let width;
    let marginLeft;
    const [saveToTrip, setSaveToTrip] = React.useState(false);
    const [loading, setLoading] = React.useState(true);
    const [bookingFlight, setBookFlight] = React.useState(false);
  

    React.useEffect(() => {
        if(email && booking_id){
                const data ={
                    email:email,
                    booking_id:booking_id
                }
                dispatch(lookUpFlightThunk(data)).then((res =>{
                    setLoading(false)
                }));  
        }
        setTimeout(() => {
            setLoading(false)
        }, 4000);

    }, [booking_id,email])

    let positionScrollToTopLazyLoading = ""
    if (!isNaN(is_mobile)) {
        width = is_mobile ? '100%' : '60%';
        marginLeft = is_mobile ? '0%' : timelineWizardStatus ? '31%' : '23%';
        positionScrollToTopLazyLoading = is_mobile ? "" : (timelineWizardStatus ? "10%" : "10%")
    }
    const dispatch = useDispatch()

    const lookUpData = useSelector(state => state.Flights.lookUpData);

    const authModal = useSelector(state => state.Member.authModal);
    const Flights = useSelector(state => state.Flights);


    const isLoggedIn = () => {
        return authModal.uuid;
    }

    const goToDestination = () =>{
      if(nearByDestination){
        const url = "/destination/" + nearByDestination.name.replace(URL_REPLACE, "-") + "/" + 1 + "/" + nearByDestination.destinationId;
        window.open(url.toLowerCase()); 
      }  
    }
   
    const cancelFlight = () =>{
        if(email && booking_id){
            const data ={
                email:email,
                booking_id:booking_id
            }
            dispatch(CancelFlightThunk(data)).then((res =>{
                dispatch(lookUpFlightThunk(data)).then((res =>{
                })); 
            }));
    }   
    }
   

    const pnr = lookUpData?.pnr_locator;
    const cancellable = lookUpData?.is_cancelable;
    const voidDate =  lookUpData?.void_window_close ? moment(lookUpData?.void_window_close).format('MMM Do YYYY, h:mm a'): "";
    const passengers  =  lookUpData?.passengers;
    const customer  =  lookUpData?.customer;
    const price_details  =  lookUpData?.price_details;
    const important_info_data =  lookUpData?.important_info_data;
    const booking_status = lookUpData?.book_status &&  lookUpData?.book_status === "VOIDED" ? "cancelled": "confirmed";
    return (
        <div>
        {loading ? <Loader
                    type="Rings"
                    color="#19BC9B"
                    className="se-pre-con"
                    height={150}
                    width={150}
                    style={{
                        backgroundColor: "#FFFFFF"
                    }} /> : 
            <div>
            <div style={{
                position: "relative",
                width: width,
                marginLeft: marginLeft,
                paddingBottom: 50,
                marginTop: 50
            }}>
                <div id="back_to_top_flights"></div>
 
                {pnr &&booking_status &&
                <FlightConfirmed pnr={pnr} booking_status={booking_status}/>
                 }

                <Grid container className="flightRecap">

                    <Grid item md={12} sm={12} xs={12} className="flightRecapBody" >
                         <FlightRecapList />
                    </Grid>

                    {cancellable && booking_status !== "cancelled" &&
                    <FlightCancel cancel={cancelFlight} voidDate={voidDate}/>}

                    {passengers && <FlightPassengers passengers={passengers}/>}
                    {customer && price_details && <FlightPaymentDetails customer={customer} price={price_details}/>}
                    {lookUpData && <FlightSupport booking={lookUpData}/>}
                </Grid>
                <FlightExpired/>
                <Backdrop className={classes.backdrop} open={bookingFlight} >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </div>



            <ScrollTopLazyLoading targetId={"#back_to_top_flights"} rightPosition={positionScrollToTopLazyLoading}>
                <Fab size="small" aria-label="scroll back to top" style={{
                    outline: "0px",
                    backgroundColor: '#19BC9B',
                }}>
                    <AngleUpIcon fill={"var(--mainWhite)"} style={{ marginLeft: "3px" }} />
                </Fab>
            </ScrollTopLazyLoading>
        </div>}
        
        </div>
    )
}


const mapStateToProps = state => {
    return {
        lang: state.Setting.lang,
        activeTrip: state.FormBank.TimelineWizard.activeTrip,
        is_mobile: state.Setting.is_mobile,
        lazyLoading: state.FormBank.HotelSearch.lazyLoading,
        hotelSearchForm: state.FormBank.HotelSearch.hotel_form_state,
        bookingForm: state.FormBank.BookingForm,
        bodyClientWidth: state.Setting.htmlBody.bodyClientWidth,
        pageYOffset: state.Setting.screenVertical.pageYOffset,
        switchFeatureContainer: state.FormBank.FeatureFormat,
        timelineWizardStatus: state.FormBank.TimelineWizard.status,
        uuid: state.Member.authModal.uuid,
    }
}

const mapDispatchToProps = {
    setTripDisplayMode: Actions.setTripDisplayMode,
    setPageYOffset: Actions.setPageYOffset
}

const FlightManagementContainer = connect(mapStateToProps, mapDispatchToProps)(FlightManagementContainer_)
export default FlightManagementContainer
