import * as Actions from '../../../../redux/actions'

import {
    EmailAddressTextFieldCompanion,
    FirstNameTextFieldCompanion,
    LastNameTextFieldCompanion,
} from "../../Common/FormComponentsBank"

import { CheckLightIcon } from "../../Common/SVGBank"
import Fab from '@material-ui/core/Fab'
import React from 'react'
import { UserPlusIcon } from "../../Common/SVGBank"
import {
    companionTextFieldUseStyles,
} from "../../Common/ComponentsStylingBank"
import { connect } from 'react-redux'
import { member_area_router } from "../../Common/RegExValidate"

export const TripCompanion = ({ fetchTripCompanionSets, switch_container, cleanLastNameCompanion, cleanEmailAddressCompanion, cleanFirstNameCompanion, emailAddressTextFieldCompanion, firstNameTextFieldCompanion, lastNameTextFieldCompanion, tripCompanionSets, activeTripId, uuid, lang }) => {
    const [loaded, setLoaded] = React.useState(false)
    const [open, setOpen] = React.useState(false)
    const classesTextField = companionTextFieldUseStyles();

    React.useEffect(() => {
        if (!loaded) {
            fetchTripCompanionSets(activeTripId, uuid, lang)
        }
        setLoaded(true)
    }, [activeTripId])

    const inviteHandler = () => {
        if (emailAddressTextFieldCompanion !== undefined
            && firstNameTextFieldCompanion !== undefined
            && lastNameTextFieldCompanion !== undefined
            && emailAddressTextFieldCompanion.emailAddress !== "" && !emailAddressTextFieldCompanion.error
            && firstNameTextFieldCompanion.firstName !== "" && !firstNameTextFieldCompanion.error
            && lastNameTextFieldCompanion.lastName !== "" && !lastNameTextFieldCompanion.error) {

            console.log(emailAddressTextFieldCompanion.emailAddress, firstNameTextFieldCompanion.firstName, lastNameTextFieldCompanion.lastName)
        }
    }
    return (
        <React.Fragment>
            { switch_container === member_area_router.memberAreaTrip ?

                <React.Fragment>
                    <div style={{ font: "23px/25px Futura Md BT", color: "#FFFFFF", textAlign: "left", paddingBottom: "10px" }}>{"Travelers"}</div>
                    <div style={{ display: "flex", flexWrap: "wrap", paddingBottom: "25px" }}>
                        {tripCompanionSets !== undefined && tripCompanionSets.length > 0 && tripCompanionSets.map((companion, companion_index) => {
                            return (
                                <div key={companion_index}></div>
                            )
                        })}
                        {<div style={{ width: "95px" }} onClick={() => setOpen(true)}>
                            <Fab disabled={false} style={{ margin: "10px 10px 5px 10px", outline: "0px", backgroundColor: "#FFFFFF50", width: "75px", height: "75px", }}>
                                <UserPlusIcon />
                            </Fab>
                            <div style={{ color: "#C7C7C7", font: "14px/14px Futura Md BT", }}>{"+ add a travel companion"}</div>
                        </div>}
                    </div>
                    {open && <div style={{ display: "flex", position: "relative" }}>
                        <div className={classesTextField.root} style={{ width: "50%", padding: "10px" }}>
                            <EmailAddressTextFieldCompanion
                                label="Email Address"
                                required={true}
                                id="email_address_companion"
                            />
                        </div>
                        <div className={classesTextField.root} style={{ width: "25%", padding: "10px" }}>
                            <FirstNameTextFieldCompanion
                                label="First Name"
                                required={true}
                                id="first_name_companion"
                            />
                        </div>
                        <div className={classesTextField.root} style={{ width: "25%", padding: "10px" }}>
                            <LastNameTextFieldCompanion
                                label="Last Name"
                                required={true}
                                id="last_name_companion"
                            />
                        </div>
                        {emailAddressTextFieldCompanion !== undefined
                            && firstNameTextFieldCompanion !== undefined
                            && lastNameTextFieldCompanion !== undefined
                            && emailAddressTextFieldCompanion.emailAddress !== "" && !emailAddressTextFieldCompanion.error
                            && firstNameTextFieldCompanion.firstName !== "" && !firstNameTextFieldCompanion.error
                            && lastNameTextFieldCompanion.lastName !== "" && !lastNameTextFieldCompanion.error &&
                            <div
                                onClick={() => inviteHandler()}
                                style={{ cursor: "pointer", position: "absolute", right: "-100px", top: "25px", font: "18px/25px Futura Md BT", color: "#19EC9E", }}>
                                <CheckLightIcon width={30} height={30} style={{ marginRight: "10px" }} /> {"Invite"}
                            </div>}
                    </div>}
                </React.Fragment > :
                <React.Fragment>
                    <div style={{ font: "23px/25px Futura Md BT", color: "#191919", textAlign: "left", paddingBottom: "10px" }}>{"Travelers"}</div>
                    <div style={{ display: "flex", flexWrap: "wrap", paddingBottom: "25px" }}>
                        {tripCompanionSets !== undefined && tripCompanionSets.length > 0 && tripCompanionSets.map((companion, companion_index) => {
                            return (
                                <div key={companion_index}></div>
                            )
                        })}
                        {<div style={{ width: "95px" }} onClick={() => setOpen(true)}>
                            <Fab disabled={false} style={{ margin: "10px 10px 5px 10px", outline: "0px", backgroundColor: "#19191950", width: "75px", height: "75px", }}>
                                <UserPlusIcon />
                            </Fab>
                            <div style={{ color: "#191919", font: "14px/14px Futura Lt BT", }}>{"+ add a travel companion"}</div>
                        </div>}
                    </div>
                    {open && <div style={{ display: "flex", position: "relative" }}>
                        <div className={classesTextField.root} style={{ width: "50%", padding: "10px" }}>
                            <EmailAddressTextFieldCompanion
                                label="Email Address"
                                required={true}
                                id="email_address_companion"
                            />
                        </div>
                        <div className={classesTextField.root} style={{ width: "25%", padding: "10px" }}>
                            <FirstNameTextFieldCompanion
                                label="First Name"
                                required={true}
                                id="first_name_companion"
                            />
                        </div>
                        <div className={classesTextField.root} style={{ width: "25%", padding: "10px" }}>
                            <LastNameTextFieldCompanion
                                label="Last Name"
                                required={true}
                                id="last_name_companion"
                            />
                        </div>
                        {emailAddressTextFieldCompanion !== undefined
                            && firstNameTextFieldCompanion !== undefined
                            && lastNameTextFieldCompanion !== undefined
                            && emailAddressTextFieldCompanion.emailAddress !== "" && !emailAddressTextFieldCompanion.error
                            && firstNameTextFieldCompanion.firstName !== "" && !firstNameTextFieldCompanion.error
                            && lastNameTextFieldCompanion.lastName !== "" && !lastNameTextFieldCompanion.error &&
                            <div
                                onClick={() => inviteHandler()}
                                style={{ cursor: "pointer", position: "absolute", right: "-100px", top: "25px", font: "18px/25px Futura Md BT", color: "#19EC9E", }}>
                                <CheckLightIcon width={30} height={30} style={{ marginRight: "10px" }} /> {"Invite"}
                            </div>}
                    </div>}
                </React.Fragment >
            }
        </React.Fragment >

    )
}

const mapStateToProps = (state) => ({
    tripCompanionSets: state.FormBank.TimelineWizard.activeTrip.tripCompanion.sets,
    activeTripId: state.FormBank.TimelineWizard.activeTrip.tripId,
    emailAddressTextFieldCompanion: state.FormBank.emailAddressTextFieldCompanion,
    firstNameTextFieldCompanion: state.FormBank.firstNameTextFieldCompanion,
    lastNameTextFieldCompanion: state.FormBank.lastNameTextFieldCompanion,

    lang: state.Setting.lang,
    uuid: state.Member.authModal.uuid,

    switch_container: state.Member.switch_container,
})

const mapDispatchToProps = {
    fetchTripCompanionSets: Actions.fetchTripCompanionSets,
    setTripCompanion: Actions.setTripCompanion,
    cleanLastNameCompanion: Actions.cleanLastNameCompanion,
    cleanFirstNameCompanion: Actions.cleanFirstNameCompanion,
    cleanEmailAddressCompanion: Actions.cleanEmailAddressCompanion,
}

export default connect(mapStateToProps, mapDispatchToProps)(TripCompanion)

