import {
    FETCH_PUBLIC_TRIP_COST,
    FETCH_PUBLIC_TRIP_COST_API_ERROR,
    FETCH_TRIP_FAILURE,
    FETCH_TRIP_SUCCESS,
    SET_COPY_TRIP,
} from "../interfaces/types"

const initialState = {
    trip: {},
    tripId: '',
    error: '',
    copyTripId: "",
    tripCost: {
        cost: null,
        apiError: false,
    },
}

/* get trip reducer */
export default (state = initialState, action) => {
    switch (action.type) {
        case FETCH_TRIP_SUCCESS:
            return {
                ...state,
                trip: action.payload,
                    tripId: action.tripId,
                    error: ''
            };
        case SET_COPY_TRIP:
            return {
                ...state,
                copyTripId: action.copy_trip_id,
            };
        case FETCH_TRIP_FAILURE:
            return {
                ...state,
                trip: {},
                    tripId: '',
                    error: action.payload
            };
        case FETCH_PUBLIC_TRIP_COST:
            return {
                ...state,
                tripCost: {
                    ...state.tripCost,
                    cost: action.cost,
                }
            };
        case FETCH_PUBLIC_TRIP_COST_API_ERROR:
            return {
                ...state,
                tripCost: {
                    ...state.tripCost,
                    apiError: action.api_error,
                }
            };

        default:
            return {
                ...state
            }
    }
}