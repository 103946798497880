import * as Actions from '../../../../redux/actions'
import React from 'react'
import { connect } from 'react-redux'

import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Slider from '@material-ui/core/Slider'



const RangeSliderScore = connect((state) => ({
  subtypes: state.FormBank.HotelSearch.subtypes,
  isMobile: state.Setting.is_mobile,

  switchFeatureContainer: state.FormBank.FeatureFormat,
  hotelSearch: state.FormBank.HotelSearch,
  lazyLoading: state.FormBank.HotelSearch.lazyLoading,

}), {
  addSelectedSubTypeHotels: Actions.addSelectedSubTypeHotels,
})(({
  switchFeatureContainer,
  isMobile,
  subtypes,
  hotelSearch,
  lazyLoading,
  addSelectedSubTypeHotels,
  establishments,
  loVal,
  hiVal
}) => {

  const useStyles = makeStyles({
    root: {
      width: 270,
    },
  });
  
  function valuetext(value) {
    return `${value}°C`;
  }
  //console.log(params);
  const classes = useStyles();
  const [value, setValue] = React.useState([loVal, hiVal]);
  const [lowValue, setLowValue] = React.useState(loVal);
  const [highValue, setHighValue] = React.useState(hiVal);

  let subtype = null

  React.useEffect(() => {
    if (hotelSearch.selectedSubtypes !== undefined ) {
      let currValue = [loVal, hiVal]
      const selectedSubtypes = hotelSearch.selectedSubtypes!== undefined ? hotelSearch.selectedSubtypes.slice() : []

      selectedSubtypes.forEach((subtype, subtype_idx) => {
    
        if(subtype.type==="lowGuestScore"){
          const lowScore = Number(subtype.id.replace("ls-",""))
          setLowValue(lowScore)
          currValue = [lowScore, currValue[1]]
        }
    
        if(subtype.type==="highGuestScore"){
          const highScore = Number(subtype.id.replace("hs-",""))
          setHighValue(highScore)
          currValue = [currValue[0], highScore]
        }
        
      })
      setValue(currValue)
    }
}, [])

  

  const handleChange = (event, newValue) => {
    //setValue(newValue);
    setValue(newValue);

    

    
    // filter establishments by hi lo price
    // filterEstablishmentsByPrice(params.establishments, newValue)


  };

  const handleCommit = (event, newValue) => {
    console.log(newValue);
    let subtypes = hotelSearch.selectedSubtypes!== undefined ? hotelSearch.selectedSubtypes.slice() : []

    if(lowValue!==newValue[0]) {
      subtype = {
        title: "Lowest guest rating "+newValue[0],
        id: "ls-"+newValue[0],
        type: "lowGuestScore"
      } 
      subtypes = subtypes!==[] && subtypes.filter(val => val.type !== "lowGuestScore")
      setLowValue(newValue[0])

    }
    
    if(highValue!==newValue[1]) {
      subtype = {
        title: "Highest guest rating "+newValue[1],
        id: "hs-"+newValue[1],
        type: "highGuestScore"
      } 
      subtypes = subtypes!==[] && subtypes.filter(val => val.type !== "highGuestScore")
      setHighValue(newValue[1])
      
    }

    console.log(subtype);
    
    
    //let subtypes = selectedSubtypes!== undefined ? selectedSubtypes.slice().filter(val => val.type !== "query") : []

    console.log("subtypes", subtypes, hotelSearch.selectedSubtypes);
    console.log(hotelSearch.establishmentsToFilter, subtype, subtypes, hotelSearch.hotel_form_state)

    
    addSelectedSubTypeHotels(hotelSearch.establishmentsToFilter, subtype, subtypes, hotelSearch.hotel_form_state, lazyLoading)

    //setQuery("")
    //setState({});
    
}

  return (
    <div className={classes.root}>
      <div style={{ display: "flex", width: "200px", paddingLeft: "10px", paddingRight: "10px", font: "16px/16px Futura Md BT", outline: "0px", }} >
        <div style={{ flexGrow: "1", paddingTop: "10px", paddingBottom: "36px", cursor: "pointer", outline: "0px", }}>
            Guest ratings
        </div>
      </div>
      <div style={{ display: "flex", width: "200px", paddingLeft: "20px", paddingRight: "10px", font: "16px/16px Futura Md BT", outline: "0px", }} >
         <div style={{ flexGrow: "1", paddingTop: "10px", paddingBottom: "6px", cursor: "pointer", outline: "0px", }}>
            <Slider
                min={loVal}
                max={hiVal}
                value={value}
                onChange={handleChange}
                onChangeCommitted={handleCommit}
                valueLabelDisplay="auto"
                aria-labelledby="range-slider"
                getAriaValueText={valuetext}
                valueLabelDisplay="on"
            />
          </div>
      
        </div>
    </div>
  )
})
export default RangeSliderScore

