import { current } from "@reduxjs/toolkit";
import moment from "moment";
import { TimeStringToSeconds, timeToDecimal } from "../../../../utility/utility";


export const flightReducer = {
    cancelLoading:(state,action) =>{ 
        state.loading = false;
        state.reachedEnd = false;
    },
    saveFlight:(state,action) =>{ 
        state.savedFlight = state.savedFlight.concat(action.payload);
    },
    saveBookingContact:(state,action) =>{ 
        state.contactInfo = action.payload;
    },
    setExpiredFlight:(state,action) =>{
        state.showExpired = action.payload;
        state.bookingMessage = '';
        state.bookingFailed = false;  
    },
    updateDepartureFlight: (state, action) => {
        state.selectedDepartureFlight = action.payload;
        state.selectedFlight = action.payload;
        state.numberAdult = state.flightData.numberAdult;
        state.numberChild = state.flightData.numberChild;
    },
    updateFlightMode: (state, action) => {
        state.selectedFlightMode = action.payload;
    },
    updateFlightDataItem: (state, action) => {
        const type = action.payload.type;
        const value = action.payload.value;
        if(type === 'mode'){
            state.flightData.flightMode = value;
        }
        if(type === 'class'){
            state.flightData.cabinClass = value;
        }
        if(type === 'adults'){
            state.flightData.numberAdult = value;
        }
        if(type === 'children'){
            state.flightData.numberChild = value;
        }
        if(type === 'flights'){
            state.flightData.flights = value;
        }
    },
    deleteFlightCart: (state,action) => {
        state.selectedDepartureFlight = null;
        state.selectedReturnFlight = null;
        state.selectedFlight = null;
        state.numberAdult = 0;
        state.numberChild = 0;
    },
    deleteFlightReturnItem: (state,action) => {
        const index  = action.payload;
        if(state.selectedReturnFlight && state.selectedReturnFlight?.slice_data.length > 1){
            let returns = state.selectedReturnFlight.slice_data;
            returns.splice(index, 1);
            state.selectedReturnFlight.slice_data = returns;
        }
    },

    deleteFlightDepartureItem: (state,action) => {
        const index  = action.payload;
        if(state.selectedDepartureFlight  && state.selectedDepartureFlight?.slice_data.length > 1){
            let returns = state.selectedDepartureFlight.slice_data;
            returns.splice(index, 1);
            state.selectedDepartureFlight.slice_data = returns;
        }
    },

    resetFlightPage: (state, action) => {
        state.page = 1;
        state.itineraries = [];
        state.searchData = [];
        state.flightListData = [];
        state.airlines = [];
        state.maxPrice = null;
        state.minPrice = null;
        state.selectedDepartureFlight = null;
        state.selectedReturnFlight = null;
        state.maxDurationSeconds = null;
        state.minDurationSeconds = null;
        state.numberAdult = 0;
        state.numberChild = 0;
        state.savedFlight = []
    },
    updateFlightPage: (state, action) => {
        state.page += 1;
    },
    updateReturnFlight: (state, action) => {
        state.selectedReturnFlight = action.payload;
        state.selectedFlight = action.payload;
        state.numberAdult = state.flightData.numberAdult;
        state.numberChild = state.flightData.numberChild;
     },
    updateFlightData: (state, action) => {
        state.flightData = action.payload;
    },
    filterByPrices: (state, action) => {
        const values = action.payload;
        let list = state.flightListData;
        if (values.length > 0) {
            state.flightListData = list.map((item) => {
                if (item.price_details.display_total_fare_per_ticket >= values[0] && item.price_details.display_total_fare_per_ticket <= values[1]) {
                    item.prices = true;
                }
                else {
                    item.prices = false;
                }
                return item;
            })
        }
    },
    filterByDuration: (state, action) => {
        const values = action.payload;
        let list = state.flightListData;
        if (values.length > 0) {
            state.flightListData = list.map((item) => {
                let totalDuration = 0;
                item.slice_data.forEach((slice, index) => {
                    totalDuration += TimeStringToSeconds(slice.info.duration);
                })
                if (totalDuration >= values[0] && totalDuration <= values[1]) {
                    item.duration = true;
                }
                else {
                    item.duration = false;
                }
                return item;
            })
        }
    },
    filterByStops: (state, action) => {
        let list = state.flightListData;
        state.flightListData = list.map((item) => {
            item.slice_data.forEach((slice, index) => {
                if (action.payload === 3) {
                    if (slice.flight_data.length > 2) {  //2+stops
                        item.stops = !item.stops;
                    }
                }
                else {
                    if (slice.flight_data.length === action.payload) {   //1 and 2 stops
                        item.stops = !item.stops;
                    }
                }

            })
            return item;
        })
    },
    filterByNonStop: (state, action) => {
        let list = state.flightListData;
        state.flightListData = list.map((item) => {
            item.slice_data.forEach((slice, index) => {
                if (slice.flight_data.length === 1) {   //1 and 2 stops
                    item.stops = true;
                }
                else{
                    item.stops = false;
                }

            })
            return item;
        })
    },
    filterByTime: (state, action) => {
        const type = action.payload.type;
        const range = action.payload.value;
        const index = action.payload.index;
        const flightMode = action.payload.flightMode;
        let list = state.flightListData;

        if (range.length > 0) {
            state.flightListData = list.map((item) => {
                const value = flightMode === "multi_destination" ? item.slice_data[index] : item.slice_data[0];
                let departureTime = timeToDecimal(value.departure.datetime.time_24h) || 0;
                let arrivalTime = timeToDecimal(value.arrival.datetime.time_24h) || 0;
                if (type === 'departure') {
                    if (departureTime >= range[0] && departureTime <= range[1]) {
                        item.times = true;
                    }
                    else {
                        item.times = false;
                    }
                }
                if (type === 'arrival') {
                    if (arrivalTime >= range[0] && arrivalTime <= range[1]) {
                        item.times = true;
                    }
                    else {
                        item.times = false;
                    }

                }
                return item;
            })
        }
    },
    filterByAirline: (state, action) => {
        let list = state.flightListData;
        let airlines = state.airlines;
        let index = action.payload;
        //airline array
        const selectedAirline = airlines[index];
        selectedAirline.show = !selectedAirline.show;
        state.airlines = airlines;

        ///data array
        state.flightListData = list.map((item) => {
            item.slice_data.forEach((slice, index) => {
                if (selectedAirline.code === slice.airline.code) {   //same airline
                    item.airline = !item.airline;
                }
            })
            return item;
        })
    },
    sortFlightList: (state, action) => {
        const list = state.flightListData;
        if (action.payload === 'Cheapest') {
            list.sort((a, b) => a.price_details.display_total_fare_per_ticket - b.price_details.display_total_fare_per_ticket);
        }
        if (action.payload === 'Quickest') {
            list.sort((a, b) => {
                let aTotalDuration = 0, bTotalDuration = 0;
                a.slice_data.forEach((slice, index) => {
                    aTotalDuration += TimeStringToSeconds(slice.info.duration);
                })

                b.slice_data.forEach((slice, index) => {
                    bTotalDuration += TimeStringToSeconds(slice.info.duration);
                })

                return aTotalDuration - bTotalDuration;
            });
        }
        if(action.payload === 'Best'){
            list.sort((a,b) => {        
                let aTotalDuration = 0, bTotalDuration = 0;
                a.slice_data.forEach((slice, index) => {
                    aTotalDuration += TimeStringToSeconds(slice.info.duration);
                })

                b.slice_data.forEach((slice, index) => {
                    bTotalDuration += TimeStringToSeconds(slice.info.duration);
                })

                return  (aTotalDuration * a.price_details.display_total_fare_per_ticket) - (bTotalDuration * b.price_details.display_total_fare_per_ticket) ;
            })

        }
        if (action.payload === 'Earliest') {
            list.sort((a, b) => new moment(a.slice_data[a.slice_data.length - 1].arrival.datetime.date_time) - new moment(b.slice_data[b.slice_data.length - 1].arrival.datetime.date_time));
        }
    },
    clearFlightFilters: (state, action) => {
        let list = state.flightListData;
        let airlines = state.airlines;
        state.airlines = airlines.map((airline) => {
            airline.show = true;
            return airline;
        })
        state.flightListData = list.map((flight) => {
            flight.airline = true;
            flight.stops = true;
            flight.duration = true;
            flight.prices = true;
            flight.times = true;
            return flight;
        })
    },
    clearNonStop: (state, action) => {
        let list = state.flightListData;
        state.flightListData = list.map((flight) => {
            flight.stops = true;
            return flight;
        })
    },
    clearFlightBooking: (state, action) => {
       state.bookingSuccess = false;
       state.bookingData = null;
    },
}