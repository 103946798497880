import "./TripImageBar.css"

import * as Actions from '../../../redux/actions'

import {
    TripNameTextField,
    TripStartDateTextField
} from '../Common/FormComponentsBank'
import { image_boxing, loadingImage } from "../Common/RegExValidate"

import { CameraSolidIcon, DeleteIcon, PrintIcon, ShareIcon } from "../Common/SVGBank"
import CircularProgress from '@material-ui/core/CircularProgress'
import HandleName from "../Common/Profile/HandleName"
import { Image } from "react-bootstrap"
import PropTypes from "prop-types"
import ReCalculateTripCostTimelineWizard from '../Common/TimelineWizard/ReCalculateTripCostTimelineWizard'
import React from 'react'
import { URL_REPLACE } from "../Common/RegExValidate"
import { connect, useDispatch } from 'react-redux'
import { member_area_router } from "../Common/RegExValidate"
import { useHistory } from "react-router-dom"
import { Star } from "../Common/MemberActions/Star"
import { Edit } from "@material-ui/icons"
import ShareTripDialog from "../Common/ShareTripDialog"
import DeleteTripEllipsis from "../Common/Trip/DeleteTripEllipsis"
import DeleteDialog from "../Common/DeleteDialog"
import { setTimeLineStatus, setTripToTimeline } from "../../../redux/actions"

/* top bar component */
const TripImageBar_ = ({ getPublicTrip, logo_width, switch_container, activeTrip, uploadTripImage, getSelectedTripInformation, trip_public, trip_private, lang, screenSize, from, uuidPublic, uuidPrivate, tripImage }) => {
    const history = useHistory()
    const dispatch  = useDispatch()
    const goesTripPage = (tripId, tripName) => {
        const url = "/trip/" + tripName.replace(URL_REPLACE, "-") + "/" + tripId;
        history.push(url.toLowerCase())
        getPublicTrip(tripId, lang)
    }

    /*  set selected trip */
    const tripMemberLinkHandler = (uuid, id) => {
        const url = "/muver/trip/" + id;
        history.push(url.toLowerCase())
    }
    const [imageFileInput, setImageFileInput] = React.useState(null)

    let topInnerImageHeight = ""
    let topImageHeight = ""
    if (!isNaN(screenSize)) {
        topInnerImageHeight = screenSize / 9 + 'px'
        topImageHeight = screenSize < 500 ? screenSize / 1.8 : screenSize / 2.5
    }
    const trip = from === "trip_private" ? trip_private : trip_public

    const uuid = from === "trip_private" ? uuidPrivate : uuidPublic
    let containerH = 500
    let imageH = 120
    if (topImageHeight !== undefined) {
        containerH = topImageHeight + "px"
        imageH = topInnerImageHeight
    }

    //  trip.nav.map(trip => ()=>console.log(trip))
    // trip.nav.map(x=>console.log (x.tripId))
    let nav_previous = ""
    let nav_next = ""
    let nav_previous_name = ""
    let nav_next_name = ""
    if (trip.nav !== undefined && trip.nav.length > 0) {
        nav_previous = trip.nav !== undefined && trip.nav.length > 0 && trip.nav[0] !== undefined && trip.nav[0] !== null && trip.nav[0].tripID !== undefined ? trip.nav[0].tripID : ""
        nav_next = trip.nav !== undefined && trip.nav.length > 0 && trip.nav[1] !== undefined && trip.nav[1] !== null && trip.nav[1].tripID !== undefined ? trip.nav[1].tripID : ""
        nav_previous_name = trip.nav !== undefined && trip.nav.length > 0 && trip.nav[0] !== undefined && trip.nav[0] !== null && trip.nav[0].tripName !== undefined ? trip.nav[0].tripName : ""
        nav_next_name = trip.nav !== undefined && trip.nav.length > 0 && trip.nav[1] !== undefined && trip.nav[1] !== null && trip.nav[1].tripName !== undefined ? trip.nav[1].tripName : ""
    }
    const tripHeaderImage = from === "trip_private" ? activeTrip.tripImage : trip_public.tripHeaderImage
    const srcTripImage = tripHeaderImage !== undefined && tripHeaderImage !== null ? image_boxing(tripHeaderImage, screenSize, topImageHeight) : loadingImage.src
    const display_letters = screenSize > 1024 ? 29 : 20
    const display_letters_owner = screenSize > 1024 ? 29 : 10

    const printTrip = () => {
        window.print()
    };

    const goToDestination = () => {
        const tripDestinations = trip?.tripDestinations
        if(tripDestinations && tripDestinations?.length > 0){
            const destination = tripDestinations[0];
            const url = "/destination/" + destination?.partialUri + "/" + 1 + "/" + destination?.id;

            dispatch(setTripToTimeline(trip?.tripID, uuidPrivate))
            dispatch(setTimeLineStatus(true))
            history.push(url.toLowerCase());
        }
      
    };


    return (
        <div className="position-relative z_index_1">
            {tripHeaderImage !== undefined && tripHeaderImage !== null && <Image
                src={srcTripImage}
                alt="trip image" width={screenSize} height={topImageHeight} />}
            {tripHeaderImage !== undefined && tripHeaderImage !== null && <div className="mask_block" style={{ height: containerH }}></div>}



            {from !== "trip_private" && tripHeaderImage !== undefined &&
                <div className="z_index_3 mask_avatar_btn text-center">
                    <Image className="rounded-circle" src={trip.ownerAvatar !== undefined ? image_boxing(trip.ownerAvatar, imageH, imageH, "muvimages/user_avatar/armando.jpg") : ""} alt="Avatar" style={{ width: imageH, height: imageH }} />
                    {trip.ownerHandle !== undefined && trip.ownerHandle !== "" && <HandleName muverHandle={trip.ownerHandle} styleHandle={{ cursor: "pointer" }} classNameHandle={"futura_md_bt_26_10_white margin_top_20_avatar text-center"} />}
                </div>}
            <div className="z_index_3 mask_title_btn">
                <div className="futura_hv_bt_60_60_white box_1 text-center" style={{ height: "100px" }} >{trip.tripName !== undefined && trip.tripName.length > display_letters + 1 ? trip.tripName.substr(0, display_letters) + "..." : trip.tripName}</div>
            </div>

            {trip && Object.keys(trip)?.length > 0 && <div style={{
                cursor:'pointer',
                position: "absolute", top: "60px", right: "84px", zIndex: 3, display: 'flex', gap: '15px'
            }} >

                <span>
                <DeleteDialog from={"Delete Trip"} tripPage={true} tripPageTrip={trip}  />
                </span>
                <span>
                    <Edit onClick={goToDestination} style={{ height: 25, width: 25, color: 'white' }} width={25} height={25} />
                </span>
                <span onClick={printTrip}>
                    <PrintIcon fill={'white'} width={25} height={25} />
                </span>
                <span>
                    <ShareTripDialog inline={true} tripPage={true} tripPageTrip={trip} />
                </span>


            </div>}
 

        </div>
    )
}

TripImageBar_.propTypes = {
    trip_public: PropTypes.object.isRequired,
    trip_private: PropTypes.object.isRequired,
    tripId: PropTypes.string.isRequired,
    lang: PropTypes.string.isRequired,
}

const mapStateToProps = state => {
    return {
        trip_public: state.PublicTrip.trip,
        trip_private: state.Member.memberArea.selectedTrip,
        activeTrip: state.FormBank.TimelineWizard.activeTrip,

        tripId: state.PublicTrip.tripId,
        lang: state.Setting.lang,
        screenSize: state.Setting.htmlBody.bodyClientWidth,
        uuidPublic: state.Social.member_uuid,
        uuidPrivate: state.Member.authModal.uuid,
        logo_width: state.Setting.appMenu.logo.width,

        tripImage: state.Member.memberArea.tripImage,
        switch_container: state.Member.switch_container,
    }
}

const mapDispatchToProps = {
    getPublicTrip: Actions.getPublicTrip,
    getSelectedTripInformation: Actions.getSelectedTripInformation,
    setTripToTimeline: Actions.setTripToTimeline,
    uploadTripImage: Actions.uploadTripImage,
}
export const TripImageBar = connect(mapStateToProps, mapDispatchToProps)(TripImageBar_)
