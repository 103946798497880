import * as Actions from '../../../redux/actions'

import { CheckLightIcon, CircleDownIcon, CircleUpIcon } from "../Common/SVGBank"

import MenuItem from '@material-ui/core/MenuItem'
import React from 'react'
import Typography from '@material-ui/core/Typography'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'

const BarsSubTypeMenuItem = connect((state) => ({
    selectedSubtypes: state.Destination.destination.selectedSubtypes,
    lazyLoading: state.Destination.destination.lazyLoading,
    switchFeatureContainer: state.FormBank.FeatureFormat,

}), {
    addSelectedSubTypeDestination: Actions.addSelectedSubTypeDestination,
    deleteSelectedSubTypeDestination: Actions.deleteSelectedSubTypeDestination,
    handleGoupeSubTypeDestination: Actions.handleGoupeSubTypeDestination,
})(({
    selectedSubtypes,
    lazyLoading,
    addSelectedSubTypeDestination,
    deleteSelectedSubTypeDestination,
    handleGoupeSubTypeDestination,
    subtypeGroup,
    switchFeatureContainer,
}) => {
    const switchFeatureContainerColorLight = switchFeatureContainer.color + "35"
    const barsStyles = makeStyles(theme => ({
        root: {
            display: 'flex',
            '& .MuiListItem-root': {
                boxShadow: "0 0 0 0 rgba(0,123,255,.25)",
                font: "16px/16px Futura Md BT",
                color: "#707070",
            },
        },
        buttonText: {
            color: switchFeatureContainer.color,
            textAlign: "left",
            font: "16px/22px Futura Md BT",
            letterSpacing: "0",
            padding: "5px",
            '&:focus': {
                border: "0",
                outline: "0px",

            }
        },
        timesDiv: {
            marginRight: "10px",
            marginLeft: "115px",
            display: "inline-block",
            textAlign: "right",
        },
        popper: {
            zIndex: "3",
            marginTop: "20px",
            font: "16px/16px Futura Md BT",
            '& .MuiListItem-root:hover': {
                backgroundColor: switchFeatureContainerColorLight,
            },
        },
        paddingLeft30: {
            paddingLeft: "30px"
        },
        backgroundSelection: {
            '&:hover': {
                backgroundColor: switchFeatureContainer.color
            }
        },
        paper: {
            marginRight: theme.spacing(2),
        },
        outline: {
            '& .MuiList-root .MuiList-padding': {
                outline: "0px",
            }
        },
    }))
    const [open, setOpen] = React.useState(false)

    const classesBars = barsStyles()

    const handleToggle = () => {
        setOpen(prevOpen => !prevOpen)
    }

    return (
        <div className="position-relative">
            <div>
                {subtypeGroup.subtypes !== undefined
                    && subtypeGroup.subtypes.length > 0 && switchFeatureContainer.featureId !== '4' &&
                    <div style={{ display: "flex", width: "200px", paddingLeft: "10px", paddingRight: "10px", font: "16px/16px Futura Md BT", outline: "0px", }} >
                        <div style={{ flexGrow: "1", paddingTop: "10px", paddingBottom: "6px", cursor: "pointer", outline: "0px", }}
                            onClick={() => { handleGoupeSubTypeDestination(subtypeGroup.subtypes, lazyLoading, selectedSubtypes) }}>{subtypeGroup.title}
                        </div>
                    </div>
                }
                {subtypeGroup.subtypes !== undefined
                    && subtypeGroup.subtypes.length > 0
                    && subtypeGroup.subtypes.map((subtype, subtype_index) => {
                        return (
                            open || subtype_index < 15 ?
                                <MenuItem key={subtype_index}
                                    className={classesBars.outline}
                                    onClick={
                                        () => {
                                            selectedSubtypes.find(val => val.id === subtype.id) ?
                                                deleteSelectedSubTypeDestination(subtype, lazyLoading, selectedSubtypes)
                                                :
                                                addSelectedSubTypeDestination(subtype, lazyLoading, selectedSubtypes)
                                        }}
                                >
                                    <Typography variant="inherit" noWrap style={{ width: "200px", font: "15px/15px Futura Lt BT" }}>
                                        {subtype.title}
                                    </Typography>
                                    {selectedSubtypes.find(val => val.id === subtype.id) && <CheckLightIcon fill={switchFeatureContainer.color} />}
                                </MenuItem>
                                : ""
                        )
                    })}

            </div>

            <div className="d-inline-block position-absolute" style={{ zIndex: "4", right: "2px", bottom: "3px" }}>
                {subtypeGroup.subtypes !== undefined
                    && subtypeGroup.subtypes.length > 15 && open && <div onClick={() => handleToggle()}> <CircleUpIcon /></div>}
                {subtypeGroup.subtypes !== undefined
                    && subtypeGroup.subtypes.length > 15 && !open && <div onClick={() => handleToggle()}> <CircleDownIcon /></div>}
            </div>
        </div>
    )
})

export default BarsSubTypeMenuItem