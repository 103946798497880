import './HotelImageSlider.css'

import * as Actions from '../../../redux/actions'

import { ArrowLeftIcon, ArrowRightIcon } from "../Common/SVGBank"
import React, { Component } from 'react'
import Image  from '../Common/Image'

import Slider from 'react-slick'
import { useLightbox } from "simple-react-lightbox" // Import Simple React Lightbox
import { connect } from 'react-redux'

import { priceline_image_boxing } from '../Common/RegExValidate'

const Entities = require('html-entities').XmlEntities

const SliderArrowLeft = ({ className, to, onClick }) => (
	<button type="button" onClick={onClick} className={`button button--text button--icon ${className}`} aria-label={to}>
		<ArrowLeftIcon className="icon" icon={to} style={{
			height: "10px",
			width: "10px",
			transform: 'translate(10px, -77px)',
		}} />
	</button>
)
const SliderArrowRight = ({ className, to, onClick }) => (
	<button type="button" onClick={onClick} className={`button button--text button--icon ${className}`} aria-label={to}>
		<ArrowRightIcon className="icon" icon={to} style={{
			height: "10px",
			width: "10px",
			transform: 'translate(-10px, -77px)'
		}} />
	</button>
)

class HotelImageSlider_ extends Component {
	
	render() {
		const { blog, screenSize, is_mobile, images, setSlideIndex, setSliderIndex, timelineStatus } = this.props
		const entities = new Entities()
		const sliderArrowRight = <SliderArrowRight />
		let heightContainer = 105
		let heightImage = 100
		let widthImage = 100
	/*	if (!isNaN(screenSize)) {
			heightContainer = screenSize * 0.82 / 4 + 100
			heightImage = is_mobile ? 150 : Math.ceil(screenSize * 0.82 / 4)
			widthImage = is_mobile ? 170 : Math.ceil(screenSize * 0.82 / 4) - 14
		} */
		const display_element_length =
			screenSize > 1724 ? 11 :
				screenSize >= 1025 ? 9 :
					screenSize > 500 ? 5 : 5
		const left_element = (images && images.length < display_element_length ? "left_element" : "") + (images && timelineStatus ? "timeline-image":"")
		const infiniteValue = images && images.length > display_element_length ? true : false
		const arrowsValue = images && images.length > display_element_length ? true : false

		let padding_l = 15
		let padding_r = 15

		if (!isNaN(is_mobile)) {
			padding_l = is_mobile ? "1" : "15"
			padding_r = is_mobile ? "1" : "15"
		}

		const setting = {
			touchMove: false,
			dots: false,
			infinite: infiniteValue,
			arrows: arrowsValue,
			speed: 500,
			lazyLoad: false,
			autoplay: false,
			slidesToShow: display_element_length,
			slidesToScroll: 3,
			pauseOnHover: true,
			//centerMode: true,
			//prevArrow: <SliderArrowLeft to="prev" />,
			//nextArrow: sliderArrowRight,			
			arrows: false,
			padSlides: true,
			draggable: true,
			swipeToSlide: true,
			responsive: [
				{
					breakpoint: 1724,
					settings: {
						touchMove: true,
						slidesToShow: display_element_length,
						slidesToScroll: 3,
						arrows: false,
					}
				},
				{
					breakpoint: 1025,
					settings: {
						touchMove: true,
						slidesToShow: display_element_length,
						slidesToScroll: 3,
						arrows: false,
					}
				},
				{
					breakpoint: 500,
					settings: {
						touchMove: true,
						slidesToShow: display_element_length,
						slidesToScroll: 2,
						arrows: false,
					}
				},
			]
		}

		
		return (
			<React.Fragment>
			{images !== undefined && images !== null &&  
				<div style={{
					backgroundColor: "white", paddingTop: 10,
				}}>
					<Slider {...setting} className={left_element} style={{ height: heightContainer }}>
						{images.length > 0 && images.map((image, index) => {
							return (
								<div key={index} style={{width: widthImage, height: heightImage, marginRight:4}}
									onClick={e => {console.log(index); setSliderIndex(index)}}
									>
									<Image fallbackSrc={priceline_image_boxing("https://amhqajbken.cloudimg.io/v7/_dev_/commom/loading.gif?func=crop&w=" + Math.floor(widthImage) + "&h=" + Math.floor(heightImage), widthImage, heightImage)}
										key={index} 
										style={{
											//margin: 4,
											width: widthImage, objectFit: "cover", objectPosition: "center", 
											height: heightImage
										}}
										src={priceline_image_boxing(image, widthImage, heightImage)}
										//className="img_fit_cover d-block w-100"
										alt={image.description} />
								</div>
								
							)
						})}
					</Slider>

				</div>}
			</React.Fragment>
			
		)
	}
}


const mapStateToProps = (state) => {
	return {
		screenSize: state.Setting.htmlBody.bodyClientWidth,
		lang: state.Setting.lang,
		//blog: state.Home.homepage.blog,
		images: state.FormBank.HotelSearch.establishment.sliderImages,
		is_mobile: state.Setting.is_mobile,
		
	}

}
const HotelImageSlider = connect(mapStateToProps, 
	{
		setSliderIndex: Actions.setSliderIndex
	})(HotelImageSlider_)
export default HotelImageSlider