import { Backdrop, CircularProgress, Modal } from '@material-ui/core'
import React from 'react'
import { TimesIcon } from '../../Common/SVGBank'
import { useSelector, useDispatch } from 'react-redux'
import { PersonalizedModalUseStyles } from '../../FeaturePage/personalizedTrip/personalizedTripStyles'


export const HotelFlightSaveModal = ({ navigateBooking,toggleModal, showModal, addToTimeline,type }) => {

    const [creating, setCreating] = React.useState(false)

    const lang = useSelector(state => state.Setting.lang)
    const uuid = useSelector(state => state.Member.authModal.uuid)

    const activeTrip = useSelector(state => state.FormBank.TimelineWizard.activeTrip);
    const activeTripId = activeTrip?.tripId;
    const destination = useSelector(state => state.Destination.destination)
    const tripName = activeTrip?.tripName;
    const classes = PersonalizedModalUseStyles();
    const dispatch = useDispatch()
    let arrow_icon_size = 15
    let arrow_font = "14px/14px Futura Md BT"


    const createTrip = async (newtrip) => {
        setCreating(true)
        if(newtrip){
           await addToTimeline(null)
        }
        else{
            await addToTimeline(activeTripId)
        }
        setCreating(false)
        clearData()
    }


    const clearData = () => {
        setCreating(false)
        toggleModal()
    }

    return (
        <React.Fragment>
            <Modal
                open={showModal}
                onClose={clearData}
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 1000,
                }}
            >
                <div style={{
                    top: '40%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)'

                }} className={classes.paper}>

                    <Backdrop className={classes.backdrop} open={creating} >
                        <CircularProgress color="inherit" />
                    </Backdrop>

                    <div className="d-flex">
                        <div style={{
                            font: "24px/24px Futura Hv BT",
                            color: "#191919",
                            paddingLeft: '0px',
                            paddingBottom: '30px',
                            paddingTop: '15px',
                            width: "90%",
                            marginRight: "5%",
                            lineHeight: '32px'
                        }}>

                        </div>
                        <div
                            style={{
                                cursor: "pointer",
                            }}
                            onClick={clearData}><TimesIcon style={{ marginTop: "15px" }} width={'20'} height={'20'} /></div>

                    </div>


                    <div className="d-flex">
                        <div style={{
                            font: "24px/24px Futura Lt BT",
                            color: "#191919",
                            paddingLeft: '0px',
                            paddingBottom: '40px',
                            paddingTop: '40px',
                            width: "70%",
                            marginRight: "5%",
                            lineHeight: '32px',
                            textAlign: 'center',
                            margin: '0 auto'
                        }}>

                            Would you like to save this {type} to your current active trip:
                            <span style={{
                                font: "24px/24px Futura Hv BT",
                                color: "#191919",
                                paddingLeft: '0px',
                                paddingBottom: '30px',
                                paddingTop: '15px'
                            }}>{tripName} </span> or create a new trip
                        </div>

                    </div>



                    <div style={{margin:'0  auto', width: "100%",textAlign:'center', paddingBottom:20}}>
                        <button className='recapButtons' style={{marginRight:40, background: '#DFDFDF', color: '#7F7F7F', font: '14px/14px Futura Hv BT' }} onClick={() => createTrip(false)}>
                          Add to current trip 
                        </button>

                        <button className='recapButtons' style={{ background: '#19BC9B', color: 'white', font: '14px/14px Futura Hv BT' }} onClick={() => createTrip(true)}>
                             Create new trip 
                        </button>
                    </div>



                </div>

            </Modal>

        </React.Fragment>
    )
}