import moment from 'moment';

/*  reformat trip range date function
    param start date 
    param duration 
    return range_date 
    */
export const ReformatTripRangeDate = (startDate, duration) => {
    const startTripDate = moment(startDate, 'YYYY-MM-DD').format("MMM D[,] YYYY")
    const endTripDate = moment(startDate, 'YYYY-MM-DD').add((duration - 1), 'days').format('MMM D[,] YYYY')
    const start_date_year = startTripDate.substring(startTripDate.length - 4).replace(/,/g, '');
    const start_date_month = startTripDate.substring(0, 3).replace(/,/g, '');
    const start_date_day = startTripDate.substring(4, 6).replace(/,/g, '');
    const end_date_year = endTripDate.substring(endTripDate.length - 4).replace(/,/g, '');
    const end_date_month = endTripDate.substring(0, 3).replace(/,/g, '');
    const end_date_day = endTripDate.substring(4, 6).replace(/,/g, '');

    const range_date =
        startTripDate !== "" && endTripDate !== "" ?
            (start_date_year === end_date_year ?
                ((start_date_month === end_date_month) ?
                    ((start_date_day === end_date_day) ?
                        (start_date_month + " " + start_date_day + ", " + start_date_year)
                        : (start_date_month + " " + start_date_day + " - " + end_date_day + ", " + end_date_year))
                    : (start_date_month + " " + start_date_day + " - " + end_date_month + " " + end_date_day + ", " + end_date_year))
                : (startTripDate + ' - ' + endTripDate))
            : endTripDate
    return range_date
}

export const ReformatTimelineTripRangeDate = (startDate, duration) => {
    const startTripDate = moment(startDate, 'YYYY-MM-DD').format("MMM D[,] YYYY")
    const endTripDate = moment(startDate, 'YYYY-MM-DD').add(duration, 'days').format('MMM D[,] YYYY')
    return startTripDate + " 🡪 " + endTripDate
}

export const ReformatDateFromDatePicker = (date) => {
    return moment(date).format("YYYY-MM-DD")
}