import * as Actions from '../../../redux/actions'

import { AngleRightIcon } from "../Common/SVGBank"
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import React from 'react'
import { URL_REPLACE } from "../Common/RegExValidate"
import { connect } from 'react-redux'
import { list_container_mode } from '../Common/RegExValidate'
import { makeStyles } from '@material-ui/core/styles'
import { member_area_router } from "../Common/RegExValidate"
import { useHistory } from "react-router-dom"

const Breadcrumb_ = ({
    createMultipleDestinationTrip,
    destination,
    isMobile,
    lang,
    activeTrip,
    uuid,
    activeDestinationId,
    setSwitchContainer,
    switchFeatureContainer,
    setPageYOffset,
    switch_container,
    setTripDisplayMode,
    activeFeatureId,
}) => {
    const history = useHistory()
    const goesToDestinationPage = (id, title, uri) => {
        const url = "/destination/" + uri +  "/" + 1 + "/"  + id;
        history.push(url.toLowerCase())
        setPageYOffset(0)
        setTripDisplayMode(list_container_mode.timeline)
        setSwitchContainer(member_area_router.destination)
    }

    const goesToHomePage = () => {
        setSwitchContainer(member_area_router.home)
        history.push("/home")
    }

    const breadsrumbsUseStyles = makeStyles(theme => ({
        root: {
            '& .MuiSvgIcon-root': {
                color: switchFeatureContainer.color,
                backgroundColor: "rgb(25, 188, 155, 0.2)",
            },
        },
    }));
    const classes = breadsrumbsUseStyles()
    const { breadcrumb, generalInfo, activeId } = destination
    const dataToCreateTrip = {
        destinations: [
            {
                destinationId: activeId,
                startDateFormat: "",
                duration: "3",
                startDate: "",
            }
        ]
    }
    let fontSize = "16"
    if (!isNaN(isMobile)) {
        fontSize = isMobile ? "9" : "16"
    }
    return (

        <React.Fragment>

            { switch_container === member_area_router.memberAreaTrip ?
                <div className="d-flex flex-wrap"
                    style={{
                        paddingTop: "10px",
                        paddingBottom: "10px",
                    }}
                >
                    {generalInfo !== undefined && <div style={{
                        font: fontSize + "px/19px Futura Md BT",
                        letterSpacing: "0",
                        paddingLeft: "10px",
                        paddingRight: "10px",
                        flexGrow: "1",
                        color: "#2C2C2C",
                        wordWrap: "break-word",
                    }}>

                        <Breadcrumbs maxItems={8} separator={<AngleRightIcon fill={switchFeatureContainer.color} />} className={classes.root}>
                            <div className="d-inline-block" style={{
                                color: switchFeatureContainer.color,
                                font: fontSize + "px/19px Futura Md BT",
                                cursor: "pointer",
                            }}
                                onClick={() => goesToHomePage()}
                            > {'home'}</div>
                            {
                                breadcrumb !== undefined && breadcrumb.length > 0
                                && breadcrumb.map((destination, idx) => {
                                    return (
                                        activeDestinationId !== destination.id ?
                                            <div key={idx}
                                                className="d-inline-block"
                                                style={{
                                                    cursor: "pointer",
                                                    font: fontSize + "px/19px Futura Md BT",
                                                    color: switchFeatureContainer.color,
                                                }}
                                                onClick={() => goesToDestinationPage(destination.id, destination.title,destination.partialUri)}>
                                                {destination.title}
                                            </div>
                                            :
                                            <div key={idx}
                                                className="d-inline-block"
                                                style={{
                                                    color: switchFeatureContainer.color,
                                                }}>
                                                {destination.title}
                                            </div>

                                    )
                                })
                            }
                        </Breadcrumbs>
                    </div>}
                </div>
                :
                <div className="d-flex flex-wrap"
                    style={{
                        paddingTop: "10px",
                        paddingBottom: "10px",
                    }}
                >
                    {generalInfo !== undefined && <div style={{
                        font: fontSize + "px/19px Futura Md BT",
                        letterSpacing: "0",
                        paddingLeft: "10px",
                        paddingRight: "10px",
                        flexGrow: "1",
                        color: "#FFFFFF",
                        wordWrap: "break-word",
                    }}>

                        <Breadcrumbs maxItems={8} separator={<AngleRightIcon fill={switchFeatureContainer.color} />} className={classes.root}>
                            <div className="d-inline-block" style={{
                                color: switchFeatureContainer.color,
                                font: fontSize + "px/19px Futura Md BT",
                                cursor: "pointer",
                            }}
                                onClick={() => goesToHomePage()}
                            > {'home'}</div>
                            {
                                breadcrumb !== undefined && breadcrumb.length > 0
                                && breadcrumb.map((destination, idx) => {
                                    return (
                                        activeDestinationId !== destination.id ?
                                            <div key={idx}
                                                className="d-inline-block"
                                                style={{
                                                    cursor: "pointer",
                                                    font: fontSize + "px/19px Futura Md BT",
                                                    color: switchFeatureContainer.color,
                                                }}
                                                onClick={() => goesToDestinationPage(destination.id, destination.title,destination.partialUri)}>
                                                {destination.title}
                                            </div>
                                            :
                                            <div key={idx}
                                                className="d-inline-block"
                                                style={{
                                                    color: switchFeatureContainer.color,
                                                }}>
                                                {destination.title}
                                            </div>

                                    )
                                })
                            }
                        </Breadcrumbs>
                    </div>}
                    <div style={{
                        font: fontSize + "px/19px Futura Md BT",
                        letterSpacing: "0",
                        color: switchFeatureContainer.color,
                        paddingLeft: "10px",
                        paddingRight: "60px",
                        wordWrap: "break-word",
                        cursor: "pointer",
                        zIndex: "2",
                        backgroundColor: "var(--mainWhite)",
                    }}
                        onClick={() => createMultipleDestinationTrip(uuid, lang, dataToCreateTrip, activeTrip.tripId)}
                    >
                        {generalInfo !== undefined && generalInfo !== null && "Start a trip in " + generalInfo.name}
                    </div>
                </div>}
        </React.Fragment>
    )
}


const mapStateToProps = state => {
    return {
        destination: state.Destination.destination,
        isMobile: state.Setting.is_mobile,
        lang: state.Setting.lang,
        activeDestinationId: state.Destination.destination.activeId,
        uuid: state.Member.authModal.uuid,
        activeTrip: state.FormBank.TimelineWizard.activeTrip,
        activeFeatureId: state.Feature.feature.activeId,
        
    switch_container: state.Member.switch_container,
        switchFeatureContainer: state.FormBank.FeatureFormat,
    }
}

const mapDispatchToProps = {

    setPageYOffset: Actions.setPageYOffset,
    setSwitchContainer: Actions.setSwitchContainer,
    setTripDisplayMode: Actions.setTripDisplayMode,

    createMultipleDestinationTrip: Actions.createMultipleDestinationTrip,

}

const Breadcrumb = connect(mapStateToProps, mapDispatchToProps)(Breadcrumb_)
export default Breadcrumb
