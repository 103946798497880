import {
    ADD_TRAVEL_HOW,
    ADD_TRAVEL_HOWLONG,
    ADD_TRAVEL_INTERESTS,
    ADD_TRAVEL_WHAT,
    ADD_TRAVEL_WHERE,
    FETCH_MUVER_INTERESTS_FAILURE,
    FETCH_MUVER_INTERESTS_SELECTED_SETS,
    FETCH_MUVER_INTERESTS_SUCCESS,
    GetAsyncHelper,
    PostAsyncHelper,
    REMOVE_TRAVEL_HOW,
    REMOVE_TRAVEL_HOWLONG,
    REMOVE_TRAVEL_INTERESTS,
    REMOVE_TRAVEL_WHAT,
    REMOVE_TRAVEL_WHERE,
    SWITCH_PROFILE_CONTAINER,
} from "../interfaces/types"

import Axios from 'axios';
import { environment } from "../../Environments/environment";

/* landing page */
export const setSwitchProfileContainer = (switch_container) => dispatch => {
    dispatch({
        type: SWITCH_PROFILE_CONTAINER,
        switch_container: switch_container,
    })
}

/* Add travel general add */
export const addSelectedElementInterests = (id, type, selected_travel_interests_merge_sets, uuid, lang) => dispatch => {
    dispatch({
        type: "ADD_" + type,
        id: id,
    })
    let Ids = selected_travel_interests_merge_sets.concat(id)
    let obj = {
        interestIDs: Ids
    }
    let api_url = environment.apiUrl +'muver/savemuverinterests/' + uuid + '/' + lang
    PostAsyncHelper(api_url, obj)
}

/* Remove travel general  */
export const removeSelectedElementInterests = (id, type, selected_travel_interests_merge_sets, uuid, lang) => dispatch => {
    dispatch({
        type: "REMOVE_" + type,
        id: id,
    })
    let Ids = selected_travel_interests_merge_sets.filter(val => val !== id)
    let obj = {
        interestIDs: Ids
    }
    let api_url = environment.apiUrl +'muver/savemuverinterests/' + uuid + '/' + lang
    PostAsyncHelper(api_url, obj)
}
/* Add travel interests */
export const addTravelInterestsSelectedElement = (id) => dispatch => {
    dispatch({
        type: ADD_TRAVEL_INTERESTS,
        id: id,
    })
}

/* Remove travel interests */
export const removeTravelInterestsSelectedElement = (id) => dispatch => {
    dispatch({
        type: REMOVE_TRAVEL_INTERESTS,
        id: id,
    })
}

/* Add travel where */
export const addTravelWhereSelectedElement = (id) => dispatch => {
    dispatch({
        type: ADD_TRAVEL_WHERE,
        id: id,
    })
}

/* Remove travel where */
export const removeTravelWhereSelectedElement = (id) => dispatch => {
    dispatch({
        type: REMOVE_TRAVEL_WHERE,
        id: id,
    })
}

/* Add travel what */
export const addTravelWhatSelectedElement = (id) => dispatch => {
    dispatch({
        type: ADD_TRAVEL_WHAT,
        id: id,
    })
}

/* Remove travel what */
export const removeTravelWhatSelectedElement = (id) => dispatch => {
    dispatch({
        type: REMOVE_TRAVEL_WHAT,
        id: id,
    })
}

/* Add travel howlong */
export const addTravelHowlongSelectedElement = (id) => dispatch => {
    dispatch({
        type: ADD_TRAVEL_HOWLONG,
        id: id,
    })
}

/* Remove travel howlong */
export const removeTravelHowlongSelectedElement = (id) => dispatch => {
    dispatch({
        type: REMOVE_TRAVEL_HOWLONG,
        id: id,
    })
}

/* Add travel how */
export const addTravelHowSelectedElement = (id) => dispatch => {
    dispatch({
        type: ADD_TRAVEL_HOW,
        id: id,
    })
}

/* Remove travel how */
export const removeTravelHowSelectedElement = (id) => dispatch => {
    dispatch({
        type: REMOVE_TRAVEL_HOW,
        id: id,
    })
}

/* fetch travel interestes  */
export const fetchTravelInterests = (uuid, lang) => async dispatch => {

    GetAsyncHelper(environment.apiUrl +'getmuverintereststodisplay').then(responseInterestsSets => {
        if (responseInterestsSets !== undefined && responseInterestsSets.statusText === "OK") {
            dispatch({
                type: FETCH_MUVER_INTERESTS_SUCCESS,
                travel_interests: responseInterestsSets.data.trips,
                travel_where: responseInterestsSets.data.where,
                travel_how: responseInterestsSets.data.how,
                travel_what: responseInterestsSets.data.what,
                travel_howlong: responseInterestsSets.data.how_long,
                fetched: true
            })
        } else {
            dispatch({
                type: FETCH_MUVER_INTERESTS_FAILURE,
                fatched: false
            })
        }
    })

    if(uuid){
        GetAsyncHelper(environment.apiUrl +"muver/getMuverInterests/" + uuid + "/" + lang).then(responseSelectedInterestsSets => {
            if (responseSelectedInterestsSets !== undefined && responseSelectedInterestsSets.statusText === "OK") {
                const resultSlectedInterestsSets = responseSelectedInterestsSets.data.result
                dispatch({
                    type: FETCH_MUVER_INTERESTS_SELECTED_SETS,
                    travel_interests: Array.from(resultSlectedInterestsSets.trips.map(t => t.id)),
                    travel_where: Array.from(resultSlectedInterestsSets.where.map(where => where.id)),
                    travel_how: Array.from(resultSlectedInterestsSets.how.map(how => how.id)),
                    travel_what: Array.from(resultSlectedInterestsSets.what.map(what => what.id)),
                    travel_howlong: Array.from(resultSlectedInterestsSets.how_long.map(how_long => how_long.id)),
                })
            }
        })   
    }
   
}