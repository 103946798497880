import * as Actions from '../../../../redux/actions'

import { CheckIcon, MinusSignIcon, PlusCircleIcon } from '../SVGBank'

import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import React from 'react'
import { SearchEstablishmentTextField } from '../FormComponentsBank'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { member_area_router } from "../../Common/RegExValidate"

export const AddScheduleEstabishment_ = ({
    lang,
    uuid_auth,
    uuid = uuid_auth !== "" ? uuid_auth : "TMPUUID",
    background_color = "",
    switchFeatureContainer,
    searchEstablishment,
    fillColor = switchFeatureContainer.color,
    destinationId,
    setSearchEstablishmentSelected,
    addActivityToTripDestinationInTrip,
    activeTripId,
    activeTripDestinationId,
    activeDayInTripDestination,
    tripDuration,
    activeDayInTrip,
    activeTrip,
    switch_container,
    destinationName,
    setOpenAddActivity,
    day_index,
}) => {

    const searchEstablishmentStyles = makeStyles((theme) => ({

        root: {
            background: background_color === "" ? "#FFFFFF 0% 0% no-repeat padding-box" : background_color + " 0% 0% no-repeat padding-box",
            position: 'relative',
            marginLeft: "30px",
            marginTop: "0px",
            height: '50px',
            width: "100%",
            padding: "6px 0px 0px 10px",

            '& .MuiFormLabel-root': {
                font: "14px/0px Futura Md BT",
                color: "#B1B1B1",
            },
            
            '& .MuiFormControl-root': {
                width: "100%",
            },
            '& .MuiAutocomplete-popupIndicator': {
                display: "none",
            },
            '& .MuiInput-underline:before': {
                transition: 0,
                borderBottom: 0,
            },
            '& .MuiInputBase-input': {
                color: background_color === "" ? "var(--bgBlack)" : "#FFFFFF80",
                font: "16px/16px Futura Md BT",
            },
            
            '& .MuiInput-underline:after': {
                transition: 0,
                borderBottom: 0,
            },
            '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                borderBottom: 0,
            },
            '& .MuiFormLabel-root.Mui-focused': {
                color: "#B1B1B1",
                paddingTop: 6,
            },
            '& .Mui-focused': {
                font: "14px/14px Futura Md BT",
            },
            '& .MuiFormLabel-filled': {
                font: "14px/14px Futura Md BT",
            },
            '& .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot': {
                paddingRight: "30px"
            },
            '& .MuiAutocomplete-inputRoot[class*="MuiInput-root"] .MuiAutocomplete-input': {
                padding: "0px",
                paddingTop: "10px"
            },
            '&  .MuiAutocomplete-endAdornment': {
                top: 'calc(50% - 10px)'
            },
           
        },
    }))
    const [openSearch, setOpenSearch] = React.useState("OPEN")
    const handleSearchStatus = (status) => {
        //setOpenSearch(status);
        if (status === "CLOSE") {
            setOpenSearch("CLOSE")
            setSearchEstablishmentSelected(null)
            setOpenAddActivity({day_index: day_index, open:false})
            //setOpenAddActivity(false)
        } else if (status === "COMPLETE") {
            setSearchEstablishmentSelected(null)
            setOpenSearch(status)
            setOpenAddActivity({day_index: day_index, open:false})
        }
        else{
            setOpenSearch(status);
        }
    }
    const anchorRef = React.useRef(null)

    const handleClose = event => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return
        }
        setOpenSearch("CLOSE")
        setSearchEstablishmentSelected(null)
    }
    const handleAddDestinationToTrip = () => {
        const activitiesInDestinationDay = activeTrip.activities.filter(a => a.activityDay === activeDayInTrip && a.tripDestinationId === activeTripDestinationId)
        const lengthActivitiesInDestinationDay = activitiesInDestinationDay !== undefined ? activitiesInDestinationDay.length : 0
        const activitiesInDestinationDayEstablishmentIds = Array.from(activitiesInDestinationDay.map((a) => a.activityEstabID))
        const last_establishmentId = activitiesInDestinationDayEstablishmentIds[lengthActivitiesInDestinationDay - 1] !== undefined ? activitiesInDestinationDayEstablishmentIds[lengthActivitiesInDestinationDay - 1] : ""
        const ids = []
        ids.push(destinationId)
        addActivityToTripDestinationInTrip(lang, uuid,
            activeTripDestinationId,
            activeDayInTripDestination,
            searchEstablishment.establishmentSelected.id,
            activeTripId,
            last_establishmentId,
            tripDuration,
            destinationId,
            ids // no creating new destination
        )

        handleSearchStatus("CLOSE")
        setOpenAddActivity({day_index: day_index, open:false})
    }
    const classesSearchBar = searchEstablishmentStyles()

    const addActivityLabel = "Add an activity to " + destinationName
    const searchlabel = "Search Activity in " + destinationName
    return (
        <React.Fragment>
            { switch_container === member_area_router.memberAreaTrip ?

                <div>
                    {
                        openSearch === "CLOSE" &&
                        <div style={{ color: "var(--mainGreen)", font: "16px/18px Futura Lt BT", cursor: "pointer", marginBottom: "10px" }}
                            onClick={() => handleSearchStatus("OPEN")}>
                                  {addActivityLabel}
                        </div>
                    }
                    {openSearch !== "CLOSE" &&
                        <ClickAwayListener onClickAway={null}>
                            <div style={{ display: "flex" }}>
                                <div className={classesSearchBar.root}>
                                    <SearchEstablishmentTextField
                                        label={searchlabel}
                                        required={false}
                                        destinationId={destinationId}
                                        setOpenSearch={setOpenSearch} />
                                </div>
                                {openSearch === "OPEN" && <div style={{ flexGrow: "0", cursor: "pointer" }} onClick={() => handleSearchStatus("CLOSE")}><MinusSignIcon style={{  marginTop: "22px", marginLeft: "13px", marginRight: "30px" }} fill={fillColor} /></div>}
                                {openSearch === "COMPLETE" && <div style={{ flexGrow: "0", cursor: "pointer" }} onClick={() => handleAddDestinationToTrip()}><CheckIcon style={{ marginTop: "23px", marginLeft: "15px", marginRight: "15px" }} fill={fillColor} /></div>}
                            </div>
                        </ClickAwayListener>
                    }
                </div> :
                <div>
                    {
                        openSearch === "CLOSE" &&
                        <div  class="addActivity" style={{ color: "#19BC9B", cursor: "pointer", marginBottom: "10px" }}
                            onClick={() => handleSearchStatus("OPEN")}>
                            <PlusCircleIcon width={25} height={25} style={{ marginRight: "5px" }} fill={"#19BC9B"} />{"Add an activity"}
                        </div>
                    }
                    {openSearch !== "CLOSE" &&
                        <ClickAwayListener onClickAway={handleClose}>
                            <div style={{ display: "flex" }}>
                                <div className={classesSearchBar.root} >
                                    <SearchEstablishmentTextField
                                        label="Search Activity"
                                        required={false}
                                        destinationId={destinationId}
                                        setOpenSearch={setOpenSearch} />
                                </div>
                                {openSearch === "OPEN" && <div style={{ flexGrow: "0", cursor: "pointer" }} onClick={() => handleSearchStatus("CLOSE")}><MinusSignIcon style={{ marginTop: "22px", marginLeft: "13px", marginRight: "30px" }} fill={fillColor} /></div>}
                                {openSearch === "COMPLETE" && <div style={{ flexGrow: "0", cursor: "pointer" }} onClick={() => handleAddDestinationToTrip()}><CheckIcon style={{ marginTop: "23px", marginLeft: "15px", marginRight: "15px" }} fill={fillColor} /></div>}
                            </div>
                        </ClickAwayListener>
                    }
                </div>}    
        </React.Fragment>
    )
}

const mapStateToProps = (state) => ({

    lang: state.Setting.lang,
    uuid_auth: state.Member.authModal.uuid,
    switchFeatureContainer: state.FormBank.FeatureFormat,
    searchEstablishment: state.FormBank.TimelineWizard.searchEstablishment,
    switch_container: state.Member.switch_container,


})

const mapDispatchToProps = {
    setSearchEstablishmentSelected: Actions.setSearchEstablishmentSelected,
    addActivityToTripDestinationInTrip: Actions.addActivityToTripDestinationInTrip,

}

const AddScheduleEstabishment = connect(mapStateToProps, mapDispatchToProps)(AddScheduleEstabishment_)
export default AddScheduleEstabishment
