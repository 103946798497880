import * as Actions from '../../../../redux/actions'

import { TripNameTextField, TripStartDateTextField } from '../FormComponentsBank'

import {
    Image,
} from 'react-bootstrap'
import React from 'react'
import TripEllipsis from './TripEllipsis.jsx'
import { connect } from 'react-redux'
import { image_boxing, list_container_mode, URL_REPLACE } from "../RegExValidate"
import { Link, useHistory} from "react-router-dom"
import { getTripInfoById } from '../../../../redux/actions'

/* trip element component */
const TripsElement_ = ({setTimeLineStatus, isMobile,trip, sizeW, sizeH, setSwitchContainer, setTripDisplayMode, getSelectedTripInformation, clearSelectedTripInformation, setTripToTimeline, uuid }) => {
    const history = useHistory()


    const handleTripInformation = (tripID) => {

        if(isMobile){
            goToTimeline()
        }
        else{
            clearSelectedTripInformation()
            const url = "/muver/trip/"+tripID;
            history.push(url.toLowerCase())
        }
       
    }

    const goToTripTimeline = () => {
        setTripDisplayMode(list_container_mode.mobileTimeline)
    }

    const goToTimeline = async () => {
  
        const selectedTrip = await getTripInfoById(uuid,trip?.tripID)

        if(selectedTrip){
            await setTripToTimeline(trip?.tripID, uuid)
            const tripName = trip?.tripName ? trip?.tripName.replace(URL_REPLACE, "-"): "";
             const url = "/destination/" + (tripName) + "/" + 1 + "/" + selectedTrip?.destinationId;
            //  setTimeLineStatus(true)
            history.push(url.toLowerCase())
            goToTripTimeline()

        }

      
    }

    const srcTripImage = trip.tripHeaderImage !== undefined ? image_boxing(trip.tripHeaderImage, sizeW, sizeH) : ""
    const id = "trip_item_" + trip.tripID 

    return (
        <div id={id} className="padding_left pb-3">
            <div className="position-relative">
                <div className="content_container" style={{ height: sizeH }}>
                    <Image className="min_width_100 img_fit_cover" src={srcTripImage} />
                </div>
                <div style={{
                    top: "0",
                    left: "0",
                    right: "0",
                    height: "20%",
                    position: "absolute",
                    alignItems: "center",
                }}>
                    <div style={{ height: "100%", backgroundColor: "rgba(25, 25, 25, 0.2)", textAlign: "right", padding: "5px", }}>
                        <TripEllipsis trip={trip} />
                    </div>
                </div>
                <div style={{
                    top: "20%",
                    left: "0",
                    right: "0",
                    height: "50%",
                    position: "absolute",
                    alignItems: "center",
                }} onClick={() => handleTripInformation(trip.tripID, uuid)}>
                    <div style={{ height: "100%", cursor: "pointer", backgroundColor: "rgba(25, 25, 25, 0.2)", textAlign: "right", padding: "5px", }}>
                    </div>
                </div>
                <div style={{
                    bottom: "0",
                    left: "0",
                    right: "0",
                    height: "30%",
                    position: "absolute",
                    alignItems: "center",
                }}>
                    <div style={{ height: "100%", backgroundColor: "rgba(25, 25, 25, 0.2)", textAlign: "left", }}>
                        <div className="pl-3" style={{ paddingBottom: "26px", position: "absolute", bottom: "0" }}>
                            <TripNameTextField tripInList={trip} />
                            <TripStartDateTextField tripInList={trip}
                                isShortFormat={true}
                                inputStyle={{
                                    font: "14px/14px Futura Md BT",
                                    color: "var(--mainWhite)",
                                }} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="box_3 futura_md_bt_13_19_green pl-2 pr-2">
                {trip.tripDesc}
            </div>
        </div>
    )

}

const mapStateToProps = (state) => ({
    uuid: state.Member.authModal.uuid,
    isMobile: state.Setting.is_mobile,
})

const mapDispatchToProps = {
    setSwitchContainer: Actions.setSwitchContainer,
    getSelectedTripInformation: Actions.getSelectedTripInformation,
    clearSelectedTripInformation: Actions.clearSelectedTripInformation,
    setTripToTimeline: Actions.setTripToTimeline,
    setTimeLineStatus: Actions.setTimeLineStatus,
    setTripDisplayMode: Actions.setTripDisplayMode,
}

const TripsElement = connect(mapStateToProps, mapDispatchToProps)(TripsElement_)
export default TripsElement
