import { AirplaneDestination, TrainDestination, HighwayDestination, BusIcon, CarIcon, DirectionsIcon, FlightShapeIcon, TrainDuotoneIcon, WalkIcon, BicycleIcon } from "../SVGBank"

import Divider from '@material-ui/core/Divider'
import React from 'react'
import { connect } from 'react-redux'
import * as Actions from '../../../../redux/actions'
import { member_area_router, list_container_mode } from "../RegExValidate"
import moment from 'moment'
import { ClickAwayListener, Fab, Popper } from "@material-ui/core"
import { makeStyles } from '@material-ui/core/styles'
import Grow from '@material-ui/core/Grow';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';
import BusLogo from'../../../../assets/images/Bus.png';

const TimeToReachDestinations_ = ({transportModes,uuid,setTransportMode, fetchTransportModes,savedTrip, destination, activeTripDestinationId, setSwitchContainer, setTripDisplayMode, activeTrip, currentPosition, index_destination }) => {
    const classes  = useStyles();
    const defaultMode = destination !== undefined && destination.transportMode !== undefined && destination.transportMode !== null ? destination.transportMode : ""
    const [open, setOpen] = React.useState(false);
    const [transportMode, setMode] = React.useState(defaultMode);
    const options = transportModes ? [...transportModes] : null;
    const anchorRef = React.useRef(null);
    let destinationTimeToReachSlice =
        (destination.destinationTimeToReach !== undefined && destination.destinationTimeToReach !== null) ?
            (destination.destinationTimeToReach).slice() : ""
    // const destinationTimeToReach = "020:01:03"
    // let destinationTimeToReachSplit = "020:01:03"
    let destinationTimeToReachSplit = destinationTimeToReachSlice !== "" ? destinationTimeToReachSlice.split(":") : []
    let timeToReachDestinations = ""

    if (destinationTimeToReachSplit.length === 3) {
        // console.log("It has day")
        const destinationTimeToReachWithDay = destinationTimeToReachSplit[1] + ":" + destinationTimeToReachSplit[2]
        const day = destinationTimeToReachSplit[0] === "0" ? "" : destinationTimeToReachSplit[0].replace(/^0/, "")
        timeToReachDestinations = moment(destinationTimeToReachWithDay, "HHmm").format('H') === "0" ?
            moment(destinationTimeToReachWithDay, "HHmm").format('m') + "min" :
            (moment(destinationTimeToReachWithDay, "HHmm").format('m') === "0" ?
                moment(destinationTimeToReachWithDay, "HHmm").format('H') + "h" :
                moment(destinationTimeToReachWithDay, "HHmm").format('H') + "h" + moment(destinationTimeToReachWithDay, "HHmm").format('m') + "min")
        timeToReachDestinations = day + "day" + timeToReachDestinations
    } else if (destination.destinationTimeToReach !== null) {
        // console.log("It has no day")
        timeToReachDestinations = moment(destination.destinationTimeToReach, "HHmm").format('H') === "0" ?
            moment(destination.destinationTimeToReach, "HHmm").format('m') + "min" :
            (moment(destination.destinationTimeToReach, "HHmm").format('m') === "0" ?
                moment(destination.destinationTimeToReach, "HHmm").format('H') + "h" :
                moment(destination.destinationTimeToReach, "HHmm").format('H') + "h" + moment(destination.destinationTimeToReach, "HHmm").format('m') + "min")

    }
    const color = activeTripDestinationId === destination.td_id ? "#8F8F8F" : "#8F8F8F"
    const fillIcon = activeTripDestinationId === destination.td_id ? "#8F8F8F" : "#8F8F8F"
    const daysInDestination = destination && destination.spanningDays ? destination.spanningDays.split(",") : []
    const firstDayInDestination = daysInDestination.length > 0 ? parseInt(daysInDestination[0]) : 0
    const tripDestinations = activeTrip.tripDestinations
    const previousDestination = tripDestinations.find((d => {
        let spanDays = d.spanningDays.split(",")
        let lastDays = parseInt(spanDays[spanDays.length - 1])
        return lastDays === firstDayInDestination
    }))

    let transitLabel = ""
    let destinationCity = ""
    let previousDestinationCity = ""

    if (previousDestination && destination) {
        destinationCity = destination.name.split(",")[0].split("-")[0]
        previousDestinationCity = previousDestination.name.split(",")[0].split("-")[0]
        transitLabel = previousDestinationCity + " - " + destinationCity + "  :  " + timeToReachDestinations
    } else if (!previousDestination) {
        destinationCity = destination.name.split(";")[0].split("-")[0]
        transitLabel = "Home  - " + destinationCity + "  :  " + timeToReachDestinations
    }

    //console.log(`TimeToReachDestination previousDestinationCity: ${previousDestinationCity}`)
    //console.log(`TimeToReachDestination destinationCity: ${destinationCity}`)
    let gUrl = destination && previousDestination ? `https://www.google.com/maps/dir/${previousDestination.latitude}+${previousDestination.longitude}/${destination.latitude}+${destination.longitude}` : ""
    let googleOpen = true
    if (!previousDestination && currentPosition) {
        gUrl = `https://www.google.com/maps/dir/${currentPosition.latitude}+${currentPosition.longitude}/${destination.latitude}+${destination.longitude}`
    } else if (!previousDestination && !currentPosition) {
        googleOpen = false
    }

    const isRoadTrip = savedTrip && savedTrip?.trip && savedTrip?.trip?.tripTravelCategory?.code === "road";

    const handleToggle = () => {
        setOpen(prevOpen => !prevOpen);
    };
    const handleClose = event => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    React.useEffect(()=>{
        fetchTransportModes()
        
    },[])


   
    const getIcon = (option) =>{
  
    
        if(option.code === "walk" || option.code === "walking"){
            return <WalkIcon fill="#0D3A7D" width={33} height={33} />
        }
        if(option.code === "public_transport"){
            return <img  src={BusLogo} style={{height:33}} alt="bus"/>;
        }
        if(option.code === "car"){
            return <HighwayDestination width={33} height={33} fill="#0D3A7D" />
        }
        if(option.code === "plane"){
            return <AirplaneDestination width={33} height={33} fill="#0D3A7D" />
        }
        if(option.code === "train"){
            return <TrainDestination width={33} height={33} fill="#0D3A7D" />
        }
    }
    const activityTimeToReach = (time) => {
        const timeToreach = moment(time, "HHmm").format('H') === "0" ?
        moment(time, "HHmm").format('m') + "min" :
        (moment(time, "HHmm").format('m') === "0" ?
            moment(time, "HHmm").format('H') + "h" :
            moment(time, "HHmm").format('H') + "h" + moment(time, "HHmm").format('m') + "min")
        return timeToreach;  
    }

    const setTimeToReach = (option) =>{
        const destId = destination?.td_id
        if(destId && option?.code){
            setTransportMode(uuid,destId,option?.code)
        }
        setMode(option?.code)
        setOpen(false);
    }



    return (
        <div>
            <div style={{ textAlign: "center", backgroundColor: "#FFFFFF" }}
               >
                <div style={{ display: "inline-block", }}>
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", position: "relative" }}>

                        {index_destination && index_destination !== 0 ? <div style={{ height: 90 }}>
                            <Divider orientation="vertical" style={{ display: "inline-block", backgroundColor: color, height: "90px" }} />
                            <div style={{
                                opacity: "1",
                                position: "relative", top: -75, padding: '6px', background: 'white'
                            }}>
                                {isRoadTrip && <div>
                                    <HighwayDestination width={38} height={42} />
                                </div>}
                                {!isRoadTrip && <div
                                  ref={anchorRef}
                                   aria-controls={open ? 'menu_ellipsis' : undefined}
                                   aria-haspopup="true"
                                   onClick={handleToggle}
                                   className={classes.button_text}
                                   style={{ cursor: "pointer", }}
                                   >
                                    {(transportMode === "bus" || transportMode ===  "public_transport") && <div>
                                    <img  src={BusLogo} style={{height:33}} alt="bus"/></div>}

                                    {transportMode === "car" && <HighwayDestination width={38} height={42} fill="#0D3A7D" />}
                                    {transportMode === "plane" && <AirplaneDestination width={38} height={42} fill="#0D3A7D" />}
                                    {transportMode === "train" && <TrainDestination width={38} height={42} fill="#0D3A7D" />}
                                    {(transportMode === "walking" || transportMode === "walk") && <WalkIcon fill="#0D3A7D" width={38} height={42} />}

                                </div>}
                               

                            </div>

                            <ClickAwayListener onClickAway={handleClose}>
                                    <Popper
                                        open={open}
                                        anchorEl={anchorRef.current}
                                        className={classes.positionZIndex}

                                        role={undefined}
                                        transition
                                        disablePortal
                                        placement={'bottom-end'}
                                        modifiers={{
                                            flip: {
                                                enabled: false,
                                            },
                                        }}

                                    >
                                        {({ TransitionProps, placement }) => (
                                            <Grow
                                                {...TransitionProps}
                                                style={{ transformOrigin: placement, }}
                                                {...({ timeout: 1000 })}
                                            >
                                                <Paper >
                                                    <MenuList

                                                        autoFocusItem={false}
                                                        disableListWrap={true}
                                                        dense={false}
                                                        id="menu_ellipsis"
                                                    >

                                                        {options && options.map((option, option_index) => {
                                                            if(option?.long_distance === "1"){
                                                                return <MenuItem className={classes.backgroundSelection} key={option_index} onClick={() => setTimeToReach(option)} >
                                                                <Fab size="small" disabled={true}
                                                                    style={{
                                                                        outline: "0px",
                                                                        backgroundColor: "transparent",
                                                                    }}>

                                                                    {getIcon(option)}
                                                                </Fab>

                                                            </MenuItem>
                                                            }
                                                            return null
                                                            
                                                        })}
                                                    </MenuList>
                                                </Paper>
                                            </Grow>
                                        )}
                                    </Popper>

                                </ClickAwayListener>

                        </div> : null}


                        {/* {!isRoadTrip && <div>
                                <Divider orientation="vertical" style={{ display: "inline-block", backgroundColor: color, height: "20px" }} />
                                <div style={{
                                    width: "320px",
                                    height: "45px",
                                    outline: "0px",
                                    background: "#FFFFFF 0% 0% no-repeat padding-box",
                                    border: "1px solid #8F8F8F",
                                    borderRadius: "36px",
                                    opacity: "1",
                                    position: "relative",
                                }}>
                                    <div style={{ position: "absolute", top: "10px", left: "20px" }}>
                                        {transportMode === "walking" && <WalkIcon fill={fillIcon} />}
                                        {transportMode === "bus" && <BusIcon fill={fillIcon} />}
                                        {transportMode === "car" && <CarIcon fill={fillIcon} />}
                                        {transportMode === "plane" && <FlightShapeIcon fill={fillIcon} />}
                                        {transportMode === "train" && <TrainDuotoneIcon fill={fillIcon} />}
                                    </div>
                                    <div style={{ position: "absolute", top: "12px", left: "55px" }}>{transitLabel}</div>
                                    <div style={{ position: "absolute", top: "12px", right: "20px" }}>
                                        <DirectionsIcon fill={fillIcon} />
                                    </div>
                                </div>
                            </div>} */}
                        {/*<Divider orientation="vertical" style={{ display: "inline-block", backgroundColor: color, height: "20px" }} />
                        <div style={{ position: "absolute", top: "30px", left: "50px" }}>{timeToReachDestinations}</div>*/}
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    activeTripDestinationId: state.FormBank.TimelineWizard.activeTripDestinationId,
    activeTrip: state.FormBank.TimelineWizard.activeTrip,
    savedTrip: state.ActiveTrip,
    uuid: state.Member.authModal.uuid,
    transportModes:state.FormBank.TimelineWizard.transportModes,
})

const mapDispatchToProps = {
    setSwitchContainer: Actions.setSwitchContainer,
    setTripDisplayMode: Actions.setTripDisplayMode,
    fetchTransportModes: Actions.fetchTransportModes,
    setTransportMode:Actions.setTransportMode
}

const TimeToReachDestinations = connect(mapStateToProps, mapDispatchToProps)(TimeToReachDestinations_)
export default TimeToReachDestinations



/*  inject css to menu component */
const useStyles = makeStyles((theme) => {

    return {
        root: {
            display: 'inline-block',
        },
        button_text: {
            color: "var(--mainGreen)",
            textAlign: "left",
            font: "16px/22px Futura Md BT",
            letterSpacing: "0",
            '&:focus': {
                border: "0",
                outline: "0px"
            }
        },
        positionZIndex: {
            top: "-210px !important",
            left: "81px !important",
            zIndex: "34 !important",
            width: 137



        },



        paddingLeft30: {
            paddingLeft: "30px"
        },
        backgroundSelection: {
            '&:hover': {
                backgroundColor: ""
            },
            minHeight: "10px",
        },
        paper: {
            marginRight: theme.spacing(2),
        },

        hr: {

            width: "100%",
            height: "1px",
            display: "block",
            position: "relative",
            marginRight: "10%",
            marginTop: "5px",
            marginBottom: "5px",
            border: "1px solid rgba(0,0,0,.05)",
        },
        timesDiv: {
            marginRight: "10px",
            marginLeft: "115px",
            display: "inline-block",
            textAlign: "right",
        },

    }
});