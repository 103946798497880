import { Grid } from "@material-ui/core"
import moment from "moment"
import React from 'react'
import { DurationLayover, secondsToHms } from "../../../../utility/utility"
import { convertObjArray } from "./FlightManagementList"

export const FlightPassengers = ({passengers}) => {
    const passengerList =   convertObjArray(passengers);
    return (
        <Grid item style={{ border: '1px solid #DFDFDF', marginBottom: 15, width: '100%',padding: "40px 50px"}}>
        <div
            style={{color:'#0B91D8',font: "26px/26px Futura Md BT",marginBottom:40}}
            >
                Passengers: 
            </div>

            { passengerList && passengerList.map((passenger, passengerIndex) => {
            
            return (<div style={{paddingBottom: 30}} key={passengerIndex}>
            <div
            style={{color:'#000000',font: "20px/20px Futura Hv BT", textTransform:"uppercase",marginBottom:40}}
            >
                {passenger?.first_name} {passenger?.last_name}
            </div>

            {passenger?.ticket_numbers && <div
            style={{color:'#000000',font: "20px/20px Futura Lt BT",marginBottom:15,clear:"both"}}
            >
               <div style={{width: "23%", float:"left",font: "20px/20px Futura Md BT"}}> Ticket EMD:  </div>

               <span> {convertObjArray(passenger?.ticket_numbers).join()} </span>
            </div>}
            
            {passenger?.seat_selection_data && passenger?.seat_selection_data?.length > 0 &&<div
            style={{color:'#000000',font: "20px/20px Futura Lt BT",marginBottom:15,clear:"both"}}
            >
               <div style={{width: "23%", float:"left",font: "20px/20px Futura Md BT"}}> Seat: </div>

               <span> {passenger?.seat_selection_data} </span>
            </div>} 
            {/* <div
            style={{color:'#000000',font: "20px/20px Futura Lt BT",marginBottom:15}}
            >
               <div style={{width: "23%", float:"left",font: "20px/20px Futura Md BT"}}>  Email: </div>

               <span> 23283823823282838238 </span>
            </div>
            <div
            style={{color:'#000000',font: "20px/20px Futura Lt BT",marginBottom:15}}
            >
               <div style={{width: "23%", float:"left",font: "20px/20px Futura Md BT"}}> Phone: </div>

               <span> 23283823823282838238 </span>
            </div> */}
           {passenger?.frequent_flyer_data && passenger?.frequent_flyer_data?.length > 0 && <div
            style={{color:'#000000',font: "20px/20px Futura Lt BT",marginBottom:15,clear:"both"}}
            >
               <div style={{width: "23%", float:"left",font: "20px/20px Futura Md BT"}}> Frequent Flyer #: </div>

               <span> {passenger?.frequent_flyer_data} </span>
            </div>}
            
            {passenger?.meal_preference_data  && passenger?.meal_preference_data?.length > 0 && <div
            style={{color:'#000000',font: "20px/20px Futura Lt BT",marginBottom:15,clear:"both"}}
            >
               <div style={{width: "23%", float:"left",font: "20px/20px Futura Md BT"}}> Preferences: </div>

               <span> {passenger?.meal_preference_data}  </span>
            </div>}

            </div>)
            }
            )}


            </Grid>
    )
}