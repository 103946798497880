import * as Actions from '../../../../redux/actions'

import { Element, Link } from 'react-scroll'

import MemberActionElement from "./MemberActionElement"
import { Menu } from 'semantic-ui-react'
import React from 'react'
import { connect } from 'react-redux'
import {  member_area_router } from "../../Common/RegExValidate"
import { Backdrop, CircularProgress, makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));


const MemberActionContainer_ = ({ memberActions, switch_container, features, lang, actionType, fetchActiveTripFromCookies, is_mobile, app_menu_height, backGroundColor, textColor }) => {


    const [loadingActions, setLoadingActions] = React.useState(false);
    const [actionsEmpty, setActionsEmpty] = React.useState(false);
    const classes = useStyles();

    React.useEffect(() => {
       if(!(memberActions)) {
        setActionsEmpty(true)
       }

       setTimeout(() => {
        setLoadingActions(false)
       }, 1000);
    },[memberActions])

    React.useEffect(() => {
        fetchActiveTripFromCookies("", lang)
        menu === "" && features.length > 0 && features[0] !== undefined && setMenu(features[0])
        if (!(memberActions && memberActions.length > 0)) {
            setLoadingActions(true)
        }

    },[features])

    const allActions = memberActions && memberActions.sort((a,b) => a.featureId - b.featureId);
    const [menu, setMenu] = React.useState("")
    let padding_l = 15
    let padding_r = 15
    let position = "sticky"
    const top_space = switch_container === member_area_router.starred ? 0 : 30
    const headerHeight = (-Math.ceil(-app_menu_height / 2))
    const menuOffset = -headerHeight - 90
    if (!isNaN(is_mobile)) {
        padding_l = is_mobile ? "1" : "3"
        padding_r = is_mobile ? "1" : "3"
    }
    //console.log("MemberActionContainer : ")
    //console.log("switch_container : ")
    //console.log(switch_container)
    //console.log("MemberActionContainer memberActions : ")
    //console.log(memberActions)
    //console.log("MemberActionContainer features : ")
    //console.log(features)
    return (
        <React.Fragment>
            {allActions !== undefined && allActions.length > 0 &&
                allActions.map((feature, feature_index) => {
                    let destinations = Array.from(new Set(feature.actions.map(a => a.unit.destinationName + "__" + a.unit.destinationId)))
                    //console.log("MemberActionContainer memberAction destinations : ")
                    //console.log(destinations)
                    if (destinations && destinations.length > 0) {
                        destinations = destinations.sort()
                    }

                    return (
                        <div key={feature_index} style={{
                            // paddingLeft: padding_l + "%",
                            // paddingRight: padding_r + "%",
                        }}>
                          

                            <Element name={feature.featureTitle} className="element">
                                <div style={{
                                    font: "30px/72px Futura Hv BT", 
                                    marginTop:is_mobile? "0px": "60px",
                                    color: textColor,
                                }}>
                                    {feature.featureTitle} 

                                </div>
                                {feature.actions && feature.actions.length > 0 ?  destinations.map((dest, dest_index) => {
                                    let destinationName = dest.slice(0, dest.indexOf('__'))
                                    let destinationId = dest.slice(dest.lastIndexOf('__')+2)

                                    return (
                                        <React.Fragment key={dest_index}>
                                            {/* {<div id={actionType + "_" + feature.featureTitle + "_" + destinationId} style={{ font: "24px/72px Futura Hv BT", color: textColor, }}>
                                                {destinationName}
                                            </div>} */}
                                            
                                            {feature.actions !== undefined && feature.actions.length > 0 &&
                                                feature.actions.filter(a => a.unit.destinationName === destinationName).map((memberAction, member_action_index) => {
                                                    return (
                                                        <MemberActionElement feature={feature} memberAction={memberAction} key={member_action_index} />
                                                    )
                                                })}

                                        </React.Fragment>
                                    )
                                })
                                :
                                 <div style={{ font: "24px/72px Futura Hv BT", color: textColor }}>
                                        You haven't added any {feature.featureTitle} yet
                                    </div> 
                                 }
                                
                            </Element>
                        </div>
                    )
                })
            }
               {  actionsEmpty &&
                                <p className="padding_top_50 padding_125rem_lr_mobile"
                                    style={{
                                        font: "24px/24px Futura Lt BT",
                                        color: "#FFFFFF",
                                        paddingBottom:40
                                    }}>
                                   {switch_container === member_area_router.bucketList ?
                                    "You haven't added any bucket list ideas yet."
                                   :
                                    `You haven't added any ${switch_container} yet.` 
                                   }
                        </p>
                            }
    <Backdrop className={classes.backdrop} open={loadingActions} >
                <CircularProgress color="inherit" />
            </Backdrop>

        </React.Fragment>
    )
}

const mapStateToProps = (state) => ({
    features: state.MemberActions.memberActionSets.features,
    memberActions: state.MemberActions.memberActionSets.memberActions,
    is_mobile: state.Setting.is_mobile,
    app_menu_height: state.Setting.appMenu.app_menu_height,
    textColor: state.Setting.appMenu.textColor,
    uuid: state.Member.authModal.uuid,
    backGroundColor: state.Setting.appMenu.backGroundColor,
    switch_container: state.Member.switch_container,
    lang: state.Setting.lang,
})

const mapDispatchToProps = {
    fetchActiveTripFromCookies: Actions.fetchActiveTripFromCookies,
}

const MemberActionContainer = connect(mapStateToProps,mapDispatchToProps)(MemberActionContainer_)
export default MemberActionContainer 
