import React from 'react'
import { connect } from 'react-redux'
import { PhotoUploadComponent } from './PhotoUploadComponent';
import { CameraIcon } from './SVGBank';
import * as Actions from '../../../redux/actions'

 const AddPhotoComponent_ = ({isMobile,switchFeatureContainer,authModal,setOpenAuthModal,dataId,dataKey}) =>{
    let widthIcon = isMobile ? "110px" : "150px";
    let heightIcon = isMobile ? "30px" : "45px";
    let fontHeightIcon = isMobile ? "17px" : "30px";
    let fontIcon = isMobile ? "10" : "16";
    let fontIconPhotoVideo = isMobile ? "18" : "25";
    const [showModal, setShowModal] = React.useState(false);
    const [addingPhoto, setAddingPhoto] = React.useState(false);


    
    const isLoggedIn = () => {
        return authModal?.uuid && authModal?.uuid.length > 0?true:false
    }

    const openModal =() =>{
        if(!isLoggedIn()){
            setAddingPhoto(true)
            setOpenAuthModal(true)     
        }
       else{
        setShowModal(true)
       }
    }
  
    const closeModal =() =>{
        setShowModal(false)
    }


    React.useEffect(() => () => {
        if(addingPhoto && authModal?.uuid){
            openModal()
        }
    
        return () =>{
            setAddingPhoto(false)
        }
    
    }, [authModal]);
   
    return (
        <React.Fragment>
        <div className="d-inline-block"
                            onClick={openModal}
                            style={{
                                width: widthIcon,
                                height: heightIcon,
                                background: "#FFFFFF",
                                color: switchFeatureContainer.color,
                                border: "1px solid " + switchFeatureContainer.color,
                                borderRadius: "39px",
                                textAlign: "center",
                                paddingTop: '5px',
                                font: fontIcon + "px/" + fontHeightIcon + " Futura Md BT",
                                marginRight: "8px",
                                cursor: "pointer",
                            }}>
                            <CameraIcon  style={{marginTop: -5}} width={fontIconPhotoVideo} fill={switchFeatureContainer.color} />{" Add photos"}
                        </div>
                        <PhotoUploadComponent open={showModal} closeModal={closeModal} uuid={authModal?.uuid} dataId={dataId} dataKey={dataKey} isMobile={isMobile}/>
                        </React.Fragment>
    )
}

const mapStateToProps = state => {
    return {
        screenSize: Math.min(state.Setting.htmlBody.bodyClientWidth, document.body.clientWidth),
        isMobile: state.Setting.is_mobile,
        sliderImages: state.Destination.destination.sliderImages,
        generalInfo: state.Destination.destination.generalInfo,
        switchFeatureContainer: state.FormBank.FeatureFormat,
        destinationId: state.Destination.destination.activeId,
        authModal: state.Member.authModal,
    }
}

const mapDispatchToProps = {
    setOpenAuthModal: Actions.setOpenAuthModal,
}

const AddPhotoComponent = connect(mapStateToProps, mapDispatchToProps)(AddPhotoComponent_)
export default AddPhotoComponent
