import * as Actions from '../../../redux/actions'

import React, { Component } from 'react'
import { validate, validate_fields } from "../Common/RegExValidate"

import Header from '../Header/Header'
import Loader from 'react-loader-spinner'
import MemberActionsInitial from "../Common/MemberActions/MemberActionsInitial"
import PageNotFound from '../ErrorPage/PageNotFound'
import { Redirect } from "react-router-dom"
import TimelineWizardContainer from "../Common/TimelineWizardContainer"
import { connect } from 'react-redux'
import { list_container_mode } from '../Common/RegExValidate'
import { member_area_router } from "../Common/RegExValidate"
import SuggestedDestinationContainer from './SuggestedDestinationContainer'

class SuggestedDestinationsPage_ extends Component {
    /* update screen size */
    updateDimensions = () => {
        this.props.setScreenSize(window.screen.width, window.screen.height)
        this.props.setBodyClientSize(document.body.clientWidth, document.documentElement.scrollHeight)
        this.props.setAppMenu(document.body.clientWidth)
    }
    /* initial component */
    componentDidMount() {
        window.addEventListener("resize", this.updateDimensions)
        this.updateDimensions()

        this.props.setPageYOffset(0)
        this.props.setSwitchContainer(member_area_router.destination)
        this.props.setAppMenuBookingButtonStatus(true)
        this.props.setTripDisplayMode(list_container_mode.timeline)
    }
    /* destory component */
    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions)
        this.props.cleanSearchQueryDestination()
        this.props.clearFeatureFormat()
    }
    render() {
        const lang = this.props.lang
        const { preload } = this.props
        return (
            validate(lang, validate_fields.languages) ?
                <div style={{
                    height: "auto",
                }}>
                    {/* {preload ? <Loader
                        type="Rings"
                        color="#19BC9B"
                        className="se-pre-con"
                        height={150}
                        width={150}
                        style={{
                            backgroundColor: "#FFFFFF"
                        }} /> : ""}
                         */}

                    <Header bg_color={"rgba(25,25,25, 1)"} text_color={"var(--mainWhite)"} header_height={"half"} />
                    <TimelineWizardContainer listDisplayFormat={list_container_mode.map} />
                    <SuggestedDestinationContainer props={this.props} />  
                   
                    <MemberActionsInitial />
                </div>
                :
                <Redirect to={"error"} component={PageNotFound} />
        )
    }
}


const mapStateToProps = state => {
    return {
        destinaionListDisplayFormat: state.Destination.destination.destinaionListDisplayFormat,
        preload: state.Setting.preload,
        switch_container: state.Member.switch_container,

        lang: state.Setting.lang,
        tripMode: state.Member.tripMode,
    }
}

const mapDispatchToProps = {
    setBodyClientSize: Actions.setBodyClientSize,

    setScreenSize: Actions.setScreenSize,
    setAppMenu: Actions.setAppMenu,
    setScreenVerticalIsTop: Actions.setScreenVerticalIsTop,
    setAppMenuBookingButtonStatus: Actions.setAppMenuBookingButtonStatus,

    setPageYOffset: Actions.setPageYOffset,
    cleanSearchQueryDestination: Actions.cleanSearchQueryDestination,
    setSwitchContainer: Actions.setSwitchContainer,
    setTripDisplayMode: Actions.setTripDisplayMode,
    clearFeatureFormat: Actions.clearFeatureFormat,

}

const SuggestedDestinationsPage = connect(mapStateToProps, mapDispatchToProps)(SuggestedDestinationsPage_)
export default SuggestedDestinationsPage
