import {
    EnvelopeIcon,
    InfoCircleIcon,
    MapMarkerIcon,
    WebsiteIcon,
} from '../Common/SVGBank'

import EstablishmentMap from './EstablishmentMap'
import React from 'react'
import { connect } from 'react-redux'

const Contact_ = ({ establishment, is_mobile }) => {
    const { generalInfo } = establishment
    const address = generalInfo.address !== undefined && generalInfo.address !== null && generalInfo.address !== "" ? generalInfo.address + ", " : ""
    const country = generalInfo.countryName !== undefined && generalInfo.countryName !== null && generalInfo.countryName !== "" ? generalInfo.countryName + " " : ""
    const postalCode = generalInfo.postalCode !== undefined && generalInfo.postalCode !== null && generalInfo.postalCode !== "" ? generalInfo.postalCode : ""
    const state = generalInfo.stateName !== undefined && generalInfo.stateName !== null && generalInfo.stateName !== "" ? generalInfo.stateName + ", " : ""
    // const fullAddress =  
    return (
        <React.Fragment>
            {generalInfo !== undefined && generalInfo !== null && <div style={{ display: is_mobile ? "" : "flex" }} >
                <div>
                    <div style={{ font: "30px/36px Futura Hv BT", color: "#191919", marginBottom: "30px", }}>Contact</div>
                    <div style={{ font: "18px/18px Futura Md BT", color: "#707070", marginBottom: "15px", }}><MapMarkerIcon fill={"#707070"} style={{ marginRight: "25px", marginBottom: "3px" }} />
                        {address + state + country + postalCode}
                    </div>
                    {generalInfo.phone !== undefined && generalInfo.phone !== null && generalInfo.phone !== "" && <div style={{ font: "18px/18px Futura Md BT", color: "#707070", marginBottom: "15px", }}><InfoCircleIcon fill={"#707070"} style={{ marginRight: "25px", marginBottom: "3px" }} />{generalInfo.phone}</div>}
                    {generalInfo.website !== undefined && generalInfo.website !== null && generalInfo.website !== "" && <div style={{ font: "18px/18px Futura Md BT", color: "#707070", marginBottom: "15px", }}><WebsiteIcon fill={"#707070"} style={{ marginRight: "25px", marginBottom: "3px" }} />{generalInfo.website}</div>}
                    {generalInfo.email !== undefined && generalInfo.email !== null && generalInfo.email !== "" && <div style={{ font: "18px/18px Futura Md BT", color: "#707070", marginBottom: "15px", }}><EnvelopeIcon fill={"#707070"} style={{ marginRight: "25px", marginBottom: "3px" }} />{generalInfo.email}</div>}
                </div>
                <div style={{ flexGrow: "1", marginLeft: is_mobile ? "" : '300px' }}>
                    {window.google !== undefined && generalInfo.latitude !== "" && generalInfo.latitude !== null &&
                        generalInfo.longitude !== null &&
                        generalInfo.longitude !== "" &&
                        <EstablishmentMap lat={generalInfo.latitude} lng={generalInfo.longitude} />}
                </div>
            </div>}
        </React.Fragment >
    )
}

const mapStateToProps = (state) => ({

    establishment: state.FormBank.HotelSearch.establishment,
    is_mobile: state.Setting.is_mobile,
    lang: state.Setting.lang,
    uuid: state.Member.authModal.uuid,

    switchFeatureContainer: state.FormBank.FeatureFormat,
})

const mapDispatchToProps = {

}

const Contact= connect(mapStateToProps, mapDispatchToProps)(Contact_)
export default Contact
