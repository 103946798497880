import * as Actions from '../../../redux/actions'
import React from 'react'
import { connect } from 'react-redux'

class LazyLoadingScrollEventListener_ extends React.Component {
   

    handleScroll() {
        const windowHeight = "innerHeight" in window ? window.innerHeight : document.documentElement.offsetHeight;
        const body = document.body;
        const html = document.documentElement;
        const docHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
        const windowBottom = windowHeight + window.pageYOffset;
        if (windowBottom >= docHeight) {
           this.props.loadMoreAllFeatureList(this.props.activeFeatureId, this.props.loading, this.props.lang,this.props.offset)
        } else {
            this.setState({
                message: 'not at bottom'
            });
        }
    }

loadMore = () => {
    
    if (window.innerHeight + window.scrollY >= document.body.offsetHeight ) {
        console.log(222)
       
    }
}

    componentDidMount() {
        window.addEventListener("scroll", this.loadMore)
    }

    /* destory component */
    componentWillUnmount() {
        window.removeEventListener("scroll", this.loadMore)
    }
    render() { return "" }
}

const mapStateToProps = (state) => {
    return {
    lazyLoading: state.Destination.destination.lazyLoading,
    activeFeatureId: state.Feature.feature.activeId,
    offset: state.Feature.feature.offset,
    lang: state.Setting.lang,
    loading: state.Feature.feature.loading,
    uuid: state.Member.authModal.uuid,
}}

const mapDispatchToProps = {
    setPageYOffset: Actions.setPageYOffset,
    setScreenVertical: Actions.setScreenVertical,
    fetchEstablishmentsLazyloading: Actions.fetchEstablishmentsLazyloading,
    setLazyloadingIsDestination: Actions.setLazyloadingIsDestination,
    loadingFeatureListMoreFeature: Actions.loadingFeatureListMoreFeature,
    loadMoreAllFeatureList: Actions.loadMoreAllFeatureList

}

const LazyLoadingScrollEventListener = connect(mapStateToProps, mapDispatchToProps)(LazyLoadingScrollEventListener_)
export default LazyLoadingScrollEventListener