import * as Actions from '../../../redux/actions'
import Rating from '@material-ui/lab/Rating';
import React from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { priceline_image_boxing, loadingImage, URL_REPLACE } from '../Common/RegExValidate'
import moment from 'moment'
import AddedToTrip from '../Common/AddedToTrip'
import {
    AngleUpIcon,
    PlusSignIcon,
    EnvelopeIcon,
    InfoCircleIcon,
    MapMarkerIcon,
    WebsiteIcon,
    RightArrowIcon,
    LeftArrowIcon
} from '../Common/SVGBank'
import CircularProgress from '@material-ui/core/CircularProgress'
import { HotelFlightSaveModal } from '../FlightsPage/RecapPage/FlightSaveModal';


import { Image } from 'react-bootstrap'

import { useHistory } from "react-router-dom"
import { saveHotelTimeline } from './PaymentPage/payment.thunk';
import { setOpenAuthModal } from '../../../redux/actions';
import { getNearByDestinationCode } from '../FlightsPage/services/Flights.thunks';
import { ALERT_BARS_HANDLE, SET_TIMELINE_WIZARD_STATUS } from '../../../redux/interfaces/types';

const BookingBasicInfo_ = ({ bookingPriceline, lang, uuid, booking_info, booking_bundles, establishment, is_mobile,
    addPricelineHotelToMuvDb, activeTripActivities, hotelSearchForm, timelineWizardStatus,
    isFetchingEstablishments, activeTrip, activeTripDestinationId, activeTripDay, destinationId, }) => {
    const { generalInfo, subtypes } = establishment

    const address = generalInfo.address !== undefined && generalInfo.address !== null && generalInfo.address !== "" ? generalInfo.address + ", " : ""
    const country = generalInfo.countryName !== undefined && generalInfo.countryName !== null && generalInfo.countryName !== "" ? generalInfo.countryName + " " : ""
    const postalCode = generalInfo.postalCode !== undefined && generalInfo.postalCode !== null && generalInfo.postalCode !== "" ? generalInfo.postalCode : ""
    const city = generalInfo.cityName !== undefined && generalInfo.cityName !== null && generalInfo.cityName !== "" ? generalInfo.cityName + ", " : ""
    const state = generalInfo.stateName !== undefined && generalInfo.stateName !== null && generalInfo.stateName !== "" ? generalInfo.stateName + ", " : ""
    const history = useHistory()
    const [bookingHotel, setBookHotel] = React.useState(false);
    const activeTripId = activeTrip.tripId
    const activeDayInTripDestination = activeTripDay.dayInTripDestination
    const activitiesInDestinationDay = activeTrip.activities.filter(a => a.activityDay === activeTripDay.dayInTrip && a.tripDestinationId === activeTripDay.tripDestinationId)
    const lengthActivitiesInDestinationDay = activitiesInDestinationDay !== undefined ? activitiesInDestinationDay.length : 0
    const activitiesInDestinationDayEstablishmentIds = Array.from(activitiesInDestinationDay.map((a) => a.activityEstabID))
    const last_establishmentId = activitiesInDestinationDayEstablishmentIds[lengthActivitiesInDestinationDay - 1] !== undefined ? activitiesInDestinationDayEstablishmentIds[lengthActivitiesInDestinationDay - 1] : ""

    const activeTripDestinationIds = Array.from(new Set(activeTrip.tripDestinations.map(d => d.id)))
    const tripDuration = activeTrip.tripDuration

    const availableDeleteActivities = activeTripActivities.filter(a => a.activityEstabID === establishment.establishmentID)
    //console.log({activeTripActivities, availableDeleteActivities});



    const handleAddHotelToTripDestinationInTrip = (establishment, hotel_form_st, lang, uuid, activeTripDestinationId, activeDayInTripDestination, establishmentID, activeTripId, last_establishmentId, tripDuration, destinationId, activeTripDestinationIds) => {
        //console.log("handleAddHotelToTripDestinationInTrip hotel_form_st ",hotel_form_st);
        //console.log("handleAddHotelToTripDestinationInTrip hotelSearchForm ",hotelSearchForm);
        const lengthOfStay = moment(hotelSearchForm.check_out).diff(moment(hotelSearchForm.check_in), "days")
        const establishmentPricelineID = establishment.generalInfo.establishmentPricelineID

        console.log({
            establishmentPricelineID, lengthOfStay, lang, uuid, activeTripDestinationId,
            activeDayInTripDestination, establishmentID, activeTripId, last_establishmentId,
            tripDuration, destinationId, activeTripDestinationIds
        })

        addPricelineHotelToMuvDb({
            establishmentPricelineID, lengthOfStay, lang, uuid, activeTripDestinationId,
            activeDayInTripDestination, establishmentID, activeTripId, last_establishmentId,
            tripDuration, destinationId, activeTripDestinationIds
        })
        //fetchHotelEstablishment(hotel_id, ppn_bundle, hotelSearchForm)
        //addActivityToTripDestinationInTrip(lang, uuid, activeTripDestinationId, activeDayInTripDestination, establishmentID, activeTripId, last_establishmentId, tripDuration, destinationId, activeTripDestinationIds)
        //document.getElementById(activeTripDay.tripDestinationId + "_" + activeTripDay.dayInTripDestination + "_" + activeTripDay.dayInTrip) !== null && document.getElementById(activeTripDay.tripDestinationId + "_" + activeTripDay.dayInTripDestination + "_" + activeTripDay.dayInTrip).scrollIntoView({ behavior: 'smooth', block: 'start' })
    }


    const imgWidth = 168
    const authModal = useSelector(state => state.Member.authModal);
    const nearByDestination = useSelector(state => state.Flights.nearByDestination);

    const handleHotelSearchForm = () => {
        let hotel_form_state = { ...hotelSearchForm }
        console.log(hotel_form_state);
        //setHotelSearchForm(hotel_form_state);

        // open new page with params

        history.push("/hotel-search?city_id=" + hotel_form_state.city_id
            + "&check_in=" + hotel_form_state.check_in
            + "&check_out=" + hotel_form_state.check_out
            + "&rooms=" + (hotel_form_state.rooms ? hotel_form_state.rooms : 1)
            + "&guests=" + (hotel_form_state.adults ? hotel_form_state.adults : 1)
            + "&where=" + (hotel_form_state.where.replace(URL_REPLACE, "_")))
        // http://host/hotel-search?city_id=800049480&check_in=2020-02-02&check_out=2020-02-13&room=1&guests=2  

    }
    const dispatch = useDispatch()



    React.useEffect(() => {

        // if (arrivalCode) {
        //     dispatch(getNearByDestinationCode(arrivalCode));
        // }

        //    dispatch(getFlightContractThunk())
    }, [])


    const goToDestination = () => {
        if (nearByDestination) {
            const url = "/destination/" + nearByDestination.name.replace(URL_REPLACE, "-") + "/" + 1 + "/" + nearByDestination.destinationId;
            history.push(url.toLowerCase())
            ///window.open(url.toLowerCase());
        }
    }

    const isLoggedIn = () => {
        return authModal.uuid && authModal?.uuid?.length > 0;
    }

    const [showSaveModal, setSaveModal] = React.useState(false);

    const toggleSaveModal = () => {
        setSaveModal(!showSaveModal)

        if (showSaveModal) {
            setBookHotel(false)
        }
    }


    const saveBooking = () => {
        if (isLoggedIn()) {
            if (activeTripId && timelineWizardStatus) { ///timeline open
                addToTimeline(activeTripId)
            }
            if (activeTripId && !timelineWizardStatus) {  //timeline closed
                toggleSaveModal()
            }
            else{
                addToTimeline(null)
            }
           
        } else {
            dispatch(setOpenAuthModal(true));
        }
    }

    const addToTimeline = async (tripId) => {

        const booking = booking_bundles && booking_bundles.length > 0 ? booking_bundles[0] : null;
        if (booking) {
            const saveData = {
                postData: {
                    tripId: tripId,
                    pricelineHotelId: booking_info?.hotel_id || "",
                    booking_trip_number: "",
                    booking_status: "",
                    booking_status_code: "",
                    checkIn: booking_info.check_in || "",
                    checkOut: booking_info.check_out || "",
                    displaySymbol: booking?.rate_symbol,
                    displayCurrency: booking?.rate_currency,
                    displayTotalFare: booking?.rate_total
                },
                uuid: uuid || null
            }

          
            const response = await dispatch(saveHotelTimeline(saveData));

            //only for hotel
            if (bookingHotel) {
                history.push('/hotel/payment')
            }
            else {
                // const destinationID = response?.payload?.result?.destinationId;
                // const destinationName = response?.payload?.result?.destinationName;
                // const tripId = response?.payload?.payload?.result?.tripID
              
                ///open when saving

            
                dispatch({
                    type: SET_TIMELINE_WIZARD_STATUS,
                    status: true
                })

                const destination = response?.payload?.result?.targetDestination;
                const destName = destination?.partialUri || destination?.nameInline;
                const destId =  destination?.destinationId;
               

               

                if (destName && destId) {
                   setTimeout(() => {
                    const url = "/destination/" + destName + "/" + 1 + "/" + destId;
                    history.push(url)
                   }, 1000);
                }
            }
           
            setBookHotel(false);

        }
    }

    const navigateBooking = (destinationName, destinationID) => {
        // setBookFlight(false)
        if (destinationName && destinationID) {
            const url = "/destination/" + destinationName + "/" + 1 + "/" + destinationID;
            history.push(url)
        }


    }



    //handle book clicked
    React.useEffect(() => {
        if (bookingHotel) {
            if (isLoggedIn()) {

                if (activeTripId && timelineWizardStatus) { ///timeline open
                    addToTimeline(activeTripId)
                }
                if (activeTripId && !timelineWizardStatus) { //timeline closed
                    toggleSaveModal()

                }
                else { ///no timeline
                    addToTimeline(null)
                }

            }
            else {
                dispatch({
                    type: ALERT_BARS_HANDLE,
                    message_type: "error",
                    message: "You must log in to save this"
                })
            }
        }
    }, [bookingHotel])

    const bookHotel = async () => {
        setBookHotel(true);
    }




    return (<div>
        <div style={{ border: "2px solid rgb(218,218,218,1)", marginRight: is_mobile ? 0 : "25px", padding: "25px", paddingBottom: 5, }}>
            {booking_info !== undefined && booking_info !== null && booking_info.name !== undefined && booking_info.name !== undefined && booking_info.name !== null && booking_info.name !== "" && <div>
                <div style={{ float: "right", display: "inline-block" }}>
                    <div style={{ font: is_mobile ? "14px/14px Futura Lt BT" : "18px/21px Futura Lt BT", textAlign: "center" }}>{Number(booking_info.reviewsAverage).toFixed(1)}</div>
                    <div style={{ font: is_mobile ? "14px/14px Futura Lt BT" : "18px/21px Futura Lt BT", textAlign: "center" }}>({booking_info.rating_count} reviews)</div>
                </div>
                <div style={{ font: is_mobile ? "18px/18px Futura Hv BT" : "24px/24px Futura Hv BT", color: "#000000", marginBottom: 0, }}>{booking_info.name}
                    <span className=" timeline_rating_color padding_left_10 padding_right_10 btn_rounded" style={{ marginBottom: "5px", }}>
                        <span style={{ font: "16px/19px Futura Md BT", color: "#707070" }}><Rating name="timeline_rating" precision={0.5} max={5} value={parseFloat(booking_info.establishmentStarRating)} disabled={true} /> {"(" + booking_info.establishmentStarRating + ")"}</span>
                    </span></div>
                <div style={{ font: is_mobile ? "12px/22px Futura Md BT" : "18px/24px Futura Md BT", color: "#707070", marginBottom: "15px" }}>{booking_info.address}, {city + state + country + postalCode}</div>

                <div style={{ font: is_mobile ? "12px/18px Futura Lt BT" : "18px/24px Futura Lt BT", color: "#707070", }}>{booking_info.num_nights} nights, {booking_info.num_rooms} rooms</div>
                <div style={{ font: is_mobile ? "12px/18px Futura Lt BT" : "18px/24px Futura Lt BT", color: "#707070", }}>
                    <div style={{ width: "8rem", display: "inline-block" }} >Check-in:</div>
                    <div style={{ font: is_mobile ? "14px/18px Futura Md BT" : "20px/24px Futura Md BT", display: "inline-block", color: "#707070", }}>{moment(booking_info.check_in).format('ddd, MMMM Do, yyyy')}</div>
                </div>
                <div style={{ font: is_mobile ? "12px/18px Futura Lt BT" : "18px/24px Futura Lt BT", color: "#707070", }}>
                    <div style={{ width: "8rem", display: "inline-block" }} >Check-out:</div>
                    <div style={{ font: is_mobile ? "14px/18px Futura Md BT" : "20px/24px Futura Md BT", display: "inline-block", color: "#707070", }}>{moment(booking_info.check_out).format('ddd, MMMM Do, yyyy')}</div>
                </div>
                <div style={{ width: "100%", marginTop: 35 }}>
                    <div style={{ display: "inline-block", width: imgWidth, marginTop: "1rem", marginRight: "2rem", marginBottom: "1rem" }}>
                        <Image style={{ width: "100%", objectFit: "cover", objectPosition: "center", width: imgWidth, height: imgWidth }}
                            src={booking_info !== undefined && booking_info !== null ?
                                priceline_image_boxing(booking_info.estabPicture, imgWidth, imgWidth) :
                                loadingImage.src + "?func=crop&w=" + Math.floor(imgWidth) + "&h=" + Math.floor(imgWidth)}
                        />
                    </div>
                    <div style={{ display: "inline-block", minWidth: 210, width: "calc(100% - 200px)", verticalAlign: "top", marginTop: 10 }}>
                        <div style={{ font: is_mobile ? "18px/22px Futura Hv BT" : "20px/32px Futura Md BT", color: "#707070", marginBottom: 5 }}>{booking_info.room_text}{booking_info?.occupancyLimit ? `(max occupancy: ${booking_info?.occupancyLimit})` : ""}</div>

                        {booking_info?.mandatory_fee &&
                            <div style={{ font: is_mobile ? "10px/22px Futura Md BT" : "18px/32px Futura Lt BT", color: "#707070" }}>
                                Resort fee: {booking_info?.rate_symbol}{booking_info?.mandatory_fee?.display_total} (Payable at the property)

                                </div>}

                        {generalInfo.phone !== undefined && generalInfo.phone !== null && generalInfo.phone !== "" &&
                            <div style={{ font: is_mobile ? "12px/22px Futura Md BT" : "18px/32px Futura Md BT", color: "#707070" }}><InfoCircleIcon fill={"#707070"} style={{ marginRight: "25px", marginBottom: "3px" }} />{generalInfo.phone}</div>}
                        {generalInfo.website !== undefined && generalInfo.website !== null && generalInfo.website !== "" &&
                            <div style={{ font: is_mobile ? "12px/22px Futura Md BT" : "18px/32px Futura Md BT", color: "#707070" }}><WebsiteIcon fill={"#707070"} style={{ marginRight: "25px", marginBottom: "3px" }} />{generalInfo.website}</div>}
                        {generalInfo.email !== undefined && generalInfo.email !== null && generalInfo.email !== "" &&
                            <div style={{ font: is_mobile ? "12px/22px Futura Md BT" : "18px/32px Futura Md BT", color: "#707070" }}><EnvelopeIcon fill={"#707070"} style={{ marginRight: "25px", marginBottom: "3px" }} />{generalInfo.email}</div>}



                    </div>
                </div>




            </div>}
        </div>

        <div style={{ paddingRight: "25px", paddingTop: 35, marginBottom: 40 }}>
            {hotelSearchForm !== undefined && hotelSearchForm !== null && hotelSearchForm.city_id !== "" && <div onClick={() => handleHotelSearchForm()}
                style={{ cursor: "pointer", font: "14px/60px Futura Lt BT", backgroundColor: '#DFDFDF', color: "#7F7F7F", display: "inline-block", width: 202, height: 60, padding: "1px 10px 10px 0px" }} > <LeftArrowIcon width={15} height={15} fill={"#7F7F7F"} style={{ marginRight: 13, marginLeft: 20, color: "#7F7F7F" }} />Back to search results</div>}

            {booking_bundles !== undefined && booking_bundles !== null && booking_bundles.length > 0 &&
                <div style={{
                    cursor: "pointer", float: "right", display: "inline-block",
                    marginTop: is_mobile ? 27 : 0
                }}>

                    <div onClick={() => {
                        bookHotel()
                    }}
                        style={{ cursor: "pointer", marginLeft: "auto", marginBottom: 10, font: "14px/60px Futura Hv BT", textAlign: "left", backgroundColor: 'var(--mainGreen)', color: "white", width: 202, height: 60, padding: "2px 5px 10px 29px" }} >Book <RightArrowIcon width={15} height={15} style={{ marginLeft: "96px" }} /></div>

                   {<div onClick={saveBooking}
                        style={{ float: "right", cursor: "pointer", color: "#000000", font: "14px/14px Futura Lt BT", padding: "0px 10px", display: "inline-block" }}>
                        Not ready to book yet, <u>save this hotel in your trip</u></div>}
                </div>}
            <HotelFlightSaveModal navigateBooking={navigateBooking} showModal={showSaveModal} toggleModal={toggleSaveModal} addToTimeline={addToTimeline}
                type={"hotel"} />

            {timelineWizardStatus && <div style={{ textAlign: "right", fontSize: ".8rem", }}>
                {availableDeleteActivities.length > 0 && !isFetchingEstablishments &&
                    <AddedToTrip
                        establishmentName={establishment.establishmentName}
                        establishmentStarRating={establishment.establishmentStarRating}
                        availableDeleteActivities={availableDeleteActivities}
                        textFont={"14px/14px Futura Hv BT"}
                        text={"Added to trip"}
                    />}
                {/* {!isFetchingEstablishments ? <React.Fragment>
                        {availableDeleteActivities.length > 0 ?
                            <div onClick={() => handleAddHotelToTripDestinationInTrip(establishment, hotelSearchForm, lang, uuid, activeTripDestinationId, activeDayInTripDestination, establishment.establishmentID, activeTripId, last_establishmentId, tripDuration, destinationId, activeTripDestinationIds)} 
                                 style={{ cursor: "pointer", color: "#707170", font: "12px/12px Futura Lt BT", padding: "0px 10px", display: "inline-block" }}>
                                     <PlusSignIcon fill={"#707170"} width={7} height={10} style={{ marginRight: "5px" }} />add hotel again</div>
                            : <div onClick={() => handleAddHotelToTripDestinationInTrip(establishment, hotelSearchForm, lang, uuid, activeTripDestinationId, activeDayInTripDestination, establishment.establishmentID, activeTripId, last_establishmentId, tripDuration, destinationId, activeTripDestinationIds)} 
                                   style={{ cursor: "pointer", color: "#000000", font: "14px/14px Futura Lt BT", padding: "0px 10px", display: "inline-block" }}>
                                    Not ready to book yet, save this hotel in your trip <PlusSignIcon width={10} height={13} fill={"#000000"} style={{ marginLeft: "5px" }} /></div>
                        }
                    </React.Fragment> : <CircularProgress
                            disableShrink
                            size={15}
                            style={{
                                color: "#6A9EEC",
                                textAlign: "right",
                                marginTop: "15px",
                                marginRight: "5px",
                            }} />
                    } */}
            </div>}
        </div>
    </div>
    )
}

const mapStateToProps = (state) => ({

    establishment: state.FormBank.HotelSearch.establishment,
    is_mobile: state.Setting.is_mobile,
    lang: state.Setting.lang,
    uuid: state.Member.authModal.uuid,

    switchFeatureContainer: state.FormBank.FeatureFormat,
    timelineWizardStatus: state.FormBank.TimelineWizard.status,
    isFetchingEstablishments: state.FormBank.TimelineWizard.isFetchingEstablishments,
    activeTripActivities: state.FormBank.TimelineWizard.activeTrip.activities,
    activeTrip: state.FormBank.TimelineWizard.activeTrip,
    activeTripDestinationId: state.FormBank.TimelineWizard.activeTripDestinationId,
    activeTripDay: state.FormBank.TimelineWizard.activeDay,
    bookingPriceline: state.FormBank.BookingPriceline,
    booking_info: state.FormBank.BookingPriceline.booking_info,
    hotelSearchForm: state.FormBank.HotelSearch.hotel_form_state,
})

const mapDispatchToProps = {
    addPricelineHotelToMuvDb: Actions.addPricelineHotelToMuvDb,

}

const BookingBasicInfo = connect(mapStateToProps, mapDispatchToProps)(BookingBasicInfo_)
export default BookingBasicInfo
