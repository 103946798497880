import Slider from "react-slick"
import React, { Component, useRef } from 'react'
import { useHistory } from "react-router-dom"
import { ArrowLeftIcon, ArrowRightIcon } from "../Common/SVGBank"
import { image_boxing, list_container_mode } from "../Common/RegExValidate"
import { connect, useSelector } from "react-redux"
import { Image } from "react-bootstrap"
import { TrackEvent } from "../../../utility/utility"
import './SuggestedDestinationsPage.css'
import * as Actions from '../../../redux/actions'

const SliderArrowLeft = ({ className, to, onClick }) => (
    <button type="button"
      style={{ marginLeft: "3%", marginTop: -40 }}
      onClick={onClick} className={`button button--text button--icon ${className}`} aria-label={to}>
      <ArrowLeftIcon className="icon" icon={to} style={{
        height: "60px",
        width: "60px",
      }} />
    </button>
  )
  const SliderArrowRight = ({ className, to, onClick }) => (
    <button type="button" style={{ marginRight: "6%",marginTop: -40 }}
      onClick={onClick} className={`button button--text button--icon ${className}`} aria-label={to}>
      <ArrowRightIcon className="icon" icon={to} style={{
        height: "60px",
        width: "60px",
      }} />
    </button>
  )

export const SuggestedMapSlider_ = ({setPageYOffset,setTripDisplayMode,
  hoverImage, setHoverImage,is_mobile,setMapCenter,setMapZoom,doubleClick})=>{
  const history = useHistory()
  const sliderArrowRight = <SliderArrowRight />
  const suggestedDestinationsMap = useSelector(state => state.ActiveTrip.suggestedDestinationsMap);
  const sliderRef = useRef();
const left_element = "left_element" 
const arrowsValue = true;

    let setting = {
        touchMove: false,
        dots: false,
        infinite: true,
        className: "center",
        centerMode: true,
        centerPadding: "60px",
        arrows: arrowsValue,
        speed: 500,
        lazyLoad: false,
        autoplay: false,
        draggable: true,
        slidesToShow: 6,
        slidesToScroll: 1,
        swipeToSlide: true,
        pauseOnHover: true,
        cssEase: 'ease-out',
        useTransform: false,
        focusOnSelect: true,
        prevArrow: <SliderArrowLeft to="prev" />,
        nextArrow: sliderArrowRight,
        beforeChange: (index, newIndex) => {
        },
        
      }

      const linkPage = (destinationId, city, state, country, uri) => {
       // TrackEvent('HOME',"Click suggested destination",destinationId)
        const url = "/destination/" + uri + "/" + 1 + "/" + destinationId;
        window.open(url.toLowerCase(), "_blank");
        // history.push(url.toLowerCase())
      }

      const onHoverImage = (destinationId)=>{
        setHoverImage(destinationId)
      }

      const removeHover = ()=>{
        setHoverImage(null)
      }
      

      let heightContainer = "200"
      let imageW = "160"
      let imageH = "180"


      const goToMap = () => {
        setTripDisplayMode(list_container_mode.map)
        setPageYOffset(0)
    }

      const getSelectedIndex  = ()=>{
        if(suggestedDestinationsMap && suggestedDestinationsMap?.length>0){
          const index =  suggestedDestinationsMap.findIndex(x => x?.destinationId === hoverImage);
          return index
        }
        return null;
      } 

      const doubleClickImage = (item)=>{
        const uri = item?.partialUri;

        if(uri){
          const url = "/destination/suggested-" +uri + "/" + 1 + "/" + item?.destinationId;
          window.open(url.toLowerCase(), "_blank");
          // history.push(url.toLowerCase())
        }
      }

      React.useEffect(() => {
        if(hoverImage){
          const index =  getSelectedIndex()+2
          sliderRef.current.slickGoTo(index,true);
        }
     }, [hoverImage])

     
      return (
        <div className="slider">
        {suggestedDestinationsMap !== undefined && suggestedDestinationsMap.length > 0 
        && <Slider ref={sliderRef}  {...setting} className={ 'suggestedDestinationSlider'} style={{ height: heightContainer,
         }}>
            {suggestedDestinationsMap.map((item, idx) => {
                return (
                    <React.Fragment key={idx}>
                        <div className="item"
                            style={{ cursor: "pointer",width:'100%'
                          }}
                            
                         
                        >
                            <Image className="min_width_100 img_fit_cover"
                                onDoubleClick={()=>{doubleClickImage(item)}}
                                style={{
                                  border:hoverImage  && hoverImage ===item?.destinationId ? "5px solid var(--mainOrange)" :"none",
                                }}
                                onMouseEnter={()=>onHoverImage(item?.destinationId)}
                                onMouseLeave={removeHover}
                                src={image_boxing(item.destinationImage, imageW, imageH)}
                                alt="suggested destination" />
                           <div 
                           className="description"
                           onClick={() => linkPage(item.destinationId, item.suggestCityName, item.suggestStateName, item.suggestCountryName, item.partialUri)}
                           style={{ cursor: "pointer" }}>
                           <h2 style={{bottom:15,position:"absolute", font:  '12px/28px Futura Md BT' }}>
                                {item?.countryName}
                            </h2>

                            <h2 style={{bottom:10,position:"absolute", font:  '14px/28px Futura Md BT' }}>
                            {item?.name}
                            </h2>

                            </div>
                        </div>
                    </React.Fragment>
                )
            })
            }
        </Slider>}
    </div>
      )

}

const mapStateToProps = (state) => {
    return {
      screenSize: state.Setting.htmlBody.bodyClientWidth,
      lang: state.Setting.lang,
      homepage: state.Home.homepage,
      is_mobile: state.Setting.is_mobile,
    }
  }

const mapDispatchToProps = {
    setPageYOffset: Actions.setPageYOffset,
    setTripDisplayMode: Actions.setTripDisplayMode,
   
}
  const SuggestedMapSlider = connect(mapStateToProps,mapDispatchToProps)(SuggestedMapSlider_)
export default SuggestedMapSlider