import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import React from 'react'

export function EstablishmentDescription({ textLength, is_mobile, bottom, description, boxDescription, fontDescription, colorDescription, height }) {
  const [open, setOpen] = React.useState(false)

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen)

  }
  const handleClose = event => {
    setOpen(false)
  }

  const max_length = textLength ?textLength : 520;

  return (
    <ClickAwayListener onClickAway={handleClose}>


      {is_mobile ?

        <div style={{
          cursor: 'pointer',
          overflowY: "auto",
          // height: 140,
          font: fontDescription,
          height: "120px",
          color: colorDescription,
          marginBottom: bottom ? bottom : 7
        }}
          dangerouslySetInnerHTML={{ __html: description }} />
        :
        <React.Fragment>
          {!open ? <div >
            <div style={{
              cursor: 'pointer',
              font: "16px Futura Lt BT",
              color: colorDescription,
              width: '98%',
              height: height ? height : "160px",
              overflowY: "hidden",
              marginBottom: bottom ? bottom : 60
            }} >
              {description !== "" && <span style={{ font: fontDescription ? fontDescription : "16px Futura Lt BT", color: "var(--mainGrey)" }}
                // dangerouslySetInnerHTML={{ __html: description}} 
                dangerouslySetInnerHTML={{ __html: description?.slice(0, max_length) }}
              />}
              {description !== "" && description.length > max_length &&
                <span onClick={() => handleToggle()}
                  style={{
                    font: fontDescription ? fontDescription : "16px/15px Futura Lt BT",
                    cursor: "pointer"
                  }} >...<span style={{ font: fontDescription ? fontDescription : "16px/15px Futura Lt BT", color: "var(--mainGreen)", }}>more</span></span>}
            </div>

          </div>
            :
              <div style={{
                cursor: 'pointer',
                overflowY: "auto",
                font: fontDescription ? fontDescription : "16px Futura Lt BT",
                marginBottom: bottom ? bottom : 60,
                color: colorDescription ||"var(--mainGrey)",
                width: '98%',
                height: "160px",
              }} >
                    {description !== "" && <span style={{ font: fontDescription ? fontDescription : "16px Futura Lt BT", color: "var(--mainGrey)" }}
                // dangerouslySetInnerHTML={{ __html: description}} 
                dangerouslySetInnerHTML={{ __html: description}}
              />}
              <span onClick={() => handleToggle()}
                style={{
                  font: fontDescription ? fontDescription : "16px/15px Futura Lt BT",
                  cursor: "pointer"
                }} ><span style={{ font: fontDescription ? fontDescription : "16px/15px Futura Lt BT", color: "var(--mainGreen)", }}>less</span></span>
            </div>
          }
        </React.Fragment>
      }

    </ClickAwayListener>
  )
}