import {
    FETCH_UUID_FAILURE,
    FETCH_UUID_SUCCESS,
} from "../interfaces/types"

const initialState = {
    member_uuid: '',
    handle: '',
    error: '',
}

/* get uuid reducer */
export default (state = initialState, action) => {
    switch (action.type) {
        case FETCH_UUID_SUCCESS:
            return {
                ...state,
                member_uuid: action.payload,
                    handle: action.handle,
                    error: ''
            };
        case FETCH_UUID_FAILURE:
            return {
                ...state,
                member_uuid: 'Handle Not found',
                    handle: action.handle,
                    error: action.payload
            };
        default:
            return {
                ...state
            }
    }
}