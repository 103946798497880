import "./VideoGallery";

import * as Actions from '../../../redux/actions';

import {
    Col,
    Image,
    Row
} from 'react-bootstrap';
import React, { Component } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PropTypes } from "prop-types"
import YouTube from "react-youtube";
import { connect } from 'react-redux'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';

class VideoGallery_ extends Component {
    videoOnReady(event){
       const player = event.target;
       player.pauseVideo();
    //    console.log((player.getDuration() / 60).toFixed(2))
    }
    render() { 
        const { trip, youtube_id, video_desc, screenSize} = this.props;
        if (!trip.tripVideoGallery && trip.tripVideoGallery === undefined ){
            return(<div className="futura_md_bt_18_21_lightGray pl-2">No video found.</div>);
        }        
        else{
            if (trip.tripVideoGallery.length > 0 && trip.tripVideoGallery[0] !== undefined && youtube_id ===""  )this.props.displayVideo(trip.tripVideoGallery[0].videoPath, "Beautiful Charles Bridge in Prague, an absolute must despite the crowds, Snow give it an even more fabulous look");
       
            let containerH = screenSize / 8;
            containerH = (containerH < 96) ? 150 : (containerH > 250 ? 250 : containerH);
            let videoSize = containerH * 2.6;
            // videoSize = (videoSize < 450) ? 450 : (videoSize > 600 ? 600 : videoSize);
            // const videoId = trip.tripVideoGallery[trip.tripVideoGallery.length - 1].videoPath;
            let opts = {
                height: videoSize,
                width: '100%',
                playerVars: { // https://developers.google.com/youtube/player_parameters
                    autoplay: 0,
                    controls: 1,
                    disablekb: 0,
                    fs: 0,
                    hl: "en",
                    iv_load_policy: 3,
                    loop: 0,
                    modestbranding: 1,
                    rel: 0,
                    cc_load_policy: 1,
                    enablejsapi:0
                } 
            };
        
            return (
                <div>
                    <Row>
                        <Col sm={12} md={8} lg={8} xl={8} className="m-0 text-center">

                            <YouTube
                                videoId={youtube_id}
                                opts={opts}
                                onReady={this.videoOnReady}
                            />
        <div className="p-3 text-left futura_md_bt_18_21_lightGray" style={{border: "rgb(232, 232, 232)" ,borderStyle: "none solid solid double", borderWidth: "0 1px 5px 1px"}}>{video_desc}</div>
                        </Col>
                        <Col sm={12} md={4} lg={4} xl={4} className="m-0 p-0 text-center">
                            <div className="row p-0 m-0">
                                {trip.tripVideoGallery.map((video, index) => {
                                    return (
                                    index < 5 ?
                                            <Col sm={6} md={6} lg={6} xl={6} key={index} className="text-center padding_top_15 padding_bottom_15 w-50">
                                                <Image src={"https://amhqajbken.cloudimg.io/v7/https://img.youtube.com/vi/"+video.videoPath+"/maxresdefault.jpg?func=crop&w=250&h=250"} style={{ height: containerH }} 
                                                        onClick={()=>this.props.displayVideo(video.videoPath, "Beautiful Charles Bridge in Prague")}/>
                                            </Col>
                                            : ""
                                    )
                                })}
                                <Col sm={6} md={6} lg={6} xl={6} key={6} className="text-center margin_top_5 margin_bottom_5 position-relative w-50" style={{ height: containerH }}>
                                    <div className="vertical_center w-100 text-center futura_md_bt_24_29_green">See more<FontAwesomeIcon icon={faAngleDown} size="1x" className="text_muv ml-2" /></div>
                                </Col>
                            </div>
                        </Col>


                    </Row>
                </div>
            )
        } 
    }
}



VideoGallery_.propTypes = {
    trip: PropTypes.object.isRequired,
    tripId: PropTypes.string.isRequired,
    lang: PropTypes.string.isRequired,
}

const mapStateToProps = state => {
    return {
        trip: state.PublicTrip.trip,
        tripId: state.PublicTrip.tripId,
        lang: state.Setting.lang,
        youtube_id: state.DisplayVideo.youtube_id,
        video_desc: state.DisplayVideo.video_desc,
        screenSize: state.Setting.htmlBody.bodyClientWidth
        
    }
};
const mapDispatchToProps = {
    displayVideo: Actions.displayVideo,
}

export const VideoGallery = connect(mapStateToProps, mapDispatchToProps)(VideoGallery_)
