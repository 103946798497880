import * as Actions from '../../../redux/actions'

import { Navbar } from 'react-bootstrap'
import React from 'react'
import { connect } from 'react-redux'
import PrintLogo from './PrintLogo';

/* header component */
class PrintHeader_ extends React.Component {
  componentDidMount() {
    this.props.settAppMenuBackGroundColor(this.props.bg_color)
    this.props.setterAppMenuTopBarTextColor(this.props.text_color)
  }
  render() {
    const { app_menu_height, is_mobile, backGroundColor, header_height,authModal, appMenuBookingButtonStatus, stars, switchFeatureContainer, switch_container } = this.props
    const getColor = () =>{
      if(switchFeatureContainer && switchFeatureContainer.featureId.length > 0){
        return switchFeatureContainer.color;
      }
      return "#19BC9B";
    }
    const lengthStars = stars.length

    const notLoggedIn = () =>{
      return authModal.uuid === ""
  }

    return (
      <React.Fragment>
        {!is_mobile && <Navbar sticky="top" expand="lg" className="pt-0 pb-0"
          style={{
            height: app_menu_height/2,
            boxShadow: appMenuBookingButtonStatus ? "0px 6px 12px #0000001A" : "",
            backgroundColor: backGroundColor
          }}>
          <div>
            <PrintLogo header_height={header_height} height={app_menu_height/2} />
          
          </div>
      
  
        </Navbar>}
      </React.Fragment >
    )
  }
}


const mapStateToProps = (state) => {
  //console.log(state)
  return {
    app_menu_height: state.Setting.appMenu.app_menu_height,
    backGroundColor: state.Setting.appMenu.backGroundColor,
    appMenuBookingButtonStatus: state.Setting.appMenu.appMenuBookingButtonStatus,
    is_mobile: state.Setting.is_mobile,
    switchFeatureContainer: state.FormBank.FeatureFormat,
    stars: state.MemberActions.fetchStarSets.stars,
    authModal: state.Member.authModal,
    switch_container: state.Member.switch_container,
  }
};
const mapDispatchToProps = {
  settAppMenuBackGroundColor: Actions.settAppMenuBackGroundColor,
  setterAppMenuTopBarTextColor: Actions.setterAppMenuTopBarTextColor,
};
const PrintHeader = connect(mapStateToProps, mapDispatchToProps)(PrintHeader_)
export default PrintHeader;