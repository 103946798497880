import * as Actions from '../../../../redux/actions'
import React from 'react';
import { connect } from 'react-redux'
import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import {
    RightArrowIcon,
    TimesIcon,
} from '../SVGBank'

import {
    EmailAddressLoginTextField,
    EmailAddressTextField,
    FirstNameTextField,
    LastNameTextField,
    PasswordConfirmTextField,
    PasswordEye,
    PasswordEyeLogin,
    PasswordLoginTextField,
    PasswordTextField,
} from "../FormComponentsBank"

import {
    loginModalUseStyles,
    registerPasswordUseStyles,
    registerTextFieldUseStyles,
} from "../ComponentsStylingBank"

const SignIn_ = ({ 
    authModal, 
    emailAddressLoginTextField,
    emailAddressTextField, 
    firstNameTextField,
    lastNameTextField,
    passwordLoginTextField,
    passwordTextField,
    setRouteAuthModal, 
    setOpenAuthModal,
    registerMemberAccount,
    loginMemberAccount,
    }) => {

    const classesLaptopLogin = loginModalUseStyles();
    //const classesLaptopRegister = registerModalUseStyles();
    const classesTextField = registerTextFieldUseStyles();
    const classesPassword = registerPasswordUseStyles();

    let errorInput = emailAddressTextField.emailAddress === ""
    || emailAddressTextField.error
    || firstNameTextField.firstName === ""
    || firstNameTextField.error
    || lastNameTextField.lastName === ""
    || lastNameTextField.error
    || passwordTextField.password === ""
    || passwordTextField.error
    || passwordTextField.passwordConfirmed === ""
    || passwordTextField.passwordConfirmedError
    let arrow_icon_size = 19
    let arrow_font = "18px/16px Futura Md BT"
    let arrow_container_width = "175px"
    let arrow_container_height = "60px"
    let arrow_container_padding_left = "10"
    let padding_l_r = "15"
    let col = "col-6"
    let eye_top = "45%"
    let eye_right = "55%"
    let eye_top_confirm = "45%"
    let eye_right_confirm = "30px"
    let translateY = "translateY(75px)"
    let classes = classesLaptopLogin

    return (
        <React.Fragment>
            <div>
                <Modal
                    open={authModal.open}
                    onClose={() => setOpenAuthModal(false)}
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 1000,
                    }}
                 >
                    {authModal.route === "Login" ?
                        <div style={{
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)'

                        }} className={classes.paper}>
                            <div className="d-flex">
                                <div style={{
                                    font: "24px/24px Futura Hv BT",
                                    color: "#191919",
                                    paddingLeft: '30px',
                                    paddingBottom: '30px',
                                    paddingTop: '15px',
                                    flexGrow: "1",
                                }}>Sign In</div>
                                <div
                                    style={{
                                        cursor: "pointer",
                                    }}
                                    onClick={() => setOpenAuthModal(false)}><TimesIcon style={{ marginTop: "15px" }} width={'30'} height={'30'} /></div>
                            </div>
                            <div>
                                <div className={"padding_left_right_" + padding_l_r}>
                                    <div className={classesTextField.root}>
                                        <EmailAddressLoginTextField
                                            label="Email Address or Username"
                                            required={true}
                                            id="email_address_login"
                                        />
                                    </div>
                                </div>

                                <div className="position-relative">
                                    <div style={{
                                        marginTop: "10px",
                                        paddingLeft: padding_l_r + "px",
                                        paddingRight: padding_l_r + "px",
                                    }}>
                                        <div className={classesPassword.root}>
                                            <PasswordLoginTextField
                                                label="Password"
                                                required={true}
                                                id="password_login"
                                            />
                                        </div>
                                    </div>

                                    <div className="position-absolute text-right d-inline-block"
                                        style={{
                                            right: "8%",
                                            top: "40%",
                                        }}>
                                        <PasswordEyeLogin />
                                    </div>

                                </div>
                            </div>
                            <div className="position-relative margin_top_20">
                                <div
                                    onClick={
                                        () => loginMemberAccount(
                                            emailAddressLoginTextField.emailAddressOrUsername,
                                            passwordLoginTextField.password)}
                                    style={{
                                        cursor: "pointer",
                                        backgroundColor: "var(--mainGreen)",
                                        paddingLeft: arrow_container_padding_left + "px",
                                        height: arrow_container_height,
                                        width: arrow_container_width,
                                        marginTop: "10px",
                                        display: "block",
                                        position: "absolute",
                                        border: "0",
                                        right: padding_l_r + "px",
                                    }}>
                                    <div style={{
                                        textAlign: "left",
                                        font: arrow_font,
                                        letterSpacing: "0",
                                        margin: "0",
                                        position: "absolute",
                                        top: "50%",
                                        msTransform: "translateY(-50%)",
                                        transform: "translateY(-50%)",
                                        color: "var(--mainWhite)",
                                    }}>
                                        Sign in  <RightArrowIcon width={arrow_icon_size} height={arrow_icon_size} style={{ marginLeft: "50px" }} />
                                    </div>

                                </div>
                            </div>
                            <div style={{
                                textAlign: "right",
                                font: arrow_font,
                                letterSpacing: "0",
                                margin: "0",
                                cursor: "pointer",
                                msTransform: translateY,
                                transform: translateY,
                                color: "var(--mainGreen)",
                                paddingLeft: padding_l_r + "px",
                                paddingRight: padding_l_r + "px",
                            }}
                                onClick={() => setRouteAuthModal("Register")}>sign up</div>
                        </div>
                        : authModal.route === "Register" ?
                            <div style={{
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                width:'93%'

                            }} className={classes.paper}>
                                <div className="d-flex">
                                    <div style={{
                                        font: "24px/24px Futura Hv BT",
                                        color: "#191919",
                                        paddingLeft: '30px',
                                        paddingBottom: '30px',
                                        paddingTop: '15px',
                                        flexGrow: "1",
                                    }}>Sign Up</div>
                                    <div
                                        style={{
                                            cursor: "pointer",
                                        }}
                                        onClick={() => setOpenAuthModal(false)}><TimesIcon style={{ marginTop: "15px" }} width={'30'} height={'30'} /></div>
                                </div>
                                <div>
                                    <div className={"padding_left_right_" + padding_l_r}>
                                        <div className={classesTextField.root}>
                                            <EmailAddressTextField
                                                label="Email Address"
                                                required={true}
                                                id="email_address"
                                            />
                                        </div>
                                    </div>
                                    <div className="row m-0">
                                        <div className={"col-6"} style={{
                                            marginTop: "10px",
                                            paddingLeft: padding_l_r + "px",
                                            paddingRight: "7.5px",
                                        }}>
                                            <div className={classesTextField.root}>
                                                <FirstNameTextField
                                                    label="First Name"
                                                    required={true}
                                                    id="first_name"
                                                />
                                            </div>
                                        </div>
                                        <div className={"col-6"} style={{
                                            marginTop: "10px",
                                            paddingLeft: "7.5px",
                                            paddingRight: padding_l_r + "px",
                                        }}>
                                            <div className={classesTextField.root}>
                                                <LastNameTextField
                                                    label="Last Name"
                                                    required={true}
                                                    id="last_name"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row m-0 position-relative">
                                        <div className={col} style={{
                                            marginTop: "10px",
                                            paddingLeft: padding_l_r + "px",
                                            paddingRight: "7.5px",
                                        }}>
                                            <div className={classesPassword.root}>
                                                <PasswordTextField
                                                    label="Password"
                                                    required={true}
                                                    id="password"
                                                />
                                            </div>
                                        </div>
                                        <div className={col} style={{
                                            marginTop: "10px",
                                            paddingLeft: "7.5px",
                                            paddingRight: padding_l_r + "px",
                                        }}>
                                            <div className={classesPassword.root}>
                                                <PasswordConfirmTextField
                                                    label="Confirm"
                                                    required={true}
                                                    id="comfirm"
                                                />
                                            </div>
                                        </div>
                                        <div className="position-absolute text-right d-inline-block"
                                            style={{
                                                right: eye_right,
                                                top: eye_top,
                                            }}>
                                            <PasswordEye />
                                        </div>
                                        <div className="position-absolute text-right d-inline-block"
                                            style={{
                                                right: eye_right_confirm,
                                                top: eye_top_confirm,
                                            }}>
                                            <PasswordEye />
                                        </div>
                                    </div>
                                </div>
                                <div className="position-relative margin_top_20">
                                    <div
                                        onClick={
                                            errorInput ? () => { } :
                                                () => registerMemberAccount(
                                                    emailAddressTextField.emailAddress,
                                                    firstNameTextField.firstName,
                                                    lastNameTextField.lastName,
                                                    passwordTextField.password)}
                                        style={{
                                            cursor: errorInput ? "" : "pointer",
                                            backgroundColor: errorInput ? "var(--lightGraySeparator)" : "var(--mainGreen)",
                                            paddingLeft: arrow_container_padding_left + "px",
                                            height: arrow_container_height,
                                            width: arrow_container_width,
                                            marginTop: "10px",
                                            display: "block",
                                            position: "absolute",
                                            border: "0",
                                            right: padding_l_r + "px",
                                        }}>
                                        <div style={{
                                            textAlign: "left",
                                            font: arrow_font,
                                            letterSpacing: "0",
                                            margin: "0",
                                            position: "absolute",
                                            top: "50%",
                                            msTransform: "translateY(-50%)",
                                            transform: "translateY(-50%)",
                                            color: "var(--mainWhite)",
                                        }}>
                                            Sign up  <RightArrowIcon width={arrow_icon_size} height={arrow_icon_size} style={{ marginLeft: "50px" }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            : ""}
                </Modal>          
            </div>
        </React.Fragment >
    )
}


const mapDispatchToProps = {
    fetchEstablishment: Actions.fetchEstablishment,
    setRouteAuthModal: Actions.setRouteAuthModal,
    setOpenAuthModal: Actions.setOpenAuthModal,
    registerMemberAccount: Actions.registerMemberAccount,
    loginMemberAccount: Actions.loginMemberAccount,
    tokenLogin: Actions.tokenLogin,
}

const mapStateToProps = (state) =>  ({
    authModal: state.Member.authModal,
    emailAddressLoginTextField: state.FormBank.emailAddressLoginTextField,
    emailAddressTextField: state.FormBank.emailAddressTextField,
    firstNameTextField: state.FormBank.firstNameTextField,
    lastNameTextField: state.FormBank.lastNameTextField,
    passwordLoginTextField: state.FormBank.passwordLoginTextField,
    passwordTextField: state.FormBank.passwordTextField,
    textColor: state.Setting.appMenu.textColor,
})

const SignIn = connect(mapStateToProps, mapDispatchToProps)(SignIn_)
export default SignIn

