import * as Actions from '../../../redux/actions'

import React from 'react'
import { connect } from 'react-redux'

class ScrollEventListener_ extends React.Component {
    handleScroll = () => {
        const windowHeight = "innerHeight" in window ? window.innerHeight : document.documentElement.offsetHeight
        const body = document.body
        const html = document.documentElement
        const docHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight)
        const windowBottom = windowHeight + window.pageYOffset
        const veticalObj = {
            windowHeight: windowHeight,
            windowBottom: windowBottom,
            isBottom: true,
        }
        windowBottom >= docHeight && this.props.setScreenVertical(veticalObj)
        this.props.setPageYOffset(window.pageYOffset)
    }

    componentDidMount() {
        window.addEventListener("scroll", this.handleScroll)
    }

    /* destory component */
    componentWillUnmount() {
        window.removeEventListener("scroll", this.handleScroll)
    }
    render() { return "" }
}


const mapDispatchToProps = {
    setPageYOffset: Actions.setPageYOffset,
    setScreenVertical: Actions.setScreenVertical,
}

const ScrollEventListener = connect(null, mapDispatchToProps)(ScrollEventListener_)
export default ScrollEventListener