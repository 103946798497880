
import { Grid } from "@material-ui/core"
import moment from "moment";
import React from 'react'
import { useSelector } from "react-redux"

export const HotelRoomList = ({itinerary_details,cancelHotel,cancellation_details,booking_status,allowCancel}) => {
  
    const rooms = convertObjArray(itinerary_details?.room_data);
    const cancellation = convertObjArray(cancellation_details);
    const policy = cancellation && cancellation.length > 0? cancellation[0]:null;
    const description =  itinerary_details?.description;
    const voidDate=  moment(policy?.date_before).format('MMM Do YYYY, h:mm a')

    return (
        <div style={{width: "100%"}}>
        <div style={{ padding: "10px 12%", marginBottom: 15}}>
            <div style={{ color: '#FB884D', font: "22px/22px Futura Md BT", marginBottom: 30, lineHeight: "40px" }}>
               Room Details
               {/* <div style={{    color: '#707070', font: "18px/18px Futura Lt BT",}}>
                   { description}
                   </div> */}
            </div>
           

            {rooms && rooms.length > 0 && rooms.map((room,index)=>{

                return(
                    <div key={index} style={{
                    color: '#707070', font: "18px/18px Futura Lt BT",
                    lineHeight: "25px",marginBottom:20,
                }}>
                    <span style={{ color: '#0D3A7D', font: "18px/18px Futura Lt BT" }}>
                    Room {index+1}:
                   </span>
                   <span style={{ color: '#000000',  font: "18px/18px Futura Md BT" }}>
                    &nbsp; {room?.name}
                   </span>
                   <br/>
                   {/* eluxe Club Room - adadad */}

                </div>
                )
            })}

            {allowCancel && <div>
            <div style={{ color: '#000000',  font: "18px/18px Futura Md BT",paddingTop:10 }}>
                Cancellation Policy:
            </div>

            <div
                style={{ color: '#707070', font: "18px/18px Futura Lt BT", paddingTop:15 }}
            >
               {policy?.description} 
               &nbsp;{booking_status && booking_status === "confirmed" &&
                   <a onClick={cancelHotel} style={{ color: "#0D3A7D", cursor: 'pointer', font: "18px/18px Futura Lt BT" }}>(Cancel now)</a>}
            </div>
            </div>}

        </div>
        <hr/>
        </div>
    )
}

export const convertObjArray = (data)=>{
    if(data &&  Object.keys(data).length > 0){
        var myData = Object.keys(data).map(key => {
            return data[key];
        })
        return myData
    }
    return []
}