import * as Actions from '../../../redux/actions';

import {
    ConnectWithFacebookIcon,
    ConnectWithGoogleIcon,
    ConnectWithInstagramIcon,
    ConnectWithMailIcon,
} from "../Common/SVGBank"
import React, { Component } from 'react'

import { connect } from 'react-redux'
import { useHistory } from "react-router-dom"

const RequestToJoin_ = ({ screenSize, setSwitchProfileContainer }) => {

    const history = useHistory()

    const navigateToRegister = () => {
        history.push("/register")
    }

    let margin_top = 30
    let text_left = "text-left"
    let text_right = "text-right"
    let container_margin = "50px"
    let container_width = "460px"
    let container_height = "200px"
    let size_icon = "90"
    let left_icon = "40%"
    const screen_at = 1025
    if (!isNaN(screenSize)) {
        // topImageHeight = screenSize < screen_at ? screenSize / 1.8 : screenSize / 2.1
        margin_top = screenSize < screen_at ? 10 : 30
        text_left = screenSize < screen_at ? "text-center" : "text-left"
        text_right = screenSize < screen_at ? "text-center" : "text-right"
        container_margin = screenSize < screen_at ? "10px" : "20px"
        container_width = screenSize < screen_at ? "280px" : "460px"
        container_height = screenSize < screen_at ? "120px" : "200px"
        size_icon = screenSize < screen_at ? "45" : "90"
        left_icon = screenSize < screen_at ? "43%" : "40%"
    }
    return (
        <div>

            <div className={"margin_top_30"} >
                <p className="futura_lt_bt_20_30"
                    style={{ color: '#B1B1B1', padding: '0% 30%', textAlign: 'center' }}>
                    This exclusive offer is made to a select group of travel writers, photographers and videographers by invitation only for a limited time. So don't miss out and regret later. Join now and be part of the future of travel.
              </p>

                <div className="text-center">
                    <div className="d-inline-block text-center position-relative " style={{
                        verticalAlign: "middle",
                        marginTop: 10,
                        width: container_width, height: 120, background: "#F8F8F8 0% 0% no-repeat padding-box"
                    }}>
                        <div
                            className="futura_hv_bt_24_28  text-center" style={{
                                cursor: "pointer",
                                textAlign: 'center', paddingTop: 45
                            }}
                            onClick={() => { navigateToRegister() }}
                        >
                            Claim your exclusive invite
                            </div>
                    </div>

                    <p className="futura_lt_bt_20_30"
                        style={{ color: '#B1B1B1', padding: '1% 30%', textAlign: 'center' }}>
                        Any question or comment, please <a
                            style={{ color: '#B1B1B1', textDecoration: 'underline' }}
                            href="/contact-us">contact us here</a>
                    </p>

                </div>


            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        screenSize: state.Setting.htmlBody.bodyClientWidth,
    }
};

const mapDispatchToProps = {
    setSwitchProfileContainer: Actions.setSwitchProfileContainer,

}

const RequestToJoin = connect(mapStateToProps, mapDispatchToProps)(RequestToJoin_)
export default RequestToJoin
