import * as Actions from '../../../../redux/actions'

import { ListIcon, TimelineIcon } from "../SVGBank"
import { list_container_mode, member_area_router } from '../RegExValidate'

import Fab from '@material-ui/core/Fab'
import React from 'react'
import TripMap from './TripMap'
import TripMapPriceline from './TripMapPricelne'
import { connect } from 'react-redux'

const ItineraryMap_ = ({ lang,fetchTypesAndSubtypesFeature,switch_container, setTripDisplayMode, app_menu_height, screenSizeHeight, setSwitchFeatureListContainer, setPageYOffset, is_mobile, hotelSearchForm }) => {
    const handleSwitchContainerMemberArea = () => {
        setSwitchFeatureListContainer(list_container_mode.list)
        setTripDisplayMode(list_container_mode.schedule)
        setPageYOffset(0)
    }
    const handleSwitchContainer = () => {
        setSwitchFeatureListContainer(list_container_mode.list)
        setTripDisplayMode(list_container_mode.timeline)
        setPageYOffset(0)
    }

    //fetchTypesAndSubtypesFeature("1", lang)

    const containerH = screenSizeHeight - Math.ceil(app_menu_height / 2)
    return (
        <div style={{ position: "relative", height: containerH }}>
            {window.google !== undefined && switch_container !== member_area_router.hotelSearch && <TripMap />}
            {window.google !== undefined && switch_container === member_area_router.hotelSearch && <TripMapPriceline />}
            <div style={{ position: "absolute", top: "50%", right: is_mobile ? "10px" : "100px", }}>
                {switch_container === member_area_router.memberAreaTrip ?
                    <Fab size="large" aria-label="back to list" style={{
                        outline: "0px",
                        backgroundColor: "#0D3A7D"
                    }} onClick={() => handleSwitchContainerMemberArea()}>
                        <ListIcon fill={"var(--mainWhite)"} style={{ marginLeft: "3px" }} />
                    </Fab>
                    :
                    <Fab size="large" disabled={false} style={{
                        outline: "0px",
                        backgroundColor: "#FFFFFF",
                        border: "2px #274674 solid"
                    }} onClick={() => handleSwitchContainer()}>
                        <TimelineIcon width={20} height={20} />
                    </Fab>}
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    activeTripId: state.FormBank.TimelineWizard.activeTrip.tripId,
    // tripMode: state.Member.tripMode,
	lang: state.Setting.lang,
    is_mobile: state.Setting.is_mobile,
    app_menu_height: state.Setting.appMenu.app_menu_height,
    screenSizeHeight: window.innerHeight,                             
    hotelSearchForm: state.FormBank.HotelSearch.hotel_form_state,

    switch_container: state.Member.switch_container,
})

const mapDispatchToProps = {
	fetchTypesAndSubtypesFeature: Actions.fetchTypesAndSubtypesFeature,
    setSwitchFeatureListContainer: Actions.setSwitchFeatureListContainer,
    setPageYOffset: Actions.setPageYOffset,
    setTripDisplayMode: Actions.setTripDisplayMode,
}

const ItineraryMap = connect(mapStateToProps, mapDispatchToProps)(ItineraryMap_)
export default ItineraryMap
