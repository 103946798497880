import * as Actions from '../../../redux/actions'

import React, { Component } from 'react'

import { connect, useDispatch } from 'react-redux'
import { SuggestedDestinationsMap } from './SuggestedDestinationsMap'
import { getSuggestedDestinationCategory, getSuggestedDestinationsMap } from '../Common/Trip/ActiveTrip.slice'
import { debounce } from 'lodash';
import Slider from 'react-slick'
import SuggestedMapSlider from './SuggestedMapSlider'
import SuggestedMapCategories from './SuggestedMapCategories'

const SuggestedDestinationContainer_ = ({ location, destination, props, switchFeatureContainer, lang, fetchDestination, setPageYOffset, fetchActiveTripFromCookies, setDestinationId, fetchActiveTripByTripId, fetchDestinationPhotoGallery, fetchDestinationFeaturesAndTypes, fetchEstablishmentSortingOptions, fetchEstablishmentInfluencers, fetchEstablishmentSuggestedTrips, setTripDisplayMode, is_mobile }) => {
    /* fetch destination */
    const mapRef = React.useRef(null)
    const dispatch = useDispatch()
    const [refreshText, setRefreshText] = React.useState("Search this area");
    const [showRefresh, setShowRefresh] = React.useState(false);
    const [mapCenter, setMapCenter] = React.useState(null);
    const [mapZoom, setMapZoom] = React.useState(null);
    const [hoverImage, setHoverImage] = React.useState(null);
    const [selectedDest, setSelectedDest] = React.useState(null);

    const search = new URLSearchParams(window.location.search)

    const level = search.get('level')

    const handleCenterChanged = () => {
        setShowRefresh(true)
        ///fetchEstablishments()
    }




    const fetch = (categories) => {
        if (mapRef) {

            let ne = mapRef?.current?.getBounds()?.getNorthEast()
            let sw = mapRef?.current?.getBounds()?.getSouthWest()
            if (ne && sw) {
                const coordinates = [{ lat: ne.lat(), lng: sw.lng() }, { lat: sw.lat(), lng: ne.lng() }];
                let data = {
                    mapBounds: coordinates,
                    level: level || 4
                }
                if(categories){
                    data = {...data,
                        categories:categories}
                }

                dispatch(getSuggestedDestinationsMap(data))
                dispatch(getSuggestedDestinationCategory(data))
            }
        }
    }



    const doubleClick = (destination)=>{
        const uri = destination?.partialUri;

        if(uri){
          const url = "/destination/suggested-" +uri + "/" + 1 + "/" + destination?.destinationId;
          window.open(url.toLowerCase(), "_blank");
          // history.push(url.toLowerCase())
        }
      }

   
    const fetchDestinations = debounce(fetch, 1000)


    ///refresh destinations on map change
    React.useEffect(() => {
        if(mapCenter){
            setShowRefresh(true)
            refreshMap()
        }
        return () => {
        };
    }, [mapCenter]);

    const refreshMap = () => {
        setRefreshText("Searching...")
        fetchDestinations()
        setTimeout(() => {
            setRefreshText("Search this area")
            setShowRefresh(false)
        }, 4000);
    }

    // React.useEffect(() => {
    //     if (props.match.params.destinationID !== undefined &&
    //         props.match.params.tripID === undefined &&
    //         props.match.params.destinationID !== null &&
    //         props.match.params.destinationID !== "") {
    //         const id = props.match.params.destinationID

    //         fetchActiveTripFromCookies(id, lang)
    //         setTripDisplayMode(list_container_mode.timeline)
    //     }
    //     if (props.match.params.tripID !== undefined &&
    //         props.match.params.tripID !== null &&
    //         props.match.params.tripID !== "") {
    //         fetchActiveTripByTripId(props.match.params.tripID, lang)

    //     }
    // }, [props.match.params.tripID, props.match.params.destinationID])


    return (
        <div >
            {/* { window.google !== undefined && <ItineraryMap />}   */}
          
            <SuggestedMapCategories
            fetchData={fetch}/>
           <div style={{ height: '93vh' }}>
            {window.google !== undefined && <SuggestedDestinationsMap
                center={mapCenter} zoom={mapZoom}
                refMap={mapRef} fetchDestinations={fetchDestinations}
                handleCenterChanged={handleCenterChanged}
                doubleClick={doubleClick}
                hoverImage={hoverImage}
                setHoverImage={setSelectedDest}/>
            }
            </div>
            {showRefresh && <div

                onClick={() => refreshMap()}
                style={{
                    position: "absolute",
                    top: is_mobile ? "25%" : "20%",
                    right: "14%",
                    zIndex: "1",
                    margin: "0",
                    left: is_mobile ? "10%" : "50%",
                    marginLeft: -20
                }}>

                <div style={{
                    backgroundColor: "#0d3a7d",
                    color: "var(--mainWhite)",
                    height: "30px",
                    display: "inline-flex",
                    borderRadius: "25px",
                    flexWrap: "wrap",
                    cursor: "pointer",
                    verticalAlign: "middle",
                    padding: ".375rem .75rem",
                    marginRight: "6px",
                    font: "16px/18px Futura Lt BT",
                }}>{refreshText}</div>
            </div>}
          
            <div
                style={{
                    position: "absolute",
                    bottom: '3%',
                    right: "1%",
                    zIndex: "1",
                    margin: "0",
                    left: "1%",
                }}>
                <SuggestedMapSlider
                setMapCenter={setMapCenter}
                setMapZoom={setMapZoom}
                doubleClick={doubleClick}
                hoverImage={selectedDest} 
                setHoverImage={setHoverImage}/>
            </div>
        </div>
    )
}


const mapStateToProps = state => {
    return {
        lang: state.Setting.lang,
        is_mobile: state.Setting.is_mobile,
        switchFeatureContainer: state.FormBank.FeatureFormat,
        switch_container: state.Member.switch_container,
        destination: state.Destination
    }
}

const mapDispatchToProps = {
    setTripDisplayMode: Actions.setTripDisplayMode,
    setPageYOffset: Actions.setPageYOffset,

    // destination
    fetchDestination: Actions.fetchDestination,
    fetchDestinationPhotoGallery: Actions.fetchDestinationPhotoGallery,
    fetchDestinationFeaturesAndTypes: Actions.fetchDestinationFeaturesAndTypes,
    fetchEstablishmentInfluencers: Actions.fetchEstablishmentInfluencers,
    fetchEstablishmentSortingOptions: Actions.fetchEstablishmentSortingOptions,
    fetchEstablishmentSuggestedTrips: Actions.fetchEstablishmentSuggestedTrips,
    setDestinationId: Actions.setDestinationId,
    fetchActiveTripFromCookies: Actions.fetchActiveTripFromCookies,
    setSwitchContainer: Actions.setSwitchContainer,



    //destination explore    
    fetchActiveTripByTripId: Actions.fetchActiveTripByTripId,
}

const SuggestedDestinationContainer = connect(mapStateToProps, mapDispatchToProps)(SuggestedDestinationContainer_)
export default SuggestedDestinationContainer
