import * as Actions from '../../../redux/actions'

import {
    CheckedBoxReglarIcon,
    DisableCheckBoxReglarIcon,
    UnCheckBoxReglarIcon,
} from './SVGBank'
import React, { Component } from 'react'

import { connect } from 'react-redux'
import { member_area_router } from "../Common/RegExValidate"

export class CheckBox extends Component {
    render() {
        const { screenSize, id, text, checked, disable, type, addSelectedElementInterests, removeSelectedElementInterests, selected_travel_interests_merge_sets, uuid, lang, switch_container } = this.props
        let screen_at_500 = 500
        let font_size_text = 18
        if (!isNaN(screenSize)) {
            // menuH = screenSize > screen_at_1200_1200 ? 100 : 40
            font_size_text = screenSize > screen_at_500 ? 18 : 12
        }
        return (
            <div className="d-inline-block"
                style={{
                    paddingBottom: "20px",
                    cursor: disable === 0 ? "pointer" : "",
                }}
                onClick={
                    () => disable === 0 ?
                        (checked === 0 ? addSelectedElementInterests(id, type, selected_travel_interests_merge_sets, uuid, lang)
                            :
                            removeSelectedElementInterests(id, type, selected_travel_interests_merge_sets, uuid, lang)) : ""}
            >
                {disable === 0 && checked === 1 && <CheckedBoxReglarIcon style={{ marginBottom: "2px" }} />}
                {disable === 0 && checked === 0 && <UnCheckBoxReglarIcon style={{ marginBottom: "2px" }} />}
                {disable === 1 && <DisableCheckBoxReglarIcon style={{ marginBottom: "2px" }} />}
                <div className="d-inline-block"
                    style={{
                        paddingLeft: "8px",
                        paddingRight: "20px",
                        font: font_size_text + "px/0px Futura Md BT",
                        color: switch_container === member_area_router.personalInfo ? "#EFEFEF" : (disable === 1 ? "#707070" : "E8E8E8"),
                        letterSpacing: "0",
                        textAlign: "left",
                    }}>
                    {text}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    screenSize: state.Setting.htmlBody.bodyClientWidth,
    selected_travel_interests_merge_sets: state.Landing.selected_travel_interests_merge_sets,
    switch_container: state.Member.switch_container,

    lang: state.Setting.lang,
    uuid: state.Member.authModal.uuid,
})

const mapDispatchToProps = {

    addSelectedElementInterests: Actions.addSelectedElementInterests,
    removeSelectedElementInterests: Actions.removeSelectedElementInterests,
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckBox)

