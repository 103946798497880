import * as Actions from '../../../redux/actions'

import { CaretRight } from "./SVGBank"
import Drawer from '@material-ui/core/Drawer'
import React from 'react'
import TimelineWizard from './TimelineWizard/TimelineWizard'
import { connect } from 'react-redux'
import { list_container_mode } from './RegExValidate'
import { makeStyles } from '@material-ui/core/styles'
import { member_area_router } from './RegExValidate'

const TimelineWizardContainer_ = ({ is_mobile, listDisplayFormat, timelineStatus, setTimeLineStatus, activeTripId, screenVertical, app_menu_height,
}) => {
    
    const timelineExtendTopConstant = 126
    let timelineContainerMarginTop = 620
    let timelineExtendTop = timelineExtendTopConstant

    const drawerWidth = "340px"
    let heightActivityContainer = 349
    
    timelineExtendTop = (listDisplayFormat === member_area_router.booking ? app_menu_height : timelineExtendTop)
    timelineContainerMarginTop = (listDisplayFormat === member_area_router.booking ? (280) : timelineContainerMarginTop)
    heightActivityContainer = (window.screen.height - timelineContainerMarginTop)       // (listDisplayFormat === member_area_router.booking ? (window.screen.height - app_menu_height - 200 - 110) : heightActivityContainer)

    if (screenVertical.pageYOffset !== undefined) {
        timelineExtendTop = timelineExtendTop - screenVertical.pageYOffset < app_menu_height / 1.7 ? app_menu_height / 1.7 : timelineExtendTop - screenVertical.pageYOffset
        timelineContainerMarginTop = timelineExtendTop + 180
        heightActivityContainer = window.screen.height - timelineContainerMarginTop - 100
    }

    timelineExtendTop = listDisplayFormat === list_container_mode.map ? (app_menu_height / 1.7) : (timelineExtendTop)
    timelineContainerMarginTop = listDisplayFormat === list_container_mode.map ? (app_menu_height / 2.8 + 200) : (timelineContainerMarginTop)
    heightActivityContainer = listDisplayFormat === list_container_mode.map ? (window.screen.height - app_menu_height / 3 - 200 - 110) : ( heightActivityContainer)

    
    const useStyles = makeStyles((theme) => ({
        root: {
            '& .MuiDrawer-paper': {
                marginLeft: "8.5%",
                marginTop: timelineContainerMarginTop + "px",
                height: heightActivityContainer + "px",
                zIndex: "5",
                overflowX: "hidden",
            },
            '& .MuiDrawer-paperAnchorDockedLeft': {
                border: "0px",
            }
        },
        drawer: {
            width: drawerWidth,
        },
        drawerPaper: {
            width: drawerWidth,
        },
    }))
    const classes = useStyles()
    return (
        <div className={classes.root}>
            {!is_mobile && <React.Fragment>
                <Drawer
                    transitionDuration={{ exit: 200,enter: 200  }}
                    className={classes.drawer}
                    variant="persistent"
                    anchor="left"
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                    open={timelineStatus}

                >
                    <div className={classes.toolbar}>
                        <TimelineWizard listDisplayFormat={listDisplayFormat}  />
                    </div>
                </Drawer>
                {!timelineStatus && (activeTripId !== undefined && activeTripId !== "" && <div style={{
                    position: "fixed",
                    top:  "165px",
                    left: "0px", cursor: "pointer", width: "23px", zIndex: "8",
                    height: "48px",
                    background: "rgba(255,255,255,0.9)",
                    borderLeft: "1px solid #D4D4D4",
                    boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.3)",
                }} onClick={() => setTimeLineStatus(true)}>
                    <CaretRight fill={"#C7C7C7"} style={{ marginTop: "15px" }} />
                </div>)}
            </React.Fragment>
            }
        </div>
    )
}

const mapStateToProps = (state) => ({
    activeTripId: state.FormBank.TimelineWizard.activeTrip.tripId,

    is_mobile: state.Setting.is_mobile,
    screenVertical: state.Setting.screenVertical,
    timelineStatus: state.FormBank.TimelineWizard.status,
    app_menu_height: state.Setting.appMenu.app_menu_height,
})

const mapDispatchToProps = {
    setTimeLineStatus: Actions.setTimeLineStatus,
}

const TimelineWizardContainer = connect(mapStateToProps, mapDispatchToProps)(TimelineWizardContainer_)
export default TimelineWizardContainer
