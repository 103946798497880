import * as Actions from '../../../redux/actions'

import React, { Component } from 'react'
import { connect } from 'react-redux'

const AboutDestination_ = ({ generalInfo }) => {
    const cityName = generalInfo ? generalInfo?.name : "";
    const stateName = generalInfo ? generalInfo?.stateName : "";
    const countryName = generalInfo ? generalInfo?.countryName : "";


    return (
        <div className="aboutEstablishment">
            <div style={{
                font: "32px/32px Futura Hv BT",
                color: "var(--frameBlack)",
                marginTop: 50,
                marginBottom: 50
            }}>
                {cityName}
                <span
                    style={{
                        font: "24px/24px Futura LT BT",
                        color: "var(--frameBlack)",
                    }}>{stateName? ", "+stateName:""}{countryName? ", "+countryName:""} </span>
            </div>

            <div style={{
                font: "28px/28px Futura Hv BT",
                color: "var(--frameBlack)",
                marginTop: 30,
                marginBottom: 30
            }}>
               About
            </div>

            {generalInfo && generalInfo.about &&
                <div dangerouslySetInnerHTML={{ __html: generalInfo.about }}   style={{
                    font:  "19px / 19px Futura Lt BT",
                    color: "var(--mainGrey)",
                    lineHeight: 1.3,
                    textAlign: "justify",
                    marginTop: 20,
                    marginBottom: 30
                }}>
                </div>}
        </div>
    )
}

const mapStateToProps = state => {
    return {
        generalInfo: state.Destination.destination.generalInfo,
    }
}
const mapDispatchToProps = {
}

const AboutDestination = connect(mapStateToProps, mapDispatchToProps)(AboutDestination_)
export default AboutDestination
