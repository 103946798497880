
import { Grid } from "@material-ui/core"
import React, { useEffect } from 'react'
import { LeftArrowIcon, RightArrowIcon } from "../../Common/SVGBank"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom";
import { getNearByDestinationCode, getNearByPricelineCode, lookUpFlightThunk } from "../services/Flights.thunks";
import { URL_REPLACE } from "../../Common/RegExValidate";
import moment from "moment";
import { bookingTestData, departureTestData, returnTestData, flightTestContract } from "../services/flightTestData";
import { convertObjArray } from "../FlightManagement/FlightManagementList";
import { setTimeLineStatus } from "../../../../redux/actions";


export const FlightFooter = ({ saveBooking, bookFlight,showSave }) => {
    const history = useHistory();

    var windowWidth = window.screen.width < window.outerWidth ?
        window.screen.width : window.outerWidth;
    var mobile = windowWidth < 500;

    return (
        <Grid item >

            <div>
                <div>
                    <button className='recapButtons' style={{ background: mobile ? 'transparent' : '#DFDFDF' }} onClick={() => history.goBack()}>
                        <LeftArrowIcon fill={'#7F7F7F'} height={14} width={14} style={{ marginRight: 10 }} />  Back to search results
            </button>


                    <div style={{ float: "right" }}>
                        <button className='recapButtons' style={{ background: '#19BC9B', color: 'white', font: '14px/14px Futura Hv BT' }} onClick={bookFlight}>
                            Book <RightArrowIcon height={14} width={14} style={{ marginLeft: 100 }} />
                        </button>
                    </div>

                </div>
                {showSave &&<div style={{ float: "right", marginTop: 10, marginBottom: 40, cursor: 'pointer' }} onClick={saveBooking}>
                    <p>Not ready to book yet, <u  > save this flight in your trip </u></p>
                </div>}

            </div>
        </Grid>
    )
}


export const CongratulationsSavedFlight = () => {
    const authModal = useSelector(state => state.Member.authModal);
    const selectedFlight = useSelector(state => state.Flights.selectedFlight);

    const arrivalCity = selectedFlight && selectedFlight.slice_data ? selectedFlight.slice_data[selectedFlight.slice_data.length - 1].arrival.airport.city : '';


    const firstName = authModal.firstName || '';

    return (
        <Grid container style={{ padding: 20 }}>
            <Grid item  >
                <div>
                    <p style={{ font: '24px/24px Futura Hv BT' }}> Congratulations {firstName},</p>
                </div>

                <div>
                    <p style={{ marginTop: 20, font: '20px/20px Futura Md BT', lineHeight: '45px' }}>Your flight to {arrivalCity} has been saved in a new trip in your account You can safely continue your trip planning.</p>
                </div>

            </Grid>

            <Grid item style={{ textAlign: 'end', marginTop: 100, width: '100%' }} >
                <div >
                    <div >
                        <button className='recapButtons' style={{ background: '#19BC9B', color: 'white', font: '14px/14px Futura Hv BT' }} onClick={null}>
                            <span style={{ paddingRight: 60, font: '14px/14px Futura Hv BT' }}>  Find a hotel     </span>
                            <RightArrowIcon height={14} width={14} />
                        </button>
                    </div>

                </div>
                <div style={{ marginTop: 10, font: '14px/14px Futura Hv BT' }}>
                    <p>Skip to  <u> explore things to see & do in {arrivalCity}  </u></p>
                </div>

            </Grid>
        </Grid>
    )
}


export const CongratulationsBookedFlight = () => {
    const authModal = useSelector(state => state.Member.authModal);
    const selectedFlight = useSelector(state =>  state.Flights.selectedFlight);
    const bookingData = useSelector(state =>  state.Flights.bookingData);
    const nearByDestination = useSelector(state => state.Flights.nearByDestination);
    const nearByPriceline = useSelector(state => state.Flights.nearByPriceline);
    const flightData = useSelector(state => state.Flights.flightData);
    const contactInfo = useSelector(state => state.Flights.contactInfo);


    let arrivalCity = selectedFlight && selectedFlight.slice_data ? selectedFlight.slice_data[selectedFlight.slice_data.length - 1].arrival.airport.city : '';
    let arrivalCode = selectedFlight && selectedFlight.slice_data ? selectedFlight.slice_data[selectedFlight.slice_data.length - 1].arrival.airport.code : '';
    const selectedReturnFlight = useSelector(state => state.Flights.selectedReturnFlight);
    const selectedFlightMode = useSelector(state => state.Flights.selectedFlightMode);
    const selectedDeparture = useSelector(state =>  state.Flights.selectedDepartureFlight);
    const flightAdults = useSelector(state => state.Flights.flightData.numberAdult);
    const flightChildren = useSelector(state => state.Flights.flightData.numberChild)
    const passengers = Number(flightAdults) + Number(flightChildren) || 1;

    const customerService = bookingData?.cs_number;

    if (selectedFlightMode === 'round_trip') {
        arrivalCity = selectedFlight && selectedFlight.slice_data ? selectedFlight.slice_data[selectedFlight.slice_data.length - 1].departure.airport.city : '';
        arrivalCode = selectedFlight && selectedFlight.slice_data ? selectedFlight.slice_data[selectedFlight.slice_data.length - 1].departure.airport.code : '';
    }

    const firstName = authModal.firstName || '';
    const dispatch = useDispatch();
    const bookingNumber = bookingData && bookingData?.trip_number ? bookingData?.trip_number : "";

    const roomNumber = flightData && flightData?.numberAdult ? flightData.numberAdult : "1";
    const roomGuests = flightData && flightData?.numberChild ? flightData.numberChild : "1";

    const checkIn = flightData && flightData?.flights ? flightData.flights[0].departureDateFormat : moment(new Date()).format("YYYY-MM-DD");
    const checkOut = checkIn ? moment(checkIn).add(3, 'days').format("YYYY-MM-DD") : moment(new Date()).add(3, 'days').format("YYYY-MM-DD")

    const email = contactInfo?.email;
    const bookingId = bookingNumber;
    const canViewDetails = email && bookingId;
    const lookUpData = useSelector(state => state.Flights.lookUpData);
    const pnr = lookUpData?.pnr_locator;

    const airlineData = lookUpData?.airline_data ? convertObjArray(lookUpData?.airline_data) : null;

    useEffect(() => {
        dispatch(getNearByDestinationCode(arrivalCode));
        dispatch(getNearByPricelineCode(arrivalCode));

        if (email && bookingId) {
            const data = {
                email: email,
                booking_id: bookingId
            }
            dispatch(lookUpFlightThunk(data)).then((res => {
            }));
        }

    }, []);



    const history = useHistory()

    const goToDestination = () => {
        const url = "/destination/" + nearByDestination.name.replace(URL_REPLACE, "-") + "/" + 1 + "/" + nearByDestination.destinationId;
        dispatch(setTimeLineStatus(true))
        history.push(url.toLowerCase());
    }

    const goToBooking = () => {

        if (canViewDetails) {
            const url = `/flight/booking_details?booking_id=${bookingId}&email=${email}`;
            window.open(url.toLowerCase());
        }
    }




    const goToHotel = () => {
        const city = nearByPriceline.city !== undefined ? nearByPriceline.city + ', ' : ""
        const state = nearByPriceline.state !== undefined ? nearByPriceline.state + ', ' : ""
        const country = nearByPriceline.country !== undefined ? nearByPriceline.country : ""
        const where = city + state + country;
        const url = "/hotel-search?city_id=" + nearByPriceline?.cityid_ppn
            + "&check_in=" + checkIn
            + "&check_out=" + checkOut
            + "&rooms=" + (roomNumber ? roomNumber : 1)
            + "&guests=" + (roomNumber ? roomNumber : 1)
            + "&city_lat=" + (nearByPriceline?.latitude)
            + "&city_lng=" + (nearByPriceline?.longitude)
            + "&where=" + (where.replace(URL_REPLACE, "_"));
        window.open(url.toLowerCase());
    }

    var windowWidth = window.screen.width < window.outerWidth ?
        window.screen.width : window.outerWidth;
    var mobile = windowWidth < 500;



    return (
        <Grid container style={{ padding: mobile? 0:20, width:mobile?'85%':'100%' }}>
            <Grid item  >
                <div>
                    <p style={{ font: '24px/24px Futura Hv BT' }}> Congratulations {firstName},</p>
                </div>

                <div>
                    <p style={{ marginTop: 20, font: '16px/16px Futura Md BT', lineHeight: '45px' }}>Your flight to {arrivalCity} has been booked.</p>

                    {selectedDeparture && <div style={{ marginTop: 20, clear: 'both', display:  mobile? 'block':'flex' }}>
                        <div style={{ font: '16px/16px Futura Md BT', float: mobile? 'none':'left', width: '15vw', lineHeight: '26px' }}>

                            Outgoing</div>

                        {selectedDeparture && selectedDeparture?.slice_data?.map((slice, index) => {
                            const departure = slice?.departure;
                            const arrival = slice?.arrival;
                            const stops = slice?.info?.connection_count;
                            const stopLabel = stops && stops > 0 ? stops === 1 ? "1 Stop" : `${stops} Stops` : stops === 0 ? "Non stop" : null;
                            const notes = slice?.info?.notes;
                            return (
                                <div key={index + 'return'} style={{ font: '16px/16px Futura Md BT', float: mobile? 'none':'right', textAlign: 'left', lineHeight: '26px' }}>

                                    {departure?.airport?.code}-{arrival?.airport?.code} <span style={{ font: '16px/16px Futura Lt BT' }}>({departure.airport.city} to {arrival.airport.city})</span> <br />
                                    {departure?.datetime?.date_display} @{departure?.datetime?.time_12h} <br />
                                    <span style={{ font: '16px/16px Futura Lt BT' }}>{stopLabel}</span> <br />
                                    {notes &&
                                        Object.keys(notes).map(key => {
                                            const val = notes[key]

                                            return (
                                                <p style={{ marginTop: 5, marginBottom: 5, width: "100%", font: "14px/14px Futura  Lt BT" }}> {val?.value}</p>
                                            )
                                        })}
                                </div>
                            )
                        })}


                    </div>}

                    {selectedReturnFlight && <div style={{ marginTop: 20, clear: 'both',display:  mobile? 'block':'flex' }}>
                        <div style={{ font: '16px/16px Futura Md BT', float: mobile? 'none':'left', width: '15vw', lineHeight: '26px' }}>

                            Incoming</div>

                        {selectedReturnFlight && selectedReturnFlight?.slice_data?.map((slice, index) => {
                            const departure = slice?.departure;
                            const arrival = slice?.arrival;
                            const stops = slice?.info?.connection_count;
                            const stopLabel = stops && stops > 0 ? stops === 1 ? "1 Stop" : `${stops} Stops` : stops === 0 ? "Non stop" : null;
                            const notes = slice?.info?.notes;
                            return (
                                <div key={index + 'return'} style={{ font: '16px/16px Futura Md BT', float: mobile? 'none':'right', textAlign: 'left', lineHeight: '26px' }}>

                                    {departure?.airport?.code}-{arrival?.airport?.code} <span style={{ font: '16px/16px Futura Lt BT' }}>({departure.airport.city} to {arrival.airport.city})</span> <br />
                                    {departure?.datetime?.date_display} @{departure?.datetime?.time_12h} <br />
                                    <span style={{ font: '16px/16px Futura Lt BT' }}>{stopLabel}</span> <br />
                                    {notes &&
                                        Object.keys(notes).map(key => {
                                            const val = notes[key]

                                            return (
                                                <p style={{ marginTop: 5, marginBottom: 5, width: "100%", font: "14px/14px Futura  Lt BT" }}> {val?.value}</p>
                                            )
                                        })}
                                </div>
                            )
                        })}


                    </div>}

                    <div style={{ marginTop: 20, clear: 'both', display: 'flex' }}>
                        <div style={{ font: '16px/16px Futura Md BT', float: 'left', width:mobile? '50vw': '15vw', lineHeight: '26px' }}>

                            Nb of Passengers</div>

                        <div style={{ font: '16px/16px Futura Md BT', float: 'right', textAlign: 'left', lineHeight: '26px' }}>

                            {passengers}
                        </div>

                    </div>

                    {(pnr) && <div style={{ marginTop: 20, clear: 'both', display: mobile?'block':'flex' }}>
                        <div style={{ font: '16px/16px Futura Md BT', float: mobile? 'none':'left',  width:mobile? '50vw': '15vw', lineHeight: '26px' }}>

                            Booking reference:</div>

                        <div style={{ font: '16px/16px Futura Md BT',float: mobile? 'none':'right', textAlign: 'left', lineHeight: '26px' }}>

                            {pnr}
                        </div>

                    </div>}

                    {<div style={{ marginTop: 20, clear: 'both', display: mobile? 'none':'flex' }}>
                        <div style={{ font: '16px/16px Futura Md BT', float: mobile? 'none':'left',  width:mobile? '50vw': '15vw', lineHeight: '26px' }}>

                            Airline confirmation #:</div>

                        <div style={{ font: '16px/16px Futura Md BT', float: mobile? 'none':'right', textAlign: 'left', lineHeight: '26px' }}>

                            {airlineData && airlineData.length > 0
                                && airlineData.map((airline, index) => {
                                    return (
                                        <div key={airline?.name} style={{ paddingBottom: 10 }} >
                                            <div
                                                style={{ color: '#000000', font: "16px/16px Futura Md BT", paddingBottom: 1 }}
                                            >
                                                {airline?.name}: {airline?.carrierLocator}
                                            </div>
                                        </div>
                                    )
                                })}

                        </div>
                    </div>}


                    <div style={{ marginTop: 20, clear: 'both', display: mobile?'block':'flex' }}>
                        <div style={{ font: '16px/16px Futura Md BT', float: mobile? 'none':'left',  width:mobile? '50vw': '15vw', lineHeight: '26px' }}>

                            Trip number:</div>

                        <div style={{ font: '16px/16px Futura Md BT', float: mobile? 'none':'right', textAlign: 'left', lineHeight: '26px' }}>

                            {bookingNumber}
                        </div>

                    </div>


                    <div style={{ marginTop: 20, clear: 'both', display:mobile?'none': 'flex' }}>
                        <div style={{ font: '16px/16px Futura Md BT',float: mobile? 'none':'left',  width:mobile? '50vw': '15vw', lineHeight: '26px' }}>

                            Customer service:</div>

                        <div style={{ font: '16px/16px Futura Md BT', float: mobile? 'none':'right', textAlign: 'left', lineHeight: '26px' }}>

                            {customerService}
                        </div>

                    </div>



                    <p style={{ marginTop: 20, font: '16px/16px Futura Md BT', lineHeight: '45px' }}>You can safely continue planning your trip.</p>
                </div>


            </Grid>

            <Grid item style={{ marginTop: 100, width: '100%' }} >
                <div >
                    {true && <button className='recapButtons' style={{ float: "left", marginRight: 10, background: '#DFDFDF' }} onClick={goToBooking}>
                        See Booking Details
                    </button>}

                    <div style={{ textAlign: "right" }}>
                        <button className='recapButtons' style={{ background: '#19BC9B', color: 'white', font: '14px/14px Futura Hv BT' }} onClick={goToHotel}>
                            <span style={{ paddingRight: mobile ? 0 : 60, font: '14px/14px Futura Hv BT' }}>  Find a hotel     </span>
                            <RightArrowIcon height={14} width={14} />
                        </button>

                        <div onClick={goToDestination} style={{ marginTop: 10, font: '14px/14px Futura Hv BT', cursor: "pointer", }}>
                            <p>Skip to  <u> explore things to see & do in {arrivalCity}  </u></p>
                        </div>
                    </div>

                </div>


            </Grid>
        </Grid>
    )
}