
import { Grid } from "@material-ui/core"
import React from 'react'
import { FlightRecapComponent } from "./FlightManagementComponent"
import { useSelector } from "react-redux"
import { multiCityData } from "../services/flightTestData"
import { useLocation, useHistory } from 'react-router-dom'
import { Modal } from 'react-bootstrap'
export const FlightRecapList = () => {
    let location = useLocation()
    const history = useHistory()
    const pathName = location?.pathname;
    const search = location?.search;
    const lookUpData = useSelector(state => state.Flights.lookUpData);
    const selectedFlightMode = lookUpData?.search_type;

    const importantInfo = lookUpData?.important_info_data;

    const sliceData = convertObjArray(lookUpData?.slice_data)

    const booking_id = lookUpData?.booking_id;
    let airlines = []
    let airline_name = "";
    const customer_phone = lookUpData?.customer?.phone_number;


    const fareRules = "";
    const passportData = lookUpData?.popup_data?.valid_passport;
    const taxes = lookUpData?.popup_data?.taxes_and_fees;
    const seats = lookUpData?.popup_data?.seat_assignments;
    const notice = lookUpData?.popup_data?.notice_of_incorp;
    const international = lookUpData?.popup_data?.internation_travel;
    const frequent = lookUpData?.popup_data?.frequent_flyer;
    const flightSchedule = lookUpData?.popup_data?.flight_schedule;
    const disinsection = lookUpData?.popup_data?.disinsection;
    const commonly = lookUpData?.popup_data?.commonly_used_terms;
    const baggage = lookUpData?.popup_data?.baggage_fees;
    const checkIn = lookUpData?.popup_data?.check_in;
    const bagSpec = lookUpData?.popup_data?.bag_spec_requirements;

  

    let mapObj = {
        "{{ customer_phone }}": customer_phone,
        "{{ airline_name }}": airline_name,
        "{{ booking_id }}": booking_id
    };

    const [show, setShow] = React.useState(false);

    const handleClose = () => {
        history.replace(pathName+search)
        setShow(false)
    };
    const handleShow = () => setShow(true);

    const showModal = (title,data) => {
        setModalInfo({
            title: title,
            value: data
        })
        handleShow()
    }

    const [modalInfo, setModalInfo] = React.useState({
        title: "",
        value: null
    });


    React.useEffect(() => {
        if (lookUpData?.airline_data) {
            Object.keys(lookUpData?.airline_data).map(airlineKey => {
                let airline = lookUpData?.airline_data[airlineKey];
                let airlineName = airline?.name;

                airlines.push(airlineName)
            })
            mapObj['airline_name'] = airlines.join(" & ");
        }

        return () => {
        };
    }, [lookUpData]);

    


    React.useEffect(() => {
        const hash = location?.hash;
        if (hash && hash === "#valid_passport") {
            showModal("Valid Passport", passportData?.paragraph_data);
        }
        if (hash && hash === "#flight_schedule") {
            showModal("Flight Schedule", flightSchedule?.paragraph_data);
        }
        if (hash && hash === "#bag_spec_requirements") {
            showModal("Baggage/Special requirements", bagSpec?.paragraph_data);
        }
        if (hash && hash === "#notice_of_incorp") {
            showModal("Notice of Incorporated Terms", notice?.paragraph_data);
        }
        if (hash && hash === "#frequent_flyer") {
            showModal("Frequent Flyer and Loyalty Programs", frequent?.paragraph_data);
        }
        if (hash && hash === "#check_in") {
            showModal("Airport Check-In and Security", checkIn?.paragraph_data);
        }
        if (hash && hash === "#internation_travel") {
            showModal("International Travel", international?.paragraph_data);
        }
        if (hash && hash === "#seat_assignments") {
            showModal("Seat Assignments", seats?.paragraph_data);
        }


    }, [location])




    return (
        <div>
            <Modal show={show} onHide={handleClose} style={{
                maxWidth: '80% !Important', top: "12%"
            }}>

                <Modal.Header closeButton>
                    <Modal.Title>{modalInfo.title}</Modal.Title>
                </Modal.Header>

                {modalInfo && modalInfo?.value && <Modal.Body
                    style={{
                        height: '600px',
                        overflowY: 'auto'
                    }}>
                    {
                        Object.keys(modalInfo?.value).map((key) => {
                            let value = modalInfo?.value[key];
                            const title = value?.title;
                            if (value?.text) {
                                value = value?.text;
                            }
                            return (
                                <div key={key} style={{ paddingTop: 10 }} >
                                    {title && title && <div style={{ font: "18px/16px Futura Md BT", lineHeight: 1.3, marginBottom: 3 }} >{title}</div>}
                                    <div style={{ font: "16px/16px Futura Lt BT", lineHeight: 1.3, }} dangerouslySetInnerHTML={{ __html: value }}></div>
                                </div>
                            )
                        })}
                </Modal.Body>}


            </Modal>

            <Grid container>
                {/* /outgoing */}
                {lookUpData && lookUpData?.slice_data && sliceData &&
                    <Grid item style={{ border: '1px solid #DFDFDF', marginBottom: 15, width: '100%' }}>
                        {sliceData.map((slice, sliceIndex) => {
                            return (
                                <FlightRecapComponent flightData={slice} key={sliceIndex + "recap"}
                                    title={selectedFlightMode === 'RoundTrip' && sliceIndex === 1 ? "Return" : "Outgoing"} />
                            )
                        })}
                    </Grid>}



            </Grid>

            {importantInfo && <div style={{
                padding: '20px 60px', border: '1px solid #DFDFDF', marginBottom: 15
            }}>
                {
                    Object.keys(importantInfo).map((key) => {
                        const info = importantInfo[key]?.paragraph_data;
                        const title = importantInfo[key]?.title;

                        return (
                            <div key={key} style={{ paddingTop: 10, paddingBottom: 20 }}>
                                <div style={{ font: "24px/24px Futura Md BT", paddingBottom: 10 }}>{title}:</div>
                                {info && Object.keys(info).map(infoKey => {
                                    let text = info[infoKey];
                                    text = text.replace(/{{ customer_phone }}|{{ airline_name }}|{{ booking_id }}/gi, function (matched) {
                                        return mapObj[matched];
                                    });

                                    const matchValue = text.match(/#.*[START]+#/) ? text.match(/#.*[START]+#/)[0] : null
                                    const isFare = matchValue ? matchValue.includes("fare") : false;
                                    const isPassport = matchValue ? matchValue.includes("passport") : false;
                                    const isSchedule = matchValue ? matchValue.includes("schedule") : false;
                                    const isBaggage = matchValue ? matchValue.includes("spec") : false;
                                    const isNotice = matchValue ? matchValue.includes("incorp") : false;
                                    const isFrequent = matchValue ? matchValue.includes("frequent") : false;
                                    const isCheckIn = matchValue ? matchValue.includes("check") : false;
                                    const isInternational = matchValue ? matchValue.includes("internation") : false;
                                    const isSeat = matchValue ? matchValue.includes("seat") : false;


                                    let link = '';
                                    if (isFare) {
                                        link = '#fare_data'
                                    }
                                    if (isPassport) {
                                        link = '#valid_passport'
                                    }
                                    if (isSchedule) {
                                        link = '#flight_schedule'
                                    }
                                    if (isBaggage) {
                                        link = '#bag_spec_requirements'
                                    } 
                                    if (isNotice) {
                                        link = '#notice_of_incorp'
                                    } if (isFrequent) {
                                        link = '#frequent_flyer'
                                    }
                                    if (isCheckIn) {
                                        link = '#check_in'
                                    }
                                    if (isInternational) {
                                        link = '#internation_travel'
                                    }
                                    if (isSeat) {
                                        link = '#seat_assignments'
                                    }
                                    if (text) {
                                        text = text.replace(/#.*[START]+#/, `<a href="pageLink" style="color:steelblue">`)
                                        text = text.replace(/#.*[END]+#/, "</a>")
                                        text = text.replace("pageLink", link)
                                    }


                                    return (
                                        <div key={infoKey}>
                                            <div style={{ font: "16px/16px Futura Lt BT", lineHeight: 1.3, paddingTop: 10 }}
                                                dangerouslySetInnerHTML={{ __html: text }}></div>
                                        </div>
                                    )
                                })}
                            </div>
                        )



                    })}

            </div>}

        </div>
    )
}

export const convertObjArray = (data) => {
    if (data && Object.keys(data).length > 0) {
        var myData = Object.keys(data).map(key => {
            return data[key];
        })
        return myData
    }
    return []
}