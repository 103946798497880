import * as Actions from '../../../../redux/actions'

import { CheckBoxIcon, CheckedBoxIcon } from "../SVGBank"
import { DirectionsRenderer, GoogleMap, withGoogleMap } from "react-google-maps"
import { compose, lifecycle, withProps } from "recompose"

import { DELAY_ZERO_SECOND, DEFAULT_MAP_ZOOM } from "../RegExValidate"
import ItineraryMarkers from "../ItineraryMarkers"
import PlacesMarkers from "../PlacesMarkers"
import PropTypes from "prop-types"
import React from 'react'
import { connect } from 'react-redux'
import mapStyles from "../../TripPage/mapStyles"
import moment from "moment"
import { list_container_mode , member_area_router } from "../RegExValidate"

// import { MarkerClusterer } from "react-google-maps/lib/components/addons/MarkerClusterer"

/* wrap map high order component */
const WrappedMap = compose(
    withProps({
        // googleMapURL: "https://maps.googleapis.com/maps/api/js?libraries=geometry,drawing,places&key=" + GOOGLE_API_KEY,
        loadingElement: <div style={{ height: `100%` }} />,
        containerElement: <div style={{ height: `100%` }} />,
        mapElement: <div style={{ height: `100%` }} />
    }),
    withGoogleMap,
    lifecycle({
        componentDidUpdate(prevProps) {
            const { tripId, activities, activeDay, destination, hotel_form_state} = this.props

            if (prevProps.tripId !== tripId
                || prevProps.activeDay.tripDestinationId !== activeDay.tripDestinationId
                || prevProps.activeDay.dayInTrip !== activeDay.dayInTrip
                || prevProps.activeDay.dayInTripDestination !== activeDay.dayInTripDestination
                || prevProps.activities.length !== activities.length) {
                let waypts = []
                let first_lat = ""
                let first_lng = ""
                activities.map((activity) => {
                    first_lat = activity.activityLatitude
                    first_lng = activity.activityLongitude
                    waypts.push(new window.google.maps.LatLng({
                        lat: parseFloat(activity.activityLatitude),
                        lng: parseFloat(activity.activityLongitude)
                    }))
                    return ""
                })
                let first = waypts.shift()
                let last = waypts.pop()
                let way_points = waypts.map(obj => {
                    let key_obj = {}
                    key_obj["location"] = obj
                    key_obj["stopover"] = true
                    return key_obj
                })
                if (first !== undefined && last !== undefined) {
                    // console.log("display directions")
                    const DirectionsService = new window.google.maps.DirectionsService()

                    DirectionsService.route({
                        origin: first,
                        destination: last,
                        waypoints: way_points,
                        provideRouteAlternatives: true,
                        optimizeWaypoints: true,
                        travelMode: window.google.maps.TravelMode.DRIVING,
                    }, (result, status) => {
                        if (status === window.google.maps.DirectionsStatus.OK) {
                            this.setState({
                                directions: { ...result },
                                display: true
                            })
                        } else {
                            console.log(`Driving error fetching directions ${result}`)
                            DirectionsService.route({
                                origin: first,
                                destination: last,
                                waypoints: way_points,
                                provideRouteAlternatives: true,
                                optimizeWaypoints: true,
                                travelMode: window.google.maps.TravelMode.WALKING,
                            }, (result, status) => {
                                if (status === window.google.maps.DirectionsStatus.OK) {
                                    this.setState({
                                        directions: { ...result },
                                        display: true
                                    })
                                } else {
                                    console.error(`Walking error fetching directions ${result}`)
                                }
                            })
                        }
                    })
                } else if (first !== undefined) {
                    // console.log(" no directions")
                    this.setState({
                        directions: null,
                        display: false,
                    })
                    this.props.setMapCenter({
                        lat: parseFloat(first_lat),
                        lng: parseFloat(first_lng)
                    })
                    this.props.setZoom(DEFAULT_MAP_ZOOM)
                } else if (first === undefined) {

                    // console.log(" no activity and directions")
                    this.setState({
                        directions: null,
                        display: false
                    })
                    this.props.setMapCenter({
                        lat: parseFloat(hotel_form_state.city_latitude),
                        lng: parseFloat(hotel_form_state.city_longitude)
                    })
                    this.props.setZoom(DEFAULT_MAP_ZOOM)
                }
            }
        },
        componentDidMount() {
            const { activities, destination, handleCenterChanged, hotel_form_state } = this.props
            handleCenterChanged()

            let waypts = []
            let first_lat = ""
            let first_lng = ""
            activities.map((activity) => {
                first_lat = activity.activityLatitude
                first_lng = activity.activityLongitude
                waypts.push(new window.google.maps.LatLng({
                    lat: parseFloat(activity.activityLatitude),
                    lng: parseFloat(activity.activityLongitude)
                }))
                return ""
            })
            let first = waypts.shift()
            let last = waypts.pop()
            let way_points = waypts.map(obj => {
                let key_obj = {}
                key_obj["location"] = obj
                key_obj["stopover"] = true
                return key_obj
            })
            if (first !== undefined && last !== undefined) {
                // console.log("display directions")
                const DirectionsService = new window.google.maps.DirectionsService()

                DirectionsService.route({
                    origin: first,
                    destination: last,
                    waypoints: way_points,
                    provideRouteAlternatives: true,
                    optimizeWaypoints: true,
                    travelMode: window.google.maps.TravelMode.DRIVING,
                }, (result, status) => {
                    if (status === window.google.maps.DirectionsStatus.OK) {
                        this.setState({
                            directions: { ...result },
                            display: true
                        })
                    } else {
                        console.log(`Driving error fetching directions ${result}`)
                        DirectionsService.route({
                            origin: first,
                            destination: last,
                            waypoints: way_points,
                            provideRouteAlternatives: true,
                            optimizeWaypoints: true,
                            travelMode: window.google.maps.TravelMode.WALKING,
                        }, (result, status) => {
                            if (status === window.google.maps.DirectionsStatus.OK) {
                                this.setState({
                                    directions: { ...result },
                                    display: true
                                })
                            } else {
                                console.error(`Walking error fetching directions ${result}`)
                            }
                        })
                    }
                })
            } else if (first !== undefined) {
                // console.log(" no directions")
                this.setState({
                    directions: null,
                    display: false,
                })
                this.props.setMapCenter({
                    lat: parseFloat(first_lat),
                    lng: parseFloat(first_lng)
                })
                this.props.setZoom(DEFAULT_MAP_ZOOM)
            } else if (first === undefined) {

                // console.log(" no activity and directions")
                this.setState({
                    directions: null,
                    display: false
                })
                this.props.setMapCenter({
                    lat: parseFloat(hotel_form_state !== undefined && hotel_form_state.city_latitude !== "" ? hotel_form_state.city_latitude 
                        : destination !== undefined ? destination.latitude : 0),
                    lng: parseFloat(hotel_form_state !== undefined && hotel_form_state.city_longitude !== "" ? hotel_form_state.city_longitude 
                        : destination !== undefined ? destination.longitude : 0),
                })
                this.props.setZoom(destination !== undefined ? DEFAULT_MAP_ZOOM : 1)
            }
        }
    })
)((props) =>
    <GoogleMap
        defaultZoom={props.zoom}
        defaultCenter={props.mapCenter}
        center={props.mapCenter}
        defaultOptions={{
            controlSize: 20,
            mapTypeControl: false,
            fullscreenControl: false,
            minZoom: 4,
            // maxZoom: 20,
            styles: mapStyles
        }}
        onCenterChanged={props.handleCenterChanged}
        onZoomChanged={props.handleZoomChanged}
        ref={props.refMap}
    >
        {/* <MarkerClusterer gridSize={30}> */}
        {props.activities.length > 0 && props.activities.map((activity, index_activity) => {
            const lengthActivities = props.activities.length
            return (
                <ItineraryMarkers key={index_activity} lengthActivities={lengthActivities} activity={activity} index_activity={index_activity} />
            )
        })}
        {/* </MarkerClusterer>
        <MarkerClusterer gridSize={30}> */}
        {props.placesNearByCenter.length > 0 && props.placesNearByCenter.map((establishment, index_establishment) => {
            return (
                <React.Fragment key={index_establishment}>
                    {!props.activities.find(a => a.activityEstabID === establishment.id) &&
                        <PlacesMarkers establishment={establishment} index_establishment={index_establishment} placesNearByCenter={props.placesNearByCenter} />
                    }
                </React.Fragment>
            )
        })}
        {/* </MarkerClusterer> */}
        {props.display && props.directions && (
            <DirectionsRenderer
                directions={props.directions}
                options={{
                    suppressMarkers: true,
                    polylineOptions: {
                        strokeColor: "#19BC9B",
                        strokeOpacity: 0.5,
                        strokeWeight: 4
                    }
                }}
            />
        )}

    </GoogleMap>
)

/* trip map component */
const TripMapPricelne_ = ({
    app_menu_height,
    screenSizeHeight,
    lang,
    uuid_auth,
    uuid = uuid_auth !== "" ? uuid_auth : "TMPUUID",

    activeDay,
    activeTrip,
    activities = activeTrip.activities,
    destinations = activeTrip.tripDestinations,
    noDate = String(activeTrip.tripNoDate),
    tripStartDate = activeTrip.tripStartDate,

    getHotelsNearbyMapCenter,
    hotelSearchForm,
    setActiveTripDestinationId,

    placesNearByCenter,
    switch_container,
    tripMode,
    generalInfo
}) => {

    const from_destination = switch_container===member_area_router.hotelSearch && tripMode===list_container_mode.map 
    const destinationsFilter = destinations.filter(d => d.td_id === activeDay.tripDestinationId)
    let destination = destinationsFilter.length > 0 && destinationsFilter[0] !== undefined ? destinationsFilter[0] : undefined
    let hotel_form_state = hotelSearchForm !== undefined && hotelSearchForm.hotel_form_state !== undefined
                         ? hotelSearchForm.hotel_form_state : null
    console.log("hotel_form_state", hotel_form_state, hotelSearchForm);
    
    if (from_destination) {
        destination = generalInfo? {latitude: parseFloat(generalInfo.latitude), longitude: parseFloat(generalInfo.longitude)}:{latitude:45.490439, longitude: -73.714369}
    }

    const containerH = screenSizeHeight - Math.ceil(app_menu_height / 2)

    //updating  activities of the iternary
    const activitiesInTripDestinationDay = activities.filter(a => a.tripDestinationId === activeDay.tripDestinationId && a.activityDayInDestination === activeDay.dayInTripDestination)

    const [mapCenter, setMapCenter] = React.useState({
        lat: parseFloat(hotelSearchForm !== undefined && hotelSearchForm.hotel_form_state !== undefined && hotelSearchForm.hotel_form_state.city_latitude !== "" ? hotelSearchForm.hotel_form_state.city_latitude 
            : destination !== undefined ? destination.latitude : 0),
        lng: parseFloat(hotelSearchForm !== undefined && hotelSearchForm.hotel_form_state !== undefined && hotelSearchForm.hotel_form_state.city_longitude !== "" ? hotelSearchForm.hotel_form_state.city_longitude 
            : destination !== undefined ? destination.longitude : 0),
    })
    const [zoom, setZoom] = React.useState(destination !== undefined ? DEFAULT_MAP_ZOOM : (hotelSearchForm.hotel_form_state.city_latitude !== "" ? DEFAULT_MAP_ZOOM : 1))

    const handleSetActiveTripDestinationId = (destination, dayInTrip, dayInTripDestination) => {
        setActiveTripDestinationId(destination, dayInTrip, dayInTripDestination)
        document.getElementById(destination.td_id + "_" + dayInTripDestination + "_" + dayInTrip) !== null
            && document.getElementById(destination.td_id + "_" + dayInTripDestination + "_" + dayInTrip)
                .scrollIntoView({ behavior: 'smooth', block: 'start' })
    }


    // updating the places markers
    const refMap = React.useRef(null)
    const handleCenterChanged = () => {
        const lat = refMap?.current?.getCenter()?.lat()
        const lng = refMap?.current?.getCenter()?.lng()
        const zoom = refMap?.current?.getZoom()
        delay(function () {
            //getHotelsNearbyMapCenter(lat, lng, zoom, lang)
        }, DELAY_ZERO_SECOND);
    }
    const handleZoomChanged = () => {
        const lat = refMap?.current?.getCenter()?.lat()
        const lng = refMap?.current?.getCenter()?.lng()
        const zoom = refMap?.current?.getZoom()
        delay(function () {
            //getHotelsNearbyMapCenter(lat, lng, zoom, lang)
        }, DELAY_ZERO_SECOND);
    }

    const delay = (() => {
        let timer = 0
        return (callback, ms) => {
            clearTimeout(timer)
            timer = setTimeout(callback, ms)
        }
    })()

    //console.log("TripMap activeDay: ")
    //console.log(activeDay)
    //console.log("TripMap activeTrip: ")
    //console.log(activeTrip)

    return (
        <div style={{
            height: containerH,
            width: "100%",
            position: "absolute",
            top: "0",
        }} className="content_container margin_bottom_20 inline-block" id="google_map_wrap">
            <WrappedMap
                tripId={activeTrip.tripId}
                activities={activitiesInTripDestinationDay}
                activeDay={activeDay}
                destination={destination}
                hotel_form_state={hotel_form_state}
                placesNearByCenter={placesNearByCenter}
                zoom={zoom}
                setZoom={setZoom}
                mapCenter={mapCenter}
                setMapCenter={setMapCenter}
                handleCenterChanged={handleCenterChanged}
                handleZoomChanged={handleZoomChanged}
                refMap={refMap}
            />

            <div
                style={{
                    position: "absolute",
                    top: "2%",
                    right: "2%",
                    zIndex: "1",
                    margin: "0",
                }}>

                {destination !== undefined &&
                    destination.spanningDays !== undefined &&
                    destination.spanningDays.split(',').length > 0 &&
                    destination.spanningDays.split(',').map((dayInTrip, idx) => {
                        const dayInTripDestination = String(idx + 1)
                        return (
                            <div key={idx}
                                style={{
                                    border: "1px solid #E8E8E8",
                                    background: "var(--mainWhite) 0% 0% no-repeat padding-box",
                                    cursor: "pointer",
                                    color: "var(--mainGreen)",
                                    padding: "1px",
                                    width: noDate === "1" ? "90px" : "110px",
                                }}
                                onClick={() => handleSetActiveTripDestinationId(destination, dayInTrip, dayInTripDestination)}
                            >
                                <div className={noDate === "1" ? "futura_md_bt_12_16 pl-1 pt-1 pb-1 text-center" : "futura_md_bt_12_16 pl-1 pt-1 pb-1 text-left"}>

                                    {activeDay.dayInTrip === dayInTrip ?
                                        <CheckedBoxIcon className="mr-1" />
                                        :
                                        <CheckBoxIcon className="mr-1" />
                                    }
                                    {noDate === "1" ? "Day " + dayInTrip : moment(tripStartDate, 'YYYY-MM-DD').add((dayInTrip - 1), 'days').format('ddd D[,] MMM[.]')}
                                </div>
                            </div>
                        )
                    })}
            </div>
        </div>
    )
}

TripMapPricelne_.propTypes = {
    activeTrip: PropTypes.object.isRequired
}
const mapStateToProps = state => {
    return {
        lang: state.Setting.lang,
        app_menu_height: state.Setting.appMenu.app_menu_height,
        bodyClientWidth: state.Setting.htmlBody.bodyClientWidth,
        screenSizeHeight: window.innerHeight,

        activeTrip: state.FormBank.TimelineWizard.activeTrip,
        activeTripDestinationId: state.FormBank.TimelineWizard.activeTripDestinationId,
        activeDay: state.FormBank.TimelineWizard.activeDay,
        uuid_auth: state.Member.authModal.uuid,

        placesNearByCenter: state.FormBank.Map.placesNearByCenter,
        generalInfo: state.Destination.destination.generalInfo,
        switch_container: state.Member.switch_container,
        tripMode: state.Member.tripMode,

        hotelSearchForm: state.FormBank.HotelSearch,
    }
}

const mapDispatchToProps = {
    getHotelsNearbyMapCenter: Actions.getHotelsNearbyMapCenter,
    setActiveTripDestinationId: Actions.setActiveTripDestinationId,
}
const TripMapPricelne = connect(mapStateToProps, mapDispatchToProps)(TripMapPricelne_)
export default TripMapPricelne
