import * as Actions from '../../../../redux/actions'

import React, { Component } from 'react'
import "./Trips.css"

import MenuListComposition from '../../Common/MenuListComposition.jsx'
import MenuMember from "../../Common/MenuMember.jsx"
import { PropTypes } from "prop-types"
import ScrollToTop from "../../Common/ScrollToTop"
import TripsElement from "../../Common/Trip/TripsElement"
import { connect } from 'react-redux'
import { Virtuoso } from 'react-virtuoso'
import { Backdrop, CircularProgress, makeStyles } from '@material-ui/core'



const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));


/* trip container component */
const TripsContainer_ = ({ pastTrips, futureTrips, screenSize, dreamTrips, app_menu_height, is_mobile, logo_height,
    uuid, getMemberAreaFutureTrips, switch_container, getMemberAreaDreamTrips, getMemberAreaPastTrips, lang }) => {
    const [tripsEmpty, setTripsEmpty] = React.useState(false);
    const [loadingTrips, setLoadingTrips] = React.useState(false);


    React.useEffect(() => {
        if (uuid && lang) {
            if (!(futureTrips && futureTrips.length > 0) && !(pastTrips && pastTrips.length > 0) && !(dreamTrips && dreamTrips.length > 0) ) {
                setLoadingTrips(true)
            }

            getMemberAreaFutureTrips(uuid, lang)
            getMemberAreaPastTrips(uuid, lang)
            getMemberAreaDreamTrips(uuid, lang)
        }
    }, [lang, uuid])

    const classes = useStyles();
    React.useEffect(() => {
        if (!futureTrips && !pastTrips && !dreamTrips) {
            setTripsEmpty(true);
        }
       setTimeout(() => {
        setLoadingTrips(false)
       }, 1500);
    }, [futureTrips, pastTrips, dreamTrips])



    // if(uuid !== undefined &&  uuid !== null &&  lang !== undefined &&  lang !== null ){
    //     futureTrips !== undefined && futureTrips.length === 0 && getMemberAreaFutureTrips(uuid, lang)
    //     pastTrips !== undefined && pastTrips.length === 0 && getMemberAreaPastTrips(uuid, lang)
    //     dreamTrips !== undefined && dreamTrips.length === 0 && getMemberAreaDreamTrips(uuid, lang)
    // }


    let margin_left = 0
    let margin_right = 0
    let sizeW = 0
    let sizeH = 0
    let position = "sticky"
    let top_H = 180
    let font_size = "50"
    if (!isNaN(screenSize)) {
        margin_left = screenSize > 1200 ? (screenSize / 100 * 5) + 430 : (screenSize <= 768 ? 0 : (screenSize / 100 * 4) + 350)
        margin_right = (screenSize <= 768) ? 0 : (screenSize / 100 * 8.5)
        sizeW = screenSize - margin_left - margin_right - 80
        font_size = screenSize <= 768 ? 25 : 50
        sizeH = screenSize > 1025 ? 265 : (screenSize > 500 ? 265 : 265)
    }
    return (

        <div style={{ minHeight: "800px", top: top_H, position: "relative", marginLeft: margin_left, marginRight: margin_right, backgroundColor: "#191919" }} className="padding_top_20">
            <Backdrop className={classes.backdrop} open={loadingTrips} >
                <CircularProgress color="inherit" />
            </Backdrop>

            <div style={{
                position: position, zIndex: "3",
                top: is_mobile ? 0 : (-Math.ceil(-app_menu_height / 2))
            }}>
                <div className="padding_top_10 padding_left_20 padding_right_20" style={{ backgroundColor: "#191919" }}>
                    <MenuMember />
                </div>
            </div>
            <div className={is_mobile ? "pl-1 pr-1" : "padding_left_40 padding_right_40"} style={{       paddingBottom: 100}}>
                <div className="padding_top_50 padding_125rem_lr_mobile"
                    style={{
                        font: font_size + "px/72px Futura Hv BT",
                        color: "#FFFFFF"
                    }}>My Trips
                        </div>


                {tripsEmpty &&
                    <p className="padding_top_50 padding_125rem_lr_mobile"
                        style={{
                            font: "24px/24px Futura Lt BT",
                            color: "#FFFFFF"
                        }}>
                        You haven't added any {switch_container} yet.
                        </p>
                }



                {
                    futureTrips !== undefined && futureTrips.length > 0 && futureTrips[0] !== undefined && <React.Fragment>
                        <div className="d-flex margin_top_20 flex-wrap">
                            <div className="flex_grow_1 padding_125rem_lr_mobile" style={{ font: "30px/70px Futura Hv BT", color: "#FFFFFF", marginBottom: "15px" }}>Upcoming trips</div>
                            {/* <div className="padding_125rem_lr_mobile padding_right_30 padding_top_20"><MenuListComposition /></div> */}
                        </div>
                        <Virtuoso
                            useWindowScroll
                            overscan={400}
                            totalCount={futureTrips.length}
                            itemContent={index => {
                                const trip = futureTrips[index]
                                return (
                                    <div className="tripsMember">

                                        <TripsElement trip={trip} key={index + "_future"} sizeW={sizeW} sizeH={sizeH} />
                                    </div>
                                )
                            }
                            }
                        />
                    </React.Fragment>
                }
                {
                    dreamTrips !== undefined && dreamTrips.length > 0 && dreamTrips[0] !== undefined && <React.Fragment>
                        <div className="d-flex margin_top_20 flex-wrap">
                            <div className="flex_grow_1 padding_125rem_lr_mobile" style={{ font: "30px/70px Futura Hv BT", color: "#FFFFFF", marginBottom: "15px" }}>Dream trips</div>
                        </div>
                        <Virtuoso
                            useWindowScroll
                            overscan={400}
                            totalCount={dreamTrips.length}
                            itemContent={index => {
                                const trip = dreamTrips[index]
                                return (
                                    <div className="tripsMember">

                                        <TripsElement trip={trip} key={index + "_dream"} sizeW={sizeW} sizeH={sizeH} />
                                    </div>
                                )
                            }
                            }
                        />
                    </React.Fragment>
                }

                {
                    pastTrips !== undefined && pastTrips.length > 0 && pastTrips[0] !== undefined && <React.Fragment>
                        <div className="d-flex margin_top_20 flex-wrap">
                            <div className="flex_grow_1 padding_125rem_lr_mobile" style={{ font: "30px/70px Futura Hv BT", color: "#FFFFFF", marginBottom: "15px" }}>Past trips</div>
                        </div>
                        <Virtuoso
                            useWindowScroll
                            overscan={400}
                            totalCount={pastTrips.length}
                            itemContent={index => {
                                const trip = pastTrips[index]
                                return (
                                    <div className="tripsMember">

                                        <TripsElement trip={trip} key={index + "_past"} sizeW={sizeW} sizeH={sizeH} />
                                    </div>
                                )
                            }
                            }
                        />
                    </React.Fragment>
                }


                <ScrollToTop />
            </div>
        </div>
    )
}

TripsContainer_.propTypes = {
    pastTrips: PropTypes.array.isRequired,
    futureTrips: PropTypes.array.isRequired,
    dreamTrips: PropTypes.array.isRequired,
}
const mapStateToProps = state => {
    return {
        pastTrips: state.Member.memberArea.memberPastTrips,
        futureTrips: state.Member.memberArea.memberFutureTrips,
        dreamTrips: state.Member.memberArea.memberDreamTrips,
        screenSize: state.Setting.htmlBody.bodyClientWidth,
        switch_container: state.Member.switch_container,
        is_mobile: state.Setting.is_mobile,
        app_menu_height: state.Setting.appMenu.app_menu_height,
        uuid: state.Member.authModal.uuid,
        logo_height: state.Setting.appMenu.logo.height,
        lang: state.Setting.lang,
    }
};

const mapDispatchToProps = {
    getMemberAreaPastTrips: Actions.getMemberAreaPastTrips,
    getMemberAreaFutureTrips: Actions.getMemberAreaFutureTrips,
    getMemberAreaDreamTrips: Actions.getMemberAreaDreamTrips,
};


const TripsContainer = connect(mapStateToProps, mapDispatchToProps)(TripsContainer_)
export default React.memo(TripsContainer)
